import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox, IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom, ReasonPopup } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

let RoleData = null;
let userAccess = null;
class OrganismVsAntibioticGrp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrganismVsAntibioticMasterData: [],

      // pagination
      page: 0,
      pageSize: 10,
      rowCount: 0,

      // dropdown state
      antibioticNameList: [],
      organismNameList: [],

      // field state
      antibioticName: null,
      organismName: null,
      deptStatus: true,
      comments :"",

      // filter state
      FilterOpen: false,
      searchOrganismName: null,
      searchAntibioticName: null,
      isFromEdit : false,
      openComments : true,
      isFromSave : false,
    }

    this.postOrganismVsAntibioticMaster = this.postOrganismVsAntibioticMaster.bind(this);
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  // api call mount
  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", "Organism Vs Antibiotic Name", "SubTab")
    this.OrganismVsAntibioticList()
  }

  // loading
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  // api call function
  OrganismVsAntibioticList() {
    try {
      this.setState({ postLoad: true })
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.LAB_ORGANISM_VS_ANTIBIO_MASTER}?organism_id=${this.state.searchOrganismName?.id || ""}&antibiotic_id=${this.state.searchAntibioticName?.id || ""}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.setState({
              OrganismVsAntibioticMasterData: response.data.data,
              antibioticNameList: response.data.antibiotics,
              organismNameList: response.data.organisms,
            })
          }
          else {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ postLoad: false })
          this.LoaderFunction(false);
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  addInactiveCommentsPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.openPopUp}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "32vw", height: "40vh", maxHeight: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box  >
              <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit organism" : "Add Organism"}</Typography>
                <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openPopUp: false ,  comments:"" })} >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <hr />
           
              {!this.state.isFromAdd && this.state.deptStatus === false ?
                <Box>
                  <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                    <Typography>{"Inactive Comments"}</Typography>
                  </Box>
                  <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                    <div>
                      <TextField
                        size='small'
                        label={("Reason *")}
                        inputProps={{ maxLength: 250 }}
                        disabled ={!this.state.isFromEdit}
                        style={{ marginTop: "0.5vw", width: "28.5vw" }}
                        multiline={true}
                        rows={3}
                        variant="outlined"
                        value={this.state.comments}
                        onChange={(event) => {
                          let value = event.target.value
                          if (value?.length <= 250) {
                            this.setState({ comments: value })
                          } else {
                            this.errorMessage('Allowed only 250 characters')
                          }
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                : null
              }
            </Box>

            <Box>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => { this.setState({comments :""}) }}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  disabled={this.state.postLoad}
                  variant='contained'
                  onClick={() => {
                    this.setState({postLoad : true,},()=>{this.postOrganismVsAntibioticMaster()})
                  }}
                >{states.isFromEdit ? "Update" : "Add"}</Button>
              </div>
            </Box>
          </Box>

        </Modal>
      </div>
    )
  }
  handleValidation = ()=>{
    if(this.state.deptStatus === false && !this.state.comments ){
      this.errorMessage("Enter Inactive comments")
      this.setState({openPopUp : true, postLoad :false})
    }
     else if (!this.state.organismName) {
      this.errorMessage("Enter organism Name")
      this.setState({ postLoad: false })
    }else if (!this.state.antibioticName) {
      this.errorMessage("Enter Antibiotic Name")
      this.setState({ postLoad: false })
    }else{
      this.postOrganismVsAntibioticMaster()
    }
  }

  postOrganismVsAntibioticMaster = () => {
    try {
      this.LoaderFunction(true);
      let data = {
        "organism_id": this.state.organismName ? this.state.organismName?.id : null,
        "antibiotic_id": this.state.antibioticName ? this.state.antibioticName?.id : null,
        "is_active": this.state.deptStatus ? this.state.deptStatus : false,
        "id": this.state.id ? this.state.id : null,
        "inactive_reason": this.state.comments  ? CommonValidation.removeSpace(this.state.comments)  : ""
      }
      const loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo') || '{}');
      const lab_id = loggedInUserInfo.laboratory_id;

      RestAPIService.create(data, `${Serviceurls.LAB_ORGANISM_VS_ANTIBIO_MASTER}?lab_id=${lab_id}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.successMessage(response.data.message)
            this.setState({ openPopUp: false, disableBtn: false })
            this.handleClear()
            this.OrganismVsAntibioticList()
          }
          else {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ postLoad: false })
          this.LoaderFunction(false);
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  // clear form function
  handleClear = () => {
    this.setState({
      id: null,
      antibioticName: null,
      organismName: null,
      deptStatus: true,
      comments :"",
    })
  }

  // edit call function
  handleEdit = (data) => {
    this.setState({
      antibioticName: this.state.antibioticNameList.find((list) => list?.antibiotic_name === data?.antibiotic_name),
      organismName: this.state.organismNameList.find((list) => list?.organism_name === data?.organism_name),
      deptStatus: data.is_active,
      id: data.id,
      comments :data?.inactive_reason ? data?.inactive_reason :"",
      isFromEdit : true,
    })
  }

  // common input
  renderAutoCompleteTextField = (value, statekey, Label, required, width, disable = false, option) => {
    const { t } = this.props;
    let states = this.state;

    return (
      <Autocomplete
        sx={{ width: width ? width : "12vw" }}
        size="small"
        disabled={disable}
        inputProps={{ maxLength: 100 }}
        autoComplete="off"
        label={t(Label)}
        required={required}
        disableClearable
        options={option}
        getOptionLabel={(option) => statekey === "organismName" ? option?.organism_name : option?.antibiotic_name}
        value={value}
        onChange={(event, newValue) => {
          states[statekey] = newValue
          this.setState({
            states
          })
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.id}>
              {statekey === "organismName" ? option?.organism_name : option?.antibiotic_name}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps }}
            label={t(Label)}
            autoComplete='off'
          />
        )}
      />
    );
  }

  renderFilterAutoCompleteTextField = (value, statekey, Label, required, width, disable = false, option) => {
    const { t } = this.props;
    let states = this.state;

    return (
      <Autocomplete
        sx={{ width: width ? width : "12vw" }}
        size="small"
        disabled={disable}
        inputProps={{ maxLength: 100 }}
        autoComplete="off"
        label={t(Label)}
        required={required}
        disableClearable
        options={option}
        getOptionLabel={(option) => statekey === "searchOrganismName" ? option?.organism_name : option?.antibiotic_name}
        value={value}
        onChange={(event, newValue) => {
          states[statekey] = newValue
          this.setState({
            states
          })
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.id}>
              {statekey === "searchOrganismName" ? option?.organism_name : option?.antibiotic_name}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps }}
            label={t(Label)}
            autoComplete='off'
          />
        )}
      />
    );
  }

  // table tool bar
  gridToolBar = () => {

    var ExportData = []
    this.state.OrganismVsAntibioticMasterData?.length > 0 && this.state.OrganismVsAntibioticMasterData?.map((item) => {

      return (
        ExportData?.push({
          "SI.No": item?.sno, "Organism Vs Antibiotic Code": item?.ova_code, "Organism Name": item?.organism_name, "Antibiotic Name": item?.antibiotic_name, "Status": item?.is_active === true ? "Active" : item?.is_active === false ? "InActive" : "",
        })
      )
    }
    )
    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Organism Vs Antibiotic Name"} filename={"Organism Vs Antibiotic Name"} />
      </Box>
    )
  }

  // table
  renderTable = () => {
    this.state.OrganismVsAntibioticMasterData?.forEach((element, index) => element.sno = index + 1)

    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "ova_code", flex: 0.2, headerName: t("Organism Vs Antibiotic Code"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ova_code ? params?.row?.ova_code : "-"}</Box>)
      },
      {
        field: "organism_name", flex: 0.2, headerName: t("Organism Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.organism_name?.length > 23 ?
          <Tooltip placement="top" title={row?.organism_name} arrow><div>{row?.organism_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.organism_name ? row?.organism_name : "-"}</div>)
      },
      {
        field: "antibiotic_name", flex: 0.2, headerName: t("Antibiotic Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.antibiotic_name?.length > 23 ?
          <Tooltip placement="top" title={row?.antibiotic_name} arrow><div>{row?.antibiotic_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.antibiotic_name ? row?.antibiotic_name : "-"}</div>)
      },
      {
        field: "deptStatus", flex: 0.2, headerName: t("Status"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (
          <Tooltip placement="top" title={ row?.inactive_reason} arrow><div>{ row?.is_active ? "Active" : "Inactive"}</div></Tooltip>  
        )
      },
      {
        field: "action", flex: 0.17, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" onClick={() => { this.handleEdit(params.row) }} arrow>
            <Button emed_tid="vnd_edit" className='eMed_usrconf_btn'>
              <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' style={{ backgroundColor: Colors.Background }}>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.OrganismVsAntibioticMasterData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              loading={this.state.isLoader}
              rowCount={this.state.OrganismVsAntibioticMasterData?.length}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  // close filter
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
 
  // filter screen
  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid="vnd_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            {this.renderFilterAutoCompleteTextField(this.state.searchOrganismName, "searchOrganismName", "Organism Name", false, "20vw", false, this.state.organismNameList)}
          </Box>

          <Box className='eMed_rts_dropdown'>
            {this.renderFilterAutoCompleteTextField(this.state.searchAntibioticName, "searchAntibioticName", "Antibiotic Name", false, "20vw", false, this.state.antibioticNameList)}
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid="fil_clear"
            onClick={() => {
              this.setState({
                searchAntibioticName: null,
                searchOrganismName: null,
              }, () => this.OrganismVsAntibioticList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid="fil_search"
            onClick={() => {
              this.OrganismVsAntibioticList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  render() {
    const { t } = this.props;

    return (
      <div style={{ backgroundColor: Colors.Background }}>
        <Box component={"div"} style={{ backgroundColor: Colors.Background }}>
          <Stack component={"div"} sx={{ margin: "1.5vh", marginLeft: "1.8vh" }}>
            <Paper>
              {/* form */}
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* form fields */}
                <Box sx={{ display: "flex", alignItems: "center", padding: "10px", gap: "1vh" }}>
                  {this.renderAutoCompleteTextField(this.state.organismName, "organismName", "Organism Name", true, "15vw", false, this.state.organismNameList)}
                  {this.renderAutoCompleteTextField(this.state.antibioticName, "antibioticName", "Antibiotic Name", true, "15vw", false, this.state.antibioticNameList)}

                  {/* status */}
                  <Checkbox
                   disabled ={!this.state.isFromEdit}
                    checked={this.state.deptStatus}
                    onChange={(e) => {
                       this.setState({ deptStatus: e.target.checked, comments :""  },()=>{  if(this.state.deptStatus === false){this.setState({openPopUp : true})}}) }}
                  />
                  <Typography>{"Active"}</Typography>
                {this.state.isFromEdit && this.state.comments ?  <IconButton>
                    <Tooltip title={this.state.comments} placement={'right'} arrow>
                      <ErrorOutlineIcon color='error'/>
                    </Tooltip>
                   
                  </IconButton>: null}
                </Box>

                {/* action button */}
                <Box>
                  <div style={{ display: userAccess?.editAccess ? "flex" : "none", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                    <Button
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                      variant='outlined'
                      onClick={() => {
                        this.setState({ isFromEdit: false }, () => { this.handleClear() })
                      }}
                    >Clear</Button>
                    <Button
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                      disabled={this.state.postLoad}
                      variant='contained'
                      onClick={() => { this.setState({ postLoad: true,isFromSave : true },() => {this.handleValidation()}) }}
                    >Save</Button>
                    <Tooltip title="Filter" placement='top' arrow>
                      <Button emed_tid="vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                        <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                      </Button>
                    </Tooltip>
                  </div>
                </Box>
              </Box>
            </Paper>
          </Stack>
        </Box>
        {/* table */}
        {this.renderTable()}
        {/* filter */}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {/* error message */}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.addInactiveCommentsPopup()}
      </div>
    )
  }
}

export default withTranslation()(OrganismVsAntibioticGrp);