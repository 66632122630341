import React, { Component } from 'react';
import {
    Box, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Stack, Typography, Autocomplete, Grid, Tooltip, IconButton, Checkbox, InputAdornment,
    Dialog,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem, Divider,
    Modal,
    InputLabel, Select, FormControl
} from "@mui/material";
import "../PharmacyPurchase/PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CommonEditButton, CommonDeleteButton, CommonPopUp, ReasonPopup } from "../../../Components/Common Components/CommonComponents";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import AddIcon from '@mui/icons-material/Add';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { CurrencySymbol } from '../../../Utility/Constants';
import WarningIcon from '@mui/icons-material/Warning';
import PharmaISRequestDetailRight from './PharmaISRequestDetailRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

let shippingDetailsSelectValues = [
    { label: "self", value: "self" },
    { label: "courier", value: "courier" },
]


class PharmaISRequestDetailLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            PharmaInternalSalesRequestList: [],
            vendor_name: "",
            vendor_id: null,
            delivery_date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            billNo: "",
            billDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            shippingDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            ISDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            purchase_order_date: null,
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            EditIndex: -1,
            billAmt: "",
            purchase_order_number: "",
            purchaseOrderNumber: null,
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            purchase_from: "directPo",
            previous_vendor_bills: [],
            bill_id: null,
            open: false,
            open1: false,
            isselectedLineItem: false,
            isNewBatch: false,
            editWithin: -1,
            showSaveBtn: true,
            EditIndex: -1,
            tabValue: "",
            stockData: {},
            stockFrom: this.props.from ? this.props.from : "",
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            isSaveClicked: false,
            from_page: "",
            is_vendor_dc: false,
            PropsFromDC: {},
            dc_popup_data: [],
            is_dc_popup: false,
            clearEditData: false,
            isFinished: false,
            callPost: false,
            fromWanted: false,
            isUpdateCall: false,
            saveLoad: false,
            paisa: '',
            roundofAmt: '',
            type_Paisa: true,
            fromDcPopup: false,
            grn_no: "-",
            dup_purchase_order_number: '',
            taxableAmt: "0.00",
            genGrn: false,
            // openPopup: false,
            shppingDetailOpenPopUp: false,
            selectedRemoveIndex: null,
            dupLftData: '[]',
            fromWarnPopup: false,
            vendor_bill_id: '',
            fromGrnLine: false,
            clickFinish: false,
            tcsAmt: '',
            isEditAccessGRN: false,
            fromDC: this.props.fromDC ? this.props.fromDC : false,
            selectedItem: {},
            ISNO: null,
            vendorName: "",
            selectedIndex: null,
            openSendDrugPopup: false,
            poId: null,
            pharmacyId: null,
            stock_id: null,
            shippingDetailSelect: shippingDetailsSelectValues,
            mobileNumber: null,
            shippingMethod: "self",
            trackingId: null,
            e_way_bill_no :"",
            comments: "",
            deletePopup: false,
            isAllDisabled: false,
            isFromWanted: false,

        }
    }

    // componentDidMount() {
    //     let  RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    //   if(RoleData.is_user){
    //      let permissionAccess = RoleData?.permission_access?.pharma 
    //      let grantAccess = permissionAccess?.isGRNEntry ? false : true
    //      this.setState({
    //         isEditAccessGRN : grantAccess
    //      })
    //   }else{
    //       this.setState({
    //           isEditAccessGRN : false
    //        })
    //   }
    //   userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Purchase Order', null, "Tab");
    //   this._getVendorList();
    // this.getPORequestSelectedData()
    //   }

    componentDidUpdate(prevProps) {
        //   let states = this.state;
        //   let history = prevProps.history?.location?.state;
        //   let purchase_order_id = history?.pharmacy_id;
        //   let isUser = !userAccess?.editAccess;
        if (this.props !== prevProps) {


            if (this.props?.history?.location?.state?.pharmacy_id && this.props?.history?.location?.state?.pharmacy_id !== this.state?.purchase_order_id) {
                let store_status = this.props?.history?.location?.state?.selectedItem?.store_status
                this.setState({
                    purchase_order_id: this.props?.history?.location?.state?.pharmacy_id ? this.props?.history?.location?.state?.pharmacy_id : null,
                    isAllDisabled: (store_status === "Cancelled" || store_status === "Approved" || store_status === "Completed") ? true : false
                    // selectedItem: this.props?.history?.location?.state?.selectedItem ? this.props?.history?.location?.state?.selectedItem : {},
                    //   purchase_status: history?.purchase_status ? history?.purchase_status : "",
                    //   purchase_from: history?.from ? history?.from : "",
                    //   showSaveBtn: (history?.purchase_status === "Completely Delivered" || history?.purchase_status === 'Completed') && isUser ? false : this.state.showSaveBtn,
                    //   vendor_id: history?.purchaseDetail?.vendor_id ? history?.purchaseDetail?.vendor_id : '',
                    //   vendor_bill_id: history?.vendor_bill_id ? history?.vendor_bill_id : '',
                    //   fromGrnLine: history?.fromGrn ? history?.fromGrn : false

                }, () => {
                    this.getPORequestSelectedData();

                    //   if (!history?.purchaseDetail?.is_finished) {
                    //       this.getVendorDClist()
                    //   }
                })
            }

            if (this.props?.history?.location?.state?.poID && this.props?.history?.location?.state?.poID !== this.state?.purchase_order_id) {
                this.setState({
                    purchase_order_id: this.props?.history?.location?.state?.poID,
                    isFromWanted: this.props?.history?.location?.state?.isFromWanted ? this.props?.history?.location?.state?.isFromWanted : false
                }, () => { this.getPORequestSelectedData() })
            }
            //    else if (this.props.stockData !== undefined && Object.keys(this.props.stockData)?.length > 0 && this.props.stockData !== this.state.stockData) {
            //       this.setState({
            //           stockData: this.props.stockData,
            //           showSaveBtn: false,
            //           fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            //       }, () => {
            //           this.assignStockDetails()
            //       })
            //   } 
            //   else if (prevProps.from !== this.props.from && this.props.from) {
            //       this.setState({
            //           stockFrom: this.props.from,
            //           showSaveBtn: false,
            //           fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            //       })
            //   }

            //   if (history?.propsFromDc?.dc_number !== this.state.PropsFromDC?.dc_number) {
            //       let LineItems = []
            //       if (history?.propsFromDc?.line_items?.length > 0) {
            //           history?.propsFromDc?.line_items?.forEach((list, index) => {
            //               list.serial_no = index + 1
            //               LineItems.push(list)
            //           })
            //       }
            //       this.setState({
            //           PropsFromDC: history?.propsFromDc,
            //           vendor_name: history?.propsFromDc?.vendor_name,
            //           vendor_id: history?.propsFromDc?.vendor_id,
            //           cgstAmt: (history?.propsFromDc?.cgstAmt) ? (history?.propsFromDc?.cgstAmt).toFixed(2) : 0,
            //           sgstAmt: (history?.propsFromDc?.sgstAmt) ? (history?.propsFromDc?.sgstAmt).toFixed(2) : 0,
            //           billAmt: (history?.propsFromDc?.billAmt) ? (history?.propsFromDc?.billAmt).toFixed(2) : 0,
            //           lftTblData: LineItems,
            //           roundofAmt: (history?.propsFromDc?.billAmt) ? (history?.propsFromDc?.billAmt).toFixed(2) : 0,
            //           taxableAmt: history?.propsFromDc?.billAmt ? (history?.propsFromDc?.billAmt - (history?.propsFromDc?.cgstAmt * 2))?.toFixed(2) : 0,
            //           fromGrnLine: history?.fromGrn ? history?.fromGrn : false
            //       })
            //   }

            //   if (history?.from_page != states.from_page && history?.from_page) {
            //       this.setState({
            //           from_page: (history?.from_page) ? (history?.from_page) : ""
            //       })
            //   }
        }


    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    alteredData = (incomingData, incomingIndex) => {
        let stockId = incomingData.stock_id
        this.setState({
            stock_id: stockId ? stockId: null
        })

        let updatedPharmaList = this.state?.PharmaInternalSalesRequestList?.map((item, index) => {
            if ((item?.selling_price !== incomingData?.buying_price) || (item?.sentQty !== incomingData?.sentQty) || (item?.freeQty !== incomingData?.freeQty) && index === this.state?.selectedIndex) {
                return {
                    ...item,
                    selling_price: incomingData?.buying_price ? incomingData?.buying_price : null,
                    // mrp: incomingData.mrp_per_pack,
                    sentQty: incomingData?.sentQty ? incomingData?.sentQty : null,
                    freeQty: incomingData?.freeQty ?  incomingData?.freeQty :null,
                };
            }
            return item;
        });
        this.setState({
            PharmaInternalSalesRequestList: updatedPharmaList
        });
    }
    getPORequestSelectedData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.INTERNALSALES_REQUESTLIST}?po_id=${this.state.purchase_order_id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            poId: response?.data?.data?.id ? response?.data?.data?.id : null,
                            pharmacyId: response?.data?.data?.pharmacy_id ? response?.data?.data?.pharmacy_id : null,
                            purchaseOrderNumber: response?.data?.data?.purchase_order_number ? response?.data?.data?.purchase_order_number :null,
                            vendor_id: response?.data?.data?.vendor_id ? response?.data?.data?.vendor_id : null,
                            PharmaInternalSalesRequestList: response?.data?.data?.line_items ? response?.data?.data?.line_items : [],

                            vendorName: response?.data?.data?.vendor_name ? response?.data?.data?.vendor_name : "",
                            // ISNO : response?.data?.data?.po_number ? response?.data?.data?.po_number : null ,
                        },
                            () => {
                                if (this.state.isFromWanted) {
                                    this.state.PharmaInternalSalesRequestList?.forEach((item) => {
                                        item["free_quantity"] = 0
                                    })
                                    this.setState({
                                        PharmaInternalSalesRequestList: this.state.PharmaInternalSalesRequestList
                                    })
                                }
                            })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    // assignStockDetails = () => {
    //     let { stockData, lftTblData } = this.state;
    //     if (stockData !== undefined && Object.keys(stockData)?.length > 0) {
    //         stockData["index"] = 1;
    //         lftTblData.push(stockData);

    //         this.setState({
    //             lftTblData,
    //             vendor_name: stockData.vendor_name,
    //             delivery_date: stockData.delivery_date,
    //             purchase_order_date: new Date(stockData.purchase_order_date),
    //             purchase_order_id: stockData.purchase_order_id,
    //             purchase_order_number: stockData.purchase_order_number,
    //             vendor_id: stockData.vendor_id,
    //             billDate: stockData.bill_date,
    //             cgstAmt: stockData.cgst_amount ? stockData.cgst_amount : "0.00",
    //             sgstAmt: stockData.sgst_amount ? stockData.sgst_amount : "0.00",
    //             grn_no: stockData.grn_no ? stockData.grn_no : '-',
    //         })
    //     }
    // }


    closePopup() {
        this.setState({
            deletePopup: false
        })
    }

    addCancelComments = (value) => {
        this.setState({
            comments: value
        }, () => { this.rejectData() })
    }
    handleOpen = (statekey) => {
        let states = this.state;
        states[statekey] = true;
        this.setState({
            states
        })
    }
    getLastDateOfMonth(dateString) {
        try {
            // Parse the input date string
            const date = new Date(dateString);

            // Get the year and month from the input date
            const year = date?.getFullYear();
            const month = date?.getMonth();

            // Create a new date object set to the first day of the next month
            const nextMonth = new Date(year, month + 1, 1);

            // Subtract one day to get the last day of the current month
            const lastDateOfMonth = new Date(nextMonth - 1);

            // Format the date as 'YYYY-MM-DD'
            const formattedDate = lastDateOfMonth?.toISOString()?.split('T')[0];

            return formattedDate;
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }
    sendDrugDetails = (isSubmitted = false) => {
        try {
            let states = this.state;
            let datas = {

                bill_date: states.billDate ? states.billDate :"",
                // bill_no: states.billNo,
                cgst_amount: states.cgstAmt ? +states.cgstAmt : 0.00,
                delivery_date: states.delivery_date ? states.delivery_date : "",
                purchase_order_date: states.purchase_order_date ? states.purchase_order_date : DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
                purchase_order_number: states.purchaseOrderNumber ? states.purchaseOrderNumber : null,
                sgst_amount: states.sgstAmt ? +states.sgstAmt : 0.00,
                po_pharmacy_id: this.state.pharmacyId ? this.state.pharmacyId : null,
                po_id: this.state.poId ? this.state.poId : null,
                stock_items: [],
                shipping_details: {},
                total_amount: +states.roundofAmt ? (+states.roundofAmt) : (+states.billAmt),
                vendor_id: states.vendor_id ? states.vendor_id : null,
                // is_finished: this.state.isSaved ? false : true,
                bill_amount: +states.billAmt ? (+states.billAmt) : 0,
                // paise_amount: states.paisa ? (states.paisa / 100) : 0,
                paise_amount: states.paisaValue ? states.paisaValue : 0,

            };
            if (isSubmitted) {
                let shippingDetails = {
                    'method': this.state.shippingMethod ? this.state.shippingMethod : "",
                    'mobile_number': this.state.mobileNumber ? this.state.mobileNumber :"",
                    'shipping_date': this.state.shippingDate ? this.state.shippingDate :"",
                    'tracking_id': this.state.trackingId ? this.state.trackingId :"",
                    'e_way_bill_no': this.state.e_way_bill_no ? this.state.e_way_bill_no :""
                }
                datas["shipping_details"] = shippingDetails
            }
            this.state.PharmaInternalSalesRequestList?.forEach((item, i) => {
                datas.stock_items?.push({
                    stock_id: item.stock_id ? item.stock_id : null,
                    batch_no: item.tabValue === "two" ? CommonValidation.removeSpace(item.free_batch_no) : item.batch_no ? CommonValidation.removeSpace(item.batch_no) : "",
                    buying_price: +item.buying_price ?  +item.buying_price :null,
                    cgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.cgst_amount ? item.cgst_amount : 0,
                    cgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.cgst_percentage ? item.cgst_percentage : 0,
                    discount_amount: item.discount_amount ? +item.discount_amount : 0,
                    discount_percentage: item.discount_percentage ? +item.discount_percentage : 0,
                    drug_ms_id: item.drug_id ? +item.drug_id : +item.drug_ms_id,
                    epr_value: +item.epr_value ? +item.epr_value : null,
                    expiry_alert_period: item.expiry_alert_period ? +item.expiry_alert_period : 3,
                    expiry_date: item.tabValue === "two" ? this.getLastDateOfMonth(item.free_expiry_date) : this.getLastDateOfMonth(item.expiry_date),
                    free_quantity: item.tabValue === "two" ? (+item.free_no_of_unit * + item.free_qty_per_unit) : item.free_quantity ? (+item.free_quantity * +item.pack_of_drugs) : 0,
                    group: item.group ? item.group : "",
                    hsn_code: item.hsn_code ? item.hsn_code : "",
                    instructions: item.description ? item.description : item.instructions ? item.instructions : "",
                    manufacturer_name: item.manufacturer_name ? item.manufacturer_name : "",
                    mrp_per_pack: item.tabValue === "two" ? +item.free_mrp_unit : item.mrp_per_pack ? +item.mrp_per_pack : 0,
                    mrp_per_quantity: item.mrp_per_quantity ? +item.mrp_per_quantity : item?.is_free_quantity ? +(+item.free_mrp_unit / +item.free_qty_per_unit)?.toFixed(2) : 0,
                    pack_of_drugs: item.tabValue === "two" ? +item.free_qty_per_unit : item.pack_of_drugs ? +item.pack_of_drugs : 0,
                    physical_box_no: item.physical_box_no ? item.physical_box_no : "",
                    recommended_by: item.recommended_by ? item.recommended_by : "",
                    reorder_threshold: item.reorder_threshold ? +item.reorder_threshold : 0,
                    sales_discount: item.sales_discount ? +item.sales_discount : 0,
                    sales_discount_amount: item.sales_discount_amount ? +item.sales_discount_amount : 0,
                    sales_unit: item.tabValue === "two" ? +item.free_no_of_unit : +item.sales_unit,
                    schedule: item.schedule ? item.schedule : "",
                    serial_no: item.tabValue === "two" ? (item.serial_no ? item.serial_no : i + 1) : item.serial_no,
                    sgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.sgst_amount ? item.sgst_amount : 0,
                    sgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.sgst_percentage ? item.sgst_percentage : 0,
                    stock_in_quantity: item.stock_in_quantity ? +item.stock_in_quantity : 0,
                    total_buying_price: item.total_buying_price ? +item.total_buying_price : (item?.buying_price && item?.sales_unit) ? (+item.buying_price * +item?.sales_unit) : 0,
                    sales_discount_for_bill: item.tabValue === "two" ? +item?.free_sales_discount_bills : item?.sales_discount_for_bill ? +item?.sales_discount_for_bill : 0,
                    is_free_quantity: item.tabValue === "two" || item?.is_free_quantity ? true : false,
                    po_line_item_id: item.po_line_item_id ? item.po_line_item_id : null
                });
                // datas.cgst_amount += (+item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.cgst_amount ? item.cgst_amount : 0);
                // datas.sgst_amount += (+item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.sgst_amount ? item.sgst_amount : 0);
                return datas
            });

            // let totalCgst = 0
            // let totalSgst = 0
            // datas.stock_items.reduce((acc, item) => {
            //     totalCgst += (+item.cgst_amount);  
            //     totalSgst += (+item.sgst_amount);
            // }, 0)
            //  this.state.cgstAmt = (+totalCgst)
            //  this.state.totalSgst = (+totalSgst)

            RestAPIService.create(datas, Serviceurls.INTERNALSALES_SEND_REQUEST_LIST)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            openSendDrugPopup: false
                        })
                        this.props.history?.push({ pathname: "/PharmacyInternalSales/RequestList" })
                        this.getPORequestSelectedData();
                        this.successMessage(response?.data?.message);
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(response?.data?.message);
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false);
                    const errorMessage = error?.response?.data?.status === "fail" ? error?.response?.data?.message : error?.message;
                    this.errorMessage(errorMessage);
                });

        } catch (e) {
            this.errorMessage(e?.message);
        }
    };

    renderDatePicker = (label, statekey, openMenu, width, noshow) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={noshow ? false : states[openMenu]}
                        onOpen={() => this.handleOpen(openMenu)}
                        onClose={() => {
                            states[openMenu] = false;
                            this.setState(states)
                        }}
                        inputFormat='dd-MM-yyyy'
                        label={t(label) + " *"}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : null}
                        autoComplete="off"
                        disabled={this.state.isAllDisabled}
                        // minDate={label === 'DeliveryDate' ? new Date(this.state.billDate) : null}
                        // maxDate={label === 'DeliveryDate' ? null : DateTime.now()}
                        onChange={(value) => {
                            states[statekey] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
                            this.setState({
                                states
                            })
                        }}
                        renderInput={(params) => <TextField
                            size="small"
                            onKeyDown={(e) => { e.preventDefault() }}
                            sx={{ width: width ? width : "9.8vw" }}
                            autoComplete="off"
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                emed_tid: label,
                            }}
                            onClick={(e) => this.handleOpen(openMenu)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    handleDelete = (data) => {
        let deletedArr = [...this.state.PharmaInternalSalesRequestList]
        let index = deletedArr?.indexOf(data)
        deletedArr?.splice(index, 1)
        this.setState({
            PharmaInternalSalesRequestList: deletedArr
        })
    }

    handleEdit = (data, index) => {
        data["edit_index"] = index + 1;

        this.setState({
            selectedItem: data,
            selectedIndex: index,
         })
    }
    IsClearEdit = () => {
        this.setState({
            selectedItem: {},
            selectedIndex: null,
         })
    }

    // if (value?.length <= 200 || value === '') {
    //     states[names] = value
    //     this.setState({ states })
    // } else {
    //     this.errorMessage('Allow Only 200 Characters')
    // }
    _validateData = (data) => {
        const { t } = this.props
        let isValid = true;
        let msg = "";      
        if (!data.brand_name) { isValid = false; msg = "Enter Brand name"; }
        else if (!data.generic_name) { isValid = false; msg = "Enter Generic name"; }
        // else if (!data.dosage_type) { isValid = false; msg = "EnterDosagetype"; }
        else if (data.tabValue === "one") {
            if (!data.batch_no) { isValid = false; msg = "Enter Batch number"; }
            else if (!data.expiry_date) { isValid = false; msg = "Enter expiry date"; }
            else if (!data.pack_of_drugs) { isValid = false; msg = "Enter QtyPerUnit"; }
            else if (!data.sales_unit) { isValid = false; msg = "Enter No Of Unit"; }
            else if (!data.mrp_per_pack) { isValid = false; msg = "Enter MRP"; }
            else if (!data.buying_price) { isValid = false; msg = "Enter buying price" }
        }
        else if (data.tabValue === "two") {
            if (!data.free_batch_no) { isValid = false; msg = "EnterBatchnumber"; }
            else if (!data.free_expiry_date) { isValid = false; msg = "Enterexpirydate"; }
            else if (!data.free_qty_per_unit) { isValid = false; msg = "EnterQtyPerUnit"; }
            else if (!data.free_no_of_unit) { isValid = false; msg = "EnterNoOfUnit"; }
            else if (!data.free_mrp_unit) { isValid = false; msg = "EnterMRP"; }
        } else if(!data.tabValue){
            isValid = false; msg = "Enter all Details"; 
        }
        if (!isValid) {
            this.errorMessage((msg))
        }
        return isValid;
    }

    calculateBillAmount = () => {
        try {
            let { PharmaInternalSalesRequestList } = this.state;
            let initialObj = {
                total_buying_price: 0,
                total_gst_amount: 0
            }
            let totalAmount = PharmaInternalSalesRequestList?.reduce((acc, cur, i) => {
                let gst_amount = cur.total_gst_amount ? cur.total_gst_amount : (2 * cur.cgst_amount);
                "serial_no" in cur ? cur["serial_no"] = cur["serial_no"] : cur["serial_no"] = i + 1;
                acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
                acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(gst_amount ? gst_amount : 0);
                return acc;
            }, initialObj)

            let newTotalAmt = 0; let newGST = 0;
            PharmaInternalSalesRequestList?.forEach(element => {
                if (element?.stock_id) {
                    newTotalAmt += (element?.total_buying_price ? element?.total_buying_price : 0)
                    newGST += (element?.total_gst_amount ? element?.total_gst_amount : 0)
                }

            })
            let RoundOfAmt = newTotalAmt ? Math?.round(+newTotalAmt) : 0
            let paiseAmt = Math?.round((RoundOfAmt - +newTotalAmt) * 100)
            let paiseWithSymbol = paiseAmt >= 0 ? `+${paiseAmt}` : `${paiseAmt}`;
            this.setState({
                PharmaInternalSalesRequestList,
                cgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                sgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
                billAmt: newTotalAmt ? newTotalAmt?.toFixed(2) : 0,
                roundofAmt: +RoundOfAmt,
                paisaValue: paiseWithSymbol ? paiseWithSymbol : 0 
            })
            // }
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    renderFields = (label, name, values, marginLeft, marginTop,) => {
        // let disable = disabled ? true : false
        let states = this.state
        return (
            <Box sx={{ marginLeft: marginLeft, marginTop: marginTop }}>
                {label === "Tracking ID" || label === "Mobile Number" || label === "E-Way Bill No" ? <TextField
                    size='small'
                    sx={{ width: "17vw" }}
                    inputProps={{ maxLength: 20 }}  
                    autoComplete='off'
                    value={values}
                    label={label}
                    // disabled={disable}
                    onChange={(e) => {
                        let value = e.target.value
                        let isNum = (CommonValidation?.mobileNumber(value)) && (value?.length<=10)
                        if (label === "Mobile Number") {
                            if (isNum || value === "") {
                                states[name] = value
                            }
                        } else {
                            states[name] = value
                        }
                        // this.onChangeHandler(e, name)
                        this.setState({
                            states
                        })

                    }}
                // InputProps={{
                //     endAdornment: <Typography style={{ backgroundColor: "#f0f0f0", padding:  "1vh 0.5vw" , marginLeft: "11.3vw", position: "absolute", borderRadius: "0vw 0.25vw 0.25vw 0vw", width: '3.6vw' }}>{name}</Typography>,
                //     emed_tid: `Field_${name}`
                // }}
                /> : null}
                {label === "Shipping Method" ? <FormControl size='small'>
                    <InputLabel>Select Shipping Method</InputLabel>
                    <Select
                        // sx={{ width: '25vw', ml: '0.5vw', mb: '0.5vw' }}
                        sx={{ width: "17vw" }}
                        label='Select Shipping Method'
                        value={this.state.selectedShippingMethod}
                        onChange={(e) => {
                            states[name] = e.target.value
                            this.setState({
                                states
                            })
                        }


                        }
                    >
                        {this.state.shippingDetailSelect?.map((item, index) => (
                            <MenuItem key={index} value={item?.value}>{item?.value}</MenuItem>
                        ))}
                    </Select>
                </FormControl> : null}



                {label === "Shipping Date" ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        open={this.state.openDatePicker}
                        onOpen={() => this.setState({ openDatePicker: true })}
                        onClose={() => { this.setState({ openDatePicker: false }) }}
                        inputFormat={"DD-MM-YYYY"}
                        autoComplete="off"
                        sx={{ width: "17vw" }}
                        // InputProps={{
                        //     disableUnderline: true
                        // }}
                        // maxDate={DateTime.now()}
                        value={values}
                        onChange={(newDate) => {
                            states[name] = newDate
                            this.setState({
                                states
                            })
                        }}
                        renderInput={(params) => <TextField {...params}
                            sx={{ width: '17vw' }} size='small' label={label}
                            onClick={() => { this.setState({ openDatePicker: true }) }}
                        />}
                    />
                </LocalizationProvider> : null}

            </Box>
        )
    }


    ShippingDetailPopup = () => {

        return (
            <div>
                <Modal open={this.state.openSendDrugPopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "32vw", height: "64vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'1vw'} fontWeight={600} color={Colors.SecondaryText}>{"Shipping Details"}</Typography>
                            <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openSendDrugPopup: false }, () => {
                                this.setState({
                                    shippingMethod: "self",
                                    mobileNumber: "",
                                    shippingDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
                                    trackingId: "",
                                    e_way_bill_no: "",
                                })
                            })} >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <hr />
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>


                            <Box component={'div'} style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "1.5vw", width: "100%", justifyContent: "center", alignItems: "center" }}>

                                {this.renderFields('Shipping Method', "shippingMethod", this.state.shippingMethod, "1vw", "1vw")}
                                {this.renderFields('Mobile Number', "mobileNumber", this.state.mobileNumber, "1vw", "1vw")}
                                {this.renderFields('Shipping Date', "shippingDate", this.state.shippingDate, "1vw", "1vw")}
                                {this.renderFields('Tracking ID', "trackingId", this.state.trackingId, "1vw", "1vw")}
                                {this.renderFields('E-Way Bill No', "e_way_bill_no", this.state.e_way_bill_no, "1vw", "1vw")}
                            </Box>
                        </Box>

                        <Box>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", marginTop: "1vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.sendDrugDetails() }}
                                >Skip</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', ml: "2vw" }}
                                    // disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.sendDrugDetails(true) }}
                                >Submit</Button>
                            </div>
                        </Box>
                    </Box>

                </Modal>
            </div>
        )
    }


    rejectData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.delete(`${Serviceurls.INTERNALSALES_SEND_REQUEST_LIST}?po_id=${this.state.purchase_order_id}&po_pharmacy_id=${this.state.pharmacyId ? this.state.pharmacyId : null}&cancel_reason=${this.state.comments}`)
                .then(response => {
                    if (response?.data?.status === "success") {
                        this.successMessage(response?.data?.message);
                        this.LoaderFunction(false)
                        this.setState({
                            deletePopup: false,

                            // historyUpdate: true
                        }, () => { this.getPORequestSelectedData() })
                        this.props?.history?.push({ pathname: "/PharmacyInternalSales/RequestList" })
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error?.response?.data?.message);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e?.message, false);
        }
    }

    AddToData = (list, isSelected, tabValue, index) => {
        const { t } = this.props;
        let states = this.state;
        if (list) {

            let alreadyExist = null;
            // states?.PharmaInternalSalesRequestList?.forEach((element,i) => {
            //     if (i=== this.state.selectedIndex) {
            //         element = list
            //     }
            // })
            // alreadyExist = states.PharmaInternalSalesRequestList?.find(item => item.drug_id === list.drug_id && (!list?.is_free_quantity && (item?.batch_no === list?.batch_no || item?.free_batch_no === list?.batch_no)) || (list?.is_free_quantity && (item?.free_batch_no === list?.free_batch_no || item?.batch_no === list?.free_batch_no)) && item.serial_no !== list.serial_no);

            // if (states.EditIndex !== -1 && (list.edit_index === states.EditIndex) && alreadyExist === null) {
            //     states.PharmaInternalSalesRequestList[states.EditIndex - 1] = list;
            // }
            list["tabValue"] = tabValue;
            // states.PharmaInternalSalesRequestList.push(list);
            if (tabValue === "one") {
                states.PharmaInternalSalesRequestList[states.selectedIndex] = list
            } else {
                list["is_free_quantity"] = true
                states.PharmaInternalSalesRequestList?.push(list)
            }
        }
        this.setState({
            states,
            selectedItem: {},
            selectedIndex: null,
            // EditIndex: (index === 0 || index) ? index + 1 : data?.serial_no

        }, () => {
            //  this.calculateBillAmount()
        })
        // if (list) {
        //     let alreadyExist = null;

        //     states?.lftTblData?.forEach(element => {
        //         if (element.drug_id === list.drug_id) {
        //             if ((!list?.is_free_quantity && (element?.batch_no === list?.batch_no || element?.free_batch_no === list?.batch_no)) || (list?.is_free_quantity && (element?.free_batch_no === list?.free_batch_no || element?.batch_no === list?.free_batch_no))) {
        //                 if (element?.serial_no !== list?.serial_no) {
        //                     alreadyExist = element
        //                 }
        //             }
        //         }
        //     })
        //     // let alreadyExist = states.lftTblData?.find(item => item.drug_id === list.drug_id && (!list?.is_free_quantity && (item?.batch_no === list?.batch_no || item?.free_batch_no === list?.batch_no)) || (list?.is_free_quantity && (item?.free_batch_no === list?.free_batch_no || item?.batch_no === list?.free_batch_no)) && item.serial_no !== list.serial_no);

        //     if (states.EditIndex !== -1 && (list.edit_index === states.EditIndex) && alreadyExist === null) {
        //         states.lftTblData[states.EditIndex - 1] = list;
        //     } 
        // else if (states.isNewBatch) {
        //         delete list.stock_id;
        //         list["newBatch"] = true;
        //         states.lftTblData.push(list);
        //     } else if ((states.EditIndex === -1 && alreadyExist) || alreadyExist) {
        //         this.errorMessage(`${alreadyExist?.brand_name} Same Batch Number ${t("AlreadyExist")}`)
        //     } else {
        //         list["tabValue"] = tabValue;
        //         states.lftTblData.push(list);
        //     }
        //     this.setState({
        //         states,
        // isselectedLineItem: isSelected,
        // isNewBatch: isSelected,
        // EditIndex: -1,
        // selectedData: {}
        // }, () => {





        //     this.calculateBillAmount()
        // let { lftTblData } = this.state;
        // let initialObj = {
        //     total_buying_price: 0,
        //     total_gst_amount: 0
        // }
        // let totalAmount = lftTblData?.reduce((acc, cur, i) => {
        //     let gst_amount = cur.total_gst_amount ? cur.total_gst_amount : (2 * cur.cgst_amount);
        //     "serial_no" in cur ? cur["serial_no"] = cur["serial_no"] : cur["serial_no"] = i + 1;
        //     acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
        //     acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(gst_amount ? gst_amount : 0);
        //     return acc;
        // }, initialObj)

        // let newTotalAmt = 0; let newGST = 0;
        // lftTblData?.forEach(element => {
        //     if (!element?.stock_id || (element.stock_id && element.addDCtoPO)) {
        //         newTotalAmt = newTotalAmt + (element?.total_buying_price ? element?.total_buying_price : 0)
        //         newGST = newGST + (element?.total_gst_amount ? element?.total_gst_amount : 0)
        //     }
        // })
        // if (this.state.fromGrnLine) {
        //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+totalAmount.total_buying_price + (this.state.paisa / 100)) :
        //         (+totalAmount.total_buying_price - (this.state.paisa / 100)) : +totalAmount.total_buying_price
        //     this.setState({
        //         lftTblData,
        //         cgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
        //         sgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
        //         billAmt: totalAmount.total_buying_price ? totalAmount.total_buying_price.toFixed(2) : 0,
        //         roundofAmt: +Bill_Amt.toFixed(2),
        //         taxableAmt: totalAmount.total_buying_price ? (+totalAmount.total_buying_price - +totalAmount.total_gst_amount)?.toFixed(2) : 0
        //     })
        // } else {
        //     let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+newTotalAmt + (this.state.paisa / 100)) :
        //         (+newTotalAmt - (this.state.paisa / 100)) : +newTotalAmt
        //     this.setState({
        //         lftTblData,
        //         cgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
        //         sgstAmt: newGST ? +(newGST / 2)?.toFixed(2) : "0.00",
        //         billAmt: newTotalAmt ? newTotalAmt?.toFixed(2) : 0,
        //         roundofAmt: +Bill_Amt.toFixed(2),
        //         taxableAmt: newTotalAmt ? (+newTotalAmt - +newGST)?.toFixed(2) : 0
        //     })
        // }
        //     })
        // }
    }


    TextBox = (names, values, labels, width, mr, mt, onlyRead, placeholder, index) => {
        try {
            let states = this.state
            return (
                <TextField
                    autoComplete='off'
                    size='small'
                    sx={{ width: width, mr: mr, mt: mt }}
                    disabled={this.state.isAllDisabled}
                    value={values ? values : ''}
                    label={labels}
                    name={names}
                    onChange={(e, names) => {
                        let value = e.target.value
                        if (value?.length <= 200 || value === '') {
                            states[names] = value
                            this.setState({ states })
                        } else {
                            this.errorMessage('Allow Only 200 Characters')
                        }
                    }
                    }
                // InputProps={{ readOnly: onlyRead }}
                // inputProps={{"aria-readonly":onlyRead}}
                />
            )
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }
   
    renderDeleteCell = (data) => {
        return (
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                <CommonDeleteButton testID="drt_btc_dlt" size="1.5vw" disable={this.state.isAllDisabled} onClick={() => this.handleDelete(data)} />
            </Stack>
        )
    }
    renderEditCell = (data, index) => {
        return (
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                <CommonEditButton testID="drt_dlv_edit" disable={this.state.isAllDisabled} size="1.5vw" onClick={() => { this.handleEdit(data, index) }} />
            </Stack>
        )
    }


    render() {
        const { t } = this.props;        
        return (
            <Box margin={"1vh"} display={"flex"} flexDirection={"row"} gap={"1vw"} marginTop={"1vh"} width={"100%"} sx={{ backgroundColor: Colors.Background }}>
                <Box display={"flex"} flexDirection={"column"}  height={"80vh"} width={"60%"}>
                    <Box  width={"100%"} height={"5vh"}>
                        <Box className="eMed_Add_vendor_page_top2" marginBottom={"3vh"}>
                            <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" emed_tid="addvnd_bk" onClick={() => {
                                let propsData = this.props.history?.location?.state?.wantedData
                                this.state.isFromWanted ? this.props.history.push({ pathname: '/PharmacyInternalSales/WantedList/POEntry',state: {pharmacy_id: propsData?.pharmacy_id, OnlyView: propsData?.OnlyView, branch_name: propsData?.branch_name, pharmacy_name: propsData?.pharmacy_name  }}) : this.props?.history?.push({ pathname: '/PharmacyInternalSales/RequestList' })
                            }} >
                                <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {this.state.isFromWanted ? t("Back To Wanted List") : t("Back To Request List  ")}</Typography>
                            </Stack>
                        </Box>
                    </Box>
                    <Box  width={"100%"} height={"20vh"}>
                        <Paper id={"eMed_pharmadrctpo_paper1"} elevation={1}>
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}  >
                                <Box >
                                    <Typography sx={{ fontSize: '1vw', fontWeight: "600", margin: "1vh" }} >{"Request Details"}</Typography>
                                </Box>
                                <Box>
                                    {this.state.isAllDisabled ? null : 
                                   
                                        <Button variant="outlined" onClick={()=>{
                                            this.setState({   deletePopup: true})
                                        }} >
                                          {"Reject"}
                                        </Button>
                                    }

                                    {/* {this.state.isAllDisabled ? null : this.MoreMenus()} */}
                                </Box>

                            </Box>
                            <Divider orientation="horizontal" />

                            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} marginTop={"1vh"}>
                                <Stack direction="row" justifyContent={"flex-start"} alignItems={"center"} spacing={1} sx={{ height: "7vh" }}>
                                    {this.TextBox("vendorName", this.state.vendorName, "Vendor Name", "15vw")}
                                    {/* {this.TextBox("ISNO", this.state.ISNO, "IS NO", "15vw")} */}
                                    {this.renderDatePicker("IS Date", "ISDate", true, "15vw", "")}
                                </Stack>

                            </Box>
                        </Paper>

                    </Box>
                    <Box width={"100%"} height={"50vh"}>
                    <Paper elevation={1} sx={{ backgroundColor: Colors.white, height: "58vh", borderTopLeftRadius: "0.3", borderTopRightRadius: "0vw", borderBottomLeftRadius: "0.3vw", borderBottomRightRadius: "0vw" }} >
                                <Box sx={{ backgroundColor: 'white', height: '51vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <TableContainer height={"50vh"} overflow={"auto"} >
                                        <Table stickyHeader size='small' >
                                            <TableHead className="eMed_pharmadrctpo_tblhead" sx={{ height: "5vh" }}>
                                                <TableRow>
                                                    <TableCell width={'5%'} id="eMed_pharmadrctpo_tblheadTxt"> {<Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
                                                        <CommonDeleteButton testID="drt_btc_dlt" disable={this.state.isAllDisabled ? true : false} size="1.5vw" onClick={() => { this.handleDelete() }} />
                                                    </Stack>}</TableCell>
                                                    <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("ProductDetails")}</TableCell>
                                                    <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("GST %")}</TableCell>
                                                    <TableCell width={'15.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("Request Quantity")}</TableCell>
                                                    <TableCell width={'12.11%'} id="eMed_pharmadrctpo_tblheadTxt"> {t("Sent Quantity")}</TableCell>
                                                    <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t("Free Quantity")}</TableCell>
                                                    <TableCell width={'12.11%'} id="eMed_pharmadrctpo_tblheadTxt"> {t("Selling Price/Qty")}</TableCell>
                                                    <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt">{t(`MRP (${CurrencySymbol})/Qty`)}</TableCell>
                                                    <TableCell width={'11.11%'} id="eMed_pharmadrctpo_tblheadTxt" align='center'>{t("Action")}</TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.PharmaInternalSalesRequestList?.map((data, index) => {
                                                         let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * +data.free_qty_per_unit)
                                                        : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                        let sentQty = data?.sales_unit * data?.pack_of_drugs
                                                        let today = (data.tabValue === "two" && data.free_expiry_date) ? new Date(data.free_expiry_date) : data.expiry_date ? new Date(data.expiry_date) : "";
                                                        let expiry_date = today ? DateTime?.fromJSDate(today)?.toFormat("MM-yyyy") : null;
                                                        let batchNo = data.tabValue === "two" ? `Batch No.:${data.free_batch_no}` : `Batch No.:${data.batch_no}`
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {this.renderDeleteCell(data, index)}
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    <Stack direction="column">
                                                                        <Typography component={'div'} id="eMed_pharmadrctpo_tblbodyTxt" color={'black'}>{data?.brand_name?.length > 11 ?
                                                                            <Tooltip placement="top" title={data?.brand_name} arrow><div style={{ color: 'black' }} id="eMed_pharmadrctpo_tblbodyTxt">{data?.brand_name?.slice(0, 11) + ".."}</div></Tooltip>
                                                                            : data?.brand_name ? <Typography component={'span'} color={'black'} id="eMed_pharmadrctpo_tblbodyTxt">{data?.brand_name}</Typography> : "-"}</Typography>
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">{data?.generic_name?.length > 6 ?
                                                                            <Tooltip placement="top" title={data?.generic_name} arrow><div style={{ fontSize: '0.9vw' }}>{data?.generic_name?.slice(0, 6) + "..."}</div></Tooltip>
                                                                            : data?.generic_name ? data?.generic_name : "-"}</Typography>
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">{data?.dosage_type + "|" + data?.dosage_strength}</Typography>

                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    {data?.gst_percentage ? data?.gst_percentage : 0}
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    {data?.quantity ? data?.quantity : 0}
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    <Box display={'flex'} flexDirection={'column'}>
                                                                        {data.tabValue === "two" ? 0 : sentQty ? sentQty : 0}
                                                                        {data.tabValue === "two" ? null : data?.batch_no ?
                                                                            data?.batch_no?.length > 10 ? <Tooltip placement='top' title={data?.batch_no}><Typography id="eMed_pharmadrctpo_tblTxt">{data?.batch_no?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                                                                <Typography id="eMed_pharmadrctpo_tblTxt">{data?.batch_no}</Typography> : null
                                                                        }
                                                                        {data.tabValue === "two" ? null : data?.expiry_date ? <Typography id="eMed_pharmadrctpo_tblTxt">Exp Date: {data?.expiry_date}</Typography> : null}

                                                                        {/* {this.state.tabValue === "two" ? '' : data?.batch_no ? (<Typography fontSize={'0.7rem'} display={'flex'}>Batch no: {
                                                                            data?.batch_no?.length > 6 ? (
                                                                                <Tooltip placement="top" title={data?.batch_no} arrow>
                                                                                    <div style={{ fontSize: '0.7rem' }}>{data?.batch_no?.slice(0, 3) + '...'}</div>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                data?.batch_no
                                                                            )}
                                                                        </Typography>) : null} */}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    <Box display={'flex'} flexDirection={'column'}>
                                                                        {freeQty ? freeQty : 0}
                                                                        {freeQty ? (data?.batch_no || data?.free_batch_no) ?
                                                                            batchNo?.length > 10 ? <Tooltip placement='top' title={batchNo}><Typography id="eMed_pharmadrctpo_tblTxt">{batchNo?.slice(0, 10) + '...'}</Typography></Tooltip> :
                                                                                <Typography id="eMed_pharmadrctpo_tblTxt">{batchNo}</Typography> : null : null
                                                                        }
                                                                        {freeQty ? expiry_date ? <Typography id="eMed_pharmadrctpo_tblTxt">Exp Date: {expiry_date}</Typography> : null : null}
                                                                        {/* {data?.batch_no ? (<Typography fontSize={'0.7rem'} display={'flex'}>Batch no: {
                                                                            data?.batch_no?.length > 6 ? (
                                                                                <Tooltip placement="top" title={data?.batch_no} arrow>
                                                                                    <div style={{ fontSize: '0.7rem' }}>{data?.batch_no?.slice(0, 3) + '...'}</div>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                data?.batch_no
                                                                            )}
                                                                        </Typography>) : null} */}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    {data?.buying_price ? data?.buying_price : 0}
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    {data?.rate_per_unit ? data?.rate_per_unit : 0}
                                                                </TableCell>
                                                                <TableCell id="eMed_pharmadrctpo_Txtclr">
                                                                    {this.renderEditCell(data, index)}
                                                                </TableCell>

                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>

                                        </Table>

                                    </TableContainer>
                                    <Box sx={{ width: '56vw', display: 'flex', justifyContent: 'center', backgroundColor: '#FAF9F9', marginBottom: "-3vh" }} >
                                        {/* <Button variant='contained' size='small' sx={{ textTransform: 'capitalize', width: '8vw', marginRight: "2vw" }}
                                            onClick={() => {

                                            }}
                                        >
                                            {"Save"}
                                        </Button> */}
                                        <Button variant='contained' size='small'  sx={{ textTransform: 'capitalize', width: '10vw'}}
                                            // disabled={enableBtn ? false : true}
                                            disabled={this.state.isAllDisabled}
                                            onClick={() => {
                                                   this.calculateBillAmount()
                                                     const allValid  = this.state.PharmaInternalSalesRequestList.every((data)=>{
                                                    // let isValid = this._validateData(data);
                                                    return this._validateData(data);

                                                })
                                                if (allValid) {
                                                    this.setState({
                                                        openSendDrugPopup: true
                                                    });
                                                } 

                                                // this.sendDrugDetails()
                                                // this.setState({
                                                //     openSendDrugPopup: true
                                                // })

                                                // let CheckedRow = this.state.lftTblData.filter((list) => (list?.rowCheck))
                                                // let BillAmt = 0
                                                // let CGST = 0
                                                // let SGST = 0
                                                // CheckedRow?.forEach((item, index) => {
                                                //     BillAmt += item?.total_buying_price
                                                //     CGST += item?.cgst_amount
                                                //     SGST += item?.sgst_amount
                                                // })
                                                // let PropData = {
                                                //     line_items: CheckedRow,
                                                //     vendor_name: this.state?.vendor_name,
                                                //     vendor_id: this.state?.vendor_id,
                                                //     billAmt: BillAmt,
                                                //     sgstAmt: SGST,
                                                //     cgstAmt: CGST,
                                                //     dc_number: this.state?.dc_number,
                                                //     dc_data: this.state?.dc_data,
                                                // }
                                                // this.props.history.push(
                                                //     {
                                                //         pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO"
                                                //         , state: {
                                                //             propsFromDc: PropData,
                                                //             PoFromDc: true,
                                                //             fromGrn:true,
                                                //             notShowFinish:true
                                                //         }
                                                //     })
                                            }}
                                        >{"Send Drug"}</Button>
                                    </Box>
                                </Box>
                            </Paper>
                    </Box>

                </Box>
                <Box display={"flex"} flexDirection={"column"}  height={"80vh"} width={"40%"} marginTop={"4.2vh"} marginRight={"0.5vw"}>
                    <Box  width={"100%"} height={"80vh"}>
                        <Box sx={{ height: '80vh', overflow: 'hidden' }}>
                            <PharmaISRequestDetailRight
                                editData={this.state.selectedItem}
                                selectedIndexData={this.state.selectedIndex}
                                errorMessage={this.errorMessage.bind(this)}
                                successMessage={this.successMessage.bind(this)}
                                LoaderFunction={this.LoaderFunction.bind(this)}
                                alteredData={this.alteredData.bind(this)}
                                sendToList={this.AddToData.bind(this)}
                                IsClearEdit={this.IsClearEdit.bind(this)}
                                isAllDisabled={this.state.isAllDisabled ? this.state.isAllDisabled : false}
                            />
                        </Box>
                    </Box>
                </Box>
                

                 {this.ShippingDetailPopup()}
                 {this.state.deletePopup ? <ReasonPopup
                    title={"Are you sure you want to cancel the Bill"}
                    AlertPopupClose={this.closePopup.bind(this)}
                    label={"Enter the Cancel Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btnvarient1={'contained'}
                    from = {"IS request"}
                    sendCmt={this.addCancelComments.bind(this)} /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaISRequestDetailLeft)
