import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let selectedStatus = ["Active", "Inactive"]
let RoleData = null;
let userAccess = null;
class DepartmentMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      depMasterData: [],
      SelectDepartment: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      selected_status: "",
      openPopUp: false,
      departmentcode: null,
      departmentName: "",
      order: "",
      shortName: "",
      deptStatus: true,
      isFromEdit: false,
      selectedRow: {},
      comments: "",
      selectedId: "",
      postLoad : false,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Department Master', "SubTab");
    this.getDepartmentMaster()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  handleValidation = () => {
    if (!this.state.departmentName) {
      this.errorMessage("Enter Department Name")
      this.setState({ postLoad: false })
    } else if (this.state.deptStatus === false && !this.state.comments) {
      this.errorMessage("Enter Inactive Reason")
      this.setState({ postLoad: false })
    } else if (this.state.isFromEdit && !this.state.order) {
      this.errorMessage("Enter Print Order")
      this.setState({ postLoad: false })
    } else {
      this.postDepartmentMaster()
    }
  }
  postDepartmentMaster = () => {
    try {
      let status = this.state.selected_status ? true : false
      this.LoaderFunction(true);
      let data = {
        "dept_name": this.state.departmentName ? CommonValidation.removeSpace(this.state.departmentName) : "", 
        "is_active": this.state.deptStatus ? this.state.deptStatus : false,
        "print_order": this.state.order ? this.state.order : "",
        "dept_short_name": this.state.shortName ? CommonValidation.removeSpace(this.state.shortName) :"",
        "dept_code": this.state.departmentcode ? this.state.departmentcode: null,
        "reason": this.state.comments ? CommonValidation.removeSpace(this.state.comments): "",
        "id": this.state.selectedId,
      }
      RestAPIService.create(data, Serviceurls.GET_LAB_DEPARTMENT_MASTER).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.LoaderFunction(false);
            this.setState({ 
              openPopUp: false, 
              isFromEdit: false, 
              departmentcode: "",
              selectedId: "",
              postLoad :false })
            this.handleClear()
            this.getDepartmentMaster()
          }
          else {
            this.LoaderFunction(false);
            this.setState({postLoad :false})
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({postLoad :false})
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
              this.setState({postLoad :false})
          } else {
            this.errorMessage(error.message)
          }
          this.LoaderFunction(false);
        })
    }
    catch (e) {
      this.setState({postLoad :false})
      this.errorMessage(e.message)
    }
  }

  getDepartmentMaster = () => {
    try {
      let status = this.state.selected_status === "Active" ? true : this.state.selected_status === "Inactive" ? false : ""
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.GET_LAB_DEPARTMENT_MASTER + `?dept_name=${this.state.SelectDepartment}&is_active=${status}`).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              depMasterData: response?.data?.data ? response?.data?.data : [],
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response?.data?.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
          this.LoaderFunction(false);
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
  handleClear = () => {
    this.setState({
      departmentName: "",
      order: "",
      shortName: "",
      deptStatus: true,
      comments: "",
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid="vnd_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("Search Department")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              value={states.SelectDepartment ? states.SelectDepartment : ""}
              inputProps={{ emed_tid: "departmentName" ,maxLength:100 }}
              onChange={(e) => {
                this.setState({
                  SelectDepartment: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              disableClearable
              options={selectedStatus}
              getOptionLabel={(option) => (option)}
              value={this.state.selected_status}
              onChange={(event, newValue) => {
                this.setState({ selected_status: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                  label={t('Select Status')}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid="fil_clear"
            onClick={() => {
              this.setState({
                selected_status: "",
                SelectDepartment: "",
              }, () => this.getDepartmentMaster())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid="fil_search"
            onClick={() => {
              this.getDepartmentMaster()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  handleEdit = (data) => {
    this.setState({
      isFromEdit: true,
      openPopUp: true,
      departmentcode: data?.dept_code ? data?.dept_code : null,
      departmentName: data?.dept_name ? data?.dept_name :"",
      order: data?.print_order,
      shortName: data?.dept_short_name ? data?.dept_short_name :"" ,
      selectedId: data?.id,
      deptStatus: data?.is_active,
      comments: data?.reason ? data?.reason :"",
    })

  }
  renderTextField = (value, statekey, Label, required, width, disable = false) => {
    const { t } = this.props;
    let states = this.state
    return (
      <TextField
        sx={{ width: width ? width : "12vw", marginX: '0.65vw', marginTop: "1vw" }}
        size="small"
        disabled={disable}
        inputProps={{ maxLength: statekey === "order" ? 3 : 100 }}
        autoComplete="off"
        label={t(Label)}
        required={required}
        value={value}
        onChange={(e) => {
          let value = e.target.value
          if (statekey === "order") {
            if (CommonValidation.numberOnly(value) || value === "") {
              states[statekey] = value.trimStart()
              this.setState({ states })
            }
          } else {
            states[statekey] = value.trimStart()
            this.setState({ states })
          }
        }}
      />
    )
  }
  addNewDepartPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.openPopUp}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "42vw", height: "63vh", maxHeight: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box  >
              <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit Department" : "Add Department"}</Typography>
                <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openPopUp: false, isFromEdit: false,departmentcode: "", selectedId: "" }, () => { this.handleClear() })} >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <hr />
              <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <Box  >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                      {this.state.isFromEdit ? this.renderTextField(this.state.departmentcode, "departmentcode", "Department Code", true, "18.5vw", true) : null}
                      {this.state.isFromEdit ? null :this.renderTextField(this.state.departmentName, "departmentName", "Department Name", true,this.state.isFromEdit? "12vw":"18.5vw" )}
                      {this.state.isFromEdit ?this.renderTextField(this.state.order, "order", "Print Order", true, "18.5vw"):null}
                      {this.state.isFromEdit ? null : this.renderTextField(this.state.shortName, "shortName", "Short Name", false, this.state.isFromEdit? "18.5vw":"18.5vw")}
                    </div>
                    {<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw" }}>
                      {this.state.isFromEdit ? this.renderTextField(this.state.shortName, "shortName", "Short Name", false,this.state.isFromEdit? "18.5vw":"18.5vw") : null}
                      {this.state.isFromEdit ? this.renderTextField(this.state.departmentName, "departmentName", "Department Name", true,this.state.isFromEdit? "18.5vw":"18.5vw" ):null}
                    </div>}
                  </Box>
                </Box>
              </Box>
              {!this.state.isFromAdd ? 
                <Box component={'div'} style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                    <Typography>{"Status"}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <Checkbox
                      disabled ={!this.state.isFromEdit}
                      checked={this.state.deptStatus}
                      onChange={(e) => { this.setState({ deptStatus: e.target.checked ,comments :"" }) }}
                    />
                    <Typography>{"Active"}</Typography>
                  </Box>
                </Box> :null}
              {!this.state.isFromAdd && this.state.deptStatus === false ?
                <Box>
                  <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                    <Typography>{"Inactive Comments"}</Typography>
                  </Box>
                  <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                    <div>
                      <TextField
                        size='small'
                        label={("Reason *")}
                        inputProps={{ maxLength: 250 }}
                        disabled ={!this.state.isFromEdit}
                        style={{ marginTop: "0.5vw", width: "38.6vw" }}
                        multiline={true}
                        rows={3}
                        variant="outlined"
                        value={this.state.comments}
                        onChange={(event) => {
                          let value = event.target.value
                          if (value?.length <= 250) {
                            this.setState({ comments: value })
                          } else {
                            this.errorMessage('Allowed only 250 characters')
                          }
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                : null
              }
            </Box>

            <Box>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => { this.handleClear() }}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  disabled={this.state.postLoad}
                  variant='contained'
                  onClick={() => {
                    this.setState({postLoad : true},()=>{this.handleValidation()})
                  }}
                >{states.isFromEdit ? "Update" : "Add"}</Button>
              </div>
            </Box>
          </Box>

        </Modal>
      </div>
    )
  }
  gridToolBar = () => {
    var ExportData = []
    this.state.depMasterData?.length > 0 && this.state.depMasterData?.map((item) => {
    return (
    ExportData?.push({"SI.No":item?.sno,"Department Code":item?.dept_code,"Department":item?.dept_name,"Print Orde":item?.print_order,"Short Name":item?.dept_short_name,  "Status": item?.is_active === true ? "Active" :item?.is_active ===  false ?  "InActive" :"" , 
      })
    )
    }
    )
    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Department Master"} filename={"Department Master"} />
      </Box>

    )
  }

  renderTable = () => {
    this.state.depMasterData?.forEach((element, index) => element.sno = index + 1)

    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "dept_code", flex: 0.166, headerName: t("Department Code"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.dept_code ? params?.row?.dept_code : "-"}</Box>)
      },
      {
        field: "dept_name", flex: 0.186, headerName: t("Department"), headerAlign: "left", align: "left",
        renderCell: (params) => (<div>{params?.row?.dept_name?.length > 25 ?
          <Tooltip placement="top" title={params?.row?.dept_name} arrow><div>{params?.row?.dept_name.slice(0, 22) + "..."}</div></Tooltip>
          : params?.row?.dept_name ? params?.row?.dept_name : "-"}</div>)
      },
      {
        field: "print_order", flex: 0.146, headerName: t("Print Order"), headerAlign: "right", align: "right",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.print_order ? params?.row?.print_order : "-"}</Box>)
      },
      // {
      //   field: "dept_short_name", flex: 0.166, headerName: t("Short Name"), headerAlign: "left", align: "left",
      //   renderCell: (params) => (<Box component={'div'}>{params?.row?.dept_short_name ? params?.row?.dept_short_name : "-"}</Box>)
      // },
      {
        field: "dept_short_name", flex: 0.186, headerName: t("Short Name"), headerAlign: "left", align: "left",
        renderCell: (params) => (<div>{params?.row?.dept_short_name?.length > 10 ?
          <Tooltip placement="top" title={params?.row?.dept_short_name} arrow><div>{params?.row?.dept_short_name.slice(0, 15) + "..."}</div></Tooltip>
          : params?.row?.dept_short_name ? params?.row?.dept_short_name : "-"}</div>)
      },
      {
        field: "is_active", flex: 0.166, headerName: t("Status"), headerAlign: "left", align: "left",
        renderCell: (params) => {
          return (
             <Tooltip placement="top" title={params?.row?.reason} arrow><div>{params?.row?.is_active ? "Active" : "InActive"}</div></Tooltip>  
          )
        }
      },

      {
        field: "action", flex: 0.134, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          { userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" arrow>
            <Button emed_tid="vnd_edit" className='eMed_usrconf_btn' onClick={() => { this.handleEdit(params?.row) }} >
              <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' sx={{ backgroundColor: Colors.Background }}>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.depMasterData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              loading={this.state.isLoader}
              rowCount={this.state.depMasterData?.length}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
            />
          </Paper>
        </Stack>
      </Box>
    )
  }
  render() {
    const { t } = this.props;
    return (
      <div style={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top' style={{ backgroundColor: Colors.Background }}>
          {userAccess?.editAccess ? <><Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.setState({ openPopUp: true }) }}>{`Add New Department`}</Button>
            <Divider orientation='vertical' /></> : null}
          <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid="vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip>

        </div>
        {this.renderTable()}
        {this.addNewDepartPopup()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}

      </div>
    )
  }
}
export default withTranslation()(DepartmentMaster)





