import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, Paper, Tooltip, Stack, Typography, Drawer, FormControlLabel, Switch, TextField} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterText, CommonPatientDetails} from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';
import { CurrencySymbol } from '../../../Utility/Constants';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';


class CanteenCreditBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creditBillData: [],
      totalBill: '',
      totalPaid: '',
      totalPayable: '',
      page: 0,
      pageSize: 10,
      isLoader: false,
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      is_bill_wise: true,
      AccNo: '',
      PatientName: '',
      MobNo: '',
      isErrorMsg: false,
      isErrorMsgText: '',
      CreditBillPopUp: false,
      successMsg: false,
      successMsgText: ''
    }
  }

  componentDidMount() {
    this.getCanteenCreditBill()
  }

  getCanteenCreditBill = () => {
    try {
      let states = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_CREDITBILL + `?from_date=${states.fromDate}&to_date=${states.toDate}&is_bill_wise=${states.is_bill_wise}&patient_name=${states.PatientName}&uhid=${states.AccNo}&mob_no=${states.MobNo}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              creditBillData: response.data.data,
              totalBill: response.data?.no_of_bills,
              totalPaid: response.data?.total_paid,
              totalPayable: response.data?.total_payable,
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }
  successMessage = (msg) => {
    this.setState({
      successMsg: true,
      successMsgText: msg
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box className='eMed_Canteen_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Tooltip>
            <Button onClick={() => { this.setState({ filterOpen: false }) }}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
            </Button>
          </Tooltip>
        </Box>
        <Box className='eMed_Canteen_fil_content'>
          <Box className='eMed_Canteen_Date'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingY: '0.5vw', paddingLeft: "1vw" }}>
            <label style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>Admission Wise</label>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  size='small'
                  color="primary"
                  checked={this.state?.is_bill_wise ? true : false}
                  onChange={(e) => {
                    this.setState({
                      is_bill_wise: e.target.checked
                    })
                  }} />}
              label="Bill Wise"
              labelPlacement="end"
            />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <TextField
              inputProps={{ maxLength: 30 }}
              className="eMed_Bill_Filter_TextFeild_small"
              size='small'
              sx={{ marginRight: '1vw' }}
              label={t("PatientName")}
              value={this.state.PatientName}
              onChange={(e) => {
                let number = CommonValidation.alphabetOnly(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ PatientName: e.target.value })
                }

              }}
            />
            <TextField
              inputProps={{ maxLength: 15 }}
              className="eMed_Bill_Filter_TextFeild_small"
              size='small'
              label={t("AccNo")}
              value={this.state.AccNo}
              onChange={(e) => {
                let number = CommonValidation.gstnumberValidation(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ AccNo: e.target.value.toUpperCase() })
                }
              }}
            />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <TextField
              inputProps={{ maxLength: 10 }}
              className="eMed_Bill_Filter_TextFeild_small"
              size='small'
              sx={{ marginRight: '1vw' }}
              label={t("MobNo")}
              value={this.state.MobNo}
              onChange={(e) => {
                let number = CommonValidation.numberOnly(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ MobNo: e.target.value })
                }
              }}
            />
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
            <Button size='small' variant='contained' className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
          </Stack>
        </Box>
      </Box>
    )
  }
  searchFilter = () => {
    this.getCanteenCreditBill()
    this.setState({ filterOpen: false })
  }
  clearFilter = () => {
    this.setState({
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      is_bill_wise: true,
      AccNo: '',
      PatientName: '',
      MobNo: '',
    }, () => {
      this.getCanteenCreditBill()
    })
  }

  closeFilter = () => {
    this.setState({ filterOpen: false })
  }
  renderTbl = () => {
    const { t } = this.props
    this.state.creditBillData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "patient_name", headerName: "Patient Name", flex: 0.20, type: 'string',
        renderCell: (params) => (
          <Box>
            <CommonPatientDetails disablePhoto={true} data={params?.row} />
          </Box>)
      },
      {
        field: "date", headerName: "Date", flex: 0.15, align: "center", headerAlign: "center",
        renderCell: (params) => (params?.row?.date ? params?.row?.date : '-')
      },
      {
        field: "ip_number", headerName: "IP Number", flex: 0.15, type: "number",
        renderCell: (params) => {
          let BillNumber = params?.row?.ip_number ? (params?.row?.ip_number) : "-";
          const billArr = BillNumber ? BillNumber.split("/") : [];
          return (
            <Tooltip placement='top' title={BillNumber} arrow>
              <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr.slice(-2).join("/") : "-"}</Box>
            </Tooltip>
          )
        }
      },
      {
        field: "credit_type", headerName: "Credit Type", flex: 0.15, type: "string",
        renderCell: (params) => (
          <Box component={'div'}>
            {params?.row?.credit_type ? <CommonCorporateAndInsuranceDetails data={params?.row} /> : "-"}
          </Box>
        )
      },
      {
        field: "amount", headerName: `Total Amount (${CurrencySymbol})`, flex: 0.12, type: 'number',
        renderCell: (params) => (AmountFormat(params?.row?.amount || 0)?.replace(`${CurrencySymbol}`, ""))
      },
      {
        field: "pending_amount", headerName: `Pending Amount (${CurrencySymbol})`, flex: 0.12, type: 'number',
        renderCell: (params) => (AmountFormat(params?.row?.pending_amount || 0)?.replace(`${CurrencySymbol}`, ""))
      },
      {
        field: "action", headerName: "Action", flex: 0.11, align: "center", headerAlign: "center",
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={"Complete Bill"} placement="top" arrow>
            <Button onClick={() => { this.setState({ CreditBillPopUp: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn'>
              <img src={ImagePaths.CreateBill.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
        </Box>
      },
    ]
    return (
      <Box height={'64vh'}>
        <DataGrid
          rows={this.state.creditBillData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          hideFooterSelectedRowCount
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          headerHeight={40}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowsPerPageOptions={[10, 20, 30]}
          disableSelectionOnClick
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
    })
  }
  CrebillPosted(message = "", rec = null, phrrec = null, error = false) {
    this.setState({
      CreditBillPopUp: false,
    }, () => {
      if (error) {
        this.errorMessage(message)
      } else {
        this.successMessage(message)
        this.getCanteenCreditBill()
      }
    })
  }
  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      CreditBillPopUp: false,
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
        this.getCanteenCreditBill()
      }
    })
  }

customFooter = () => {
  var { t } = this.props
  return (
      <Box component={'div'} className='eMed_billview_table_footer'>
          <Box component={'div'} position={'relative'} left={"1.1vw"}>
              <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                  <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.85vw" }}>{t("SubAmount")}</Typography>
                  <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{`${CurrencySymbol} `} </Typography>
              </Box>
              <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                  <Typography mt={0.5} fontSize={"0.85vw"} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("concession") + " %"}</Typography>
                  <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{12}</Typography>
                  <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{`${CurrencySymbol} ` + 12}</Typography>
              </Box>
              <Box component={'div'} className='eMed_bill_view_tbl_footer' borderTop={"2px solid gray"}>
                  <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }} fontWeight='600'>{t("TotalAmount")}</Typography>
                  <Typography mt={0.5} fontSize={"0.8vw"} mr={'0.8vw'}>{`${CurrencySymbol} ` + 12}</Typography>
              </Box>
          </Box>
      </Box>
  )
}

  render() {
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Canteen_TopCon'>
          <Box className='eMed_Canteen_CardCon'>
            {AmountsCard('Total Bill', this.state.totalBill ? this.state.totalBill : 0, true, "white", false, null, 'camteen_no_bill')}
            {AmountsCard('Total Payable', this.state.totalPayable ? this.state.totalPayable : 0, false, "white", false, null, 'canteen_no_payable')}
          </Box>
          <Box className='eMed_Canteen_FilterCon'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button onClick={() => { this.setState({ filterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
            {/* <Box sx={{ borderRight: '1px solid #616161', height: '2vw', marginBottom: '.4vw' }}></Box> */}
            {/* <Tooltip title="Print" placement='top' arrow>
              <Button>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_Canteen_img' />
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
        <Paper className='eMed_Canteen_SecondCon' elevation={2}>
          {this.renderTbl()}
        </Paper>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
        {
          this.state.CreditBillPopUp ?
            <CreditBillCompletePoP
              modules={['Canteen']}
              Module_ID_Key_Name={this.state.SelectedBill?.bill_types === "Out Patient" ? "appointment_id" : this.state.SelectedBill?.bill_types === "In Patient" ? "ip_admission_id" : "bill_id"}
              Module_ID_Key_Value={this.state.SelectedBill?.ip_admission_id}
              BillData={this.state.SelectedBill}
              CrebillPosted={this.CrebillPosted.bind(this)}
              ClosePopUp={this.ClosePopUp.bind(this)}
              CollectedIn={"CANTEEN"}
              Bill_Id={this.state.FilterDatas?.is_bill_wise ? this.state.SelectedBill?.id : null}
            />
            : null
        }
      </Box>
    )
  }
}
export default withTranslation()(CanteenCreditBill);