import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./Billings.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../../Styles/Colors';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CircularProgress from '@mui/material/CircularProgress';
import { CurrencySymbol } from '../../../Utility/Constants';

let today = new Date();
class CreditBillPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            BillData: this.props.BillData ? this.props.BillData : {},
            PaymentDate: new Date(),
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            CoinsCounts: "",
            PayableAmt: this.props.serviceBilling ? this.props.data?.remain_amount : this.props.CorpBillData ? this.props.CorpBillData?.policy_amount : "",
            serviceBillData: this.props.data ? this.props.data : {},
            opPatientCreditBill: this.props.BillData ? this.props.BillData : {},
            FiveCount: "",
            ReceiveAmtClicked: false,
            corporatePay: (this.props.BillData?.credit_type === "Corprate credit" || this.props.ipPatientCreditBill?.credit_type === "Corprate credit") ? "Corporate" : (this.props.BillData?.credit_type === "Insurance credit" || this.props.ipPatientCreditBill?.credit_type === "Insurance credit") ? "Insurance" : null,
            receiptDescription: "",
            opPatientData: this.props.OpPatientData ? this.props.OpPatientData : {},
            paymentMadeBy: "",
            labBillData: this.props.FromLabBill ? this.props.BillData : {},
            labAmountData: this.props.LabBillAmount ? this.props.LabBillAmount : {},
            isInsurance: false,
            insuranceAmt: "",
            insuranceTrfNo: "",
            companyName: "",
            policyNo: "",
            corporateData: this.props.CorpBillData ? this.props.CorpBillData : {},
            ipPatientCreditBill: this.props.ipPatientCreditBill ? this.props.ipPatientCreditBill : {},
            ipPatientData: this.props.ipPatientData ? this.props.ipPatientData : {},
            PayForOPCredit: false,
            PayForIPCredit: false,
            PayForPharmaCredit: false,
            OPpayingAmount: "",
            IPPayingAmount: "",
            PharmaPayingAmount: "",
        }
    }
    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.ClosePopUp()
    }
    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "" ,  ReceivedCashAmount: "",CashBalanceAmount: "",}) }
            if (!states.isCard) { this.setState({ CardAmount: "", CardNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
            if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" ,ReceivedCashAmount: "",CashBalanceAmount: "",}) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
            if (!states.isInsurance) { this.setState({ insuranceAmt: "", insuranceTrfNo: "", companyName: "", policyNo: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
            // if(states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer){
            //     this.setState({ CashAmount : this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt, })  // Fetch Full amount in Cash Amount, when only the cash is selected
            // }
            this.AutoFetchbillAmount()
        })
    }

    AutoFetchbillAmount = () => {
        let FetchingAccess = localGetItem("auto_fetch_bill_amount_to_payment")
        var states = this.state
        if (FetchingAccess === "true") {
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque) {
                this.setState({ CashAmount: this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt })
            } else if (!states.isCash && states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque ) {
                this.setState({ CardAmount: this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt })
            } else if (!states.isCash && !states.isCard && states.isUPI && !states.isBankTranfer && !states.isCheque ) {
                this.setState({ UPIAmount: this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt })
            } else if (!states.isCash && !states.isCard && !states.isUPI && states.isBankTranfer && !states.isCheque ) {
                this.setState({ BankTfrAmount: this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt })
            } else if (!states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && states.isCheque ) {
                this.setState({ ChequeAmount: this.state.serviceBillData?.remain_amount ? this.state.serviceBillData?.remain_amount : this.state.PayableAmt })
            }
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }
    Clear() {
        this.setState({
            PaymentDate: new Date(),
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false
        })
    }

    renderCountsText = (Amount, Key, Right = false) => {
        var states = this.state
        return (
            <Box component={"div"} xs={4} marginLeft={Right ? "5vw" : "1.2vw"} className="eMed_CrdPop_Denomi_invidual_div">
                <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
                <Typography>x</Typography>
                <TextField
                    placeholder="counts"
                    size='small'
                    inputProps={{ maxLength: 4 }}
                    className='eMed_crdPop_amountTextField'
                    variant='standard'
                    value={states[Key]}
                    onChange={(e) => {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </Box>
        )
    }


    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0 }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                label={t(label) + `(${CurrencySymbol})`}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        },()=>{
                            if (key === "CashAmount") {
                                this.setState({
                                  CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                                })
                              }
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width:"10vw" }}
                inputProps={{ maxLength: 20 }}
                focused={key === "CashBalanceAmount"}
                disabled ={( key === "CashBalanceAmount" )|| (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false)}
                size='small'
                label={t(label)}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let onlynumber = CommonValidation.numberOnly(e.target.value);
                    if (key === "ReceivedCashAmount") {
                        if (onlynumber || e.target.value === "") {
                          this.setState({
                            ReceivedCashAmount: e.target.value,
                            CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
                          })
                        }
                      }else{
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            })
                        }
                      }  
                }}
            />
        )
    }
    ClearData() {
        this.setState({
            PaymentDate: new Date(),
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            CardAmount: "",
            CardNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            CoinsCounts: "",
            PayableAmt: this.props.serviceBilling ? this.state.PayableAmt : "",
            FiveCount: "",
            receiptDescription: "",
            paymentMadeBy: "",
            PayForOPCredit: false,
            PayForIPCredit: false,
            PayForPharmaCredit: false,
            OPpayingAmount: "",
            IPPayingAmount: "",
            PharmaPayingAmount: "",
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            ReceiveAmtClicked : false
        })
    }

    CalculateAmount(RefNumCheck = false) {
        var states = this.state
        let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
        let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
        let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
        let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
        let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
        let insuranceAmt = states.insuranceAmt === "" ? 0 : +states.insuranceAmt
        if (states.PayableAmt === "") {
            return false
        }
        else if (+states.PayableAmt > states.BillData?.pending_amount) {
            return false
        }
        else if ((states.isCash && !CashAmt) || (states.isCard && !CardAmt) || (states.isUPI && !UPIAmt) || (states.isBankTranfer && !bankTransferAmt) || (states.isCheque && !ChequeAmt) ) {
            return false
        }
        else if (+states.PayableAmt === (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + insuranceAmt)) {
            if (RefNumCheck) {
                if ((CardAmt === 0 || states.CardTransactionNumber !== "") &&
                    (UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== "") &&
                    (ChequeAmt === 0 || states.ChequeNumber !== "") &&
                    (insuranceAmt === 0 || states.insuranceTrfNo !== "" || states.companyName !== "" || states.policyNo !== "")) {
                    return true
                }
            } else {
                return true
            }
        }
    }

    convertTimeToDecimal(time) {
        let [hours, minutes] = time?.split(':').map(Number);
        return hours * 60 + minutes;
    }

    CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
        let fMinTime = typeof (minTime) === 'number' ? (minTime < 9 ? `00:0${minTime}` : `00:${minTime}`) : minTime
        let fixedTimes = this.convertTimeToDecimal(fixedTime);
        let changedTimes = this.convertTimeToDecimal(changedTime);
        let minTimes = this.convertTimeToDecimal(fMinTime ? fMinTime : '00:00');
        let changeAndFixedTime = changedTimes - fixedTimes
        let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
        let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : (Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes))
        let totalCost = ((+ratePerHour) + (timeCalculateTime * minAmount))
        // let totalCost = Math.ceil(changedTimes / fixedTimes) * ratePerHour;

        return totalCost;
    }

    postServiceBill = () => {
        try {
            let { states } = this.state
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            if (this.state.isInsurance) {
                payment.push(6)
            }
            let startDate = new Date(this.state.serviceBillData.invoice_date)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            let invoice_date = this.state.serviceBillData.invoice_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}:00` : ""
            let PaymentDate = DateTime.fromJSDate(this.state.PaymentDate).toFormat('yyyy-MM-dd')
            let service_items = this.state.serviceBillData.service_line_item
            let service_line_items = [];
            service_items.forEach(element => {
                let timeTakenStr = element?.time_taken && element.time_taken?.split(':');
                let timeTaken = timeTakenStr?.length > 0 && timeTakenStr?.slice(0, 2)?.join(':')
                let Unit = timeTaken ? (timeTaken + " " + "hrs") : element.unit
                let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken ? element.time_taken : null
                let timeAmount = element.time_taken ? this.CalculateHourAmount(+((this.props?.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount), element.time_taken, old_Unit,element?.minimum_time,element?.min_amount) : 0
                service_line_items.push({
                    service_name: element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
                    service_type: element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
                    service_units: Unit ? Unit : "1",
                    service_old_units: old_Unit ? old_Unit : null,
                    service_rate_per_unit: +((this.props?.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage): element.amount),
                    service_amount: element.time_taken ? (+timeAmount - element.discountAmount) : +(((this.props?.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.totalAmount, element?.gst_percentage): element.totalAmount) - element.discountAmount),
                    discount_amount: +element.discountAmount ? +element.discountAmount : 0,
                    discount_percentage: element.discountPercentage ? +element.discountPercentage : 0,
                    total_amount: (element.time_taken ? this.CalculateHourAmount(+((this.props?.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount), Unit, (old_Unit ? old_Unit : Unit),element?.minimum_time,element?.min_amount) : +((this.props?.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.totalAmount, element?.gst_percentage): element.totalAmount) * +element.unit),
                    status: 'paid',
                    id: this.props.inVoiceId ? element.line_item_id : null,
                    template_id: element.templateId ? element.templateId : element?.ip_template_id ? element?.ip_template_id : null,
                    service_id: element?.service_id ? element?.service_id : element.ip_service_id ? element.ip_service_id : element.id ? element.id : null,
                    service_category_id: element.service_category_id ? element.service_category_id : null,
                    amount_type: element?.amount_type ? element?.amount_type : 'rupee',
                    doctor_fee: element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : element.doctor_share ? element.doctor_share : element.new_doctor_fee ? element.new_doctor_fee : 0,
                    hospital_fee: element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : element.hospital_share ? element.hospital_share : element.new_hospital_fee ? element.new_hospital_fee : 0,
                    doctor_percentage: element?.ip_service?.doctor_percentage ? element?.ip_service?.doctor_percentage : element?.doctor_percent ? element.doctor_percent : element.doctor_percentage ? element.doctor_percentage : 0,
                    hospital_percentage: element?.ip_service?.hospital_percentage ? element?.ip_service?.hospital_percentage : element?.hospital_percent ? element.hospital_percent : element.hospital_percentage ? element.hospital_percentage : 0,
                    old_doctor_fee: element?.ip_service?.original_doctor_fee ? element?.ip_service?.original_doctor_fee : element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : 0,
                    old_hospital_fee: element?.ip_service?.original_hospital_fee ? element?.ip_service?.original_hospital_fee : element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : 0,
                    // old_doctor_fee: element?.ip_service?.old_doctor_fee ? element?.ip_service?.old_doctor_fee : element.old_doctor_fee ? element.old_doctor_fee : element?.doctor_share ? element?.doctor_share : 0,
                    // old_hospital_fee: element?.ip_service?.old_hospital_fee ? element?.ip_service?.old_hospital_fee : element.old_hospital_fee ? element.old_hospital_fee : element?.hospital_share ? element?.hospital_share : 0,
                    new_doctor_fee: element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : element.doctor_share ? element?.doctor_share : element.new_doctor_fee ? element.new_doctor_fee : 0,
                    new_hospital_fee: element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : element.hospital_share ? element.hospital_share : element.new_hospital_fee ? element.new_hospital_fee : 0,
                    is_auto_billing: element?.is_auto_billing,
                    procedure_start_time: element?.start_date ? element?.start_date : null,
                    procedure_end_time: element?.end_date ? element?.end_date : null,
                    minimum_amount: element.time_taken && element?.min_amount ? element?.min_amount : null,
                    minimum_time: element.minimum_time ? (typeof(element.minimum_time) === 'number' ? (element.minimum_time < 9 ? `00:0${element.minimum_time}` : `00:${element.minimum_time}`) : element.minimum_time ): '00:00',
                    individual_discount_comments : element?.individual_discount_comments ? element?.individual_discount_comments : "",
                    other_consultant_name: element?.other_consultant_name ? element?.other_consultant_name : null,
                    gst_percentage: element?.gst_percentage ? element?.gst_percentage : 0,

                })
            })
            let data = {
                "ip_admission_id": this.state.serviceBillData.ip_admission_id,
                "net_amount": this.state.serviceBillData.net_amount,
                "paid_amount": +this.state.serviceBillData.remain_amount,
                "received_amount": this.state.serviceBillData.remain_amount,
                "detect_from_advance": this.state.serviceBillData.advCheck,
                "amount_detect_from_advance": this.state.serviceBillData.totalDeductedAmount,
                "discount_percentage": this.state.serviceBillData.discount_percentage ? +this.state.serviceBillData.discount_percentage : 0,
                "discount_amount": this.state.serviceBillData.discount_amount ? +this.state.serviceBillData.discount_amount : 0,
                "invoice_date": invoice_date,
                "payment_mode": payment,
                "cash_amount": +this.state.CashAmount,
                "card_amount": +this.state.CardAmount,
                "upi_amount": +this.state.UPIAmount,
                "bank_transfer_amount": +this.state.BankTfrAmount,
                "cheque_amount": +this.state.ChequeAmount,
                "card_number": this.state.CardTransactionNumber,
                "upi_ref_no": this.state.UPINumber,
                "bank_ref_no": this.state.BankTfrNumber,
                "cheque_ref_no": this.state.ChequeNumber,
                "payment_status": "paid",
                "payment_date": PaymentDate,
                "total_bill_amount": this.state.serviceBillData.total_bill_amount,
                "total_paid_amount": this.state.serviceBillData.total_paid_amount,
                "total_balance": this.state.serviceBillData.total_balance,
                "service_line_item": service_line_items,
                "total_amount": this.state.serviceBillData.noDiscountAmount,
                "total_sub_amount": this.state.serviceBillData?.serviceAmount,
                "bill_type": "regular",
                "insurance_amount": +states.insuranceAmt,
                "insurance_company_name": states.companyName,
                "policy_number": states.policyNo,
                "transaction_number": states.insuranceTrfNo,
                // "receipt_description": states.receiptDescription,
                "payment_made_by": states.paymentMadeBy,
                removable_ids: this.state.serviceBillData?.removable_ids,
                'remarks': this.state.serviceBillData?.discountReason ? this.state.serviceBillData.discountReason : ''
            }
            if (this.props.inVoiceId) { data["bill_id"] = this.props.inVoiceId }
            if (this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isCheque || this.state.isBankTranfer || this.state.isInsurance) {
                if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Doesn't Match With Payable Amount")
                } else if (DateTime.fromJSDate(this.state.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "" ||
                    (this.state.insuranceAmt !== "" && (this.state.insuranceTrfNo === "" || this.state.companyName === "" || this.state.policyNo === ""))) {
                    this.errorMessage("Please,Enter Reference Number")
                } else {
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.IP_SERVICE_BILL_POST)
                        .then((response) => {
                            if (response.data.status === "success") {
                                this.props.CrebillPosted(response.data.message, response.data.bill_id, response.data.receipt_id)
                                this.LoaderFunction(false)
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.status === "fail") {
                                this.setState({ ReceiveAmtClicked : false })
                                this.LoaderFunction(false)
                                this.errorMessage(error?.response?.data?.message)
                            } else {
                                this.setState({ ReceiveAmtClicked : false })
                                this.LoaderFunction(false)
                                this.errorMessage(error.message)
                            }
                        })
                }
            } else {
                this.errorMessage("Select Payment Type")
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postOpPatientCreditBill = () => {
        try {
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            var states = this.state
            let Aptime = DateTime.fromJSDate(states.PaymentDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            if (
                states.PayableAmt !== "" &&
                `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}` !== "Invalid DateTime" &&
                (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) &&
                this.CalculateAmount()
            ) {
                if (states.BillData?.balance_amount + +states.PayableAmt > 0) {
                    this.errorMessage("Payable Amount Not Matched")
                    return;
                }
                if (states.BillData?.balance_amount > 0 && (+states.PayableAmt > states.BillData?.balance_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                    return;
                }
                if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
                    this.errorMessage("Please,Enter Reference Number")
                } else {
                    // let data = {
                    //     "invoice_id": states.opPatientCreditBill?.id,
                    //     "amount_cash": +states.CashAmount,
                    //     "amount_card": +states.CardAmount,
                    //     "upi_amount": +states.UPIAmount,
                    //     "paid_amount": +states.PayableAmt,
                    //     "bank_transfer_amount": +states.BankTfrAmount,
                    //     "cheque_amount": +states.ChequeAmount,
                    //     "cheque_ref_no": states.ChequeNumber,
                    //     "bank_ref_no": states.BankTfrNumber,
                    //     "upi_ref_no": states.UPINumber,
                    //     "card_ref_no": states.CardTransactionNumber,
                    //     "invoice_date": `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}`,
                    //     "patient_type": "Out Patient",
                    // }

                    let data = {
                        "cash_amount": +states.CashAmount,
                        "card_amount": +states.CardAmount,
                        "upi_amount": +states.UPIAmount,
                        "bank_transfer_amount": +states.BankTfrAmount,
                        "cheque_amount": +states.ChequeAmount,
                        "payment_mode": payment,
                        "card_ref_no": states.CardTransactionNumber,
                        "upi_ref_no": states.UPINumber,
                        "bank_ref_no": states.BankTfrNumber,
                        "cheque_ref_no": states.ChequeNumber,
                        "appointment_id": states.opPatientData?.appointment_id,
                        "patient_id": states.opPatientData?.patient_id,
                        "payment_date": `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}:00`,
                        "payment_made_by": states.paymentMadeBy,
                        "payable_amount": +states.PayableAmt,
                        // "receipt_description": states.receiptDescription,
                        "total_bill_amount": this.state.opPatientCreditBill?.balance_amount,
                        "pharmacy_paid_amount": states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0,
                        "fo_paid_amount": states?.OPpayingAmount ? +states?.OPpayingAmount : (states?.IPPayingAmount ? +states?.IPPayingAmount : 0)
                    }
                    if(this.props.fromDoctor){
                        data['clinic_id'] = +this.props.clinicId
                    }
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.OP_PATIENT_CREDIT_BILL_POST).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.LoaderFunction(false)
                                this.props.creditBillPost(response.data.message, response.data?.data?.receipt_id ? response.data?.data?.receipt_id : response.data?.receipt_id?.fo_receipt_id, response.data?.data?.pharmacy_receipt_id ? response.data?.data?.pharmacy_receipt_id : response.data?.receipt_id?.pharmacy_receipt_id )
                            }
                            else {
                                this.LoaderFunction(false)
                                this.errorMessage(response.data.message)
                            }
                        }).catch((error) => {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        })
                }
            }
            else {
                if (states.PayableAmt === "" || states.PayableAmt === "0") {
                    this.errorMessage("Please Enter the Payable Amount")
                }
                else if ((states.BillData?.balance_amount + +states.PayableAmt > 0)) {
                    this.errorMessage("Payable Amount Not Matched")
                }
                else if (states.BillData?.balance_amount > 0 && (+states.PayableAmt > states.BillData?.balance_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                }
                else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) {
                    this.errorMessage("Please Select any Payment Methods")
                }
                else if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Not Matched ")
                }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    ipReceivePayment = () => {
        try {
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            var states = this.state
            let Aptime = DateTime.fromJSDate(states.PaymentDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            if (states.PayableAmt !== "" &&
                `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}` !== "Invalid DateTime" &&
                (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) &&
                this.CalculateAmount()) {
                let datas = {
                    "ip_admission_id": states.corporateData?.ip_admission_id ? states.corporateData?.ip_admission_id : null,
                    "payable_amount": states.PayableAmt ? +states.PayableAmt : 0,
                    "payment_date": states.PaymentDate ? DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(today).toFormat('yyyy-MM-dd'),
                    "payment_made_by": states.paymentMadeBy ? states.paymentMadeBy : "",
                    "patient_id": states.corporateData?.patient_id ? states.corporateData?.patient_id : null,
                    "patient_account_number": states.corporateData?.patient_uhid ? states.corporateData?.patient_uhid : "",
                    "cash_amount": +states.CashAmount ? +states.CashAmount : 0,
                    "card_amount": +states.CardAmount ? +states.CardAmount : 0,
                    "upi_amount": +states.UPIAmount ? +states.UPIAmount : 0,
                    "bank_transfer_amount": +states.BankTfrAmount ? +states.BankTfrAmount : 0,
                    "cheque_amount": +states.ChequeAmount ? +states.ChequeAmount : 0,
                    "payment_mode": payment.length > 0 ? payment : [],
                    "card_ref_no": states.CardTransactionNumber ? states.CardTransactionNumber : null,
                    "upi_ref_no": states.UPINumber ? states.UPINumber : null,
                    "bank_ref_no": states.BankTfrNumber ? states.BankTfrNumber : null,
                    "cheque_ref_no": states.ChequeNumber ? states.ChequeNumber : null,
                    "bill_type": "credit",
                    "total_bill_amount": this.state.corporateData?.total_bill_amount,
                    "is_credit": false,
                    "payment_against": "Corporate",
                    // "receipt_description":states.receiptDescription ? states.receiptDescription : '',
                }
                this.LoaderFunction(true)
                RestAPIService.create(datas, Serviceurls.IP_RECEIVE_PAYMENT_GET).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.LoaderFunction(false)
                            this.props.CrebillPosted(response.data.message, response.data?.data?.receipt_id ? response.data?.data?.receipt_id : response.data?.receipt_id?.fo_receipt_id, response.data?.data?.pharmacy_receipt_id ? response.data?.data?.pharmacy_receipt_id : response.data?.receipt_id?.pharmacy_receipt_id)   
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message);
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message);
                        }
                    })

            } else {
                if (states.PayableAmt === "" || states.PayableAmt === "0") {
                    this.errorMessage("Please Enter the Payable Amount")
                }
                else if ((states.BillData?.balance_amount + +states.PayableAmt > 0)) {
                    this.errorMessage("Payable Amount Not Matched")
                }
                else if (states.BillData?.balance_amount > 0 && (+states.PayableAmt > states.BillData?.balance_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                }
                else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) {
                    this.errorMessage("Please Select any Payment Methods")
                }
                else if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Not Matched ")
                }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    postLabReceivePayment = () => {
        try {
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            if (this.state.isInsurance) {
                payment.push(6)
            }
            var states = this.state
            let Aptime = DateTime.fromJSDate(states.PaymentDate).toLocaleString(DateTime.TIME_24_SIMPLE);
            if (
                states.PayableAmt !== "" &&
                `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}` !== "Invalid DateTime" &&
                (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance) &&
                this.CalculateAmount()
            ) {
                if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "" ||
                    (this.state.insuranceAmt !== "" && (this.state.insuranceTrfNo === "" || this.state.companyName === "" || this.state.policyNo === ""))) {
                    this.errorMessage("Please,Enter Reference Number")
                } else {
                    let data = {
                        "invoice_id": states.labBillData?.id,
                        "cash_amount": +states.CashAmount,
                        "card_amount": +states.CardAmount,
                        "upi_amount": +states.UPIAmount,
                        "payable_amount": +states.PayableAmt,
                        "bank_transfer_amount": +states.BankTfrAmount,
                        "cheque_amount": +states.ChequeAmount,
                        "cheque_ref_no": states.ChequeNumber,
                        "bank_ref_no": states.BankTfrNumber,
                        "upi_ref_no": states.UPINumber,
                        "card_ref_no": states.CardTransactionNumber,
                        "invoice_date": `${DateTime.fromJSDate(states.PaymentDate).toFormat("yyyy-MM-dd")} ${Aptime}:00`,
                        "lab_bill_summary": states.labBillData.lab_bill_summary_id,
                        "ip_number": states.labBillData.ip_number ? states.labBillData.ip_number : null,
                        "patient_id": states.labBillData.patient_id ? states.labBillData.patient_id : null,
                        "payment_made_by": states.paymentMadeBy,
                        // "receipt_description": states.receiptDescription,
                        "insurance_amount": +states.insuranceAmt,
                        "insurance_company_name": states.companyName,
                        "policy_number": states.policyNo,
                        "transaction_number": states.insuranceTrfNo,
                        "total_bill_amount": this.state.labAmountData?.total_balance_amount,
                        "payment_mode": payment,
                        "appointment_id": states.labBillData.appointment_id ? states.labBillData.appointment_id : null,
                        "ip_admission_id": states.labBillData.ip_admission_id ? states.labBillData.ip_admission_id : null,
                        "fo_paid_amount": states.PayableAmt ? +states.PayableAmt : 0,
                        //"patient_type": "Out Patient",
                        "clinic_id": this.props?.LabClinicId ? +this.props?.LabClinicId : null
                    }
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.LAB_RECEIVE_PAYMENT).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.LoaderFunction(false)
                                this.props.CrebillPosted(response.data.message, response.data?.data?.receipt_id ? response.data?.data?.receipt_id : response.data?.receipt_id?.fo_receipt_id, response.data?.data?.pharmacy_receipt_id ? response.data?.data?.pharmacy_receipt_id : response.data?.receipt_id?.pharmacy_receipt_id)
                            }
                            else {
                                this.LoaderFunction(false)
                                this.errorMessage(response.data.message)
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.status === "fail") {
                                this.LoaderFunction(false)
                                this.errorMessage(error.response.data.message, false);
                            } else {
                                this.LoaderFunction(false)
                                this.errorMessage(error.message, false);
                            }
                        })
                }
            }
            else {
                if (states.PayableAmt === "" || states.PayableAmt === "0") {
                    this.errorMessage("Please Enter the Payable Amount")
                }
                else if ((+states.PayableAmt > states.BillData?.patient_pending_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                }
                else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance)) {
                    this.errorMessage("Please Select any Payment Methods")
                }
                else if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Not Matched ")
                }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    PostBill() {
        try {
            var states = this.state
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            if (
                states.PayableAmt !== "" &&
                DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') !== "Invalid DateTime" &&
                (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) &&
                this.CalculateAmount()
            ) {
                if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
                    this.errorMessage("Please,Enter Reference Number")
                } else {
                    let data = {
                        // "invoice_id": states.BillData?.id,
                        "amount_cash": +states.CashAmount,
                        "amount_card": +states.CardAmount,
                        "upi_amount": +states.UPIAmount,
                        "paid_amount": +states.PayableAmt,
                        "bank_transfer_amount": +states.BankTfrAmount,
                        "cheque_amount": +states.ChequeAmount,
                        "cheque_ref_no": states.ChequeNumber,
                        "bank_ref_no": states.BankTfrNumber,
                        "upi_ref_no": states.UPINumber,
                        "card_ref_no": states.CardTransactionNumber,
                        "invoice_date": DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd'),
                        "modual_type": states.BillData?.bill_types,
                        "payment_mode": payment,
                        "module_id": states.BillData?.module_id,
                        "patient_id": states.BillData?.patient_id,
                        "pharmacy_paid_amount": states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0,
                        "fo_paid_amount": states?.OPpayingAmount ? +states?.OPpayingAmount : (states?.IPPayingAmount ? +states?.IPPayingAmount : this.state.BillData?.bill_types === "Laboratory" ? +states.PayableAmt : 0),
                        // "receipt_description":states.receiptDescription,
                        "payment_made_by":states.paymentMadeBy,
                    }
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.CREDIT_BILLS_GET).
                        then((response) => {
                            this.LoaderFunction(false)
                            if (response.data.status === "success") {
                                this.LoaderFunction(false)
                                this.props.CrebillPosted(response.data.message, response.data?.data?.receipt_id ? response.data?.data?.receipt_id : response.data?.receipt_id?.fo_receipt_id, response.data?.data?.pharmacy_receipt_id ? response.data?.data?.pharmacy_receipt_id : response.data?.receipt_id?.pharmacy_receipt_id)
                            }
                            else {
                                this.LoaderFunction(false)
                                this.errorMessage(response.data.message)
                            }
                        }).catch((error) => {
                            this.errorMessage(error.message)
                        })
                }
            }
            else {
                if (states.PayableAmt === "" || states.PayableAmt === "0") {
                    this.errorMessage("Please Enter the Payable Amount")
                }
                else if ((+states.PayableAmt > states.BillData?.pending_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                }
                else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) {
                    this.errorMessage("Please Select any Payment Methods")
                }
                else if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Not Matched ")
                }
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    postIpPatientCreditBill = () => {
        try {
            let states = this.state
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            if (
                states.PayableAmt !== "" &&
                DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') !== "Invalid DateTime" &&
                (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) &&
                this.CalculateAmount()
            ) {
                if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
                    this.errorMessage("Please,Enter Reference Number")
                } else {
                    let data = {
                        ip_admission_id: states.ipPatientCreditBill?.ip_admission_id,
                        payable_amount: +states.PayableAmt,
                        total_bill_amount: states.ipPatientCreditBill?.total_bill_amount,
                        payment_date: DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd'),
                        payment_made_by: states.paymentMadeBy,
                        patient_id: states.ipPatientData?.patient_id,
                        cash_amount: +states.CashAmount,
                        card_amount: +states.CardAmount,
                        upi_amount: +states.UPIAmount,
                        bank_transfer_amount: +states.BankTfrAmount,
                        cheque_amount: +states.ChequeAmount,
                        payment_mode: payment,
                        card_ref_no: states.CardTransactionNumber,
                        upi_ref_no: states.UPINumber,
                        bank_ref_no: states.BankTfrNumber,
                        cheque_ref_no: states.ChequeNumber,
                        pharmacy_paid_amount: states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0,
                        fo_paid_amount: states?.OPpayingAmount ? +states?.OPpayingAmount : (states?.IPPayingAmount ? +states?.IPPayingAmount : 0)
                    }
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.IP_RECEIVE_PAYMENT_GET).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.LoaderFunction(false)
                                this.props.creditBillPost(response.data.message, response.data?.data?.receipt_id ? response.data?.data?.receipt_id : response.data?.receipt_id?.fo_receipt_id, response.data?.data?.pharmacy_receipt_id ? response.data?.data?.pharmacy_receipt_id : response.data?.receipt_id?.pharmacy_receipt_id)
                            }

                        }).catch((error) => {
                            if (error?.response?.data?.message) {
                                this.LoaderFunction(false)
                                this.errorMessage(error?.response?.data?.message)
                            } else {
                                this.LoaderFunction(false)
                                this.errorMessage(error.message)
                            }
                        })
                }
            }
            else {
                if (states.PayableAmt === "" || states.PayableAmt === "0") {
                    this.errorMessage("Please Enter the Payable Amount")
                }
                else if ((+states.PayableAmt > states.BillData?.pending_amount)) {
                    this.errorMessage("Payable Amount is More than Pending Amount")
                }
                else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
                    this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) {
                    this.errorMessage("Please Select any Payment Methods")
                }
                else if (!this.CalculateAmount()) {
                    this.errorMessage("Amount Not Matched ")
                }
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    returnTotal() {
        try {
            let Amount = 0
            let states = this.state
            if (states.TwoThounsandCounts != "") {
                Amount += +states.TwoThounsandCounts * 2000
            }
            if (states.FiveHundredCounts != "") {
                Amount += +states.FiveHundredCounts * 500
            }
            if (states.TwoHundredCounts != "") {
                Amount += +states.TwoHundredCounts * 200
            }
            if (states.HundredCounts != "") {
                Amount += +states.HundredCounts * 100
            }
            if (states.FiftyCounts != "") {
                Amount += +states.FiftyCounts * 50
            }
            if (states.TwentyCounts != "") {
                Amount += +states.TwentyCounts * 20
            }
            if (states.TenCounts != "") {
                Amount += +states.TenCounts * 10
            }
            if (states.FiveCount != "") {
                Amount += +states.FiveCount * 5
            }
            if (states.CoinsCounts != "") {
                Amount += +states.CoinsCounts * 1
            }
            return Amount
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    SplitPayable = (label, PendingAmt, CheckBoxKey, PayableAmtKey) => {
        let states = this.state;
        return (
            <Box className="eMed_split_Wrap">
                <Checkbox
                    disabled={PendingAmt === 0}
                    checked={states[CheckBoxKey]}
                    onChange={(e) => {
                        states[CheckBoxKey] = e.target.checked
                        if (!states[CheckBoxKey]) {
                            states[PayableAmtKey] = ""
                        }
                        this.setState({ states })
                    }}
                    size='small' />
                <Box className="eMed_split_Label_Amount">
                    <Typography fontSize={'0.8vw'}>{label}</Typography>
                    <Typography fontSize={'0.95vw'} fontWeight={600}>{AmountFormat(PendingAmt || 0)}</Typography>
                </Box>
                { states[CheckBoxKey] ?
                        <TextField
                            inputProps={{style: { height : "1vw" } }}
                            size='small'
                            value={states[PayableAmtKey]}
                            sx={{ width: "8vw", marginLeft:'1vw'}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography>{CurrencySymbol}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                let number = CommonValidation.numberOnly(e.target.value);
                                if ((number && e.target.value > 0 && e.target.value <= +PendingAmt) || e.target.value === "") {
                                    states[PayableAmtKey] = e.target.value
                                    this.setState({ states }, ()=>{
                                        let OpAmt = states?.OPpayingAmount ? +states?.OPpayingAmount : 0 
                                        let IpAMt = states?.IPPayingAmount ? +states?.IPPayingAmount : 0 
                                        let PharmaAmt = states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0 
                                        this.setState({
                                            PayableAmt : OpAmt + IpAMt + PharmaAmt
                                        })
                                    })
                            }
                        }}
                    /> : null}
            </Box>
        )
    }
    render() {
        let states = this.state
        var parts = this.state.BillData?.invoice_date ? this.state.BillData?.invoice_date?.split('-') : [];
        var InvoiceDate = new Date(parts[0], parts[1] - 1, parts[2]);
        const { t } = this.props
        let CreditType = this.props.serviceBilling ? null : this.state.BillData?.credit_type ? this.state.BillData?.credit_type : this.state.ipPatientCreditBill?.credit_type ? this.state.ipPatientCreditBill?.credit_type : "-"
        let CorporateName = this.state.BillData?.employer_name ? this.state.BillData?.employer_name : this.state.corporateData?.policy_company_name ? this.state.corporateData?.policy_company_name : this.state.ipPatientCreditBill?.employer_company_name ? this.state.ipPatientCreditBill?.employer_company_name : this.state.BillData?.employer_company_name ? this.state.BillData?.employer_company_name : ""
        let CorpEmpId = this.state.BillData?.employee_id_no ? this.state.BillData?.employee_id_no : this.state.ipPatientCreditBill?.employer_id_number ? this.state.ipPatientCreditBill?.employer_id_number : this.state.BillData?.employer_id_number ? this.state.BillData?.employer_id_number : ""
        let CorpDiscPercent = this.state.BillData?.employer_discount ? this.state.BillData?.employer_discount : this.state.ipPatientCreditBill?.employer_discount_percent ? this.state.ipPatientCreditBill?.employer_discount_percent : this.state.BillData?.employer_discount_percent ? this.state.BillData?.employer_discount_percent : ""
        let InsuCompanyName = this.state.BillData?.insurance_name ? this.state.BillData?.insurance_name : this.state.ipPatientCreditBill?.insurance_company_name ? this.state.ipPatientCreditBill?.insurance_company_name : this.state.BillData?.insurance_company_name ? this.state.BillData?.insurance_company_name : ""
        let InsuPolicyNo = this.state.BillData?.policy_no ? this.state.BillData?.policy_no : this.state.ipPatientCreditBill?.insurance_policy_number ? this.state.ipPatientCreditBill?.insurance_policy_number : this.state.BillData?.insurance_policy_number ? this.state.BillData?.insurance_policy_number : ""
        return (
            <div>
                <Dialog open={this.state.Open} >
                    <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                        {
                            (this.props.serviceBilling || this.props.FromLabBill) ?
                            <Typography fontWeight={600} marginLeft={"1vw"}>{t("Payment")}</Typography> :
                            <Box display={'flex'}>
                                 <Typography fontWeight={600} marginLeft={"1vw"}>{`${t("CreditBill")}`}</Typography>
                                 <Typography marginLeft={"1vw"} fontSize={"0.9vw"}>{` ${CreditType ? `( Credit Type : ${CreditType} )` : ""}`}</Typography>
                            </Box>
                        }
                        <Button onClick={() => { this.handleClose() }} >
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, maxHeight: "38vw" }}>
                        {this.props.serviceBilling ?
                            <Box component={"div"} className="eMed_CrdPop_AmtTitle">
                                <Typography component={'label'} marginLeft={"2vw"}>{t("TotalBill")}<Typography className='eMed_popup_amount_label' component={'span'} color={Colors.themeDark}>{AmountFormat(this.state.serviceBillData?.remain_amount || 0)}</Typography></Typography>
                            </Box> :
                            this.props.FromLabBill ?
                                <Box component={"div"} className="eMed_CrdPop_AmtTitle">
                                    <Typography component={'label'} marginLeft={"2vw"}>{t("TotalBill")}<Typography className='eMed_popup_amount_label' component={'span'} color={Colors.themeDark}>{AmountFormat(this.state.labAmountData?.total_bill_amount || 0)}</Typography></Typography>
                                    <Typography component={'label'} marginLeft={"4vw"}>{t("PendingAmount")}<Typography className='eMed_popup_amount_label' component={'span'} color={'red'}>{AmountFormat(this.state.labAmountData?.total_balance_amount) || 0}</Typography></Typography>
                                </Box> :
                                <Box component={"div"} className="eMed_CrdPop_AmtTitle">
                                    <Typography component={'label'} marginLeft={"2vw"}>{t("TotalBill")}<Typography className='eMed_popup_amount_label' component={'span'} color={Colors.themeDark}>{this.state.BillData?.amount_net || this.state.opPatientCreditBill?.total_amount || this.state.labAmountData?.total_bill_amount || this.state.corporateData?.total_bill_amount || this.state.ipPatientCreditBill?.total_bill_amount ? AmountFormat(this.state.BillData?.amount_net || this.state.opPatientCreditBill?.total_amount || this.state.labAmountData?.total_bill_amount || this.state.corporateData?.total_bill_amount || this.state.ipPatientCreditBill?.total_bill_amount) : AmountFormat(0)}</Typography></Typography>
                                    <Typography component={'label'} marginLeft={"4vw"}>{t("PendingAmount")}<Typography className='eMed_popup_amount_label' component={'span'} color={'red'}>{this.state.BillData?.pending_amount || this.state.opPatientCreditBill?.balance_amount || this.state.labAmountData?.total_balance_amount || this.state.corporateData?.policy_amount || this.state.ipPatientCreditBill?.balance_amount ? AmountFormat(this.state.BillData?.pending_amount || this.state.opPatientCreditBill?.balance_amount || this.state.labAmountData?.total_balance_amount || this.state.corporateData?.policy_amount || this.state.ipPatientCreditBill?.balance_amount) : AmountFormat(0)}</Typography></Typography>
                                </Box>
                        }
                        {
                            (this.props.FromLabBill || this.props.serviceBilling || this.state.BillData?.bill_types === "Laboratory") ? null :
                                <Box component={"div"}>
                                    <Divider textAlign='left'>
                                        <Typography component={'p'} className='eMed_Service_title'>Split the Payment Amount</Typography>
                                    </Divider>
                                    {/* Op credit Bill From - Billing Transcation, Bills in Patient */}
                                    {(this.state.BillData?.bill_types === "Out Patient" || this.props?.opPatientBillCredit) ? this.SplitPayable("Total OP Pending", this.state.BillData?.fo_pending_amount ? this.state.BillData?.fo_pending_amount : 0, "PayForOPCredit", "OPpayingAmount") : null}
                                    {/* IP credit Bill From - Billing Transcation */}
                                    {this.state.BillData?.bill_types === "In Patient" ? this.SplitPayable("Total IP Pending", this.state.BillData?.fo_pending_amount ? this.state.BillData?.fo_pending_amount : 0, "PayForIPCredit", "IPPayingAmount") : null}
                                    {/* IP credit Bill From - Bills in Patient */}
                                    {this.props?.fromIpPatient ? this.SplitPayable("Total IP Pending", this.state.ipPatientCreditBill?.fo_pending_amount ? this.state.ipPatientCreditBill?.fo_pending_amount : 0, "PayForIPCredit", "IPPayingAmount") : null}
                                    {/* Pharmacy credit Bill From - Billing Transcation, Bills in Patient */}
                                    {/* Access is Required from custom Settings */}
                                    {localGetItem("Allow_Pharma_CreditBill_In_FO") === "true" ? this.SplitPayable("Total Pharmacy Pending", this.state.BillData?.pharmacy_pending_amount ? this.state.BillData?.pharmacy_pending_amount : this.state.ipPatientCreditBill?.pharmacy_pending_amount ? this.state.ipPatientCreditBill?.pharmacy_pending_amount : this.state.BillData?.pharmacy_pending_amount ? this.state.BillData?.pharmacy_pending_amount : 0, "PayForPharmaCredit", "PharmaPayingAmount") : null}
                                </Box>
                        }
                        <Box component={"div"} className="eMed_CrdPop_InputDiv">
                            <Box component={"div"} width={"15vw"} >
                                <TextField
                                    label={t("PayableAmount")}
                                    size="small"
                                    sx={{ width: "15vw" }}
                                    disabled={this.state.serviceBillData.net_amount >= 0 || this.props.CorpBillData?.policy_amount >= 0 || (this.props.FromLabBill ? false : this.state.BillData?.bill_types !== "Laboratory")}
                                    // inputProps={{
                                    //     readOnly: this.state.serviceBillData.net_amount >= 0 ? true : false
                                    // }}
                                    value={this.state.PayableAmt}
                                    onChange={(e) => {
                                        let number = CommonValidation.numberOnly(e.target.value);
                                        if ((number && e.target.value > 0) || e.target.value === "") {
                                            this.setState({ PayableAmt: e.target.value })
                                        }
                                    }}
                                    error={this.state.PayableAmt != "" && +this.state.PayableAmt > this.state.BillData?.pending_amount}
                                />
                                <Typography className='eMed_CreditBill_ErrorTxt'>{(this.state.PayableAmt != "" && +this.state.PayableAmt > this.state.BillData?.pending_amount) ? "Payable Amount is more then Pending Amount !" : ''}</Typography>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DateTimePicker
                                    label={t("PaymentDate")}
                                    value={this.state.PaymentDate}
                                    name="PaymentDate"
                                    inputFormat='DD-MM-YYYY & hh:mm A'
                                    onChange={(newDate) => {
                                        if (newDate === null) {
                                            this.setState({ PaymentDate: new Date() })
                                        }
                                        else {
                                            this.setState({ PaymentDate: newDate?.$d })
                                        }
                                    }}
                                    maxDate={new Date()}
                                    minDate={InvoiceDate}
                                    renderInput={(params) => <TextField style={{ width: '15.5vw' }} size='small' {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box component={'div'} mb={"0.5vw"}>
                            <TextField
                                fullWidth
                                sx={{ marginBottom: "1vw", marginTop: "0.5vw" }}
                                placeholder='Payment Made By'
                                size='small'
                                value={this.state.paymentMadeBy}
                                name='paymentMadeBy'
                                onChange={(e) => {
                                    const { name, value } = e.target
                                    this.setState({ [name]: value })
                                }}
                                label={"Payment Made By"}
                            />
                            {/* <TextField
                                fullWidth
                                placeholder='Receipt Description'
                                value={this.state.receiptDescription}
                                name='receiptDescription'
                                onChange={(e) => {
                                    const { name, value } = e.target
                                    this.setState({ [name]: value })
                                }}
                                label={"Receipt Description"}
                            /> */}
                        </Box>
                        {((this.state.BillData?.credit_type !== "Patient credit") && (this.state.ipPatientCreditBill?.credit_type !== "Patient credit") && !this.props.serviceBilling && !this.props.FromLabBill) ?
                            <Box component={'div'} className='eMed_cor_txt'>
                                <FormControl disabled>
                                    <RadioGroup value={this.state.corporatePay}>
                                        <FormControlLabel value={"Corporate"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                                            <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Corporate")}: `}</span>{`${t("PatientUnder")} `}
                                            <span className='eMed_cor_font'>{CorporateName}</span>  {`${t("CorporatePlan")} (${t("ID.No.")}`}
                                            <span className='eMed_cor_font'>{CorpEmpId}</span> {") "}
                                            <span className='eMed_cor_font'>{CorpDiscPercent} %</span>{` ${t("corporateTxt")}`}</Typography>} />
                                        <FormControlLabel value={"Insurance"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                                            <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Insurance")}: ${InsuCompanyName} `}</span>{`${t("PatientRegisteredUnder")} (${t("Policy No : ")}  ${InsuPolicyNo} )`}</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </Box> : null}

                        <Box className="eMed_CrdPop_Check_Group">
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} />
                            {/* {((this.state.labBillData?.lab_bill_summary !== null || this.state.labBillData?.ip_admission_id !== null) && this.state.labBillData?.appointment_id === null) || this.state.serviceBillData?.ip_admission_id ?
                                <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isInsurance} onChange={(e) => { this.handleCheckBox(e, "isInsurance") }} size='small' />} label={t("Insurance")} /> : null} */}
                        </Box>

                        <Box component={'div'}>
                            {
                                this.state.isCash ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} >
                                        {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                        {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque ?
                                            <Box  display={"flex"} flexDirection={"row"}>
                                                {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                                {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                            </Box> : null
                                        }
                                    </Box> : null
                            }
                            {
                                this.state.isCard ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                        {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isUPI ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                        {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isBankTranfer ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                        {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isCheque ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                        {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isInsurance ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                        {this.renderAmountTextFields("InsuranceAmt", "insuranceAmt", CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default)}
                                        {this.renderNumberTextFields("InsuranceTrfNo", "insuranceTrfNo", true)}
                                        {this.renderNumberTextFields("CompanyName", "companyName", true)}
                                        {this.renderNumberTextFields("PolicyNo", "policyNo", true)}
                                    </Box>
                                    : null
                            }

                        </Box>
                        {this.state.isCash ?
                            <Box component={"div"} className="eMed_CrdPop_Denomi_div">
                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                    {this.renderCountsText("2000", "TwoThounsandCounts")}
                                    {this.renderCountsText("20", "TwentyCounts", true)}
                                </Box>
                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                    {this.renderCountsText("500", "FiveHundredCounts")}
                                    {this.renderCountsText("10", "TenCounts", true)}
                                </Box>
                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                    {this.renderCountsText("200", "TwoHundredCounts")}
                                    {this.renderCountsText("5", "FiveCount", true)}
                                </Box>
                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                    {this.renderCountsText("100", "HundredCounts")}
                                    {this.renderCountsText("1", "CoinsCounts", true)}
                                </Box>
                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                    {this.renderCountsText("50", "FiftyCounts")}
                                </Box>
                                <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'}>
                                    <Typography>{t("Total")}<b style={{ color: (this.returnTotal() === +this.state.PayableAmt && this.state.PayableAmt !== "") ? Colors.themeDark : Colors.black, marginLeft: '1vw' }}>{`${AmountFormat(this.returnTotal() || 0)}`}</b></Typography>
                                    <Typography marginLeft={"3vw"}>{t("Balance")}<b style={{ color: (this.state.PayableAmt !== "" ? +this.state.PayableAmt - this.returnTotal() : 0) < 0 ? Colors.red : Colors.black, marginLeft: '1vw' }}>{this.state.PayableAmt !== "" ? AmountFormat(+this.state.PayableAmt - this.returnTotal() || 0) : AmountFormat(0)}</b></Typography>
                                </Box>
                            </Box> : null}

                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                        {this.state.isLoader ?
                            <Box sx={{ width: "6.5vw", display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                <CircularProgress size={18} />
                            </Box> :
                            <Button
                                sx={{ width: "5vw" }}
                                variant='contained' size='small'
                                className='eMed_Filter_Btns'
                                disabled={this.state.ReceiveAmtClicked || (this.props.serviceBilling && !this.CalculateAmount(true)) || (this.props.FromLabBill && !this.CalculateAmount(true) || (this.props.fromIpPatient && !this.CalculateAmount(true)))}
                                onClick={() => {
                                    this.setState({ ReceiveAmtClicked: true }, () => {
                                        if (this.props.serviceBilling) {
                                            this.postServiceBill()
                                        } else if (this.props.opPatientBillCredit) {
                                            this.postOpPatientCreditBill()
                                        } else if (this.props.FromLabBill) {
                                            this.postLabReceivePayment()
                                        } else if (this.props.ReceivePayment) {
                                            this.ipReceivePayment()
                                        } else if (this.props.fromIpPatient) {
                                            this.postIpPatientCreditBill()
                                        } else {
                                            this.PostBill()
                                        }
                                        setTimeout(() => { this.setState({ ReceiveAmtClicked: false }) }, 2000)
                                    })
                                }}>{t("Receive")}</Button>}
                    </DialogActions>
                </Dialog>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        )
    }
}

export default withTranslation()(CreditBillPopUp)