import { Box, Button, Modal, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react';
import { ImagePaths } from '../../Utility/ImagePaths';
import "../../Modules/FrontOffice/BillingTransaction/Billings.css";
import { withTranslation } from 'react-i18next';
import ToastMsg from '../ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import { ReasonPopup } from './CommonComponents';

class PrintPreviewPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Open: true,
            BillData: this.props.BillData,
            isCash: false,
            BillCancelClicked:false
        }
    }
    componentDidMount() {
        if (!this.props.Data) {
            this.getPrintPreview()
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPrintPreview = () => {
        try {
            let API_URL = ""
            if(this.props.PRINT_URL){
                API_URL = this.props.PRINT_URL
            }
            else if (this.props.BillData?.receipt_number) {
                let clinicID =  localGetItem("clinicId") !== null ? `&clinic_id=${localGetItem("clinicId")}`: ""
                let fromReservation =  this.props.BillData?.from_reservation ? `&from_reservation=${this.props.BillData?.from_reservation}`: ""
                API_URL = this.props.Pharmacy ? Serviceurls.RECEIPT_PHARMACY_PRINT + `?receipt_id=` + this.props.BillData?.id : Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + this.props.BillData?.id + clinicID + fromReservation
            }  else {
                if (this.props.BillData?.bill_types === "OT") {
                    API_URL = Serviceurls.GET_PRINT_PREVIEW + `?export=pdf&id=` + this.props.BillData?.id
                }
                else if (this.props.BillData?.bill_types === "In Patient") {
                    API_URL = Serviceurls.IP_INVOICE_PRINT + `?ip_bill_summary_id=` + this.props.BillData?.id
                }
                else if (this.props.BillData?.bill_types === "Out Patient") {
                    API_URL = Serviceurls.OP_INVOICE_PRINT + "?invoce_id=" + this.props.BillData?.id
                }
                else if (this.props.BillData?.bill_types === "pharmacy") {
                    API_URL = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + this.props.BillData?.id + "&export=pdf" + "&pharmacy_id=" + this.props.BillData?.pharmacy_id + "";
                }
                else if (this.props.BillData?.bill_types === "Radiology") {
                    API_URL = Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + this.props.BillData?.id + "&export_type=pdf"
                }
                else if (this.props.BillData?.bill_types === "Laboratory") {
                    // if(this.props.BillData?.bill_type === "cancelled"){ 
                        API_URL = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + this.props.BillData?.id + "&export_type=pdf" + "&laboratory_id=" + this.props.BillData?.laboratory_id + "";
                    // }
                    // else if (this.props.BillData?.payment_status === "paid") {
                    //     API_URL = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + this.props.BillData?.id + "&export_type=pdf" + "&laboratory_id=" + this.props.BillData?.laboratory_id + "";
                    // }
                    // else if (this.props.BillData?.payment_status === "not paid") {
                    //     API_URL = Serviceurls.LAB_CREATE_BILL_PARTIAL_PRINT + "?bill_summary_id=" + this.props.BillData?.id + "&export_type=pdf" + "&laboratory_id=" + this.props.BillData?.laboratory_id;
                    // }
                    
                }
                else if(this.props.BillData?.billing_status){
                    API_URL = Serviceurls.PHARMA_BILLS_GET + "?id=" + this.props.BillData?.id + "&export=pdf" + "&pharmacy_id=" + this.props.BillData?.pharmacy_id + "";
                }
            }
            RestAPIService.getAllPrint(API_URL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    document.querySelector("iframe").src = fileURL;
                }).catch((e) => {
                    this.errorMessage(e.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    handleClose = () => {
        this.setState({
            Open: false,
        })
        this.props.ClosePopUp()
    }

    CancelBill(reason) {
        try {
            let data = {
                bill_id: this.state.BillData?.id,
                bill_type: 'cancelled',
                cancel_reason: reason
            }
            let URL = this.state.BillData?.bill_types === "In Patient" ? Serviceurls.IP_RECEIVE_PAYMENT_GET : Serviceurls.FO_PATIEMT_BILLING
            RestAPIService.create(data, URL)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.props.ClosePopUp(true,response.data.message )
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                      } else {
                        this.errorMessage(error.message)
                      }
                })
        }
        catch (e) {

        }
    }

    GetAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + "?appointment_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "patientData")
                            history.push({ pathname: "/OPHome/PatientDetails/Billing", state: { AppointmentID: id, InvoiceID: billid } })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetIPAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            RestAPIService.getAll(Serviceurls.FO_GET_IP_ADMISSION_DETAILS + "?ip_admission_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                                setCachevalue(JSON.stringify(this.state.AppointmentDetails), "IppatientData")
                                history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling", state: { AppointmentID: id, InvoiceID: billid } })
                            })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    addCancelComments = (comments) =>{
        this.CancelBill(comments)
    }
    popupClose = () => {
        this.setState({
          BillCancelClicked: false,
        })
      }
      msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    render() {
        const { t } = this.props
        return (
            <div>
                <Modal
                    open={this.state.Open}
                >
                    <Box component={'div'} className="eMed_BillPreview_Main" >
                        <Box component={'div'} className="eMed_BillPreview_Top">
                            <Typography variant='h6'>{this.props.Title}</Typography>
                            <Button onClick={() => { this.handleClose() }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <Box component={'div'} className="eMed_BillPreview_Image">
                            <iframe src={this.props.Data ? this.props.Data : ""} type="application/pdf" width="100%" height="100%"> </iframe>
                        </Box>
                        <Box component={'div'} className="eMed_BillPreview_BtnDiv">
                            {/* {
                                (this.props.cancelBillButton && this.props.BillData?.bill_types !== "pharmacy" && this.props.BillData?.bill_types !== "laboratory") ?
                                    <Button variant='outlined' size='small' color='error' className='eMed_Filter_Btns' onClick={() => { this.setState({ BillCancelClicked: true }) }}>
                                        {t("CancelBill")}
                                    </Button> : null
                            } */}
                            {/* {
                                this.props.ModifyBillButton ?
                                    <Button variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => {
                                        if(this.state.BillData?.bill_types === "Out Patient"){
                                        this.GetAppointmentDetails(this.state.BillData?.appointment_id, this.state.BillData?.id)
                                        }
                                        if(this.state.BillData?.bill_types === "In Patient"){
                                            this.GetIPAppointmentDetails(this.state.BillData?.ip_number_id, this.state.BillData?.id)
                                        }
                                    }}>
                                        {t("ModifyBill")}
                                    </Button> : null
                            } */}
                            {
                                this.props.PrintButton ?
                                    <Tooltip title={t("Print")} placement="top" arrow>
                                        <Button onClick={() => { this.handleClose() }} className='eMed_usrconf_btn'>
                                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                                        </Button>
                                    </Tooltip> : null
                            }
                        </Box>
                    </Box>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.BillCancelClicked ? <ReasonPopup
                    title={"Are you sure? Do you want to cancel the Bill"}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={"Enter the Cancel Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btnvarient1={'contained'}
                    sendCmt={this.addCancelComments.bind(this)} /> : null}
            </div>
        )
    }
}
export default withTranslation()(PrintPreviewPop)
