import { Box, Button, Drawer, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../Styles/Colors'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { AmountFormat, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { DateTime } from 'luxon'
import { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents'
import { CurrencySymbol } from '../../../Utility/Constants'
import BillingsFilter from '../../FrontOffice/BillingTransaction/BillingsFilter'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'

export default class LabDayEnd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dayEndData: [],
            page: 0,
            pageSize: 10,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "PayStatus": [],
                "PayMethods": [1, 2, 3, 4, 5, 6],
                "startTime": null,
                "endTime": null,
                "UserList": [],
                "UserDetails": [],
                "is_user": true,
                "ReceiptFrom": "",
                "ReceiptTo": "",
            },
            PageName: 'LabDayEndReport',
            cash: 0,
            card: 0,
            Upi: 0,
            bankTfr: 0,
            cheque: 0,
            credit: 0,
            loader: false,
            FilterOpen: false
        }
    }

    componentDidMount() {
        this.getLabDayEndReport()
    }

    getLabDayEndReport = () => {
        try {
            let states = this.state
            let User_ID = []
            this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            this.setState({ loader: true })
            RestAPIService.getAll(Serviceurls.LAB_DAY_END_REPORT_GET +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                // "&module_type=" + states.FilterDatas.BillType +
                // "&bill_type=" + states.FilterDatas.BillStatus +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo +
                "&user_id=" + User_ID
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        dayEndData: response.data.data?.data,
                        cash: response?.data?.data?.cash_amount,
                        card: response?.data?.data?.card_amount,
                        Upi: response?.data?.data?.upi_amount,
                        bankTfr: response?.data?.data?.bank_amount,
                        cheque: response?.data?.data?.cheque_amount,
                        credit: response?.data?.data?.total_credit_value,
                        totalReceiveAmt: response?.data?.data?.total_amount,
                        loader: false
                    })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                if (error?.response?.data?.status === "fail") {
                    this.errorMessage(error?.response?.data?.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.setState({ loader: false })
            this.errorMessage(error.message)
        }
    }

    getLabDayEndPrintData = () => {
        try {
            let User_ID = []
            this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let states = this.state
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let params = Serviceurls.LAB_DAY_END_REPORT_GET;

            RestAPIService.getAll(
                params +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                // "&module_type=" + states.FilterDatas.BillType +
                // "&bill_type=" + states.FilterDatas.BillStatus +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo +
                "&user_id=" + User_ID +
                "&export_type=pdf"
            ).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    gridToolBar = () => {
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) }
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray} title={"Day End Bills"} filename={"Day End Status"} />
            </Box>

        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.getLabDayEndReport() })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    PrintDetails = (data) => {
        this.setState({
            FilterDatas: data
        }, () => { })
    }

    render() {
        this.state.dayEndData?.forEach((element, index) => element.sno = index + 1)
        let columns = [
            {
                field: "bill_type", flex: 0.1, headerName: ("Bill Type"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_type ? this.getCapitalize(params?.row?.bill_type) : "-"}</Box>)
            },
            // {
            //     field: "bill_amount", flex: 0.1, headerName: t("Bill Amount"), hide: dayEndBasis === "1" ? true : false,
            //     renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_amount ? params?.row?.bill_amount : "0"}</Box>)
            // },
            {
                field: "cash", flex: 0.1, headerName: ('Cash') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.cash || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "card", flex: 0.1, headerName: ('Card') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.card || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "upi", flex: 0.1, headerName: ('UPI') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.upi || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "bank_transfer", flex: 0.1, headerName: ('Bank Transfer') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.bank_transfer || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "cheque", flex: 0.1, headerName: ('Cheque') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.cheque || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "total", flex: 0.1, headerName: ('Total') + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            // {
            //     field: "credit_value", flex: 0.1, headerName: ('Credit value') + ` (${CurrencySymbol})`, type: "number",
            //     renderCell: (params) => (<Box component={'div'}>{params?.row?.credit_value ? AmountFormat(params?.row?.credit_value)?.replace(`${CurrencySymbol}`, "") : params?.row?.credit_value === 0 ? "0" : "-"}</Box>)
            // }
        ]
        return (
            <Box component={'div'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '79vh' }}>
                    <Box component={'div'} height={'15vh'} display={'flex'} pt={'0.5vw'}>
                        <Box component={'div'} display={'flex'} flex={0.8}>
                            {AmountsCard("Total Received Amount", this.state.totalReceiveAmt, false, 'white', false, '', 'receive', "8vw")}
                            {AmountsCard("Cash", this.state.cash, false, 'white', false, '', 'cash', "8vw")}
                            {AmountsCard("Card", this.state.card, false, 'white', false, '', 'card', "8vw")}
                            {AmountsCard("UPI", this.state.Upi, false, 'white', false, '', 'upi', "8vw")}
                            {AmountsCard("Bank TFR", this.state.bankTfr, false, 'white', false, '', 'bank', "8vw")}
                            {AmountsCard("Cheque", this.state.cheque, false, 'white', false, '', 'cheque', "8vw")}
                            {/* {AmountsCard("Credit", this.state.credit, false, 'white', false, '', 'credit', "8vw")} */}
                        </Box>
                        <Box component={'div'} flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mr={'0.5vw'}>
                            <Box component={"div"} className='eMed_Filter_Icon_div'>
                                <Tooltip title="Filter" placement='top' arrow>
                                    <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                        <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                    </Button>
                                </Tooltip>
                            </Box>
                            <Box sx={{ borderRight: '1px solid #888888', height: '2vw', }}></Box>
                            <Tooltip title="Print" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.getLabDayEndPrintData() }}>
                                    <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                                </Button></Tooltip>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'64vh'} p={'0.5vw'}>
                        <DataGrid
                            sx={{ backgroundColor: Colors.white }}
                            rows={this.state.dayEndData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            hideFooterSelectedRowCount
                            hideFooter
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: this.gridToolBar,
                                NoRowsOverlay: () => (
                                    <Box className='eMed_conf_nodata'>
                                        {("No Records To Be Shown")}
                                    </Box>
                                )
                            }}
                            rowsPerPageOptions={[10, 20, 30]}
                            disableSelectionOnClick
                            pagination
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            loading={this.state.loader}
                        />
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter.bind(this) }}
                >
                    <BillingsFilter PageName={"LabDayEnd"} FilterDatas={this.state.FilterDatas} CloseFilter={this.closeFilter.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
            </Box>
        )
    }
}
