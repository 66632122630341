import { Box, Table, TableCell, TableHead, Stack, TableRow, TableBody, TableContainer, Paper, Button, Checkbox, TextField } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../Styles/Colors';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';

class BranchCustomConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsgText: '',
            isLoader: false,
            customConfigData: [
                { name: "Amoung branches, Common UHID", checked: false },
                { name: "Branch-to-Branch Stock Transfer", checked: false },
                { name: "Allow Password Reset", checked: false },
            ],
            common_uhid: false,
            branch_to_branch_stock_transfer: false,
            isAllowBranch: false,
            password_policy_enabled: false,
            password_policy_alert_period: 10,
            password_policy_block_period: 45,
            
        }
    }

    componentDidMount() {
        this.getCustomConfig()
    }

    getCustomConfig = () => {
        try {
            RestAPIService.getAll(Serviceurls.BRANCH_MOD_CUSTOM_CONFIG).
                then((response) => {
                    if (response.data?.status === "success") {
                        this.state.customConfigData[0]["checked"] = response.data.data[0]?.common_uhid
                        this.state.customConfigData[1]["checked"] = response.data.data[0]?.branch_to_branch_stock_transfer
                        this.state.customConfigData[2]["checked"] = response.data.data[0]?.password_policy_enabled
                        this.setState({
                            customConfigData: this.state.customConfigData,
                            isAllowBranch: response.data?.is_branch_transfer_allow,
                            password_policy_alert_period: response.data.data[0]?.password_policy_alert_period,
                            password_policy_block_period: response.data.data[0]?.password_policy_block_period,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    postCustomConfig = () => {
        try {
            let states = this.state
            if (states.customConfigData[2].checked && (+states.password_policy_alert_period < 5 ||
                +states.password_policy_block_period < 40)) {
                    if(+states.password_policy_alert_period < 5){
                        this.errorMessage('Alert Period should  Minmum 5 days')
                    }else if (+states.password_policy_block_period < 40){
                        this.errorMessage('Change Period should Minmum 40 days')
                    }
            }else{
                let data = {
                    common_uhid: states.customConfigData[0].checked,
                    branch_to_branch_stock_transfer: states.customConfigData[1].checked,
                    password_policy_enabled: states.customConfigData[2].checked,
                    password_policy_alert_period: +states.password_policy_alert_period || 10,
                    password_policy_block_period: +states.password_policy_block_period || 45,
                }
                RestAPIService.updateWithOutId(data, Serviceurls.BRANCH_MOD_CUSTOM_CONFIG).
                    then((response) => {
                        if (response.data?.status === "success") {
                            this.setState({
                                common_uhid: response.data?.data[0]?.common_uhid,
                                branch_to_branch_stock_transfer: response.data?.data[0]?.branch_to_branch_stock_transfer,
                                password_policy_alert_period: response.data?.data[0]?.password_policy_alert_period,
                                password_policy_block_period: response.data?.data[0]?.password_policy_block_period,
                            })
                            this.successMessage(response?.data?.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error?.message)
                        }
                    })
            }

        } catch (e) {
            this.errorMessage(e?.message)
        }

    }

    switchFunction = (name, newValue = null) => {
        let { customConfigData } = this.state
        switch (name) {
            case "Amoung branches, Common UHID":
                this.state.customConfigData[0]["checked"] = newValue
                this.setState({ customConfigData })
                break;
            case "Branch-to-Branch Stock Transfer":
                this.state.customConfigData[1]["checked"] = newValue
                this.setState({ customConfigData })
                break;
            case "Allow Password Reset":
                this.state.customConfigData[2]["checked"] = newValue
                this.setState({ customConfigData })
                break;
            default:
                break;
        }
    }

    BlockBills = (item) => {
        let ItemData = this.state.customConfigData?.find((list) => list.name === item.name)
        const checked = ItemData?.checked
        let allow = false
        if (item.name === 'Branch-to-Branch Stock Transfer') {
            allow = !this.state.isAllowBranch ? true : false
        }else {
            allow = false
        }
        return (
            <Checkbox disabled={allow} onChange={(e) => { this.switchFunction(item.name, e.target.checked) }} checked={checked} />
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    FeildItems = (item) => {
        let states = this.state
        return (
            <Box>
                {(item.name === "Amoung branches, Common UHID" || item.name === "Branch-to-Branch Stock Transfer") ? '-' :
                    <Box display={"flex"} flexDirection={'row'} alignItems={"center"} justifyContent={'space-between'}>
                        <Box display={"flex"} alignItems={"center"}>
                            <label style={{ fontSize: "0.9vw", fontWeight: "bold" }}>{"Change Period: "}</label>
                            <TextField
                                id="allowperiod"
                                variant="outlined"
                                disabled={!this.state.customConfigData[2].checked}
                                size='small'
                                style={{ width: '5vw', }}
                                value={this.state.password_policy_block_period}
                                inputProps={{ style: { height: '1vw', fontSize: '0.8vw' } }}
                                onChange={(e) => {
                                    let isValid = CommonValidation.numberOnly(e.target.value)
                                    if (e.target.value === "" || (isValid && e.target.value <= 356)) {
                                        this.setState({ password_policy_block_period: e.target.value })
                                    }
                                }} />
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <label style={{ fontSize: "0.9vw", fontWeight: "bold" }}>{"Alert Period: "}</label>
                            <TextField
                                id="allowperiod"
                                variant="outlined"
                                disabled={!this.state.customConfigData[2].checked}
                                size='small'
                                style={{ width: '5vw', }}
                                value={this.state.password_policy_alert_period}
                                inputProps={{ style: { height: '1vw', fontSize: '0.8vw' } }}
                                onChange={(e) => {
                                    let isValid = CommonValidation.numberOnly(e.target.value)
                                    if (e.target.value === "" || (isValid && e.target.value <= 10)) {
                                        this.setState({ password_policy_alert_period: e.target.value })
                                    }
                                }} />
                        </Box>
                    </Box>
                }
            </Box>

        )
    }

    render() {
        const { t } = this.props
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.Background }}>
                <Paper className="eMed_srvConfig_Div" >
                    <TableContainer component={'div'} className="eMed_ABill_Tbl">
                        <Table aria-label="collapsible table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Head">{t('S.No')}</TableCell>
                                    <TableCell sx={{ width: '50vw' }} className="eMed_ABill_Head">{t('Type')}</TableCell>
                                    <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Head">{t("Allow")}</TableCell>
                                    <TableCell sx={{ width: '30vw' }} className="eMed_ABill_Head">{t("Limit")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.customConfigData?.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Tblcell">{index + 1}</TableCell>
                                            <TableCell sx={{ width: '50vw' }} className="eMed_ABill_Tblcell">{item.name}</TableCell>
                                            <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Tblcell">
                                                {this.BlockBills(item)}
                                            </TableCell>
                                            <TableCell sx={{ width: '30vw' }} className="eMed_ABill_Tblcell">
                                                {this.FeildItems(item)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                            )}
                        </Table>
                    </TableContainer>
                    <div className='eMed_TestConfig_RightBtnCon'>
                        <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Button size='small' id='eMed_Btn_Text' variant='contained' emed_tid="CustomConfig_Save_Btn"
                                disabled={this.state.disableBtn}
                                onClick={() => { this.postCustomConfig() }}>{t("Save")}</Button>
                        </Stack>
                    </div>
                </Paper>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </Box>

        )
    }
}
export default withTranslation()(BranchCustomConfig)
