import { Box, Button, Stack, Tooltip, Typography, Drawer, TextField, IconButton, Menu, MenuItem } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import "./PharmaPurchase.css"
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { Colors } from '../../../Styles/Colors';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { clearCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTime } from 'luxon';
import CircularProgress from '@mui/material/CircularProgress';
 

class PharmaISSalesReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      fromDate: '',
      toDate: '',
      isLoader: false,
      PORequestData: [],
      trued : true,
    }
  }
  componentDidMount() {
    this.getPharmaSalesReport()

  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPharmaSalesReport = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.INTERNALSALES_SALES_REPORT}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              PORequestData: response?.data?.data ? response?.data?.data : [],
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.errorMessage(error?.message)
    }
  }
  getPharmaSalesReportPrint = (poid) => {
    let id = poid ? poid :""
    try {
      RestAPIService.getAll(Serviceurls.INTERNALSALES_SALES_REPORT + `?po_id=${id}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    } catch (error) {
      this.errorMessage(error?.message)
    }
  }
  
  getPharmaSalesReportExcel = () => {
    const { t } = this.props;
    // let { pharmacy_id, bill_year } = this.state;
    // let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
    try {
        this.setState({ disableBtn: true })
        RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_SALES_REPORT}?export=${"excel"}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
    } catch (error) {
        this.errorMessage(t("PrintError"))
        this.setState({ disableBtn: false })
    }
}

  render() {
     const { t } = this.props
    this.state.PORequestData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "sno", sortable: false, flex: 0.03, headerName: t("SNo"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "from_pharmacy", flex: 0.126, headerName: t("From Pharmacy"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.from_pharmacy?.length > 15 ?
          <Tooltip placement="top" title={row?.from_pharmacy} arrow><div>{row?.from_pharmacy.slice(0, 24) + "..."}</div></Tooltip>
          : row?.from_pharmacy ? row?.from_pharmacy : "-"}</div>)
      },
     
      // {
      //   field: "to_pharmacy", flex: 0.121, headerName: ("To Pharmacy"), headerAlign: "center", align: "center",
      //   renderCell: (params) => (<Box component={'div'}>{params?.row?.to_pharmacy ? params?.row?.to_pharmacy : "-"}</Box>)
      // },
      {
        field: "to_pharmacy", flex: 0.126, headerName: t("To Pharmacy"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.to_pharmacy?.length > 15 ?
          <Tooltip placement="top" title={row?.to_pharmacy} arrow><div>{row?.to_pharmacy.slice(0, 24) + "..."}</div></Tooltip>
          : row?.to_pharmacy ? row?.to_pharmacy : "-"}</div>)
      },
      {
        field: "po_id", flex: 0.111, headerName: t("PI Number"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.po_id?.length > 25 ?
          <Tooltip placement="top" title={row?.po_id} arrow><div>{row?.po_id.slice(0, 24) + "..."}</div></Tooltip>
          : row?.po_id ? row?.po_id : "-"}</div>)
      },
      {
        field: "is_no", flex: 0.101, headerName: t("IS No"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.is_no?.length > 15 ?
          <Tooltip placement="top" title={row?.is_no} arrow><div>{row?.is_no.slice(0, 18) + "..."}</div></Tooltip>
          : row?.is_no ? row?.is_no : "-"}</div>)
      },
     
      {
        field: "is_date", flex: 0.111, headerName: t("IS Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.is_date ? formatDate(params?.row?.is_date) : "-"}</Box>)
      },
      {
        field: "selling_price", flex: 0.101, headerName: t("Selling Price"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.selling_price ? params?.row?.selling_price?.toFixed(2) : "-"}</Box>)
      },
      {
        field: "mrp", flex: 0.101, headerName: t("MRP"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp  ? params?.row?.mrp  : "-"}</Box>)
      },
      {
        field: "total_drugs", flex: 0.081, headerName: t("Total Drugs"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_drugs ? params?.row?.total_drugs : "-"}</Box>)
      },
      {
        field: "action", flex: 0.111, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: ({ row }) => (
          <Box alignItems={"center"} display={'flex'} flexDirection={'row'}>
            <Tooltip title={t("PrintBill")} placement="top" arrow>
              <Button onClick={() => {this.getPharmaSalesReportPrint(row?.po_id) }} className='eMed_usrconf_btn' emed_tid='PO_requestList_print'>
                <img src={ImagePaths.PrintIcons.default} alt="Create Bill" style={{ width: '1.5vw', height: '1.5vw' }} />
              </Button>
            </Tooltip>
            {/* <Tooltip title={t("Edit Request")} placement="top" arrow>
              <Button disabled={row?.status === "Placed Order" ? false : true} onClick={() => { this.props.history.push({ pathname: '/PharmacyPurchase/PurchaseOrder/POrequest', states: { selectedID: row.id } }); setCachevalue(true, "IsPORequestEdit"); }} className='eMed_usrconf_btn' emed_tid='PO_requestList_print'>
                <img src={row?.status === "Placed Order" ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} alt="Create Bill" style={{ width: '1.5vw', height: '1.5vw' }} />
              </Button>
            </Tooltip> */}
          </Box>
        )
      }
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }} >
        <div className='eMed_Purchase_Table_top'>
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid="vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaSalesReportPrint()}>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid="vnd_export" className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn} onClick={() => this.getPharmaSalesReportExcel()}>
              <img src={ImagePaths.LabUploadButton.default} alt='upload' className='eMed_action_img' />
            </Button></Tooltip>
        </div>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.PORequestData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            // hideFooterSelectedRowCount
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            loading={this.state.isLoader}
          />
        </Box>
      </Box>
    )
  }
}

export default withTranslation()(PharmaISSalesReport)
