import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../Utility/ImagePaths'
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../Components/Common Components/CommonDatePicker';
import { localGetItem } from '../../Utility/Services/CacheProviderService';

class ApprovalFilters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FilterDatas: {
                "FromDate": this.props.FilterDatas.FromDate,
                "ToDate": this.props.FilterDatas.ToDate,
                "DateIndex": this.props.FilterDatas.DateIndex,
                "PatientName": this.props.FilterDatas.PatientName,
                "AccNo": this.props.FilterDatas.AccNo,
                "MobileNumber": this.props.FilterDatas.MobileNumber,
                "Status": this.props.FilterDatas.Status,
                "userName": this.props.FilterDatas.userName,
                "expenseFor": this.props.FilterDatas.expenseFor,
                "expenseCategory": this.props.FilterDatas.expenseCategory,
            },
            expenseCategoryList: this.props.expenseCategoryList,
            userNameList: this.props.userNameList,
        }
    }

    FilterDate = (From, To, Index) => {
        this.state.FilterDatas.FromDate = From
        this.state.FilterDatas.ToDate = To
        this.state.FilterDatas.DateIndex = Index
        this.setState({ FilterDatas: this.state.FilterDatas }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    ClearFilterData() {
        this.setState((prevState) => ({
            ...prevState,
            FilterDatas: {
                "FromDate": null,
                "ToDate": null,
                "DateIndex": 0,
                "PatientName": "",
                "AccNo": "",
                "MobileNumber": "",
                "Status": { value: "Pending", label: "Pending" },
                "userName": [],
                "expenseFor": "",
                "expenseCategory": "",
            }
        }))
    }

    FilterSearch() {
        this.setState({ FilterOpen: false });
        this.props.FilteredData(this.state.FilterDatas);
    }

    render() {
        const { t } = this.props
        const LoggedData = JSON.parse(localGetItem("loggedInUserInfo"));
        const StatusList = [
            { value: "Pending", label: "Pending" },
            { value: "Approved", label: "Approved" },
            { value: "Cancelled", label: "Cancelled" },
        ]
        const expenseForList = [
            { value: "Add", label: "Add" },
            { value: "Modify", label: "Modify" },
            { value: "Cancel", label: "Cancel" },
        ]
        return (
            <Box component={"div"} className='eMed_Filter_Billing_Container' sx={{ width: "23vw" }}>
                <Box component={"div"} className='eMed_Filter_Top'>
                    <Typography variant='h6' fontWeight={"600"}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }, () => { this.props.CloseFilter() }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box maxHeight={'73vh'} overflow={'scroll'}>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.FilterDatas.FromDate}
                            ToDate={this.state.FilterDatas.ToDate}
                            DateIndex={this.state.FilterDatas.DateIndex}
                            SelectedDates={this.FilterDate.bind(this)} />
                    </Box>
                    {this.props.pageName === "ExpenseApproval" ? null :
                        <>
                            <Box className='eMed_rts_dropdown'>
                                <TextField
                                    sx={{ width: "20vw" }}
                                    inputProps={{ maxLength: 13 }}
                                    size='small'
                                    label={t("Patient Name")}
                                    name={"FilterKey"}
                                    value={this.state.FilterDatas.PatientName}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        this.state.FilterDatas.PatientName = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }}
                                />
                            </Box>
                            <Box className='eMed_rts_dropdown'>
                                <TextField
                                    sx={{ width: "20vw" }}
                                    inputProps={{ maxLength: 13 }}
                                    size='small'
                                    label={t("UHID")}
                                    name={"FilterKey"}
                                    value={this.state.FilterDatas.AccNo}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        this.state.FilterDatas.AccNo = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }}
                                />
                            </Box>
                            <Box className='eMed_rts_dropdown'>
                                <TextField
                                    sx={{ width: "20vw" }}
                                    inputProps={{ maxLength: 13 }}
                                    size='small'
                                    label={t("Mobile Number")}
                                    name={"FilterKey"}
                                    value={this.state.FilterDatas.MobileNumber}
                                    autoComplete='off'
                                    onChange={(e) => {
                                        this.state.FilterDatas.MobileNumber = e.target.value
                                        this.setState({ FilterDatas: this.state.FilterDatas })
                                    }}
                                />
                            </Box>
                        </>
                    }
                    {this.props.pageName === "ExpenseApproval" ?
                        <>
                            {LoggedData?.is_user ? null : <Box className='eMed_rts_dropdown'>
                                <Autocomplete
                                    multiple
                                    limitTags={1}
                                    size='small'
                                    options={this.state.userNameList?.map((item) => item)}
                                    getOptionLabel={(option) => (option?.user_name)}
                                    disableCloseOnSelect
                                    disablePortal
                                    autoComplete='off'
                                    value={this.state.FilterDatas?.userName ? this.state.FilterDatas?.userName : null}
                                    onChange={(event, newValue) => {
                                        this.setState(prevState => ({
                                            FilterDatas: {
                                                ...prevState.FilterDatas,
                                                userName: newValue
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('sltUserName')}
                                        />
                                    )}
                                />
                            </Box>}
                            <Box className='eMed_rts_dropdown'>
                                <Autocomplete
                                    size='small'
                                    options={expenseForList?.map((item) => item)}
                                    getOptionLabel={(option) => (option?.label)}
                                    value={this.state.FilterDatas?.expenseFor ? this.state.FilterDatas?.expenseFor : null}
                                    onChange={(event, newValue) => {
                                        this.setState(prevState => ({
                                            FilterDatas: {
                                                ...prevState.FilterDatas,
                                                expenseFor: newValue
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Select Expense For')}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='eMed_rts_dropdown'>
                                <Autocomplete
                                    size='small'
                                    options={this.state.expenseCategoryList?.map((item) => item)}
                                    getOptionLabel={(option) => (option?.name)}
                                    value={this.state.FilterDatas?.expenseCategory ? this.state.FilterDatas?.expenseCategory : null}
                                    onChange={(event, newValue) => {
                                        this.setState(prevState => ({
                                            FilterDatas: {
                                                ...prevState.FilterDatas,
                                                expenseCategory: newValue
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('sltExpCategory')}
                                        />
                                    )}
                                />
                            </Box>
                        </>
                        : null}
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={StatusList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.label)}
                            value={this.state.FilterDatas?.Status ? this.state.FilterDatas.Status : null}
                            onChange={(event, newValue) => {
                                this.state.FilterDatas.Status = newValue
                                this.setState({
                                    FilterDatas: this.state.FilterDatas
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Status')}
                                />
                            )}
                        />
                    </Box>
                    <Box component={"div"} className="eMed_Filter_Btn_Div">
                        <Button size="small" className='eMed_Filter_Btns' onClick={() => { this.ClearFilterData() }} variant='outlined'>{t("Clear")}</Button>
                        <Button size="small" className='eMed_Filter_Btns' onClick={() => { this.FilterSearch() }} variant='contained'>{t("Search")}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}
export default withTranslation()(ApprovalFilters)
