import React, { Component } from 'react';
import {
    Box, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Stack, Typography, Autocomplete, Grid, Tooltip, Checkbox
} from "@mui/material";
import "./PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { Colors } from "../../../Styles/Colors";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc, AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import moment from 'moment';
import { CurrencySymbol } from '../../../Utility/Constants';
import PharmaDCRight from './PharmaDCRight';

let userAccess = null;

class PharmaDCview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            dc_number: "",
            billDate: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
            purchase_order_date: null,
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            purchase_from: "directPo",
            previous_vendor_bills: [],
            bill_id: null,
            open: false,
            open1: false,
            stockData: {},
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            returnQty: '',
            is_Return: false,
            return_Data: [],
            purchase_return_number: '',
            from_page: "",
            dc_number: null,
            dc_data: {}

        }
    }

    componentDidMount() {
        userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Delivery Challan', null, "Tab");
        this._getVendorList();
    }

    componentDidUpdate(prevProps) {
        let states = this.state;
        let history = prevProps.history?.location?.state;
        const isUser = !userAccess?.editAccess;

        if (history?.dc_data?.dc_number && history?.dc_data?.dc_number !== states.dc_number) {
            this.setState({
                dc_number: history?.dc_data?.dc_number,
                dc_data: history?.dc_data,
                from_page: history?.from_page ? history?.from_page : "",
                vendor_id: history?.dc_data?.vendor_id ? history?.dc_data?.vendor_id : "",
                cgstAmt: history?.dc_data?.cgst_amount?.toFixed(2),
                sgstAmt: history?.dc_data?.sgst_amount?.toFixed(2),
                billAmt: history?.dc_data?.total_amount?.toFixed(2),
                vendor_name: history?.dc_data?.vendor_name,
                isFinished: history?.dc_data?.is_finished || isUser,
            }, () => {
                this.DeliveryChallanList();
            })
        }
    }

    DeliveryChallanList = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PURCHASE_DC_POST + "?detailed_view=true" + "&dc_number=" + this.state.dc_number + "&vendor_id=" + this.state.vendor_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            lftTblData: response.data.data,
                        },() => {
                            this.state.lftTblData?.forEach(element => element.gen_grn = true)
                        })
                    }
                    else {
                        this.LoaderFunction(false);
                        this.errorMessage(response.data.message)
                    }
                }).catch((e) => {
                    this.LoaderFunction(false);
                    if (e?.response?.data?.message) {
                        this.errorMessgae(e.response.data.message)
                    } else {
                        this.errorMessgae(e.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getLastDateOfMonth(dateString) {
        try {
            // Parse the input date string
            const date = new Date(dateString);

            // Get the year and month from the input date
            const year = date.getFullYear();
            const month = date.getMonth();

            // Create a new date object set to the first day of the next month
            const nextMonth = new Date(year, month + 1, 1);

            // Subtract one day to get the last day of the current month
            const lastDateOfMonth = new Date(nextMonth - 1);

            // Format the date as 'YYYY-MM-DD'
            const formattedDate = lastDateOfMonth.toISOString().split('T')[0];

            return formattedDate;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AddToStock = () => {
        let states = this.state;

        let datas = {};
        let url = "";
        let method = "";
        let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;
        let list = []
        method = "create";
        datas = {
            dc_number: states.dc_number,
            dc_date: states.delivery_date,
            stock_items: [],
            vendor_id: states.vendor_id,
            is_finished: this.state.isSaved ? false : true
        }
        list = states.lftTblData
        list?.forEach((item, i) => {
            let tempdata = {
                bar_code: item.bar_code ? item.bar_code : "",
                batch_no: item.batch_no ? CommonValidation.removeSpace(item.batch_no) : "",
                buying_price: +item.buying_price,
                cgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.cgst_amount ? item.cgst_amount : 0,
                cgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.cgst_percentage ? item.cgst_percentage : 0,
                discount_amount: item.discount_amount ? +item.discount_amount : 0,
                discount_percentage: item.discount_percentage ? +item.discount_percentage : 0,
                drug_ms_id: item.drug_id ? +item.drug_id : +item.drug_ms_id,
                epr_value: +item.epr_value,
                expiry_alert_period: item.expiry_alert_period ? +item.expiry_alert_period : 3,
                expiry_date: this.getLastDateOfMonth(item.expiry_date),
                free_quantity: states.tabValue === "two" ? (+item.free_no_of_unit * + item.free_qty_per_unit) : item.free_quantity ? (+item.free_quantity * +item.pack_of_drugs) : 0,
                group: item.group ? item.group : "",
                hsn_code: item.hsn_code ? item.hsn_code : "",
                instructions: item.description ? item.description : item.instructions ? item.instructions : "",
                manufacturer_name: item.manufacturer_name ? item.manufacturer_name : "",
                mrp_per_pack: states.tabValue === "two" ? +item.free_mrp_unit : item.mrp_per_pack ? +item.mrp_per_pack : 0,
                mrp_per_quantity: item.mrp_per_quantity ? +item.mrp_per_quantity : 0,
                pack_of_drugs: states.tabValue === "two" ? +item.free_qty_per_unit : item.pack_of_drugs ? +item.pack_of_drugs : 0,
                physical_box_no: item.physical_box_no ? item.physical_box_no : "",
                recommended_by: item.recommended_by ? item.recommended_by : "",
                reorder_threshold: item.reorder_threshold ? +item.reorder_threshold : 0,
                sales_discount: item.sales_discount ? +item.sales_discount : 0,
                sales_discount_amount: item.sales_discount_amount ? +item.sales_discount_amount : 0,
                sales_unit: states.tabValue === "two" ? +item.free_no_of_unit : +item.sales_unit,
                schedule: item.schedule ? item.schedule : "",
                serial_no: i + 1,
                sgst_amount: item.total_gst_amount ? parseFloat((item.total_gst_amount / 2).toFixed(2)) : item.sgst_amount ? item.sgst_amount : 0,
                sgst_percentage: item.gst_percentage ? parseFloat((item.gst_percentage / 2).toFixed(2)) : item.sgst_percentage ? item.sgst_percentage : 0,
                stock_in_quantity: item.stock_in_quantity ? +item.stock_in_quantity : 0,
                total_buying_price: item.total_buying_price ? +item.total_buying_price : (item.buying_price && item?.sales_unit) ? (+item.buying_price * +item?.sales_unit) : 0,
                sales_discount_for_bill: states.tabValue === "two" ? +item.free_sales_discount_bills : item.sales_discount_for_bill ? +item.sales_discount_for_bill : 0,
                is_free_quantity: states.tabValue === "two" ? true : false,
                po_line_item_id: item.po_line_item_id ? item.po_line_item_id : null,
                stock_id: item?.stock_id
            }
            datas["stock_items"].push(tempdata)
        })
        try {
            this.setState({ saveLoad: true })

            RestAPIService.create(datas, Serviceurls.PURCHASE_DC_POST)
                .then(response => {
                    if (response.data.status === "success") {
                        this.handleClearData();
                        this.setState({
                            isSaved: false,
                            fromWanted: false,
                            isUpdateCall: false,
                            saveLoad: false
                        })
                        this.props.history.push({
                            pathname: "/PharmacyPurchase/DeliveryChallan", state: { DirectPoSuccessMsg: response.data.message }
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.setState({
                            isSaved: false,
                            saveLoad: false
                        })
                        this.errorMessage(error.response.data.message);
                    }
                });
        }
        catch (e) {
            this.errorMessage(e.message);
            this.setState({
                isSaved: false,
                saveLoad: false
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getVendorList = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            vendor_list: response.data.data,
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.errorMessage(error.response?.data?.message);
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }



    handleClearData = () => {
        let todate = new Date();
        this.setState({
            vendor_name: "",
            vendor_id: "",
            delivery_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            dc_number: "",
            billDate: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            purchase_order_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
            cgstAmt: "0.00",
            sgstAmt: "0.00",
            billAmt: "",
            purchase_order_number: "",
            lftTblData: [],
            vendor_list: [],
            selectedData: {},
            purchase_order_id: null,
            purchase_status: "",
            previous_vendor_bills: [],
            latest_vendor_bill: {},
            open: false,
            open1: false,

        })
    }


    handleOpen = (statekey) => {
        let states = this.state;
        states[statekey] = true;
        this.setState({
            states
        })
    }

    renderAutoSlt = (label, stateKey1, stateKey2, showkey, valueKey, Data = [], width) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <Autocomplete
                    size='small'
                    clearIcon={false}
                    readOnly={true}
                    sx={{ width: width ? width : '30.80vw' }}
                    options={Data}
                    getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                    value={states[stateKey1] ? states[stateKey1] : ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        states[stateKey1] = newValue[showkey] // value to be shown
                        states[stateKey2] = newValue[valueKey] // actual value
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                // type: 'search',
                                emed_tid: label,
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    renderDatePicker = (label, statekey, openMenu, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        readOnly
                        inputFormat='dd-MM-yyyy'
                        label={t(label) + " *"}
                        name={statekey}
                        value={moment(states.dc_data?.dc_date)}
                        autoComplete="off"
                        renderInput={(params) => <TextField
                            size="small"
                            onKeyDown={(e) => { e.preventDefault() }}
                            sx={{ width: width ? width : "9.8vw" }}
                            autoComplete="off"
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                emed_tid: label,
                            }}
                        />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderText(label, statekey, Noshow = false, mandatory = false, width) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_pharmadrctpo_txt">
                <TextField
                    label={t(label)}
                    disabled={true}
                    required={mandatory}
                    sx={{ width: width ? width : "10vw" }}
                    size="small"
                    autoComplete="off"
                    InputProps={{
                        readOnly: true,
                    }}
                    name={statekey}
                    value={statekey === "dc_number" ? (states[statekey] || "") :
                        states[statekey] ? AmountFormat(states[statekey] || 0)?.replace(`${CurrencySymbol}`, "") :
                            "0.00"}
                    inputProps={{ emed_tid: label }}
                    onChange={this.updateState}
                />
            </div>
        )
    }

    updateState = (event) => {
        let states = this.state;
        let { name, value } = event.target;
        states[name] = value
        if (name === "dc_number") {
            states.dc_number = value;
        }
        this.setState({
            states
        })
    }

    getAbsoluteFix = (num) => {
        let absoluteValue = Math.abs(num);
        let formattedValue = absoluteValue.toFixed(2);
        return AmountFormat(formattedValue)?.replace(`${CurrencySymbol}`, "");
    }

    AddToData = (list, isSelected, tabValue) => {
        const { t } = this.props;
        let states = this.state;
        let duplicate = states.lftTblData?.some(item => item?.drug_id === list?.drug_id)
        if (list) {
            let alreadyExist = states.lftTblData?.find(item => item.drug_id === list.drug_id && item.batch_no === list.batch_no);

            // if (states.EditIndex !== -1 && (list.edit_index === states.EditIndex) || (tabValue === "two" && list.edit_index === states.EditIndex) && alreadyExist === undefined) {
            //     states.lftTblData[states.EditIndex - 1] = list;
            //     states.tabValue = tabValue ? tabValue : "";
            // } else if (states.isNewBatch) {
            //     delete list.stock_id;
            //     list["newBatch"] = true;
            //     states.lftTblData.push(list);
            // } else if (alreadyExist) {
            //     this.errorMessage(`${alreadyExist?.brand_name} ${t("AlreadyExist")}`)
            // } else {
            states.lftTblData.push(list);
            // }
            this.setState({
                states,
                isselectedLineItem: isSelected,
                isNewBatch: isSelected,
                EditIndex: -1,
                selectedData: {}
            }, () => {
                let { lftTblData } = this.state;
                let initialObj = {
                    total_buying_price: 0,
                    total_gst_amount: 0
                }
                let totalAmount = lftTblData?.reduce((acc, cur, i) => {
                    let gst_amount = cur.total_gst_amount ? cur.total_gst_amount : (2 * cur.cgst_amount);
                    "serial_no" in cur ? cur["serial_no"] = cur["serial_no"] : cur["serial_no"] = i + 1;
                    acc["total_buying_price"] = parseFloat(acc.total_buying_price) + parseFloat(cur.total_buying_price ? cur.total_buying_price : 0);
                    acc["total_gst_amount"] = parseFloat(acc.total_gst_amount) + parseFloat(gst_amount ? gst_amount : 0);
                    return acc;
                }, initialObj)

                let Bill_Amt = this.state.paisa ? this.state.type_Paisa ? (+totalAmount.total_buying_price + (this.state.paisa / 100)) :
                    (+totalAmount.total_buying_price - (this.state.paisa / 100)) : +totalAmount.total_buying_price

                this.setState({
                    lftTblData,
                    cgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                    sgstAmt: totalAmount.total_gst_amount ? parseFloat((totalAmount.total_gst_amount / 2).toFixed(2)) : "0.00",
                    billAmt: totalAmount.total_buying_price ? totalAmount.total_buying_price.toFixed(2) : 0,
                    roundofAmt: +Bill_Amt.toFixed(2),
                },() => {
                    lftTblData?.forEach(element => element.gen_grn = true)
                })
            })
        }
    }

    IsClearEdit = () => {
        this.setState({
            EditIndex: -1,
            clearEditData: false
        })
    }

    render() {
        const { t } = this.props;
        let { lftTblData } = this.state
        let states = this.state;
        var allRowCheckTrue = lftTblData.filter((list) => list?.moved_to_po === false).every((data) => data.rowCheck === true)
        var enableBtn = lftTblData.some((data) => data.rowCheck === true)
        return (
            <Box paddingRight={'0.5vw'} id={"eMed_pharmadrctpo_Container"}>
                <Grid container spacing={3} id="eMed_pharmadrctpo_mainDiv">
                    <Grid item xs={7.6}>
                        <div id="eMed_pharmadrctpo_rhtDiv">
                            <Paper id="eMed_pharmadrctpo_paper1" elevation={1} >
                                <Stack direction="row" justifyContent={"flex-start"} alignItems={"center"} spacing={1} sx={{ height: "7vh" }}>
                                    {this.renderAutoSlt("VendorName", "vendor_name", "vendor_id", "name", "vendor_id", states.vendor_list, "35vw")}
                                    <Button variant='contained' sx={{ textTransform: 'capitalize' }}
                                        disabled={this.state.isFinished || this.state.saveLoad}
                                        onClick={() => this.setState({ isSaved: true ,saveLoad: true}, () => {
                                            this.AddToStock()
                                        })}
                                    >Save</Button>
                                    <Button variant='contained' sx={{ textTransform: 'capitalize' }}
                                        disabled={this.state.isFinished || this.state.saveLoad}
                                        onClick={() => this.setState({ isSaved: false ,saveLoad: true}, () => { {
                                            this.AddToStock()
                                        } })}
                                    >Finish</Button>
                                </Stack>
                                <div className="eMed_pharmadrctpo_subDiv">
                                    {this.renderText("DC No", "dc_number", true)}
                                    {this.renderDatePicker("DC Date", "dc_date", "open1", "10vw")}
                                    {this.renderText("CGSTAmount", "cgstAmt", true)}
                                    {this.renderText("SGSTAmount", "sgstAmt", true)}
                                    {this.renderText("BillAmount", "billAmt", true)}
                                </div>
                            </Paper>
                            <Box sx={{ backgroundColor: 'white', height: '57vh' }}>
                                <Box sx={{ backgroundColor: 'white', height: '53vh' }}>
                                    <TableContainer id='eMed_pharmaReturnPO_table' elevation={1} sx={{ backgroundColor: Colors.white }}>
                                        <Table stickyHeader size='small'>
                                            <TableHead className="eMed_pharmadrctpo_tblhead" sx={{ height: "5vh" }}>
                                                <TableRow>
                                                    <TableCell sx={{ flex: 0.05 }} id="eMed_pharmadrctpo_tblheadTxt">{
                                                        <Checkbox size='small' checked={allRowCheckTrue} onClick={(e) => {
                                                            var updatedData = [...lftTblData]
                                                            updatedData.forEach((data) => {
                                                                if (!data?.moved_to_po) {
                                                                    data.rowCheck = e.target.checked
                                                                    data.item_from_dc = true
                                                                    data.total_gst_amount = data?.cgst_amount ? data?.cgst_amount * 2 : 0
                                                                }
                                                            });
                                                            this.setState({
                                                                lftTblData: updatedData,
                                                            });
                                                        }} />
                                                    }</TableCell>
                                                    <TableCell sx={{ flex: 0.20 }} id="eMed_pharmadrctpo_tblheadTxt">{t("ProductDetails")}</TableCell>
                                                    <TableCell sx={{ flex: 0.10 }} id="eMed_pharmadrctpo_tblheadTxt">{t(`MRP (${CurrencySymbol})`)}</TableCell>
                                                    <TableCell sx={{ flex: 0.35 }} id="eMed_pharmadrctpo_tblheadTxt">{"Quantity"}</TableCell>
                                                    <TableCell sx={{ flex: 0.20 }} id="eMed_pharmadrctpo_tblheadTxt"> {t("PurchaseValue")}</TableCell>
                                                    <TableCell sx={{ flex: 0.10 }} id="eMed_pharmadrctpo_tblheadTxt">{t("NetAmount") + `(${CurrencySymbol})`}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="eMed_pharmadrctpo_tblhead">
                                                {lftTblData.length > 0 && lftTblData.map((data, index) => {
                                                    let gst_percentage = data.cgst_percentage ? (data.cgst_percentage * 2) : 0;
                                                    let gst_amount = data.cgst_amount ? (data.cgst_amount * 2) : 0;
                                                    let buying_price = data.total_buying_price && data.total_gst_amount ? (data.total_buying_price - data.total_gst_amount) : (data.total_buying_price - gst_amount);
                                                    let today = data.expiry_date ? new Date(data.expiry_date) : "";
                                                    let expiry_date = today ? DateTime.fromJSDate(today).toFormat("MM-yyyy") : null;
                                                    // let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * +data.free_qty_per_unit)
                                                    // : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                    // let salesQty = data?.sales_unit * data?.pack_of_drugs
                                                    // let stockQty = salesQty + (+freeQty)

                                                    let freeQty = data.tabValue === "two" ? (+data.free_no_of_unit * +data.free_qty_per_unit)
                                                        : (data.free_quantity && data.pack_of_drugs) ? (+data.free_quantity * +data.pack_of_drugs) : data.free_quantity;
                                                    // let salesQty = (data?.sales_unit && data?.pack_of_drugs) ? data?.sales_unit * data?.pack_of_drugs : (data?.quantity || data?.purchase_quantity)
                                                    let salesQty = data?.sales_unit * data?.pack_of_drugs
                                                    // let stockQty = (data?.sales_unit && data?.pack_of_drugs) ? salesQty + (+freeQty) : data.stock_in_quantity ? data.stock_in_quantity : ''
                                                    let stockQty = salesQty + (+freeQty)
                                                    let onlyFreeQty = (data?.is_free_quantity && data?.pack_of_drugs && data?.sales_unit) ? (data?.pack_of_drugs * data?.sales_unit) : (data?.free_no_of_unit * data?.free_qty_per_unit)
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ flex: 0.05 }}>
                                                                <Checkbox size='small' disabled={data?.moved_to_po} checked={data?.rowCheck === true} onClick={(e) => {
                                                                    var updatedData = [...lftTblData]
                                                                    updatedData[index] = {
                                                                        ...updatedData[index],
                                                                        rowCheck: e.target.checked,
                                                                        item_from_dc: true,
                                                                        total_gst_amount: gst_amount
                                                                    };
                                                                    this.setState({
                                                                        lftTblData: updatedData,
                                                                    });
                                                                }} />
                                                            </TableCell>
                                                            <TableCell sx={{ flex: 0.20 }}>
                                                                <Stack direction="column">
                                                                    <Typography id="eMed_pharmadrctpo_tblbodyTxt">{data.brand_name?.length > 13 ?
                                                                        <Tooltip placement="top" title={data.brand_name} arrow><div>{data.brand_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                        : data.brand_name ? data.brand_name : "-"}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">{data.generic_name?.length > 13 ?
                                                                        <Tooltip placement="top" title={data.generic_name} arrow><div>{data.generic_name.slice(0, 12) + "..."}</div></Tooltip>
                                                                        : data.generic_name ? data.generic_name : "-"}</Typography>
                                                                    <Typography id="eMed_pharmadrctpo_tblTxt">{data.dosage_type + "|" + data.dosage_strength}</Typography>
                                                                    {data.hsn_code ?
                                                                        <Typography id="eMed_pharmadrctpo_tblTxt">HSN Code:{data.hsn_code}</Typography>
                                                                        : null}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.10 }}>
                                                                {data.mrp_per_quantity ? this.getAbsoluteFix(data.mrp_per_quantity) : "0.00"}
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.35 }}>
                                                                <Stack direction="column">
                                                                    <Typography sx={{ textAlign: data.quantity && !(data.stock_in_quantity) ? "center" : null }}>

                                                                        {/* {(salesQty && data.free_quantity) ?
                                                                            (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0} + ${freeQty} (Free) `)
                                                                            : data.free_quantity || data.free_no_of_unit ? (`${stockQty ? stockQty : "0"} + ${freeQty} (Free) `)
                                                                                : data.stock_in_quantity} */}
                                                                        {data?.is_free_quantity ? `${data?.purchase_quantity ? data?.purchase_quantity : onlyFreeQty} (Free)` :
                                                                            ((data.quantity || salesQty) && data.free_quantity) ?
                                                                                (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0} + ${freeQty} (Free) `)
                                                                                : data.free_quantity || data.free_no_of_unit ? (`${stockQty ? stockQty : "0"} + ${freeQty} (Free) `)
                                                                                    : (data.quantity || data.purchase_quantity) ? (`${stockQty ? stockQty : ""} / ${salesQty ? salesQty : 0}`)
                                                                                        : data.current_quantity && data.free_quantity ? // From stock List
                                                                                            (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0} + ${freeQty} (Free) `)
                                                                                            : data.current_quantity ? (`${data.current_quantity ? data.current_quantity : 0} / ${data.stock_in_quantity ? data.stock_in_quantity : 0}`)
                                                                                                : data.stock_in_quantity}
                                                                    </Typography>
                                                                    {data.batch_no ? <Typography id="eMed_pharmadrctpo_tblTxt">Batch No: {data.batch_no}</Typography> : null}
                                                                    {expiry_date ? <Typography id="eMed_pharmadrctpo_tblTxt">Exp Date: {expiry_date}</Typography> : null}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.20 }}>{(data.gst_percentage || gst_percentage) != 0 ?
                                                                (`${buying_price ? this.getAbsoluteFix(buying_price) : "0.00" } + ${data.gst_percentage ? data.gst_percentage : gst_percentage ? gst_percentage : 0}% GST`)
                                                                : (data.total_buying_price || data.buying_price) ? (`${data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                                                    : data.buying_price ? this.getAbsoluteFix(data.buying_price) : ""} + 0% GST`) : "0.00 + 0% GST"}</TableCell>
                                                            <TableCell id="eMed_pharmadrctpo_Txtclr" sx={{ flex: 0.10 }}>{(data.total_buying_price || data.buying_price) ? (data.total_buying_price ? this.getAbsoluteFix(data.total_buying_price)
                                                                : data.buying_price ? this.getAbsoluteFix(data.buying_price) : "0.00") : "0.00"}</TableCell>
                                                        </TableRow>
                                                    )
                                                })

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box sx={{ width: '58.2vw', display: 'flex', justifyContent: 'center', backgroundColor: '#FAF9F9' }}>
                                    <Button variant='contained' size='small' disabled={enableBtn ? false : true} sx={{ textTransform: 'capitalize', width: '10vw' }}
                                        onClick={() => {
                                            let CheckedRow = this.state.lftTblData.filter((list) => (list?.rowCheck))
                                            let BillAmt = 0
                                            let CGST = 0
                                            let SGST = 0
                                            CheckedRow?.forEach((item, index) => {
                                                BillAmt += item?.total_buying_price
                                                CGST += item?.cgst_amount
                                                SGST += item?.sgst_amount
                                            })
                                            let PropData = {
                                                line_items: CheckedRow,
                                                vendor_name: this.state?.vendor_name,
                                                vendor_id: this.state?.vendor_id,
                                                billAmt: BillAmt,
                                                sgstAmt: SGST,
                                                cgstAmt: CGST,
                                                dc_number: this.state?.dc_number,
                                                dc_data: this.state?.dc_data,
                                            }
                                            this.props.history.push(
                                                {
                                                    pathname: "/PharmacyPurchase/PurchaseOrder/DirectPO"
                                                    , state: {
                                                        propsFromDc: PropData,
                                                        PoFromDc: true,
                                                        fromGrn:true,
                                                        notShowFinish:true
                                                    }
                                                })
                                        }}
                                    >{"Move to PO"}</Button>
                                </Box>
                            </Box>
                        </div>

                    </Grid>
                    <Grid item xs={4.4}>
                        {this.state.isFinished ? null :
                            <PharmaDCRight
                                EditData={this.state.selectedData}
                                PO_id={this.state.purchase_order_id ? this.state.purchase_order_id : null}
                                sendToList={this.AddToData.bind(this)}
                                tabValue={this.state.tabValue ? this.state.tabValue : "one"}
                                purchase_status={this.state.purchase_status}
                                errorMessage={this.errorMessage.bind(this)}
                                successMessage={this.successMessage.bind(this)}
                                LoaderFunction={this.LoaderFunction.bind(this)}
                                IsClearEdit={this.IsClearEdit.bind(this)}
                                editWithin={this.state.editWithin}
                                isAdmin={this.state.loggedInuserInfo?.is_branch_admin || this.state.loggedInuserInfo?.is_super_admin}
                                RightDataClear={this.state.clearEditData}
                                isFinished={this.state.isFinished}
                                fromWanted={this.state.fromWanted}
                                leftTblData={this.state.lftTblData}
                            />
                        }
                    </Grid>
                </Grid>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }

}
export default withTranslation()(PharmaDCview);