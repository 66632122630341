import { Box, Button, Tooltip, Stack, Drawer } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { AmountFormat, formatDate, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../Utility/Constants'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import PharmaFilter from '../PharmacyReports/PharmaFilter'
import { DateTime } from 'luxon'
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

let userAccess = null;
class PharmaCreditNoteHome extends Component {
    constructor(props){
        super(props)
        this.state={
            page: 0,
            pageSize: 10,
            rowCount: 0,
            successMsg: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsgText:'',
            creditNoteData: [],
            total_amount: 0,
            pageName: 'creditNote',
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": 30,
                "vendorList": []
            },
        }
    }

    componentDidMount = () => {
        const RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
        userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Purchase", "Credit Note", null, "Tab");
        this.getCreditNoteData()
        if (this.props.history?.location?.state?.successMsg) {
            this.successMessage(this.props.history?.location?.state?.successMsg)
            this.props.history.push({ state: {} })
        }
    }

    getCreditNoteData = () => {
        try{
            let vendorId = []
            this.state.filterDatas.vendorList?.forEach(element => vendorId?.push(element?.vendor_id))
            let url = ''
            if (this.props.isReport) {
                url = Serviceurls.PHARMA_CREDIT_NOTE_REPORT + `?vendor_id=${vendorId}&from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}`
            } else {
                url = Serviceurls.PHARMA_CREDIT_NOTE_REPORT
            }
            this.setState({isLoader: true})
            RestAPIService.getAll(url)
            .then((response)=>{
                if(response?.data?.status === "success"){
                    this.setState({
                        creditNoteData: response.data?.data,
                        total_amount: response?.data?.total_amount ? response?.data?.total_amount : 0
                    },()=>{
                        this.setState({isLoader: false})
                    })
                }
            }).catch((error)=>{
                if(error?.response?.data?.message){
                    this.errorMessage(error?.response?.data?.message)
                }else{
                    this.errorMessage(error.message)
                }
                this.setState({isLoader: false})
            })
        }catch(e){
            this.errorMessage(e.message)
            this.setState({isLoader: false})
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClosePopUp = () => {
        this.setState({filterOpen: false})
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getCreditNoteData() })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    render() {
        const { t } = this.props
        this.state.creditNoteData?.forEach((element, index) => element.serial = index + 1)
        const columns = [
            {
                field: "serial", flex: 0.01, headerName: t("SNo"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
            },
            {
                field: "vendor_name", flex: 0.14, headerName: t("Vendor Name"), headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<div>{row?.vendor_name?.length > 14 ?
                    <Tooltip placement="top" title={row?.vendor_name} arrow><div>{row?.vendor_name?.slice(0, 12) + "..."}</div></Tooltip>
                    : row?.vendor_name ? row?.vendor_name : "-"}</div>)
            },
            {
                field: "credit_number", flex: 0.14, headerName: t("Credit No"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.credit_number ? params?.row?.credit_number : "-"}</Box>)
            },
            {
                field: "credit_date", flex: 0.14, headerName: t("Credit Date"),
                renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.credit_date ? formatDate(params?.row?.credit_date) : "-"}</Box>)
            },
            {
                field: "no_of_items", flex: 0.14, headerName: t("No of Items"), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.no_of_items ? params?.row?.no_of_items : "-"}</Box>)
            },
            {
                field: "total_amount", flex: 0.14, headerName: t("Credit Value") + ` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        if (!this.props.isReport) {
            columns.push(
                {
                    field: "action", flex: 0.14, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <Tooltip title={userAccess?.editAccess ? t("Edit") : t("View")} placement="top" arrow>
                                <Button onClick={() => {
                                    this.props.history.push(
                                        {
                                            pathname: "/PharmacyPurchase/CreditNote/NewCreditNote"
                                            , state: {
                                                creditNoteData: params?.row,
                                                isEditAccess: userAccess?.editAccess
                                            },
                                        })
                                }} className='eMed_usrconf_btn'>
                                    <img src={userAccess?.editAccess ? ImagePaths.Edit.default : ImagePaths.View.default} alt="View" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                    )
                }
            )
        }

        return (
            <Box Component={'div'}>
                {this.props.isReport ?
                    <Box component={'div'} className='eMed_Pharma_rts_header'>
                        <Box component={'div'} flex={0.8} display={'flex'}>
                            {AmountsCard("Total Amount", this.state.total_amount, false, "white", false, null, 'pur_credit_total')}
                        </Box>
                        <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                            <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                                <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                            </Button>
                        </Box>
                    </Box>
                    :
                    <Box component={'div'} className='eMed_Purchase_Table_top'>
                        <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                            {userAccess?.editAccess && <Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.props.history.push({ pathname: '/PharmacyPurchase/CreditNote/NewCreditNote' }) }} >{`Credit Note`}</Button>}
                        </Box>
                    </Box> 
                }
                <Box component={'div'} height={this.props.isReport ? '63vh' : '70vh'}>
                    <DataGrid
                        rows={this.state.creditNoteData}
                        columns={columns}
                        getRowId={(row) => row['serial']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        hideFooterSelectedRowCount
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        headerHeight={40}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    number_of_items: false,
                                    state: false,
                                    igst: false
                                }
                            }
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        disableSelectionOnClick
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        loading={this.state.isLoader}
                    />
                </Box>
                {this.props.isReport ?
                    <Drawer
                        anchor={'right'}
                        open={this.state.filterOpen}
                        ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                    >
                        <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                    </Drawer> : null}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaCreditNoteHome)