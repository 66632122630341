import {
  Box, Button, IconButton, Menu, MenuItem, Table, TableBody,
  TableCell, TableHead, TableRow, Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientDetails } from '../../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../../Styles/Colors'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import ChangePaymentMode from '../../../../FrontOffice/BillingTransaction/ChangePaymentMode'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'

let isUser = null;

export default class RadReceiptBill extends Component {

  constructor(props) {
    super(props)
    this.state = {
      patientData: {},
      attender_mobile: '',
      attender_name: '',
      radReceiptBillData: []
    }
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    let patientData = JSON.parse(getCachevalue('RadPatientData'))
    this.setState({
      patientData: patientData
    }, () => this.getRadReceiptBillList())
  }

  getRadReceiptBillList = () => {
    try {
      let branchId = localGetItem('BranchId')
      let params = ''
      let date = this.state.patientData?.invoice_date?.slice(0, 10)?.split('-')
      let invoiceDate = date?.length > 0 ? date[2] + "-" + date[1] + "-" + date[0] : ""
      if (this.state.patientData?.ip_admission_id !== null && this.state.patientData?.ip_admission_id !== undefined) {
        params = `?module_id=${this.state.patientData?.ip_admission_id}&module=IP&branch_id=${branchId}&patient_id=${this.state.patientData?.patient_id}&date=${invoiceDate}`
      } else if (this.state.patientData?.appointment_id !== null && this.state.patientData?.appointment_id !== undefined) {
        params = `?module_id=${this.state.patientData?.appointment_id}&module=OP&branch_id=${branchId}&patient_id=${this.state.patientData?.patient_id}&date=${invoiceDate}`
      } else {
        params = `?patient_id=${this.state.patientData?.patient_id}&date=${invoiceDate}&module=RADIOLOGY&branch_id=${branchId}`
      }
      RestAPIService.getAll(Serviceurls.RAD_RECEIPT_BILL_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radReceiptBillData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  ClosePopUp() {
    this.setState({
      creditBillPopUp: false,
      ChangePaymentPopup: false,
    })
  }

  PaymentModeChanged(message) {
    this.setState({
      ChangePaymentPopup: false,
    }, () => {
      this.successMessage(message)
      this.getRadReceiptBillList()
    })
  }

  MenuItem = (data) => {
    const { t } = this.props
    let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    let PaymentChangeAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.change_payment_mode ? RoleData?.permission_access?.common?.change_payment_mode : false) : true
    const multi_option = [
      { value: "ChangePaymentMode", label: ("Change Payment Mode") },
    ]
    return (
      <div>
        <Tooltip title={("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} emed_tid={`${option?.value}`}
                disabled={!CheckAccessFunc('Radiology', "Home", 'Billing', 'Receipts', "SubTab")?.editAccess}
                onClick={() => {
                  this.setState({ anchorEl: null }, () => {
                    if (option.value === "ChangePaymentMode") {
                      data.patient_age = this.state.patientData?.patient_age
                      data.patient_uhid = this.state.patientData?.patient_account_number
                      data.mobile_number = this.state.patientData?.patients_mobile
                      data.patient_name = this.state.patientData?.name
                      this.setState({ ChangePaymentPopup: true, SelectedBill: data })
                    }
                  })
                }}>
                {(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  GetBillPrint = (id) => {
    try {
      this.setState({ disableBtn: true })
      let clinicID = localGetItem("loggedInUserInfo") !== null ? `&clinic_id=${JSON.parse(localGetItem("loggedInUserInfo"))?.clinic_id}` : ""
      RestAPIService.getAll(Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + id + clinicID).
        then((response) => {
          this.setState({ disableBtn: false })
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({ disableBtn: false })
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
      this.errorMessage(error.message, false)
    }
}

  render() {
    // let data = {
    //   lineOne: "Attender Name",
    //   lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
    //   lineFour: "Contact Number",
    //   LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    // }
    let visibility = this.props?.history?.location?.state?.fromCancelBill || this.props?.history?.location?.state?.fromCancelBill
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '73vh' }}>
          <Box component={'div'} height={"9.5vh"}>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'}>
                <CommonPatientDetails data={this.state.patientData} showDetailed={(this.state.patientData?.op_number || this.state.patientData?.ip_number) ? true : false} />
              </Box>
              {/* <CommonPatientCard details={data} showDetailed={true} /> */}
            </Box>
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} m={'0.5vw'} height={'62vh'} overflow={'auto'} sx={{ backgroundColor: Colors.white }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Date And Time</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Receipt No</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Payment Mode</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Receipt Type</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Paid In</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Created By</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Description</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head' align='right'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Total Bill Amount</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head' align='center'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.radReceiptBillData?.length > 0 ? this.state.radReceiptBillData?.map((item, index) => (
                  <TableRow>
                    <TableCell><Typography>{item?.invoice_date ? item?.invoice_date : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.receipt_number ? item?.receipt_number : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.payment_mode_types ? item?.payment_mode_types : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.receipt_type ? item?.receipt_type : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.collected_in ? item?.collected_in : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.created_by ? item?.created_by : '-'}</Typography></TableCell>
                    <TableCell>{item?.description ? item.description?.length > 15 ?
                      <Tooltip placement='top' title={item?.description} arrow><div>{item?.description?.slice(0, 15) + '...'}</div></Tooltip> :
                      <Typography>{item?.description}</Typography> : '-'}</TableCell>
                    <TableCell align='right'><Typography>{AmountFormat(item?.amount_received || 0)}</Typography></TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} sx={{visibility:visibility ? 'hidden' : 'visible'}}>
                        <Button className='Common_Btn_Min_Width' emed_tid='rad_rcp_prt' disabled={this.state.disableBtn} onClick={()=>{this.GetBillPrint(item?.id)}}>
                          <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                        {getCachevalue("isForCancelBill") === "true" ? null : this.MenuItem(item, index)}
                      </Box>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={9} align='center'>No Records To Be Shown</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.ChangePaymentPopup ? <ChangePaymentMode BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null}
      </Box>
    )
  }
}
