import { Box, Button, Divider, Paper, Stack, Tooltip, Typography, Drawer, Autocomplete, TextField, Switch, IconButton, Modal } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import {CommonGridToolBarWithFilterText, CommonPatientDetails, ConfirmPopup} from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, CheckAccessFunc, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { AmountsCard, PaymentsCard } from '../../../Components/CommonCards/CommonCards';
import { Bucket_Name, CurrencySymbol } from '../../../Utility/Constants';
import { Close } from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});
let RoleData = null;
let userAccess = null;
let statuslist = ["All","Specimen Not Collected","Specimen Collected","Transferred","Received","Result Uploaded"];



class OutsourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 10,
      rowCount: 0,
      totalTest:0,
      totalSpecimenNot:0,
      totalSpecimenCollect:0,
      totalReceived:0,
      totalTransmitted:0,
      totalResultUpload:0,
      FilterOpen: false,
      dateIndex: 0,
      testOutSourceLabGet: [],
      testOutsourceList: [],
      selectOutLabName: [],
      uploadedImages: [],
      selectedImage: {},
      selectedImageName: [],
      selectedImageRowId: null,
      selectedUploadData: [],
      uploadData: {},
      filt_status: "",
      testNameData: [],
      selectedDeletedIndex: [],
      PatientKey: "",
      testName: "",
      labName: "",
      statusKey: "All",
      datePicker: new Date(),
      selectedItem: {}
    }
    this.uploadImage = React.createRef();
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Home", 'Outsource List', null, "Tab");

    this.LabSourceList();
    this.getTestNameList();
    this.getOutSourceLabData();
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  
  
  getTestNameList = () => {
    try {
        RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?only_test_name=true")
            .then((response) => {
                if (response.data.status === 'success') {
                    this.setState({
                        testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                    })
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error?.response?.data?.message)
                } else {
                    this.errorMessage(error?.message)
                }
            })
    } catch (error) {
        this.errorMessage(error.message)
    }
  }

  removeDuplicate(data) {
    let nameListJsonObject = data.map(JSON.stringify);
    let nameListUniqueSet = new Set(nameListJsonObject);
    let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  getOutSourceLabData = () => {
    try {
        RestAPIService.getAll(Serviceurls.LAB_OUTSOURCE_CONFIG)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({
                        testOutSourceLabGet: response.data.data ? response.data.data : []
                    })
                }
            }).catch(e => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: e.message
                })
            })

    } catch (e) {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
        })
    }
  }

  LabSourceList(status) {
    try {
      this.setState({ disableBtn: true, filt_status: status });
      this.LoaderFunction(true);
      const statusFilter = this.state?.statusKey && this.state?.statusKey !== "All" 
        ? this.state.statusKey 
        : status || "";
      var StateDate = new Date(this.state.datePicker)
      var date = this.state.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null

      const queryParams = new URLSearchParams({
        status: statusFilter,
        patient_name: this.state?.PatientKey || "",

        bill_date: date,
        test_name: this.state?.testName ? this.state?.testName?.test_name : "",
        lab_id: this.state?.labName ? this.state?.labName?.id : ""
      }).toString();

      RestAPIService.getAll(`${Serviceurls.LAB_OUTSOURCE_LIST}?${queryParams}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ disableBtn: false });
            this.LoaderFunction(false);
            this.setState({
              testOutsourceList: response?.data?.data,
              rowCount: response?.data?.data?.total_count,
              totalReceived: response?.data?.total?.total_received,
              totalResultUpload: response?.data?.total?.total_result_uploaded,
              totalSpecimenCollect: response?.data?.total?.total_specimen_collected,
              totalSpecimenNot: response?.data?.total?.total_specimen_not_collected,
              totalTransmitted: response?.data?.total?.total_transferred,
              totalTest: response?.data?.total?.total_test,
              filt_status: status || "",
            }, () =>this.setDefaultLabSelection(response?.data?.data))
          }
          else {
            this.setState({ disableBtn: false });
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({ disableBtn: false });
          this.LoaderFunction(false);
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    }
    catch (e) {
      this.setState({ disableBtn: false });
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  getOutSourceListPrint = () => {
    try{
      this.setState({disableBtn: true})
      const statusFilter = this.state?.statusKey && this.state?.statusKey !== "All" 
      ? this.state.statusKey 
      : this.state?.filt_status || "";
      var StateDate = new Date(this.state.datePicker)
      var date = this.state.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null

      const queryParams = new URLSearchParams({
        status: statusFilter,
        patient_name: this.state?.PatientKey || "",
        bill_date: date,
        test_name: this.state?.testName ? this.state?.testName?.test_name : "",
        lab_id: this.state?.labName ? this.state?.labName?.id : "",
        export: "pdf",
      }).toString();
      RestAPIService.getAll(`${Serviceurls.LAB_OUTSOURCE_LIST}?${queryParams}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid = "outfilt_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={this.state.DatePickerOpen}
              onOpen={() => { this.setState({ DatePickerOpen: true }) }}
              onClose={() => { this.setState({ DatePickerOpen: false }) }}
              value={this.state?.datePicker}
              name={"datePicker"}
              label={'Bill Date'}
              inputFormat='DD-MM-YYYY'
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  this.setState({
                    datePicker: newDate,
                  });
                }}
              renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
            />
          </LocalizationProvider>
          </Box>
          <Box className='eMed_rts_dropdown'>
              <Autocomplete
                options={this.state.testOutSourceLabGet}
                getOptionLabel={(options) => typeof (options) === "string" ? options : options.outsource_laboratory_name}
                onChange={(e, value) => {
                  this.setState({
                    labName: value,
                  })
                }}
                value={this.state?.labName ? this.state?.labName : null}
                id="combo-box-demo"
                // sx={{ width: "29vw" }}
                renderInput={(params) => <TextField {...params} label={t("OutsourceLaboratoryName")} size='small' />}
              />
          </Box>
          <Box className='eMed_rts_dropdown'>
              <Autocomplete
                  size='small'
                  clearIcon
                  options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                  getOptionLabel={(option) => option?.test_name}
                  value={this.state?.testName ? this.state?.testName : null}
                  onChange={(e, newValue) => {
                      this.setState({ testName: newValue })
                  }}
                  sx={{ width: '20vw' }}
                  renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
              />
          </Box>
          <Box className='eMed_rts_dropdown'>
              <TextField
                  label={"Patient Name / UHID"}
                  sx={{ width: "20vw" }}
                  size='small'
                  autoComplete = "off"
                  value={this.state?.PatientKey}
                  onChange={(e) => {
                      this.setState({
                          PatientKey: e.target.value
                      })
                  }}
              />
            </Box>
            <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              disableClearable
              options={statuslist}
              getOptionLabel={(option) => (option)}
              value={this.state?.statusKey}
              onChange={(event, newValue) => {
                this.setState({ statusKey: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, 'emed_tid': 'outsoruce_status', }}
                  label={t('Select Status')}
                  autoComplete='off'
                />
              )}
            />
          </Box>     
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid = "fil_clear"
            onClick={() => {
              this.setState({
                datePicker: new Date(),
                PatientKey: "",
                testName: "",
                labName: "",
                statusKey: "All",  
              }, () => this.LabSourceList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid = "fil_search"
            disabled={this.state?.disableBtn}
            onClick={() => {
              this.LabSourceList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  
  handleTableCheckbox = (selectedIds) => {
    const { testOutsourceList, selectOutLabName } = this.state;

    // Check if "Select All" checkbox is selected
    const isSelectAll = selectedIds.length === testOutsourceList.filter(row => row.status === "Specimen Collected").length;
    
    if (isSelectAll) {
      // If "Select All" is clicked, validate if any row with "Specimen Collected" status has no lab selected
      const invalidRows = testOutsourceList.filter(
        (row) => row.status === "Specimen Collected" && 
                (!selectOutLabName?.[row.id] || !selectOutLabName[row.id].out_source_lab_id)
      );

      if (invalidRows.length > 0) {
        // Show error message if there are invalid rows
        this.errorMessage("Kindly Select Outsource Lab");

        // Remove all selected checkboxes if any invalid row exists
        this.setState({ selectedRowIds: [] });
        return;
      }
    }

    const selectedRows = testOutsourceList.filter((row) => selectedIds.includes(row.sno) );
      // Check if any row has no corresponding lab selected
      const invalidRows = selectedRows.filter(
        (row) => !selectOutLabName?.[row.id] || !selectOutLabName[row.id].out_source_lab_id );
      if (invalidRows.length > 0) {
        // Show error message if there are invalid rows
        this.errorMessage("Kindly Select Outsource Lab");
        // Filter out invalid rows from selection
        const validSelections = selectedIds.filter(
          (id) => !invalidRows.some((row) => row.sno === id)
        );
        // Update state with valid selections only
        this.setState({ 
          selectedRowIds: validSelections,
        });
        return;
      }
      const finalData = selectedRows.map((row) => {
        const selectedLab = selectOutLabName?.[row.id];
        return {
          id: row.id || null,
          status: row.status || null,
          outsource_lab_id: selectedLab ? selectedLab.out_source_lab_id : null,
        };
      });
    
      this.setState({ 
        selectTransmitData: finalData, 
        selectedRowIds: selectedIds,
      });
  }

  SendOutsourceLab = (item) => {
    const { selectTransmitData, selectOutLabName } = this.state;
    const { t } = this.props;
    try {
      let datas = {
        data: [],
        action: "Transferred",
      };
  
      if (item) { // Individual selection
        const selectedLabID = selectOutLabName?.[item?.id];
        
        if (!selectedLabID || !selectedLabID.out_source_lab_id) {
          this.errorMessage(t("Kindly Select Outsource Lab"));
          return; // Stop execution if lab is not selected
        }
        else{
          // Prepare data for individual selection
          const tempdata = {
            id: item.id,
            status: item.status,
            outsource_lab_id: selectedLabID.out_source_lab_id,
          };
          datas.data.push(tempdata);
        }
      } else { // Bulk selection
        if (selectTransmitData?.length > 0) {
          // Validate that all rows have a valid `outsource_lab_id`
          const invalidRows = selectTransmitData?.filter(
            (row) => !row.outsource_lab_id
          );
          if (invalidRows.length > 0) {
            this.errorMessage(t("Kindly Select Outsource Lab"));
            return; // Stop execution if any row is invalid
          }
          else{
            // Prepare data for bulk selection
          datas.data = [...selectTransmitData];
          }
        } 
      }
      
      // Proceed with API call if data is valid
      if (datas?.data?.length > 0) {  
        this.setState({ disableBtn: true });
        RestAPIService.updateWithOutId(datas, Serviceurls.LAB_OUTSOURCE_LIST)
          .then((response) => {
            if (response.data.status === "success") {
              this.LabSourceList();
              this.successMessage(response.data.message);
              this.setState({
                disableBtn: false,
                selectTransmitData: [], // Clear selected transmit data
                selectOutLabName: [],  // Clear selected lab names
                selectedRowIds: []
              });
            }
          })
          .catch((error) => {
            const errorMessage =
              error?.response?.data?.status === "fail"
                ? error.response.data.message
                : error.message;
  
            this.errorMessage(errorMessage);
            this.setState({ disableBtn: false });
          });
      } else {
        this.errorMessage(t("Kindly Select Any Data"));
      }
    } catch (e) {
      this.setState({ disableBtn: false });
      this.errorMessage(e.message);
    }
  };
  
  sendReceiveStatus = () => {
    try{
      let tempdata
      let datas = {
        data: [],
        action : "Received"
      }
      if (this.state.selectedItem) { // individual select
        tempdata = {
          "id": this.state.selectedItem?.id,
          "status": this.state.selectedItem?.status,
          "outsource_lab_id": this.state.selectedItem?.outsource_lab_id,
        };
        datas['data'].push(tempdata);
      }
      if(datas?.data?.length > 0){
      this.setState({disableBtn: true})
      RestAPIService.updateWithOutId(datas, Serviceurls.LAB_OUTSOURCE_LIST)
        .then(response => {
          if (response.data.status == "success") {

            this.LabSourceList();
            this.successMessage(response.data.message)
            this.setState({disableBtn: false, confirmationPopup: false, selectedItem: {}})
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
      } 
    }
    catch (e){
      this.setState({disableBtn: false})
      this.errorMessage(e.message)
    }
  }

  sendResultUpload = (item) => {
    try{
      let tempdata
      let datas = {
        data: [],
        action : "Result Uploaded"
      }
      if (item) { // individual select
        tempdata = {
          "id": item.id,
          "status": item.status,
          "outsource_lab_id": item.outsource_lab_id,
        };
        datas['data'].push(tempdata);
      }
      if(datas?.data?.length > 0){
      this.setState({disableBtn: true})
      RestAPIService.updateWithOutId(datas, Serviceurls.LAB_OUTSOURCE_LIST)
        .then(response => {
          if (response.data.status == "success") {

            this.LabSourceList();
            this.successMessage(response.data.message)
            this.setState({disableBtn: false})
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
      } 
    }
    catch (e){
      this.setState({disableBtn: false})
      this.errorMessage(e.message)
    }
  }
  
  gridToolBar = () => {
    const {t} = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText searchHolder = {t("search")}/>
      </Box>
    )
  }

  async sourceOpen(list, subIndex) {
    let tempArr = [];
    let image = list?.result_image ? JSON.parse(list.result_image) : []
    if (image !== null) {
      for (let j = 0; j < image?.length; j++) {
          let doc_key = image[j];
          let id = list.id;

          // Fetch data from S3 or your API
          let getData = await this.getS3Key(doc_key, id);

          // Add the `savedBefore` flag
          const imageDataWithFlag = {
              ...getData,  // Assuming getData returns the image object
              savedBefore: true,  // Set the flag indicating it's a saved image
          };
          tempArr.push(imageDataWithFlag);
      }
      this.setState({
        sourceOpen: true,
        selectedImageIndex: subIndex,
        selectedUploadData: list,
        uploadedImages: tempArr,
        selectedImage: null,
      });
  }
  }

  getS3Key(doc_key, id) {

    return new Promise(resolve => {
        var parms = {
            Bucket: Bucket_Name,
            Key: doc_key, Expires: 300
        };
        s3.getSignedUrl("getObject", parms, (err, url) => {
            if (err) {
                resolve(err)
            } else {
                var data = {
                    "data_url": url,
                    "id": id,
                    "type": doc_key?.split('.')?.pop() === "pdf" ? "pdf" : "image"
                }
                resolve(data)
            }
        })
    })
  }

  sourceClose = () => {
      this.setState({ sourceOpen: false })
      this.LabSourceList(this.state.filt_status);
  }

  onImageRemove = (index) => {
    let deletedArr = [...this.state.uploadedImages];
    deletedArr.splice(index, 1);
    this.setState({
        uploadedImages: [...deletedArr]
    })
  }

  onChange = (imageList, addUpdateIndex) => {
    this.setState({
        uploadedImages: [...this.state.uploadedImages, ...imageList.target.files]
    })
  };

  uploadOutsourceImage = (list) => {
    const { t } = this.props;
    try {
        const dataForm = new FormData();
        const { uploadedImages } = this.state;
        for (let i = 0; i < uploadedImages.length; i++) {
            if (!uploadedImages[i].id) { 
                // Ensures only new files are uploaded
                dataForm.append("result_images", uploadedImages[i]);
            }
            else{
              dataForm.append("result_images", []);
            }
        }
        dataForm.append("result_item_id", list.result_item_id);

        RestAPIService.patch(dataForm, Serviceurls.LAB_PATIENT_OUTSOURCE_PATCH)
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        uploadedImages: [],
                        selectedImage: {},
                        selectedImageName: [],
                        selectedImageRowId: null,
                        selectedUploadData: [],
                        uploadData: {},
                        selectedDeletedIndex: [],
                    }, ()=>{
                      this.successMessage(response.data.mesage)
                    });
                    this.sourceClose(); // Close the modal
                }
            })
        .catch((error) => {
          this.errorMessage(error.response?.data?.message || t("ImageError"))
        });
    } catch (error) {
      this.errorMessage(t("ImageError"))
    }
  };

  
  deleteUploadImages = (index, list) => {
    const { t } = this.props;
    try {
        // Parse the result_image string into an array
        let resultImageArray = list?.result_image ? JSON.parse(list.result_image) : [];

        // Check if the array is not null or empty
        if (resultImageArray && resultImageArray.length > 0) {
            // Remove the image at the specified index
            resultImageArray.splice(index, 1);  // Splice removes 1 item at the specified index
            
            // If the array is empty after removal, set result_image to null
            const updatedResultImage = resultImageArray.length > 0 ? JSON.stringify(resultImageArray) : null;

            // Prepare the data to send to the backend
            const data = {
                result_item_id: list?.result_item_id,
                result_images: updatedResultImage,  // Updated images array (or null)
            };

            // Call the API to delete the image from the server
            RestAPIService.delete(Serviceurls.LAB_PATIENT_RESULT_DELETE, data)
                .then((response) => {
                    if (response.data.status === "success") {
                        // After deletion, update the state
                        this.setState({
                            selectedImage: null,
                        },
                        () => {
                          // Optionally call any callback to update parent components or UI
                          this.onImageRemove(index); 
                          this.successMessage(response.data.message)
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        selectedImage: null,
                    }, ()=>{
                      this.errorMessage(error?.response?.data?.message)
                    });
                });
        } else {
            // Handle case when there are no images to delete
            this.errorMessage(t("NoImagesToDelete"))
        }
    } catch (error) {
      this.errorMessage(t("ImageError"))
    }
  };

  confirmPopupClose = () => {
    this.setState({
      confirmationPopup: false,
      selectedItem: {}
    })
  }

  renderSourcePopUp = (list, subIndex) => {
    const { t } = this.props
    return (
        <>
           <Modal
              open={this.state.sourceOpen}
              onClose={this.sourceClose} // Close when clicking outside or pressing Escape
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              footer={null}
              onCancel={this.sourceClose}
            >
              <Box className='emedhub_popup_outsource_list'>
                {/* Modal Header */}
                <div className='emedhub_popup_title'>
                  <label id="modal-title" className='emedhub_popup_title_left'>{t("TestReport")}</label>
                  <div className='emedhub_card_close_button'>
                    <img
                      className='emedhub_popup_title_right'
                      src={ImagePaths.LabCloseButton.default}
                      onClick={this.sourceClose}
                      alt="close"
                    />
                  </div>
                </div>

                {/* Modal Content */}
                <div className='emedhub_popup_title_2'>
                  {/* <Typography className='emedhub_popup_title_Header'>{t("SculptureTest")}</Typography> */}
                </div>
                <div className='emedhub_print_popup'>
                  {/* File Upload Section */}
                  <input
                    type="file"
                    multiple
                    accept="application/pdf"
                    onChange={(e) => this.onChange(e)}
                    ref={this.uploadImage}
                    style={{ display: "none" }}
                  />
                  <div className='image_top_header'>
                    <label className='test_name_style'><b>{t("TestName")}:</b>
                      <span style={{marginLeft:"5px"}}>{"  " + this.state?.selectedUploadData?.test_name}</span>
                    </label>
                      <div className='emedhub_image_upload_button'>
                        <button
                          className='image_upload_button'
                          onClick={() => this.uploadImage.current.click()}
                        >
                          {t("upload PDF")}
                        </button>
                      </div>
                    </div>

                  {/* Uploaded Images Preview */}
                  <div style={{ display: "flex", width: "100%", overflow: "auto", overflowY: "hidden" }}>
                    {this.state.uploadedImages?.map((item, index) => (
                      <div key={index} className='emedhub_multiple_image_size_outlist'>
                        <img
                          alt="Uploaded Preview"
                          src={item.type === "image" ? item["data_url"] : ImagePaths.DocumentPreview.default}
                          className='emedhub_multiple_image_resolution'
                          onClick={() => {
                            // If the item is a PDF and it was saved before, trigger download
                            if (item.type === "pdf" && item?.savedBefore) {
                              // Trigger PDF download (assuming item has a `data_url` or a URL that can be used for download)
                              const link = document.createElement("a");
                              link.href = item["data_url"];
                              link.download = `downloaded_file_${index}.pdf`; // Customize the filename if needed
                              link.click();
                            } else if (item.type === "application/pdf") {
                              // If it's a PDF but not saved before, open in a new window
                              window.open(item?.type === "pdf" ? item["data_url"] : URL.createObjectURL(item));
                            } 
                          }}
                        />
                          <IconButton
                            onClick={() => { 
                              this.setState({
                                selectedImage: null
                              })
                              if (item?.type === "image" || item?.type === "pdf") {
                                // Only call deleteUploadImages if item is an image or pdf
                                this.deleteUploadImages(index, this.state.selectedUploadData);
                              } else {
                                // Handle other types of deletions here if necessary
                                this.onImageRemove(index);
                              }
                              }
                            }
                            size='small'
                            className='emedhub_icon_style_outlist'
                          >
                            <Close fontSize='small' />
                          </IconButton>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Footer Buttons */}
                  <div className='emedhub_popup_button'>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        this.setState({ uploadedImages: [] }); // Reset uploaded images
                      }}
                      sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                    >
                      {t("Reset")}
                    </Button>
                    <Button
                      disabled= {this.state.disableBtn || this.state.uploadedImages?.length === 0}
                      variant='contained'
                      onClick={() => {
                        this.uploadOutsourceImage(this.state.selectedUploadData);
                      }}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {t("SaveChanges")}
                    </Button>
                  </div>
              </Box>
            </Modal>

        </>
    )
   }

    setDefaultLabSelection = (data) => {
      const updatedOutLabNames = {};
      data?.forEach((row) => {
        // Check if there's exactly one lab and if the status is "Specimen Collected"
        if (row?.laboratories?.length === 1 && row.status === "Specimen Collected") {
          updatedOutLabNames[row.id] = row.laboratories[0]; // Set the single lab as selected for this row
        }
      });

      if (Object.keys(updatedOutLabNames).length > 0) {
        this.setState((prevState) => ({
          selectOutLabName: {
            ...prevState.selectOutLabName, ...updatedOutLabNames, // Merge the new lab selections into the state
          },
        }));
      }
    };

  renderTable = () => {
    this.state.testOutsourceList.forEach((element, index) => {
      element.sno = index + 1
      element.patient_name = `${element?.patient_details?.first_name} ${element?.patient_details?.last_name}`
      element.patient_details.patient_name = `${element?.patient_details?.first_name} ${element?.patient_details?.last_name}`
    })
    const { t } = this.props
    const columns = [
      {
        field: "patient_name", flex: 0.12, headerName: t("Patient Details"), headerAlign: "left", align: "left",
        renderCell: (params) => (<Box component={'div'}>
          <CommonPatientDetails disablePhoto = {true} data={params?.row?.patient_details} />
      </Box>)
      },
      {
        field: "group_name", flex: 0.1, headerName: t("Group Name"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
           {params.row?.group_name?.length > 16 ?
            <Tooltip title={params.row?.group_name} placement='top' arrow>
              <Typography style={{fontSize: "1vw"}}>{params.row?.group_name.slice(0, 16) + '...'}</Typography>
            </Tooltip> : <Typography style={{ marginLeft: "0.2vw"}}>{params.row?.group_name ? params.row?.group_name : "-"}</Typography>
          }</Box>)
      },
      {
        field: "test_name", flex: 0.11, headerName: t("Test Details"), headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params.row?.test_name?.length > 18 ?
            <Tooltip title={params.row?.test_name} placement='top' arrow>
              <Typography style={{fontSize: "0.9vw"}}>{params.row?.test_name.slice(0, 18) + '...'}</Typography>
            </Tooltip> : <Typography>{params.row?.test_name ? params.row?.test_name : "-"}</Typography>
          }
          <Typography id="eMed_OP_AptTbl_AptText1">
          {`Billed On: ${params?.row?.bill_date
              ? `${new Date(params.row.bill_date).toLocaleDateString()} | ${new Date(params.row.bill_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
              : "- | -"
          }`}
        </Typography>
        </Box>
        )
      },
      {
        field: "specimen", flex: 0.12, headerName: t("Specimen Details"), headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>
            <Typography id='eMed_OP_AptTbl_AptText1' style={{display: "flex", alignItems: "center"}} fontWeight={'bold'}>{"Specimen :"}
            {params.row?.specimen?.length > 22 ?
            <Tooltip title={params.row?.specimen} placement='top' arrow>
              <Typography style={{ marginLeft: "0.2vw", fontSize :"0.8vw"}}>{params.row?.specimen.slice(0, 20) + '...'}</Typography>
            </Tooltip> : <Typography style={{ marginLeft: "0.2vw", fontSize :"0.8vw"}}>{params.row?.specimen ? params.row?.specimen : "-"}</Typography>
            }
          </Typography>
          <Typography id="eMed_OP_AptTbl_AptText1">
          {`Collected On: ${params?.row?.specimen_collected_date
              ? `${new Date(params.row.specimen_collected_date).toLocaleDateString()} | ${new Date(params.row.specimen_collected_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
              : "- | -"
          }`}
        </Typography>
        </Box>)
      },
      {
        field: "laboratories", flex: 0.12, headerName: t("Outsource Laboratory"),  headerAlign: "center", align: "center",
        renderCell: (params) => { 
          const rowIndex = params.row.id;
          return (
          <Box component={'div'}> 
          <Autocomplete
            size='small'
            sx={{ width: '12vw', mr: '0.5vw' }}
            disabled={!!params.row?.outsource_lab_name || params.row?.status !== "Specimen Collected"}
            options={params.row?.laboratories}
            getOptionLabel={(options) => typeof (options) === "string" ? options : options.out_source_lab_name}
            value={
              this.state.selectOutLabName?.[params.row.id] // Check if state already has the selection
              ? this.state.selectOutLabName[params.row.id] // Use the state value if it exists
              : params.row?.laboratories?.find(
                  (lab) => (lab.out_source_lab_id === params.row.outsource_lab_id && params.row.outsource_lab_id) // Fallback to row data
                ) || null // Fallback to null if no match is found
            }
            onChange={(e, value) => {
              const labId = value?.out_source_lab_id || null; 
              this.setState((prevState) => {
                const updatedOutLabNames = {
                  ...prevState.selectOutLabName,
                  [rowIndex]: value || null,  // Store the selected lab in selectOutLabName
                };
                const updatedTransmitData = prevState.selectTransmitData?.map((data) =>
                  data.id === rowIndex ? { ...data, outsource_lab_id: labId } : data
                );
                return { selectOutLabName: updatedOutLabNames,  selectTransmitData: updatedTransmitData };
              });    
            }}
            onKeyDown={(e) => e.stopPropagation()}
            renderInput={(params) => <TextField {...params} label='Select Laboratory' />}
          /></Box>);
        }
      },
      {
        field: "status", flex: 0.14, headerName: t("Status"),  type: "number",  headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} style={{width: "15vw"}}>
          { 
            <Box component={'div'} className = {params.row?.status === "Specimen Not Collected" ? "eMed_Lab_Status_specimenNot"
              : params.row?.status === "Specimen Collected" ? "eMed_Lab_Status_specimen"
              : params.row?.status === "Transferred" ? "eMed_Lab_Status_transfer"
              : params.row?.status === "Received" ? "eMed_Lab_Status_Received"
              : params.row?.status === "Result Uploaded" && "eMed_Lab_Status_ResultUpload"
              }>
              <img src={params.row?.status === "Specimen Not Collected" ? ImagePaths.LabSpecimenNot.default 
                : params.row?.status === "Specimen Collected" ? ImagePaths.LabSpecimen.default 
                : params.row?.status === "Transferred" ? ImagePaths.LabTransferred.default 
                : params.row?.status === "Received" ? ImagePaths.LabReceived.default 
                : params.row?.status === "Result Uploaded" ? ImagePaths.GreenTick.default 
                : null} style={{padding: "9px"}}></img><Typography>{params.row?.status}</Typography>
            </Box>
          }
        </Box>)
      }
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {(params.row?.status === "Specimen Collected" || params.row?.status === "Specimen Not Collected") ?
              <Tooltip title={t("Transfer")} placement="top" arrow>
              <div>
                <Button emed_tid="out_Transfer" Color = {Colors.disableBtn} className='eMed_usrconf_btn' disabled={(params.row?.status === "Specimen Collected" && !this.state.disableBtn) ? false : true} onClick={() => { this.SendOutsourceLab(params.row) }}>
                  <img src={params.row?.status === "Specimen Collected" ? ImagePaths.LabTransfer.default : ImagePaths.LabTransferDbsl.default} alt="cart" className='eMed_action_img' />
                </Button>
              </div>
            </Tooltip> :
            <Tooltip title={t("Receive")} placement="top" arrow>
            <div>
              <Button emed_tid="out_Receive" className='eMed_usrconf_btn' disabled={(params.row?.status === "Transferred" && !this.state.disableBtn) ? false : true} onClick={() => {this.setState({ confirmationPopup: true, selectedItem: params.row })}}>
                <img src={params.row?.status === "Transferred" ? ImagePaths.LabReceive.default : ImagePaths.LabReceiveDbsl.default } alt="Receive" className='eMed_action_img' />
              </Button>
            </div>
            </Tooltip>
            }
            <Tooltip title={t("Result Upload")} placement="top" arrow>
                <div>
                  <Button emed_tid="out_upload" className='eMed_usrconf_btn' disabled = {(params.row.status !== "Received" && params.row.status !== "Result Uploaded" )} 
                  onClick={() => { this.setState({ selectedImageRowId: params.row.id,  uploadData: params.row })
                                   this.sourceOpen(params.row, params.row.id) }}>
                    <img src={params.row?.status === "Received" || params.row?.status === "Result Uploaded" ? ImagePaths.LabUpload.default : ImagePaths.DisableUpload.default }
                     alt="Upload" className='eMed_action_img' />
                  </Button>
                </div>
              </Tooltip>
            {/* {this.renderSourcePopUp(params.row, params.row.id) } */}
          </Box>
        }
      );
    }

    return (
      <Box component={"div"} className='eMed_Pharma_rts_tbl_card'>
        {/* <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' > */}
            <DataGrid
              className='eMed_Pharma_table'
              isRowSelectable={(params) => (userAccess?.editAccess && params.row.status === "Specimen Collected")}
              checkboxSelection = {userAccess?.editAccess}
              onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
              rows={this.state.testOutsourceList}
              selectionModel={this.state.selectedRowIds} 
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              loading={this.state.isLoader}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={45}
              rowHeight={60}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowCount={this.state.rowCount}
            />
          {/* </Paper>
        </Stack> */}
      </Box>
    ) 
  }
  render() {
  const {t} = this.props;
    return (
      <div> 
          <Box component={'div'} className='eMed_Pharma_rts_header'>
            <Box component={'div'} flex={0.8} display={'flex'}>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("")}>{AmountsCard("Total Test", this.state.totalTest, true, this.state?.filt_status === "" ? "#04b7b1" : "white",false,null)}</Box>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("Specimen Not Collected")}>{AmountsCard("Total Specimen Not Collected", this.state.totalSpecimenNot, true, this.state?.filt_status=== "Specimen Not Collected" ? "#04b7b1" : "white",false,null)}</Box>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("Specimen Collected")}>{AmountsCard("Total Specimen Collected", this.state.totalSpecimenCollect, true, this.state?.filt_status=== "Specimen Collected" ? "#04b7b1" : "white",false,null)}</Box>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("Transferred")}>{AmountsCard("Total Transferred", this.state.totalTransmitted, true, this.state?.filt_status=== "Transferred" ? "#04b7b1" : "white",false,null)}</Box>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("Received")}>{AmountsCard("Total Received", this.state.totalReceived, true, this.state?.filt_status=== "Received" ? "#04b7b1" : "white",false,null)}</Box>
              <Box style = {{cursor:"pointer"}} onClick={() => this.LabSourceList("Result Uploaded")}>{AmountsCard("Total Result Uploaded", this.state.totalResultUpload, true, this.state?.filt_status=== "Result Uploaded" ? "#04b7b1" : "white",false,null)}</Box>
            </Box>
            <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
              <Button variant="contained" id = "eMed_Btn_Text"  sx={{ width: "13vw" }}
                disabled = {this.state.disableBtn || !userAccess?.editAccess}
                onClick={() => { this.SendOutsourceLab()
              }}>{"TRANSFER TEST"}</Button>
            <Tooltip title="Filter" placement='top' arrow>
              <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' style={{height:'2.3vw',width:'2.3vw'}} />
              </Button>
            </Tooltip>
              <Tooltip title="Print" placement='top' arrow>
                <Button  emed_tid = "outpay_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getOutSourceListPrint()}>
                  <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' style={{height:'2.3vw',width:'2.3vw'}}/>
                </Button></Tooltip>
            </Box>
          </Box>

        {this.renderTable()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.confirmationPopup ?
          <ConfirmPopup
            cnfTitle={'Confirmation'}
            cnfContent1={'Are you sure, Do you want to receive ?'}
            cnfAlertPopupClose={this.confirmPopupClose.bind(this)}
            cnfData={this.sendReceiveStatus.bind(this)}
          />
          : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.sourceOpen ? this.renderSourcePopUp(this.state.uploadData, this.state.selectedImageRowId) : null}
      </div>
    )
  }
}
export default withTranslation()(OutsourceList)

