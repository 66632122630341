import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { CurrencySymbol } from '../../../Utility/Constants';

class GroupwiseSummaryReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {},
            GroupwiseSummaryData: [],
            GroupDataList: [],
            Groupsearch: [],
            GroupTotalCount: 0,
            GroupTotalAmount: 0,
            DepartDataList: [],
            Departsearch: null,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getDeptList()
        this.getGroupWiseData()
        this.getTestGroupData()
    }

    getDeptList = () => {
        try {
            RestAPIService.getAll(Serviceurls.GET_LAB_DEPARTMENT_MASTER)
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        var DeptData = response.data?.data ? response.data?.data : []
                        this.setState({
                            DepartDataList: DeptData
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
 LoaderFunction=(key)=>{
    this.setState({
        isLoader:key
    })
 }
    getGroupWiseData = () => {
        var { Departsearch } = this.state
        try {
            this.LoaderFunction(true)
            let groupName = []; this.state.Groupsearch.forEach(element => groupName.push(element.name))
            let departName = Departsearch?.dept_name ? Departsearch?.dept_name : ""
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.LAB_GROUP_BASED_LIST_GET + `?report_type=overview&grp_name=${groupName}&dept_name=${departName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            GroupwiseSummaryData: response.data.data.result,
                            GroupTotalCount: response.data.data?.total_count,
                            GroupTotalAmount: response.data.data?.total_amt,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                            disableBtn: false,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    getTestGroupData = () => {
        var { Departsearch } = this.state
        let departName = Departsearch?.id ? Departsearch?.id : ""
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET + `?dept_id=${departName}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            GroupDataList: response.data.data
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={this.state.DepartDataList}
                            getOptionLabel={(option) => (option?.dept_name)}
                            value={this.state.Departsearch}
                            onChange={(event, newValue) => {
                                this.setState({ Departsearch: newValue, Groupsearch: [] },() => {this.getTestGroupData()})
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Department')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.GroupDataList}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.Groupsearch}
                            onChange={(event, newValue) => {
                                this.setState({ Groupsearch: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Group')}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                Groupsearch: [],
                                Departsearch: null,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            },
                                () => this.getGroupWiseData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='GroupWiseSummary_Report_FilterSearch_btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getGroupWiseData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        this.state.GroupwiseSummaryData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "invoice_date", headerName: t("Date"), flex: 0.133, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? formatDate(params?.row?.invoice_date) : '-'}</Box>)
            },
            {
                field: "dept_name", headerName: t("Department Name"), flex: 0.153,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.dept_name?.length > 25 ?
                    <Tooltip placement='top' title={params?.row?.dept_name} arrow><div>{params?.row?.dept_name.slice(0, 25) + "..."}</div></Tooltip>
                    : params?.row?.dept_name ? params?.row?.dept_name : "-"}</Box>)
            },
            {
                field: "grp_name", headerName: t("Group Name"), flex: 0.153,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.grp_name?.length > 25 ?
                    <Tooltip placement='top' title={params?.row?.grp_name} arrow><div>{params?.row?.grp_name.slice(0, 25) + "..."}</div></Tooltip>
                    : params?.row?.grp_name ? params?.row?.grp_name : "-"}</Box>)
            },
            {
                field: "count", headerName: `${t("Count")}`, flex: 0.133, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.count ? params?.row?.count : "0"}</Box>)
            },
            {
                field: "total_amt", headerName: `${t("Total Amount")} (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total_amt || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Count"), this.state.GroupTotalCount, true)}
                        {AmountsCard(t("TotalAmount"), this.state.GroupTotalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        {/* <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.GroupwiseSummaryData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        loading={this.state.isLoader}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(GroupwiseSummaryReport)