import {
  Box, Button, Drawer, Stack, Tooltip, Typography,
  IconButton,
} from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DateTime } from 'luxon'
import { CommonStockTransferPopup } from '../../../Components/Common Components/SubLocCommonComponents'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import Loader from '../../../Components/Loader';
import SubLocReportFilter from '../../SubLocation/Reports/SubLocReportFilter'
import { formatDate, AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { darken, lighten, styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { withTranslation } from 'react-i18next';
import { CurrencySymbol } from '../../../Utility/Constants';


class PharmaStockTfrReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transferReportData: [],
      page: 0,
      pageSize: 10,
      filterDatas: {
        fromDate: '',
        toDate: '',
        "dateIndex": 0,
      },
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      pageName: "PhramaStockTransferReport",
      filterOpen: false,
      isReceiveClicked : false,
      selectedData: {},
      selectedRowId: null,
      selectedRow: null
    }
  }

  componentDidMount() {
    this.getStockTransferReportData()
  }

  getStockTransferReportData = () => {
    try {
      this.LoaderFunction(true)
      var {filterDatas} = this.state
      RestAPIService.getAll(Serviceurls.SUBLOC_STOCK_TRANSFER + 
        `?transmit_type=report&from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&transfer_type=${filterDatas.trans_type ? filterDatas.trans_type : ''}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              transferReportData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
        this.getStockTransferReportData()
    })
  }

  gridToolBar = () => {
    let TextArray = []
    if(this.state.filterDatas.fromDate !== '' && this.state.filterDatas.toDate !== ''){
      TextArray = [
        { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
        { label: "To Date", value: formatDate(this.state.filterDatas.toDate) }
      ]
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  popupClose = () => {
    this.setState({
      isReceiveClicked: false,
      selectedData: {},
      selectedRowId: null,
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPrint = (id) => {
    try {
      RestAPIService.getAllPrint(Serviceurls.SUBLOC_LINE_OF_ITEM + `?request_id=${id}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((e) => {
          if (e?.reponse?.data?.message) {
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.errorMessage(e?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  MoreMenus = (data) => {
    const { t } = this.props;
    const styles = {fontSize: "0.85dvw"};
    const tooltipLines = [
      <div style={styles} key="request-reason">
        <strong style={styles}>{t("Request Reason")}:</strong> {data?.request_reason || t("No reason available")}
      </div>,
    ];

    if (data?.cancel_reason) {
      tooltipLines.push(<div style={styles} key="approve-reason">
        <strong style={styles}>{t("Cancel Reason")}:</strong> {data?.cancel_reason}
      </div>)
    } else {
      tooltipLines.push(<div style={styles} key="approve-reason">
        <strong style={styles}>{t("Approve Reason")}:</strong> {data?.approved_reason || t("No reason available")}
      </div>,
        <div style={styles} key="transfer-reason">
          <strong style={styles}>{t("Transfer Reason")}:</strong> {data?.transfer_reason || t("No reason available")}
        </div>)
    }

    return (
      <div>
        <Tooltip title={<div>{tooltipLines}</div>} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                selectedRow: data?.row?.id,
                isSelected: true
              });
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
}

  render() {
    this.state.transferReportData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'request_number', headerName: 'Request No', flex: 0.111,
        renderCell: (params) => ((params?.row?.is_cancel && params?.row?.status === 'Cancelled') ? 
        <Tooltip title={params?.row?.cancel_reason}><Typography>{params?.row?.request_number ? params?.row?.request_number : '-'}</Typography></Tooltip> :
        <Box>{params?.row?.request_number ? params?.row?.request_number : '-'}</Box>
        )
      },
      {
        field: 'request_date', headerName: 'Request Date', flex: 0.111,headerAlign:'center',align:'center',
        renderCell: (params) => ((params?.row?.is_cancel && params?.row?.status === 'Cancelled') ? 
        <Tooltip title={params?.row?.cancel_reason}><Typography>{params?.row?.request_date ? params?.row?.request_date : '-'}</Typography></Tooltip> :
        <Box>{params?.row?.request_date ? params?.row?.request_date : '-'}</Box>
        )
      },
      {
        field: 'transfer_from', headerName: 'Transferred From', flex: 0.111,
        renderCell: ({ row }) => {
          const displayText = <Box component={'div'}>
            {row?.transfer_from?.length > 16 ?
              <Tooltip placement='top' title={row?.transfer_from} arrow>
                <div>{row?.transfer_from.slice(0, 16) + "..."}</div>
              </Tooltip>
              : row?.transfer_from || "-"}</Box>;

          return ((row?.is_cancel && row?.status === 'Cancelled') ?
            <Tooltip title={row?.cancel_reason}>{displayText}</Tooltip> : displayText)
        }
      },
      {
        field: 'transfer_to', headerName: 'Transferred To', flex: 0.111,
        renderCell: ({ row }) => {
          const displayText = <Box component={'div'}>
            {row?.transfer_to?.length > 16 ?
              <Tooltip placement='top' title={row?.transfer_to} arrow>
                <div>{row?.transfer_to?.slice(0, 16) + "..."}</div>
              </Tooltip>
              : row?.transfer_to || "-"}</Box>;

          return ((row?.is_cancel && row?.status === 'Cancelled') ?
            <Tooltip title={row?.cancel_reason}>{displayText}</Tooltip> : displayText)
        }
      },
      {
        field: 'no_of_product', headerName: 'No Of Products', flex: 0.111, type: 'number',
        renderCell: (params) => ((params?.row?.is_cancel && params?.row?.status === 'Cancelled') ? 
        <Tooltip title={params?.row?.cancel_reason}><Typography>{params?.row?.no_of_product ? params?.row?.no_of_product : '-'}</Typography></Tooltip> :
        <Box>{params?.row?.no_of_product ? params?.row?.no_of_product : '-'}</Box>)
      },
      {
        field: 'total_cost', headerName: `Total Cost (${CurrencySymbol})`, flex: 0.111, type: 'number',
        renderCell: ({ row }) => {
          const totalCost = AmountFormat(row?.total_cost)?.replace(`${CurrencySymbol}`, "");
          return ((row?.is_cancel && row?.status === 'Cancelled') ?
            <Tooltip title={row?.cancel_reason}><Typography>{totalCost}</Typography></Tooltip> :
            <Box>{totalCost}</Box>)
        }
      },
      {
        field: 'transit_name', headerName: 'Transit Details', flex: 0.111,
        renderCell: (params) => ((params.row.transit_name && params.row.transit_number) ? 
        <Box>
            {params?.row?.transit_name?.length > 15 ? 
            <Tooltip title={params?.row?.transit_name} placement='top'><Typography sx={{fontWeight: 'bold'}}>{params?.row?.transit_name.slice(0, 15) + "..."}</Typography></Tooltip> : params?.row?.transit_name ? params?.row?.transit_name : '-'}
            <Typography sx={{color: Colors.themeDark}}>{params?.row?.transit_number ? params.row.transit_number : '-'}</Typography>
        </Box>  : '-')
      },
      {
        field: 'approved_by', headerName: 'Approved By', flex: 0.111,
        renderCell: (params) => (<Box>{params?.row?.approved_by ? params?.row?.approved_by : '-'}</Box>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.111,align:'center',headerAlign:'center',
        renderCell: ((params) => (
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Button className='eMed_common'
              onClick={() => {
              this.setState({
                isReceiveClicked: true,
                selectedData: params?.row,
                selectedRowId: params?.row?.id
              })
             }}>
              <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'2vw'} width={'2vw'} />
            </Button>
            <Button className='eMed_common'
              onClick={() => {this.getPrint(params?.row?.id)}}>
              <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
            </Button>
            <Box>{this.MoreMenus(params.row)}</Box>
          </Box>
        ))
      }
    ]

    // Data styling for cancel status -- start...
    const getBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
      '& .eMed_OP_CancelPatient': {
        backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
          ),
        },
      }
    }))
    // Data styling for cancel status -- end
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box pr={'1vw'}>
            <Button
              emed_tid={'stoTfr_rpt_ftr'}
              className='eMed_common'
              sx={{ width: '2vw', height: '2vw', mr: '1vw' }}
              onClick={() => this.setState({ filterOpen: true })}
            >
              <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Box>
          <Box>
          {/* need to work start */}
            {/* <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', ml: '1vw', mr: '1vw' }}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
            </Button>
            <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', mr: '1vw' }}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
            </Button> */}
            {/* need to work end */}
          </Box>
        </Box>
        <Box component={'div'} height={'73vh'} p={'1vw'}>
          <StyledDataGrid
            rows={this.state.transferReportData}
            sx={{ backgroundColor: 'white' }}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowClassName={(params) => params.row.is_cancel ? 'eMed_OP_CancelPatient' : null}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Stack>
              )
            }}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isReceiveClicked ? 
        <CommonStockTransferPopup 
        Data = {this.state.selectedData}
        title={"Transfer Details"}
        handlePopUpClose={this.popupClose}
        RowId={this.state.selectedRowId}
        />
        : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(PharmaStockTfrReport);