import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';

class PharmaDayEnd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dayEndData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                'days':91
            },
            pageName: 'pharmaDayEnd',
            filterOpen: false,
            totalAmount: 0,
            cash: 0,
            card: 0,
            bank: 0,
            insurance: 0,
            upi: 0,
            return: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getDayEndReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getDayEndReportData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getDayEndReportData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHARMA_DAY_END_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            dayEndData: response.data.data?.result,
                            totalAmount: response.data.data?.total_amount,
                            cash: response.data.data?.cash_total,
                            card: response.data.data?.card_total,
                            bank: response.data.data?.bank_transfer_total,
                            insurance: response.data.data?.insurance_total,
                            upi: response.data.data?.upi_total,
                            return: response.data.data?.total_return_amount,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSalesdayendPrint = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_DAY_END_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSalesdayendExcel = () => {
        try {
            RestAPIService.excelGet(Serviceurls.PHARMA_DAY_END_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    render() {
        const { t } = this.props
        this.state.dayEndData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'invoice_date', headerName: "Bill Date", flex: 0.111, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let invoice_date = params?.row?.invoice_date ? splitDate(params?.row?.invoice_date) : "-";
                    return (<Box component={'div'}>
                        {invoice_date?.length > 12 ? <Tooltip placement='top' title={invoice_date} arrow><div>{invoice_date?.slice(0, 12) + '...'}</div></Tooltip> :
                            params?.row?.invoice_date ? invoice_date : '-'}
                    </Box>)
                }
            },
            {
                field: 'invoice_number', headerName: "Bill Number", flex: 0.126, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 18 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number?.slice(0, 18) + '...'}</div></Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            {
                field: 'cash', headerName: `Cash (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.cash || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'card', headerName: `Card (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.card || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'upi', headerName: `UPI (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.upi || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'bank_transfer', headerName: `Bank Transfer (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.bank_transfer || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'insurance', headerName: `Insurance (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.insurance || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: '', headerName: `${"Outstanding Amount"} (${CurrencySymbol})`, flex: 0.126, type: 'number',
                renderCell: ({row}) => (<Box component={'div'}>{row?.invoice_number ? '0.00' : AmountFormat(row?.total || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'total', headerName: `${"Total"} (${CurrencySymbol})`, flex: 0.106, type: 'number',
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.total || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Total", this.state.totalAmount, false, "white", false, null, 'rpt_day_end_total_amt')}
                        {AmountsCard("Cash", this.state.cash, false, "white", false, null, 'rpt_day_end_cash')}
                        {AmountsCard("Card", this.state.card, false, "white", false, null, 'rpt_day_end_card')}
                        {AmountsCard("UPI", this.state.upi, false, "white", false, null, 'rpt_day_end_upi')}
                        {AmountsCard("Bank Transfer", this.state.bank, false, "white", false, null, 'rpt_day_end_bank')}
                        {AmountsCard("Insurance", this.state.insurance, false, "white", false, null, 'rpt_day_end_insurance')}
                        {AmountsCard("Return", this.state.return, false, "white", false, null, 'rpt_day_end_rtn')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_day_end_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_day_end_print" className='eMed_Pharma_rts_btn' onClick={() => { this.getSalesdayendPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_day_end_export" className='eMed_Pharma_rts_btn' onClick={() => this.getSalesdayendExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.dayEndData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getDayEndReportData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getDayEndReportData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PharmaDayEnd)