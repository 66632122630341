import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { withTranslation } from 'react-i18next'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CommonPatientDetails } from '../../../Components/Common Components/CommonComponents'
import Loader from '../../../Components/Loader';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CurrencySymbol } from '../../../Utility/Constants'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import { DateTime } from 'luxon'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'

class CreateReservation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileNo: null,
            isAlsoforAttender: false,
            admission_date: new Date(),
            DatePicker: false,
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            AdvanceAmt: '',
            PatientBlockList: [],
            PatientFloorList: [],
            PatientWardList: [],
            AttenderBlockList: [],
            AttenderFloorList: [],
            AttenderWardList: [],
            WardList: [],
            doctorList: [],
            filteredDocList: [],
            ComplaintsList: localGetItem("ipComplaintsDataList") === null ? [] : JSON.parse(localGetItem("ipComplaintsDataList")),
            specList: [],
            patient_id: null,
            reserv_doctor: {},
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: '',
            reserv_spec: {},
            complaints: "",
            preference: "",
            cashAmt: "",
            cardAmt: "",
            upiAmt: "",
            bankTfrAmt: "",
            chequeAmt: "",
            cardNo: "",
            upiNo: "",
            bankTfrNo: "",
            chequeNo: "",
            isCash: false,
            isCard: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            uhid: '',
            patient_name: ''
        }
    }

    componentDidMount = () => {
        this.getWardInfo()
        this.getSpecInfo()
        this.getDoctorDetails()
    }

    getWardInfo = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_RESERVATION_BEDSLT)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            PatientWardList: this.RemoveDuplicates(response.data.data, "ward_id"),
                            WardList: response.data.data
                        }, () => {
                            if (this.state.PatientWardList?.length == 1) {
                                this.setState({
                                    reserv_ward: this.state.PatientWardList[0].ward_id,
                                    PatientBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWardList[0].ward_id)), "block_id")
                                }, () => {
                                    if (this.state.PatientBlockList?.length == 1) {
                                        this.setState({
                                            reserv_block: this.state.PatientBlockList[0].block_id,
                                            PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                                        }, () => {
                                            if (this.state.PatientFloorList?.length == 1) {
                                                this.setState({
                                                    reserv_floor: this.state.PatientFloorList[0].floor
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }


    getSpecInfo = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            specList: response.data.data.filter((item) => (item.is_active === true)),
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    getDoctorDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorList: response.data.data.filter((item) => (item.specialization_active === true)),
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    handlePostReservation = () => {
        let states = this.state;
        let admissionDate = DateTime.fromJSDate(new Date(states.admission_date))?.toFormat("yyyy-MM-dd")
        let paymentMode = []
        if (states.isCash && states.cashAmt) { paymentMode.push(1) }
        if (states.isCard) { paymentMode.push(2) }
        if (states.isUPI) { paymentMode.push(3) }
        if (states.isBankTranfer) { paymentMode.push(5) }
        if (states.isCheque) { paymentMode.push(4) }
        let datas = {
            "patient_id": states.patient_id ? states.patient_id : null,
            "doctor_id": states.reserv_doctor?.doctor_id ? states.reserv_doctor?.doctor_id : null,
            "block_id": states.reserv_block ? states.reserv_block : null,
            "floor": states.reserv_floor ? states.reserv_floor : null,
            "approx_check_in_date": states.admission_date ? admissionDate : "",
            "ward_id": states.reserv_ward ? states.reserv_ward : null,
            "speciality_id": states.reserv_spec?.id ? states.reserv_spec?.id : null,
            "complaints": states.complaints ? states.complaints : "",
            "preference": states.preference ? states.preference : '',
            "is_attender": states.isAlsoforAttender ? states.isAlsoforAttender : false,
            "attender_block_id": states.attender_reserv_block ? states.attender_reserv_block : null,
            "attender_floor": states.attender_reserv_floor ? states.attender_reserv_floor : null,
            "attender_ward_id": states.attender_reserv_ward ? states.attender_reserv_ward : null,
            "receipt_for": states.AdvanceAmt ? "Advance" : '',
            "amount_received": states.AdvanceAmt ? +(Number(states.AdvanceAmt)?.toFixed(2)) : 0,
            "invoice_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
            "payment_mode": paymentMode?.length > 0 ? paymentMode : [],
            "amount_cash": states.cashAmt ? +states.cashAmt : 0,
            "amount_card": states.cardAmt ? +states.cardAmt : 0,
            "upi_amount": states.upiAmt ? +states.upiAmt : 0,
            "bank_transfer_amount": states.bankTfrAmt ? +states.bankTfrAmt : 0,
            "cheque_amount": states.chequeAmt ? +states.chequeAmt : 0,
            "card_ref_no": states.cardNo ? states.cardNo : '',
            "upi_ref_no": states.upiNo ? states.upiNo : '',
            "bank_ref_no": states.bankTfrNo ? states.bankTfrNo : '',
            "cheque_ref_no": states.chequeNo ? states.chequeNo : '',
            "status": "Reserved",
            "cancel_reason": "",
            "reservation_id": null,
            "receipt_cancel_reason": "",
            "with_reservation": true,
        }
        try {
            this.LoaderFunction(true)
            RestAPIService.create(datas, Serviceurls.FO_RESERVATION_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data?.message);
                        this.LoaderFunction(false)
                        this.handleClear()
                        this.props.history.push({ pathname: '/IPHome/Reservation' })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.LoaderFunction(false)
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message);
        }
    }

    CheckExistingPatients() {
        try {
            RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.mobileNo}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ipPatientList: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    SameNumberPatientPop() {
        let { t } = this.props;
        return (
            <Modal open={true}>
                <Box className="eMed_MobileNum_Main">
                    <Box component={'div'} className="eMed_MobileNum_Header">
                        <Typography variant='h6'>{"Patient List"}</Typography>
                        <IconButton onClick={() => { this.setState({ ipPatientList: [] }) }} marginRight={'4vw'}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </IconButton>
                    </Box>
                    <Box component={'div'} className="eMed_MobileNum_wrapper">
                        {
                            this.state.ipPatientList?.length > 0 ?
                                this.state.ipPatientList?.map((list) => {
                                    let PatientFullName = `${list.first_name} ${list.last_name}`
                                    list["patient_name"] = PatientFullName
                                    list["patient_mobile_number"] = list.mobile_number
                                    list["patient_photo_url"] = list.photo_url
                                    return (
                                        <Box>
                                            <Box className="eMed_Patient_Num_Search_pop">
                                                <CommonPatientDetails data={list} />
                                                <Tooltip title={t("Create Reservation")} arrow>
                                                    <IconButton position="end" onClick={() => {
                                                        this.setState({
                                                            ip_admission_id: list.ip_admission_id,
                                                            patient_id: list.patient_id,
                                                            uhid: list.patient_account_number,
                                                            patient_name: list.patient_name,
                                                            ipPatientList: []
                                                        })
                                                    }}>
                                                        <PersonAddAlt1Icon sx={{ color: Colors.themeDark }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    )
                                }) : null
                        }
                    </Box>
                </Box>
            </Modal>
        )
    }

    handleTextField = (e) => {
        let states = this.state
        let name = e.target.name;
        let value = e.target.value.trimStart();
        switch (name) {
            case "mobileNo":
                let isValid = CommonValidation.numberOnly(value);
                if ((isValid && value.length < 11) || value === "") {
                    states[name] = value
                    if (value.length === 10) { this.CheckExistingPatients() }
                    if (states.mobileNo) {
                        states.patient_id = "";
                        states.uhid = "";
                        states.patient_name = "";
                        states.ip_admission_id = "";
                    }
                }
                break;
            case "AdvanceAmt":
                if ((CommonValidation.NumberWithDot(value) && value > 0) || value === "") {
                    states["AdvanceAmt"] = value
                }
                break;
            default:
                if (value?.length <= 150 || value === "") {
                    states[name] = value
                }
                break;
        }
        this.setState({
            states
        })
    }

    renderTextField = (Label, stateKey) => {
        const { t } = this.props;
        let states = this.state
        return (
            stateKey === "admission_date" ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        open={this.state.DatePicker}
                        onOpen={() => { this.setState({ DatePicker: true }) }}
                        onClose={() => { this.setState({ DatePicker: false }) }}
                        value={states.admission_date}
                        name={Label}
                        label={Label}
                        minDate={new Date()}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        onChange={
                            (newDate) => {
                                states[stateKey] = newDate
                                this.setState({
                                    states
                                })
                            }}
                        renderInput={(params) =>
                            <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '16.5vw', margin: "0.5vw" }} size='small' {...params} onClick={() => { this.setState({ DatePicker: true }) }} />}
                    />
                </LocalizationProvider>
                : stateKey === "PaymentDate" ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={states.PaymentDate}
                            disabled={true}
                            name={Label}
                            label={Label}
                            inputFormat='DD-MM-YYYY'
                            views={["year", "month", "day"]}
                            renderInput={(params) =>
                                <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '16.5vw', margin: "0.5vw" }} size='small' {...params} />}
                        />
                    </LocalizationProvider>
                    :
                    <TextField
                        sx={{ width: "16.5vw", margin: "0.5vw" }}
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        autoComplete="off"
                        disabled={stateKey === "patient_name" || stateKey === "uhid"}
                        label={Label}
                        name={stateKey}
                        value={states[stateKey] ? states[stateKey] : ''}
                        onChange={this.handleTextField}
                        InputProps={{
                            startAdornment: (stateKey === "mobileNo" ? <InputAdornment position="start">{"+91"}</InputAdornment> : null)
                        }}
                    />
        )
    }


    renderAutoDropdown = (label, stateKey, Data = []) => {
        let states = this.state
        const { t } = this.props;
        return (
            <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: '16.5vw', margin: "0.5vw" }}
                options={Data}
                getOptionLabel={(item) => ((label === "DoctorName" ? (typeof (item?.doctor_name) === "string" ? item?.doctor_name : "") : label === "Speciality" ? (typeof (item?.name) === "string" ? item?.name : "") : item))}
                value={states[stateKey] ? states[stateKey] : ""}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue
                    if (typeof newValue === 'object' && newValue !== null) {
                        if (newValue !== null && "doctor_id" in newValue) {
                            let docDetail = { ...newValue }
                            docDetail["name"] = docDetail["specialization_name"];
                            delete docDetail.specialization_name;
                            docDetail["id"] = docDetail["specialization_id"];
                            delete docDetail.specialization_id;
                            states["reserv_spec"] = docDetail;
                        }
                        if (newValue !== null && "name" in newValue) {
                            let filteredLst = states["doctorList"].length > 0 && states["doctorList"].filter(item => item["specialization_id"] === newValue["id"])
                            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                            states["reserv_doctor"] = null;
                        }
                        if (newValue === null && label === "Speciality") {
                            states["filteredDocList"] = states["doctorList"];
                        }
                    }
                    this.setState({ states })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        placeholder={label}
                    />
                )}
            />
        )
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array?.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        return FilteredArray
    }

    renderDropdown(label, Key, List, disable, LabelKey, ValueKey, Type) {
        let states = this.state;
        const { t } = this.props;
        try {
            return (
                <FormControl size='small' sx={{ width: "16.5vw", margin: "0.5vw" }}>
                    <InputLabel>{t(label)}</InputLabel>
                    <Select
                        disabled={disable}
                        value={states[Key]}
                        label={t(label)}
                        onChange={(e) => {
                            states[Key] = e.target.value;
                            if (Type === "patient") {
                                if (ValueKey === "ward_id") {
                                    states["PatientBlockList"] = this.RemoveDuplicates(this.state.WardList?.filter((item) => (item.ward_id === e.target.value)), "block_id")
                                } else if (ValueKey === "block_id") {
                                    states["PatientFloorList"] = this.RemoveDuplicates(this.state.WardList?.filter((item) => (item.ward_id === this.state.reserv_ward && item.block_id === this.state.reserv_block)), "floor")
                                }
                            } else if (Type === "attender") {
                                if (ValueKey === "ward_id") {
                                    states["AttenderBlockList"] = this.RemoveDuplicates(this.state.WardList?.filter((item) => (item.ward_id === e.target.value)), "block_id")
                                } else if (ValueKey === "block_id") {
                                    states["AttenderFloorList"] = this.RemoveDuplicates(this.state.WardList?.filter((item) => (item.ward_id === this.state.attender_reserv_ward && item.block_id === this.state.attender_reserv_block)), "floor")
                                }
                            }
                            this.setState({ states })
                        }}
                        MenuProps={{
                            style: { maxHeight: 300, maxWidth: 150 }
                        }}
                    >
                        {
                            List?.length > 0 ?
                                List.map((list, index) => (
                                    <MenuItem key={index} value={list[ValueKey]}>{list[LabelKey]}</MenuItem>
                                )) : null
                        }
                    </Select>
                </FormControl>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleAmtChange = (value, stateKey, strField) => {
        let states = this.state
        let isValid = false;
        if (strField) {
            isValid = CommonValidation.ALPHA_NUMARIC(value);
            if (value === "" || (isValid && value?.length <= 16)) {
                states[stateKey] = value;
                this.setState({ states })
            }
        } else {
            isValid = CommonValidation.NumberWithDot(value);
            if (value === "" || (isValid && value > 0)) {
                states[stateKey] = value;
                this.setState({ states })
            }
        }
    }

    renderNumberTextFields = (label, statekey, value, image, helperText) => {
        var states = this.state
        let { t } = this.props;
        let strField = (statekey === "cardNo" || statekey === "upiNo" || statekey === "bankTfrNo" || statekey === "chequeNo")
        return (
            <TextField
                sx={{ width: statekey === "bankTfrAmt" ? "11vw" : "9.5vw", padding: '0.5vw' }}
                size="small"
                autoComplete="off"
                label={t(label)}
                name={statekey}
                type={"text"}
                value={value ? value : ""}
                required={true}
                variant={"filled"}
                placeholder={strField ? "" : "1,00,000.00"}
                InputProps={{
                    startAdornment: strField ? null :
                        <InputAdornment position="start">
                            <img src={image} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                }}
                onChange={(e) => this.handleAmtChange(e.target.value, statekey, strField)}
                helperText={helperText ? t(helperText) : ""}
            />
        )
    }

    alsoForAttender = (checked) => {
        this.setState({
            isAlsoforAttender: checked,
            AttenderWardList: this.RemoveDuplicates(this.state.WardList, "ward_id")?.filter((item) => (item?.ward__attender === true)),
        }, () => {
            if (!this.state.isAlsoforAttender) {
                this.setState({
                    attender_reserv_block: "",
                    attender_reserv_floor: "",
                    attender_reserv_ward: "",
                })
            }
        });
    }
    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ cashAmt: null }) }
            if (!states.isCard) { this.setState({ cardAmt: null, cardNo: null }) }
            if (!states.isUPI) { this.setState({ upiAmt: null, upiNo: null }) }
            if (!states.isCheque) { this.setState({ chequeAmt: null, chequeNo: null }) }
            if (!states.isBankTranfer) { this.setState({ bankTfrAmt: null, bankTfrNo: null }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ cashAmt: this.state.AdvanceAmt, })
            }
        })
    }


    handleValidation = () => {
        let states = this.state
        let msg = null
        let CashAmt = states.cashAmt === "" ? 0 : +states.cashAmt;
        let CardAmt = states.cardAmt === "" ? 0 : +states.cardAmt;
        let UPIAmt = states.upiAmt === "" ? 0 : +states.upiAmt;
        let bankTransferAmt = states.bankTfrAmt === "" ? 0 : +states.bankTfrAmt;
        let ChequeAmt = states.chequeAmt === "" ? 0 : +states.chequeAmt;
        let totalAmt = CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt

        if (!states.mobileNo) {
            msg = "Enter Mobile Number"
        } else if (!states.uhid || !states.patient_name) {
            msg = "Patient data not found"
        } else if (!states.complaints) {
            msg = "Select reason"
        } else if (!states.admission_date) {
            msg = "Select admission date"
        } else if (states.AdvanceAmt && (+states.AdvanceAmt < totalAmt)) {
            msg = "Amount is greater than payment amount"
        } else if (states.AdvanceAmt && (+states.AdvanceAmt > totalAmt)) {
            msg = "Amount does not match with payment amount"
        } else if (states.isCash && !states?.cashAmt) {
            msg = "Please Enter Cash Amount"
        } else if (states.isCard && !states?.cardAmt) {
            msg = "Please Enter Card Amount"
        } else if (states.isCard && !states?.cardNo) {
            msg = "Please Enter Card Reference No"
        } else if (states.isUPI && !states?.upiAmt) {
            msg = "Please Enter UPI Amount"
        } else if (states.isUPI && !states?.upiNo) {
            msg = "Please Enter UPI Reference No"
        } else if (states.isBankTranfer && !states?.bankTfrAmt) {
            msg = "Please Enter Bank Amount"
        } else if (states.isBankTranfer && !states?.bankTfrNo) {
            msg = "Please Enter Bank Reference No"
        } else if (states.isCheque && !states?.chequeAmt) {
            msg = "Please Enter Cheque Amount"
        } else if (states.isCheque && !states?.chequeNo) {
            msg = "Please Enter Cheque Reference No"
        } else {
            msg = null
        }

        if (msg) {
            this.errorMessage(msg)
        } else {
            this.handlePostReservation()
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    handleClear = () => {
        this.setState({
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            AdvanceAmt: '',
            mobileNo: null,
            isAlsoforAttender: false,
            admission_date: new Date(),
            patient_id: null,
            reserv_doctor: {},
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: '',
            reserv_spec: {},
            complaints: "",
            preference: "",
            cashAmt: "",
            cardAmt: "",
            upiAmt: "",
            bankTfrAmt: "",
            chequeAmt: "",
            cardNo: "",
            upiNo: "",
            bankTfrNo: "",
            chequeNo: "",
            isCash: false,
            isCard: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            uhid: '',
            patient_name: ''
        })
    }

    render() {
        let states = this.state
        let { t } = this.props;
        return (
            <div style={{ backgroundColor: Colors.Background, padding: '1vw', height: '100%' }}>
                <Paper sx={{ padding: '0.5vw', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box sx={{ height: '100%', overflowY: "auto" }}>
                        <Typography sx={{ fontWeight: 'bold', marginBottom: '0.5vw' }}>Basic Details</Typography>
                        <Divider />
                        <Box sx={{ padding: '0.5vw' }}>
                            <Box sx={{ display: 'flex' }}>
                                {this.renderTextField("Patient Mobile No *", "mobileNo")}
                                {this.renderTextField("Patient UHID", "uhid")}
                                {this.renderTextField("Patient Name", "patient_name")}
                                {this.renderAutoDropdown("DoctorName", "reserv_doctor", states.filteredDocList?.length > 0 ? states.filteredDocList : states.doctorList)}
                                {this.renderAutoDropdown("Speciality", "reserv_spec", states.specList)}
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                {this.renderTextField("Preference", "preference")}
                                {this.renderAutoDropdown("Reason / Diagnosis *", "complaints", states.ComplaintsList)}
                                {this.renderTextField("Admission Date *", "admission_date")}
                            </Box>
                        </Box>
                        <Typography sx={{ fontWeight: 'bold', marginBottom: '0.5vw' }}>Room Details</Typography>
                        <Divider />
                        <Box sx={{ padding: '0.5vw' }}>
                            <Box sx={{ display: 'flex' }}>
                                {this.renderDropdown("SelectWard", "reserv_ward", states.PatientWardList, false, "ward_name", "ward_id", "patient")}
                                {this.renderDropdown("SelectBlock", "reserv_block", states.PatientBlockList, states.reserv_ward ? false : true, "block_name", "block_id", "patient")}
                                {this.renderDropdown("SelectFloor", "reserv_floor", states.PatientFloorList, states.reserv_block ? false : true, "floor", "floor", "patient")}
                                <Box display={"flex"} flexDirection={"row"} marginLeft={"1vw"} width={"30vw"} sx={{ alignItems: 'center' }}>
                                    <FormControlLabel control={<Checkbox size='small' checked={this.state.isAlsoforAttender} onChange={(e) => { this.alsoForAttender(e.target.checked) }} />} label={"Reserve Room also for Attender"} />
                                </Box>
                            </Box>
                            {this.state.isAlsoforAttender ?
                                <Box sx={{ display: 'flex' }}>
                                    {this.renderDropdown("SelectWardAttender", "attender_reserv_ward", states.AttenderWardList, false, "ward_name", "ward_id", "attender")}
                                    {this.renderDropdown("SelectBlockAttender", "attender_reserv_block", states.AttenderBlockList, states.attender_reserv_ward ? false : true, "block_name", "block_id", "attender")}
                                    {this.renderDropdown("SelectFloorAttender", "attender_reserv_floor", states.AttenderFloorList, states.attender_reserv_block ? false : true, "floor", "floor", "attender")}
                                </Box>
                                : null}
                        </Box>

                        <Typography sx={{ fontWeight: 'bold', marginBottom: '0.5vw' }}>Payment Details</Typography>
                        <Divider />
                        <Box sx={{ display: 'flex' }}>
                            {this.renderTextField("Payment Amount", "AdvanceAmt")}
                            {this.renderTextField("Payment Date", "PaymentDate")}
                        </Box>
                        <Box className="eMed_CrdPop_Check_Group" sx={{ paddingLeft: '0.5vw', gap: '0.5vw' }}>
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCash} disabled={!states.AdvanceAmt} onChange={(e) => { this.handleCheckBox(e, "isCash") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCard} disabled={!states.AdvanceAmt} onChange={(e) => { this.handleCheckBox(e, "isCard") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Card")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isUPI} disabled={!states.AdvanceAmt} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isBankTranfer} disabled={!states.AdvanceAmt} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCheque} disabled={!states.AdvanceAmt} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cheque")}</Typography>} />
                        </Box>

                        <Box component={'div'} sx={{ padding: '0.5vw', display: 'flex' }}>
                            {this.state.isCash ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                                    {this.renderNumberTextFields("", "cashAmt", states["cashAmt"], CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default, "CashAmount", !states["isCash"])}
                                </Stack> : null}
                            {this.state.isCard ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderNumberTextFields("", "cardAmt", states["cardAmt"], ImagePaths.CardIcon.default, "CardAmount", !states["isCard"])}
                                    {this.renderNumberTextFields("", "cardNo", states["cardNo"], "", "ReferenceNo", !states["isCard"])}
                                </Stack> : null}
                            {this.state.isUPI ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderNumberTextFields("", "upiAmt", states["upiAmt"], ImagePaths.UPIIcon.default, "UPIAmount", !states["isUPI"])}
                                    {this.renderNumberTextFields("", "upiNo", states["upiNo"], "", "ReferenceNo", !states["isUPI"])}
                                </Stack> : null}
                            {this.state.isBankTranfer ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderNumberTextFields("", "bankTfrAmt", states["bankTfrAmt"], ImagePaths.BankTransferIcon.default, "BankTransferAmount", !states["isBankTranfer"])}
                                    {this.renderNumberTextFields("", "bankTfrNo", states["bankTfrNo"], "", "ReferenceNo", !states["isBankTranfer"])}
                                </Stack> : null}
                            {this.state.isCheque ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderNumberTextFields("", "chequeAmt", states["chequeAmt"], ImagePaths.ChequeIcon.default, "ChequeAmount", !states["isCheque"])}
                                    {this.renderNumberTextFields("", "chequeNo", states["chequeNo"], "", "ReferenceNo", !states["isCheque"])}
                                </Stack>
                                : null
                            }

                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1vw', justifyContent: 'center' }}>
                        <Button size='small' variant='outlined' onClick={() => { this.handleClear() }}>Clear</Button>
                        <Button size='small' variant='contained' onClick={() => { this.handleValidation() }}>Complete</Button>
                    </Box>
                </Paper>
                {states.ipPatientList?.length > 0 ? this.SameNumberPatientPop() : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </div>
        )
    }
}
export default withTranslation()(CreateReservation)
