import React, { Component } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
    Box, Paper, Stack, Button, Typography, Tooltip, TableCell,
    TableContainer, Table, TableHead, TableBody, TableRow,
    Modal
} from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import { CommonPopUp } from "../../../Components/Common Components/CommonComponents";
import { Bucket_Name } from '../../../Utility/Constants';

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});
export default class TestHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            HistoryData: [],
            isViewHistory: false,
            viewCultureData: [],
            testName: "",
            selectedtab: null
        }
    }

    componentDidMount() {
        if (this.props.Source) {
            this.setState({
                selectedtab: this.props.Source
            }, () => {
                this.GetHistoryDataList()
            })
        } else {
            this.GetHistoryDataList()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Source != this.props.Source) {
            this.setState({
                selectedtab: this.props.Source
            }, () => {
                this.GetHistoryDataList()
            })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetHistoryDataList() {
        try {
            let result_summary_id = this.props?.PatientDetails?.result_summary_id ? this.props?.PatientDetails?.result_summary_id : this.props?.PatientDetails?.id;
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_TEST_HISTORY + "?patient_id=" + this.props?.PatientDetails?.patient_id + "&result_summary_id=" + result_summary_id).
                then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data?.length > 0 ? response?.data?.data?.map((item, index) => {
                            let resultValue = '';
                            if (item?.result) {
                                if (Array.isArray(item.result.value) && item.method === "Culture Test Result") {
                                    resultValue = 'Culture Test';
                                } else if (item?.result?.value === null || item?.result?.value) {
                                    resultValue = item?.result?.value;
                                } else {
                                    resultValue = item?.result;
                                }
                            }

                            return {
                                ...item,
                                result_value: resultValue,
                                sno: index + 1,
                            };
                        }) : [];
                        var OutSourceData = []; OutSourceData = Data?.filter((list) => list?.is_outsource)
                        var InHouseData = []; InHouseData = Data?.filter((list) => !list?.is_outsource)
                        this.setState({
                            HistoryData: this.state.selectedtab === "Inhouse" ? InHouseData : OutSourceData
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    PopUpClose = () => {
        this.setState({
            isViewHistory: false,
            viewCultureData: [],
            testName: "",
        })
    }

    viewCultureTest = (data) => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "47vh",
                overflowY: "auto",
            }}
            >
                {data?.map((items, indexs) => {
                    return (
                        <div key={indexs} style={{ width: "70vw", marginBottom: "1vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "1vw", marginBottom: "1vw" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Box component={'div'} sx={{ display: "flex" }}>
                                        <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                            {items?.bacteria ? items?.bacteria?.length > 20 ?
                                                <Tooltip placement='top' title={items?.bacteria} arrow>
                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                        {items?.bacteria.slice(0, 20) + '...'}
                                                    </Typography>
                                                </Tooltip> :
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                                        </Typography>
                                    </Box>
                                </div>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`MICROSCOPY : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.microscopy ? items?.microscopy?.length > 20 ?
                                            <Tooltip placement='top' title={items?.microscopy} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.microscopy.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`COLONY COUNT : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.colony_count ? items?.colony_count?.length > 20 ?
                                            <Tooltip placement='top' title={items?.colony_count} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.colony_count.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ display: "flex" }}>
                                    <Typography>{`METHOD : `}</Typography>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                        {items?.method_type ? items?.method_type?.length > 20 ?
                                            <Tooltip placement='top' title={items?.method_type} arrow>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.method_type.slice(0, 20) + '...'}
                                                </Typography>
                                            </Tooltip> :
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                                    </Typography>
                                </Box>
                            </div>
                            <TableContainer style={{ maxHeight: "35vh" }}>
                                <Table style={{ border: "1px solid gray" }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"SNo"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Antibiotic"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"MIC Result/Range"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Zone Result/Range"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Interpretaion"}</b></TableCell>
                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Efficiency Ration"}</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.antibacteria?.length > 0 ?
                                            items?.antibacteria?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.antibiotic} arrow>
                                                                <Typography>
                                                                    {item?.antibiotic.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.antibiotic}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.mic_break_point} arrow>
                                                                    <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                                                </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                                            {item?.mic_break_point_std ?
                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.mic_break_point_std} arrow>
                                                                        <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                                                    </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.zone_size} arrow>
                                                                    <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                                                </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                                            {item?.zone_size_std ?
                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.zone_size_std} arrow>
                                                                        <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                                                    </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.interpretation ? item?.interpretation?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.interpretation} arrow>
                                                                <Typography>
                                                                    {item?.interpretation.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.interpretation}</Typography> : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                                            <Tooltip placement='top' title={item?.methodThreeBacteriaResult} arrow>
                                                                <Typography>
                                                                    {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                                                </Typography>
                                                            </Tooltip> :
                                                            <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : <TableRow>
                                                <TableCell align="center" colSpan={6}>No Records To Be Shown</TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                })}
            </div>
        )
    }

    async sourceOpen(list, subIndex) {
        let tempArr = [];
        let image = list?.result_image ? JSON.parse(list.result_image) : []
        if (image !== null) {
          for (let j = 0; j < image?.length; j++) {
              let doc_key = image[j];
              let id = list.id;
    
              // Fetch data from S3 or your API
              let getData = await this.getS3Key(doc_key, id);
    
              // Add the `savedBefore` flag
              const imageDataWithFlag = {
                  ...getData,  // Assuming getData returns the image object
                  savedBefore: true,  // Set the flag indicating it's a saved image
              };
              tempArr.push(imageDataWithFlag);
          }
          this.setState({
            sourceOpen: true,
            selectedUploadData: list,
            uploadedImages: tempArr,
            selectedImage: null,
          });
      }
      }
    
      getS3Key(doc_key, id) {
    
        return new Promise(resolve => {
            var parms = {
                Bucket: Bucket_Name,
                Key: doc_key, Expires: 300
            };
            s3.getSignedUrl("getObject", parms, (err, url) => {
                if (err) {
                    resolve(err)
                } else {
                    var data = {
                        "data_url": url,
                        "id": id,
                        "type": doc_key?.split('.')?.pop() === "pdf" ? "pdf" : "image"
                    }
                    resolve(data)
                }
            })
        })
    }
    
    sourceClose = () => {
        this.setState({ sourceOpen: false })
    }

    renderSourcePopUp = (list, subIndex) => {
        const { t } = this.props
        return (
            <>
               <Modal
                  open={this.state.sourceOpen}
                  onClose={this.sourceClose} // Close when clicking outside or pressing Escape
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  footer={null}
                  onCancel={this.sourceClose}
                >
                  <Box className='emedhub_popup_outsource_list'>
                    {/* Modal Header */}
                    <div className='emedhub_popup_title'>
                      <label id="modal-title" className='emedhub_popup_title_left'>{"TestReport"}</label>
                      <div className='emedhub_card_close_button'>
                        <img
                          className='emedhub_popup_title_right'
                          src={ImagePaths.LabCloseButton.default}
                          onClick={this.sourceClose}
                          alt="close"
                        />
                      </div>
                    </div>
                    {/* Modal Content */}
                    <div className='emedhub_popup_title_2'>
                    {/* <Typography className='emedhub_popup_title_Header'>{t("SculptureTest")}</Typography> */}
                    </div>
                    <div className='emedhub_print_popup'>
                      {/* File Upload Section */}
                      <div className='image_top_header'>
                        <label className='test_name_style'><b>{"TestName"}:</b>
                          <span style={{marginLeft:"5px"}}>{"  " + this.state?.selectedUploadData?.test_name}</span>
                        </label>
                        </div>
                      {/* Uploaded Images Preview */}
                      <div style={{ display: "flex", width: "100%", overflow: "auto", overflowY: "hidden" }}>
                        {this.state.uploadedImages?.map((item, index) => (
                          <div key={index} className='emedhub_multiple_image_size_outlist'>
                            <img
                              alt="Uploaded Preview"
                              src={item.type === "image" ? item["data_url"] : ImagePaths.DocumentPreview.default}
                              className='emedhub_multiple_image_resolution'
                              onClick={() => {
                                // If the item is a PDF and it was saved before, trigger download
                                if (item.type === "pdf" && item?.savedBefore) {
                                  // Trigger PDF download (assuming item has a `data_url` or a URL that can be used for download)
                                  const link = document.createElement("a");
                                  link.href = item["data_url"];
                                  link.download = `downloaded_file_${index}.pdf`; // Customize the filename if needed
                                  link.click();
                                }  
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                </Modal>
    
            </>
        )
    }
    
    render() {
        const { t } = this.props
        this.state.HistoryData?.forEach((element, index) => element.s_no = index + 1)
        const columns = [
            { field: "s_no", flex: 0.05, headerName: "S No", headerAlign: "center", align: "center", },
            {
                field: "test_date", flex: 0.2, headerName: "Bill Date & Time", headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.test_date ? row?.test_date : "-"}</Box>)
            },
            {
                field: "test_name", flex: 0.3, headerName: "Test Name", headerAlign: "left", align: "left",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.test_name?.length > 80 ?
                    <Tooltip placement='top' title={row?.test_name} arrow><div>{row?.test_name.slice(0, 80) + "..."}</div></Tooltip>
                    : row?.test_name ? row?.test_name : "-"}</Box>)
            },
            {
                field: "result_value", flex: 0.2, headerName: "Result",
                renderCell: ({ row }) => (
                    (row?.result_value === "Culture Test" && row?.method === "Culture Test Result" && row?.result?.value?.length > 0) ?
                        <Button size="small" variant="contained" sx={{ textTransform: "capitalize" }}
                            onClick={() => {
                                this.setState({
                                    isViewHistory: true,
                                    viewCultureData: row?.result?.value?.length > 0 ? row?.result?.value : [],
                                    testName: row.test_name,
                                })
                            }}>{"View Culture History"}</Button>
                        : row?.result_image ? <div>
                        <Button
                        variant='contained'
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {this.setState({ selectedImageRowId: row.id,  uploadData: row })
                        this.sourceOpen(row, row.id) }}>{"View PDF"}
                        </Button>
                        </div>
                        :
                        <Box component={'div'}>{row?.result_value?.length > 45 ?    
                            <Tooltip placement='top' title={row?.result_value} arrow><div>{row?.result_value.slice(0, 45) + "..."}</div></Tooltip>
                            : row?.result_value ? row?.result_value
                                : (row?.status === "Result Completed" || row?.status === "Result Delivered") ?
                                    "Result Not Entered"
                                    : "Result yet to complete"}</Box>)
            },
            {
                field: 'comments', headerName: 'Comments', flex: 0.2,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.result?.comment ? params?.row?.result?.comment?.length > 25 ?
                        <Tooltip placement='top' title={params?.row?.result?.comment}><Typography>{params?.row?.result?.comment?.slice(0, 25) + '...'}</Typography></Tooltip> :
                        <Typography>{params?.row?.result?.comment}</Typography> : '-'}
                </Box>)
            },
        ]
        return (
            <Box>
                <Box component={"div"} className='eMed_Table_Container' marginTop={"1vw"}>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' style={{ width: "90vw", height: "56vh" }} >
                            <DataGrid
                                rows={this.state.HistoryData}
                                columns={columns}
                                getRowId={(row) => row['s_no']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {"No Records Found"}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                {
                    this.state.isViewHistory ?
                        <CommonPopUp width={"75vw"}
                            backgroundColor={Colors.white}
                            popUpClose={this.PopUpClose.bind(this)}
                            title={this.state.testName}
                            component={this.viewCultureTest.bind(this, this.state.viewCultureData)} />
                        : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.sourceOpen ? this.renderSourcePopUp(this.state.uploadData, this.state.selectedImageRowId) : null}
            </Box>
        )
    }
}
