import React, { Component } from 'react'
import './Canteen.css'
import { Box, Typography, FormControlLabel, Checkbox, TextField, InputAdornment, Stack, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Autocomplete } from '@mui/material';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../Styles/Colors';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';
import { AmountFormat } from '../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../Utility/Constants';
import SearchIcon from '@mui/icons-material/Search';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BillSuccessPoPUp, CommonPatientDetails, PharmaDiscardPop, ReasonPopup, ReasonPopupWithRefund } from '../../Components/Common Components/CommonComponents';
import { clearCachevalue, getCachevalue } from '../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';
import Loader from '../../Components/Loader';

class CanteenHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: '',
      isCash: false,
      isCard: false,
      isUPI: false,
      isBankTranfer: false,
      CashAmount: "",
      CashBalanceAmount: "",
      ReceivedCashAmount: "",
      CardAmount: "",
      CardTransactionNumber: "",
      UPIAmount: "",
      UPINumber: "",
      BankTfrAmount: "",
      BankTfrNumber: "",
      canteenBillData: [
        {
          item_name: '',
          qty: '',
          price: '',
          discount_percent: '',
          discount_amount: '',
          total_amount: '',
          item_code: '',
          id: '',
        }
      ],
      subAmount: 0,
      isCredit: false,
      menuItemData: [],
      totalDiscountAmount: '',
      totalDiscountPercentage: '',
      payableAmount: 0,
      roundOff: 0,
      billDate: new Date(),
      isClear: false,
      DateTimerOpen: false,
      patientData: {},
      CompletedBillID: null,
      cancelBillID: null,
      getbillData: {},
      isCancelled: false,
      paymentStatus: '',
      DateTimeChanged: false,
    }
  }

  componentDidMount() {
    this.getMenuItemData()
    setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
      if (!this.state.DateTimeChanged) {
          const newMinute = new Date().getMinutes();
          if (this.state.billDate && this.state.billDate?.getMinutes() !== newMinute) {
              this.setState({
                  billDate: new Date(),
              });
          }
      }
  }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && (this.props.history?.location?.state ? Object.keys(this.props.history?.location?.state).length > 0 : false)) {
      if (this.props.history?.location?.state?.patientData) {
        const newPatientData = this.props.history?.location?.state?.patientData;

        // Check if the new patientData is different from the current state
        if (newPatientData !== this.state.patientData) {
          this.setState({
            patientData: newPatientData,
          });
        } else {
          // If the new patientData is the same as the current state, clear it
          this.setState({
            patientData: {},
          });
        }
      }
      if (this.props.history?.location?.state?.Bill_ID) {
        const newBill_ID = this.props.history?.location?.state?.Bill_ID;
        if (newBill_ID !== this.state.Bill_ID) {
          this.setState({
            cancelBillID: newBill_ID,
          }, () => {
            this.getCanteenBillData()
          });
        } else {
          this.setState({
            cancelBillID: null,
          });
        }
      }
    }
  }

  getMenuItemData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              menuItemData: response.data.data
            }, () => {
              this.state.menuItemData?.forEach(element => {
                element.qty = ''
                element.discount_amount = ''
                element.discount_percent = ''
                element.total_amount = ''
              })
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  getCanteenBillData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_BILLING + `?id=${this.state.cancelBillID}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              getbillData: response.data.data
            }, () => {
              this.assigngetData(response.data.data)
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  assigngetData = (data) => {
    try {
      this.setState({
        canteenBillData: data?.items,
        payableAmount: data?.net_total_amount,
        isCash: data?.amount_received ? true : false,
        CashAmount: data?.amount_received,
        subAmount: data?.total_amount,
        billDate: data?.invoice_date,
        paymentStatus: data?.payment_status,
        totalDiscountPercentage: data?.discount_percent,
        totalDiscountAmount: data?.discount_amount,
        patientData: data?.ip_admission_id ? {
          patient_name: data?.patient_name,
          patient_age: data?.patient_age,
          patient_gender: data?.patient_gender,
          patient_account_number: data?.patient_account_number,
          patient_mobile_number: data?.patient_mobile_number,
          ip_admission_id: data?.ip_admission_id,
          ip_number: data?.ip_number,
        } : {}
      })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postCanteenBill = () => {
    try {
      let states = this.state
      let Datetime = new Date(states.billDate)
      let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
      let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
      let PaymentTypeData = [];
      let PaymentTypeTypes = []
      if (this.state.isCash) {
        PaymentTypeData.push(1)
        PaymentTypeTypes.push("Cash")
      }
      if (this.state.isCard) {
        PaymentTypeData.push(2)
        PaymentTypeTypes.push("Card")
      }
      if (this.state.isUPI) {
        PaymentTypeData.push(3)
        PaymentTypeTypes.push("UPI")
      }
      if (this.state.isBankTranfer) {
        PaymentTypeData.push(5)
        PaymentTypeTypes.push("Bank Transfer")
      }
      let Lineitems = []
      states.canteenBillData.forEach((item) => {
        if (item.item_name !== "") {
          let BillItem = {
            "item_name": item.item_name,
            "item_code": item.item_code,
            "price": +item.price,
            "qty": +item.qty,
            "total_amount": +item.total_amount,
            "discount_amount": +item.discount_amount,
            "discount_percent": +item.discount_percent,
            "id": +item.id,
          }
          Lineitems.push(BillItem)
        }
      })
      let data = {
        "invoice_date": `${date} ${time}:00`,
        "payment_status": states.isCredit ? "pending" : "completed",
        "bill_type": states.isCredit ? "credit" : "regular",
        "payment_mode": PaymentTypeData ? PaymentTypeData : [],
        "payment_mode_types": PaymentTypeTypes,
        "cash_amount": +states.CashAmount,
        "card_amount": +states.CardAmount,
        "upi_amount": +states.UPIAmount,
        "bank_transfer_amount": +states.BankTfrAmount,
        "card_number": states.CardTransactionNumber,
        "upi_ref_no": states.UPINumber,
        "bank_ref_no": states.BankTfrNumber,
        "discount_percent": +states.totalDiscountPercentage,
        "discount_amount": +states.totalDiscountAmount,
        "items": Lineitems,
        "total_amount": states.subAmount,
        "net_amount": states.payableAmount,
      }
      if (Object.keys(states.patientData).length > 0) {
        data['ip_admission_id'] = states.patientData?.id
        data['patient_id'] = states.patientData?.patient_id
        data['doctor_id'] = states.patientData?.doctor_id
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.CANTEEN_BILLING)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.setState({
              CompletedBillID: response?.data?.bill_summary_id
            })
            this.DiscardAction(true)
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  CancelBill = (reason, receipttype) => {
    try {
      let states = this.state
      let payment = []
      if (states.isCash) {
        payment.push(1)
      }
      if (states.isUPI) {
        payment.push(3)
      }
      if (states.isBankTranfer) {
        payment.push(5)
      }
      let data = {
        "bill_summary_id": states.cancelBillID,
        "cancel_reason": reason,
        "bill_type": 'cancelled',
        "receipt_type": receipttype ? receipttype : "Cancel",
        "payment_status": states.paymentStatus,
        "payment_mode": payment,
        "cash_amount": states.CashAmount ? states.CashAmount : 0,
        "upi_amount": states.UPIAmount ? states.UPIAmount : 0,
        "bank_transfer_amount": states.BankTfrAmount ? states.BankTfrAmount : 0,
        "upi_ref_no": states.UPINumber,
        "bank_ref_no": states.BankTfrNumber,
        "items": states.canteenBillData
      }
      if (states.patientData?.ip_admission_id) {
        data['ip_admission_id'] = states.patientData?.ip_admission_id
      }
      let URL = Serviceurls.CANTEEN_BILLING
      this.LoaderFunction(true)
      RestAPIService.create(data, URL)
        .then((response) => {
          if (response.data.status === "success") {
            clearCachevalue("isCanteenOverall");
            this.LoaderFunction(false)
            this.ReasonPopupClose()
            this.DiscardAction(true)
            this.props.history.push('/CanteenBillingTransaction')
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }

  successMessage = (msg) => {
    this.setState({
      isSuccessMsg: true,
      isSuccessMsgText: msg
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      isSuccessMsg: false,
      isSuccessMsgText: '',
    })
  }

  cardDetails = () => {
    let states = this.state
    return (
      <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', }} >
        <Box component={'div'} className='eMed_Canteen_CardBox'>
          <img className='eMed_Canteen_img' src={ImagePaths.AvatarCardIcon.default} alt="icon" />
          {Object.keys(this.state.patientData).length > 0 ?
            <Box marginLeft={'1vw'}>
              <CommonPatientDetails disablePhoto={true} data={this.state.patientData} />
            </Box> :
            <Typography sx={{ color: Colors.grayShade, marginLeft: '1vw' }}>Patient Details</Typography>}

        </Box>
        {Object.keys(this.state.patientData).length > 0 ?
          <Box component={'div'} className='eMed_Canteen_CardBox'>
            <img className='eMed_Canteen_img' src={ImagePaths.IPNumberCard.default} alt="icon" />
            <Box marginLeft={'1vw'}>
              <Typography sx={{ color: Colors.grayShade, fontSize: '0.8vw' }}>IP Number</Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: '0.9vw' }}>{this.state.patientData.ip_number ? this.state.patientData.ip_number : '-'}</Typography>
            </Box>
          </Box> : null}
        <Box component={'div'} className='eMed_Canteen_CardBox'>
          <img className='eMed_Canteen_img' src={ImagePaths.CalenderIcon.default} alt="icon" />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={true} // this.state.cancelBillID ---> Previous condition
              emed_tid="Invoice DateTime"
              open={states.DateTimerOpen}
              onOpen={() => { this.setState({ DateTimerOpen: true }) }}
              onClose={() => { this.setState({ DateTimerOpen: false }) }}
              maxDateTime={new Date(Date.now())}
              minDate={new Date(Date.now())}
              label={"Invoice Date & Time"}
              value={this.state.billDate}
              inputFormat='DD-MM-YYYY & hh:mmA'
              onChange={(value) => {
                if (value === null) {
                  states["billDate"] = new Date()
                } else {
                  states["billDate"] = value
                }
                this.setState({ states })
              }}
              renderInput={(params) => <TextField
                size="small" {...params}
                variant='standard'
                required
                autoComplete='off'
                onKeyDown={(e) => e.preventDefault()}
                onClick={() => { this.setState({ DateTimerOpen: true }) }}
                sx={{ width: "16vw", marginLeft: '1vw' }}
              />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    )
  }

  PaymentDetails = (params) => {
    const { t } = this.props
    let states = this.state
    let disable = this.state.subAmount ? false : true
    return (
      <Box component={'div'} display={'flex'} flexDirection={'row'}>
        <Box flex={0.75} overflow={'scroll'} visibility={this.state.paymentStatus === 'pending' ? 'hidden' : 'visible'}>
          <Box component={'div'} border={'1px solid lightgray'} pl={'1vw'} marginTop={'0.5vw'} height={'9vw'} overflow={'scroll'}>
            <Box component={'div'} style={{ marginTop: "0.5vw" }} className='eMed_Pharma_payment_card'>
              <Typography fontWeight={600} fontSize={'0.9vw'}>Mode Of Payment</Typography>
            </Box>
            <Box component={'div'}>
              <Box>
                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : false} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="cash_checkbox" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                {this.state.cancelBillID ? null : <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : false} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="card_checkbox" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />}
                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : false} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="upi_checkbox" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : false} className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
              </Box>
              <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                {
                  this.state.isCash ?
                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                      {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                      {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isInsurance ?
                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                          {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                          {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                        </Box> : null
                      }
                    </Box> : null
                }
                {
                  this.state.isCard ?
                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                      {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                      {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                    </Box>
                    : null
                }
                {
                  this.state.isUPI ?
                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                      {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                      {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                    </Box>
                    : null
                }
                {
                  this.state.isBankTranfer ?
                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                      {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                      {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                    </Box>
                    : null
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component={'div'} flex={0.25}>
          <Box component={'div'} className='eMed_bill_tbl_footer'>
            <Typography mt={0.5} fontSize={'0.9vw'}>{t("Sub Amount")}</Typography>
            <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer'>
            <Typography mt={0.5} fontSize={'0.9vw'}>{`${t("Discount")} %`}</Typography>
            {this.renderTextBox('totalPercentage', this.state.totalDiscountPercentage, '2vh', disable)}
            {this.renderTextBox('totalDiscount', this.state.totalDiscountAmount, '2vh', disable)}
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer'>
            <Typography mt={0.5} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
            <Typography emed_tid="total_roundoff_amount" mt={0.5} mr={'0.8vw'}>
              {this.billingCalculation(params, 'roundOff')}
            </Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer' borderTop={"2px solid gray"}>
            <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("PayableAmount")}</Typography>
            <Typography emed_tid="total_payable_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>
              {this.state.cancelBillID ? AmountFormat(+this.state.payableAmount || 0) : this.billingCalculation(params, 'payableAmount')}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
  billingCalculation = (params = [], type) => {
    try {
      let amount = 0;
      switch (type) {
        case 'totalServiceAmount':
          params?.forEach(element => {
            amount = amount + (+element?.total_amount)
          })
          this.state.subAmount = +amount
          return AmountFormat(+this.state.subAmount || 0)
        case 'roundOff':
          this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscountAmount)
          return AmountFormat(+this.state.roundOff || 0)
        case 'payableAmount':
          let totalAmount = +this.state.subAmount - +this.state.totalDiscountAmount
          this.state.payableAmount = Math.round(totalAmount)
          return AmountFormat(+this.state.payableAmount || 0)
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  calculateTotalDiscount = () => {
    try {
      this.state.totalDiscountAmount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalDiscountPercentage)
      this.state.totalDiscountPercentage = CommonValidation.amountPercentage(this.state.totalDiscountAmount, this.state.subAmount)
      this.setState({
        totalDiscountAmount: +this.state.totalDiscountAmount,
        totalDiscountPercentage: +this.state.totalDiscountPercentage
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, height, diasble) => {
    try {
      return (
        <TextField
          autoFocus
          disabled={this.state.cancelBillID}
          variant='standard'
          autoComplete='off'
          size='small'
          InputProps={{
            startAdornment: names === "totalDiscount" ? <InputAdornment position="start">{CurrencySymbol}</InputAdornment> : null,
            endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
          }}
          className={'eMed_discount_txt'}
          inputProps={{ style: { height: height }, emed_tid: names }}
          name={names}
          value={value}
          onChange={(e) => { this.onChangeHandler(e, names) }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAmountTextFields = (label, key, Icon) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        inputProps={{ emed_tid: label }}
        sx={{ padding: 0, margin: 0, width: "10vw" }}
        className='eMed_CrdPop_Amount_TextFields'
        size='small'
        label={t(label)}
        autoComplete='off'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Icon} alt={label} className='eMed_action_img_small' />
            </InputAdornment>
          ),
        }}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.NumberWithDot(e.target.value);
          if ((number && +e.target.value > 0) || e.target.value === "") {
            states[key] = e.target.value
            this.setState({
              states
            }, () => {
              if (key === "CashAmount") {
                this.setState({
                  CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                })
              }
            })
          }
        }}
      />
    )
  }

  renderNumberTextFields = (label, key, Right = false) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        className='eMed_CrdPop_Amount_TextFields'
        sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
        inputProps={{ maxLength: 20, emed_tid: label }}
        disabled={this.state.forCancel || key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false) || (this.state.isForReturn ? false : this.state.isFromInvoice)}
        focused={key === "CashBalanceAmount"}
        size='small'
        label={t(label)}
        value={states[key]}
        autoComplete='off'
        onChange={(e) => {
          let number = CommonValidation.gstnumberValidation(e.target.value);
          let onlynumber = CommonValidation.numberOnly(e.target.value);
          if (key === "ReceivedCashAmount") {
            if (onlynumber || e.target.value === "") {
              this.setState({
                ReceivedCashAmount: e.target.value,
                CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
              })
            }
          }
          else {
            if (number || e.target.value === "") {
              states[key] = e.target.value.toUpperCase()
              this.setState({
                states
              })
            }
          }
        }
        }
      />
    )
  }

  handleCheckBox = (e, key) => {
    try {
      var states = this.state
      states[key] = e.target.checked
      // if (key == "isCredit" && (states?.Patcompany_nameientDetails?.primary_payment_type == "Corporate" || states?.PatientDetails?.primary_payment_type == "Corprate credit")) {
      //   this.setState({
      //     CorpCompanyName: states?.PatientDetails?.,
      //     CorpIDNumber: states?.PatientDetails?.id_no
      //   })
      // }
      this.setState({ states }, () => {
        if (!states.isCash) { this.setState({ CashAmount: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
        if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" ,ReceivedCashAmount: "",CashBalanceAmount: "",}) }
        if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" ,ReceivedCashAmount: "",CashBalanceAmount: "",}) }
        if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" ,ReceivedCashAmount: "",CashBalanceAmount: "",}) }
        this.SetFullCashAmount()
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  SetFullCashAmount = () => {
    let states = this.state
    if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
      this.setState({ CashAmount: this.state.payableAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
    }
    if (!states?.subAmount) {
      this.setState({
        isCard: false,
        isCash: false,
        isUPI: false,
        isBankTranfer: false,
        isCheque: false,
        isInsurance: false,
        CashAmount: "",
        CardAmount: "",
        CardTransactionNumber: "",
        UPINumber: "",
        UPIAmount: "",
        BankTfrAmount: "",
        BankTfrNumber: "",
        ChequeNumber: "",
        ChequeAmount: "",
        InsuranceAmount: "",
        InsuCompanyName: "",
        InsuPolicyNumber: "",
        InsuTransactionNumber: "",
        InsuValidity: '',
      })
    }
  }

  renderAutoComplete = (item, index) => {
    let { canteenBillData } = this.state;
    return (
      <Box>
        <Autocomplete
          disabled={this.state.cancelBillID}
          options={this.state?.menuItemData}
          getOptionLabel={(options) => typeof (options) === 'string' ? options : `${options?.item_name ? options.item_name : ""} - ${options?.item_code ? options.item_code : ""} `}
          onChange={(e, value) => {
            let isDuplicate = canteenBillData.slice(0, -1).some((item) =>
              Object.is(item.item_name, value?.item_name)
            );

            if (!isDuplicate) {
              canteenBillData[index]['item_name'] = value?.item_name
              canteenBillData[index]['price'] = value?.price
              canteenBillData[index]['qty'] = value?.quantity
              canteenBillData[index]['discount_amount'] = value?.discount_amount
              canteenBillData[index]['discount_percent'] = value?.discount_percent
              canteenBillData[index]['total_amount'] = ''
              canteenBillData[index]['id'] = value?.id
              canteenBillData[index]['item_code'] = value?.item_code

              // Check if item_name is empty and remove the corresponding item from the array
              if (!value?.item_name && index !== (canteenBillData.length - 1)) {
                canteenBillData.splice(index, 1);
              }

              this.setState({ canteenBillData }, () => {
                // Check if value is truthy before pushing canteendata
                if (value) {
                  let canteendata = {
                    item_name: '',
                    qty: '',
                    price: '',
                    discount_percent: '',
                    discount_amount: '',
                    total_amount: '',
                    item_code: '',
                    id: '',
                  }

                  // Check if the last object in canteenBillData has a non-empty item_name
                  const lastItem = canteenBillData[canteenBillData.length - 1];
                  const isLastItemNotEmpty = lastItem && lastItem.item_name !== "";

                  // Only push canteendata if the last item has a non-empty item_name
                  if (isLastItemNotEmpty) {
                    canteenBillData.push(canteendata);
                  }

                  this.setState({ canteenBillData });
                }
              });
            } else {
              this.errorMessage("Menu already selected. Please choose a different menu.")
            }
          }}
          value={item?.item_name}
          id="combo-box"
          sx={{ width: "18vw" }}
          renderInput={(params) => <TextField {...params} variant='standard' size='small' />}
        />
      </Box>
    );
  };

  onChangeHandler = (e, names, index) => {
    try {
      let value = e.target.value
      let { canteenBillData } = this.state
      switch (names) {
        case 'quantity':
          if ((CommonValidation.numberOnly(value)) || value === '') {
            canteenBillData[index]['qty'] = +value
            canteenBillData[index]['total_amount'] = (+value * canteenBillData[index]['price']) - (+canteenBillData[index]['discount_amount'])
            this.setState({ canteenBillData }, () => this.calculateTotalDiscount())
          }
          break;
        case 'discount_percent':
          if ((CommonValidation.DecimalNumberTwo(value) && value <= 100) || value === '') {
            canteenBillData[index]['discount_percent'] = +value
            let qty = canteenBillData[index]['qty'] ? +canteenBillData[index]['qty'] : 0
            let totalAmount = +(qty * canteenBillData[index]['price'])
            canteenBillData[index]['discount_amount'] = CommonValidation.calculatePercentageWithoutRoundoff(value, totalAmount)
            canteenBillData[index]['total_amount'] = +(qty * canteenBillData[index]['price']) - (+canteenBillData[index]['discount_amount'])?.toFixed(2)
            this.setState({ canteenBillData }, () => this.calculateTotalDiscount())
          }
          break;
        case 'totalDiscount':
          if (CommonValidation.NumberWithDot(value) && value <= this.state.subAmount || value === '') {
            this.state.totalDiscountAmount = +value
            this.state.totalDiscountPercentage = CommonValidation.amountPercentage(value, this.state.subAmount)
            this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, value)
            this.state.payableAmount = Math.round(value - this.state.subAmount)
            this.setState({
              totalDiscountPercentage: +this.state.totalDiscountPercentage,
              totalDiscountAmount: +this.state.totalDiscountAmount,
              roundOff: +this.state.roundOff,
              payableAmount: +this.state.payableAmount
            })
          }
          break;
        case 'totalPercentage':
          if (CommonValidation.DecimalNumberTwo(value) && value <= 100 || value === '') {
            this.state.totalDiscountPercentage = value
            this.state.totalDiscountAmount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
            this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, value)
            this.state.payableAmount = Math.round(value - this.state.subAmount)
            this.setState({
              totalDiscountPercentage: this.state.totalDiscountPercentage,
              totalDiscountAmount: this.state.totalDiscountAmount,
              roundOff: this.state.roundOff,
              payableAmount: this.state.payableAmount
            })
          }
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (values, names, width, height, index, disable = false) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width }}
          names={names}
          disabled={disable}
          value={values}
          onChange={(e) => this.onChangeHandler(e, names, index)}
          InputProps={{ style: { height: height } }}
          inputProps={{
            style: {
              textAlign: 'center'
            }
          }}
          variant='standard'
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  canteenBilling = () => {
    return (
      <Box component={'div'}>
        <TableContainer sx={{ border: '1px solid lightgray', height: '18vw', backgroundColor: 'white' }}>
          <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
            <TableHead>
              <TableRow sx={{ display: 'flex' }}>
                <TableCell sx={{ flex: 0.05 }} className='eMed_Pharma_tbl_header'>
                  <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                    <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                  </Button>
                </TableCell>
                <TableCell sx={{ flex: 0.05 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                <TableCell sx={{ flex: 0.30 }} className='eMed_Pharma_tbl_header'>Menu Name</TableCell>
                <TableCell sx={{ flex: 0.15 }} align='center' className='eMed_Pharma_tbl_header'>Quantity</TableCell>
                <TableCell align='center' sx={{ flex: 0.15 }} className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                <TableCell sx={{ flex: 0.15 }} className='eMed_Pharma_tbl_header'>Discount%</TableCell>
                <TableCell align='center' sx={{ flex: 0.15 }} className='eMed_Pharma_tbl_header'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.canteenBillData?.map((item, index) => {
                return (
                  <TableRow sx={{ display: 'flex', }} key={index}>
                    <TableCell sx={{ flex: 0.05 }}>
                      {item.item_name ?
                        <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                          <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                        </Button> :
                        <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn'>
                          <SearchIcon color="disabled" />
                        </Button>}
                    </TableCell>
                    <TableCell align='center' sx={{ flex: 0.05 }}>{index + 1}</TableCell>
                    <TableCell sx={{ flex: 0.30 }}>{this.renderAutoComplete(item, index)}</TableCell>
                    <TableCell align='center' sx={{ flex: 0.15 }} >
                      {this.inputTextBox(item?.qty, 'quantity', '5vw', '2vw', index, (!item?.item_name || this.state.cancelBillID ? true : false))}
                    </TableCell>
                    <TableCell align='center' sx={{ flex: 0.15 }}>{AmountFormat(item?.price || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                    <TableCell sx={{ flex: 0.15 }}>
                      {this.inputTextBox(item?.discount_percent, 'discount_percent', '5vw', '2vw', index, (!item?.qty || this.state.cancelBillID ? true : false))}
                    </TableCell>
                    <TableCell align='center' sx={{ flex: 0.15 }}>{AmountFormat(item?.total_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  deleteLineItems = (i = null) => {
    if (i === null) {
      this.setState({
        isCash: false,
        isCard: false,
        isUPI: false,
        isBankTranfer: false,
        CashAmount: "",
        CashBalanceAmount: "",
        ReceivedCashAmount: "",
        CardAmount: "",
        CardTransactionNumber: "",
        UPIAmount: "",
        UPINumber: "",
        BankTfrAmount: "",
        BankTfrNumber: "",
        canteenBillData: [
          {
            item_name: '',
            qty: '',
            price: '',
            discount_percent: '',
            discount_amount: '',
            total_amount: '',
            item_code: '',
            id: '',
          }
        ],
        subAmount: 0,
        isCredit: false,
        totalDiscountAmount: '',
        totalDiscountPercentage: '',
        payableAmount: 0,
        roundOff: 0
      })
    } else {
      this.state.canteenBillData.splice(i, 1)
      this.setState({ canteenBilling: this.state.canteenBillData, totalDiscount: "" }, () => {
        this.calculateTotalDiscount()
      })
    }
  }

  FullAmountEntered() {
    try {
      let states = this.state;
      let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
      let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
      let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
      let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
      if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt) == +states.payableAmount && +states.payableAmount !== 0) {
        return true
      } else {
        return false
      }
    }
    catch (e) {
      return false
    }
  }

  DiscardAction = (flag) => {
    if (flag) {
      this.setState({
        isCash: false,
        isCard: false,
        isUPI: false,
        isBankTranfer: false,
        CashAmount: "",
        CashBalanceAmount: "",
        ReceivedCashAmount: "",
        CardAmount: "",
        CardTransactionNumber: "",
        UPIAmount: "",
        UPINumber: "",
        BankTfrAmount: "",
        BankTfrNumber: "",
        canteenBillData: [
          {
            item_name: '',
            qty: '',
            price: '',
            discount_percent: '',
            discount_amount: '',
            total_amount: '',
            item_code: '',
            id: '',
          }
        ],
        subAmount: 0,
        isCredit: false,
        totalDiscountAmount: '',
        totalDiscountPercentage: '',
        payableAmount: 0,
        roundOff: 0,
        billDate: new Date(),
        isClear: false,
        patientData: {},
        getbillData: {},
        DateTimeChanged: false
      })
    } else {
      this.setState({
        isClear: false
      })
    }
  }

  ClosePopUp() {
    this.setState({ CompletedBillID: null }, () => {
      this.DiscardAction(true)
    })
  }

  ReasonPopupClose = () => {
    this.setState({
      isCancelled: false,
    })
  }
  addCancelComments = (comments, receipttype) => {
    this.CancelBill(comments, receipttype)
  }

  render() {
    return (
      <Box component={'div'}>
        <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background, }}>
          <Box component={'div'} sx={{ height: '4.5vw', padding: '.5vw' }}>
            {this.cardDetails()}
          </Box>
          <Box sx={{ height: '18vw', marginTop: '.5vw', }}>
            {this.canteenBilling()}
          </Box>
          <Box sx={{ height: '10vw', marginTop: '.5vw', backgroundColor: 'white' }}>
            {this.PaymentDetails(this.state.canteenBillData)}
          </Box>
          <Box component={'div'}>
            <Box display={'flex'} flexDirection={'row'}>
              <Box width={'80vw'}>
                {this.state.patientData.ip_number && !getCachevalue("isCanteenOverall") ?
                  <FormControlLabel disabled={this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.subAmount <= 0} control={<Checkbox size='small' emed_tid="add_credit_bill" checked={this.state.isCredit} onChange={(e) => { this.handleCheckBox(e, "isCredit") }} />} label={'Add To Credit'} />
                  : null}
              </Box>
              {getCachevalue("isCanteenOverall") === 'true' ?
                <Stack spacing={2} direction={'row'} width={'20vw'}>
                  <Button size='small' variant='contained' color='error' sx={{ width: '10vw', textTransform: 'capitalize' }} onClick={() => { this.setState({ isCancelled: true }) }}>{"Cancel Bill"}</Button>
                </Stack>
                :
                <Stack spacing={2} direction={'row'} width={'20vw'}>
                  <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.setState({ isClear: true }) }}>Clear</Button>
                  <Button size='small' variant='contained' disabled={(!this.state.isCredit && !this.FullAmountEntered())} sx={{ width: '10vw', textTransform: 'capitalize' }} onClick={() => { this.postCanteenBill() }}>{this.state.isCredit ? "Add to Credit" : "Receive Payment"}</Button>
                </Stack>}
            </Box>
          </Box>
        </Box>
        {this.state.isClear ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
        {this.state.isSuccessMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.isSuccessMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.CompletedBillID !== null ? <BillSuccessPoPUp isFromCanteen={true} InvoiceId={this.state.CompletedBillID} ClosePopUp={this.ClosePopUp.bind(this)} /> : null}
        {this.state.isCancelled && this.state.paymentStatus !== "pending" ?
          <ReasonPopupWithRefund
            title={"Cancel Bill"}
            AlertPopupClose={this.ReasonPopupClose.bind(this)}
            label={"Enter the Cancel Reason"}
            btntext={'Close'}
            btnvarient={'outlined'}
            btncolor={'error'}
            btntext1={'Confirm'}
            btnvarient1={'contained'}
            sendCmt={this.addCancelComments.bind(this)}
            BillReceivedAmount={this.state.payableAmount} />
          : this.state.isCancelled && this.state.paymentStatus === "pending" ? <ReasonPopup
            title={"Are you sure? Do you want to cancel the Bill"}
            AlertPopupClose={this.ReasonPopupClose.bind(this)}
            label={"Enter the Reason"}
            btntext={'Cancel'}
            btnvarient={'outlined'}
            btncolor={'error'}
            btntext1={'Confirm'}
            btnvarient1={'contained'}
            sendCmt={this.addCancelComments.bind(this)} /> : null

        }
      </Box>
    )
  }
}
export default withTranslation()(CanteenHome);