import { Box, Button, Drawer, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadBillTrans.css'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { AmountFormat, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import RadBillingFilter from './RadBillingFilter'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'

export default class RadDayEndStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dayEndData: [],
      totalPatient: 0,
      cash: 0,
      card: 0,
      upi: 0,
      bank: 0,
      credit: 0,
      cheque:0,
      page: 0,
      pageSize: 10,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PatientName": "",
        "AccNo": "",
        "MobNo": "",
        "DoctorName": [],
        "BillNumber": "",
        "PaidAmount": "",
        "InPatientSelected": true,
        "OutPatientSelected": true,
        "PaidSelected": true,
        "PartialPaidSelected": true,
        "CreditSelected": true,
        "CardSelected": true,
        "CashSelected": true,
        "UPISelected": true,
        "BankTransferSelected": true,
        "InsuranceSelected": true,
        "ChequeSelected": true,
        "BillType": ['op', 'ip', 'radiology'],
        "PayStatus": [],
        "PayMethods": [1, 2, 3, 4, 5, 6],
        'BillStatus': ["completed", "saved", "credit", "cancelled"],
        "CompletedSelected": true,
        "CreditSelected": true,
        "SavedSelected": true,
        "CancelledSelected": true,
        "startTime": null,
        "endTime": null,
        "UserList": [],
        "UserDetails": [],
        "is_user": true,
        'radiologyCreditBills': true,
        'RadiologyBills': true,
        "RadiologySelected": true
      },
    }
  }

  componentDidMount() {
    this.getDayEndData()
  }

  getDayEndData = () => {
    try {

      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      let User_ID = []
      this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))

      RestAPIService.getAll(Serviceurls.RAD_DAY_END_BILL_TRANS_GET +
        "?module_type=" + this.state.FilterDatas?.BillType +
        "&payment_method=" + this.state.FilterDatas?.PayMethods +
        "&from_date=" + this.state.FilterDatas?.FromDate +
        "&to_date=" + this.state.FilterDatas?.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&user_id=" + User_ID
      )
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              dayEndData: response.data.data?.data,
              totalAmount: response.data.data?.total_amount,
              totalPatient: response.data.data?.total_patient,
              cash: response.data.data?.cash_amt,
              card: response.data.data?.card_amt,
              upi: response.data.data?.upi_amt,
              bank: response.data.data?.bank_amt,
              credit: response.data.data?.credit_amount,
              cheque: response.data.data?.cheque_amt
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDayEndPrint = () => {
    try {
      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      let User_ID = []
      this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.RAD_DAY_END_BILL_TRANS_GET +
        "?module_type=" + this.state.FilterDatas?.BillType +
        "&payment_method=" + this.state.FilterDatas?.PayMethods +
        "&from_date=" + this.state.FilterDatas?.FromDate +
        "&to_date=" + this.state.FilterDatas?.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&user_id=" + User_ID +
        "&export_type=pdf"
      ).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ disableBtn: false })
      }).catch((error) => {
        this.setState({ disableBtn: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
        { label: "Start Time", value: timeOnlyConvert(setStartTime) },
        { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      CreditBillPopUp: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
      }
    })
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.getDayEndData() })
  }

  clearFilter = (data) => {
    this.setState({
      FilterDatas: data
    })
  }

  render() {
    this.state.dayEndData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'bill_type', headerName: 'Bill Type', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_bill_type_day'>{params?.row?.bill_type ? params?.row?.bill_type : '-'}</Box>)
      },
      {
        field: 'cash', headerName: 'Cash', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pat_name_day'>{AmountFormat(params?.row?.cash ? params?.row?.cash : 0)}</Box>)
      },
      {
        field: 'card', headerName: 'Card', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_svr_name_day'>{AmountFormat(params?.row?.card ? params?.row?.card : 0)}</Box>)
      },
      {
        field: 'upi', headerName: 'UPI', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pay_mtd_day'>{AmountFormat(params?.row?.upi ? params?.row?.upi : 0)}</Box>)
      },
      {
        field: 'bank_transfer', headerName: 'Bank TFR', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_bill_amt_day'>{AmountFormat(params?.row?.bank_transfer ? params?.row?.bank_transfer : 0)}</Box>)
      },
      {
        field: 'cheque', headerName: 'Cheque', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_chq_amt_day'>{AmountFormat(params?.row?.cheque ? params?.row?.cheque : 0)}</Box>)
      },
      {
        field: 'insurance_credit', headerName: 'Insurance Credit', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_revd_day'>{AmountFormat(params?.row?.insurance_credit ? params?.row?.insurance_credit : 0)}</Box>)
      },
      {
        field: 'corporate_credit', headerName: 'Corporate Credit', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_revd_day'>{AmountFormat(params?.row?.corporate_credit ? params?.row?.corporate_credit : 0)}</Box>)
      },
      {
        field: 'patient_credit', headerName: 'Patient Credit', flex: 0.111,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_revd_day'>{AmountFormat(params?.row?.patient_credit ? params?.row?.patient_credit : 0)}</Box>)
      }
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='rad_bill_trns_container'>
          <Box component={'div'} className='rad_bill_trns_header_card'>
            <Box component={'div'} className='rad_bill_trns_cards'>
              {AmountsCard('Total Amount', this.state.totalAmount, false, "white", false, null, 'rad_bill_trns_tlt_amt_day')}
              {AmountsCard('Cash', this.state.cash, false, "white", false, null, 'rad_bill_trns_cash_amt_day')}
              {AmountsCard('Card', this.state.card, false, "white", false, null, 'rad_bill_trns_card_amt_day')}
              {AmountsCard('UPI', this.state.upi, false, "white", false, null, 'rad_bill_trns_tlt_upi_day')}
              {AmountsCard('Bank Transfer', this.state.bank, false, "white", false, null, 'rad_bill_trns_bank_amt_day')}
              {AmountsCard('Cheque', this.state.cheque, false, "white", false, null, 'rad_bill_trns_cheque_amt_day')}
              {AmountsCard('Credit', this.state.credit, false, "white", false, null, 'rad_bill_trns_credit_amt_day')}
            </Box>
            <Box component={'div'} className='rad_bill_trns_btns'>
              <Tooltip placement='top' title={'Filter'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_flt_day'
                  onClick={() => this.setState({ FilterOpen: true })}>
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={'Print'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_prt_day'
                  disabled={this.state.disableBtn}
                  onClick={() => this.getDayEndPrint()}
                >
                  <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement='top' title={'Export'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_exp_day'>
                  <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
          <Box component={'div'} className='rad_bill_trns_content_card'>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={columns}
              rows={this.state.dayEndData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <RadBillingFilter PageName={"DayEndStatus"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>
      </Box>
    )
  }
}
