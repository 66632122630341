import React, { Component } from "react";
import { Box, Button, Stack, Paper, Typography, Modal, IconButton, TextField, Divider, RadioGroup, FormControlLabel, Radio, Tooltip, Dialog, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, InputAdornment, Checkbox, TablePagination } from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AmountFormat, formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from "../../../Utility/Constants";
import SearchIcon from '@mui/icons-material/Search';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CommonPopUp } from "../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../Styles/Colors";

let today = new Date();
class PharmaISVendorPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacy_id: null,
            pharmacyData: [],
            bill_year: today,
            pharmacy_detail: {
                total_bill_amount: 0,
                total_paid_amount: 0,
                total_balance_amount: 0,
                pharmacy_name: "",
                pharmacy_gst_no: null,
            },
            pageSize: 10,
            page: 0,
            open: false,
            openYear: false,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            disableBtn: false,
            isLoader: false,
            poPharmacyID : null,
        }
    }

    // componentDidMount() {
    //     this.GetPharmacyPayment();
    // }

    componentDidUpdate(prevProps) {
        //   let states = this.state;
        //   let history = prevProps.history?.location?.state;
        //   let purchase_order_id = history?.pharmacy_id;
        //   let isUser = !userAccess?.editAccess;
        if (this.props !== prevProps) {


            if (this.props?.history?.location?.state?.lineItem && this.props?.history?.location?.state?.lineItem?.pharmacy_id !== this.state.poPharmacyID) {
                this.setState({
                    poPharmacyID: this.props?.history?.location?.state?.lineItem.pharmacy_id ? this.props?.history?.location?.state?.lineItem.pharmacy_id : null,
 
                    // selectedItem: this.props?.history?.location?.state?.selectedItem ? this.props?.history?.location?.state?.selectedItem : {},
                    //   purchase_status: history?.purchase_status ? history?.purchase_status : "",
                    //   purchase_from: history?.from ? history?.from : "",
                    //   showSaveBtn: (history?.purchase_status === "Completely Delivered" || history?.purchase_status === 'Completed') && isUser ? false : this.state.showSaveBtn,
                    //   vendor_id: history?.purchaseDetail?.vendor_id ? history?.purchaseDetail?.vendor_id : '',
                    //   vendor_bill_id: history?.vendor_bill_id ? history?.vendor_bill_id : '',
                    //   fromGrnLine: history?.fromGrn ? history?.fromGrn : false

                }, () => {
                    this.GetPharmacyPayment();

                    //   if (!history?.purchaseDetail?.is_finished) {
                    //       this.getVendorDClist()
                    //   }
                })
            }

             
           
        }


    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetPharmacyPayment = () => {
        let { poPharmacyID, bill_year, pharmacy_detail } = this.state;
        let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.INTERNALSALES_MONTHWISE_INVOICE_REPORT}?po_pharmacy_id=${poPharmacyID}&bill_year=${billyear}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        let list = "data" in response.data && response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["sno"] = i + 1;
                        })

                        pharmacy_detail.total_bill_amount = response.data?.total_bill_amount;
                        pharmacy_detail.total_paid_amount = response.data?.total_received_amount;
                        pharmacy_detail.total_balance_amount = response.data?.total_balance_amount;
                        pharmacy_detail.pharmacy_name = response.data?.pharmacy_name;
                        pharmacy_detail.pharmacy_gst_no = response.data?.gst_no;

                        this.setState({
                            pharmacyData: list,
                            pharmacy_detail
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    printVendorMonthList = () => {
        try {
            let {poPharmacyID, pharmacy_id, bill_year } = this.state;
            let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.INTERNALSALES_MONTHWISE_INVOICE_REPORT + `?po_pharmacy_id=${poPharmacyID}&bill_year=${billyear}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    getVendorMonthListExcel = () => {
        const { t } = this.props;
        let {poPharmacyID, pharmacy_id, bill_year } = this.state;
        let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
        try {
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_MONTHWISE_INVOICE_REPORT}?po_pharmacy_id=${poPharmacyID}&export=${"excel"}&bill_year=${billyear}`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    this.errorMessage(error.response.data?.message)
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(t("PrintError"))
            this.setState({ disableBtn: false })
        }
    }

    handleClick = (statekey, value) => {
        let states = this.state;
        states[statekey] = value;
        this.setState({
            states
        })
    }

    renderYearPicker = (label, statekey, openMenu, width) => {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id="eMed_pharmadrctpo_txtbox">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={states[openMenu]}
                        onOpen={() => this.handleClick(openMenu, true)}
                        onClose={() => this.handleClick(openMenu, false)}
                        views={['year']}
                        inputFormat="yyyy"
                        label={t(label)}
                        name={statekey}
                        value={states[statekey] ? states[statekey] : new Date()}
                        autoComplete="off"
                        onChange={
                            (value) => {
                                states[statekey] = new Date(value);
                                this.setState({
                                    states
                                }, () => this.GetPharmacyPayment())
                            }
                        }
                        emed_tid={label}
                        renderInput={(params) =>
                            <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                autoComplete="off"
                                size="small"
                                sx={{ width: width ? width : "18.5vw" }}
                                {...params}
                                onClick={() => this.handleClick(openMenu, true)} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    render() {
        const { t } = this.props
        let { pharmacyData, pharmacy_detail } = this.state;
        const columns = [
            { field: 'sno', headerName: 'S.No', flex: 0.04, sortable: false, hideable: false },
            {
                field: "bill_month", headerAlign: "center", headerName: 'Month', flex: 0.142, hideable: false, align: "center",
                renderCell: ({ row }) => (<div>{row?.bill_month ? row?.bill_month : "-"}</div>)
            },
            {
                field: 'total_invoice', headerName: 'Total Invoice', flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_invoice ? row?.total_invoice : "-"}</div>)
            },
            {
                field: 'total_bill_amt', headerName: `Bill Amount (${CurrencySymbol})`, flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_bill_amt ? AmountFormat(row?.total_bill_amt).replace(`${CurrencySymbol}`, "") : "-"}</div>)
            },
            {
                field: 'total_received_amt', headerName: `Paid Amount (${CurrencySymbol})`, flex: 0.142, type: "number",
                renderCell: ({ row }) => (<div>{row?.total_received_amt ? AmountFormat(row?.total_received_amt).replace(`${CurrencySymbol}`, "") : "0.00"}</div>)
            },
            {
                field: 'total_balance_amt', headerName: `OutStanding Amount (${CurrencySymbol})`, flex: 0.142, type: "number", renderCell: ({ row }) => (
                    <Typography sx={{ color: row["total_balance_amt"] == 0 ? "#1E972A" : "#B82121" }}>
                        {row.total_balance_amt ? AmountFormat(row.total_balance_amt).replace(`${CurrencySymbol}`, "") : "0.00"}
                    </Typography>)
            },
            {
                field: 'payment_status', headerAlign: 'center', headerName: 'Status', flex: 0.125, hideable: false, align: "center", renderCell: ({ row }) => (
                    <Tooltip placement="top" title={row.payment_status === 1 ? "Completed" : "Pending"} arrow><div>
                        <img className="emedhub_popupIcon_img"
                            src={row.payment_status === 1 ? ImagePaths.checkCircle.default : ImagePaths.pendingAction.default}
                            alt={"status"} />
                    </div></Tooltip>)
            },
        ]
        return (
            <Box className="eMed_PharmaVendor_mainBox">
                <Paper className="eMed_PharmaVendor_paper" elevation={2} sx={{ height: '24vh' }}>
                    <Stack direction="row" sx={{ padding: "0.625vw" }} justifyContent="space-evenly" spacing={2} alignItems="start">
                        <IconButton emed_tid="vndmnth_bk" onClick={() => { { this.props.history.push({ pathname: "/PharmacyInternalSales/InvoiceReport" }) } }} sx={{ marginRight: "0.5vw" }}>
                            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} />
                        </IconButton>
                        <Stack spacing={2} direction="column">
                            <Typography fontWeight={'bold'}>{t("Pharmacy Details")}</Typography>
                            <div>
                                <Typography id="eMed_PharmaVendor_headTxt">{t("Pharmacy")}</Typography>
                                <Typography id="eMed_PharmaVendor_Txt">{pharmacy_detail.pharmacy_name}</Typography>
                            </div>
                            <Box component={'div'} display={"flex"} gap={'3vw'}>
                                <div>
                                    <Typography id="eMed_PharmaVendor_headTxt">{t("GSTNumber")}</Typography>
                                    <Typography id="eMed_PharmaVendor_Txt">{pharmacy_detail.pharmacy_gst_no}</Typography>
                                </div>
                                <div>
                                    <Typography id="eMed_PharmaVendor_headTxt">{t("OutstandingAmount")}</Typography>
                                    <Typography id="eMed_PharmaVendor_Txt">{`${CurrencySymbol}  ${pharmacy_detail.total_balance_amount ? AmountFormat(pharmacy_detail.total_balance_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                </div>
                                <div>
                                    <Typography id="eMed_PharmaVendor_headTxt">{t("BillAmount")}</Typography>
                                    <Typography id="eMed_PharmaVendor_Txt">{`${CurrencySymbol}  ${pharmacy_detail.total_bill_amount ? AmountFormat(pharmacy_detail.total_bill_amount).replace(`${CurrencySymbol}`, "") : 0}`}</Typography>
                                </div>
                            </Box>
                        </Stack>
                        <Stack spacing={2} direction="column" justifyContent="flex-end">

                        </Stack>
                    </Stack>
                </Paper>
                <Stack sx={{ margin: "0.625vw" }} spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                    {this.renderYearPicker("", "bill_year", "openYear", "8vw")}
                    <Tooltip placement="top" title={t("print")} arrow>
                        <IconButton emed_tid="vndmnth_print" size="small" disabled={this.state.disableBtn} onClick={() => this.printVendorMonthList()}>
                            <img className="emedhub_directPo_img" src={ImagePaths.LabPrintIcon.default} alt='print' />
                        </IconButton></Tooltip>
                    <Tooltip placement="top" title={t("Export")} arrow>
                        <IconButton emed_tid="vndmnth_export" size="small" disabled={this.state.disableBtn} onClick={() => this.getVendorMonthListExcel()}>
                            <img className="emedhub_directPo_img" src={ImagePaths.LabUploadButton.default} alt='upload' />
                        </IconButton></Tooltip>
                </Stack>
                <div id="eMed_PharmaVendor_Table" style={{ height: '46vh' }}>
                    <DataGrid
                        rows={pharmacyData}
                        columns={columns}
                        disableColumnFilter
                        getRowId={(row) => row.sno}
                        hideFooterSelectedRowCount
                        components={{
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "30vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        rowCount={pharmacyData?.length}
                        page={this.state.page}
                        onPageChange={(newPage) => this.setState({ page: newPage })}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                    />
                </div>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(PharmaISVendorPayment);