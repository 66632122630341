import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import '../../Radiology/Reports/RadRpts.css'
import { Colors } from '../../../Styles/Colors'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { CurrencySymbol } from '../../../Utility/Constants'
import { DateTime } from 'luxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export default class TestCensusReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      testCensusData: [],
      testCensusDataOption :[],
      page: 0,
      pageSize: 10,
      isLoader  : false,
      FilterOpen: false,
      fromFilterYear: new Date(),
      toFilterYear: new Date(),
      testNameList: [],
      selected_test_name: null,
    }
  }


  componentDidMount() {
    this.getTestCensusReportData()
    this.testCencusOptions()

  }
  testCencusOptions = () => {
    this.setState({isLoader: true})
    try {
      RestAPIService.getAll(Serviceurls.LAB_CENSUS_REPORT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
               testCensusDataOption: response.data.data ? response.data.data : [],
            },()=>{this.setState({isLoader : false})})
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
            this.setState({loading: false})
          } else {
            this.errorMessage(error.message)
            this.setState({loading: false})
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({loading: false})
    }
  }

  getTestCensusReportData = () => {
    this.setState({isLoader: true})
    try {
            let from_filtered_year = this.state.fromFilterYear ? new Date(this.state.fromFilterYear).getFullYear() : ''
      let to_filtered_year = this.state.toFilterYear ? new Date(this.state.toFilterYear).getFullYear() : ''
      RestAPIService.getAll(Serviceurls.LAB_CENSUS_REPORT+ `?test_name=${this.state.selected_test_name ? this.state.selected_test_name : ""}&from_year=${from_filtered_year}&to_year=${to_filtered_year}` )
        // RestAPIService.getAll(Serviceurls.LAB_CENSUS_REPORT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              testCensusData: response.data.data ? response.data.data : [],
            },()=>{this.setState({isLoader : false})})
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
            this.setState({loading: false})
          } else {
            this.errorMessage(error.message)
            this.setState({loading: false})
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({loading: false})
    }
  }

  printCensusReport = () => {
    try {
      let from_filtered_year = this.state.fromFilterYear ? new Date(this.state.fromFilterYear).getFullYear() : ''
      let to_filtered_year = this.state.toFilterYear ? new Date(this.state.toFilterYear).getFullYear() : ''
      RestAPIService.getAll(Serviceurls.LAB_CENSUS_REPORT + `?test_name=${this.state.selected_test_name ? this.state.selected_test_name : ""}&from_year=${from_filtered_year}&to_year=${to_filtered_year}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  censusReportExcelExport = () => {
    try {
      let from_filtered_year = this.state.fromFilterYear ? new Date(this.state.fromFilterYear).getFullYear() : ''
      let to_filtered_year = this.state.toFilterYear ? new Date(this.state.toFilterYear).getFullYear() : ''

      RestAPIService.excelGet(Serviceurls.LAB_CENSUS_REPORT + `?test_name=${this.state.selected_test_name ? this.state.selected_test_name : ""}&from_year=${from_filtered_year}&to_year=${to_filtered_year}&export_type=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"]?.split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  closeFilter = () => {
    this.setState({
      FilterOpen: false
    })
  }
  renderFilterScreen = () => {
    let states = this.state
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{'Filter'}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' marginTop={"1vw"}>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              options={states.testCensusDataOption}
              getOptionLabel={(options) => typeof (options) === 'string' ? options : options.test_name}
              onChange={(e, newValue) => {
                this.setState({
                  selected_test_name: newValue?.test_name
                })
              }}
              value={states.selected_test_name}
              sx={{ width: '21.5vw' }}
              renderInput={(params) => <TextField {...params} label={('Select Test Name')} />}
            />
          </Box>
          <Box display={"flex"} flexDirection={"row"}>

            <Box className='eMed_rts_dropdown' marginTop={"1vw"}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={states.openMenu}
                  onOpen={() => { this.setState({ openMenu: true }) }}
                  onClose={() => { this.setState({ openMenu: false }) }}
                  views={['year']}
                  inputFormat="yyyy"
                  label={("Select From Year")}
                  name={"Select From Year"}
                  value={this.state.fromFilterYear ? this.state.fromFilterYear : new Date()}
                  autoComplete="off"
                  onChange={
                    (value) => {
                      this.setState({
                        fromFilterYear: new Date(value)
                      })
                    }
                  }
                  emed_tid={"Select Year"}
                  renderInput={(params) =>
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "10vw" }}
                      {...params}
                      onClick={(e) => { this.setState({ openMenu: true }) }} />}
                />
              </LocalizationProvider>
            </Box>
            <Box className='eMed_rts_dropdown' marginTop={"1vw"}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={states.toOpenMenu}
                  onOpen={() => { this.setState({ toOpenMenu: true }) }}
                  onClose={() => { this.setState({ toOpenMenu: false }) }}
                  views={['year']}
                  inputFormat="yyyy"
                  label={("Select To Year")}
                  name={"Select To Year"}
                  value={this.state.toFilterYear ? this.state.toFilterYear : new Date()}
                  autoComplete="off"
                  onChange={
                    (value) => {
                      this.setState({
                        toFilterYear: new Date(value)
                      })
                    }
                  }
                  emed_tid={"Select Year"}
                  renderInput={(params) =>
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      autoComplete="off"
                      size="small"
                      sx={{ width: "10vw" }}
                      {...params}
                      onClick={(e) => { this.setState({ toOpenMenu: true }) }} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              this.setState({
                selected_test_name: null,
                fromFilterYear: new Date(),
                toFilterYear: new Date(),

              },
                () => this.getTestCensusReportData()
              )
            }}
            id="eMed_Config_btn">{"Clear"}</Button>
          <Button variant='contained' size="small"
            onClick={() => {
              this.setState({ FilterOpen: false }, () => this.getTestCensusReportData())
            }}
            id="eMed_Config_btn">{"Search"}</Button>
        </Stack>
      </Box>
    )
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Year", value: this.state.fromFilterYear ? new Date(this.state.fromFilterYear).getFullYear() : '' },
      { label: "To Year", value: this.state.toFilterYear ? new Date(this.state.toFilterYear).getFullYear() : '' },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }


  render() {
    this.state.testCensusData?.forEach((element, index) => element.sno = index + 1)

    const column = [
      {
        field: "test_name", headerName: "Test Name", flex: 3,
        renderCell: (params) => (<Box component={'div'}>{params.row.test_name?.length > 55  ?
          <Tooltip placement='top' title={params.row.test_name}><div>{params.row.test_name.slice(0, 55) + "..."}</div></Tooltip>
          : params.row.test_name ? params.row.test_name : "0"}</Box>)

      },
      {
        field: "jan", headerName: "Jan", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.jan?.length > 20 ?
          <Tooltip placement='top' title={params.row.jan}><div>{params.row.jan?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.jan ? params.row.jan : "0"}</Box>)

      },
      {
        field: "feb", headerName: "Feb", flex: 0.58, type: 'number', 
        renderCell: (params) => (<Box component={'div'}>{params.row.feb?.length > 20 ?
          <Tooltip placement='top' title={params.row.feb}><div>{params.row.feb?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.feb ? params.row.feb : "0"}</Box>)

      },
      {
        field: "mar", headerName: "Mar", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.mar?.length > 20 ?
          <Tooltip placement='top' title={params.row.mar}><div>{params.row.mar?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.mar ? params.row.mar : "0"}</Box>)

      },
      {
        field: "apr", headerName: "Apr", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.apr?.length > 20 ?
          <Tooltip placement='top' title={params.row.apr}><div>{params.row.apr?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.apr ? params.row.apr : "0"}</Box>)

      },
      {
        field: "may", headerName: "May", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.may?.length > 20 ?
          <Tooltip placement='top' title={params.row.may}><div>{params.row.may?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.may ? params.row.may : "0"}</Box>)

      },
      {
        field: "jun", headerName: "Jun", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.jun?.length > 20 ?
          <Tooltip placement='top' title={params.row.jun}><div>{params.row.jun?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.jun ? params.row.jun : "0"}</Box>)

      },
      {
        field: "jul", headerName: "Jul", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.jul?.length > 20 ?
          <Tooltip placement='top' title={params.row.jul}><div>{params.row.jul?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.jul ? params.row.jul : "0"}</Box>)

      },
      {
        field: "Aug", headerName: "Aug", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.Aug?.length > 20 ?
          <Tooltip placement='top' title={params.row.Aug}><div>{params.row.Aug?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.Aug ? params.row.Aug : "0"}</Box>)

      },

      {
        field: "sep", headerName: "Sep", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.sep?.length > 20 ?
          <Tooltip placement='top' title={params.row.sep}><div>{params.row.sep?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.sep ? params.row.sep : "0"}</Box>)

      },
      {
        field: "oct", headerName: "Oct", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.oct?.length > 20 ?
          <Tooltip placement='top' title={params.row.oct}><div>{params.row.oct?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.oct ? params.row.oct : "0"}</Box>)

      },
      {
        field: "nov", headerName: "Nov", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.nov?.length > 20 ?
          <Tooltip placement='top' title={params.row.nov}><div>{params.row.nov?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.nov ? params.row.nov : "0"}</Box>)

      },
      {
        field: "dec", headerName: "Dec", flex: 0.58, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params.row.dec?.length > 20 ?
          <Tooltip placement='top' title={params.row.dec}><div>{params.row.dec?.slice(0, 20) + "..."}</div></Tooltip>
          : params.row.dec ? params.row.dec : "0"}</Box>)

      },
    ]
    return (

      <Box component={'div'} className='eMed_rts_container'>
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'>
          </Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Box>
            <Button className='eMed_usrconf_btn' onClick={() => { this.printCensusReport() }}>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button>
            <Button className='eMed_usrconf_btn' onClick={() => { this.censusReportExcelExport() }}>
              <img src={ImagePaths.ExportIcon.default} alt="print" className='eMed_action_img' />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_rts_table'>
          <DataGrid
            rows={this.state.testCensusData}
            columns={column}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            density={'compact'}
            rowHeight={100}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}


            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
