import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let selectedStatus = ["Active", "Inactive"]

let RoleData = null;
let userAccess = null;
class MethodMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            methodMasterData: [],
            searchMethod: "",
            page: 0,
            pageSize: 10,
            rowCount: 0,
            selected_status: "",
            openPopUp: false,
            departmentcode: null,
            departmentName: "",
            order: "",
            shortName: "",
            deptStatus: true,
            isFromEdit: false,
            selectedRow: {},
            comments: "",
            selectedId: "",
            deptData: [],
            selected_dept: null,
            disableBtn :false,
            selectedDept: {},
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            disableBtn: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    componentDidMount() {
        RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
        userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", "Method Master", "SubTab")
        this.getDeptData()
        this.getMethodMaster()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }
    postMethodMaster = () => {
        try {
            this.LoaderFunction(true);
            let data = {
                "method_code": this.state.methodCode ? this.state.methodCode : null,
                "method_name": this.state.methodName ? CommonValidation.removeSpace(this.state.methodName) : '',
                "select_dept": this.state.selectedDept?.id ? this.state.selectedDept?.id : '',
                "id": this.state.selectedId ? this.state.selectedId : null,
                "is_active": this.state.deptStatus ? this.state.deptStatus : false,
                "reason": this.state.comments ? CommonValidation.removeSpace(this.state.comments) : '',
            }
            RestAPIService.create(data, Serviceurls.GET_LAB_METHOD_MASTER).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.successMessage(response?.data?.message)
                        this.setState({ openPopUp: false, disableBtn: false, isFromEdit: false, methodCode: "", selectedId : null })
                        this.handleClear()
                        this.getMethodMaster()
                    }
                    else {
                        this.setState({disableBtn :false})
                        this.LoaderFunction(false);
                        this.errorMessage(response?.data?.message)
                    }
                }).catch((error) => {
                    this.setState({disableBtn :false})
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.LoaderFunction(false);

                })
        }
        catch (e) {
            this.setState({disableBtn :false})
            this.errorMessage(e?.message)
        }
    }

    getDeptData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.GET_LAB_DEPARTMENT_MASTER).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            deptData: response.data.data ? response.data.data : []
                        },()=>{
                            let filteredDeptData = this.state.deptData?.filter((item)=> item.is_active)
                            this.setState({deptData :filteredDeptData})
                            })
                    }
                    else {
                        this.LoaderFunction(false);
                        this.errorMessage(response?.data?.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                      } else {
                        this.errorMessage(error?.message)
                      }
                      this.LoaderFunction(false);
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }
    getMethodMaster = () => {
        try {
            this.LoaderFunction(true);
            let status = this.state.selected_status === "Active" ? true : this.state.selected_status === "Inactive" ? false : ""
            RestAPIService.getAll(Serviceurls.GET_LAB_METHOD_MASTER + `?select_dept=${this.state.selected_dept?.id ? this.state.selected_dept.id : ""}&is_active=${status}&method_name=${this.state.searchMethod}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            methodMasterData: response?.data?.data ? response.data.data : []
                        })
                    }
                    else {
                        this.LoaderFunction(false);
                        this.errorMessage(response?.data?.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                      } else {
                        this.errorMessage(error?.message)
                      }
                      this.LoaderFunction(false);
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    handleClear = () => {
        this.setState({
            methodName: '',
            selectedDept: {},
            selected_dept: null,
            deptStatus: true,
            comments: "",
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid="vnd_close" onClick={() => { this.setState({ FilterOpen: false  }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={t("Search Method")}
                            sx={{ width: "20vw" }}
                            size="small"
                            autoComplete="off"
                            value={states.searchMethod ? states.searchMethod : ""}
                            inputProps={{ emed_tid: "VendorName", maxLength: 100 }}
                            onChange={(e) => {
                                this.setState({
                                    searchMethod: e.target.value.trimStart()
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            sx={{ width: "20vw" }}
                            disableClearable
                            options={states.deptData ? states.deptData : []}
                            // getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.dept_name}

                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.dept_name}
                            value={this.state.selected_dept ? this.state.selected_dept : null}
                            onChange={(event, newValue) => {
                                this.setState({ selected_dept: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                                    label={t('Select Department')}
                                    autoComplete='off'
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            sx={{ width: "20vw" }}
                            disableClearable
                            options={selectedStatus}
                            getOptionLabel={(option) => (option)}
                            value={this.state.selected_status}
                            onChange={(event, newValue) => {
                                this.setState({ selected_status: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                                    label={t('Select Status')}
                                    autoComplete='off'
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid="fil_clear"
                        onClick={() => {
                            this.setState({
                                selected_status: "",
                                searchMethod: "",
                                selected_dept: null,
                            }, () => this.getMethodMaster())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid="fil_search"
                        onClick={() => {
                            this.getMethodMaster()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    handleEdit = (data) => {
        let deptValue = this.state.deptData?.find((item) => item.id === data.select_dept)
        this.setState({
            isFromEdit: true,
            openPopUp: true,
            methodCode: data?.method_code ? data?.method_code: null,
            methodName: data?.method_name ? data?.method_name : "",
            selectedDept: deptValue ? deptValue : {},
            selectedId: data?.id,
            deptStatus: data?.is_active,
            comments: data?.reason ? data.reason : ''
        })

    }
    renderTextField = (value, statekey, Label, required = false, width, disable = false) => {
        const { t } = this.props;
        let states = this.state
        return (
            statekey === "selectedDept" ?
                <Autocomplete
                    size='small'
                    clearIcon
                    sx={{ width: width ? width : "12vw", margin: "0.65vw", marginTop: "1.5vw" }}
                    options={this.state.deptData}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.dept_name}
                    value={value}
                    required = {required}
                    onChange={(event, newValue) => {
                        states[statekey] = newValue
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={Label}
                        />
                    )}
                />
                :
                <TextField
                    sx={{ width: width ? width : "12vw", marginX: '0.65vw', marginTop: "1vw" }}
                    size="small"
                    disabled={disable}
                    inputProps={{ maxLength: 100 }}
                    autoComplete="off"
                    label={t(Label)}
                    required={required}
                    value={value}
                    onChange={(e) => {
                        let value = e.target.value
                        if (statekey === "order") {
                            if (CommonValidation.numberOnly(value) || value === "") {
                                states[statekey] = value.trimStart()
                                this.setState({ states })
                            }
                        } else {
                            states[statekey] = value.trimStart()
                            this.setState({ states })
                        }

                    }}
                />
        )
    }

    handleValidation = () => {
        if (!this.state.methodName) {
            this.errorMessage("Enter Method Name")
        } else if (this.state.deptStatus === false && !this.state.comments) {
            this.errorMessage("Enter Inactive Reason")
        }
        else if (!this.state.selectedDept?.id) {
            this.errorMessage("Enter Department Name")
        } else {
            this.postMethodMaster()
        }
    }
    addNewDepartPopup = () => {
       let states = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "42vw", height: "63vh", maxHeight: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit Method" : "Add Method"}</Typography>
                                <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openPopUp: false, isFromEdit: false, methodCode: "", selectedId: null }, () => { this.handleClear() })} >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                            <hr />
                            <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <Box  >
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                            {this.state.isFromEdit ?
                                                this.renderTextField(this.state.methodCode, "methodCode", "Method Code", true, "12vw", true)
                                                : null}
                                            {this.renderTextField(this.state.methodName, "methodName", "Method Name", true, !this.state.isFromEdit ? "18.5vw" :"12vw")}
                                            {this.renderTextField(this.state.selectedDept?.dept_name ? this.state.selectedDept?.dept_name : null, "selectedDept", "Select Department*", true, !this.state.isFromEdit ? "18.5vw" :"12vw")}
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                <Box component={'div'} style={{ display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ marginLeft: "1vw", marginTop: "1vh" }}>
                                        <Typography>{"Status"}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginLeft: '1vw' }}>
                                        <Checkbox
                                            disabled ={!this.state.isFromEdit}
                                            checked={this.state.deptStatus}
                                            onChange={(e) => { this.setState({ deptStatus: e.target.checked ,comments :""}) }}
                                        />
                                        <Typography>{"Active"}</Typography>
                                    </Box>
                                </Box>}
                            {this.state.deptStatus === false ?
                                <Box>
                                    <Box sx={{ marginLeft: "1vw", marginTop: "1vh" }}>
                                        <Typography>{"Inactive Comments"}</Typography>
                                    </Box>
                                    <Box sx={{ marginLeft: "1vw", marginTop: "1vh" }}>
                                        <div>
                                            <TextField
                                                size='small'
                                                label={("Reason *")}
                                                style={{ marginTop: "0.5vw", width: "38.6vw" }}
                                                multiline={true}
                                                disabled ={!this.state.isFromEdit}
                                                inputProps={{ maxLength: 250 }}
                                                rows={3}
                                                variant="outlined"
                                                value={this.state.comments}
                                                onChange={(event) => {
                                                    let value = event.target.value
                                                    if (value?.length <= 250) {
                                                        this.setState({ comments: value })
                                                    } else {
                                                        this.errorMessage('Allowed only 250 characters')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Box>
                                </Box>
                                : null
                            }
                        </Box>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                variant='outlined'
                                onClick={() => { this.handleClear() }}
                            >Clear</Button>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                disabled={this.state.disableBtn}
                                variant='contained'
                                onClick={() => { this.setState({ disableBtn: true }, () => this.handleValidation()) }}
                            >{states.isFromEdit ? "Update" : "Add"}</Button>
                        </div>
                    </Box>

                </Modal>
            </div>
        )
    }
    gridToolBar = () => {
        var ExportData = []
        this.state.methodMasterData?.length > 0 && this.state.methodMasterData?.map((item) => {
            return (
                ExportData?.push({
                    "SI.No": item?.sno, "Method Code": item?.method_code, "Method Name": item?.method_name, "Department": item?.select_dept_name, "Status": item?.is_active === true ? "Active" : item?.is_active === false ? "InActive" : "",
                })
            )
        }
        )
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Method Master"} filename={"Method Master"} />
            </Box>
        )
    } 
    renderTable = () => {
        this.state.methodMasterData?.forEach((element, index) => element.sno = index + 1)

        const { t } = this.props
        const columns = [
            {
                field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "method_code", flex: 0.166, headerName: t("Method Code"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.method_code ? params?.row?.method_code : "-"}</Box>)
            },
            {
                field: "method_name", flex: 0.186, headerName: t("Method Name"), headerAlign: "left", align: "left",
                renderCell: (params) => (<div>{params?.row?.method_name?.length > 25 ?
                    <Tooltip placement="top" title={params?.row?.method_name} arrow><div>{params?.row?.method_name?.slice(0, 22) + "..."}</div></Tooltip>
                    : params?.row?.method_name ? params?.row?.method_name : "-"}</div>)
            },
            {
                field: "select_dept_name", flex: 0.186, headerName: t("Department"), headerAlign: "left", align: "left",
                renderCell: (params) => (<div>{params?.row?.select_dept_name?.length > 25 ?
                    <Tooltip placement="top" title={params?.row?.select_dept_name} arrow><div>{params?.row?.select_dept_name?.slice(0, 22) + "..."}</div></Tooltip>
                    : params?.row?.select_dept_name ? params?.row?.select_dept_name : "-"}</div>)
            },
            {
                field: "is_active", flex: 0.166, headerName: t("Status"), headerAlign: "left", align: "left",
                renderCell: (params) => {
                    return (
                        <Tooltip placement="top" title={params?.row?.reason} arrow><div>{params?.row?.is_active ? "Active" : "Inactive"}</div></Tooltip>  
                    )
                }
            },
            {
                field: "action", flex: 0.134, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    {userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" arrow>
                        <Button emed_tid="vnd_edit" className='eMed_usrconf_btn' onClick={() => { this.handleEdit(params.row) }} >
                            <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Tooltip> : null}
                </Box>
            }
        ]
        return (
            <Box component={"div"} className='eMed_Purcahse_Table_Container' sx={{ backgroundColor: Colors.Background }}>
                <Stack component={"div"}>
                    <Paper className='eMed_Purchase_Table_Wrapper' >
                        <DataGrid
                            rows={this.state.methodMasterData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            hideFooterSelectedRowCount
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: this.gridToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            loading={this.state.isLoader}
                            rowCount={this.state.methodMasterData?.length}
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            disableSelectionOnClick
                        />
                    </Paper>
                </Stack>
            </Box>
        )
    }
    render() {
        const { t } = this.props;
        return (
            <div style={{ backgroundColor: Colors.Background }}>
                <div className='eMed_Purchase_Table_top' style={{ backgroundColor: Colors.Background }}>
                    {userAccess?.editAccess ? <><Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.setState({ openPopUp: true }) }}>{"Add New Method"}</Button>
                        <Divider orientation='vertical' /></> : null}
                    <Tooltip title="Filter" placement='top' arrow>
                        <Button emed_tid="vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Tooltip>

                </div>
                {this.renderTable()}
                {this.addNewDepartPopup()}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

            </div>
        )
    }
}
export default withTranslation()(MethodMaster)