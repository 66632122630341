import { IconButton, AccordionDetails, AccordionSummary, Menu, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography, DialogContent, DialogContentText, Dialog, DialogActions, RadioGroup, Radio, Divider } from '@mui/material'
import React, { Component } from 'react'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from '../../../../Styles/Colors';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonPatientDetails, ReasonPopupWithRefund } from '../../../../Components/Common Components/CommonComponents';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { AmountFormat, CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import Loader from '../../../../Components/Loader';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';


class ReserveReceivePayement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payabelDate: null,
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            isInsurance: false,
            cashAmt: null,
            cardAmt: null,
            cardNo: null,
            upiAmt: null,
            upiNo: null,
            bankTfrAmt: null,
            bankTfrNo: null,
            chequeAmt: null,
            chequeNo: null,
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            TwoThounsandCountsPayment: "",
            FiftyCountsPayment: "",
            FiveHundredCountsPayment: "",
            TwentyCountsPayment: "",
            TwoHundredCountsPayment: "",
            TenCountsPayment: "",
            HundredCountsPayment: "",
            CoinsCountsPayment: "",
            FiveCountPayment: "",
            patientDetails: {},
            reservation_id: null,
            paymentDetails: [],
            payable_amt: '',
            paymentDate: new Date(),
            isCancel: false,
            ReceiptCancelFor: 'Cancel',
            SelectedPatientDetails: {}
        }
    }

    componentDidUpdate = () => {
        let patientData = getCachevalue("reservePatientData") ? JSON.parse(getCachevalue("reservePatientData")) : {}
        if (patientData?.id && patientData?.id !== this.state.reservation_id) {
            this.setState({ reservation_id: patientData?.id, patientId: patientData?.patient_id, status: patientData?.status }, () => {
                this.getPatientDetailData()
                this.getReservePayment()
            })
        }
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data ? response?.data?.data : {},
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getReservePayment = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.IP_RESERVATION_PAYMENT}?reservation_id=${this.state.reservation_id}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            paymentDetails: response.data.data,
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    handleReceive = () => {
        let states = this.state;
        let paymentMode = []
        if (states.isCash && states.cashAmt) { paymentMode.push(1) }
        if (states.isCard) { paymentMode.push(2) }
        if (states.isUPI) { paymentMode.push(3) }
        if (states.isBankTranfer) { paymentMode.push(5) }
        if (states.isCheque) { paymentMode.push(4) }
        let datas = {
            "receipt_for": states.payable_amt ? "Advance" : '',
            "amount_received": states.payable_amt ? +(Number(states.payable_amt)?.toFixed(2)) : 0,
            "invoice_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
            "payment_mode": paymentMode?.length > 0 ? paymentMode : [],
            "amount_cash": states.cashAmt ? +states.cashAmt : 0,
            "amount_card": states.cardAmt ? +states.cardAmt : 0,
            "upi_amount": states.upiAmt ? +states.upiAmt : 0,
            "bank_transfer_amount": states.bankTfrAmt ? +states.bankTfrAmt : 0,
            "cheque_amount": states.chequeAmt ? +states.chequeAmt : 0,
            "card_ref_no": states.cardNo ? states.cardNo : '',
            "upi_ref_no": states.upiNo ? states.upiNo : '',
            "bank_ref_no": states.bankTfrNo ? states.bankTfrNo : '',
            "cheque_ref_no": states.chequeNo ? states.chequeNo : '',
            "reservation_id": states.reservation_id ? states.reservation_id : null,
            "with_reservation": false,
        }
        try {
            this.LoaderFunction(true)
            RestAPIService.create(datas, Serviceurls.FO_RESERVATION_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        this.successMessage(response.data?.message);
                        this.handleClear()
                        this.getReservePayment()
                        this.setState({ disableBtn: false })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.LoaderFunction(false)
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            disableBtn: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderCountsText = (Amount, Key, Right = false) => {
        var states = this.state
        return (
            <Box component={"div"} marginLeft={Right ? "2vw" : "1.3vw"} className="eMed_recPayment_Denomi_invidual_div">
                <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
                <Typography>x</Typography>
                <TextField
                    placeholder="counts"
                    size='small'
                    inputProps={{ maxLength: 4 }}
                    className='eMed_crdPop_amountTextField'
                    variant='standard'
                    value={states[Key]}
                    onChange={(e) => {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </Box>
        )
    }

    returnTotal() {
        try {
            let Amount = 0
            let states = this.state
            if (states.TwoThounsandCounts != "") {
                Amount += +states.TwoThounsandCounts * 2000
            }
            if (states.FiveHundredCounts != "") {
                Amount += +states.FiveHundredCounts * 500
            }
            if (states.TwoHundredCounts != "") {
                Amount += +states.TwoHundredCounts * 200
            }
            if (states.HundredCounts != "") {
                Amount += +states.HundredCounts * 100
            }
            if (states.FiftyCounts != "") {
                Amount += +states.FiftyCounts * 50
            }
            if (states.TwentyCounts != "") {
                Amount += +states.TwentyCounts * 20
            }
            if (states.TenCounts != "") {
                Amount += +states.TenCounts * 10
            }
            return Amount
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleChange = (event, stateKey, strField) => {
        let states = this.state;
        let name = event.target.name;
        let value = event.target.value?.trimStart();
        let isValid = false;
        if (strField) {
            isValid = CommonValidation.ALPHA_NUMARIC(value);
            if (value === "" || (isValid && value?.length <= 16)) {
                states[stateKey] = value;
                this.setState({ states })
            }
        } else {
            isValid = CommonValidation.NumberWithDot(value);
            if (value === "" || (isValid && value > 0)) {
                states[name] = value;
                this.setState({ states })
            }
        }
    }

    renderTextBox(label, statekey, value, width, image, helperText, amtEdit) {
        const { t } = this.props;
        let { open } = this.state;
        let states = this.state;
        let strField = (statekey === "cardNo" || statekey === "upiNo" || statekey === "bankTfrNo" || statekey === "chequeNo")
        return (
            <div id="eMed_IP_recPayment_Div" sx={{ height: "69vh" }}>
                {statekey === "paymentDate" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            open={open}
                            onOpen={() => { this.setState({ open: true }) }}
                            onClose={() => { this.setState({ open: false }) }}
                            disabled={true}
                            inputFormat="dd-MM-yyyy"
                            label={label ? t(label) + "*" : ""}
                            name={statekey}
                            value={value ? value : null}
                            autoComplete="off"
                            maxDate={DateTime.now()}
                            onChange={(value) => {
                                states["paymentDate"] = value
                                this.setState({
                                    states
                                })
                            }
                            }
                            renderInput={(params) => <TextField onKeyDown={(e) => this.onKeyDown(e)} size="small" sx={{ width: width ? width : "14.5vw" }} {...params} onClick={() => { this.setState({ open: true }) }} />}
                        />
                    </LocalizationProvider>
                    :
                    <TextField
                        sx={{ width: width ? width : "10vw" }}
                        size="small"
                        autoComplete="off"
                        label={t(label)}
                        name={statekey}
                        type={"text"}
                        value={value ? value : ""}
                        required={true}
                        variant={statekey === "payable_amt" ? "outlined" : "filled"}
                        placeholder={(statekey === "payable_amt" || strField) ? "" : "1,00,000.00"}
                        InputProps={{
                            startAdornment: (statekey === "payable_amt" || strField) ? null : (
                                <InputAdornment position="start">
                                    <img src={image} alt={label} className='eMed_action_img_small' />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => this.handleChange(e, statekey, strField)}
                        helperText={helperText ? t(helperText) : ""}
                    />
                }
            </div>

        )
    }

    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ cashAmt: null }) }
            if (!states.isCard) { this.setState({ cardAmt: null, cardNo: null }) }
            if (!states.isUPI) { this.setState({ upiAmt: null, upiNo: null }) }
            if (!states.isCheque) { this.setState({ chequeAmt: null, chequeNo: null }) }
            if (!states.isBankTranfer) { this.setState({ bankTfrAmt: null, bankTfrNo: null }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ cashAmt: this.state.payable_amt, })
            }
        })
    }

    renderCountsText = (Amount, Key, Right = false) => {
        var states = this.state
        return (
            <Box component={"div"} marginLeft={Right ? "2vw" : "1.3vw"} className="eMed_recPayment_Denomi_invidual_div">
                <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
                <Typography>x</Typography>
                <TextField
                    placeholder="counts"
                    size='small'
                    inputProps={{ maxLength: 4 }}
                    className='eMed_crdPop_amountTextField'
                    variant='standard'
                    value={states[Key]}
                    onChange={(e) => {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </Box>
        )
    }


    ReceivePaymentTab = () => {
        const { t } = this.props
        let states = this.state;
        return (
            <div>
                <div className="eMed_recPayment_subDiv" style={{ height: "54vh", overflow: 'auto' }}>
                    <Stack className="emedhub_recPayment_RightContentBox" direction="row" justifyContent="center" alignItems="center" spacing={2} mt={"0.5vw"}>
                        {this.renderTextBox("Receivable Amount", "payable_amt", states["payable_amt"], "13.4vw")}
                        {this.renderTextBox("Receipt Date", "paymentDate", states["paymentDate"], "13.2vw")}
                    </Stack>
                    <Stack className="emedhub_recPayment_RightContentBox" direction="column" justifyContent="center" alignItems="center" spacing={1} sx={{ padding: '1vw 1.25vw' }}>
                        <FormGroup row sx={{ display: 'flex', gap: '0.5vw' }}>
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Card")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
                            <FormControlLabel control={<Checkbox size="small" checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cheque")}</Typography>} />
                        </FormGroup>

                        <Box component={'div'} display={"flex"} justifyContent={"flex-start"} flexDirection={"column"} sx={{ width: "27.5vw" }}>
                            {this.state.isCash ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                                    {this.renderTextBox("", "cashAmt", states["cashAmt"], "13.5vw", CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default, "CashAmount", !states["isCash"])}
                                </Stack> : null}
                            {this.state.isCard ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderTextBox("", "cardAmt", states["cardAmt"], "13.5vw", ImagePaths.CardIcon.default, "CardAmount", !states["isCard"])}
                                    {this.renderTextBox("", "cardNo", states["cardNo"], "13.5vw", "", "ReferenceNo", !states["isCard"])}
                                </Stack> : null}
                            {this.state.isUPI ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderTextBox("", "upiAmt", states["upiAmt"], "13.5vw", ImagePaths.UPIIcon.default, "UPIAmount", !states["isUPI"])}
                                    {this.renderTextBox("", "upiNo", states["upiNo"], "13.5vw", "", "ReferenceNo", !states["isUPI"])}
                                </Stack> : null}
                            {this.state.isBankTranfer ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderTextBox("", "bankTfrAmt", states["bankTfrAmt"], "13.5vw", ImagePaths.BankTransferIcon.default, "BankTransferAmount", !states["isBankTranfer"])}
                                    {this.renderTextBox("", "bankTfrNo", states["bankTfrNo"], "13.5vw", "", "ReferenceNo", !states["isBankTranfer"])}
                                </Stack> : null}
                            {this.state.isCheque ?
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                                    {this.renderTextBox("", "chequeAmt", states["chequeAmt"], "13.5vw", ImagePaths.ChequeIcon.default, "ChequeAmount", !states["isCheque"])}
                                    {this.renderTextBox("", "chequeNo", states["chequeNo"], "13.5vw", "", "ReferenceNo", !states["isCheque"])}
                                </Stack>
                                : null
                            }
                        </Box>
                        {
                            this.state.isCash ?
                                <Box component={"div"} className="eMed_recPayment_Denomi_div" sx={{ display: this.state.isCash ? "block" : "none" }}>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                        {this.renderCountsText("2000", "TwoThounsandCounts")}
                                        {this.renderCountsText("50", "FiftyCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                        {this.renderCountsText("500", "FiveHundredCounts")}
                                        {this.renderCountsText("20", "TwentyCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                        {this.renderCountsText("200", "TwoHundredCounts")}
                                        {this.renderCountsText("10", "TenCounts", true)}
                                    </Box>
                                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                        {this.renderCountsText("100", "HundredCounts")}
                                    </Box>
                                </Box> : null
                        }
                    </Stack>
                </div>
            </div>
        )
    }

    handleValidation = () => {
        let states = this.state
        let msg = null
        let CashAmt = states.cashAmt === "" ? 0 : +states.cashAmt;
        let CardAmt = states.cardAmt === "" ? 0 : +states.cardAmt;
        let UPIAmt = states.upiAmt === "" ? 0 : +states.upiAmt;
        let bankTransferAmt = states.bankTfrAmt === "" ? 0 : +states.bankTfrAmt;
        let ChequeAmt = states.chequeAmt === "" ? 0 : +states.chequeAmt;
        let totalAmt = CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt
        let DenominationAmt = this.returnTotal()

        if (states.payable_amt && (+states.payable_amt < totalAmt)) {
            msg = "Amount is greater than receivable amount"
        } else if (states.payable_amt && (+states.payable_amt > totalAmt)) {
            msg = "Amount does not match with receivable amount"
        } else if (states.payable_amt < +DenominationAmt) {
            msg = "Amount does not match with receivable amount"
        } else if (states.isCash && !states?.cashAmt) {
            msg = "Please Enter Cash Amount"
        } else if (states.isCard && !states?.cardAmt) {
            msg = "Please Enter Card Amount"
        } else if (states.isCard && !states?.cardNo) {
            msg = "Please Enter Card Reference No"
        } else if (states.isUPI && !states?.upiAmt) {
            msg = "Please Enter UPI Amount"
        } else if (states.isUPI && !states?.upiNo) {
            msg = "Please Enter UPI Reference No"
        } else if (states.isBankTranfer && !states?.bankTfrAmt) {
            msg = "Please Enter Bank Amount"
        } else if (states.isBankTranfer && !states?.bankTfrNo) {
            msg = "Please Enter Bank Reference No"
        } else if (states.isCheque && !states?.chequeAmt) {
            msg = "Please Enter Cheque Amount"
        } else if (states.isCheque && !states?.chequeNo) {
            msg = "Please Enter Cheque Reference No"
        } else {
            msg = null
        }

        if (msg) {
            this.errorMessage(msg)
        } else {
            this.handleReceive()
        }
    }

    handleClear = () => {
        this.setState({
            payable_amt: "",
            cashAmt: "",
            cardAmt: "",
            upiAmt: "",
            bankTfrAmt: "",
            chequeAmt: "",
            cardNo: "",
            upiNo: "",
            bankTfrNo: "",
            chequeNo: "",
            isCash: false,
            isCard: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            TwoThounsandCountsPayment: '',
            FiftyCounts: '',
            FiveHundredCounts: '',
            TwentyCounts: '',
            TwoHundredCounts: '',
            TenCounts: '',
            HundredCounts: '',
        })
    }

    MenuItem = (row) => {
        const { t } = this.props;
        let multi_option = [{ label: "Cancel", value: "Cancel" }]
        return (
            <div>
                <Tooltip title={"more"} placement='top' arrow>
                    <IconButton
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: row.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == row.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}
                    >
                        {multi_option.map((option) => (
                            <MenuItem key={option}
                                disabled={(row?.is_rf_cancelled || this.state.status === "Completed" || this.state.status === "Cancelled" || row?.receipt_for === "Cancelled" || row?.receipt_for === "Refund")}
                                onClick={() => {
                                    if (option?.label === "Cancel") {
                                        this.setState({ isCancel: true, SelectedPatientDetails: row })
                                    }
                                }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu>
                    : null}
            </div>
        )
    }


    cancelReceipt = (datas) => {
        let states = this.state;
        let { SelectedPatientDetails } = this.state
        let payment = []
        if (datas?.isCash) {
            payment.push(1)
        }
        if (datas?.isUPI) {
            payment.push(3)
        }
        if (datas?.isBankTranfer) {
            payment.push(5)
        }
        let data = {
            "receipt_id": SelectedPatientDetails?.id,
            "cancel_reason": datas?.receiptType === "Cancel" ? datas?.comments : '',
            "receipt_cancel_reason": datas?.receiptType !== "Cancel" ? datas?.comments : '',
            "receipt_for": datas?.receiptType === "Cancel" ? "Cancelled" : "Refund",
            "amount_received": SelectedPatientDetails?.amount_received ? SelectedPatientDetails?.amount_received : 0,
            "reservation_id": states.reservation_id ? states.reservation_id : null,
            "with_reservation": false,
            "payment_mode": payment,
            "invoice_date": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
            "amount_cash": datas?.CashAmount ? +datas?.CashAmount : 0,
            "upi_amount": datas?.UPIAmount ? +datas?.UPIAmount : 0,
            "bank_transfer_amount": datas?.BankTfrAmount ? +datas?.BankTfrAmount : 0,
            "bank_ref_no": datas?.BankTfrNumber ? datas?.BankTfrNumber : "",
            "upi_ref_no": datas?.UPINumber ? datas?.UPINumber : "",
        }
        try {
            RestAPIService.create(data, Serviceurls.FO_RESERVATION_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.closePopup()
                        this.successMessage(response.data?.message);
                        this.getReservePayment();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message);
        }
    }

    closePopup = () => {
        this.setState({
            isCancel: false,
            isSelected: false,
            SelectedPatientDetails: {},
            selectedRow: ''
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.white, height: '79dvh' }}>
                <Box component={'div'} height={'10dvh'}>
                    <Box component={'div'} className='eMed_nur_notes_details' ml={'0.8vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} />
                    </Box>
                </Box>
                <Box component={'div'} display={'flex'} height={'69dvh'} gap={'0.5dvw'} p={'0.5dvw'}>
                    <Box component={'div'} flex={0.72} sx={{ backgroundColor: Colors.white }}>
                        <Paper elevation={2} sx={{ height: '100%' }}>
                            <TableContainer sx={{ height: "100%", overflow: 'auto' }}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead sx={{ backgroundColor: Colors.Background, height: '5vh' }}>
                                        <TableRow sx={{ paddingY: "0.5vw" }}>
                                            <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">S No</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Receipt No</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Receipt Date & Time</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Receipt Type</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Payment Mode</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Collected By</TableCell>
                                            <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">{t('Total Amount') + `(${CurrencySymbol})`}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.paymentDetails?.length > 0 ? this.state.paymentDetails?.map((row, index) => (
                                            <TableRow
                                                key={row?.name}
                                                sx={{ height: "7vh" }}
                                            >
                                                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{index + 1}</TableCell>
                                                <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">
                                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{row?.receipt_number ?
                                                        row?.receipt_number?.length > 18 ?
                                                            <Tooltip placement='top' title={row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{'...' + row?.receipt_number?.slice(4)}</div></Tooltip> :
                                                            row?.receipt_number : '-'}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center"><Typography>{row?.invoice_date ? row?.invoice_date : '-'}</Typography></TableCell>
                                                <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center"><Typography>{row?.receipt_for ? row?.receipt_for : '-'}</Typography></TableCell>
                                                <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">
                                                    <Typography>{row?.payment_mode_types ?
                                                        row?.payment_mode_types?.length > 18 ?
                                                            <Tooltip placement='top' title={row?.payment_mode_types}><div>{row?.payment_mode_types?.slice(0, 15) + '...'}</div></Tooltip> :
                                                            row?.payment_mode_types : '-'}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ flex: 0.15 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{row?.collectd_by ? row.collectd_by : 0}</TableCell>
                                                <TableCell sx={{ flex: 0.15, fontWeight: 600 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">
                                                    <Box display={"flex"} justifyContent={'flex-end'} alignItems={'center'}>
                                                        {row?.amount_received ? AmountFormat(row?.amount_received)?.replace(`${CurrencySymbol}`, "") : 0}
                                                        {this.MenuItem(row)}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                            <TableCell sx={{ borderBottom: "none" }} align="right">
                                                <Typography paddingTop={"2vw"}>No Records Found</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                    <Box component={'div'} flex={0.35} sx={{ backgroundColor: Colors.white }}>
                        <Box item flex={0.28} padding={0}>
                            <Paper className="eMed_IP_recPayment_Div" sx={{ height: "67vh" }} elevation={2}>
                                <Grid xs={12} item className='eMed_recPayment_gridDiv' sx={{ height: "61dvh", overflow: 'auto' }}>
                                    <Tabs
                                        value={"one"}
                                        sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                                    >
                                        <Tab value="one" label={"Receive Advance"} className='eMed_tab_header' />
                                    </Tabs>
                                    {this.ReceivePaymentTab()}
                                </Grid>
                                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg, marginTop: "0.3vw" }} alignItems="center" justifyContent="center">
                                    <Button variant='outlined' size="small" id="eMed_recPayment_btn" disabled={(this.state.status === "Completed" || this.state.status === "Cancelled") ? true : false} onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button disabled={(this.state.disableBtn || this.state.isLoader || !this.state.payable_amt || this.state.status === "Completed" || this.state.status === "Cancelled") ? true : false} variant='contained' size="small" id="eMed_recPayment_btn" emed_tid="ReceiveAmt_Receive_Btn"
                                        onClick={() => {
                                            this.setState({ disableBtn: true }, () => { this.handleValidation() })
                                        }}>{t("Receive")}</Button>
                                </Stack>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
                {
                    this.state.isCancel ?
                        <ReasonPopupWithRefund
                            title={"Cancel Bill"}
                            AlertPopupClose={this.closePopup}
                            label={"Enter reason for cancel"}
                            btntext={'Close'}
                            btnvarient={'outlined'}
                            btncolor={'error'}
                            btntext1={'Confirm'}
                            btnvarient1={'contained'}
                            IPRefundPost={this.cancelReceipt}
                            fromReservation={true}
                            payableAmount={this.state.SelectedPatientDetails?.amount_received}
                            BillReceivedAmount={this.state.SelectedPatientDetails?.amount_received}
                        />
                        : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(ReserveReceivePayement)