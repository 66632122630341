import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, TextField, Tooltip, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { Link } from 'react-router-dom';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import SearchIcon from '@mui/icons-material/Search';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { clearCachevalue, getCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

const testStatusList = ["All", "Specimen not collected", "Test Inprogress", "Result Saved", "Result Completed", "Result Delivered"];

const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;

class ForTechnicians extends Component {

    constructor(props) {
        super(props)
        let filterData = getCachevalue('filteredData')
        let filter = JSON.parse(filterData)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: filter?.fromDate ? filter?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: filter?.toDate ? filter?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: filter?.dateIndex ? filter?.dateIndex : 1,
            forTechniciansData: [],
            departmentData: [],
            groupData: [],
            testNameData: [],
            patientSearch: filter?.patientSearch ? filter?.patientSearch : "",
            departmentName: filter?.departmentName ? filter?.departmentName : "",
            groupName: filter?.groupName ? filter?.groupName : "",
            testName: filter?.testName ? filter?.testName : "",
            TestStatus: filter?.status ? filter?.status : "All",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getDeptList()
        this.getGroupNameList()
        this.getTestNameList()
        if (getCachevalue('LabForTechDateFilter')) {
            let FilterData = JSON.parse(getCachevalue('LabForTechDateFilter'))
            this.setState({
                fromDate: FilterData?.fromDate,
                toDate: FilterData?.toDate,
                dateIndex: FilterData?.dateIndex,
            }, () => {
                this.getForTechniciansData()
            })
        } else {
            setCachevalue(JSON.stringify({
                "fromDate": this.state?.fromDate,
                "toDate": this.state?.toDate,
                "dateIndex": this.state?.dateIndex,
            }), 'LabForTechDateFilter')
            this.getForTechniciansData()
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    FilterDate = (from, to, index) => {
        setCachevalue(JSON.stringify({
            "fromDate": from,
            "toDate": to,
            "dateIndex": index
        }), 'LabForTechDateFilter')
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    // api calls 
    getForTechniciansData = () => {
        try {
            let states = this.state;
            let uhid = "", patient_names = "";

            (states.patientSearch && alphanumericRegex.test(states.patientSearch)) ? uhid = states.patientSearch : patient_names = states.patientSearch;
            let departmentName = this.state.departmentName?.dept_name ? this.state.departmentName?.dept_name : this.state.departmentName ? this.state.departmentName : ''
            let groupName = this.state.groupName?.name ? this.state.groupName?.name : this.state.groupName ? this.state.groupName : ''
            let testName = this.state.testName?.test_name ? this.state.testName?.test_name : this.state.testName ? this.state.testName : ''
            this.setState({ disableBtn: true, LoaderOpen: true })
            RestAPIService.getAll(`${Serviceurls.LAB_FOR_TECHNICIANS}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${patient_names}&patient_uhid=${uhid}&dept_name=${departmentName}&grp_name=${groupName}&test_name=${testName}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data?.length > 0 ? response?.data?.data?.map((item, index) => {
                            // let resultValue = '-';
                            // if (item?.is_outsource) {
                            //     resultValue = 'Outsource'
                            //     delete item.result;
                            // } else {
                            //     if (item?.result) {
                            //         if (Array.isArray(item.result.value) && item.method === "Culture Test Result") {
                            //             resultValue = 'Culture Test';
                            //             delete item.result;
                            //         } else if (item?.result?.value === null || item?.result?.value) {
                            //             resultValue = item?.result?.value;
                            //             delete item.result;
                            //         } else {
                            //             resultValue = item?.result;
                            //             delete item.result;
                            //         }
                            //     }
                            // }

                            return {
                                ...item,
                                // result_value: resultValue,
                                sno: index + 1,
                            };
                        }) : [];

                        this.setState({
                            forTechniciansData: Data,
                            disableBtn: false,
                            LoaderOpen: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false, LoaderOpen: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false, LoaderOpen: false })
        }
    }

    getDeptList = () => {
        try {
            RestAPIService.getAll(Serviceurls.GET_LAB_DEPARTMENT_MASTER + "?dept_name=" + '&is_active=')
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            departmentData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getGroupNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            groupData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTextBox = () => {
        return (<Box className="eMed_Lab_techTxt">
            <TextField
                label={"Patient name/UHID"}
                size="small"
                value={this.state.patientSearch}
                onChange={(e) => { this.setState({ patientSearch: e.target.value.trimStart() }) }}
                sx={{ width: '14vw' }} />
        </Box>)
    }

    renderAutoComplete = (label, list = [], statekey, showKey) => {
        let states = this.state;
        return (
            <Box className="eMed_Lab_techTxt">
                <Autocomplete
                    size='small'
                    disableClearable={statekey === 'TestStatus' ? true : false}
                    options={list}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options[showKey]}
                    value={states[statekey]}
                    onChange={(event, newValue) => {
                        if (statekey === 'TestStatus') {
                            states[statekey] = newValue
                            this.setState({ states })
                        } else {
                            states[statekey] = newValue
                            this.setState({ states })
                        }
                    }}
                    sx={{ width: '14vw' }}
                    renderInput={(params) => (<TextField {...params} label={label} autoComplete='off' />)}
                />
            </Box>
        )
    }

    renderDatePicker = () => {
        return (
            <Box className="eMed_Lab_techDate" sx={{ marginTop: "-1.2vw" }}>
                <CommonDatePicker
                    FromDate={this.state.fromDate}
                    ToDate={this.state.toDate}
                    DateIndex={this.state.dateIndex}
                    SelectedDates={this.FilterDate.bind(this)}
                    HideAllMenu={true}
                    HideDate={true}
                    height={true} />
            </Box>
        )
    }

    render() {
        let states = this.state;
        // filtering data 
        let filterData = states.forTechniciansData?.filter(item => {
            return (states.TestStatus !== 'All' ? item?.test_status === null ? null : (item?.test_status?.toLowerCase().includes(states.TestStatus?.toLowerCase())) : item
            )
        })

        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.05
            },
            {
                field: "patient_details", headerName: t("Patient Details"), flex: 0.14, renderCell: ({ row }) => (<CommonPatientDetails data={row} disablePhoto={true} />)
            },
            {
                field: "ip_op_no", headerName: t("IP / OP No | Bill No"), flex: 0.14, headerAlign: "center", align: "center",
                renderCell: ({ row }) => {
                    let ip_op = row?.ip_number ? row?.ip_number : row?.op_number ? row?.op_number : "-"
                    let ipOpData = ip_op !== "-" ? ip_op?.split("/") : [];
                    let bill_number = row?.bill_number && row?.bill_number?.split("/");
                    return (
                        <Stack direction={"row"}>
                            <Tooltip placement='top' title={ip_op !== "-" ? ip_op : null} arrow>
                                <div id='eMed_Com_Patient_UhidText'>{ipOpData?.length > 0 ? `...${ipOpData[ipOpData.length - 1]}  | ` : "- | "}</div>
                            </Tooltip>
                            <Tooltip placement='top' title={row?.bill_number} arrow>
                                <div id='eMed_Com_Patient_UhidText'>{bill_number?.length > 0 ? `...${bill_number[bill_number.length - 1]}` : "-"}</div>
                            </Tooltip>
                        </Stack>
                    )
                }
            },
            {
                field: "dept_name", headerName: t("Department Name"), flex: 0.15,
                renderCell: ({ row }) => (<Box component={'div'} id="eMed_Lab_techFont">{row?.dept_name?.length > 20 ?
                    <Tooltip placement='top' title={row?.dept_name} arrow>
                        <div id="eMed_Lab_techFont">{row?.dept_name.slice(0, 20) + "..."}</div></Tooltip>
                    : row?.dept_name ? row?.dept_name : "-"}</Box>)
            },
            {
                field: "group_or_test", headerName: t("Group / Test"), flex: 0.18,
                renderCell: ({ row }) => (<Stack direction={"column"} >
                    <Box component={'div'} id='eMed_Com_Patient_UhidText'>{row?.test_name?.length > 30 ?
                        <Tooltip placement='top' title={row?.test_name} arrow>
                            <div id='eMed_Com_Patient_UhidText'>{row?.test_name.slice(0, 30) + "..."}</div></Tooltip>
                        : row?.test_name ? row?.test_name : "-"}</Box>
                    <Box component={'div'} id='eMed_Com_Patient_UhidText' display={row?.set_name ? 'flex' : "none"} alignItems={'center'}>
                        <Typography fontSize={'0.6vw'} marginRight={"0.3vw"} fontWeight={600}>Group - </Typography>
                        {row?.set_name?.length > 30 ?
                        <Tooltip placement='top' title={row?.set_name} arrow>
                            <div id='eMed_Com_Patient_UhidText' style={{ fontSize:'0.7vw'}}>{row?.set_name.slice(0, 30) + "..."}</div></Tooltip>
                        : row?.set_name ? <div id='eMed_Com_Patient_UhidText' style={{ fontSize:'0.7vw'}}>{row?.set_name}</div> : "-"}</Box>
                </Stack>)
            },
            // {
            //     field: "result_value", headerName: `${t("Result")}`, flex: 0.135, headerAlign: "center", align: "center",
            //     renderCell: ({ row }) => {
            //         return (<Box component={'div'} id="eMed_Lab_techFont">{
            //             row?.result_value?.length > 18 ?
            //                 <Tooltip placement='top' title={row?.result_value} arrow>
            //                     <div id="eMed_Lab_techFont">{row?.result_value?.slice(0, 18) + "..."}</div></Tooltip>
            //                 :
            //                 <Tooltip placement='top' title={row?.result_value === "Culture Test" ? "Kindly check action" : null} arrow>
            //                     <div id="eMed_Lab_techFont">{row?.result_value ? row?.result_value : "-"}</div></Tooltip>
            //         }</Box>)
            //     }
            // },
            {
                field: "test_status", headerName: `${t("Status")}`, flex: 0.135, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'} id="eMed_Lab_techFont">{row?.test_status?.length > 18 ?
                    <Tooltip placement='top' title={row?.test_status} arrow>
                        <div id="eMed_Lab_techFont">{row?.test_status.slice(0, 18) + "..."}</div></Tooltip>
                    : row?.test_status ? row?.test_status : "-"}</Box>)
            },
            {
                field: "action", headerName: `${t("Action")}`, flex: 0.07, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Button emed_tid = "Common_Btn_Min_Width_TestID" className='Common_Btn_Min_Width'
                    disabled={!CheckAccessFunc("Laboratory", "Home", "For Technicians", null, "Tab")?.editAccess}
                >
                    {((row?.test_status !== "Specimen not collected" && row?.test_status !== "Result Completed") && row?.test_status !== "Cancelled") ?
                        <Link emed_tid = "Result_TestID" to={{ pathname: "/Results", state: { resultList: row, fromTechnician: true } }}>
                            <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} 
                            onClick={() => {
                                let filterData = {
                                    "status": this.state.TestStatus,
                                    "dateIndex": this.state.dateIndex,
                                    "fromDate": this.state.fromDate,
                                    "toDate": this.state.toDate,
                                    "patientSearch": this.state.patientSearch,
                                    "departmentName": this.state.departmentName?.dept_name,
                                    "groupName": this.state.groupName?.name,
                                    "testName": this.state.testName?.test_name,
                                }
                                setCachevalue(JSON.stringify(filterData),'filteredData')
                            }} />
                        </Link> :
                        <Box component={'div'}>
                            <Box  emed_tid = "img_TestID" component={'img'} src={ImagePaths.ResultOff.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                        </Box>
                    }
                </Button>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Lab_techFlt'>
                    {this.renderTextBox()}
                    {this.renderAutoComplete('Department Name', states.departmentData, "departmentName", "dept_name")}
                    {this.renderAutoComplete('Group Name', states.groupData, "groupName", "name")}
                    {this.renderAutoComplete('Test Name', states.testNameData, "testName", "test_name")}
                    {this.renderAutoComplete('Status', testStatusList, "TestStatus", "test_name")}
                    {this.renderDatePicker()}
                    <Stack direction={"row"} spacing={0.5}>
                        <Tooltip title={t('Search')} placement='top' arrow>
                            <IconButton
                                disabled={this.state.disableBtn}
                                emed_tid='Technicians_Search_Btn'
                                onClick={() => this.getForTechniciansData()}
                                sx={{ border: "2px solid gray", borderRadius: "0.3vw", width: "2vw", height: "2vw", marginBottom: "0.3vw" }}>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Clear')} placement='top' arrow>
                            <IconButton
                                disabled={this.state.disableBtn}
                                emed_tid='Technicians_clear_Btn'
                                sx={{ border: "2px solid gray", borderRadius: "0.3vw", width: "2vw", height: "2vw", marginBottom: "0.3vw" }}
                                onClick={() => {
                                    clearCachevalue('filteredData')
                                    this.setState({
                                        patientSearch: '',
                                        departmentName: null,
                                        groupName: null,
                                        testName: null,
                                        TestStatus: "All",
                                        fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                        dateIndex: 1
                                    }, () => {
                                        setCachevalue(JSON.stringify({
                                            "fromDate": this.state?.fromDate,
                                            "toDate": this.state?.toDate,
                                            "dateIndex": this.state?.dateIndex,
                                        }), 'LabForTechDateFilter')
                                        this.getForTechniciansData()
                                    })
                                }}>
                                <ClearSharpIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={filterData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.LoaderOpen}
                    />
                </Box>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(ForTechnicians)