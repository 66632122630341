import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
class LabTatReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            TatReportData: [],
            testNameData: [],
            registerUser: "",
            collectUser: "",    
            testDoneUser: "",
            testName: "",
            testType : "",
            userList: [],
            FilterOpen: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getTestNameList()
        this.getTatList()
        this.GetUserDetails()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    GetUserDetails = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            userList : response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    getTatList = () => {
        try {
            this.LoaderFunction(true);
            this.setState({ disableBtn: true })
            RestAPIService.getAll(`${Serviceurls.LAB_TAT_REPORT}?register_user_id=${this.state?.registerUser?.id ? this.state?.registerUser?.id : ""}&test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : "" }&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&collected_user_id=${this.state?.collectUser?.id ? this.state?.collectUser?.id : ""}&test_done_user_id=${this.state?.testDoneUser?.id ? this.state?.testDoneUser?.id : ""}&is_outsource=${this.state.testType}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data;    
                        this.LoaderFunction(false);
                        this.setState({
                            TatReportData: Data?.length > 0 ? Data : [],
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }


    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    printReports = () => {
        try {
            RestAPIService.getAllPrint(`${Serviceurls.LAB_TAT_REPORT}?register_user_id=${this.state?.registerUser?.id ? this.state?.registerUser?.id : ""}&test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : "" }&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&collected_user_id=${this.state?.collectUser?.id ? this.state?.collectUser?.id : ""}&test_done_user_id=${this.state?.testDoneUser?.id ? this.state?.testDoneUser?.id : ""}&is_outsource=${this.state.testType}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getExcel = () => {
        try {
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(`${Serviceurls.LAB_TAT_REPORT}?register_user_id=${this.state?.registerUser?.id ? this.state?.registerUser?.id : ""}&test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : ""}&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&collected_user_id=${this.state?.collectUser?.id ? this.state?.collectUser?.id : ""}&test_done_user_id=${this.state?.testDoneUser?.id ? this.state?.testDoneUser?.id : ""}&is_outsource=${this.state.testType}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"]?.split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'TatReport.xlsx');
                        pom.click();
                        // this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({ disableBtn: false })
        }
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                            getOptionLabel={(option) => option?.test_name}
                            value={this.state?.testName ? this.state?.testName : null}
                            onChange={(e, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.userList?.length > 0 ? this.state.userList : []}
                            getOptionLabel={(option) => option?.user_name}
                            value={this.state?.registerUser ? this.state?.registerUser : null }
                            onChange={(e, newValue) => {
                                this.setState({ registerUser: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Registered User' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.userList?.length > 0 ? this.state.userList : []}
                            getOptionLabel={(option) => option?.user_name}
                            value={this.state?.collectUser ? this.state?.collectUser : null}
                            onChange={(e, newValue) => {
                                this.setState({ collectUser: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Collected User' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.userList?.length > 0 ? this.state.userList : []}
                            getOptionLabel={(option) => option?.user_name}
                            value={this.state?.testDoneUser ? this.state?.testDoneUser : null}
                            onChange={(e, newValue) => {
                                this.setState({ testDoneUser: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Done User' />)}
                        />
                    </Box>
                    <Typography fontWeight={600} sx={{display:'flex', flexDirection:"row", alignItems:"flex-start" ,marginLeft :"1vw"}}>{"Test Type"}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingY: '0.5vw' }}>
                        <FormControl sx={{  display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <RadioGroup
                                value={this.state.testType}
                                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                                onChange={(e) => {
                                    this.setState({ testType: e.target.value })
                                }}
                                emed_tid='testTypeTestID'
                            >
                                <FormControlLabel value={""} control={<Radio size='small' />} label="All" />
                                <FormControlLabel value={"false"} control={<Radio size='small' />} label="Inhouse" />
                                <FormControlLabel value={"true"} control={<Radio size='small' />} label="Outsource" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                registerUser: "",
                                collectUser: "",
                                testDoneUser: "",
                                testName: "",
                                testType :"",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            },
                                () => this.getTatList())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn' disabled={!this.state.disableBtn}
                        onClick={() => {
                            this.getTatList()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state?.TatReportData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.14, type:'string',
                renderCell: (params) => (<CommonPatientDetails data={params?.row} disablePhoto={true}/>)
            },
            {
                field: "sid_details", headerName: `${t("SID Details")}`, flex: 0.12, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={"0.8vw"}>{(params?.row?.sid_number ? params?.row?.sid_number : "-")}</Typography>
                    <Typography fontSize={"0.8vw"}>{params?.row?.specimen_collected_date ? params?.row?.specimen_collected_date : '-'}</Typography>
                </Box>)
            },
            {
                field: "test_name", headerName: `${t("Test Name")}`, flex: 0.12, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 18 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><Typography fontSize={".9vw"}>{params?.row?.test_name.slice(0, 18) + "..."}</Typography></Tooltip>
                    : <Typography fontSize={".9vw"}>{params?.row?.test_name ? params?.row?.test_name : "-"}</Typography>}</Box>)
            },
            {
                field: "reg_date", headerName: `${t("Registered Date & Time")}`, flex: 0.12, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={".9vw"}>{(params?.row?.registered_date ? params?.row?.registered_date : "-" )}</Typography>
                </Box>)
            },
            {
                field: "registered_users", headerName: `${t("Register User")}`, flex: 0.10, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.registered_users?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.registered_users} arrow><Typography fontSize={".9vw"}>{params?.row?.registered_users.slice(0, 15) + "..."}</Typography></Tooltip>
                    : <Typography fontSize={".9vw"}>{params?.row?.registered_users ? params?.row?.registered_users : "-"}</Typography>}</Box>)
            },
            {
                field: "collected_users", headerName: `${t("Collected User")}`, flex: 0.10, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.collected_users?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.collected_users} arrow><Typography fontSize={'.9vw'}>{params?.row?.collected_users.slice(0, 15) + "..."}</Typography></Tooltip>
                    : <Typography fontSize={'.9vw'}>{params?.row?.collected_users ? params?.row?.collected_users : "-"}</Typography>}</Box>)
            },
            {
                field: "test_done_users", headerName: `${t("Test Done User")}`, flex: 0.10, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_done_users?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.test_done_users} arrow><Typography fontSize={'.9vw'}>{params?.row?.test_done_users.slice(0, 15) + "..."}</Typography></Tooltip>
                    : <Typography fontSize={'.9vw'}>{params?.row?.test_done_users ? params?.row?.test_done_users : "-"}</Typography>}</Box>)
            },
            {
                field: "complete_date", headerName: `${t("Completed Date & Time")}`, flex: 0.12, type: "string",
                renderCell: (params) => (<Box component={'div'}><Typography fontSize={'.9vw'}>{params?.row?.result_completed_date ? params?.row?.result_completed_date : '-'}</Typography></Box>)
            },
            {
                field: "actual_tat", headerName: `${t("Actual TAT")}`, flex: 0.08, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography fontSize={"0.9vw"}>{(params?.row?.actual_tat ? params?.row?.actual_tat : "-")}</Typography>
                </Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => this.printReports()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.getExcel() }}>
                            <img src={ImagePaths.ExportIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} sx={{height: '66vh', width: '92vw', margin: 'auto', backgroundColor: 'white'}}>
                    <DataGrid
                        rows={this.state?.TatReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        sx={{
                            width: "100%", // Occupy the full parent width
                            overflow: "auto",
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(LabTatReport)

