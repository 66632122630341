import { Box, Button, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, ToolsField } from '../../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../../Styles/Colors'
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'

let isUser = null;

export default class RadNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientData: {},
      attender_name: '',
      attender_mobile: '',
      radNotes: ''
    }
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    let patientData = JSON.parse(getCachevalue('RadPatientData'))
    this.setState({
      patientData: patientData
    }, () => { this.GetPatientNotes() })
  }

  GetPatientNotes = () => {
    try {
      this.setState({ tempPost: true })
      RestAPIService.getAll(Serviceurls.RAD_NOTES + '?patient_id=' + this.state.patientData?.patient_id + '&radiology_id=' + JSON.parse(localGetItem('loggedInUserInfo'))?.radiology_id)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radNotes: response.data.data[0]?.notes ?  response.data.data[0]?.notes : "",
              SelectedNotesID : response.data.data[0]?.id ?  response.data.data[0]?.id : null,
            })
          }
        }).catch((error) => {
          this.setState({ tempPost: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ tempPost: false })
      this.errorMessage(error.message)
    }
  }

  postRadNotesData = (resultData) => {
    try {
      let data = {
        patient_id: this.state.patientData?.patient_id ? this.state.patientData?.patient_id : null,
        appointment_id: this.state.patientData?.ip_admission_id ? null : this.state.patientData?.appointment_id ? this.state.patientData?.appointment_id : null,
        ip_admission_id: this.state.patientData?.ip_admission_id ? this.state.patientData?.ip_admission_id : null,
        notes: resultData ? resultData : null,
       }
       if(this.state.SelectedNotesID){
        data['id'] = this.state.SelectedNotesID ? this.state.SelectedNotesID : null
       }

      RestAPIService.create(data, Serviceurls.RAD_NOTES)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.GetPatientNotes()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearHandler = () => {
    try {
      this.setState({
        radNotes: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  render() {
    let data = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
      lineFour: "Contact Number",
      LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    }
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '70vh' }}>
          <Box component={'div'} height={"9.5vh"}>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'}>
                <CommonPatientDetails data={this.state.patientData} showDetailed={(this.state.patientData?.op_number || this.state.patientData?.ip_number) ? true : false} />
              </Box>
              {/* <CommonPatientCard details={data} showDetailed={true} /> */}
            </Box>
          </Box>
          <Box sx={{ paddingX: "0.5vw" }}>
            <ToolsField Title={"Notes"} EditorRef={this.state.radNotes} handlePost={this.postRadNotesData.bind(this)} height={"49vh"} overallHeight={"62vh"} EditorHeight={"48.5vh"} />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
