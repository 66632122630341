import React, { Component } from "react";
import './IpDemograhics.css';
import { Box, Grid, Stack } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonPatientDetails, CommonPatientCard } from "../../../../Components/Common Components/CommonComponents";
import { Colors } from "../../../../Styles/Colors";
import { getCachevalue } from "../../../../Utility/Services/CacheProviderService";
import { t } from "i18next";
import CreateAppointments from '../../OutPatients/QuickBillAppointments/CreateAppointment';

class ViewDemographics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientData: {},
      attenderData: {},
    }
  }
  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData");
    let patients = JSON.parse(patientDetails);
    this.setState({
      patientData: patients
    })
  }

  IpPatientUpdateSuccess = (data)=>{
    if(data){
      let patientDetails = getCachevalue("IppatientData");
      let patients = JSON.parse(patientDetails);
      this.setState({
        patientData: patients
      })
    }
  }
  render() {
    console.log("patientData",this.state.patientData)
    const { t } = this.props;
    let states = this.state;
    let data = {
      lineOne: t("Emergency Contact"),
      lineTwo: `${states["patientData"]["caretaker_name"] ? states["patientData"]["caretaker_name"] : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${states["patientData"]["caretaker_mobile"] ? states["patientData"]["caretaker_mobile"] : "-"}`
    }
    return (
      <Box id="eMed_AdHistory_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Grid container className="eMed_AdHistory_Box">
          <Stack className="eMed_AdHistory_header" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1.2}>
            <Stack className="eMed_recCard_div" sx={{ width: "23vw", backgroundColor: Colors.white }} >
              <CommonPatientDetails data={this.state.patientData} />
            </Stack>
            <CommonPatientCard details={data} showDetailed={true} />
          </Stack>
          <Box className="eMed_AdHistory_Div">
            <CreateAppointments
              fromViewGraphics={true}
              PatientDetailBack={states.patientData}
              IpPatientUpdateSuccess={this.IpPatientUpdateSuccess.bind(this)}
            />
          </Box>
        </Grid>
      </Box>
    )
  }
}
export default withTranslation()(ViewDemographics);
