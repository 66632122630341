import React, { Component } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Drawer, IconButton, Paper, Tooltip } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import SubLocReportFilter from "./SubLocReportFilter";
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../Utility/Constants';

class SubLocConsumptionReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DataList: [],
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                'brandName': ''
            },
            totalBill: 0,
            totalAmount: 0,
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            pageName: 'SubConsumptionReport',
            startTime: '',
            endTime: ''
        }
    }

    componentDidMount() {
        this.getSubLocConsumptionRpt();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => {
            this.getSubLocConsumptionRpt()
        })
    }

    getSubLocConsumptionRpt = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.SUBLOC_CONSUMPTION_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_search=${this.state.filterDatas.brandName}&start_time=${this.state.startTime}&end_time=${this.state.endTime}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let list = response.data?.data?.length > 0 ? response.data.data : [];
                        list.length > 0 && list?.forEach((item, i) => {
                            item["sno"] = i + 1;
                        })
                        this.setState({
                            DataList: list,
                            totalBill: response.data?.total_bill ? response.data.total_bill : 0,
                            totalAmount: response.data?.total_amount ? response.data.total_amount : 0
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getSubLocConsumptionRptPrint = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.SUBLOC_CONSUMPTION_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_search=${this.state.filterDatas.brandName}&start_time=${this.state.startTime}&end_time=${this.state.endTime}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response.data.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getSubLocConsumptionRptExcel = () => {
        try {
            RestAPIService.excelGet(`${Serviceurls.SUBLOC_CONSUMPTION_REPORT_GET}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&drug_search=${this.state.filterDatas.brandName}&start_time=${this.state.startTime}&end_time=${this.state.endTime}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: this.state.filterDatas.fromDate },
            { label: "To Date", value: this.state.filterDatas.toDate }
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    renderTable = () => {
        const { t } = this.props
        let states = this.state;
        const columns = [
            {
                field: 'consumption_date', headerName: t(`Date`), flex: 0.111, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<div>{row?.consumption_date ? formatDate(row?.consumption_date.slice(0, 10)) : "-"}</div>)
            },
            {
                field: 'drug_name', headerName: t(`BrandName`), flex: 0.111,
                renderCell: ({ row }) => (row?.drug_name?.length > 18 ?
                    <Tooltip placement="top" title={row?.drug_name} arrow><div>{row?.drug_name.slice(0, 16) + "..."}</div></Tooltip>
                    : <Box component={'div'}>{row?.drug_name ? row?.drug_name : "-"}</Box>)
            },
            {
                field: 'generic_name', headerName: t(`GenericName`), flex: 0.111,
                renderCell: ({ row }) => (row?.generic_name?.length > 18 ?
                    <Tooltip placement="top" title={row?.generic_name} arrow><div>{row?.generic_name.slice(0, 16) + "..."}</div></Tooltip>
                    : <Box component={'div'}>{row?.generic_name ? row?.generic_name : "-"}</Box>)
            },
            {
                field: 'dosage_type', headerName: t(`Dosage Type / Strength`), flex: 0.111, headerAlign: "center", align: "center",
                renderCell: ({ row }) => {
                    let dosage = `${row?.dosage_type ? row?.dosage_type : "-"} | ${row?.drug_strength ? row?.drug_strength : "-"}`
                    return (dosage?.length > 18 ?
                        <Tooltip placement="top" title={dosage} arrow><div>{dosage.slice(0, 16) + "..."}</div></Tooltip>
                        : <Box component={'div'}>{dosage ? dosage : "-"}</Box>)
                }
            },
            {
                field: 'batch_no', headerName: t(`BatchNo`), flex: 0.111, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<div>{row.batch_no ? row.batch_no : "-"}</div>)
            },
            {
                field: 'expiry_date', headerName: t(`ExpiryDate`), flex: 0.111, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<div>{row.expiry_date ? (row.expiry_date) : "-"}</div>)
            },
            {
                field: 'quantity', headerName: t(`Qty`), flex: 0.07, type: "number",
                renderCell: ({ row }) => (<div>{row.quantity ? row.quantity : 0}</div>)
            },
            {
                field: 'total_cost', headerName: t('TotalAmount')+` (${CurrencySymbol})`, flex: 0.111, type: "number",
                renderCell: ({ row }) => (<div>{AmountFormat(row?.total_cost || 0)?.replace(`${CurrencySymbol}`, "")}</div>)
            },
            {
                field: 'created_by', headerName: t(`UpdatedBy`), flex: 0.111,
                renderCell: ({ row }) => (row?.created_by?.length > 18 ?
                    <Tooltip placement="top" title={row?.created_by} arrow><div>{row?.created_by.slice(0, 16) + "..."}</div></Tooltip>
                    : <Box component={'div'}>{row?.created_by ? row?.created_by : "-"}</Box>)
            },
        ]
        return (
            <Box component={'div'}>
                <Paper sx={{ height: '63vh' }}>
                    <DataGrid
                        rows={states.DataList}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={states.page}
                        pageSize={states.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => this.setState({ page: newPage })}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Paper>
            </Box>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard(t("TotalBill"), this.state.totalBill, true)}
                        {AmountsCard(t("TotalAmount"), this.state.totalAmount)}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <IconButton emed_tid={"subLoc_consumprpt_flt"} className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </IconButton>
                        <IconButton emed_tid={"subLoc_consumprpt_print"} className='eMed_Pharma_rts_btn' onClick={() => { this.getSubLocConsumptionRptPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </IconButton>
                        <IconButton emed_tid={"subLoc_consumprpt_export"} className='eMed_Pharma_rts_btn' onClick={() => { this.getSubLocConsumptionRptExcel() }}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </IconButton>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    {this.renderTable()}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <SubLocReportFilter
                        pageName={this.state.pageName}
                        filterDatas={this.state.filterDatas}
                        closeFilter={this.ClosePopUp.bind(this)}
                        filteredData={this.renderFilter.bind(this)}
                        ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(SubLocConsumptionReport);