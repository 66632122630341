import React, { Component } from 'react';
import { Typography, Button, TextField, OutlinedInput, InputAdornment, Tooltip, MenuItem } from '@mui/material';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import './../../../Laboratory/Home/home.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CircularProgress from '@mui/material/CircularProgress';
class Prescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchkey: "",
            prescriptionData: [],
            filterDate: new Date(),
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
        }
    }
    componentDidMount() {
        this.getPrescription()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getPrescription() {
        try {
            let date = DateTime.fromJSDate(this.state.filterDate).toFormat('yyyy-MM-dd')
            if(date !== "Invalid DateTime"){
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PRESCRIPTION_HOME + "?search_key=" + this.state.searchkey + "&prescription_date=" + date)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            prescriptionData: response.data.data
                        }, ()=>{this.LoaderFunction(false)});
                    }
                })
                .catch(e => {
                    if (e?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.response.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMsgFunc(e.message)
                    }
                });}
        }
        catch (e) {
            this.errorMsgFunc(e.message)
        }
    }
    errorMsgFunc = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMsgFunc = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    GotoBill = (item) => {
        try {
            let APIURL = ""
            if (item?.is_opt_pharma) {
                APIURL = Serviceurls.OPT_PRESCRIPTION_PRINT + `?prescription_id=${item?.prescription_id}&appointment_id=${item?.appointment_id}&doctor_id=${item?.doctor_id}&export_type=pdf`
            } else {
                APIURL = Serviceurls.PHARMA_PRESCRIPTION_GET + item?.id + `/?export_type=pdf`
            }
            RestAPIService.getAllTwo(APIURL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.props.properties.history.push({ pathname: "/PharmacyBilling", state: { isFromPrescription: true, billDetails: item, showHistory: true } })
                    this.props.onClickPrescriptionClose(false)
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMsgFunc(error.response?.data?.message)
                    } else {
                        this.errorMsgFunc(error.message)
                    }
                })
        } catch (e) {
            this.errorMsgFunc(e?.message)
        }
    }
    msgClose() {
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    render() {
        const { t } = this.props
        const { history } = this.props.properties
        return (
            <div>
                <div style={{ display: "flex", margin: "0.5vw" }}>
                    <label style={{ flex: "0.5" }}><b>{t("Prescription")}</b></label>
                    <div style={{ flex: "0.5", display: "flex", justifyContent: "end" }}
                        onClick={() => { this.props.onClickPrescriptionClose(false) }}>
                        <img
                            src={ImagePaths.LabCloseButton.default}
                            style={{ cursor: "pointer", height: "1vw", width: "1vw" }}
                            alt=""
                        />
                    </div>
                </div>

                <div className='eMed_Pres_Header'>
                    <OutlinedInput
                        placeholder="Search Prescription..."
                        onChange={(e) => {
                            this.setState({
                                searchkey: e.target.value
                            }, () => {
                                this.getPrescription()
                            })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "13vw", marginRight: "0.5vw" }}
                        endAdornment={
                            <InputAdornment position="end">
                                <i class="fas fa-search"></i>
                            </InputAdornment>
                        }
                        inputProps={{ emed_tid:"Presc_search_text" }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            emed_tid="Presc_toBill_date"
                            className="Lab_SavedBill_date"
                            id="Lab_SavedBill_date"
                            label={("")}
                            maxDate={new Date()}
                            value={this.state.filterDate}
                            inputFormat={"dd-MM-yyyy"}
                            autoComplete="off"
                            onChange={(newValue) => {
                                this.setState({
                                    filterDate: newValue > new Date() ? new Date() : newValue
                                }, () => this.getPrescription())
                            }}
                            renderInput={(params) => <TextField size="small" sx={{ width: "10vw" }} {...params}
                                error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                        />
                    </LocalizationProvider>
                </div>
                {this.state.prescriptionData && this.state.prescriptionData.length > 0 ?
                    <div className='eMed_Pres_DataView'>
                        {this.state.prescriptionData.map((item, index) => {
                            return (
                                <MenuItem emed_tid="Presc_toBill_item" onClick={()=>{this.GotoBill(item)}} className="Lab_Home_Saved_Bill">
                                    <div style={{ flex: 0.10 }}>{index + 1}</div>
                                    <div style={{ flex: 0.15 }}><img src={ImagePaths.Male.default} alt='Avatar' /></div>
                                    <div style={{ flex: 0.45 }}>
                                        {item?.patient_name.length > 12 ? 
                                        <Tooltip title={item?.patient_name} placement='top'>
                                            <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name.slice(0,12) + '...' : '-'}</Typography>
                                        </Tooltip> : <Typography className='savedBillText'>{item?.patient_name ? item?.patient_name : '-'}</Typography>}
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                                    </div>
                                    <div style={{ flex: 0.30 }}>
                                    {item?.doctor_name.length > 12 ? 
                                        <Tooltip title={item?.doctor_name} placement='top'>
                                            <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name.slice(0,12) + '...' : '-'}</Typography>
                                        </Tooltip> : <Typography className='savedBillText'>{item?.doctor_name ? item?.doctor_name : '-'}</Typography>}
                                        <Typography sx={{ fontSize: "0.8vw", color: '#C9CACC' }}>{item?.patient_account_number ? item?.patient_account_number : '-'}</Typography>
                                    </div>
                                    {/* <div style={{ flex: 0.10 }}>
                                        <Button size='small' variant='contained'>Print</Button>
                                    </div> */}
                                </MenuItem>
                            )
                        })}


                    </div> :
                   this.state.isLoader ? <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}} className='eMed_App_DataView'><CircularProgress/></div>:<div><Typography id='eMed_Pres_pop_NORec'>{t("No records found")}</Typography></div>}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </div>
        );
    }
}
export default withTranslation()(Prescription)