import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let concessionList = ["Active", "Inactive"]


let RoleData = null;
let userAccess = null;
class AntibioticMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antibioticMasterData: [],
      antibioticGroupList: [],

      // filter panel data
      searchAntibiotic: "",
      antibiotic_Group: null,
      staus_type: "",

      // pagination
      page: 0,
      pageSize: 10,
      openPopUp: false,
      isFromEdit: false,

      // form data
      antibioticCode: null,
      antibioticGroup: "",
      antibioticContent: "",
      order: "",
      antibioticName: "",
      deptStatus: true,
      comments: "",
      selectedId: null,
    }

    this.postAntibioticMaster = this.postAntibioticMaster.bind(this);
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  // api call mount
  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", "Antibiotic Master", "SubTab")
    this.AntibioticList()
  }

  // loading
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  // api call function
  AntibioticList() {
    try {
      this.setState({ postLoad: true })
      this.LoaderFunction(true);
      let status = this.state.staus_type === "Active" ? true : this.state.staus_type === "Inactive" ? false : ""
      RestAPIService.getAll(`${Serviceurls.LAB_ANTIBIOTIC_MASTER}?antibiotic_name=${this.state.searchAntibiotic}&antibiotic_group_id=${this.state.antibiotic_Group?.id ? this.state.antibiotic_Group?.id : ""}&is_active=${status}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.setState({
              antibioticMasterData: response.data.data,
              antibioticGroupList: response.data.antibiotic_group_data,
            })
          }
          else {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.LoaderFunction(false);
          this.setState({ postLoad: false })
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  handleValidation = () => {
    if (!this.state.antibioticName) {
      this.errorMessage("Enter Antibiotic Name")
      this.setState({ postLoad: false })
    } else if (this.state.deptStatus === false && !this.state.comments) {
      this.errorMessage("Enter Inactive comments")
      this.setState({ postLoad: false })
    } else if (this.state.isFromEdit && !this.state.order) {
      this.errorMessage("Enter Print Order")
      this.setState({ postLoad: false })
    } else if (!this.state.antibioticGroup) {
      this.errorMessage("Enter Antibiotic Group")
      this.setState({ postLoad: false })
    }
    else {
      this.postAntibioticMaster()
    }
  }

  postAntibioticMaster = () => {
    try {
      this.LoaderFunction(true);
      let data = {
        "antibiotic_id": this.state.selectedId ? this.state.selectedId : null,
        "antibiotic_code": this.state.antibioticCode ? this.state.antibioticCode : null,
        "antibiotic_group_id": this.state.antibioticGroup?.id ? this.state.antibioticGroup?.id : null,
        "antibiotic_group_name": this.state.antibioticGroup?.antibiotic_group_name ? this.state.antibioticGroup?.antibiotic_group_name : (this.state.antibioticGroup ? this.state.antibioticGroup  : null),
        "antibiotic_content": this.state.antibioticContent ? CommonValidation.removeSpace(this.state.antibioticContent) : null,
        "antibiotic_order": this.state.order ? this.state.order : null,
        "antibiotic_name": this.state.antibioticName ? CommonValidation.removeSpace(this.state.antibioticName) : '',
        "is_active": this.state.deptStatus ? this.state.deptStatus : false,
        "reason": this.state.comments ? CommonValidation.removeSpace(this.state.comments) : '',
      }

      RestAPIService.create(data, `${Serviceurls.LAB_ANTIBIOTIC_MASTER}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.successMessage(response.data.message)
            this.setState({ 
              openPopUp: false,
              disableBtn: false,
              isFromEdit: false,
              openPopUp: false,
              antibioticCode: null,
              selectedId: null })
            this.handleClear()
            this.AntibioticList()
          }
          else {
            this.setState({ postLoad: false})
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ postLoad: false })
          this.LoaderFunction(false);
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  // close filter
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  // clear popup function
  handleClear = () => {
    this.setState({
      antibioticGroup: '',
      antibioticName: '',
      antibioticContent: '',
      order: '',
      deptStatus: true,
      comments: ""
    })
  }

  // filter screen
  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid="vnd_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("Search Antibiotic")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              value={states.searchAntibiotic ? states.searchAntibiotic : ""}
              inputProps={{ emed_tid: "antibioticName" ,maxLength: 100 }}
              onChange={(e) => {
                this.setState({
                  searchAntibiotic: e.target.value.trimStart()
                })
              }}
            />
          </Box>

          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              sx={{ width: "20vw" }}
              size="small"
              inputProps={{ maxLength: 100 }}
              autoComplete="off"
              label={t("Select Antibiotic Group")}
              disableClearable
              options={this.state.antibioticGroupList}
              getOptionLabel={(option) => option?.antibiotic_group_name}
              value={this.state.antibiotic_Group ? this.state.antibiotic_Group : null}
              onChange={(event, newValue) => {
                this.setState({ antibiotic_Group: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps }}
                  label={t("Select Antibiotic Group")}
                  autoComplete='off'
                />
              )}
            />
          </Box>

          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              disableClearable
              options={concessionList}
              getOptionLabel={(option) => (option)}
              value={this.state.staus_type}
              onChange={(event, newValue) => {
                this.setState({ staus_type: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                  label={t('Select Status')}
                  autoComplete='off'
                />
              )}
            /></Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid="fil_clear"
            onClick={() => {
              this.setState({
                searchAntibiotic: "",
                antibiotic_Group : null,
                staus_type : ""
              }, () => {               
                this.AntibioticList()
              })
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid="fil_search"
            onClick={() => {
              this.AntibioticList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  // edit call function
  handleEdit = (data) => {
    let GrpName = this.state.antibioticGroupList?.find((item) => item.id === data.antibiotic_group_id ) || null;    
    this.setState({
      isFromEdit: true,
      openPopUp: true,
      selectedId: data.id,
      antibioticCode: data.antibiotic_code,
      antibioticGroup: GrpName,
      antibioticName: data.antibiotic_name,
      antibioticContent: data.antibiotic_content,
      order: data.antibiotic_order,
      deptStatus: data.is_active,
      comments: data.reason || ""
    })
  }

  renderautoAntibiotic = (label) => {
    const { t } = this.props
    return (
        <div>
            <Autocomplete
                sx={{ width: "12vw", marginX: "0.65vw", marginTop: "1vw" }}
                disabled={false}
                options={this.state.antibioticGroupList}
                openOnFocus
                getOptionLabel= {(options) => typeof(options) === 'string' ? options : options.antibiotic_group_name || ""  }
                onChange={(e, value) => {
                    this.setState({
                        antibioticGroup: value,
                    })
                }}
                onInputChange={(event, newvalue) => {
                  const currentGroupName = this.state.antibioticGroup?.antibiotic_group_name || "";
                  if (newvalue !== currentGroupName) {
                    this.setState({ antibioticGroup: newvalue });
                  }
                }}
                value={this.state.antibioticGroup ? this.state.antibioticGroup : null}
                size="small"
                id="combo-box-demo"
                renderInput={(params) => <TextField {...params} label={label} />}
            />
        </div>
    )
  }

  renderTextField = (value, stateKey, label, required, width = "12vw", disable = false) => {
    const { t } = this.props;
  
    return (
      <TextField
        sx={{ width, marginX: "0.65vw", marginTop: "1vw" }}
        size="small"
        disabled={disable}
        inputProps={{
          maxLength: stateKey === "order" ? 3 : 100,
          autoComplete: "off"
        }}
        label={t(label)}
        required={required}
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value;
          const isValid =
            stateKey === "order"
              ? CommonValidation.numberOnly(inputValue) || inputValue === ""
              : true;
  
          if (isValid) {
            this.setState((prevState) => ({
              ...prevState,
              [stateKey]: inputValue.trimStart(),
            }));
          }
        }}
      />
    );
  };
  

  // popup
  addNewAntibioticPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.openPopUp}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "43vw", height: !this.state.isFromEdit ? "63vh" :"75vh", maxHeight: "100%", position: "relative" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              {/* title */}
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit Antibiotic" : "Add Antibiotic"}</Typography>
              {/* close */}
              <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ openPopUp: false, isFromEdit: false, antibioticCode: null, selectedId: null }, () => { this.handleClear() })} >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <hr />
            {/* form */}
            <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1vh" }}>
                {/* form fields */}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.isFromEdit ?
                    this.renderTextField(this.state.antibioticCode, "antibioticCode", "Antibiotic Code", false, "12vw", true)
                    : null}
                  {this.renderautoAntibiotic("Select Antibiotic Group*")}
                  {this.renderTextField(this.state.antibioticName, "antibioticName", "Antibiotic Name", true, "12vw", false)}
                  {this.renderTextField(this.state.antibioticContent, "antibioticContent", "Antibiotic Content", false, "12vw", false)}
                  {this.state.isFromEdit ?
                    this.renderTextField(this.state.order, "order", "Print Order", true, "12vw", false)
                    : null}
                </div>

                {/* status */}
                <Box component={'div'} style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ marginLeft: "1vh", marginTop: "2vh" }}>
                    <Typography fontWeight={600}>{"Status"}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <Checkbox
                      disabled ={!this.state.isFromEdit}
                      checked={this.state.deptStatus}
                      onChange={(e) => { this.setState({ deptStatus: e.target.checked,comments :"" }) }}
                    />
                    <Typography>{"Active"}</Typography>
                  </Box>
                </Box>

                {this.state.deptStatus === false ?
                  <Box>
                    <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                      <Typography fontWeight={600}>{"Inactive Comments"}</Typography>
                    </Box>
                    <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                      <div>
                        <TextField
                          size='small'
                          label={("Reason *")}
                          style={{ marginTop: "0.5vw", width: "38.6vw" }}
                          disabled ={!this.state.isFromEdit}
                          multiline={true}
                          rows={3}
                          variant="outlined"
                          value={this.state.comments}
                          onChange={(event) => {
                            let value = event.target.value
                            if (value?.length <= 250) {
                              this.setState({ comments: value })
                            } else {
                              this.errorMessage('Allowed only 250 characters')
                            }
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                  : null
                }
              </Box>
            </Box>
            {/* footer */}
            <Box sx={{ position: "absolute", bottom: "2vh", right: "1vh", backgroundColor: "white" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => { this.handleClear() }}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  disabled={this.state.postLoad}
                  variant='contained'
                  onClick={() => { this.setState({ postLoad : true}, ()=> {this.handleValidation()}) }}
                >{states.isFromEdit ? "Update" : "Add"}</Button>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  // table tool bar
  gridToolBar = () => {
    var ExportData = []
    this.state.antibioticMasterData?.length > 0 && this.state.antibioticMasterData?.map((item) => {

        return (
            ExportData?.push({
                "SI.No": item?.sno, "Antibiotic Code": item?.antibiotic_code, "Antibiotic Group": item?.antibiotic_group_name, "Antibiotic Name": item?.antibiotic_name,"Antibiotic Content": item?.antibiotic_content, "Print Order": item?.antibiotic_order,  "Status": item?.is_active === true ? "Active" : item?.is_active === false ? "InActive" : "",
            })
        )
    }
    )
    return (
        <Box>
            <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Antibiotic Master"} filename={"Antibiotic Master"} />
        </Box>
    )
} 

  // table
  renderTable = () => {
    this.state.antibioticMasterData?.forEach((element, index) => element.sno = index + 1)

    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "antibiotic_code", flex: 0.166, headerName: t("Antibiotic Code"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.antibiotic_code ? params?.row?.antibiotic_code : "-"}</Box>)
      },
      {
        field: "antibiotic_group_name", flex: 0.166, headerName: t("Antibiotic Group"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.antibiotic_group_name?.length > 23 ?
          <Tooltip placement="top" title={row?.antibiotic_group_name} arrow><div>{row?.antibiotic_group_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.antibiotic_group_name ? row?.antibiotic_group_name : "-"}</div>)
      },
      {
        field: "antibiotic_name", flex: 0.166, headerName: t("Antibiotic Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.antibiotic_name?.length > 23 ?
          <Tooltip placement="top" title={row?.antibiotic_name} arrow><div>{row?.antibiotic_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.antibiotic_name ? row?.antibiotic_name : "-"}</div>)
      },
      {
        field: "antibiotic_content", flex: 0.166, headerName: t("Antibiotic Content"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.antibiotic_content?.length > 23 ?
          <Tooltip placement="top" title={row?.antibiotic_content} arrow><div>{row?.antibiotic_content.slice(0, 22) + "..."}</div></Tooltip>
          : row?.antibiotic_content ? row?.antibiotic_content : "-"}</div>)
      },
      {
        field: "antibiotic_order", flex: 0.166, headerName: t("Print Order"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.antibiotic_order?.length > 18 ?
          <Tooltip placement="top" title={row?.antibiotic_order} arrow><div>{row?.antibiotic_order.slice(0, 17) + "..."}</div></Tooltip>
          : row?.antibiotic_order ? row?.antibiotic_order : "-"}</div>)
      },
      {
        field: "is_active", flex: 0.166, headerName: t("Status"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (
          <Tooltip placement="top" title={ row?.reason} arrow><div>{row?.is_active ? "Active" : "Inactive"}</div></Tooltip>  
          )
      },
      {
        field: "action", flex: 0.134, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" arrow>
            <Button emed_tid="vnd_edit" className='eMed_usrconf_btn' onClick={() => { this.handleEdit(params.row) }}>
              <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' style={{ backgroundColor: Colors.Background }}>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.antibioticMasterData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              loading={this.state.isLoader}
              rowCount={this.state.antibioticMasterData?.length}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top' style={{ backgroundColor: Colors.Background }}>
          {userAccess?.editAccess ? <><Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.setState({ openPopUp: true }) }}>{`Add New Antibiotic`}</Button>
            <Divider orientation='vertical' /></> : null}
          <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid="vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip>

        </div>
        {this.renderTable()}
        {this.addNewAntibioticPopup()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}

      </div>
    )
  }
}

export default withTranslation()(AntibioticMaster);