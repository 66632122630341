import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate,  } from '../../../../Components/CommonFunctions/CommonFunctions';

class CounsellorSurgeryReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            CounsellorName: [],
            dateIndex:1,
            PatientSurgerydatas: [],
            TotalCoveredPatient: 0,
            TotalConsoledPatient: 0,
            TotalNonCoveredPatient: 0,
            AllPatient: 0,
            appointmentTypeList: [],
            CounsellorList: [],
            specializationList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            PatientsCount: null,
            PatientsAmount: null,
            showBox: false,
            showNPcard: false,
            patientType: [],
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
            SearchedPatientName : "",
            SearchedPatientUHID : "",
            SelectedStatus : ""
        }
    }

    componentDidMount() {
        this.getCounsollerSurgeryData()
        this.GetCounsollerData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getCounsollerSurgeryData = () => {
        try {
            this.LoaderFunction(true)
            let CounsellorID = []; 
            this.state.CounsellorName.forEach(element => CounsellorID.push(element.counsellor_id))
            RestAPIService.getAll(Serviceurls.FO_REPORTS_COUNSELLOR_SURGERY + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&counsellor_id=${CounsellorID}&patient_name=${this.state.SearchedPatientName}&patient_account_number=${this.state.SearchedPatientUHID}&status=${this.state.SelectedStatus}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            PatientSurgerydatas: response.data.data,
                            TotalConsoledPatient: response.data.consoled_patient,
                            TotalCoveredPatient: response.data.surgery_converted_patient,
                            TotalNonCoveredPatient: response.data.non_consoled_patient,
                            AllPatient: response.data.total_patient,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    GetCounsollerData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_COUNSELLOR_GET)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            CounsellorList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Patient Name"}
                            sx={{width:"20vw"}}
                            size='small'
                            value={this.state.SearchedPatientName}
                            onChange={(e) => {
                                this.setState({
                                    SearchedPatientName: e.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Patient UHID"}
                            sx={{width:"20vw"}}
                            size='small'
                            value={this.state.SearchedPatientUHID}
                            onChange={(e) => {
                                this.setState({
                                    SearchedPatientUHID: e.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.CounsellorList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.counsellor_name)}
                            value={this.state.CounsellorName}
                            onChange={(event, newValue) => {
                                this.setState({ CounsellorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Counsellor')}
                                />
                            )}
                        />
                    </Box>
            
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                CounsellorName: [], 
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                SearchedPatientName : "",
                                SearchedPatientUHID : "",
                            }, () => this.getCounsollerSurgeryData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getCounsollerSurgeryData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        // let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        // let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        // let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        // let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true}/>
          </Box>
    
        )
      }

    render() {
        this.state.PatientSurgerydatas.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.05, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : '-'}</Box>)
            },
            {
                field: "transmitted_date", headerName: t("Date"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.transmitted_date ? params?.row?.transmitted_date : '-'}</Box>)
            },
            {
                field: "patient_name", headerName: t("PatientName"), flex: 0.27,
                renderCell: (params) => ( <CommonPatientDetails data={params?.row}/> )
            },
            {
                field: "op_number", headerName: t("Appointment Details"), flex: 0.2, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.op_number ? params?.row?.op_number : '-'}</Box>)
            },
            {
                field: "counsellor_name", headerName: t("Counsellor Name"), flex: 0.23,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.counsellor_name ? params?.row?.counsellor_name?.length > 30 ?
                    <div><Tooltip placement='top' title={params?.row?.counsellor_name} arrow><div>{params?.row?.counsellor_name.slice(0, 30) + "..."}</div></Tooltip></div> : params?.row?.counsellor_name : "-"}</Box>)
            },
            {
                field: "surgery_converted", headerName: t("Surgery Converted"), flex: 0.1,headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.surgery_converted ? "Yes" : 'No'}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ SelectedStatus: "" }, () => { this.getCounsollerSurgeryData() }) }}>
                            {AmountsCard(t("All"), this.state.AllPatient, true, this.state.SelectedStatus === "" ? "#b4ece4" : "white")}
                        </Button>
                        <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ SelectedStatus: "consoled" }, () => { this.getCounsollerSurgeryData() }) }}>
                            {AmountsCard(t("Consoled Patient"), this.state.TotalConsoledPatient, true, this.state.SelectedStatus === "consoled" ? "#b4ece4" : "white")}
                        </Button>
                        <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ SelectedStatus: "converted" }, () => { this.getCounsollerSurgeryData() }) }}>
                            {AmountsCard(t("Converted Patient"), this.state.TotalCoveredPatient, true, this.state.SelectedStatus === "converted" ? "#b4ece4" : "white")}
                        </Button>
                        <Button className='eMed_Recpt_header_amt_card' onClick={() => { this.setState({ SelectedStatus: "non consoled" }, () => { this.getCounsollerSurgeryData() }) }}>
                            {AmountsCard(t("Not Consoled Patient"), this.state.TotalNonCoveredPatient, true, this.state.SelectedStatus === "non consoled" ? "#b4ece4" : "white")}
                        </Button>
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.PatientSurgerydatas}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar:this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={60}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(CounsellorSurgeryReport)