import { Box, IconButton, Modal, Paper, Stack, Typography } from '@mui/material'
import React, { Component } from 'react'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';


export default class EditHistoryPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bill_id: this.props?.BillId ? this.props?.BillId : null,
            bill_type: this.props?.BillType ? this.props?.BillType : null,
            HistoryList: []
        }
    }
    componentDidMount() {
        this.GetBillHistoryData()
    }
    returnBillType(Type) {
        if (Type === "Out Patient" || Type === "OP") { return "op" }
        else if (Type === "In Patient" || Type === "IP") { return "ip" }
        else if (Type === "Laboratory" || Type === "LAB") { return "lab" }
        else { return null }
    }
    GetBillHistoryData = () => {
        try {
            
            let ClinicId = ""
            if(this.props?.sendClinicId){
                ClinicId = "&clinic_id=" + (localGetItem("clinicId") ? +localGetItem("clinicId") : null)
            }
            RestAPIService.getAll(Serviceurls.GET_BILL_MODIFY_HISTORY + "?bill_id=" + (this.state?.bill_id) + "&bill_type=" + this.returnBillType(this.state.bill_type) + ClinicId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            HistoryList: response.data.data
                        })
                    }
                    else {
                        this.props.HistoryPopClose()
                    }
                }).catch((error) => {
                    this.props.HistoryPopClose()
                })
        }
        catch (e) {
            this.props.HistoryPopClose()
        }
    }
    render() {
        this.state.HistoryList.forEach((element, index) => { element.sno = index + 1 })
        const columns = [
            {
                field: "sno", flex: 0.1, headerName: "S No", headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "modified_date", flex: 0.25, headerName: "Date And Time", headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
            },
            {
                field: "modified_by_to", flex: 0.25, headerName: "Modified By", headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by_to ? params?.row?.modified_by_to : "-"}</Box>)
            },
            {
                field: "net_amount_from", flex: 0.2, headerName: `Previous Total Amount (${CurrencySymbol})`, type:"number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.net_amount_from || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "net_amount_to", flex: 0.2, headerName: `Current Total Amount (${CurrencySymbol})`, type:"number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.net_amount_to || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Modal open={true}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "68vw", height: "70vh" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', height: '6vh' }}>
                        <Typography fontWeight={600} marginLeft={'1vw'}>Bill Modify History</Typography>
                        <IconButton onClick={() => { this.props.HistoryPopClose() }}>
                            <img style={{ height: '1vw', width: '1vw', marginRight: '1vw' }} src={ImagePaths.LabCloseButton.default} alt='Close' />
                        </IconButton>
                    </Box>
                    <Paper sx={{ width: "67vw", height: '60vh' }}>
                        <DataGrid
                            rows={this.state.HistoryList}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            hideFooterSelectedRowCount
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {"No Records Found"}
                                    </Stack>
                                )
                            }}
                            disableSelectionOnClick
                            hideFooter
                        />
                    </Paper>
                </Paper>
            </Modal>
        )
    }
}
