import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { CommonGridToolBarWithFilterText } from '../../Components/Common Components/CommonComponents';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import ApprovalFilters from './ApprovalFilters';
import { DateTime } from 'luxon';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import { AmountFormat, formatDate, formatDateAndTimeMeth2 } from '../../Components/CommonFunctions/CommonFunctions';
import ExpensePopUp from '../FrontOffice/BillingTransaction/ExpensePopUp';

class ExpenseApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 30,
            expenseApprovalData: [],
            FilterDatas: {
                "FromDate": null,
                "ToDate": null,
                "DateIndex": 0,
                "userName": [],
                "expenseFor": "",
                "expenseCategory": "",
                "Status": { value: "Pending", label: "Pending" },
            },
            isApproveExpense: false,
            isViewExpense: false,
            viewDetails: {},
            expCategoryList: [],
            userNameList: [],
        }
    }
    componentDidMount() {
        this.getExpenseData();
        this._getExpenseCategoryDetails();
        this.getUserDetails();
        if (this.props.history?.location?.state?.message) {
            this.successMessage(this.props.history?.location?.state?.message)
            this.props.history.push({ state: {} })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getExpenseData() {
        try {
            this.LoaderFunction(true)
            const LoggedData = JSON.parse(localGetItem("loggedInUserInfo"));
            const user_id = JSON.parse(localGetItem("login_user_id"));
            const User_ID = (this.state.FilterDatas.userName?.length === 0 && LoggedData?.is_user && user_id) ?
                user_id : this.state.FilterDatas.userName?.map(element => element.id);
            RestAPIService.getAll(`${Serviceurls.EXPENSE_REQUEST_POST}?from_date=${this.state.FilterDatas?.FromDate || ""}&to_date=${this.state.FilterDatas?.ToDate || ""}&request_status=${this.state.FilterDatas?.Status?.value ? this.state.FilterDatas?.Status?.value : ""}&login_user_id=${User_ID}&expense_for=${this.state.FilterDatas?.expenseFor?.value ? this.state.FilterDatas?.expenseFor?.value : ""}&expense_category=${this.state.FilterDatas?.expenseCategory?.name ? this.state.FilterDatas?.expenseCategory?.name : ""}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            expenseApprovalData: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    _getExpenseCategoryDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.EXPENSE_CATEGORY_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            expCategoryList: response.data?.data,
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getUserDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            userNameList: response.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                // this.successMessage(message)
                // this.GetBillList()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.getExpenseData() })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    // Convert base64 string to a File object
    convertBase64ToFile = (base64String, fileName, fileType) => {
        const arr = base64String.split(",");
        const mime = fileType || arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };

    expenseBillPost = (reqdata) => {
        try {
            const { expense_for, request_data } = reqdata || {};
            const id = request_data?.id;

            if (expense_for === "Cancel" && id) {
                RestAPIService.delete(`${Serviceurls.EXPENSE_BILL_GET}?id=${id}`)
                    .then(this.handleResponse)
                    .catch(this.handleError);
            } else {
                const formData = new FormData();
                const method = id ? "updateWithOutId" : "create";

                Object.entries(request_data || {})?.forEach(([key, value]) => {
                    if (key !== "document") {
                    formData.append(key, value);
                    } else {
                        if (key === "document" && value) {
                            JSON.parse(value)?.forEach((file) => {
                                if (file?.data_url) {
                                    const document = file?.name
                                        ? this.convertBase64ToFile(file.data_url, file.name, file.type)
                                        : file.image_title;
                                    formData.append("document", document);
                                }
                            });
                        }
                    }
                });

                RestAPIService[method](formData, Serviceurls.EXPENSE_BILL_GET)
                    .then(this.handleResponse)
                    .catch(this.handleError);
            }
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    handleResponse = (response) => {
        if (response.data.status === "success") {
            this.getExpenseData();
            this.handleAddClose();
        } else {
            this.errorMessage(response.data.message);
        }
    };

    handleError = (error) => {
        const errorMsg = error?.response?.data?.status === "fail"
            ? error.response.data.message :
            error.message;
        this.errorMessage(errorMsg);
    };

    PostApprovalData = (data, status = "") => {
        try {
            const payload_data = {
                "id": data.id ? data.id : undefined,
                'request_date': data.request_date ? DateTime.fromJSDate(new Date(data.request_date)).toFormat('yyyy-MM-dd')
                    : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'from_bill_amount': data.from_bill_amount ? data.from_bill_amount : 0,
                'to_bill_amount': data.from_bill_amount ? data.from_bill_amount : 0,
                'request_data': "",
                'approved_data': data?.request_data,
                'status': status,
                'remarks': data.remarks ? data.remarks : "",
                'payment_mode': data.payment_mode ? data.payment_mode : "",
                'expense_for': data.expense_for ? data.expense_for : "",
                'expense_category': data.expense_category ? data.expense_category : "",
            }
            RestAPIService.create(payload_data, Serviceurls.EXPENSE_REQUEST_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.props.triggerCountsApi()
                        if (status === "Approved") {
                            this.expenseBillPost(data)
                        } else {
                            this.handleAddClose();
                            this.getExpenseData();
                        }
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas?.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas?.ToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    handleAddClose = () => {
        this.setState({
            isViewExpense: false,
            viewDetails: {},
        })
    }

    render() {
        this.state.expenseApprovalData?.forEach((element, index) => element.sno = index + 1)
        let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
        const states = this.state;
        const { t } = this.props
        const columns = [
            {
                field: "user_name", headerName: t("Username"), flex: 0.12,
                renderCell: ({row}) => (<Box component={'div'}>{row?.user_name ? row?.user_name?.length > 18 ?
                    <Tooltip placement='top' title={row?.user_name} arrow><div>{row?.user_name.slice(0, 18) + "..."}</div></Tooltip> : row?.user_name : "-"}</Box>)
            },
            {
                field: "request_date", headerName: t("Date & Time"), flex: 0.12,
                renderCell: ({ row }) => (<Box component={'div'}>{row?.request_date ? row?.request_date?.length > 18 ?
                    <Tooltip placement='top' title={formatDateAndTimeMeth2(row?.request_date)} arrow>
                        <div>{formatDateAndTimeMeth2(row?.request_date).slice(0, 18) + "..."}</div></Tooltip>
                    : row?.request_date : "-"}</Box>)
            },
            {
                field: "expense_for", headerName: t("Expense For"), flex: 0.11,
                renderCell: ({row}) => (<Box component={'div'}>{row?.expense_for ? row?.expense_for?.length > 18 ?
                    <Tooltip placement='top' title={row?.expense_for} arrow><div>{row?.expense_for.slice(0, 18) + "..."}</div></Tooltip> : row?.expense_for : "-"}</Box>)
            },
            {
                field: "expense_category", headerName: t("Expense Category"), flex: 0.11,
                renderCell: ({row}) => (<Box component={'div'}>{row?.expense_category ? row?.expense_category?.length > 18 ?
                    <Tooltip placement='top' title={row?.expense_category} arrow><div>{row?.expense_category.slice(0, 18) + "..."}</div></Tooltip> : row?.expense_category : "-"}</Box>)            },
            {
                field: "from_bill_amount", headerName: t("Paid Amount"), flex: 0.11, type: "number",
                renderCell: ({row}) => (<Box component={'div'}>{AmountFormat(row?.from_bill_amount ? row?.from_bill_amount : 0)}</Box>)
            },
            {
                field: "payment_mode", headerName: t("Payment Mode"), flex: 0.11,
                renderCell: ({row}) => (<Box component={'div'}>{row?.payment_mode ? row?.payment_mode?.length > 18 ?
                    <Tooltip placement='top' title={row?.payment_mode} arrow><div>{row?.payment_mode.slice(0, 18) + "..."}</div></Tooltip> : row?.payment_mode : "-"}</Box>)
            },
            {
                field: "remarks", headerName: t("Notes"), flex: 0.11,
                renderCell: ({row}) => (<Box component={'div'}>{row?.remarks ? row?.remarks?.length > 18 ?
                    <Tooltip placement='top' title={row?.remarks} arrow><div>{ row?.remarks.slice(0, 18) + "..."}</div></Tooltip> : row?.remarks : "-"}</Box>)
            },
            {
                field: "status", headerName: t("Status"), flex: 0.09, headerAlign: "center", align: "center",
                renderCell: ({row}) => (<Box component={'div'}>
                    <Typography sx={{ color: row?.status === "Approved" ? "green" : (row?.status === "Pending" ? "orange" : "red"), fontWeight: 600 }}>
                        {row?.status ? row?.status : ""}</Typography>
                </Box>)
            },
            {
                field: "uhid", headerName: t("Action"), flex: 0.12, headerAlign: "center", align: "center",
                renderCell: ({row}) => (
                <Box component={'div'} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                    {(!LoggedData?.is_user && row?.status === "Pending") ?
                        <>
                            <IconButton
                                id="emed_CalenderClose_Icon"
                                onClick={() => {
                                    this.setState({
                                        isViewExpense: true,
                                        viewDetails: row
                                    })
                                }}>
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={ImagePaths.EyeView.default} alt='close' />
                            </IconButton>
                            <IconButton
                                disabled={row?.status !== "Pending"}
                                onClick={() => {
                                    this.PostApprovalData(row, "Approved")
                                }}
                                id="emed_CalenderClose_Icon">
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={row?.status === "Pending" ? ImagePaths.ApproveTick.default : ImagePaths.ApproveDisabled.default} alt='close' />

                            </IconButton>
                        </> : null}
                    <IconButton
                        disabled={row?.status !== "Pending"}
                        onClick={() => {
                            this.PostApprovalData(row, "Cancelled")
                        }}
                        id="emed_CalenderClose_Icon">
                        <img style={{ width: '1.5vw', height: '1.5vw' }} src={row?.status === "Pending" ? ImagePaths.CancelEnable.default : ImagePaths.CancellDisable.default} alt='close' />
                    </IconButton>
                </Box>)
            },
        ]
        return (
            <Box>
                <Box component={"div"}>
                    <Box component={"div"} className='eMed_Action_Container' sx={{ width: "100%", marginTop: "1vw" }}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table' sx={{ height: '70vh', width: '100%', overflow: 'auto' }}>
                    <DataGrid
                        rows={this.state.expenseApprovalData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    user_name: (LoggedData?.is_user ? false : true),
                                }
                            }
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={60}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <ApprovalFilters
                        FilterDatas={this.state.FilterDatas}
                        CloseFilter={this.ClosePopUp.bind(this)}
                        FilteredData={this.renderFilter.bind(this)}
                        ClearData={this.clearFilter.bind(this)}
                        pageName={"ExpenseApproval"} 
                        expenseCategoryList={this.state.expCategoryList}
                        userNameList={this.state.userNameList}
                    />
                </Drawer>
                {states.isViewExpense ? <ExpensePopUp
                    View={states.isViewExpense}
                    viewData={states.viewDetails}
                    expCategoryList={states.expCategoryList}
                    popUpClose={this.handleAddClose.bind(this)}
                    errorMessage={this.errorMessage.bind(this)}
                    successMessage={this.successMessage.bind(this)}
                    LoaderFunction={this.LoaderFunction.bind(this)}
                    fromExpenseApproval={true}
                    PostApprovalData={(status) => {
                        this.PostApprovalData(states.viewDetails, status)
                    }}
                    key={"view"}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(ExpenseApprovals);