import React, { Component } from 'react';
import {Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CommonGridHeader, { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, OpenDirectPrint, Time_convert, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';


class DayEndStatus extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 30,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "PayMethods": [1,2,3,4,5,6],
                'BillStatus':["completed", "saved", "credit", "cancelled"],
                "CompletedSelected": true,
                "CreditSelected": true,
                "SavedSelected": true,
                "CancelledSelected": true,
                "startTime":null,
                "endTime":null,
                "UserList" : [],
                "UserDetails" : [],
                "is_user" : true,
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "ReceiptFrom": "",
                "ReceiptTo": "",
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
            },
            CardDetails:{
                "Total": 0,
                "Cash": 0,
                "Card": 0,
                "UPI": 0,
                "BankTransfer": 0,
                "Credit": 0,
                "Cheque": 0,
                "Advance": 0,
                "CreditAmt": 0,
                billAmt:0,
                returnAmt:0
            }
        }
    }

    componentDidMount() {
        this.GetBillList()
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        // if (UserData?.is_user == false) {
            this.state.FilterDatas.is_user = false
            this.setState({
                FilterDatas : this.state.FilterDatas
            }, () => {this.GetUserDetails()})
        // }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    GetBillList() {
        let User_ID = []
        this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
        try {
            var states = this.state

            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let dayEndBasis = localGetItem("day_end_value")
            let params = '';
            if(dayEndBasis === "1"){
                params =  Serviceurls.DAY_END_STATUS_GET
            }else{
                params = Serviceurls.DAY_END_MODULE_BASED
            }
            this.LoaderFunction(true)
            RestAPIService.getAll(params +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&module_type=" + states.FilterDatas.BillType +
                // "&bill_type=" + states.FilterDatas.BillStatus +
                "&payment_method=" + states.FilterDatas.PayMethods + 
                "&start_time=" + setStartTime + 
                "&end_time=" + setEndTime +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo + 
                "&user_id=" + User_ID +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        states.CardDetails.Total = response?.data?.data?.total_amount;
                        states.CardDetails.Cash = response?.data?.data?.cash_amount;
                        states.CardDetails.Card = response?.data?.data?.card_amount;
                        states.CardDetails.UPI = response?.data?.data?.upi_amount;
                        states.CardDetails.BankTransfer = response?.data?.data?.bank_amount;
                        states.CardDetails.Credit = response?.data?.data?.insurance_amount;
                        states.CardDetails.Cheque = response?.data?.data?.cheque_amount;
                        states.CardDetails.Advance = response?.data?.data?.advance_amount;
                        states.CardDetails.CreditAmt = response?.data?.data?.total_credit_value;
                        states.CardDetails.billAmt = response?.data?.data?.bill_amount;
                        states.CardDetails.returnAmt = response?.data?.data?.return_amount;
                        this.setState({
                            BillList: response.data?.data?.data,
                            CardDetails: states.CardDetails
                        },()=>{this.LoaderFunction(false)})
                    }
                    else {
                         this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if(error?.response?.data?.status === "fail"){
                         this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                         this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    } 
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    PrintFilterDetails = () => {
        try {
            let states = this.state
            let bill_type = []
            if (states.FilterDatas.BillType.length > 0) {
                for (var i = 0; i < states.FilterDatas.BillType.length; i++) {
                    bill_type.push("&bill_type=" + (states.FilterDatas.BillType[i] == "op" ? "OP" : states.FilterDatas.BillType[i] == "ip" ? "IP" : states.FilterDatas.BillType[i] == "pharmacy" ? "Pharmacy" : states.FilterDatas.BillType[i] == "lab" ? "Lab" : ""))
                }
            }else{
                bill_type = states.FilterDatas.BillType
            }
            const Multi_bill_type = bill_type.join('');
            // let dayEndBasis = localGetItem("day_end_value")
            // let params = '';
            // if(dayEndBasis === "1"){
            //     params =  Serviceurls.DAY_END_STATUS_Filter
            // }else{
            //     params = Serviceurls.DAY_END_MODULE_BASED
            // }
            RestAPIService.getAll(
                Serviceurls.DAY_END_STATUS_Filter +
                "?invoice_date=" + states.FilterDatas.FromDate +
                "&invoice_to_date=" + states.FilterDatas.ToDate +
                Multi_bill_type + 
                "&payment_type=" + states.FilterDatas.PayMethods +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo +
                "&export_type=pdf" +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
                ).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        // const file = new Blob(
                        //     [response.data],
                        //     { type: 'application/pdf' });
                        // //Build a URL from the file
                        // const fileURL = URL.createObjectURL(file);
                        // //Open the URL on new Window
                        // window.open(fileURL);
                        OpenDirectPrint(response)
                    }
                }).catch((error) => {
                    if(error?.response?.data?.message){
                        this.errorMessage(error?.response?.data?.message)
                    }else{
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PrintDayendDetaild = () => {
        try {
            let User_ID = []
            this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let states = this.state
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let params = Serviceurls.FO_DAY_END_DETAILED_PRINT;
           
            RestAPIService.getAll(
                params +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&module_type=" + states.FilterDatas.BillType +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime +
                "&user_id=" + User_ID +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo +
                "&export_type=pdf"
            ).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        // const file = new Blob(
                        //     [response.data],
                        //     { type: 'application/pdf' });
                        // //Build a URL from the file
                        // const fileURL = URL.createObjectURL(file);
                        // //Open the URL on new Window
                        // window.open(fileURL);
                        OpenDirectPrint(response)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetUserDetails = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET + `?show_all=true`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        states.FilterDatas["UserList"] =  response.data.data
                        this.setState({
                            states
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    closeFilter() {
        this.setState({
            FilterOpen: false
        })
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "DayendDetailed", label: t("Dayend Detailed") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                    size='small'
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon sx={{width: '1.5vw', height: '1.5vw'}} />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} onClick={() => { 
                                this.setState({ anchorEl: null }, ()=>{
                                    if(option.value === "DayendDetailed"){
                                       this.PrintDayendDetaild()
                                    }
                                }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetBillList() })
    }
    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }
    PrintDetails = (data) => {
        this.setState({
            FilterDatas: data
        }, () => { this.PrintFilterDetails() })
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
        })
      }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    PrintReport = () =>{
        try {
            let User_ID = []
            this.state.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let states = this.state
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS)+".000";
            
            let dayEndBasis = localGetItem("day_end_value")
            let params = '';
            if(dayEndBasis === "1"){
                params =  Serviceurls.DAY_END_STATUS_GET
            }else{
                params = Serviceurls.DAY_END_MODULE_BASED
            }
            RestAPIService.getAll(
                params +
                "?from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&module_type=" + states.FilterDatas.BillType +
                // "&bill_type=" + states.FilterDatas.BillStatus +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime + 
                "&end_time=" + setEndTime +
                "&user_id=" + User_ID +
                "&receipt_no_from=" + states.FilterDatas.ReceiptFrom +
                "&receipt_no_to=" + states.FilterDatas.ReceiptTo + 
                "&export_type=pdf" +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            ).
              then((response) => {
                if (response) {
                  //Create a Blob from the PDF Stream
                //   const file = new Blob(
                //     [response.data],
                //     { type: 'application/pdf' });
                //   //Build a URL from the file
                //   const fileURL = URL.createObjectURL(file);
                //   //Open the URL on new Window
                //   window.open(fileURL);
                OpenDirectPrint(response)
                }
              }).catch((error) => {
                if(error?.response?.data?.message){
                    this.errorMessage(error?.response?.data?.message)
                }else{
                    this.errorMessage(error.message)
                }
              })
          } catch (e) {
            this.errorMessage(e.message)
          }
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
            let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilterDatas.FromDate)},
            {label:"To Date", value:formatDate(this.state.FilterDatas.ToDate)}
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
            var ExportData = []
            this.state.BillList?.length > 0 && this.state.BillList.map((item) =>
                ExportData.push({"BillType":item.bill_type,'Cash':item.cash , 'Card':item.card ,'UPI':item.upi ,'BankTransfer':item.bank_transfer ,'Cheque':item.cheque , 'Total':item.total,'Credit value':item.credit_value})
            )
        return(
          <Box>
             <CommonGridToolBarWithFilterTextCustom  FilterTextArray={TextArray} data={ExportData} title={"Day End Bills"} filename={"Day End Status"}/>
          </Box>
    
        )
      }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => { element.sno = index + 1 })
        let dayEndBasis = localGetItem("day_end_value")
        const columns = [
            {
                field: "bill_type", flex: 0.1, headerName: t("BillType"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_type ? this.getCapitalize(params?.row?.bill_type) : "-"}</Box>)
            },
            {
                field: "bill_amount", flex: 0.1, headerName: t("Bill Amount"),hide:dayEndBasis === "1" ? true : false,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_amount ? AmountFormat(params?.row?.bill_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
            },
            {
                field: "cash", flex: 0.1, headerName: t('Cash')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.cash ? AmountFormat(params?.row?.cash)?.replace(`${CurrencySymbol}`, "") : params?.row?.cash === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "card", flex: 0.1, headerName: t('Card')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.card ? AmountFormat(params?.row?.card)?.replace(`${CurrencySymbol}`, "") : params?.row?.card === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "upi", flex: 0.1, headerName: t('UPI')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.upi ? AmountFormat(params?.row?.upi)?.replace(`${CurrencySymbol}`, "") : params?.row?.upi === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "bank_transfer", flex: 0.1, headerName: t('BankTransfer')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bank_transfer ? AmountFormat(params?.row?.bank_transfer)?.replace(`${CurrencySymbol}`, "") : params?.row?.bank_transfer === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "cheque", flex: 0.1, headerName: t('Cheque')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.cheque ? AmountFormat(params?.row?.cheque)?.replace(`${CurrencySymbol}`, "") : params?.row?.cheque === 0 ? "0.00" : "-"}</Box>)
            },
            // {
            //     field: "insurance", flex: 0.1, headerName: t("Insurance₹"), type: "number",
            //     renderCell: (params) => (<Box component={'div'}>{params?.row?.insurance ? AmountFormat(params?.row?.insurance)?.replace("₹", "") : params?.row?.insurance === 0 ? "0" : "-"}</Box>)
            // },
            {
                field: "total", flex: 0.1, headerName: t('Total')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total ? AmountFormat(params?.row?.total)?.replace(`${CurrencySymbol}`, "") : params?.row?.total === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "credit_value", flex: 0.1, headerName: t('Credit')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.credit_value ? AmountFormat(params?.row?.credit_value)?.replace(`${CurrencySymbol}`, "") : params?.row?.credit_value === 0 ? "0.00" : "-"}</Box>)
            },
            {
                field: "return_amount", flex: 0.1, headerName: t('Return Amount')+` (${CurrencySymbol})`, type: "number",hide:dayEndBasis === "1" ? true : false,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.return_amount ? AmountFormat(params?.row?.return_amount)?.replace(`${CurrencySymbol}`, "") : params?.row?.return_amount === 0 ? "0.00" : "-"}</Box>)
            },
        ]
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Stack direction = {"row"} alignItems = {"center"}  sx={{flex: 0.115, marginBottom : "0.195vw"}}>
                        {dayEndBasis === "1" ? null :AmountsCard(t("Total Bill Amount"), this.state.CardDetails.billAmt)}
                        {AmountsCard(t("Total Received Amount"), this.state.CardDetails.Total)}
                    </Stack>
                    <Box component={"div"} className='eMed_Cards_Container' sx={{flex:0.735}} ml={'0.5vw'}>
                        {AmountsCard(t("Cash"), this.state.CardDetails.Cash,false,'white',false,'','cash',"8vw")}
                        {AmountsCard(t("Card"), this.state.CardDetails.Card,false,'white',false,'','card',"8vw")}
                        {AmountsCard(t("UPI"), this.state.CardDetails.UPI,false,'white',false,'','upi',"8vw")}
                        {AmountsCard(t("BankTransfer"), this.state.CardDetails.BankTransfer,false,'white',false,'','bank',"8vw")}
                        {AmountsCard(t("Cheque"), this.state.CardDetails.Cheque,false,'white',false,'','cheque',"8vw")}
                        {/* {AmountsCard(t("Insurance"), this.state.CardDetails.Credit)} */}
                        {/* {AmountsCard(t("Advance"), this.state.CardDetails.Advance)} */}
                        {AmountsCard(t("Credit"), this.state.CardDetails.CreditAmt,false,'white',false,'','credit',"8vw")}
                        {dayEndBasis === "1" ? null : AmountsCard(t("Return"), this.state.CardDetails.returnAmt,false,'white',false,'','return',"8vw")}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container' sx={{flex:0.15}}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box sx={{borderRight: '1px solid #888888',  height: '2vw', }}></Box>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.PrintReport() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        {this.MenuItem()}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                hideFooter
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter.bind(this) }}
                >
                    <BillingsFilter PageName={"DayEndStatus"} FilterDatas={this.state.FilterDatas} CloseFilter={this.closeFilter.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)}  PrintData={this.PrintDetails.bind(this)} FromFODayEnd={true}/>
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    {/* <Loader loaderOpen={this.state.isLoader} /> */}
            </Box>
        )
    }
}

export default withTranslation()(DayEndStatus)


