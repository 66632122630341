import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let concessionList = ["Active", "Inactive"]


let RoleData = null;
let userAccess = null;
class OrganismMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organismMasterData: [],
      searchOrganism: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      staus_type: "",
      openPopUp: false,
      isFromEdit: false,

      selectedId: null,
      organismCode: null,
      organismName: "",
      order: null,
      deptStatus: true,
      comments: ""
    }

    this.postOrganismMaster = this.postOrganismMaster.bind(this);
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  // api call mount
  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", "Organism Master", "SubTab")
    this.OrganismList()
  }

  // loading
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  // api call function
  OrganismList() {
    try {
      this.setState({ postLoad: true })
      this.LoaderFunction(true);
      let status = this.state.staus_type === "Active" ? true : this.state.staus_type === "Inactive" ? false : ""
      RestAPIService.getAll(`${Serviceurls.LAB_ORGANISM_MASTER}?organism_name=${this.state.searchOrganism}&is_active=${status}`).
        then((response) => {
         
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.setState({
              organismMasterData: response.data.data,
              rowCount: response.data.data?.total_count
            })
          }
          else {
            this.setState({ postLoad: false})
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.LoaderFunction(false);
          this.setState({ postLoad: false })
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  postOrganismMaster = () => {
    try {
      this.LoaderFunction(true);
      let data = {
        "organism_id": this.state.selectedId ? this.state.selectedId : null,
        "organism_code": this.state.organismCode ? this.state.organismCode : null,
        "organism_order": this.state.order ? this.state.order : null,
        "organism_name": this.state.organismName ? CommonValidation.removeSpace(this.state.organismName) : '',
        "is_active": this.state.deptStatus ? this.state.deptStatus : false,
        "reason": this.state.comments ? CommonValidation.removeSpace(this.state.comments) : '',
      }

      RestAPIService.create(data, `${Serviceurls.LAB_ORGANISM_MASTER}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.successMessage(response.data.message)
            this.setState({ openPopUp: false, disableBtn: false, organismCode: null, isFromEdit : false, selectedId : null })
            this.handleClear()
            this.OrganismList()
          }
          else {
            this.LoaderFunction(false);
            this.setState({ postLoad: false })
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.LoaderFunction(false);
          this.setState({ postLoad: false })
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  // close filter
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  // clear popup function
  handleClear = () => {
    this.setState({
      antibioticGroup: '',
      organismName: '',
      antibioticContent: '',
      order: '',
      deptStatus: true,
      comments: ""
    })
  }

  // filter screen
  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid="vnd_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("Search Organism")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              value={states.searchOrganism ? states.searchOrganism : ""}
              inputProps={{ emed_tid: "organismName", maxLength:100 }}
              onChange={(e) => {
                this.setState({
                  searchOrganism: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              disableClearable
              options={concessionList}
              getOptionLabel={(option) => (option)}
              value={this.state.staus_type}
              onChange={(event, newValue) => {
                this.setState({ staus_type: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                  label={t('Select Status')}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid="fil_clear"
            onClick={() => {
              this.setState({
                searchOrganism: "",
                staus_type: "",
              }, () => this.OrganismList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid="fil_search"
            onClick={() => {
              this.OrganismList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  // edit call function
  handleEdit = (data) => {
    this.setState({
      isFromEdit: true,
      openPopUp: true,
      selectedId: data.id,
      organismCode: data.organism_code,
      organismName: data.organism_name,
      order: data.organism_order,
      deptStatus: data.is_active,
      comments: data.reason || ""
    })
  }

  // common input
  renderTextField = (value, statekey, Label, required, width, disable = false) => {
    const { t } = this.props;
    let states = this.state
    return (
      <TextField
        sx={{ width: width ? width : "12vw", marginX: '0.65vw', marginTop: "1vw" }}
        size="small"
        disabled={disable}
        inputProps={{ maxLength: 100 }}
        autoComplete="off"
        label={t(Label)}
        required={required}
        value={value}
        onChange={(e) => {
          let value = e.target.value
          if (statekey === "order") {
            if (CommonValidation.numberOnly(value) || value === "") {
              states[statekey] = value.trimStart()
              this.setState({ states })
            }
          } else {
            states[statekey] = value.trimStart()
            this.setState({ states })
          }
        }}
      />
    )
  }

  // popup
  addNewAntibioticPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.openPopUp}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ padding: "1vw", width: "43vw", height: "63vh", maxHeight: "100%", position: "relative" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              {/* title */}
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit Organism" : "Add Organism"}</Typography>
              {/* close */}
              <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ organismCode: '', openPopUp: false, isFromEdit: false, selectedId: null }, () => { this.handleClear() })} >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <hr />
            {/* form */}
            <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1vh" }}>
                {/* form fields */}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.isFromEdit ?
                    this.renderTextField(this.state.organismCode, "organismCode", "Organism Code", false, "12vw", true)
                    : null}
                  {this.renderTextField(this.state.organismName, "organismName", "Organism Name", true, !this.state.isFromEdit ? "18.5vw" :"12vw", false)}
                  {this.state.isFromEdit ?
                    this.renderTextField(this.state.order, "order", "Print Order", true, "12vw", false)
                    : null}
                </div>

                {/* status */}
                <Box component={'div'} style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ marginLeft: "1vh", marginTop: "2vh" }}>
                    <Typography fontWeight={600}>{"Status"}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <Checkbox
                      checked={this.state.deptStatus}
                      onChange={(e) => { this.setState({ deptStatus: e.target.checked ,comments :""}) }}
                      disabled ={!this.state.isFromEdit}
                    />
                    <Typography>{"Active"}</Typography>
                  </Box>
                </Box>

                {this.state.deptStatus === false ?
                  <Box>
                    <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                      <Typography fontWeight={600}>{"Inactive Comments"}</Typography>
                    </Box>
                    <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                      <div>
                        <TextField
                          size='small'
                          label={("Reason *")}
                          disabled ={!this.state.isFromEdit}
                          style={{ marginTop: "0.5vw", width: "38.6vw" }}
                          multiline={true}
                          rows={3}
                          variant="outlined"
                          value={this.state.comments}
                          onChange={(event) => {
                            let value = event.target.value
                            if (value?.length <= 250) {
                              this.setState({ comments: value })
                            } else {
                              this.errorMessage('Allowed only 250 characters')
                            }
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                  : null
                }
              </Box>
            </Box>
            {/* footer */}
            <Box sx={{ position: "absolute", bottom: "2vh", right: "1vh", backgroundColor: "white" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => { this.handleClear() }}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  disabled={this.state.postLoad}
                  variant='contained'
                  onClick={() => {
                    if (!this.state.organismName) {
                      this.errorMessage("Enter Organism Name")
                    }else if (this.state.isFromEdit && !this.state.order) {
                      this.errorMessage("Enter Print Order")
                    } else if (this.state.deptStatus === false && this.state.comments === "") {
                      this.errorMessage("Enter Inactive Reason")
                    } else {
                      this.setState({ postLoad: true }, () => {this.postOrganismMaster()})
                    }
                  }}
                >{states.isFromEdit ? "Update" : "Add"}</Button>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  // table tool bar
  gridToolBar = () => {

    var ExportData = []
    this.state.organismMasterData?.length > 0 && this.state.organismMasterData?.map((item) => {

      return (
        ExportData?.push({
          "SI.No": item?.sno, "Organism Code": item?.organism_code, "Organism Name": item?.organism_name, "Print Order": item?.organism_order,"Status": item?.is_active === true ? "Active" : item?.is_active === false ? "InActive" : "",
        })
      )
    }
    )
    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Organism Master"} filename={"Organism Master"} />
      </Box>
    )
  } 

  // table
  renderTable = () => {
    this.state.organismMasterData?.forEach((element, index) => element.sno = index + 1)

    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "organism_code", flex: 0.166, headerName: t("Organism Code"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.organism_code ? params?.row?.organism_code : "-"}</Box>)
      },
      {
        field: "organism_name", flex: 0.166, headerName: t("Organism Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.organism_name?.length > 23 ?
          <Tooltip placement="top" title={row?.organism_name} arrow><div>{row?.organism_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.organism_name ? row?.organism_name : "-"}</div>)
      },
      {
        field: "organism_order", flex: 0.166, headerName: t("Print Order"), headerAlign: "left", align: "left",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.organism_order ? params?.row?.organism_order : "-"}</Box>)
      },
      {
        field: "is_active", flex: 0.166, headerName: t("Status"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (
          <Tooltip placement="top" title={row?.reason} arrow><div>{row?.is_active ? "Active" : "Inactive"}</div></Tooltip>  
          )
      },
      {
        field: "action", flex: 0.134, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" onClick={() => { this.handleEdit(params.row) }} arrow>
            <Button emed_tid="vnd_edit" className='eMed_usrconf_btn'>
              <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' style={{ backgroundColor: Colors.Background }}>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.organismMasterData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              loading={this.state.isLoader}
              rowCount={this.state.rowCount}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top' style={{ backgroundColor: Colors.Background }}>
          {userAccess?.editAccess ? <><Button emed_tid="add_vnd" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.setState({ openPopUp: true }) }}>{`Add New Organism`}</Button>
            <Divider orientation='vertical' /></> : null}
          <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid="vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip>

        </div>
        {this.renderTable()}
        {this.addNewAntibioticPopup()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}

      </div>
    )
  }
}

export default withTranslation()(OrganismMaster);