import React, { Component ,createRef} from 'react';
import './IPPatientsHome.css';
import { TextField, IconButton, Tooltip, Box, Typography, Menu, MenuItem, Stack, Checkbox, Paper, Divider, FormControlLabel, FormControl, InputLabel, Select, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImagePaths } from '../../.././Utility/ImagePaths';
import CommonGridHeader, { AppointmentCard, CommonPatientDetails, ReasonPopup, CommonPopUp, VacentRoomPopup, DeletePopup, CommonGridToolBarWithButton } from '../../.././Components/Common Components/CommonComponents';
import { DataGrid, gridNumberComparator, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { Colors } from '../../.././Styles/Colors';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../.././Utility/Services/RestAPIService';
import { Serviceurls } from '../../.././Utility/API/Serviceurls';
import ToastMsg from '../../.././Components/ToastMsg/ToastMsg';
import { getCachevalue, setCachevalue } from '../../.././Utility/Services/CacheProviderService';
import { AmountFormat } from "../../.././Components/CommonFunctions/CommonFunctions";
// import Loader from '../../../Components/Loader';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { localGetItem } from '../../.././Utility/Services/CacheProviderService';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { CurrencySymbol, DischargeTypeList } from '../../../Utility/Constants';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';

let today = new Date();

class IPpatientsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datePicker: new Date(),
      IpPatientDetails: {},
      IpPatientData: [],
      selectedOption: "",
      SelectedPatientDetails: {},
      isCheckout: false,
      isClicked: false,
      anchorEl: null,
      open: false,
      checkoutDateTime: today,
      selectedRow: null,
      isSelected: false,
      selectedCard: 'In Ip',
      isAdvanceClicked: false,
      fromDate: this.props.dateDetails?.fromDate ? this.props.dateDetails?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: this.props.dateDetails?.toDate ? this.props.dateDetails?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: this.props.dateDetails?.dateIndex ? this.props.dateDetails?.dateIndex : 1,
      page: 0,
      pageSize: 30,
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      SelectedDoctorID: "",
      WardPricePopup: false,
      VacantPopOpen: false,
      ApproxAmount: 0,
      isAMApatient: false,
      DiscComments:"",
      disable:false,
      checkoutPopup:false,
      checkOutId:null,
      refundPatientData:{},
      DischargeType : "Normal",
      LoggedData : JSON.parse(localGetItem("loggedInUserInfo"))
    }
  }

  componentDidMount() {
    let DoctorID = JSON.parse(getCachevalue("SelectedIPDoctorID"))
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser;

    if (DoctorID !== this.state.SelectedDoctorID && ((DoctorID != null && DoctorID != undefined))) {
      this.setState({ SelectedDoctorID: DoctorID }, () => {
        this.getPatientDetails()
      })
    } else {
      this.getPatientDetails();
    }
    if (UserData?.is_user == true) {
      this.GetWardList()
    }
    if (this.props.history?.location?.state?.NewMessage) {
      this.responseSuccessErrorMessage(this.props.history?.location?.state?.NewMessage, true);
      this.props.history.push({ state: { NewMessage: null } })
    }
  }

  componentDidUpdate(prevProps) {
    let DoctorID = JSON.parse(getCachevalue("SelectedIPDoctorID"))

    if (DoctorID !== this.state.SelectedDoctorID && ((DoctorID != null && DoctorID != undefined))) {
      this.setState({ SelectedDoctorID: DoctorID }, () => {
        this.getPatientDetails()
      })
    }
    if (prevProps.dateDetails !== this.props.dateDetails) {
      this.setState({
        fromDate: this.props.dateDetails?.fromDate ? this.props.dateDetails?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        toDate: this.props.dateDetails?.toDate ? this.props.dateDetails?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        dateIndex: this.props.dateDetails?.dateIndex ? this.props.dateDetails?.dateIndex : 1,
      }, () => this.getPatientDetails())
    }
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPatientDetails = () => {
    let states = this.state;
    this.setState({
      IpPatientDetails: {},
      IpPatientData: []
    })
    try {
      this.LoaderFunction(true)
      setCachevalue(states["fromDate"] ? states["fromDate"] : "", 'FormDate')
      setCachevalue(states["toDate"] ? states["toDate"] : "", 'ToDate')
      setCachevalue(states.selectedCard, 'Status')
      var DoctorId = (JSON.parse(getCachevalue("SelectedIPDoctorID")) === 0 || JSON.parse(getCachevalue("SelectedIPDoctorID")) === null || JSON.parse(getCachevalue("SelectedIPDoctorID")) === undefined) ? "" : JSON.parse(getCachevalue("SelectedIPDoctorID"));
      RestAPIService.getAll(`${Serviceurls.FO_IP_HOME}?from_date=${states["fromDate"] ? states["fromDate"] : ""}&to_date=${states["toDate"] ? states["toDate"] : ""}&status=${this.state.selectedCard ? this.state.selectedCard : ""}&doctor_id=${DoctorId}`)
        .then(response => {
          let data = response?.data;
          if (response.data.status === "success") {
            this.setState({
              IpPatientDetails: data,
              IpPatientData: data.data
            }, () => {
              this.state["IpPatientData"].forEach((item, i) => {
                item["index"] = i
                item.room_ward_block = `${item.bed_number ? item.bed_number : "-"}/${item.room_number ? item.room_number : "-"}/${item.ward_name ? item.ward_name : "-"}/${item.block_name ? item.block_name : "-"}`
                item.patientDetails = `${CommonValidation.removeSpace(item?.patient_name)} ${item?.patient_account_number} ${item?.patient__mobile_number} ${item?.doctor_name} ${item?.patient_address_1} ${item?.patient_address_2} ${item?.patient_city} ${item?.father_name} ${item?.mother_name} ${item?.bed_number}`
              })
              this.LoaderFunction(false)
            });
          }
        })
        .catch(error => {
          if (error.response?.data.status === "fail") {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(error.response?.data?.message, false);
          } else {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(error.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }

  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message,
      removeDisableChecKBtn : true
    })
  }

  onPressViewButton = (flag) => {
    this.setState({
      WardPricePopup: flag
    })
  }
  gridToolBar = () => {
    let { t } = this.props
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"));
    return (
      <Box>
        <CommonGridToolBarWithButton
          onClickCommonButton={this.onPressViewButton.bind(this)}
          label={t("Room Price")}
          ViewBtn={UserData?.is_user == true}
          endIcon={CurrencySymbol} />
      </Box>

    )
  }

  postPatientStatus = (status, id, comments, isForcheckout = false) => {
    try {
      let states = this.state;
      let newDate = "", newTime = "";
      if (states["checkoutDateTime"]) {
        newDate = DateTime.fromJSDate(states["checkoutDateTime"]).toFormat("yyyy-MM-dd");
        newTime = DateTime.fromJSDate(states["checkoutDateTime"]).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      }

      let data = {
        "admission_id": id,
        "status": status,
        "to_credit": states["isClicked"],
        "is_checkout": states["isAdvanceClicked"],
        "discharge_date": newDate,
        "discharge_time": newTime,
        "cancel_reson": comments.replace(/\s+/g, ' ').trim(),
        "is_ama_patient": this.state.DischargeType === "AMA",
        "ama_comment": this.state.DischargeType === "AMA" ? CommonValidation.removeSpace(states.DiscComments) : "",
        "discharge_comment" : CommonValidation.removeSpace(states.DiscComments),
        "discharge_type" : this.state.DischargeType
      }
      this.setState({ disable: true })
      RestAPIService.create(data, Serviceurls.FO_IP_HOME)
        .then(response => {
          if (response.data.status === "success") {
            this.getPatientDetails();
            if (isForcheckout) {
              setCachevalue(JSON.stringify(this.state.SelectedPatientDetails), "IppatientData");
              this.redirect()
              this.setState({ CheckoutConfirmation: false, disable: false, DiscComments: "" })

            } else {
              this.checkoutPopUpClose();
              this.setState({ disable: false, checkOutId: null, checkoutPopup: false, DiscComments: "" })
              this.responseSuccessErrorMessage(response.data.message, true);
            }
          }
        }).catch(e => {
          this.setState({ disable: false })
          if (e.response.data.status === "fail") {
            this.responseSuccessErrorMessage(e.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(e.message, false)
          }
        })
    } catch (e) {
      this.setState({ disable: false })
      this.responseSuccessErrorMessage(e.message, false)
    }
  }

  redirect = (forRefund) => {
    if (this.state.SelectedPatientDetails.discharge_status !== "Checked Out") {
      setCachevalue(true, "IsForCheckedOut");
      if (forRefund) {
        setCachevalue(JSON.stringify(this.state.refundPatientData), 'IppatientData')
      }
      { this.props.history.push({ pathname: "/IPHome/PatientDetails/ReceivePayment", state: { isFromCheckOut: true, fromFinalBill: true, forRefund: forRefund } }) }
    }
  }

  renderPatientType = () => {
    const { t } = this.props
    var states = this.state
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <div className='eMed_OP_AddPatient'>
        <div className='eMed_OP_PatientType_CreateBtn'>
          {UserData?.module_name !== 'nurse' ?
            <div>
              <Tooltip title={t('CreatePatient')} placement='top' arrow>
                <IconButton size="small" onClick={() => { this.setState({ selectedOption: "createPatient" }, () => this.routeToPatientDetails("")) }}>
                  <img className='eMed_OP_AddPatient_Icon' src={ImagePaths.AddNewPatient.default} alt={"Add Patient"} />
                </IconButton>
              </Tooltip>
            </div> : null}
          <div className='eMed_OP_PatientTypeCont'>
            <p className='eMed_OP_TypeNew'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.NewPatient.default} alt={'New Patient'} /> {`${t('NewPatients')}  ( ${states["IpPatientDetails"]["new_patient"] ? states["IpPatientDetails"]["new_patient"] : 0} )`}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.EstablishedPatient.default} alt={'Review Patient'} /> {`${t('ReviewPatients')}  ( ${states["IpPatientDetails"]["review_patient"] ? states["IpPatientDetails"]["review_patient"] : 0} )`}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.ReviewPatient.default} alt={'Old Patients'} /> {`${t('Old Patients')} ( ${states["IpPatientDetails"]["old_patient"] ? states["IpPatientDetails"]["old_patient"] : 0} )`}</p>
          </div>
        </div>
        <div>
          <div className='eMed_OP_Apt_Cards'>
            <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["all"] ? states["IpPatientDetails"]["all"] : 0}`} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'In Ip' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["overall_count"] ? states["IpPatientDetails"]["overall_count"] : 0}`} label={t("In IP")} color={"#616161"} isSelectedCard={states.selectedCard === "In Ip" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Admitted' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["admitted"] ? states["IpPatientDetails"]["admitted"] : 0}`} label={t("Admission")} color={"#04B7B1"} isSelectedCard={states.selectedCard === "Admitted" ? true : false} />
            {/* <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Tentative' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["tentative"] ? states["IpPatientDetails"]["tentative"] : 0}`} label={t("Tentative")} color={"#0461B7"} isSelectedCard={states.selectedCard === "Tentative" ? true : false} /> */}
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Checked Out' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["checked_out"] ? states["IpPatientDetails"]["checked_out"] : 0}`} label={t("Discharge")} color={"#1E972A"} isSelectedCard={states.selectedCard === "Checked Out" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getPatientDetails() }) }} count={`${states["IpPatientDetails"]["cancelled"] ? states["IpPatientDetails"]["cancelled"] : 0}`} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} />
          </div>
        </div>
      </div>
    )
  }

  getPatientBillDetailedPrint = (params) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_PATIENT_BILL_DETAILED_PRINT + "?ip_admission_id=" + params?.row?.id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response?.data?.message, false);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  getAdmissionCard = (params) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_PATIENT_ADMISSION_PRINT + "?ip_admission_id=" + params.row?.id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response?.data?.message, false);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  getIpSummaryBillPrint = (params) => {
    try {
      RestAPIService.getAllPrint(Serviceurls.IP_ADMISSION_BILL_SUMMARY_PRINT + "?ip_admission_id=" + params.row?.id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response?.data?.message, false);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  getApproxBillPrint = (params) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_ADMISSION_BILL_APPROX_PRINT + "?ip_admission_id=" + params.row?.id + "&patient_id=" + params.row?.patient_id + "&branch_id=" + localGetItem("BranchId") + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response?.data?.message, false);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  getCommonBarCodePrint = (params) => {
    try {
      RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + params.row?.patient_id + "&admission_id=" + params.row?.id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.responseSuccessErrorMessage(error.response?.data?.message, false);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  CheckoutConfirmation = (data) => {
    if (data.discharge_status === "Checked Out") {
      this.setState({ isCheckout: false, CheckoutConfirmation: true, SelectedPatientDetails: data, checkoutDateTime: new Date(), DischargeType: data.discharge_type })
    } else { this.setState({ isCheckout: true, SelectedPatientDetails: data, checkoutDateTime: new Date(), DischargeType: data.discharge_type }) }
  }

  checkoutScreenPopup = () => {
    try {
      return (
        <Modal open={this.state.checkoutPopup}>
          <Box component={'div'} className='eMed_Checkout_popup'>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontWeight={600}>{'Checkout'}</Typography>
              <Button onClick={() => this.setState({ checkoutPopup: false })} className='Common_Btn_Min_Width'>
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box component={'div'} mt={'0.5vw'} p={'0.5vw'}>
              <Typography fontWeight={600}>
                This patient has an advance payment on their account. Please click 'Refund' to process a refund for the advance payment to the
                patient or click Checkout ?
              </Typography>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'flex-end'} p={'0.5vw'}>
              <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                onClick={() => { this.redirect(true) }}
              >Refund</Button>
              <Button variant='contained' color='error' sx={{ textTransform: 'capitalize', height: '2vw' }}
                disabled={this.state.disable}
                onClick={() => this.postPatientStatus("Checked Out", this.state.checkOutId, '', false)}
              >Checkout</Button>
            </Box>
          </Box>
        </Modal>
      )
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  ActionBtn = (data) => {
    const { t } = this.props;
    let states = this.state;
    const multi_option = [
      { label: t("DischargeSummary"), value: "DischargeSummary" },
      { label: t("RoomChange"), value: "RoomChange" },
      { label: t("ReceivePayment"), value: "ReceivePayment" },
      { label: t("BillDetails"), value: "BillDetails" },
      { label: t("SummaryBill"), value: "SummaryBill" },
      { label: t("Approximate Bill"), value: "ApproxBill" },
      { label: t("BarCode"), value: "CommonBarcode" },
      { label: t("Cancel"), value: "Cancel" }
    ]

    if(data?.row?.is_final_bill_generated && data.row.status !== "Cancelled" && data.row.status !== "Checked Out"){
      multi_option?.push({label:"Revert Final Bill",value:'revert_final_bill'})
    }

    if(data?.row?.is_emergency){
      multi_option?.push({label:"Disable Highlight",value:'ChangeTag'})
    }

    if (data?.row?.discharge_status === "Checked Out") {
      multi_option?.push({ label: t("Discharge Checkout Slip"), value: "DischargeSlip" })
    }

    if (data.row.status === "Admitted") {
      multi_option.splice(4, 0, { label: t("AdmissionCard"), value: "AdmissionCard" })
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1.5}>
        <Tooltip title={`${t('Service')} ${t('Bill')}`} placement='top' arrow>
          <div>
            <IconButton size="small" disabled={data.row.status === "Cancelled" ? true : false} onClick={() => { this.setState({ selectedOption: "ServiceBilling" }, () => this.routeToPatientDetails(data)) }}><img className='eMed_IP_AptTbl_Status_Icon' src={data.row.status === "Cancelled" ? ImagePaths.DisabledCreateBill.default : ImagePaths.BillCreated.default} alt='ServiceBill' /></IconButton>
          </div>
        </Tooltip>
        {data?.row?.advance_amount !== 0 && data?.row?.discharge_status === 'Checked Out' ?
          <Tooltip title={data.row.status === "Checked Out" ? t('Checkedout') : data.row.discharge_status === "Checked Out" ? t('Checkout') : t('Generate Final Bill')} placement='top' arrow>
            <div>
              <IconButton size="small" disabled={(data.row.status === "Cancelled" || data.row.status === "Checked Out") ? true : false}
                onClick={() => {
                  this.setState({
                    checkoutPopup: true,
                    checkOutId: data?.row?.id,
                    refundPatientData: data?.row
                  })
                }}>
                <img className='eMed_IP_AptTbl_Status_Icon' src={data.row.status === "Cancelled" || data.row.status === "Checked Out" ? ImagePaths.FrontOfficeOff.default : (data.row?.discharge_status === "Checked Out" ? ImagePaths.ReadyForCheckout.default : data.row.is_ready_for_final_bill ? ImagePaths.ipBillAction.default : ImagePaths.IpCheckoutOn.default)} alt='Checkout' />
              </IconButton>
            </div>
          </Tooltip> :
          <Tooltip title={data.row.status === "Checked Out" ? t('Checkedout') : data.row.discharge_status === "Checked Out" ? t('Checkout') : t('Generate Final Bill')} placement='top' arrow>
            <div>
              <IconButton size="small" disabled={(data.row.status === "Cancelled" || data.row.status === "Checked Out") ? true : false} onClick={() => { this.CheckoutConfirmation(data.row) }}>
                <img className='eMed_IP_AptTbl_Status_Icon' src={data.row.status === "Cancelled" || data.row.status === "Checked Out" ? ImagePaths.FrontOfficeOff.default : (data.row?.discharge_status === "Checked Out" ? ImagePaths.ReadyForCheckout.default : data.row.is_ready_for_final_bill ? ImagePaths.ipBillAction.default : ImagePaths.IpCheckoutOn.default)} alt='Checkout' />
              </IconButton>
            </div>
          </Tooltip>}
        <div>
          <Tooltip title={t("More")} placement='top' arrow>
            <IconButton
              size="small"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  selectedRow: data.id,
                  isSelected: true
                })
              }}
              disabled={data.row.status === "Cancelled" ? true : false}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          {(this.state.isSelected && this.state.selectedRow == data.id) ?
            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => {
                this.setState({ anchorEl: null, })
              }}
            >
              {multi_option.map((option, index) => (
                <MenuItem key={index}
                  disabled={(data.row.status === "Checked Out" && (option.value === "RoomChange" || option.value === "Cancel"))
                    || (this.state.selectedOption === option.value)}
                  onClick={() => {
                    this.setState({
                      selectedOption: option.value, anchorEl: null,
                      SelectedPatientDetails: data.row
                    }, () => this.routeToPatientDetails(data))
                  }}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Menu> : null}
        </div>
      </Stack>
    )
  }

  postChangeTagNormal = (item) => {
    try{
      let data = {
        ip_admission_id:item?.row?.id,
        is_emergency:false
      }
      RestAPIService.create(data,Serviceurls.FO_IP_OP_PATIENT_TAG_CHANGE_NORMAL)
      .then((response) => {
        if(response?.data?.status === 'Success'){
          this.responseSuccessErrorMessage(response.data.message,true)
          this.getPatientDetails()
        }
      }).catch((error) => {
        if(error?.response?.data?.message){
          this.responseSuccessErrorMessage(error.response.data.message,false)
        }else{
          this.responseSuccessErrorMessage(error.message,false)
        }
      })
    }catch(error){
      this.responseSuccessErrorMessage(error.message,false)
    }
  }

  routeToPatientDetails = (params) => {
    let { selectedOption } = this.state;
    let { history } = this.props;
    if (params) { setCachevalue(JSON.stringify(params.row), "IppatientData"); }

    if (params.field === 'patientDetails' || params.field === "complaints" || params.field === "doctor_name" || params.field === "room_ward_block" || params.field === "advance_amount" || params.field === "balance_amount") {
      history.push({ pathname: "/IPHome/PatientDetails/PatientDetails" })
    }

    switch (selectedOption) {
      case "createPatient":
        history.push({ pathname: "/IPHome/CreateAppointment" })
        break;
      case "DischargeSummary":
        history.push({ pathname: "/IPHome/PatientDetails/DischargeSummary" })
        break;
      case "RoomChange":
        history.push({ pathname: "/IPHome/PatientDetails/RoomChange" })
        break;
      case "ReceivePayment":
        history.push({ pathname: "/IPHome/PatientDetails/ReceivePayment" })
        break;
      case "ServiceBilling":
        history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling" })
        break;
      case "BillDetails":
        this.getPatientBillDetailedPrint(params)
        break;
      case "AdmissionCard":
        this.getAdmissionCard(params)
        break;
      case "SummaryBill":
        this.getIpSummaryBillPrint(params)
        break;
      case "ApproxBill":
        this.getApproxBillPrint(params)
        break;
      case "CommonBarcode":
        this.getCommonBarCodePrint(params)
        break;
      case 'ChangeTag':
        this.postChangeTagNormal(params)
        break;
      case 'revert_final_bill':
        this.setState({ 
          RevertFinalBillPop : true,
          RevertAdmisData : params.row,
          selectedOption: "",
        })
      case 'DischargeSlip':
        this.printDischargeSlip(params)
        break;
      default:
        break;
    }
  }

  printDischargeSlip = (params) => {
    try {
      RestAPIService.getAllPrint(Serviceurls.IP_PATIENT_DISCHARGE_SLIP + "?ip_admission_id=" + params?.row?.id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error?.response?.data?.message, false)
          } else {
            this.responseSuccessErrorMessage(error?.message, false)
          }
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
      this.setState({ selectedOption: "" })
    }
  }

  _getCheckout = (patientDetails) => {
    const { t } = this.props;
    let { open, isClicked, checkoutDateTime, isAdvanceClicked } = this.state;
    let balance_amount = "balance_amount" in patientDetails && patientDetails?.balance_amount;
    return (
      <Stack direction="column" justifyContent="center" alignItems="flex-start" sx={{ width: "100%" }}>
        <Box sx={{ display:'flex', flexDirection:'row', justifyContent:"space-between", width:'32vw'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              // open={open}
              // onOpen={() => { this.setState({ open: true }) }}
              // onClose={() => { this.setState({ open: false }) }}
              label={`${t("Checkout")} ${t("DateAndTime")}` + "*"}
              value={checkoutDateTime ? checkoutDateTime : null}
              inputFormat='dd-MM-yyyy & hh:mm a'
              minDateTime={new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)}
              maxDateTime={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)}
              onChange={(value) => {
                if (value === null) {
                  this.setState({ checkoutDateTime: new Date() })
                } else {
                  this.setState({
                    checkoutDateTime: value
                  })
                }
              }}
              renderInput={(params) => <TextField size="small" sx={{ width: "16vw" }} {...params}
              />}
            />
          </LocalizationProvider>
          <Box>
            <FormControl sx={{ width: '14vw', }} size='small'>
              <InputLabel id="group-select">{t('Discharge Type')}</InputLabel>
              <Select
                labelId="group-select"
                id="demo-simple-select"
                value={this.state.DischargeType}
                label={t('GrpbyCor')}
                readOnly
                // onChange={(event) => {
                //   this.setState({
                //     DischargeType: event.target.value
                //   })
                // }}
              >
                {
                  DischargeTypeList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box sx={{ marginBottom: "1vw", marginTop: "1vw", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          {/* <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.isAMApatient} 
          onChange={(e) => { 
            this.setState({ isAMApatient: e.target.checked },()=>{
              if(!this.state.isAMApatient){
                  this.setState({DiscComments : ""})
              }
            }) 
            }} size='small' />} label={t("AMA Patient")} /> */}
          <Box sx={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <Typography sx={{ fontSize: "0.9vw", marginRight: '0.5vw' }}>{`${t("ipsavedcountlabel")}`}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{ (this.state.SelectedPatientDetails?.saved_count) ? (this.state.SelectedPatientDetails.saved_count) : 0}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "1vw" }}>
          <TextField
            multiline={true}
            rows={2}
            label={`Comments ${this.state.DischargeType === "AMA" ? "*" : ""}`}
            sx={{ width: "32vw" }}
            value={this.state.DiscComments}
            onChange={(e) => {
              let alpha = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
              if (alpha || e.target.value === "") {
                this.setState({ DiscComments: e.target.value })
              }
            }}
          />
        </Box>
        <div>
          <Stack className="eMed_IP_checkoutDiv" direction="row" justifyContent="center" alignItems="center">
            <img className='eMed_IP_pending_Icon' src={ImagePaths.Warning.default}></img>
            <Typography sx={{ fontSize: "0.9vw", marginLeft: '1vw', color: 'maroon', fontWeight: 600 }}>{`Pharmacy Pending Amount : ${CurrencySymbol} ${patientDetails?.pharmacy_balance_amount ? patientDetails?.pharmacy_balance_amount : 0}`}
            </Typography>
          </Stack>
        </div>
        <div>
          <Stack className="eMed_IP_checkoutDiv" direction="row" justifyContent="center" alignItems="center">
            <img className='eMed_IP_pending_Icon' src={ImagePaths.Warning.default}></img>
            <Typography sx={{ fontSize: "0.9vw", marginLeft: '1vw' }}>{`${t("checkoutcomment")}`}
            </Typography>
          </Stack>
        </div>
      </Stack>
    )
  }

  checkoutPost = () => {
    let { SelectedPatientDetails, isClicked, isAdvanceClicked } = this.state;
    if (this.state.checkoutDateTime == "Invalid Date") {
      this.responseSuccessErrorMessage("Enter Correct Date & Time Formate", false)
    }else{ 
      if(this.state.DischargeType === "AMA" && this.state.DiscComments === ""){
        this.responseSuccessErrorMessage("Comments are mandatory for AMA (Against Medical Advice) patients.", false)
      }else{
        this.setState({ 
          isCheckout:false,
          // CheckoutConfirmation: true
        }, ()=>{
          this.postPatientStatus(this.state.SelectedPatientDetails.discharge_status == "Generate Bill" ? "Generate Bill" : 'Checked Out', this.state.SelectedPatientDetails.id, "", true);
        })
      }
  }
    // let hasBalance = "balance_amount" in SelectedPatientDetails && SelectedPatientDetails?.balance_amount;

    // if(!hasBalance ||( hasBalance === 0) || ( hasBalance < 0 && isClicked)){
    //   this.postPatientStatus('Checked Out', this.state.SelectedPatientDetails.id, "");
    // } else if(hasBalance > 0 && isAdvanceClicked ||((hasBalance < 0 && isClicked) && (hasBalance > 0 && isAdvanceClicked))){
    // this.postPatientStatus('Checked Out', this.state.SelectedPatientDetails.id, "", true);
    // }
    // setCachevalue(JSON.stringify(SelectedPatientDetails), "IppatientData");
    // this.props.history.push({ pathname: "/IPHome/PatientDetails/ReceivePayment", state: { isFromCheckOut: true } })
  }
  AllowCheckout() {
    this.postPatientStatus(this.state.SelectedPatientDetails.discharge_status == "Generate Bill" ? "Generate Bill" : 'Checked Out', this.state.SelectedPatientDetails.id, "", true);
  }

  checkoutPopUpClose = () => {
    this.setState({
      isCheckout: false,
      isClicked: false,
      isAdvanceClicked: false,
      SelectedPatientDetails: {},
      checkoutDateTime: new Date(),
      ApproxAmount: 0,
      CheckoutConfirmation: false,
      isAMApatient: false,
      DiscComments:"",
    })
  }



  reasonPopupClose = () => {
    this.setState({
      selectedOption: ""
    })
  }
  addCancelComments = (comments) => {
    var states = this.state
    this.postPatientStatus('Cancelled', states.SelectedPatientDetails.id, comments)
    this.setState({
      selectedOption: "",
      SelectedPatientDetails: {},
    })
  }


  stringOrNumberSort = (v1, v2) => {
    let sorted = gridStringOrNumberComparator(v1, v2);
    return sorted;
  }

  PaymentStatus(BillAmount, ReceivedAmount, BalanceAmount, IsForCheckOut) {
    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PaymentsIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: '#616161' }} />
          <Typography fontSize={"0.8vw"}>{`Bill : ${AmountFormat(BillAmount)} `}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: 'green' }} />
          <Typography color={"green"} fontSize={"0.8vw"}>{`Paid : ${AmountFormat(ReceivedAmount)} `}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ReportIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: IsForCheckOut ? "#e18223" : "#B8170D" }} />
          <Typography color={IsForCheckOut ? "#e18223" : "#B8170D"} fontSize={"0.8vw"}>{`${IsForCheckOut ? "Credit" : "Balance"} : ${AmountFormat(BalanceAmount ? (BalanceAmount < 0 ? (BalanceAmount * -1) : BalanceAmount) : 0)} `}</Typography>
        </Box>
      </Box>
    )
  }

  renderAptTable = () => {
    const { t } = this.props;
    let states = this.state;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    
    const columns = UserData?.module_name !== 'nurse' ? [
      { field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmission={true} />), density: 'compact' },
      {
        field: 'complaints', headerName: t('IPRecords'), headerClassName: 'eMed_Apt_TblHead', flex: 0.17, density: 'compact',
        renderCell: ({ row }) => (
          <div>
            <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600' }}>{'IP :' + (row.ip_number ? row.ip_number : '-')}</Typography></div>
            <div>{row.complaints && row.complaints?.length > 18 ?
              <Tooltip title={row.complaints ? row.complaints : null} placement={'top'} arrow>
                <Typography id='eMed_OP_AptTbl_AptText1'>{'Diagnosis :' + (row.complaints ? row.complaints.slice(0, 15) + '...' : '-')}</Typography>
              </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'Diagnosis :' + (row.complaints ? row.complaints : '-')}</Typography>} </div>
            <Typography id='eMed_OP_AptTbl_AptText'>{'Medico-Legal :' + (row.medico_legal_case ? "Yes" : "No")}</Typography>
          </div>),
      },
      {
        field: 'doctor_name', headerName: t('Consultant/Tag'), headerClassName: 'eMed_Apt_TblHead', flex: 0.14, density: 'compact',
        renderCell: ({ row }) => {
        return(
          <div>
          <div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div style={{fontSize:'0.9vw',fontWeight:'600'}}>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? <Typography fontSize={'0.9vw'} fontWeight={600}>{row.doctor_name}</Typography> : "-"}</div>
          <div style={{fontSize:'0.8vw' , fontWeight: "bold" , color : row.patient_tags__patient_tag_color ? row.patient_tags__patient_tag_color : "#000000" }}>{`Tag : ${row?.patient_tags__patient_tag_name ? row?.patient_tags__patient_tag_name : '-'}`}</div>
          </div>
        )}
      },
      // {
      //   field: 'room_ward_block', headerName: t('Bed/Room/Ward/Block'), headerClassName: 'eMed_Apt_TblHead', flex: 0.15, density: 'compact',
      //   renderCell: ({ row }) => {
      //     let room_ward_block = `${row.bed_number ? row.bed_number : "-"}/${row.room_number ? row.room_number : "-"}/${row.ward_name ? row.ward_name : "-"}/${row.block_name ? row.block_name : "-"}`
      //     return (<div>{room_ward_block.length > 25 ? <Tooltip placement="top" title={room_ward_block} arrow><div>{room_ward_block.slice(0, 23) + "..."}</div></Tooltip> : room_ward_block ? room_ward_block : "-"}</div>)
      //   }, valueGetter: ({ row }) => row?.ward_name, sortComparator: this.stringOrNumberSort, sortingOrder: ['asc', 'desc']
      // },
      {
        field: 'room_ward_block', headerName: t('Bed/Room/Ward/Block'), headerClassName: 'eMed_Apt_TblHead', flex: 0.15, density: 'compact',
        renderCell: ({ row }) => (<div>{row?.room_ward_block?.length > 20 ? <Tooltip placement="top" title={row?.room_ward_block} arrow><div>{row?.room_ward_block.slice(0, 18) + "..."}</div></Tooltip> : row.room_ward_block ? row.room_ward_block : "-"}</div>)
      },
      {
        field: 'advance_amount', headerName: t('Advance Amount'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact', type: "number",
        renderCell: ({ row }) => {
          let amount = row.advance_amount && AmountFormat(row.advance_amount)
          return (<Typography paddingRight={"1vw"}>{amount ? amount : `${CurrencySymbol} 0.00`}</Typography>)
        }
      },
      {
        field: 'balance_amount', headerName: t('Payment Status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact',
        renderCell: ({ row }) => (
          <div>{this.PaymentStatus(row?.net_amount ? row?.net_amount : 0, row?.paid_amount ? row?.paid_amount : 0, row?.balance_amount ? row?.balance_amount : 0, row?.discharge_status === "Checked Out")}</div>
        )
      },
      {
        field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
        renderCell: (params) => (<div>{this.ActionBtn(params)}</div>)
      }
    ] : [
      {
        field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (
          <Box component={'div'}>
            <CommonPatientDetails data={row} showAdmission={true} />
          </Box>
        ), density: 'compact'
      },
      {
        field: 'complaints', headerName: t('IPRecords'), headerClassName: 'eMed_Apt_TblHead', flex: 0.17, density: 'compact',
        renderCell: ({ row }) => (
          <div>
            <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600' }}>{'IP :' + (row.ip_number ? row.ip_number : '-')}</Typography></div>
            <div>{row.complaints && row.complaints?.length > 18 ?
              <Tooltip title={row.complaints ? row.complaints : null} placement={'top'} arrow>
                <Typography id='eMed_OP_AptTbl_AptText1'>{'Complaints :' + (row.complaints ? row.complaints.slice(0, 15) + '...' : '-')}</Typography>
              </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'Complaints :' + (row.complaints ? row.complaints : '-')}</Typography>} </div>
            <Typography id='eMed_OP_AptTbl_AptText'>{'Medico-Legal :' + (row.medico_legal_case ? "Yes" : "No")}</Typography>
          </div>),
      },
      {
        field: 'doctor_name', headerName: t('Consultant/Tag'), headerClassName: 'eMed_Apt_TblHead', flex: 0.14, density: 'compact',
        renderCell: ({ row }) => {
        return(
          <div>
          {row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}
          {row?.patient_tags__patient_tag_name ? row?.patient_tags__patient_tag_name : "-"}
          </div>
        )}
      },
      {
        field: 'room_ward_block', headerName: t('Room/Ward/Block'), headerClassName: 'eMed_Apt_TblHead', flex: 0.15, density: 'compact',
        renderCell: ({ row }) => {
          let room_ward_block = `${row.room_number ? row.room_number : "-"}/${row.ward_name ? row.ward_name : "-"}/${row.block_name ? row.block_name : "-"}`
          return (<div>{room_ward_block.length > 20 ? <Tooltip placement="top" title={room_ward_block} arrow><div>{room_ward_block.slice(0, 18) + "..."}</div></Tooltip> : room_ward_block ? room_ward_block : "-"}</div>)
        }, valueGetter: ({ row }) => row?.ward_name, sortComparator: this.stringOrNumberSort, sortingOrder: ['asc', 'desc']
      },
      {
        field: 'advance_amount', headerName: t('Advance Amount'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact', type: "number",
        renderCell: ({ row }) => {
          let amount = row.advance_amount && AmountFormat(row.advance_amount)
          return (<Typography paddingRight={"1vw"}>{amount ? amount : `${CurrencySymbol} 0.00`}</Typography>)
        }
      },
      {
        field: 'balance_amount', headerName: t('Payment Status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact', headerAlign: 'center', align: "center",
        renderCell: ({ row }) => (
          <div>{this.PaymentStatus(row?.net_amount ? row?.net_amount : 0, row?.paid_amount ? row?.paid_amount : 0, row?.balance_amount ? row?.balance_amount : 0, row?.discharge_status === "Checked Out")}</div>
        )
      },
      // {
      //   field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
      //   renderCell: (params) => (<div>{this.ActionBtn(params)}</div>)
      // }
    ]
    return (
      <div>
        <Box className='eMed_IPAptTbl_Con'>
          <DataGrid
            rows={states["IpPatientData"]}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={this.state.pageSize}
            page={this.state.page}
            rowsPerPageOptions={[10, 20, 30, 50]}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            density={'compact'}
            rowHeight={100}
            // onCellClick={(params) => params.row.status === "Cancelled" ? null : this.routeToPatientDetails(params)}
            onCellClick={(params) => {
              if (params.field === "patientDetails" || params.field === "complaints" || params.field === "doctor_name" || params.field === "room_ward_block" || params.field === "advance_amount" || params.field === "balance_amount") {
                this.routeToPatientDetails(params)
              }
            }}
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
      </div>
    )
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, () => {
      this.getPatientDetails()
    })
  }
  msgClose() {
    this.setState({
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: ''
    })
  }

  GetWardList() {
    try {
      RestAPIService.getAll(Serviceurls.WARD_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              WardList: response.data.data
            })
          }
          else {
            this.responseSuccessErrorMessage(response?.data?.message, false)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error?.response?.data?.message, false)
          } else {
            this.responseSuccessErrorMessage(error?.message, false)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.responseSuccessErrorMessage(error?.response?.data?.message, false)
      } else {
        this.responseSuccessErrorMessage(error?.message, false)
      }
    }
  }

  renderCorporateLeft = () => {
    try {
      this.state.WardList.forEach((element, index) => { element.sno = index + 1 })

      const { t } = this.props
      const columns = [
        { field: "sno", flex: 0.08, headerName: t("SNo") },
        {
          field: "ward_name", flex: 0.22, headerName: t("WardName"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.ward_name ? params?.row?.ward_name?.length > 20 ?
              <Tooltip placement='top' title={params?.row?.ward_name}>
                <div>{params?.row?.ward_name.slice(0, 20) + "..."}</div></Tooltip> :
              params?.row?.ward_name : "-"}</Box>)
        },
        {
          field: "day_ren", flex: 0.15, headerName: t('RentPerDay') + ` (${CurrencySymbol})`, type: "number",
          renderCell: (params) => (<Box component={'div'}>{params?.row?.day_rent ?
            params?.row?.day_rent?.total
            : "-"}</Box>)
        },
        {
          field: "hourly_rent", flex: 0.3, headerName: t('RentHourly') + ` (${CurrencySymbol})`, type: "number",
          renderCell: (params) => {
            let HoursSplitTotal = "";
            if (params?.row?.hourly_rent) {
              params?.row?.hourly_rent.forEach((item) => (
                HoursSplitTotal += `${item.hourType} - ${CurrencySymbol} ${item.total} , `
              ))
            } else {
              HoursSplitTotal = "-"
            }
            return (
              <Box component={'div'}>{
                params?.row?.hourly_rent ?
                  HoursSplitTotal?.length > 30 ?
                    <Tooltip placement='top' title={HoursSplitTotal} arrow>
                      <div>{HoursSplitTotal.slice(0, 30) + "..."}</div></Tooltip> :
                    HoursSplitTotal : "-"}</Box>
            )
          }
        },
        {
          field: "attender_rent", flex: 0.2, headerName: t('Attender Rent/Day') + ` (${CurrencySymbol})`, type: "number",
          renderCell: (params) => {
            let AttenderRent = params?.row?.attender ? params.row?.attender_rent?.total : "Only for Patient"
            return <Box component={'div'}>{AttenderRent}</Box>
          }
        },
      ]
      return (
        <Stack component={"div"}>
          <div style={{ width: "57vw", height: "55vh" }}>
            <DataGrid
              rows={this.state.WardList}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            />
          </div>
        </Stack>
      )
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  CheckoutPopClose(isPatientCheckout = false) {
    this.setState({
      SelectedPatientDetails: {},
      isCheckout: false,
      ApproxAmount: 0
    }, () => {
      if (isPatientCheckout) {
        this.setState({ VacantPopOpen: true })
        setTimeout(() => { this.setState({ VacantPopOpen: false }) }, 3000)
      }
    })
  }

  revertFinalBillPost = (isForApproval) => {
    if (isForApproval) {
      let AdmisssionData = this.state?.RevertAdmisData;
      let startDate = new Date()
      let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}`

      let data = {
        'patient_id': AdmisssionData?.patient_id,
        // 'login_user_id': request.user.id,
        'ip_admission_id': AdmisssionData?.id,
        'request_date': invoice_date,
        'status': "Pending",
        'remarks': this.state.RevertFinalReason,
      }
      RestAPIService.create(data, Serviceurls.REVERT_FINAL_BILL)
        .then(response => {
          if (response.data.status === "success") {
            this.props.getmakerCount(true);
            this.getPatientDetails()
            this.responseSuccessErrorMessage(response.data.message, true);
            this.setState({
              RevertAdmisData: null,
              RevertFinalBillPop: false,
              RevertFinalReason: "",
            })
          }
        }).catch(e => {
          if (e.response.data.status === "fail") {
            this.responseSuccessErrorMessage(e.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(e.message, false)
          }
        })
    } else {
      let AdmisssionData = this.state?.RevertAdmisData;
      let data = {
        'ip_admission_id': AdmisssionData?.id,
      }
      this.setState({ disable: true })
      RestAPIService.create(data, Serviceurls.FINAL_BILL_REVERT_POST)
        .then(response => {
          if (response.data.status === "success") {
            this.props.getmakerCount(true);
            this.getPatientDetails()
            this.responseSuccessErrorMessage(response.data.message, true);
            this.setState({
              RevertAdmisData: null,
              RevertFinalBillPop: false,
              RevertFinalReason: "",
            })
          }
        }).catch(e => {
          this.setState({ disable: false })
          if (e.response.data.status === "fail") {
            this.responseSuccessErrorMessage(e.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(e.message, false)
          }
        })
    }
  }

  RevertFinalBillPopUpScrn = () => {
    let isForApproval = (this.state.LoggedData?.is_user && !this.state.LoggedData?.permission_access?.common?.revert_final_bill_access)
    return (
      <Dialog
        open={this.state.RevertFinalBillPop}
        // onClose={()=>{ this.s}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Revert Final Bill"}
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ width: "30vw", marginTop: "10px" }}
            multiline
            value={this.state.RevertFinalReason}
            rows={4}
            label={"Reason for revert *"}
            onChange={(e)=>{ this.setState({ RevertFinalReason : e.target.value})}}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'capitalize' }} size='small' variant='outlined'
            onClick={() => {
              this.setState({
                RevertAdmisData: null,
                RevertFinalBillPop: false,
                RevertFinalReason: "",
              })
            }}
          >{"Cancel"}</Button>
          <Button sx={{ textTransform: 'capitalize' }} size='small' variant="contained" onClick={() => { this.revertFinalBillPost(isForApproval) }} disabled={!this.state.RevertFinalReason}>
            {isForApproval ? "Send Request" : "Revert Bill"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { t } = this.props;
    let { SelectedPatientDetails, isClicked, isAdvanceClicked } = this.state;
    var UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    let isBalance = "balance_amount" in SelectedPatientDetails;
    let balance_amount = SelectedPatientDetails?.balance_amount;
    let disablecheckoutBtn = (isBalance && (balance_amount < 0 && !isClicked || balance_amount > 0 && !isAdvanceClicked))
      || ((isBalance && balance_amount < 0 && !isClicked) && (balance_amount > 0 && !isAdvanceClicked));

    return (
      <div id='eMed_IP_Appointment'>
        {/* <div className='eMed_IP_AptHead'>
          <div className='eMed_Price_Card_Icon'>
            <p>{t('InPatientList')}</p>
            {UserData?.is_user == true ? <div style={{ paddingLeft: "1vw" }}>
              <Button variant="contained" onClick={() => { this.setState({ WardPricePopup: true }) }} endIcon={<CurrencyRupeeIcon />}>
                {t('Room Price')}
              </Button>
            </div> : null}
          </div>
          <Box className='eMed_IP_AptDateFlt'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
        </div> */}
        {this.renderPatientType()}
        {this.renderAptTable()}
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          (this.state.isCheckout) ?
            <CommonPopUp width={"35vw"}
              popUpClose={this.checkoutPopUpClose.bind(this)}
              title={t("Checkout")} 
              justifyRight={true}
              component={this._getCheckout.bind(this, this.state.SelectedPatientDetails)}
              buttonOne={t("Close")}
              buttonOneAction={this.checkoutPopUpClose.bind(this)}
              buttonTwo={`${t(this.state.SelectedPatientDetails.discharge_status === "Checked Out" ? " Checkout" : "Generate Final Bill")}`}
              buttonTwoAction={this.checkoutPost.bind(this)}
              disableBtn2={this.state.disable}
              RestrictDisableBtn={true}
              />
            : null
        }
        {
          this.state.selectedOption === "Cancel" ? <ReasonPopup
            title={"Are you sure? Do you want to cancel the admission"}
            AlertPopupClose={this.reasonPopupClose.bind(this)}
            label={"Enter the Reason"}
            btntext={'Cancel'}
            btnvarient={'outlined'}
            btncolor={'error'}
            btntext1={'Confirm'}
            btnvarient1={'contained'}
            sendCmt={this.addCancelComments.bind(this)} /> : null
        }
        {this.state.WardPricePopup && UserData?.is_user == true ?
          <Modal open={this.state.WardPricePopup}>
            <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "58.5%" }}>
              <div className="emedhub_Price_popup_header" style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : Colors.DataTblHeaderbg }}>
                <Typography marginLeft={"1vw"}>{t("Ward Price Details")}</Typography>
                <Button onClick={() => { this.setState({ WardPricePopup: false }) }} >
                  <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
              </div>
              {this.renderCorporateLeft()}
            </Paper>
          </Modal>
          : null}
        {this.state.VacantPopOpen ? <VacentRoomPopup /> : null}
        {this.state.CheckoutConfirmation ? <DeletePopup
          DeletTitle={this.state.SelectedPatientDetails.discharge_status === "Checked Out" ? `Are you Sure. You Want to Checkout?` : ` Are you sure to Generate Final Summary Bill?`}
          deleteAlertPopupClose={this.checkoutPopUpClose.bind(this)}
          removeData={this.AllowCheckout.bind(this)}
          ButtonText={'Yes'}
          disable={this.state.disable}
        /> : null}
        {this.state.checkoutPopup ? this.checkoutScreenPopup() : null}
        {this.RevertFinalBillPopUpScrn()}
      </div>

    )
  }
}
export default withTranslation()(IPpatientsHome);