import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';

class LowStockDrug extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lowStockData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                'brandName':'',
                "dosageType": [],
                "days":91
            },
            pageName: 'lowStockDrug',
            filterOpen: false,
        }
    }

    componentDidMount() {
        this.getLowStockData()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getLowStockData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            // FilterTextArray={TextArray}
            <Box>
                <CommonGridToolBarWithFilterText  />
            </Box>

        )
    }

    getLowStockData = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_LOW_STOCK_REPORT_GET + `?brand_name=${brandName}&dosage_type=${this.state.filterDatas.dosageType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            lowStockData: response.data.data
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getLowStockPrint = () => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_LOW_STOCK_REPORT_GET + `?brand_name=${this.state.filterDatas.brandName}&dosage_type=${this.state.filterDatas.dosageType}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    render() {
        const { t } = this.props
        this.state.lowStockData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'drug_name', headerName: "Brand Name", flex: 0.167,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.drug_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.drug_name ? params?.row?.drug_name : '-'}
                </Box>)
            },
            {
                field: 'dosage_type', headerName: "Dosage Type", flex: 0.167,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.dosage_type?.length > 12 ? <Tooltip placement='top' title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.dosage_type ? params?.row?.dosage_type : '-'}
                </Box>)
            },
            {
                field: 'dosage_strength', headerName: "Dosage Strength", flex: 0.167, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.dosage_strength?.length > 12 ? <Tooltip placement='top' title={params?.row?.dosage_strength} arrow><div>{params?.row?.dosage_strength?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.dosage_strength ? params?.row?.dosage_strength : '-'}
                </Box>)
            },
            // {
            //     field: 'batch_no', headerName: "Batch No.", flex: 0.167, headerAlign: "center", align: "center",
            //     renderCell: (params) => (<Box component={'div'}>
            //         {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
            //             params?.row?.batch_no ? params?.row?.batch_no : '-'}
            //     </Box>)
            // },
            {
                field: 'reorder_threshold', headerName: `Reordered Threshold`, flex: 0.167, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params.row?.reorder_threshold ? params.row?.reorder_threshold : '0'}</Box>)
            },
            {
                field: 'available_quantity', headerName: `Available Quantity`, flex: 0.167, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params.row?.available_quantity ? params.row?.available_quantity : '0'}</Box>)
            }
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header' sx={{ height : "7vh"}}>
                    <Box component={'div'} flex={0.8} display={'flex'}>

                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid = "rpt_lowStk_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid = "rpt_lowStk_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={()=>{this.getLowStockPrint()}}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card' sx={{ height : "72vh"}}>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.lowStockData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.lowStockData?.length}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDosType={true}/>
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(LowStockDrug)