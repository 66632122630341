import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton,
    InputAdornment, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TextField, Tooltip, Typography, Modal, Radio, Dialog
} from '@mui/material'
import { ImagePaths } from '../../../Utility/ImagePaths'
import {
    BillSuccessPoPUp, CommonCorporateAndInsuranceDetails, CommonPatientDetails,
    PharmaDiscardPop, ReasonPopupWithRefund
} from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import Loader from '../../../Components/Loader';
import PrescriptionHistoryPop from '../../Pharmacy/PharmacyHome/PrescriptionHistoryPop'
import { clearCachevalue, getCachevalue, localGetItem, localSetItem } from '../../../Utility/Services/CacheProviderService';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReturnMultipleBill from '../../Pharmacy/PharmacyHome/ReturnMultipleBill'
import { CurrencySymbol } from '../../../Utility/Constants'
import AddCircleIcon from '@mui/icons-material/AddCircle';

let RoleData = null;

class PharmacyBilling extends Component {
    constructor(props) {
        super(props)
        this.QuantityRef = React.createRef();
        this.DrugNameRef = React.createRef();
        this.state = {
            discount: '',
            batchNo: '',
            drugDetailsData: [],
            pharmacyTblData: [
                {
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                    gst_percentage: null,
                }
            ],
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            isInsurance: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            InsuranceAmount: "",
            InsuCompanyName: "",
            InsuPolicyNumber: "",
            InsuTransactionNumber: "",
            InsuValidity: '',
            subAmount: 0,
            totalPercentage: '',
            totalDiscount: '',
            roundOff: 0,
            netAmount: 0,
            creditPayment: false,
            receivePayment: false,
            savedBill: false,
            isCredit: false,
            DurgNameCurrentIndex: null,
            QuantityCurrentIndex: null,
            PatientDetails: {},
            BillDetails: {},
            PatientSearchClicked: false,
            SearchedPatientList: [],
            DoctorsList: [],
            doctordetails: {},
            PatientMobileNumber: "",
            PatientName: "",
            invoiceDateTime: new Date(),
            Patient_Id: null,
            Bill_ID: null,
            DateTimerOpen: false,
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            isFromInvoice: false,
            isForReturn: false,
            isFromSavedBill: false,
            isForInvoiceEdit: false,
            isFromIpRequest: false,
            isFromIpReturn: false,
            isFromPrescription: false,
            CompletedBillID: null,
            ip_transmit_id: null,
            LoggedRole: null,
            totalBuyingPrice: 0,
            ShowHistory: false,
            SearchingDrugIndex: false,
            SearchingBatchIndex: false,
            isBillsFetched: false,
            InvoiceNumber: null,
            CorpCompanyName: "",
            CorpIDNumber: "",
            PrescHistoryData: null,
            invoiceNumber: '',
            OutStandingAmount: 0,
            BillReceivedAmount: 0,
            outstandingAmt: 0,
            ReturnedNetAmount: 0,
            totalBillNetAmount: 0,
            ReceiptAmt: 0,
            AdvanceAmt: 0,
            isCurrentIP: false,
            addToAdvance: false,
            isReturnClicked: false,
            prescription_transmitted_id: null,
            typeDocName: '',
            selectedBatchListData: [],
            MasterBillData: {},
            perAccess: {},
            isCredit_or_isPaid: true,
            DateTimeChanged: false,
            discountReasonPopup: false,
            discountReason: '',
            isSavedReason: false,
            common_uhid: false,
            creditChange: false,
            addInsurance: false,
            addCorporate: false,
            paymentCreditType: '',
            corpName: null,
            employeeNo: '',
            insuranceName: null,
            insuranceNo: '',
            CropName: '',
            CropNo: '',
            InsName: '',
            InsNO: '',
            // History State
            prescriptionList: [],
            selectedPharmacy: null,
            pharmaList: [],
            isDataFetched: false,
            cancelBillClicked: false,
        }
    }

    componentDidMount() {
        // Setting Logged User Role as String in state(LoggedRole)
        RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        if (RoleData.is_super_admin) { this.setState({ LoggedRole: "super_admin" }) }
        else if (RoleData.is_branch_admin) { this.setState({ LoggedRole: "branch_admin" }) }
        else if (RoleData.is_user) {
            this.setState({ LoggedRole: "user", perAccess: RoleData?.permission_access })
        }
        this.setState({
            pharmaList: RoleData?.pharmacy_data?.length > 0 ? RoleData?.pharmacy_data : [],
            selectedPharmacy: RoleData?.pharmacy_data?.length > 0 ? RoleData?.pharmacy_data[0] : null
        }, () => {
            // get Doctor List
            const pharmaId = RoleData?.pharmacy_data?.length > 0 ? RoleData?.pharmacy_data[0]?.pharmacy_id : ""
            this.getDoctorsList(pharmaId)
        })

        if (this.props?.history?.location?.state?.ApprovalData && this.props?.history?.location?.state?.ApprovalData?.bill_id != this.state.ApprovalData?.bill_id) {
            let billID = this.props?.history?.location?.state?.ApprovalData?.bill_id ? this.props?.history?.location?.state?.ApprovalData?.bill_id : null
            let patientID = this.props?.history?.location?.state?.ApprovalData?.patient_id ? this.props?.history?.location?.state?.ApprovalData?.patient_id : null
            this.setState({
                ApprovalData: this.props?.history?.location?.state?.ApprovalData,
                selectedPharmacy: this.props?.history?.location?.state?.ApprovalData?.module_id ? RoleData?.pharmacy_data?.find(list => list.pharmacy_id === this.props?.history?.location?.state?.ApprovalData?.module_id) : null
            }, () => {
                this.GetPatientDetails(patientID, true)
                this.GetBillingDetails(Serviceurls.PHARMA_BILLS_GET + "?id=" + billID, false, true)
            })
        }


        this.intervalId = setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
            if (!this.state.DateTimeChanged) {
                const newMinute = new Date().getMinutes();
                if (this.state.invoiceDateTime?.getMinutes() && this.state.invoiceDateTime?.getMinutes() !== newMinute) {
                    this.setState({
                        invoiceDateTime: new Date(),
                    });
                }
            }
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        const { history } = this.props;
        const history_state = history?.location?.state;
        const patient_data = history_state?.selectedPatientDetails;

        if (this.props !== prevProps && (history_state ? Object.keys(history_state)?.length > 0 : false)) {
            if (history_state?.isFromInvoice && !this.state.isDataFetched ) {
                this.setState({
                    Patient_Id: history_state?.Patient_ID || null,
                    selectedPharmacy: {
                        "pharmacy_id": history_state?.billDetails?.pharmacy_id || history_state?.billDetails?.module_id || history_state?.request_data?.pharmacy_id,
                        "pharmacy_name": history_state?.billDetails?.pharmacy_name || this.state.pharmaList?.find(item => item.pharmacy_id === history_state?.billDetails?.module_id || history_state?.request_data?.pharmacy_id)?.pharmacy_name || "",
                    },
                    Bill_ID: history_state?.Bill_ID || null,
                    PatientName: history_state?.billDetails?.billing_customer_name ? history_state?.billDetails?.billing_customer_name : history_state?.billDetails?.patient_name,
                    PatientMobileNumber: history_state?.billDetails?.billing_customer_mobile_no ? history_state?.billDetails?.billing_customer_mobile_no : history_state?.billDetails?.patient_mobile,
                    BillDetails: history_state?.billDetails,
                    isFromInvoice: history_state?.isFromInvoice ? history_state?.isFromInvoice : false,
                    isForCancel: history_state?.isForCancel ? history_state?.isForCancel : false,
                    isDataFetched: true,
                    isFromIpBills: history_state?.isFromIpBills ? history_state?.isFromIpBills : false,
                    isFromOpBills: history_state?.isFromOpBills ? history_state?.isFromOpBills : false,
                }, () => {
                    this.GetPatientDetails(history_state?.Patient_ID, true)
                    this.GetBillingDetails(Serviceurls.PHARMA_BILLS_GET + "?id=" + history_state?.Bill_ID, false, true)
                })
            } else if (patient_data?.patient_id && patient_data?.patient_id !== this.state.Patient_Id) {
                this.setState({
                    Patient_Id: patient_data?.patient_id || null,
                }, () => {
                    this.GetPatientDetails(patient_data?.patient_id, true)
                })
            }
        }
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    GetPatientDetails(Patient_id, RefreshTable = true, from_presc = false) {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHARMA_PATIENT_DETAILS_GET + "?patient_id=" + Patient_id
                + "&pharmacy_id=" + this.state.selectedPharmacy?.pharmacy_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        if (!from_presc && !this.state.isFromIpRequest) {
                            this.setState({
                              doctordetails: this.state.DoctorsList?.find((item) => (item?.doctor_id === response.data.data?.doctor_id))
                            })
                          }
                        if (response.data.data?.primary_payment_type == "Insurance credit") {
                            this.setState({
                                InsName: response.data.data?.insurance_company_name ? response.data.data?.insurance_company_name : '',
                                InsNO: response.data.data?.policy_number ? response.data.data?.policy_number : '',
                            })
                        } else if (response.data.data?.primary_payment_type == "Corprate credit") {
                            this.setState({
                                CropName: response.data.data?.company_name ? response.data.data?.company_name : '',
                                CropNo: response.data.data?.id_no ? response.data.data?.id_no : '',
                                CompanyID: response.data.data?.employer_id ? response.data.data?.employer_id : null,
                            })
                        }

                        this.setState({
                            PatientDetails: response.data.data,
                            paymentCreditType: response.data.data?.primary_payment_type,
                            PatientName: `${response.data.data?.first_name} ${response.data.data?.last_name ? response.data.data?.last_name : ""}`,
                            PatientMobileNumber: response.data.data?.mobile_number,
                            ReceivedCashAmount: "",
                            CashBalanceAmount: "",
                            totalPercentage: '',
                            totalDiscount: '',
                            discountReason: '',
                            isSavedReason: false,
                            invoiceDateTime: new Date(),
                            DateTimeChanged: false,
                            pharmacyTblData: RefreshTable ? [
                                {
                                    drug_name: '',
                                    dosage_strength: '',
                                    dosage_type: '',
                                    batch_no: '',
                                    expiry_date: '',
                                    physical_box_no: '',
                                    cost_per_quantity: '',
                                    quantity: '',
                                    total_cost: '',
                                    discount: '',
                                    total_price: '',
                                    hsn_code: '',
                                    stock_in_quantity: '',
                                    gst_percentage: null,
                                    Drug_sugg_list: [],
                                }
                            ] : this.state.pharmacyTblData,

                        }, () => {
                            if (this.state.ShowHistory && this.state.PrescHistoryData === null) {
                                this.GetPrescpHistoryData()
                                this.getPrescriptionListData()
                            }
                            this.LoaderFunction(false)
                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data?.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error?.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    GetBillingDetails(URL, ExtraLineItem = false, FetchPaymentDetails = false, TableData = null) {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(URL)
                .then((response) => {
                    if (response.data.status === "success") {
                        let EmptyLine = [
                            {
                                drug_name: '',
                                dosage_strength: '',
                                dosage_type: '',
                                batch_no: '',
                                expiry_date: '',
                                physical_box_no: '',
                                cost_per_quantity: '',
                                quantity: '',
                                total_cost: '',
                                discount: '',
                                total_price: '',
                                hsn_code: '',
                                stock_in_quantity: '',
                                Drug_sugg_list: [],
                                gst_percentage: null,
                            }
                        ]

                        let LineItems = []
                        response.data.data?.bill_line_items?.forEach((item) => {
                            item["drug_name"] = item?.brand_name
                            item["discount"] = item?.discount_percentage
                            item["total_price"] = item?.net_total_amount
                            LineItems.push(item)
                        })
                        this.setState({
                            pharmacyTblData: TableData ? TableData : (ExtraLineItem ? [...LineItems, ...EmptyLine] : LineItems),
                            totalPercentage: response.data.data?.general_discount_percentage,
                            totalDiscount: this.state.isFromIpReturn ? CommonValidation.calculatePercentageWithoutRoundoff(response.data.data?.general_discount_percentage, this.state.subAmount) : response.data.data?.general_discount,
                            discountReason: response.data.data?.remarks ? response.data.data?.remarks : '',
                            invoiceDateTime: this.state.isFromSavedBill ? new Date() : response.data.data?.invoice_date,
                            DateTimeChanged: this.state.isFromSavedBill ? false : true,
                            doctordetails: (response.data.data?.doctor_name && response.data.data?.doctor_name !== "-") ? { displayKey: `${response.data.data?.doctor_name} | ${response.data.data?.doctor_reg_no}`, doctor_id: response.data.data?.doctor_id, doctor_reg_no: response.data.data?.doctor_reg_no, name: response.data.data?.doctor_name } : {},
                            isBillsFetched: true,
                            InvoiceNumber: response.data.data?.invoice_number ? response.data.data?.invoice_number : response.data.data?.temp_invoice_number,
                            // OutStandingAmount: response.data.data?.total_outstanding_amt ? response.data.data?.total_outstanding_amt : 0,
                            BillReceivedAmount: response.data.data?.paid_amount ? response.data.data?.paid_amount : 0, // hard coded "received amount key"
                            outstandingAmt: response.data.data?.outstanding_amount ? response.data.data?.outstanding_amount : 0,
                            totalBillNetAmount: response.data.data?.net_amount ? response.data.data?.net_amount : 0,
                            isCurrentIP: response.data.data?.is_current_ip,
                            FromMultiReturn: response.data.data?.return_status === "Multiple Bill Return" ? true : false
                        }, () => {
                            if ((getCachevalue("ForAdminApproval") === "true")) {
                                let ApprovalData = JSON.parse(getCachevalue("AdminApprovalData"))
                                if (ApprovalData?.request_discount_amount !== this.state.totalDiscount && ApprovalData?.request_discount_amount) {
                                    this.setState({
                                        totalDiscount: ApprovalData?.request_discount_amount,
                                        totalPercentage: ApprovalData?.request_discount_percent,
                                        discountReason: ApprovalData?.remarks,
                                    })
                                }
                            }
                            if (this.state.discountReason !== "") {
                                this.setState({ isSavedReason: true })
                            }
                            if (FetchPaymentDetails) {
                                let PaymentMode = response.data.data?.payment_modes ? response.data.data?.payment_modes : null
                                this.FetchPaymentMode(PaymentMode)
                                this.setState({
                                    CashAmount: response.data.data?.cash_amount ? response.data.data?.cash_amount : "",
                                    CardAmount: response.data.data?.card_amount ? response.data.data?.card_amount : "",
                                    UPIAmount: response.data.data?.upi_amount ? response.data.data?.upi_amount : "",
                                    BankTfrAmount: response.data.data?.bank_amount ? response.data.data?.bank_amount : "",
                                    ChequeAmount: response.data.data?.cheque_amount ? response.data.data?.cheque_amount : "",
                                    InsuranceAmount: response.data.data?.insurance_amount ? response.data.data?.insurance_amount : "",
                                    CardTransactionNumber: response.data.data?.card_number ? response.data.data?.card_number : "",
                                    UPINumber: response.data.data?.transaction_id ? response.data.data?.transaction_id : "",
                                    BankTfrNumber: response.data.data?.reference_no ? response.data.data?.reference_no : "",
                                    InsuCompanyName: response.data.data?.insurance_company_name ? response.data.data?.insurance_company_name : "",
                                    InsuPolicyNumber: response.data.data?.policy_number ? response.data.data?.policy_number : "",
                                    InsuTransactionNumber: response.data.data?.transaction_number ? response.data.data?.transaction_number : "",
                                    InsuValidity: response.data.data?.validity ? response.data.data?.validity : "",
                                })
                            } else {
                                this.setState({
                                    isCard: false,
                                    isCash: false,
                                    isUPI: false,
                                    isBankTranfer: false,
                                    isCheque: false,
                                    isInsurance: false,
                                    CashAmount: "",
                                    CardAmount: "",
                                    UPIAmount: "",
                                    BankTfrAmount: "",
                                    ChequeAmount: "",
                                    InsuranceAmount: "",
                                })
                            }
                        })
                        this.calculateMarginProfit()
                        this.LoaderFunction(false)
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data?.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error?.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    FetchPaymentMode = (paymentModes) => {
        try {
            let states = this.state
            const modeToState = {
                1: "isCash",
                2: "isCard",
                3: "isUPI",
                5: "isBankTranfer",
                6: "isInsurance"
            };
            paymentModes?.forEach(mode => {
                const stateKey = modeToState[mode];
                if (stateKey) {
                    states[stateKey] = true;
                }
            });
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorsList(pharmaId) {
        try {
            RestAPIService.getAll(`${Serviceurls.PHARMA_DOCTOR_LIST}?pharmacy_id=${pharmaId}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let DocList = []
                        response.data?.data.forEach((item) => {
                            item.displayKey = `${item?.name} | ${item?.doctor_reg_no}`
                            DocList.push(item)
                        })
                        this.setState({
                            DoctorsList: response.data?.data
                        })
                    }
                    else {
                        this.errorMessage(response.data?.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error?.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose = () => {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    DrugSearchLoader = (index) => {
        this.setState({
            SearchingDrugIndex: index
        })
    }
    BatchearchLoader = (index) => {
        this.setState({
            SearchingBatchIndex: index
        })
    }


    getBrandListData = (index) => {
        try {
            let { pharmacyTblData } = this.state
            if (pharmacyTblData[index]['drug_name'] !== "") {
                this.DrugSearchLoader(index)
                let brandName = pharmacyTblData[index]['drug_name']?.replaceAll('&', '%26')
                RestAPIService.getAll(Serviceurls.PHARMA_BILLING_BRAND_SEARCH +
                    `?search=${CommonValidation.removeSpace(brandName)}&pharmacy_id=${this.state.selectedPharmacy?.pharmacy_id}`)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            if (response.data?.data?.length > 0) {
                                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                                this.setState({ pharmacyTblData }, () => {
                                    this.DrugSearchLoader(null)
                                })
                            } else if (response.data?.data?.length === 0) {
                                this.DrugSearchLoader(null)
                                this.errorMessage("No Drug Found")
                            } else {
                                this.DrugSearchLoader(null)
                                this.errorMessage(response?.data?.message)
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.message)
                        }
                    })
            }
            else {
                this.errorMessage("Enter Brand Name")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDrugDetailsData = (index, FromDrug = false) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state

            this.BatchearchLoader(index)
            let QueryParams = ""
            QueryParams = `?drug_ms_id=${this.state.pharmacyTblData[index]?.drug_ms_id ? this.state.pharmacyTblData[index]?.drug_ms_id : this.state.pharmacyTblData[index]?.drug_id}&pharmacy_id=${this.state.selectedPharmacy?.pharmacy_id || ''}`
            if (this.state.pharmacyTblData[index]?.drug_ms_id || this.state.pharmacyTblData[index]?.drug_id) {
                RestAPIService.getAll(Serviceurls.PHARMA_BILLING_DRUG_DETAILS_GET + QueryParams)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({ selectedBatchListData: response.data.data })
                            if (response.data.data?.length > 0) {
                                pharmacyTblData[index]['Batch_Sugg_list'] = response.data.data
                                if (pharmacyTblData[index]['batch_no'] === "" || !pharmacyTblData[index]['batch_no']) {
                                    let paraData = response.data.data
                                    pharmacyTblData[index]['drug_id'] = response.data.data[0]?.drug_id
                                    if (pharmacyTblData[index]?.drug_id === pharmacyTblData[index - 1]?.drug_id && paraData?.length > 1) {
                                        let sameBatch = pharmacyTblData?.some(item => ((item?.drug_id === response.data.data[1]?.drug_id) && (item?.batch_no === response.data.data[1]?.batch_no) && (item?.expiry_date === response.data.data[1]?.expiry_date) && (item?.cost_per_quantity === response.data.data[1].cost_per_quantity)))
                                        if (sameBatch) {
                                            for (let i = 1; i < paraData?.length; i++) {
                                                if (!pharmacyTblData?.some(item => ((item?.drug_id === response.data.data[i]?.drug_id) && (item?.batch_no === response.data.data[i]?.batch_no) && (item?.expiry_date === response.data.data[i]?.expiry_date) && (item?.cost_per_quantity === response.data.data[i].cost_per_quantity)))) {
                                                    let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                                                    pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                    pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                    pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                    pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                    pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                    pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                                                    pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                    pharmacyTblData[index]['gst_percentage'] = response.data.data[i]?.gst_percentage
                                                    if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                                                    pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                    break;
                                                }
                                            }
                                            // this.errorMessage(`${paraData[1]?.batch_no} Batch No is Already Exists`)
                                        } else {
                                            let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[1]?.cost_per_quantity ? +(response.data.data[1]?.cost_per_quantity) : 0)
                                            pharmacyTblData[index]['batch_no'] = paraData[1]?.batch_no
                                            pharmacyTblData[index]['cost_per_quantity'] = response.data.data[1]?.cost_per_quantity
                                            pharmacyTblData[index]['expiry_date'] = response.data.data[1]?.expiry_date
                                            pharmacyTblData[index]['physical_box_no'] = response.data.data[1]?.physical_box_no
                                            pharmacyTblData[index]['stock_in_quantity'] = response.data.data[1]?.stock_in_quantity
                                            pharmacyTblData[index]['hsn_code'] = response.data.data[1]?.hsn_code
                                            pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[1]?.buying_price_per_piece
                                            pharmacyTblData[index]['gst_percentage'] = response.data.data[1]?.gst_percentage
                                            if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = response.data.data[1]?.sales_discount_for_bill }
                                            else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = response.data.data[1]?.sales_discount_for_bill }
                                            else { pharmacyTblData[index]['discount'] = response.data.data[1]?.sales_discount_for_bill }
                                            pharmacyTblData[index]['schedule'] = response.data.data[1]?.schedule
                                            pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                        }
                                    } else {
                                        let sameBatchNo = pharmacyTblData?.some(item => (item?.drug_id === response.data.data[0]?.drug_id) && (item?.batch_no === response.data.data[0]?.batch_no) && (item?.expiry_date === response.data.data[0]?.expiry_date) && (item?.cost_per_quantity === response.data.data[0].cost_per_quantity))
                                        if (sameBatchNo) {
                                            for (let i = 1; i < paraData?.length; i++) {
                                                if (!pharmacyTblData?.some(item => (item?.drug_id === response.data.data[i]?.drug_id) && (item?.batch_no === response.data.data[i]?.batch_no) && (item?.expiry_date === response.data.data[i]?.expiry_date) && (item?.cost_per_quantity === response.data.data[i].cost_per_quantity))) {
                                                    let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[i]?.cost_per_quantity ? +(response.data.data[i]?.cost_per_quantity) : 0)
                                                    pharmacyTblData[index]['batch_no'] = paraData[i]?.batch_no
                                                    pharmacyTblData[index]['cost_per_quantity'] = response.data.data[i]?.cost_per_quantity
                                                    pharmacyTblData[index]['expiry_date'] = response.data.data[i]?.expiry_date
                                                    pharmacyTblData[index]['physical_box_no'] = response.data.data[i]?.physical_box_no
                                                    pharmacyTblData[index]['stock_in_quantity'] = response.data.data[i]?.stock_in_quantity
                                                    pharmacyTblData[index]['hsn_code'] = response.data.data[i]?.hsn_code
                                                    pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[i]?.buying_price_per_piece
                                                    pharmacyTblData[index]['gst_percentage'] = response.data.data[i]?.gst_percentage
                                                    if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    else { pharmacyTblData[index]['discount'] = response.data.data[i]?.sales_discount_for_bill }
                                                    pharmacyTblData[index]['schedule'] = response.data.data[i]?.schedule
                                                    pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                                    break;
                                                }
                                            }
                                        } else {
                                            let totalCost = (pharmacyTblData[index]['quantity'] ? +(pharmacyTblData[index]['quantity']) : 0) * (response.data.data[0]?.cost_per_quantity ? +(response.data.data[0]?.cost_per_quantity) : 0)
                                            pharmacyTblData[index]['batch_no'] = paraData[0]?.batch_no
                                            pharmacyTblData[index]['cost_per_quantity'] = response.data.data[0]?.cost_per_quantity
                                            pharmacyTblData[index]['expiry_date'] = response.data.data[0]?.expiry_date
                                            pharmacyTblData[index]['physical_box_no'] = response.data.data[0]?.physical_box_no
                                            pharmacyTblData[index]['stock_in_quantity'] = response.data.data[0]?.stock_in_quantity
                                            pharmacyTblData[index]['hsn_code'] = response.data.data[0]?.hsn_code
                                            pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[0]?.buying_price_per_piece
                                            pharmacyTblData[index]['gst_percentage'] = response.data.data[0]?.gst_percentage
                                            if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = response.data.data[0]?.sales_discount_for_bill }
                                            else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = response.data.data[0]?.sales_discount_for_bill }
                                            else { pharmacyTblData[index]['discount'] = response.data.data[0]?.sales_discount_for_bill }
                                            pharmacyTblData[index]['schedule'] = response.data.data[0]?.schedule
                                            pharmacyTblData[index]['total_cost'] = totalCost ? totalCost : 0
                                        }
                                    }
                                }
                                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => {
                                    this.BatchearchLoader(null)
                                    this.calculateDiscountTotalPrice(index)
                                })
                                if (FromDrug && !this.state.isFromIpRequest) {
                                    this.QuantityRef?.current?.focus();
                                }
                            } else {
                                pharmacyTblData[index]['Batch_Sugg_list'] = []
                                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => { this.BatchearchLoader(null) })
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.BatchearchLoader(null)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.BatchearchLoader(null)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    postPharmacyBillData = () => {
        try {
            let states = this.state
            let url = ''
            if (this.state.receivePayment) {
                if (this.state.isForReturn || this.state.isFromIpReturn) {
                    url = Serviceurls.PHARMA_RETURN_BILL
                }
                else {
                    url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
                }
            } else if (states.addToAdvance) {
                url = Serviceurls.PHARMA_RETURN_BILL
            } else if (this.state.creditPayment) {
                url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
            } else if (this.state.savedBill) {
                url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST
            }
            let Lineitems = []
            let SumOfDiscount = 0
            this.state.pharmacyTblData.forEach((item) => {
                if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true) && (this.state.isForReturn ? item?.return_quantity !== "" : true)) {
                    let BillItem = {
                        "drug_id": item.drug_id,
                        "hsn_code": item.hsn_code,
                        "batch_no": item.batch_no,
                        "expiry_date": item.expiry_date,
                        "physical_box_no": item.physical_box_no,
                        "cost_per_quantity": item.cost_per_quantity,
                        "total_cost": +(+item.total_cost)?.toFixed(2),
                        "discount_percentage": +item.discount,
                        "gst_percentage": item.gst_percentage ? item.gst_percentage : 0,
                        "quantity": (this.state.isForReturn || this.state.isFromIpReturn) ? +item.return_quantity : +item.quantity,
                        "net_total_amount": (this.state.isForReturn || this.state.isFromIpReturn) ? +(+item.total_return_price)?.toFixed(2) : +(+item.total_price)?.toFixed(2),
                    }
                    if (this.state.isFromIpRequest) { BillItem["ip_line_item_id"] = item?.line_item_id }
                    if (this.state.isFromIpReturn) { BillItem["return_id"] = item?.return_id }
                    if (item?.line_item_id) { BillItem["line_item_id"] = item?.line_item_id }
                    Lineitems.push(BillItem)
                    var dis_amt = (+item.total_cost) * (+item.discount / 100)
                    SumOfDiscount += (+dis_amt)
                }
            })
            let noDiscountAmount = 0;
            this.state.pharmacyTblData?.forEach(element => {
                noDiscountAmount = noDiscountAmount + element.total_cost
            })

            let Datetime = new Date(this.state.invoiceDateTime)
            let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
            let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_WITH_SECONDS)

            let PaymentTypeData = [];
            if (this.state.isCash) {
                PaymentTypeData.push(1)
            }
            if (this.state.isCard) {
                PaymentTypeData.push(2)
            }
            if (this.state.isUPI) {
                PaymentTypeData.push(3)
            }
            if (this.state.isBankTranfer) {
                PaymentTypeData.push(5)
            }

            let ReceivedAmt = 0

            if (states.isCredit || states.savedBill) {
                ReceivedAmt = 0
            } else if (states?.isForReturn || states?.isFromIpReturn) {
                if ((states.BillReceivedAmount != states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
                    ReceivedAmt = states.AdvanceAmt ? states.AdvanceAmt : states.ReceiptAmt
                } else if ((states.BillReceivedAmount == states.totalBillNetAmount) && states.BillReceivedAmount != 0) {
                    ReceivedAmt = states.ReturnedNetAmount
                } else {
                    ReceivedAmt = states.BillReceivedAmount
                }
            } else {
                ReceivedAmt = states.netAmount ? states.netAmount : 0
            }

            let FinalPayableAmount = this.state.netAmount ? +this.state.netAmount : 0
            let FinalDiscountAmount = this.state.totalDiscount ? +this.state.totalDiscount : 0
            let FinalDiscountPercent = this.state.totalPercentage ? this.state.totalPercentage : 0
            if (this.state.isForSendApproval) {
                let discount = this.state.totalDiscount ? +this.state.totalDiscount : 0
                FinalPayableAmount = ((this.state.netAmount ? +this.state.netAmount : 0) + discount)
                FinalDiscountAmount = 0
                FinalDiscountPercent = 0
            }

            let creditType = (states.isCredit && (states?.paymentCreditType == "Patient credit")) ? "Patient credit" : (states.isCredit && (states?.paymentCreditType == "Insurance" || states?.paymentCreditType == "Insurance credit")) ? "Insurance credit" : (states.isCredit && (states?.paymentCreditType == "Corporate" || states?.paymentCreditType == "Corprate credit")) ? "Corprate credit" : null
            let data = {
                "invoice_date": `${date} ${time}`,
                "billing_customer_name": states.PatientName,
                "add_to_advance": (states.AdvanceAmt || states.addToAdvance) ? true : false,
                "billing_customer_mobile_no": states?.PatientMobileNumber,
                "doctor_name": (states.doctordetails?.name && states.doctordetails?.doctor_id) ? states.doctordetails?.name : states?.PatientDetails?.doctor_name,
                "patient_id": states.PatientDetails?.patient_id,
                "patient_account_number": states?.PatientDetails?.patient_account_number,
                "ip_number": states?.PatientDetails?.ip_number,
                "ip_admission_id": states?.isFromIpRequest ? states?.IpRequestAdmId : states?.PatientDetails?.ip_admission_id ? states?.PatientDetails?.ip_admission_id : null,
                "appointment_id": states?.PatientDetails?.ip_admission_id ? null : (states?.PatientDetails?.appointment_id ? states?.PatientDetails?.appointment_id : null),
                "discount_amount": SumOfDiscount ? +(SumOfDiscount).toFixed(2) : 0,
                "taxable_amount": (states?.isForReturn || states?.isFromIpReturn) ? +(states.ReturnedNetAmount)?.toFixed(2) : +(FinalPayableAmount)?.toFixed(2),
                "general_discount": +FinalDiscountAmount,
                "cgst_amount": 0,
                "sgst_amount": 0,
                "igst_amount": 0,
                "tax_amount": 0,
                "round_off": states.roundOff,
                "delivery_charge": 0,
                "gross_amount": +states.subAmount?.toFixed(2),
                "grand_total": +states.subAmount?.toFixed(2),
                "net_amount": (states?.isForReturn || states?.isFromIpReturn) ? +(states.ReturnedNetAmount)?.toFixed(2) : +(FinalPayableAmount)?.toFixed(2),
                "received_amount": ReceivedAmt ? +(ReceivedAmt)?.toFixed(2) : 0,
                "balance_amount": 0,
                "bill_line_items": Lineitems,
                "employee_id": states?.paymentCreditType == "Corprate credit" ? states.CropNo : null,
                "company_name": states?.paymentCreditType == "Corprate credit" ? states.CropName : "",
                "credit_type": creditType ? creditType : "Patient credit",
                "validity": states.InsuValidity,
                "bill_type": this.state.creditPayment ? "Credit" : this.state.savedBill ? "Saved" : "Bill",
                "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.reg_no : null,
                "line_item": (states.isFromIpRequest || states.isFromIpReturn) ? Lineitems : [],
                "ip_transmit_id": (states.isFromIpRequest || states?.isFromIpReturn) ? states?.ip_transmit_id : null,
                "cash_amount": +states.CashAmount,
                "card_amount": +states.CardAmount,
                "upi_amount": +states.UPIAmount,
                "bank_amount": +states.BankTfrAmount,
                "cheque_amount": +states.ChequeAmount,
                "insurance_amount": +states.InsuranceAmount,
                "general_discount_percentage": +FinalDiscountPercent,
                "payment_modes": PaymentTypeData ? PaymentTypeData : [],
                "doctor_id": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_id : null,
                "doctor_reg_no": states.doctordetails?.doctor_id ? states.doctordetails?.doctor_reg_no : null,
                "card_number": states.CardTransactionNumber,
                "reference_no": states.BankTfrNumber,
                "transaction_id": states.UPINumber,
                "insurance_company_name": states?.paymentCreditType == "Insurance credit" ? states.InsName : "",
                "transaction_number": states?.InsuTransactionNumber,
                "policy_number": states?.paymentCreditType == "Insurance credit" ? states?.InsNO : null,
                "billing_status": (this.state.savedBill || this.state.isCredit) ? 'Partially Saved' : "Payment Received",
                "payment_status": (this.state.savedBill || this.state.isCredit) ? 'not paid' : "paid",
                "billing_doctor_name": !states.doctordetails?.doctor_id ? states.doctordetails?.name : null,
                "net_total_amount": noDiscountAmount ? +(+noDiscountAmount)?.toFixed(2) : 0,
                "remarks": +FinalDiscountPercent != 0 ? this.state.discountReason : '',
                "employer_id": states?.paymentCreditType == "Corprate credit" ? states.CompanyID : null,
                "pharmacy_id": states?.selectedPharmacy?.pharmacy_id
            }
            if (states.Bill_ID) { data["bill_summary_id"] = states.Bill_ID }
            if (states.isForReturn) {
                data["invoice_number"] = states.BillDetails?.invoice_numbers
                data["invoice_sequence"] = states.BillDetails?.invoice_sequence
            }
            if (states.isFromIpReturn) {
                data["invoice_number"] = states.BillDetails?.invoice_number
            }
            if (states.isFromSavedBill) {
                data["temp_invoice_number"] = states.BillDetails?.temp_invoice_number
            }
            if (states.isFromPrescription) {
                data['transmitted_id'] = states.PrescriptionId
            }
            if (this.state.isForSendApproval) {
                data['is_pending_bill'] = true
            }

            if (this.state?.isForInvoiceEdit) { // For Update Invoice
                RestAPIService.updateWithOutId(data, url)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false,
                                disableBtn: false,
                            }, () => {
                                this.DiscardAction(true, true)
                            })
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.setState({ disableBtn: false })
                            this.errorMessage(error.response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        } else {
                            this.LoaderFunction(false)
                            this.setState({ disableBtn: false })
                            this.errorMessage(error.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        }
                        this.setState({ disableBtn: false })
                    })
            } else {
                RestAPIService.create(data, url)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.setState({ disableBtn: false })
                            if (this.state.receivePayment) {
                                this.LoaderFunction(false)
                                this.setState({ disableBtn: false })
                                if (this.state.isForReturn) {
                                    this.setState({
                                        CompletedBillID: response.data.data?.id,
                                        invoiceNumber: response?.data?.data?.invoice_number
                                    })
                                }
                                else if (this.state.isFromIpReturn) {
                                    this.setState({
                                        CompletedBillID: response.data.data?.id,
                                    })
                                }
                                else {
                                    this.setState({
                                        CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                        creditPayment: false,
                                        receivePayment: false,
                                        savedBill: false,
                                        invoiceNumber: response?.data?.data?.invoice_number
                                    })
                                }
                            } else {
                                this.setState({ disableBtn: false })
                                if (this.state.creditPayment) {
                                    this.setState({
                                        CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                        creditPayment: false,
                                        receivePayment: false,
                                        savedBill: false,
                                        invoiceNumber: response?.data?.data?.invoice_number,
                                    }, () => {
                                        if (this.state.isForSendApproval) {
                                            this.PostApprovalRequestData(
                                                states.PatientDetails?.patient_id,
                                                (states?.PatientDetails?.ip_admission_id ? null : (states?.PatientDetails?.appointment_id ? states?.PatientDetails?.appointment_id : null)),
                                                (states?.PatientDetails?.ip_admission_id ? states?.PatientDetails?.ip_admission_id : null),
                                                response.data.data?.id,
                                                +FinalPayableAmount,
                                                response?.data?.data?.invoice_number,
                                                date
                                            )
                                        }
                                    })
                                    this.LoaderFunction(false)
                                    if (this.state.isForReturn) {
                                        this.setState({
                                            CompletedBillID: response.data.data?.id,
                                        })
                                    }
                                    else if (this.state.isFromIpReturn) {
                                        this.setState({
                                            CompletedBillID: response.data.data?.id,
                                        })
                                    }
                                    else {
                                        this.setState({
                                            CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                            creditPayment: false,
                                            receivePayment: false,
                                            savedBill: false,
                                            invoiceNumber: response?.data?.data?.invoice_number
                                        })
                                    }
                                } else {
                                    if (this.state.creditPayment) {
                                        this.setState({
                                            CompletedBillID: this.state.isForReturn ? null : response.data.data?.id,
                                            creditPayment: false,
                                            receivePayment: false,
                                            savedBill: false,
                                        })
                                        this.LoaderFunction(false)
                                    } else {
                                        this.setState({
                                            invoiceNumber: response?.data?.data?.temp_invoice_number,
                                            creditPayment: false,
                                            receivePayment: false,
                                            savedBill: false,
                                        })
                                        this.LoaderFunction(false)
                                        this.DiscardAction(true, this.state.isFromInvoice)
                                    }
                                }
                            }
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.setState({ disableBtn: false })
                            this.errorMessage(error.response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        } else {
                            this.LoaderFunction(false)
                            this.setState({ disableBtn: false })
                            this.errorMessage(error.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        }
                        this.setState({ disableBtn: false })
                    })
            }

        } catch (error) {
            this.setState({ disableBtn: false })
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    calculateDiscountTotalPrice = (index) => {
        try {
            let { pharmacyTblData } = this.state
            let totalCost = pharmacyTblData[index]['total_cost']
            let discount = pharmacyTblData[index]['discount']
            let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
            pharmacyTblData[index]['total_price'] = totalCost - discountValue
            this.setState({ pharmacyTblData }, () => {
                this.calculateTotalDiscount()
                this.SetFullCashAmount()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateCostAndTotal = (value, index, FromReturn = false) => {
        try {
            if (FromReturn) {
                let { pharmacyTblData } = this.state
                pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
                let totalCost = pharmacyTblData[index]['total_cost']
                let discount = pharmacyTblData[index]['discount']
                let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
                let FinalPrice = totalCost - discountValue
                pharmacyTblData[index]['total_return_price'] = FinalPrice
                this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
            } else {
                let { pharmacyTblData } = this.state
                pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
                let totalCost = pharmacyTblData[index]['total_cost']
                let discount = pharmacyTblData[index]['discount']
                let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
                let FinalPrice = totalCost - discountValue
                pharmacyTblData[index]['total_price'] = FinalPrice
                this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateTotalDiscount = () => {
        try {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
            if (!this.state.isForReturn) {
                this.state.totalPercentage = CommonValidation.amountPercentage(this.state.totalDiscount, this.state.subAmount)
            }
            this.setState({
                totalDiscount: this.state.totalDiscount,
                totalPercentage: this.state.totalPercentage
            }, () => {
                this.SetFullCashAmount()
                this.calculateMarginProfit()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CheckisForApproval = () => {
        if (this.state.LoggedRole === "user" && !this.state.perAccess?.common?.concession_access && this.state.totalDiscount) {
            this.setState({
                isCredit: true,
                isForSendApproval: true,
                isCard: false,
                isCash: false,
                isUPI: false,
                isBankTranfer: false,
                isCheque: false,
                isInsurance: false,
                CashAmount: "",
                CardAmount: "",
                CardTransactionNumber: "",
                UPINumber: "",
                UPIAmount: "",
                BankTfrAmount: "",
                BankTfrNumber: "",
                ChequeNumber: "",
                ChequeAmount: "",
                InsuranceAmount: "",
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: '',
            })
        } else {
            this.setState({
                isCredit: this.state.isForSendApproval ? false : this.state.isCredit,
                isForSendApproval: false
            })
        }
    }

    onChangeHandler = (e, index) => {
        try {
            const { name, value } = e.target
            switch (name) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if (((CommonValidation.numberOnly(value) && value?.length < 7) || value === '')) {
                            this.state.pharmacyTblData[index]['quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, false)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'return_quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.numberOnly(value) && value?.length < 7) && (value != 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, true)
                                this.calculateMarginProfit()
                                if ((this.state?.isForReturn || this.state?.isFromIpReturn) && +this.state.netAmount === 0 && this.state.subAmount && +this.state.subAmount > 0) {
                                    this.setState({
                                        isCard: false,
                                        isCash: true,
                                        isUPI: false,
                                        isBankTranfer: false,
                                        isCheque: false,
                                        isInsurance: false,
                                        CashAmount: "",
                                        CardAmount: "",
                                        CardTransactionNumber: "",
                                        UPINumber: "",
                                        UPIAmount: "",
                                        BankTfrAmount: "",
                                        BankTfrNumber: "",
                                        ChequeNumber: "",
                                        ChequeAmount: "",
                                        InsuranceAmount: "",
                                        InsuCompanyName: "",
                                        InsuPolicyNumber: "",
                                        InsuTransactionNumber: "",
                                        InsuValidity: '',
                                    })
                                } else if (this.state?.isForReturn || this.state?.isFromIpReturn) {
                                    this.setState({ isCash: true })
                                }
                            })
                        } else if ((value === '') || (value === 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = ''
                            this.state.pharmacyTblData[index]['total_cost'] = ''
                            this.state.pharmacyTblData[index]['total_return_price'] = ''
                            this.setState({
                                totalDiscount: 0,
                                pharmacyTblData: this.state.pharmacyTblData
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity']) {
                        if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
                            this.state.pharmacyTblData[index]['discount'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Enter Quantity')
                    }
                    break;
                case 'totalDiscount':
                    if ((CommonValidation.NumberWithDot(value) && value <= this.state.subAmount) || value === "") {
                        this.state.totalPercentage = CommonValidation.amountPercentage(value, this.state.subAmount)
                        this.setState({
                            totalDiscount: value
                        }, () => {
                            this.calculateMarginProfit()
                            this.CheckisForApproval()
                        })
                    }
                    break;
                case 'totalPercentage':
                    if ((CommonValidation.DecimalNumber(value) && +value <= 100) || value === "") {
                        this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
                        this.setState({
                            totalPercentage: value
                        }, () => {
                            this.calculateMarginProfit()
                            this.CheckisForApproval()
                        })
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disabled = (names, index) => {
        try {
            let disable = true
            switch (names) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name'] && !this.state.isFromInvoice) {
                        disable = false
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity'] && !this.state.isFromInvoice) {
                        disable = false
                    }
                    break;
                case 'batch_no':
                    if (this.state.pharmacyTblData[index]['drug_name'] &&
                        this.state.pharmacyTblData[index]['dosage_strength'] &&
                        this.state.pharmacyTblData[index]['dosage_type'] &&
                        !this.state.isFromInvoice) {
                        disable = false
                    }
                    break;
                case 'drug_name':
                    disable = false;
                    break;
                case 'return_quantity':
                    disable = false;
                    break;
                default:
                    disable = true;
                    break;
            }
            return disable;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (values, names, labels, width, index, height, disable) => {
        return (
            <TextField
                inputRef={this.state.isFromIpRequest ? null : ((names === "quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null)}
                autoFocus
                size='small'
                disabled={(disable ? disable : this.disabled(names, index)) || (getCachevalue("ForAdminApproval") === "true")}
                variant='standard'
                sx={{ width: width }}
                placeholder={labels}
                onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
                name={names}
                autoComplete='off'
                inputProps={{
                    emed_tid: labels,
                    style: {
                        height: height ? height : '2dvh', marginTop: '0.5dvw', textAlign: 'center',
                        color: (names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"]) ? "red" : null) :
                            (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"]) ? "red" : null) : null
                    }
                }}
                value={values}
                onChange={(e) => { this.onChangeHandler(e, index) }}
                error={(names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"])) :
                    (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["return_quantity"] > this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["quantity"])) : false}
            />
        )
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.totalDiscount || this.state.totalPercentage) && (this.state.discountReason === "")) {
            if (names === 'totalDiscount' || names === 'totalPercentage') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    renderTextBox = (names, value, height, diasble) => {
        try {
            return (
                <TextField
                    autoFocus
                    variant='standard'
                    autoComplete='off'
                    size='small'
                    InputProps={{
                        startAdornment: names === "totalDiscount" ? <InputAdornment position="start">{CurrencySymbol}</InputAdornment> : null,
                        endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
                    }}
                    className={'eMed_discount_txt'}
                    disabled={diasble || this.state.isForReturn || this.state.isFromIpReturn || (this.state.isFromInvoice)}
                    inputProps={{ style: { height: height }, emed_tid: names }}
                    name={names}
                    value={value}
                    onChange={(e) => { this.onChangeHandler(e, names) }}
                    onBlur={() => { this.concessionManditoryPopup(names) }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    autocompleteOnChangeHandler = (newValue, names, index) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state
            let avoid_drugDiscount = this.state.ConfigSetting;
            let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
            let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);

            // if (pharmacyTblData[index][names]?.length > 0) {
            switch (names) {
                case 'drug_name':
                    pharmacyTblData[index][names] = newValue['drug_name']
                    pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
                    pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
                    pharmacyTblData[index]['drug_ms_id'] = newValue['drug_ms_id']
                    pharmacyTblData[index]['quantity'] = ""
                    pharmacyTblData[index]['total_cost'] = ""
                    pharmacyTblData[index]['total_price'] = ""
                    pharmacyTblData[index]['discount'] = ""
                    this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => this.getDrugDetailsData(index, true))
                    break;
                case 'batch_no':
                    let batchSelected = false
                    pharmacyTblData?.forEach(element => {
                        if ((element?.batch_no === newValue['batch_no']) && (element?.drug_id === newValue['drug_id'] && (element?.expiry_date === newValue['expiry_date']) && (element?.cost_per_quantity === newValue['cost_per_quantity']))) {
                            batchSelected = true
                        }
                    })
                    if (this.state.isFromIpRequest) {
                        if (!batchSelected) {
                            pharmacyTblData[index][names] = newValue['batch_no']
                            pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                            pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
                            pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
                            pharmacyTblData[index]['drug_id'] = newValue['drug_id']
                            pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
                            pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                            pharmacyTblData[index]['total_cost'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
                            pharmacyTblData[index]['total_price'] = newValue['cost_per_quantity'] * pharmacyTblData[index]['quantity']
                            if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                            else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                            else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                            pharmacyTblData[index]['schedule'] = newValue['schedule']
                        } else {
                            this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                        }
                    } else {
                        if (batchSelected) {
                            this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                        } else {
                            pharmacyTblData[index][names] = newValue['batch_no']
                            pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                            pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
                            pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
                            pharmacyTblData[index]['drug_id'] = newValue['drug_id']
                            pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                            pharmacyTblData[index]['total_cost'] = ""
                            pharmacyTblData[index]['total_price'] = ""
                            pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
                            if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                            else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                            else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                            pharmacyTblData[index]['schedule'] = newValue['schedule']
                        }
                    }
                    this.setState({ pharmacyTblData, QuantityCurrentIndex: this.state.isFromIpRequest ? null : index, BatchAnchor: null, CurrentBatchIndex: null }, () => {
                        this.calculateCostAndTotal(pharmacyTblData[index]['quantity'], index, false)
                        this.calculateMarginProfit()
                        if (pharmacyTblData[index]['quantity'] === "") { this.QuantityRef?.current?.focus() }
                    })
                    break;
                default: break;
            }
            // }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onInputChangeAutoCompleteHandler = (value, names, index) => {
        try {
            let { pharmacyTblData } = this.state
            switch (names) {
                case 'drug_name':
                    this.state.pharmacyTblData[index]['drug_name'] = value
                    this.setState({ pharmacyTblData })
                    break;
                case 'batch_no':
                    this.state.pharmacyTblData[index]['batch_no'] = value
                    this.setState({ pharmacyTblData })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                inputProps={{ emed_tid: label }}
                sx={{ padding: 0, margin: 0, width: "10dvw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                disabled={this.state.forCancel || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        }, () => {
                            if (key === "CashAmount") {
                                this.setState({
                                    CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                                })
                            }
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1dvw" : 0, width: "10dvw" }}
                inputProps={{ maxLength: 20, emed_tid: label }}
                disabled={this.state.forCancel || key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false) || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                focused={key === "CashBalanceAmount"}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let onlynumber = CommonValidation.numberOnly(e.target.value);
                    if (key === "ReceivedCashAmount") {
                        if (onlynumber || e.target.value === "") {
                            this.setState({
                                ReceivedCashAmount: e.target.value,
                                CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
                            })
                        }
                    }
                    else {
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            })
                        }
                    }
                }
                }
            />
        )
    }

    handleCheckBox = (e, key) => {
        try {
            var states = this.state
            states[key] = e.target.checked
            this.setState({ states }, () => {
                if (!states.isCash) { this.setState({ CashAmount: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
                if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
                if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
                if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
                if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
                this.SetFullCashAmount()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    SetFullCashAmount = () => {
        let states = this.state
        if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
            if (states?.isForReturn || states?.isFromIpReturn) {
                this.setState({ CashAmount: this.state.ReturnedNetAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
            } else {
                this.setState({ CashAmount: this.state.netAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        }
        if (!states?.subAmount) {
            this.setState({
                isCard: false,
                isCash: false,
                isUPI: false,
                isBankTranfer: false,
                isCheque: false,
                isInsurance: false,
                CashAmount: "",
                CardAmount: "",
                CardTransactionNumber: "",
                UPINumber: "",
                UPIAmount: "",
                BankTfrAmount: "",
                BankTfrNumber: "",
                ChequeNumber: "",
                ChequeAmount: "",
                InsuranceAmount: "",
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: '',
            })
        }
    }
    calculateMarginProfit = () => {
        var { pharmacyTblData } = this.state
        // calculate margin cumulative sum
        for (let i = 0; i < pharmacyTblData.length; i++) {
            let total_buying_price_drug = 0
            if (pharmacyTblData[i].batch_no != "") {
                let profit = parseFloat(pharmacyTblData[i]["cost_per_quantity"]) - parseFloat(pharmacyTblData[i]["buying_price_per_piece"]).toFixed(2)
                pharmacyTblData[i]["margin_profit"] = (profit * (+(pharmacyTblData[i]["quantity"])))

                total_buying_price_drug = (parseFloat(pharmacyTblData[i]["buying_price_per_piece"] * (+(pharmacyTblData[i]["quantity"]))))
                pharmacyTblData[i]["total_buying_price_per_drug"] = total_buying_price_drug

                this.setState({
                    pharmacyTblData
                }, () => {
                    // calculate total margin profit
                    const margin_profit = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map((item) => (item.batch_no === "" ? 0 : +(item.margin_profit))).reduce((prev, curr) => (prev + curr));
                    // calculate total buying price
                    const total_buying_price = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map(item => (item.batch_no === "" ? 0 : +(item.total_buying_price_per_drug))).reduce((prev, curr) => (prev + curr));

                    this.setState({
                        totalMarginProfit: margin_profit,
                        totalBuyingPrice: total_buying_price
                    })
                })
            }
        }

    }

    billingCalculation = (params = [], type) => {
        try {
            let amount = 0; let payableAmount = 0; let OutStandingValue = 0;
            switch (type) {
                case 'totalServiceAmount':
                    if (this.state.isForReturn || this.state.isFromIpReturn) {
                        params.forEach(element => {
                            amount = amount + (element.total_return_price ? element.total_return_price : 0)
                        })
                        if (this.state.subAmount !== +amount) {
                            this.setState({
                                subAmount: +amount
                            }, () => {
                                this.calculateTotalDiscount()
                            })
                        }
                    } else {
                        params.forEach(element => {
                            amount = amount + (element.total_price ? element.total_price : 0)
                        })
                        if (this.state.subAmount !== +amount) {
                            this.setState({
                                subAmount: +amount
                            }, () => {
                                this.calculateTotalDiscount()
                            })
                        }
                    }
                    return AmountFormat(this.state.subAmount)
                case 'marginProfit':
                    let finalMarginProfit = this.state.netAmount - this.state.totalBuyingPrice
                    return AmountFormat(this.state.netAmount == 0 ? 0 : (finalMarginProfit ? parseFloat(finalMarginProfit).toFixed(2) : 0))
                case 'roundOff':
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscount)
                    return this.state.roundOff == 0 ? 0 : (this.state.roundOff > 0 ? `+ ${this.state.roundOff}` : `- ${this.state.roundOff * -1}`);
                case 'payableAmount':
                    if (this.state.isForReturn || this.state.isFromIpReturn) {
                        let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                        let netpayableAmount = this.state.subAmount - discount
                        if (this.state.ReturnedNetAmount !== (this.state.subAmount ? Math.round(+netpayableAmount) : 0)) {
                            this.setState({
                                ReturnedNetAmount: this.state.subAmount ? Math.round(+netpayableAmount) : 0
                            }, () => { this.SetFullCashAmount() })
                        }
                        return AmountFormat(this.state.ReturnedNetAmount)
                    } else {
                        let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                        payableAmount = this.state.subAmount - discount
                        if (this.state.netAmount !== (Math.round(+payableAmount))) {
                            this.setState({
                                netAmount: Math.round(+payableAmount)
                            }, () => { this.SetFullCashAmount() })
                        }
                        return AmountFormat(this.state.netAmount)
                    }
                case 'ReceivedAmount':
                    return (this.state.BillReceivedAmount && this.state.BillReceivedAmount > 0) ? AmountFormat(this.state.BillReceivedAmount) : 0
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onAddHandler = (names, index) => {
        try {
            if (this.state.pharmacyTblData[index]['drug_name']) {
                if (names === 'quantity' || names === 'discount') {
                    if (index === this.state.pharmacyTblData?.length - 1) {
                        let duplicateTbl = {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            gst_percentage: null,
                            Drug_sugg_list: [],
                            Batch_Sugg_list: [],
                        }
                        this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
                        this.setState({
                            pharmacyTblData: this.state.pharmacyTblData
                        }, () => {
                            this.setState({
                                drugDetailsData: []
                            })
                        })
                    }
                }
            } else {
                this.errorMessage('Enter All Fields')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearTableData = () => {
        let TableHasValues = false
        this.state.pharmacyTblData.forEach((list) => {
            if (list.drug_name !== "") { TableHasValues = true }
        })
        if (TableHasValues) {
            this.setState({ ShowDiscard: true })
        } else {
            this.DiscardAction(true)
        }
    }

    PharmacyPatientSearch = (ShowErrMsg = true) => {
        try {
            const { t } = this.props;
            this.setState({ isPatientisSearching: true })
            const LoggedDetails = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
            let url = ''
            if (LoggedDetails?.pharmacy_type === "Standalone Pharmacy") {
                url = Serviceurls.PHARMA_HEADER_SEARCH
            } else {
                url = Serviceurls.FO_PATIENT_SEARCH
            }
            RestAPIService.getAll(url + `?search_key=${this.state.PatientMobileNumber}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            SearchedPatientList: response.data.data,
                            isPatientisSearching: false,
                            common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false,
                        }, () => {
                            if (this.state.PatientMobileNumber !== "") {
                                if (response.data.data.length > 0) {
                                    this.setState({ PatientSearchClicked: true })
                                } else {
                                    if (ShowErrMsg) { this.errorMessage(t("NoRecordsFound")) }
                                }
                            }
                        })
                    }
                    else {
                        this.setState({ isPatientisSearching: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ isPatientisSearching: false })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.setState({ isPatientisSearching: false })
            this.errorMessage(e.message)
        }
    }

    renderSearch(label, statekey, img, Data = []) {
        const { t } = this.props;
        let states = this.state;
        let PatientDetails = statekey === "ipopNumber" ? (states.PatientDetails?.ip_number ? states.PatientDetails?.ip_number : (states.PatientDetails?.op_number ? states.PatientDetails?.op_number : "-")) : "-"
        let nowdate = new Date();
        let room_ward_block = `${states.PatientDetails?.bed_number}/${states.PatientDetails?.room_number}/${states.PatientDetails?.ward_name}/${states.PatientDetails?.block_name}`
        return (
            <Box className="eMed_Ip_Est_ptCard" height={"2.8dvw"} width={statekey === "PatientMobileNumber" ? "16dvw" : statekey === "ipopNumber" ? "19dvw" : "18dvw"}>
                <Box component={'div'} className='eMed_pharma_header_icon_warp'>
                    <img className='eMed_pharma_header_icon_img' src={img ? img : ImagePaths.Male.default} alt="male" />
                </Box>
                <Box component={'div'} >
                    {statekey === "ipopNumber" ?
                        <Box display={'flex'} flexDirection={'column'} backgroundColor={states.PatientDetails?.ip_number ? '#f2d0d2' : 'white'}>
                            <Box width={"16dvw"} height={'2dvw'} display={'flex'} flexDirection={'row'} paddingTop={states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? '0.5dvw' : '0dvw'} paddingBottom={states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? '0.5dvw' : '0dvw'}>
                                {states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ?
                                    <Typography fontSize={"0.7dvw"} color={"#960320"} fontWeight={500} mr={'0.3dvw'}>{states.PatientDetails?.ip_number ? "IP No -" : states.PatientDetails?.op_number ? "OP No - " : "IP / OP Number"}</Typography>
                                    :
                                    <Box>
                                        <Box component={'div'} display={'flex'}>
                                            <Typography fontSize={"0.7dvw"} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={500} mr={'0.3dvw'}>{states.PatientDetails?.ip_number ? "IP No -" : states.PatientDetails?.op_number ? "OP No - " : "IP / OP Number"}</Typography>
                                            {PatientDetails?.length > 27 ?
                                                <Tooltip placement='top' title={PatientDetails} arrow>
                                                    <Typography fontSize={"0.7dvw"} fontWeight={'bold'} marginRight={'1dvw'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{PatientDetails.slice(0, 27) + "..."}</Typography>
                                                </Tooltip>
                                                : <Typography fontSize={"0.7dvw"} fontWeight={'bold'} marginRight={'1dvw'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{PatientDetails}</Typography>}
                                        </Box>
                                        <Typography fontSize={"0.7dvw"} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={500}>{states.PatientDetails?.ip_number ? "(Credit Limit :" : null}
                                            <Typography component={'span'} fontSize={"0.7dvw"} marginRight={'1dvw'} color={states.PatientDetails?.ip_number ? "#960320" : '#685c5c'} fontWeight={600}>{
                                                states.PatientDetails?.ip_number ?
                                                    String(states.PatientDetails?.balance_ip_pharmacy_credit_limit)?.length > 9 ?
                                                        <Tooltip placement='top' title={states.PatientDetails?.balance_ip_pharmacy_credit_limit}><span style={{ fontSize: '0.7dvw' }}>{` ${CurrencySymbol}  ${String(states.PatientDetails?.balance_ip_pharmacy_credit_limit)?.slice(0, 7) + '...)'}`}</span></Tooltip> :
                                                        ` ${CurrencySymbol} ${states.PatientDetails?.balance_ip_pharmacy_credit_limit})`
                                                    : null}</Typography>
                                        </Typography>
                                    </Box>
                                }
                                {states.PatientDetails?.balance_ip_pharmacy_credit_limit === 0 ? (
                                    PatientDetails?.length > 27 ? (
                                        <Tooltip placement='top' title={PatientDetails} arrow>
                                            <Typography fontSize={"0.7dvw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>
                                                {PatientDetails.slice(0, 27) + "..."}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography fontSize={"0.7dvw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>
                                            {PatientDetails}
                                        </Typography>
                                    )
                                ) : null}
                            </Box>
                            {states.PatientDetails?.ip_number ?
                                <Box width={"16dvw"} display={'flex'} flexDirection={'row'}>
                                    <Typography fontSize={"0.7dvw"} color={"#960320"} fontWeight={500} mr={'0.3dvw'}>{"Bed No - "}</Typography>
                                    <Typography fontSize={"0.7dvw"} fontWeight={'bold'} color={states.PatientDetails?.ip_number ? "#960320" : 'black'}>{
                                        room_ward_block.length > 35 ? <Tooltip placement="top" title={room_ward_block} arrow><Typography fontSize={"0.7dvw"} fontWeight={'bold'} >{room_ward_block.slice(0, 35) + "..."}</Typography></Tooltip> : room_ward_block ? room_ward_block : "-"}</Typography>
                                </Box> : null}
                        </Box>
                        : statekey === "doctordetails" ?
                            <Autocomplete
                                disabled={this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription || this.state.isFromInvoice || (getCachevalue("ForAdminApproval") === "true")}
                                size='small'
                                clearIcon={false}
                                options={Data}
                                label={t(label)}
                                getOptionLabel={(item) => (typeof (item.displayKey) === "string" ? item.displayKey : "")}
                                value={states[statekey] ? states[statekey] : ""}
                                ListboxProps={{ style: { maxHeight: 300 } }}
                                onChange={(event, newValue) => {
                                    states[statekey] = newValue;
                                    this.setState({ states });
                                }}
                                sx={{ width: '15dvw' }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.id}>
                                        {option?.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        disabled={this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                                        size='small'
                                        {...params}
                                        placeholder='Enter Doctor Name Here'
                                        label={t(label)}
                                        onChange={(e) => {
                                            var newDoc = { displayKey: e.target.value, doctor_id: "", doctor_reg_no: '', name: e.target.value };
                                            this.setState({
                                                doctordetails: newDoc
                                            });
                                        }}
                                        autoComplete='off'
                                        variant='standard'
                                        inputProps={{
                                            ...params.inputProps,
                                            'emed_tid': 'doctor name',
                                        }}
                                    />
                                )}
                            />
                            :
                            statekey === "invoiceDateTime" ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        disabled={true} //this.state.isFromInvoice ---> Previous Condition
                                        emed_tid="Invoice DateTime"
                                        open={states.DateTimerOpen}
                                        onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                                        onClose={() => { this.setState({ DateTimerOpen: false }) }}
                                        maxDateTime={new Date(Date.now())}
                                        minDate={new Date(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate() - 1)}
                                        label={t(label)}
                                        value={states[statekey]}
                                        inputFormat='DD-MM-YYYY & hh:mmA'
                                        onChange={(value) => {
                                            if (value === null) {
                                                states[statekey] = new Date()
                                            } else {
                                                states[statekey] = value
                                            }
                                            states["DateTimeChanged"] = true
                                            this.setState({ states })
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small" {...params}
                                            variant='standard'
                                            required
                                            autoComplete='off'
                                            onKeyDown={(e) => e.preventDefault()}
                                            onClick={() => { this.setState({ DateTimerOpen: true }) }}
                                            sx={{ width: "15dvw" }}
                                        />}
                                    />
                                </LocalizationProvider>
                                :
                                <TextField
                                    disabled={this.state.isFromInvoice || this.state.isFromSavedBill || this.state.isForInvoiceEdit || this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription || (getCachevalue("ForAdminApproval") === "true")}
                                    sx={{ width: statekey === "PatientMobileNumber" ? "12dvw" : "15dvw" }}
                                    inputProps={{ maxLength: statekey === "PatientMobileNumber" ? 10 : 32, emed_tid: statekey === "PatientMobileNumber" ? "Mobile Number" : "Patient Name" }}
                                    label={`${label}`}
                                    variant='standard'
                                    size="small"
                                    autoComplete="off"
                                    name={statekey}
                                    value={states[statekey] ? states[statekey] : ""}
                                    placeholder={statekey === "PatientMobileNumber" ? "Mobile Number" : "Patient Name"}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter" && event.target.value?.length === 10) {
                                            this.PharmacyPatientSearch()
                                        }
                                    }}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        let alpha = CommonValidation.alphabetOnly(value);
                                        let number = CommonValidation.numberOnly(value);
                                        if (statekey === "PatientMobileNumber") {
                                            if (number || value === "") {
                                                states[statekey] = value
                                                if (states?.PatientDetails?.patient_id) {
                                                    states["PatientName"] = ""
                                                }
                                            }
                                        } else if (statekey === "PatientName") {
                                            if (alpha || value === "") {
                                                states[statekey] = value
                                                if (states?.PatientDetails?.patient_id) {
                                                    states["PatientMobileNumber"] = ""
                                                }
                                            }
                                        } else {
                                            if (alpha || value === "") {
                                                states[statekey] = value
                                            }
                                        }
                                        this.setState({ states, MobileNumberAnchor: e.currentTarget, PatientDetails: {}, Patient_Id: null, Bill_ID: null, isCredit: false }, () => {
                                            if (statekey === "PatientMobileNumber" && states.PatientMobileNumber.length === 10) {
                                                this.PharmacyPatientSearch(false)
                                            } else {
                                                this.setState({ SearchedPatientList: [], common_uhid: false })
                                            }
                                        })
                                    }}
                                    InputProps={{
                                        endAdornment: statekey === "PatientMobileNumber" ?
                                            <InputAdornment position="end">
                                                {
                                                    this.state.isPatientisSearching ?
                                                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                                            <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                                        </Box> :
                                                        <IconButton size="small"
                                                            disabled={states.PatientMobileNumber === "" || this.state.isFromInvoice || this.state.isFromSavedBill || this.state.isForInvoiceEdit || this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                                                            onClick={() => {
                                                                if (states.PatientMobileNumber.length === 10) {
                                                                    this.PharmacyPatientSearch()
                                                                }
                                                            }}>
                                                            <SearchIcon />
                                                        </IconButton>}
                                            </InputAdornment> : null
                                    }}
                                />}
                </Box>
                <Menu
                    open={this.state.PatientSearchClicked}
                    className='eMed_Pharma_Mobile_Search_Pop'
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => {
                        this.setState({
                            SearchedPatientList: [],
                            PatientSearchClicked: false,
                            common_uhid: false,
                        })
                    }}
                    elevation={1}
                >
                    {
                        this.state.SearchedPatientList?.length > 0 ?
                            this.state.SearchedPatientList?.map((list, index) => (
                                <MenuItem emed_tid="Patient_mobile_suggestions"
                                    autoFocus={index === 0}
                                    onClick={() => {
                                        this.setState({
                                            SearchedPatientList: [],
                                            PatientSearchClicked: false,
                                            common_uhid: false,
                                            Patient_Id: list?.patient_id,
                                        }, () => { this.GetPatientDetails(list?.patient_id, this.state.isBillsFetched) })
                                    }}
                                    key={index}>
                                    <Box><CommonPatientDetails data={list} isCommonUhid={this.state.common_uhid} /></Box>
                                </MenuItem>
                            )) : null
                    }
                </Menu>
            </Box>
        )
    }

    deleteLineItems = (index = null) => {
        try {
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
            if (index === null || (index === 0 && BillingLineItems.length === 1)) {
                this.setState({
                    pharmacyTblData: [
                        {
                            drug_name: '',
                            dosage_strength: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            gst_percentage: null,
                            Drug_sugg_list: [],
                        }
                    ],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    CashAmount: "",
                    CardAmount: "",
                    CardTransactionNumber: "",
                    UPINumber: "",
                    UPIAmount: "",
                    BankTfrAmount: "",
                    BankTfrNumber: "",
                    ChequeNumber: "",
                    ChequeAmount: "",
                    InsuranceAmount: "",
                    InsuCompanyName: "",
                    InsuPolicyNumber: "",
                    InsuTransactionNumber: "",
                    InsuValidity: '',
                    subAmount: 0,
                    totalPercentage: '',
                    totalDiscount: '',
                    discountReason: '',
                    isSavedReason: false,
                    roundOff: 0,
                    netAmount: 0,
                    ReturnedNetAmount: 0,
                }, () => {
                })
            } else {
                if (index !== this.state.pharmacyTblData.length - 1) {
                    this.state.pharmacyTblData.splice(index, 1)
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => {
                        this.calculateTotalDiscount()
                    })
                } else {
                    this.state.pharmacyTblData[index] = {
                        drug_name: '',
                        dosage_strength: '',
                        dosage_type: '',
                        batch_no: '',
                        expiry_date: '',
                        physical_box_no: '',
                        cost_per_quantity: '',
                        quantity: '',
                        total_cost: '',
                        discount: '',
                        total_price: '',
                        hsn_code: '',
                        stock_in_quantity: '',
                        Drug_sugg_list: [],
                    }
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => {
                        this.calculateTotalDiscount()
                    })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FullAmountEntered() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            let InsuranceAmt = states.InsuranceAmount === "" ? 0 : +states.InsuranceAmount;
            if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == +states.netAmount && +states.netAmount !== 0) {
                if (!states.isFromInvoice) {
                    return true
                } else if (states.isForReturn) {
                    return true
                } else {
                    return false
                }
            } else if ((states?.isForReturn || states?.isFromIpReturn) && (+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0) {
                return true
            } else if (((states?.isForReturn || states?.isFromIpReturn) && ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == states.ReturnedNetAmount) || states.addToAdvance) && states.subAmount && +states.subAmount > 0) {
                return true
            }
            else {
                return false
            }
        }
        catch (e) {
            return false
        }
    }

    BillItemsIsValid = () => {
        try {
            let Flag = false
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "" && (this.state.isFromIpRequest ? item?.stock_in_quantity !== 0 : true)) { BillingLineItems.push(item) } })
            if (BillingLineItems.length > 0) {
                for (let i = 0; i < BillingLineItems.length; i++) {
                    if ((BillingLineItems[i]["drug_name"] !== "" && BillingLineItems[i]["batch_no"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") && BillingLineItems.length > 0) {
                        if (this.state.isForReturn || this.state.isFromIpReturn) {
                            if ((BillingLineItems[i]["return_quantity"] <= BillingLineItems[i]["quantity"]) && BillingLineItems[i]["return_quantity"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0") {
                                Flag = true
                            } else {
                                if ((BillingLineItems[i]["return_quantity"] > BillingLineItems[i]["quantity"])) {
                                    Flag = false
                                    this.errorMessage(`${BillingLineItems[i]["drug_name"]} Return Quantity is More then Actual Quantity`)
                                    this.setState({ disableBtn: false })
                                    break
                                }
                            }
                        } else {
                            if ((BillingLineItems[i]["quantity"] <= BillingLineItems[i]["stock_in_quantity"] || !BillingLineItems[i]["stock_in_quantity"]) && BillingLineItems[i]["quantity"] !== "") {
                                Flag = true
                            } else {
                                if (BillingLineItems[i]["quantity"] > BillingLineItems[i]["stock_in_quantity"]) {
                                    Flag = false
                                    this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is More then Stock Quantity`)
                                    this.setState({ disableBtn: false })
                                    break
                                }
                            }
                        }
                    } else {
                        if (BillingLineItems[i]["drug_name"] === "") {
                            this.errorMessage("No drug were Entered")
                            this.setState({ disableBtn: false })
                        }
                        else if (BillingLineItems[i]["batch_no"] === "") {
                            this.errorMessage(`${BillingLineItems[i]["drug_name"]} "Batch were Not Selected`)
                            this.setState({ disableBtn: false })
                        }
                        else if (BillingLineItems[i]["quantity"] === "" || BillingLineItems[i]["quantity"] === "0") {
                            this.errorMessage(`${BillingLineItems[i]["drug_name"]} Quantity is Not Entered`)
                            this.setState({ disableBtn: false })
                        }
                        else {
                            this.errorMessage("Invalid Bill Items")
                            this.setState({ disableBtn: false })
                        }
                        Flag = false
                        break
                    }
                }
                return Flag
            } else {
                Flag = false
                this.errorMessage("No Drug were Added")
                this.setState({ disableBtn: false })
                return Flag
            }

        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }

    Validation = () => {
        try {
            let states = this.state;
            let isHaveScheduledDrug = false
            for (let i = 0; i < this.state.pharmacyTblData.length; i++) {
                if (this.state.pharmacyTblData[i]?.drug_name !== "") {
                    isHaveScheduledDrug = this.isScheduledDrug(i)
                    if (isHaveScheduledDrug) { break }
                }
            }
            let DoctorValidation = isHaveScheduledDrug ? ((states.doctordetails?.name || states.doctordetails?.doctor_id) ? true : false) : true
            if (states.PatientName !== "" && ((DoctorValidation || !isHaveScheduledDrug) || this.state.isForReturn || this.state.isFromIpReturn) && this.BillItemsIsValid() && (this.state.totalDiscount === '' || (this.state.totalDiscount !== '' && this.state.discountReason !== ""))) {
                if ((this.state.isForReturn || this.state.isFromIpReturn) && !states.addToAdvance && states.BillReceivedAmount != 0 && states.BillReceivedAmount != states.totalBillNetAmount) {
                    this.setState({
                        isReturnClicked: true,
                        isLoader: false,
                        disableBtn: false
                    })
                } else {
                    this.postPharmacyBillData()
                }
            } else {
                this.LoaderFunction(false)
                if (states.PatientName === "") {
                    this.errorMessage("Enter Patient Name")
                    this.setState({ disableBtn: false })
                }
                else if (!DoctorValidation && !this.state.isForReturn && !this.state.isFromIpReturn) {
                    this.errorMessage("Bill Contains a Scheduled drug, so please select the doctor")
                    this.setState({ disableBtn: false })
                }
                else if (states.discountReason === "" && this.state.totalDiscount) {
                    this.errorMessage("Reason is mandatory.Click Note icon to enter reason")
                    this.setState({ disableBtn: false })
                }
            }
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }

    PrintInvoice() {
        try {
            RestAPIService.PrintPDF(Serviceurls.PHARMA_BILLS_GET + "?id=" + this.state.Bill_ID + "&export=pdf").
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    console.log(error.message)
                })
        } catch (e) {
            console.log(e.message)
        }
    }

    getInsuranceDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            insuranceData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message, 'error')
                    } else {
                        this.errorMessage(error.message, 'error')
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }
    getCorporateDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            corporateData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message, 'error')
                    } else {
                        this.errorMessage(error.message, 'error')
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }
    renderCreditType = () => {
        let details = this.state.PatientDetails
        let ins_name = details?.insurance_name ? details?.insurance_name : details?.insurance_company_name ? details?.insurance_company_name : this.state.InsName
        let ins_no = details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : this.state.InsNO
        let comp_name = details?.company_name ? details?.company_name : this.state.CropName
        let employee_no = details?.employee_id ? details?.employee_id : details?.id_no ? details?.id_no : this.state.CropNo
        let paymentCreditType = this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit'
        return (
            <Box>
                <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40dvw'} justifyContent={'space-between'}>
                    <Typography fontSize={'1dvw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                    <IconButton size='small' onClick={() => {
                        this.setState({
                            creditChange: false,
                            addInsurance: false,
                            addCorporate: false,
                            paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type,
                            insuranceName: null,
                            insuranceNo: '',
                            corpName: null,
                            employeeNo: '',
                            InsName: ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                            InsNO: ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                            CropName: comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                            CropNo: employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                            CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''
                        })
                    }}>
                        <img src={ImagePaths.Close.default} alt="close" style={{ width: '1dvw', height: '1dvw' }} />
                    </IconButton>
                </Box>
                <Box component={'div'} sx={{ height: '5dvw', marginTop: '2dvw', display: 'flex' }}>
                    <Box component={'div'} sx={{ width: '13dvw', height: '5dvw', borderRight: '1px solid lightgray', marginLeft: '1dvw' }}>
                        <Typography>Patient Credit</Typography>
                        <FormControlLabel value={'Patient credit'} control={<Radio checked={paymentCreditType === 'Patient credit'} size='small' />}
                            onClick={(e) => {
                                this.setState({
                                    paymentCreditType: e.target.value,
                                    addInsurance: false,
                                    addCorporate: false,
                                    corpName: null,
                                    employeeNo: '',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }} label="Patient Credit" />
                    </Box>
                    {this.state.PatientDetails?.ip_admission_id ?
                        <Box component={'div'} sx={{ width: '15dvw', height: '5dvw', borderRight: '1px solid lightgray', marginLeft: '1dvw' }}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Typography>Insurace Credit</Typography>
                                {ins_name ? <Tooltip title={'edit'} placement='top'>
                                    <IconButton size='small' onClick={() => {
                                        this.setState({
                                            addInsurance: true,
                                            addCorporate: false,
                                            paymentCreditType: 'Insurance credit',
                                            insuranceName: ins_name,
                                            insuranceNo: ins_no,
                                            corpName: null,
                                            employeeNo: ''
                                        })
                                    }}>
                                        <img style={{ width: '.9dvw', height: '.9dvw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                    </IconButton>
                                </Tooltip> : null}
                            </Box>
                            {ins_name ?
                                <FormControlLabel value={'Insurance credit'} control={<Radio checked={paymentCreditType === 'Insurance credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false, InsName: ins_name, InsNo: ins_no }) }}
                                    label={<Box>
                                        {ins_name?.length > 18 ?
                                            <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8dvw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                            : <Typography sx={{ fontSize: '0.8dvw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                        {ins_no?.length > 18 ?
                                            <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7dvw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                            : <Typography sx={{ fontSize: '0.7dvw', color: '#888888' }}>{ins_no}</Typography>}
                                    </Box>} /> :
                                <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                    onClick={() => {
                                        this.setState({
                                            addInsurance: true,
                                            addCorporate: false,
                                            paymentCreditType: 'Insurance credit',
                                            insuranceName: null,
                                            insuranceNo: ''
                                        })
                                    }}
                                >Add Insurance</Button>}
                        </Box>
                        : null}
                    <Box component={'div'} sx={{ width: '15dvw', height: '5dvw', marginLeft: '1dvw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Corporate Credit</Typography>
                            {comp_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                        corpName: comp_name,
                                        employeeNo: employee_no,
                                        insuranceName: null,
                                        insuranceNo: '',

                                    })
                                }}>
                                    <img style={{ width: '.9dvw', height: '.9dvw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {comp_name ?
                            <FormControlLabel value={'Corprate credit'} control={<Radio checked={paymentCreditType === 'Corprate credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false, CropName: comp_name, CropNo: employee_no }) }}
                                label={<Box>
                                    {comp_name?.length > 18 ?
                                        <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8dvw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8dvw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                    {employee_no?.length > 18 ?
                                        <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7dvw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7dvw', color: '#888888' }}>{employee_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                    })
                                }}
                            >
                                Add Corporate</Button>}
                    </Box>
                </Box>
                {this.state.addInsurance ?
                    <Box marginTop={'1dvw'} display={'flex'} height={'5dvw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.insuranceData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                            onChange={(e, value) => {
                                this.setState({
                                    insuranceName: value,
                                })
                            }}
                            value={this.state.insuranceName}
                            sx={{ width: '13dvw', marginRight: '1dvw' }}
                            renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Insurance No"
                            variant="outlined"
                            sx={{ width: '13dvw' }}
                            value={this.state.insuranceNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        insuranceNo: e.target.value
                                    })
                                }
                            }}
                        />
                    </Box> :
                    this.state.addCorporate ?
                        <Box marginTop={'1dvw'} display={'flex'} height={'5dvw'}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.corporateData}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        corpName: value,
                                    })
                                }}
                                value={this.state.corpName}
                                sx={{ width: '13dvw', marginRight: '1dvw' }}
                                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                            />
                            <TextField
                                size='small'
                                label="Employee ID"
                                variant="outlined"
                                sx={{ width: '13dvw' }}
                                value={this.state.employeeNo}
                                onChange={(e) => {
                                    let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                    if (e.target.value === "" || alphanum) {
                                        this.setState({
                                            employeeNo: e.target.value
                                        })
                                    }
                                }} />
                        </Box> : <Box marginTop={'1dvw'} display={'flex'} height={'5dvw'}></Box>}
                <Box marginTop={'2dvw'} display={'flex'} justifyContent={'flex-end'}>
                    <Stack spacing={2} direction="row">
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                            onClick={() => {
                                this.setState({
                                    creditChange: false,
                                    addInsurance: false,
                                    addCorporate: false,
                                    paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type,
                                    insuranceName: null,
                                    insuranceNo: '',
                                    corpName: null,
                                    employeeNo: '',
                                    InsName: ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                    InsNO: ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                                    CropName: comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                                    CropNo: employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                    CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''

                                })
                            }}>Back</Button>
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                            if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                                this.errorMessage("Invalid Insurance Details", 'error')
                            } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                                this.errorMessage("Invalid Corporate Details", 'error')
                            } else {
                                this.setState({
                                    creditChange: false,
                                    // InsName:this.state.insuranceName?.insurance_name,
                                    // CropName: this.state.corpName?.company_name,
                                    // InsNO:this.state.insuranceNo,
                                    // CropNo:this.state.employeeNo,
                                    paymentCreditType: this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit',
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                    InsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : ins_name ? ins_name : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                    InsNO: this.state.insuranceNo ? this.state.insuranceNo : ins_no ? ins_no : this.state.PatientDetails?.policy_no ? this.state.PatientDetails?.policy_no : '',
                                    CropName: this.state.corpName?.company_name ? this.state.corpName?.company_name : comp_name ? comp_name : this.state?.PatientDetails?.company_name ? this.state?.PatientDetails?.company_name : '',
                                    CropNo: this.state.employeeNo ? this.state.employeeNo : employee_no ? employee_no : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                    CompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.CompanyID ? this.state.CompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : ''
                                })
                            }
                        }}>Submit</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    navigateToApprovals = (msg, topage) => {
        clearCachevalue("ForAdminApproval");
        clearCachevalue("AdminApprovalData");
        clearCachevalue("patientData");
        clearCachevalue("IppatientData");
        clearCachevalue("isForCancelBill");
        clearCachevalue("CancelAdminApprovalData");
        this.props?.history.push({ pathname: "/Approval", state: { message: msg, "topage" : topage } })
    }

    PostApprovalData = (status = "") => {
        try {
            let ApprovalData = JSON.parse(getCachevalue("AdminApprovalData"))
            var data = {
                "bill_type": ApprovalData?.bill_type ? ApprovalData?.bill_type : "",
                "bill_id": ApprovalData?.bill_id ? ApprovalData?.bill_id : null,
                "request_id": ApprovalData?.id ? ApprovalData?.id : null,
                "discount_amount": this.state.totalDiscount ? +this.state.totalDiscount : 0,
                "discount_percent": this.state.totalPercentage ? +this.state.totalPercentage : 0,
                "request_status": status,
            }
            RestAPIService.create(data, Serviceurls.USER_APPROVAL_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.props.getmakerCount(true);
                        this.navigateToApprovals(response?.data?.message ? response.data.message : "")
                    } else {
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.errorMessage(error.message, "error")
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, "error")
        }
    }

    cancelBillPost = (comments) => {
        try {
            var data = {
                "bill_summary_id": this.state?.Bill_ID ? this.state?.Bill_ID : null,
                "pharmacy_id": this.state?.selectedPharmacy?.pharmacy_id ? this.state?.selectedPharmacy?.pharmacy_id : null,
            }
            this.LoaderFunction(true);
            if (this.state.LoggedRole === "user" && !this.state.perAccess?.common?.cancel_bill_approval) {
                this.CancelBillRequest(comments)
            } else {
            RestAPIService.create(data, Serviceurls.PHARMACY_BILL_CANCEL_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ disableBtn: false })
                        this.LoaderFunction(false);
                        this.ReasonPopupClose();
                        if (this.state.isFromIpBills) {
                            this.props?.history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' });
                            return;
                        } else if (this.state.isFromOpBills){
                            this.props?.history.push({ pathname: '/OPHome/PatientDetails/Billing' });
                            return;
                        } else {
                            this.props?.history.push({ pathname: '/BillingTransaction/OverAllBills' });
                            return;
                        }
                    } else {
                        this.LoaderFunction(false);
                        this.setState({ disableBtn: false })
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.setState({ disableBtn: false })
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.setState({ disableBtn: false })
                        this.LoaderFunction(false);
                        this.errorMessage(error.message, "error")
                    }
                })}
        } catch (error) {
            this.setState({ disableBtn: false });
            this.LoaderFunction(false);
            this.errorMessage(error.message, "error")
        }
    }
    
    CancelBillRequest = (comments) => {
        try {
            let request_data = {
                "bill_summary_id": this.state?.Bill_ID ? this.state?.Bill_ID : null,
                "pharmacy_id": this.state?.selectedPharmacy?.pharmacy_id ? this.state?.selectedPharmacy?.pharmacy_id : null,
            }
            let PatientData = this.state.PatientDetails
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}`
            let data = {
                'patient_id': PatientData?.patient_id ? PatientData?.patient_id : null,
                'appointment_id': PatientData?.appointment_id ? PatientData?.appointment_id : null,
                'ip_admission_id': PatientData?.ip_admission_id ? PatientData?.ip_admission_id : null,
                'bill_type': "PHARMACY",
                'bill_id': this.state?.Bill_ID ? this.state?.Bill_ID : null,
                'invoice_number': this.state.InvoiceNumber,
                'cancel_request_date': invoice_date,
                'patient_name_2': "",
                'bill_amount': this.state?.totalBillNetAmount ? this.state?.totalBillNetAmount : 0,
                'status': "Pending",
                'remarks': comments,
                "request_data": request_data,
                "module_id": this.state?.selectedPharmacy?.pharmacy_id ? this.state?.selectedPharmacy?.pharmacy_id : null,
            }
            RestAPIService.create(data, Serviceurls.CANCEL_APPROVAL_REQUEST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ disableBtn: false })
                        this.props.getmakerCount(true);
                        this.LoaderFunction(false);
                        clearCachevalue("PharmaCancelBillData");
                        clearCachevalue("isForCancelBill");
                        if (this.state.isFromIpBills) {
                            this.props?.history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' });
                        } else if (this.state.isFromOpBills){
                            this.props?.history.push({ pathname: '/OPHome/PatientDetails/Billing' });
                        } else{
                            this.props?.history.push({ pathname: '/BillingTransaction/OverAllBills' });
                        }
                    } else {
                        this.setState({ disableBtn: false })
                        this.LoaderFunction(false);
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.errorMessage(error.message, "error")
                    }
                    this.LoaderFunction(false);
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.setState({ disableBtn: false });
            this.LoaderFunction(false);
            this.errorMessage(error.message, "error")
        }
    }

    PostApprovalRequestData = (PatientId = null, AppointId = null, AdmissId = null, BillId = null, NetAmount = null, InvoiceNumber = null, InvoiceDate = null, ReqId = null) => {
        try {
            var data = {
                'patient_id': PatientId,
                'clinic_id': RoleData?.clinic_id ? RoleData?.clinic_id : null,
                'appointment_id': AppointId,
                'ip_admission_id': AdmissId,
                'bill_type': "PHARMACY",
                'bill_id': BillId,
                'invoice_number': InvoiceNumber,
                'bill_amount': NetAmount,
                'request_discount_amount': this.state.totalDiscount ? +this.state.totalDiscount : 0,
                'request_discount_percent': this.state.totalPercentage ? +this.state.totalPercentage : 0,
                'status': "Pending",
                'remarks': this.state.discountReason ? this.state.discountReason : '',
                'concession_date': InvoiceDate,
                'module_id': this.state.selectedPharmacy?.pharmacy_id ? this.state.selectedPharmacy?.pharmacy_id : null,
                'patient_name': this.state?.PatientName
            }
            if (ReqId) { data["id"] = ReqId }
            RestAPIService.create(data, Serviceurls.USER_REQUEST_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.props.getmakerCount(true);
                        if (ReqId) {
                            this.navigateToApprovals(response?.data?.message ? response.data.message : "")
                        }
                    } else {
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.errorMessage(error.message, "error")
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, "error")
        }
    }

    cancelbillreqPost = (reqData) => {
        try {
            var data = reqData;
            RestAPIService.create(data, Serviceurls.PHARMACY_BILL_CANCEL_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.navigateToApprovals(response?.data?.message ? response.data.message : "", "bill_cancel")
                    } else {
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.errorMessage(error.message, "error")
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, "error")
        }
    }

    PostCancelReqData = (status = "") => {
        try {
            let data = JSON.parse(getCachevalue("CancelAdminApprovalData"));
            var payload_data = {
                'id': data?.id,
                'patient_id': data?.patient_id ? data?.patient_id : null,
                'appointment_id': data?.appointment_id ? data?.appointment_id : null,
                'ip_admission_id': data?.ip_admission_id ? data?.ip_admission_id : null,
                'bill_type': data?.bill_type ? data?.bill_type : "",
                'bill_id': data?.bill_id ? data?.bill_id : null,
                'invoice_number': data?.invoice_number ? data?.invoice_number : "",
                'cancel_request_date': data?.cancel_request_date ? data?.cancel_request_date : "",
                'patient_name_2': "",
                'bill_amount': data?.bill_amount ? data?.bill_amount : 0,
                'status': status,
                'remarks': data?.remarks ? data?.remarks : "",
            }
            RestAPIService.create(payload_data, Serviceurls.CANCEL_APPROVAL_REQUEST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.props.getmakerCount(true);
                        if (status === "Approved") {
                            this.cancelbillreqPost(data?.request_data)
                        } else {
                            this.navigateToApprovals(response?.data?.message ? response.data.message : "", "bill_cancel")
                        }
                    } else {
                        this.errorMessage(response.data.message, "error")
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message, "error")
                    } else {
                        this.errorMessage(error.message, "error")
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, "error")
        }
    }

    renderPaymentButton = () => {
        let states = this.state
        let enableIPaddAdvance = (states.BillReceivedAmount == states.totalBillNetAmount && states.isCurrentIP) ? true : false
        const cancel_reason = JSON.parse(getCachevalue("CancelAdminApprovalData") || "{}")?.remarks || "";
        const concession_reason = JSON.parse(getCachevalue("AdminApprovalData") || "{}")?.remarks || "";

        try {
            return (
                <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }}>
                    <Box flex={0.7} display={'flex'} alignItems={'center'} marginLeft={'1dvw'} sx={{ visibility: (getCachevalue("ForAdminApproval") === "true") ? 'hidden' : 'visible' }}>
                        {(!(!this.state.PatientDetails?.patient_id || this.state.isForInvoiceEdit)) ?
                            <FormGroup >
                                <box display="row">
                                    <FormControlLabel
                                        disabled={this.state.netAmount === 0 ? true : !this.state.PatientDetails?.patient_id || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || this.state.subAmount <= 0|| this.state.isForCancel}
                                        control={<Checkbox emed_tid={"add_credit_bill"}
                                            checked={this.state.isCredit}
                                            onChange={(e) => { this.handleCheckBox(e, "isCredit") }} />}
                                        label={'Add To Credit'} />
                                    <Tooltip title={'Change Credit Type'} placement='top'>
                                        <IconButton size='small'
                                            disabled={!this.state.PatientDetails?.patient_id || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || this.state.subAmount <= 0 || this.state.isCredit === false}
                                            onClick={() => {
                                                this.setState({
                                                    creditChange: true,
                                                }, () => {
                                                    this.getInsuranceDetails()
                                                    this.getCorporateDetails()
                                                })
                                            }}>
                                            <img style={{ width: '1.2dvw', height: '1.2dvw' }} src={!this.state.isCredit ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                                        </IconButton>
                                    </Tooltip>
                                </box>
                            </FormGroup> :
                            (enableIPaddAdvance && states.isForReturn) ?
                                <FormGroup>
                                    <FormControlLabel disabled={!this.state.PatientDetails?.patient_id || this.state.isFromIpReturn || this.state.isForInvoiceEdit || this.state.isCash || this.state.isCard || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || (!states.subAmount)} className='' control={<Checkbox emed_tid={"add_to_adv"} checked={this.state.addToAdvance} onChange={(e) => { this.handleCheckBox(e, "addToAdvance") }} />} label={'Add To IP Advance'} />
                                </FormGroup> : null
                        }
                        {(states?.paymentCreditType == "Insurance" || states?.paymentCreditType == "Insurance credit") ?
                            <div style={{ marginLeft: "2.5dvw" }}>
                                {this.state.InsName?.length > 25 ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginRight: "0.5dvw", fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>Insurance Patient : </div>
                                        <Tooltip placement='top' title={this.state.InsName} arrow>
                                            <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{this.state.InsName.slice(0, 25) + "..."}</div>
                                        </Tooltip>
                                        <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.InsNO}`}</div>
                                    </div>
                                    :
                                    <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{`Insurance Patient : ${this.state.InsName}  |  ${this.state.InsNO}`}</div>}
                            </div>
                            : null}
                        {(states?.paymentCreditType == "Corporate" || states?.paymentCreditType == "Corprate credit") ?
                            <div style={{ marginLeft: "0.5dvw" }}>
                                {this.state.CropName?.length > 25 ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginRight: "0.5dvw", fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>Corporate Patient : </div>
                                        <Tooltip placement='top' title={this.state.CropName} arrow>
                                            <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{this.state.CropName.slice(0, 25) + "..."}</div>
                                        </Tooltip>
                                        <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.CropNo}`}</div>
                                    </div>
                                    :
                                    <div style={{ fontSize: '0.6dvw', color: '#616161', fontWeight: 'bold' }}>{`Corporate Patient : ${this.state.CropName}  |  ${this.state.CropNo}`}</div>}
                            </div>
                            : null}
                    </Box>
                    {
                        (getCachevalue("ForAdminApproval") === "true") ?
                            <Box flex={0.7} display={'flex'} justifyContent={'flex-end'} columnGap={"0.8dvw"} marginRight={"0.625dvw"}>
                                <Tooltip
                                    title={concession_reason && concession_reason?.length > 100 ? concession_reason : ""}
                                    arrow
                                    placement="top"
                                >
                                    <TextField
                                        label="Concession Notes"
                                        sx={{ width: '25dvw' }}
                                        disabled
                                        size="small"
                                        value={concession_reason || ""}
                                    />
                                </Tooltip>
                                <Button size='small' variant='outlined' className='emed_approve_btn'
                                    onClick={() => { this.navigateToApprovals() }}
                                >Back to Approvals</Button>
                                {(RoleData?.is_user) ?
                                    <>
                                        <Button size='small' variant='contained' className='emed_approve_btn'
                                            onClick={() => {
                                                let ApprovalData = JSON.parse(getCachevalue("AdminApprovalData"))
                                                this.PostApprovalRequestData(
                                                    ApprovalData?.patient_id,
                                                    ApprovalData?.appointment_id,
                                                    ApprovalData?.ip_admission_id,
                                                    ApprovalData?.bill_id,
                                                    ApprovalData?.bill_amount,
                                                    ApprovalData?.invoice_number,
                                                    ApprovalData?.concession_date,
                                                    ApprovalData?.id,
                                                )
                                            }}
                                        >Save Request</Button>
                                    </> :
                                    <Box display = {"flex"} alignItems={"center"} justifyContent={"flex-end"} columnGap={"0.8dvw"}>
                                        <Button size='small' variant='contained' color='error' className='emed_approve_btn' onClick={() => {
                                            this.PostApprovalData("Cancelled")
                                        }}>Reject</Button>
                                        <Button size='small' variant='contained' className='emed_approve_btn'
                                            onClick={() => {
                                                this.PostApprovalData("Approved")
                                            }}
                                        >Approve</Button>
                                    </Box>}
                            </Box> 
                            : getCachevalue("CancelAdminApprovalData") ?
                                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} columnGap={"0.8dvw"} marginTop={"0.5dvw"}>
                                    <Tooltip
                                        title={cancel_reason && cancel_reason?.length > 100 ? cancel_reason : ""}
                                        arrow
                                        placement="top"
                                    >
                                        <TextField
                                            label="Cancel Notes"
                                            sx={{ width: '25dvw' }}
                                            disabled
                                            size="small"
                                            value={cancel_reason || ""}
                                        />
                                    </Tooltip>
                                    <Button size='small' variant='outlined' className='emed_approve_btn'
                                        onClick={() => { this.navigateToApprovals("", "bill_cancel") }}
                                    >Back to Approvals</Button>
                                    <Button size='small' variant='contained' color='error' className='emed_approve_btn' onClick={() => {
                                        this.PostCancelReqData("Cancelled")
                                    }}>Reject</Button>
                                    <Button size='small' variant='contained' className='emed_approve_btn'
                                        onClick={() => {
                                            this.PostCancelReqData("Approved")
                                        }}
                                    >Approve</Button>
                                </Box>
                                : this.state.isForCancel ?
                                <Box flex={0.7} display={'flex'} justifyContent={'flex-end'}>
                                    <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5dvw 1dvw 0.5dvw 0dvw', textTransform: 'capitalize', width: "10dvw" }}
                                        size='small'
                                        variant='contained'
                                        color='error'
                                        disabled={(this.state?.isLoader || this.state.disableBtn)}
                                        onClick={() => {
                                            this.setState({
                                                cancelBillClicked: true,
                                                disableBtn: true,
                                            })
                                        }}
                                    >{'Cancel'}</Button>
                                </Box>
                                :<Box flex={0.7} display={'flex'} justifyContent={'flex-end'}>
                                    <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5dvw 1dvw 0.5dvw 0dvw', textTransform: 'capitalize' }}
                                        disabled={this.state.isForReturn ? false : this.state.isFromInvoice}
                                        size='small'
                                        variant='outlined'
                                        onClick={() => this.onClearTableData()}
                                    >Clear</Button>

                                    <Button emed_tid="bill_saved_btn" sx={{ margin: '0.5dvw 1dvw 0.5dvw 0dvw', textTransform: 'capitalize' }}
                                        size='small'
                                        variant='outlined'
                                        disabled={(this.state?.isLoader || this.state.disableBtn) ? true : (this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isBankTranfer || this.state.isInsurance || (this.state.isCredit && !this.state.isForSendApproval))}
                                        onClick={() => { this.setState({ disableBtn: true, savedBill: true }, () => this.Validation()) }}
                                    >Save as Draft</Button>
                                    {this.state.isFromInvoice ?
                                        <Button emed_tid="bill_print_btn" sx={{ margin: '0.5dvw 1dvw 0.5dvw 0dvw', textTransform: 'capitalize' }}
                                            size='small'
                                            variant='outlined'
                                            onClick={() => { this.PrintInvoice() }}
                                        >Print Invoice</Button> : null}
                                    {
                                        this.state.LoggedRole === "user" && !this.state.perAccess?.common?.concession_access && this.state.totalDiscount ?
                                            <Button emed_tid="bill_sent_request_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                                                size='small'
                                                variant='contained'
                                                disabled={this.state.netAmount === 0 ? true : (this.state?.isLoader || this.state.disableBtn) ? true : false}
                                                onClick={() => {
                                                    this.LoaderFunction(true)
                                                    this.setState({ disableBtn: true }, () => {
                                                        this.setState({ creditPayment: true }, () => this.Validation())
                                                    })
                                                }}
                                            >{'Send Request'}</Button> :
                                            <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5dvw 1dvw 0.5dvw 0dvw', textTransform: 'capitalize', width: "10dvw" }}
                                                size='small'
                                                variant='contained'
                                                disabled={this.state.netAmount === 0 ? false :
                                                    (this.state?.isLoader || this.state.disableBtn) ? true :
                                                        (!this.state.isCredit && (this.state.isForReturn ? !this.FullAmountEntered() :
                                                            (this.state.isFromInvoice ? true : !this.FullAmountEntered())))}
                                                onClick={() => {
                                                    this.LoaderFunction(true)
                                                    this.setState({ disableBtn: true }, () => {
                                                        if (this.state.isCredit) {
                                                            this.setState({ creditPayment: true }, () => this.Validation())
                                                        } else if (states.addToAdvance) {
                                                            this.Validation()
                                                        } else {
                                                            this.setState({ receivePayment: true }, () => this.Validation())
                                                        }
                                                    })
                                                }}
                                            >{this.state.isCredit ? 'Add To Credit' : (this.state.isForReturn || this.state.isFromIpReturn) ? "Return" : this.state.isForInvoiceEdit ? "Update Invoice" : 'Receive Payment'}</Button>}
                                </Box>

                    }

                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }



    customPharmaFooter = (params) => {
        try {
            const { t } = this.props
            let states = this.state
            let disable = this.state.subAmount ? false : true;
            const disableModePay = (this.state.isCredit || this.state.subAmount <= 0 || states.addToAdvance || this.state.isFromInvoice);
            return (
                <Box component={'div'} height={'12dvw'} border={"none"} className='eMed_Pharmabill_Table_Container'>
                    <Box component={'div'} display={'flex'}>
                        <Box flex={0.75} overflow={'scroll'}>
                            <Box component={'div'} border={'1px solid lightgray'} pl={'1dvw'} marginTop={'0.5dvw'} height={'10dvw'} overflow={'scroll'} visibility={((states?.isForReturn || states?.isFromIpReturn) && (+states.BillReceivedAmount === 0 || states.BillReceivedAmount != states.totalBillNetAmount) && states.subAmount && +states.subAmount > 0 || (getCachevalue("ForAdminApproval") === "true")) ? "hidden" : "visible"}>
                                <Box component={'div'} style={{ marginTop: "0.5dvw" }} className='eMed_Pharma_payment_card'>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Mode Of Payment</Typography>
                                </Box>
                                <Box component={'div'} className=''>
                                    <Box>
                                        <FormControlLabel disabled={disableModePay} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="cash_checkbox" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                                        <FormControlLabel disabled={disableModePay} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="card_checkbox" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
                                        <FormControlLabel disabled={disableModePay} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="upi_checkbox" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                                        <FormControlLabel disabled={disableModePay} className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10dvw" }} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                                    </Box>
                                    <Box component={'div'} display={this.state.FromMultiReturn ? "none" : 'flex'} ml={'0.5dvw'}>
                                        {
                                            this.state.isCash ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                                    {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isInsurance ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                            {this.renderNumberTextFields("Received Amount", "ReceivedCashAmount", true)}
                                                            {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                                        </Box> : null
                                                    }
                                                </Box> : null
                                        }
                                        {
                                            this.state.isCard ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                                    {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isUPI ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                    {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isBankTranfer ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                    {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isCheque ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                                    {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isInsurance ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5dvw"}>
                                                    {this.renderAmountTextFields("InsuranceAmount", "InsuranceAmount", ImagePaths.ChequeIcon.default)}
                                                    {this.renderNumberTextFields("Company Name *", "InsuCompanyName", true)}
                                                    {this.renderNumberTextFields("Policy Number *", "InsuPolicyNumber", true)}
                                                    {this.renderNumberTextFields("Transaction Number", "InsuTransactionNumber", true)}
                                                    {this.renderNumberTextFields("Validity", "InsuValidity", true)}
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box component={'div'} flex={0.25}>
                            <Box component={'div'} className='eMed_Pharmabill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9dvw'}>{t("Sub Amount")}</Typography>
                                <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8dvw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
                            </Box>
                            {this.state.isForReturn || this.state.isFromIpReturn ?
                                <Box component={'div'} className='eMed_Pharmabill_tbl_footer'>
                                    <Typography mt={0.3} fontSize={'0.9dvw'}>{t("Received Amount")}</Typography>
                                    <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8dvw'}>{this.billingCalculation(params, 'ReceivedAmount')}</Typography>
                                </Box> : null}
                            <Box component={'div'} className='eMed_Pharmabill_tbl_footer'>
                                <Typography mt={0.3} fontSize={'0.9dvw'}>{`${t("Discount")} %`}</Typography>
                                {this.renderTextBox('totalPercentage', this.state.totalPercentage, '2dvh', disable)}
                                {this.renderTextBox('totalDiscount', this.state.totalDiscount, '2dvh', disable)}
                                {(this.state.totalPercentage || this.state.totalDiscount) && !this.state.isForView && !this.state.isForReturn && !this.state.isForCancel ?
                                    <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : ""}>
                                        <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : "" })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5dvw'} height={'1.5dvw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                                    </Tooltip> :
                                    <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1dvw'} height={'1dvw'} alignSelf={'center'} />
                                }
                            </Box>
                            <Box component={'div'} className='eMed_Pharmabill_tbl_footer'>
                                <Typography mt={0.3} fontSize={'0.9dvw'}>{t("RoundOff")}</Typography>
                                <Typography emed_tid="total_roundoff_amount" mt={0.5} mr={'0.8dvw'}>
                                    {this.billingCalculation(params, 'roundOff')}
                                </Typography>
                            </Box>
                            <Box component={'div'} className='eMed_Pharmabill_tbl_footer' borderTop={"2px solid gray"}>
                                <Typography mt={0.3} fontSize={'0.9dvw'} fontWeight='600'>{(this.state.isForReturn || this.state.isFromIpReturn || this.state.BillDetails?.billing_status === "Returned") ? t("Refundable Amount") : t("PayableAmount")}</Typography>
                                <Typography emed_tid="total_payable_amount" mt={0.5} mr={'0.8dvw'} fontWeight='600'>
                                    {this.billingCalculation(params, 'payableAmount')}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }} component={'div'} className='eMed_Pharmabill_tbl_footer'>
                                <Typography mt={0.3} fontSize={'0.9dvw'} >{t("Margin Profit")}</Typography>
                                <Typography emed_tid="total_margin_profit" mt={0.5} mr={'0.8dvw'}>
                                    {this.billingCalculation(params, 'marginProfit')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {this.renderPaymentButton()}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2dvw'} display={'flex'} m={'1dvw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Concession Reason</Typography>
                        </Box>
                        <Box m={'1dvw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                inputProps={{ maxLength: 250}}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value === "" || value.length <= 250) {
                                        this.setState({ discountReason: value })
                                    }
                                }}
                            />
                        </Box>
                        <Box m={'1dvw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5dvw', height: '2dvw', mr: '1dvw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false })
                                    if (!this.state.isSavedReason) {
                                        this.setState({ discountReason: '' })
                                    }
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5dvw', height: '2dvw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false, isSavedReason: true })
                                    } else {
                                        this.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }

    isScheduledDrug(index) {
        try {
            if (
                this.state.pharmacyTblData[index]?.schedule &&
                this.state.pharmacyTblData[index]?.schedule !== null &&
                this.state.pharmacyTblData[index]?.schedule !== "" &&
                this.state.pharmacyTblData[index]?.drug_name &&
                this.state.pharmacyTblData[index]?.drug_name !== "" &&
                this.state.pharmacyTblData[index]?.batch_no &&
                this.state.pharmacyTblData[index]?.batch_no !== "") {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }

    renderDrugColumn = (index, item) => {
        let frequent = "Frequently Brought Medicine"
        let { pharmacyTblData } = this.state
        return (
            <Box>
                <Tooltip title={pharmacyTblData[index]['drug_name']?.length > 18 ? (pharmacyTblData[index]['drug_name'] ? pharmacyTblData[index]['drug_name'] : "") : ""} placement="top" arrow>
                    <TextField
                        inputRef={this.DrugNameRef}
                        size='small'
                        variant='standard'
                        autoComplete='off'
                        value={item.drug_name}
                        disabled={this.state.isFromInvoice || (getCachevalue("ForAdminApproval") === "true")}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                pharmacyTblData[index]['dosage_strength'] = ""
                                pharmacyTblData[index]['batch_no'] = ""
                                pharmacyTblData[index]['dosage_type'] = ""
                                pharmacyTblData[index]['physical_box_no'] = ""
                                pharmacyTblData[index]['cost_per_quantity'] = ""
                                pharmacyTblData[index]['total_cost'] = ""
                                pharmacyTblData[index]['quantity'] = ""
                                pharmacyTblData[index]['discount'] = ""
                                pharmacyTblData[index]['total_price'] = ""
                                pharmacyTblData[index]['hsn_code'] = ""
                                pharmacyTblData[index]['stock_in_quantity'] = ""
                                pharmacyTblData[index]['expiry_date'] = ""
                                pharmacyTblData[index]['gst_percentage'] = null
                                pharmacyTblData[index]['Drug_sugg_list'] = []
                                delete pharmacyTblData[index]['Batch_Sugg_list']
                                this.setState({ pharmacyTblData }, () => {
                                    if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                                        this.setState({
                                            DurgNameCurrentIndex: index
                                        }, () => { this.getBrandListData(index) })
                                    }
                                })
                            } else if (e.target.value === "" && index !== (pharmacyTblData.length - 1)) {
                                this.state.pharmacyTblData.splice(index, 1)
                                this.setState({ pharmacyTblData: this.state.pharmacyTblData })
                            } else {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                this.setState({ pharmacyTblData })
                            }
                        }}
                        sx={{ width: '12.5dvw' }}
                        className={this.isScheduledDrug(index) ? 'eMed_DrugBox_Scheduled' : null}
                        onKeyDown={(e) => {
                            this.setState({
                                DrugNameAnchor: e.currentTarget,
                                FocusDrugList: e.key === "ArrowDown" ? true : false
                            }, () => {
                                if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                                    this.setState({
                                        DurgNameCurrentIndex: index
                                    }, () => { this.getBrandListData(index) })
                                }
                            })
                        }}
                        inputProps={{ emed_tid: "bill_drug_name", style: { color: this.isScheduledDrug(index) ? 'rgb(150, 13, 11)' : null, paddingLeft: '0.4dvw' } }}
                        InputProps={{
                            endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
                        }}

                    />
                </Tooltip>


                <Menu
                    open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0 && item.drug_name !== ""}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.DrugNameAnchor}
                    onClose={() => {
                        this.setState({ DurgNameCurrentIndex: null })
                    }}
                    autoFocus={false}
                    disableAutoFocusItem
                    disableAutoFocus
                >
                    <Box width={"26dvw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3dvw' }}>
                            <Tooltip placement='top' title={frequent}><Typography width={"70%"} fontWeight={'600'} fontSize={"0.8dvw"} paddingLeft={'1dvw'}>{frequent}</Typography></Tooltip>
                            <Typography width={"30%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8dvw"}>Quantity</Typography>
                        </Box>
                        <Box maxHeight={'14dvw'} overflow={'scroll'}>
                            {
                                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                                    item.Drug_sugg_list.map((list, Drugindex) => (
                                        <MenuItem
                                            onKeyDown={(e) => {
                                                if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                    e.stopPropagation()
                                                    this.setState({ DurgNameCurrentIndex: index })
                                                }
                                            }}
                                            emed_tid="bill_drug_sugg_li"
                                            key={Drugindex}
                                            autoFocus={this.state.FocusDrugList && Drugindex === 0}
                                            onClick={() => {
                                                this.setState({
                                                    DurgNameCurrentIndex: null
                                                }, () => {
                                                    this.onAddHandler("quantity", index)
                                                    this.autocompleteOnChangeHandler(list, "drug_name", index)
                                                })
                                            }}
                                            sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box width={"70%"}>
                                                <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                                            </Box>
                                            <Typography width={"30%"} fontSize={"0.8dvw"} textAlign={"center"}>{list?.in_stock}</Typography>
                                        </MenuItem>
                                    )) : null
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }
    renderBatchColumn = (index, item) => {
        return (
            <Box>
                <TextField
                    inputRef={this.BatchNameRef}
                    disabled={this.disabled('batch_no', index) || (getCachevalue("ForAdminApproval") === "true")}
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    value={item.batch_no}
                    onKeyDown={(e) => {
                        e.preventDefault()
                    }}
                    onFocus={(e) => {
                        this.setState({
                            BatchAnchor: e.currentTarget,
                            CurrentBatchIndex: index
                        }, () => {
                            e.preventDefault()
                            if ((!this.state.pharmacyTblData[index]['Batch_Sugg_list'])) {
                                this.getDrugDetailsData(index, false)
                            }
                        })
                    }}
                    inputProps={{ emed_tid: "bill_batch_name", style: { paddingLeft: '0.4dvw' } }}
                    InputProps={{
                        endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
                    }}

                />
                <Menu
                    open={this.state.CurrentBatchIndex === index}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.BatchAnchor}
                    onClose={() => {
                        this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
                    }}
                    autoFocus={true}
                    disableAutoFocusItem
                    disableAutoFocus
                    disableRestoreFocus
                >
                    <Box width={"30dvw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4dvw' }} emed_tid={"bill_batch_no_items"}>
                            <Typography width={"35%"} fontWeight={600} fontSize={'0.8dvw'} paddingLeft={'1dvw'}>Batch No</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8dvw'} >Expiry Date</Typography>
                            <Typography width={"25%"} fontWeight={600} fontSize={'0.8dvw'}>Avl/Qty</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8dvw'} >Cost/Qty</Typography>
                        </Box>
                        <Box maxHeight={'14dvw'} overflow={'scroll'}>
                            {
                                (item.Batch_Sugg_list && item.Batch_Sugg_list.length > 0) ?
                                    item.Batch_Sugg_list.map((list, Drugindex) => {
                                        var datePart = list?.expiry_date ? list?.expiry_date.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <MenuItem
                                                onKeyDown={(e) => {
                                                    if (Drugindex === item.Batch_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                    else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                        e.stopPropagation()
                                                        this.setState({ DurgNameCurrentIndex: index })
                                                    }
                                                }}
                                                emed_tid={`batch_suggestion`}
                                                key={Drugindex}
                                                autoFocus={true}
                                                onClick={() => { this.autocompleteOnChangeHandler(list, 'batch_no', index) }}
                                                sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? { backgroundColor: '#f0bc89' } : null }]}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                                                    {list?.batch_no ? list?.batch_no?.length > 12 ?
                                                        <Tooltip placement='top' title={list?.batch_no} arrow>
                                                            <Typography width={"35%"} fontSize={'0.8dvw'}>{list?.batch_no.slice(0, 10) + "..."}</Typography>
                                                        </Tooltip>
                                                        : <Typography width={"35%"} fontSize={'0.8dvw'}>{list?.batch_no}</Typography> : "-"}
                                                    <Typography width={"20%"} fontSize={'0.8dvw'}>{ExpiryDate}</Typography>
                                                    <Typography width={"25%"} fontSize={'0.8dvw'}>{list?.stock_in_quantity}</Typography>
                                                    <Typography width={"20%"} fontSize={'0.8dvw'} textAlign={'center'}>{list?.cost_per_quantity}</Typography>
                                                </Box>
                                            </MenuItem>
                                        )
                                    }) : <Typography textAlign={"center"} paddingY={"0.5dvw"} width={"100%"} fontSize={'0.8dvw'}>{"No Batches Found"}</Typography>
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }

    DiscardAction = (flag, MovetoInvoice = false, MoveToReturnTab = false) => {
        if (flag) {
            const EmptyBillItem = [
                {
                    drug_name: '',
                    dosage_strength: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    gst_percentage: null,
                    Drug_sugg_list: [],
                }
            ]
            try {
                this.setState({
                    pharmacyTblData: EmptyBillItem,
                    drugDetailsData: [],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    ChequeAmount: '',
                    CashAmount: '',
                    CardAmount: '',
                    BankTfrAmount: '',
                    UPIAmount: '',
                    CardTransactionNumber: '',
                    ChequeNumber: '',
                    BankTfrNumber: '',
                    UPINumber: '',
                    netAmount: 0,
                    subAmount: 0,
                    roundOff: 0,
                    totalDiscount: '',
                    totalPercentage: '',
                    DurgNameCurrentIndex: null,
                    PatientDetails: {},
                    BillDetails: {},
                    PatientSearchClicked: false,
                    SearchedPatientList: [],
                    doctordetails: {},
                    PatientMobileNumber: "",
                    PatientName: "",
                    invoiceDateTime: new Date(),
                    DateTimeChanged: false,
                    ShowDiscard: false,
                    Patient_Id: null,
                    ReceivedCashAmount: "",
                    CashBalanceAmount: "",
                    isFromInvoice: false,
                    isForReturn: false,
                    isFromSavedBill: false,
                    isForInvoiceEdit: false,
                    isFromIpRequest: false,
                    isFromIpReturn: false,
                    isFromPrescription: false,
                    ShowHistory: false,
                    CompletedBillID: null,
                    Bill_ID: null,
                    SearchingDrugIndex: null,
                    SearchingBatchIndex: null,
                    isBillsFetched: false,
                    InvoiceNumber: null,
                    isCredit: false,
                    savedBill: false,
                    PrescHistoryData: null,
                    PrescriptionId: null,
                    OutStandingAmount: 0,
                    BillReceivedAmount: 0,
                    ReturnedNetAmount: 0,
                    IpRequestAdmId: null,
                    MasterBillData: {},
                    discountReason: "",
                    isSavedReason: false,
                    common_uhid: false,
                    creditChange: false,
                    addInsurance: false,
                    addCorporate: false,
                    paymentCreditType: '',
                    corpName: null,
                    employeeNo: '',
                    insuranceName: null,
                    insuranceNo: '',
                    CropName: '',
                    CropNo: '',
                    InsName: '',
                    InsNO: '',

                })
            } catch (error) {
                this.errorMessage(error.message)
            }
        } else {
            this.setState({ ShowDiscard: false })
        }
    }

    clearSinglePayment = (item) => {
        if (item === "2") {
            this.setState({
                CardTransactionNumber: "",
            })
        } else if (item === "4") {
            this.setState({
                UPINumber: "",
            })
        } else if (item === "5") {
            this.setState({
                BankTfrNumber: ""
            })
        } else if (item === "6") {
            this.setState({
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: "",
            })
        }
        this.setState({
            secondAmount: ""
        })
    }

    ClosePopUp() {
        this.setState({ CompletedBillID: null }, () => {
            this.DiscardAction(true, this.state.isFromInvoice)
        })
    }

    ReturnPartialBill = (data) => {

        this.setState({
            isCash: data?.isCash,
            isUPI: data?.isUPI,
            isBankTranfer: data?.isBankTranfer,
            CashAmount: data?.CashAmount ? +data?.CashAmount : 0,
            UPIAmount: data?.UPIAmount ? +data?.UPIAmount : 0,
            BankTfrAmount: data?.BankTfrAmount ? +data?.BankTfrAmount : 0,
            BankTfrNumber: data?.BankTfrNumber ? data?.BankTfrNumber : "",
            UPINumber: data?.UPINumber ? data?.UPINumber : "",
            ReceiptAmt: data?.refundAmt ? data?.refundAmt : 0,
            AdvanceAmt: data?.AdvanceAmt ? data?.AdvanceAmt : 0,
            isReturnClicked: false,
            isLoader: true
        }, () => {
            this.postPharmacyBillData()
        })
    }

    ReasonPopupClose = () => {
        this.setState({
            cancelBillClicked: false,
            isLoader: false,
            disableBtn: false,
        })
    }

    addCancelComments = (comments, ReceiptType) => {
        this.cancelBillPost(comments, ReceiptType)
    }

    render() {
        let { BillReceivedAmount, outstandingAmt, ReturnedNetAmount, totalBillNetAmount, pharmaList } = this.state;
        let condition = 0
        let PendingAmt = outstandingAmt
        if (!PendingAmt && BillReceivedAmount && (BillReceivedAmount != totalBillNetAmount)) {
            PendingAmt = totalBillNetAmount - BillReceivedAmount
        }
        if (BillReceivedAmount == 0) {
            condition = 6
        } else if (BillReceivedAmount == totalBillNetAmount) {
            condition = 5
        } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
            condition = 4
        } else if (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt) {
            condition = 3
        } else if ((ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
            condition = 2
        } else if ((ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
            condition = 1
        }
        return (
            <Box component={'div'} className='eMed_Pharmabill_Billing_container'>
                <Box component={'div'} border={"none"} className='eMed_Pharmabill_Header_Card'>
                    {
                        (this.state.InvoiceNumber) ?
                            <Box marginRight={"0.6vw"}>
                                <Typography fontSize={'0.7dvw'}>Invoice Number</Typography>
                                <Typography fontSize={'0.9dvw'} fontWeight={600}>{this.state.InvoiceNumber ? this.state.InvoiceNumber : '-'}</Typography>
                            </Box> : null
                    }
                    <Autocomplete
                        disableClearable
                        value={this.state.selectedPharmacy ? this.state.selectedPharmacy : ""}
                        sx={{ width: "20dvw", marginRight: "0.725dvw" }}
                        size="small"
                        options={pharmaList?.length > 0 ? pharmaList : []}
                        getOptionLabel={option => typeof (option) === "string" ? option : option.pharmacy_name}
                        onChange={(event, newValue) => {
                            this.setState({
                                selectedPharmacy: newValue,
                            })
                        }}
                        disabled={this.state.isFromInvoice || (getCachevalue("ForAdminApproval") === "true")}
                        renderInput={(params) => (
                            <TextField
                                sx={{ width: '18.5dvw', marginLeft: '1.4dvw', marginTop: '0.4dvw' }}
                                {...params}
                                label={"Selected Pharmacy"}
                                autoComplete="off"
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                    />
                </Box>
                {
                    <Box height={"74dvh"}>
                        <Box component={'div'} height={'3dvw'} className='eMEd_pharma_Home_header'>
                            {this.renderSearch((this.state.PatientDetails?.patient_tag && this.state.PatientDetails?.patient_tag !== "") ? `Patient Mobile - (PT : ${this.state.PatientDetails?.patient_tag})` : "Patient Mobile", "PatientMobileNumber", ImagePaths.MobileCardIcon.default)}
                            {this.renderSearch((this.state.PatientDetails?.patient_account_number && this.state.PatientDetails?.patient_account_number !== "null") ? `Patient Name*   -   (UHID : ${this.state.PatientDetails?.patient_account_number})` : "Patient Name *", "PatientName", ImagePaths.AvatarCardIcon.default)}
                            {this.renderSearch("Consultant Name | Reg No", "doctordetails", ImagePaths.Doctor_Icon.default, this.state.DoctorsList)}
                            {this.renderSearch("IP / OP Number", "ipopNumber", ImagePaths.IPNumberCard.default, this.state.DoctorsList)}
                            {this.renderSearch("Invoice Date & Time", "invoiceDateTime", ImagePaths.CalenderIcon.default,)}
                        </Box>
                        <Box component={'div'}>
                            <Box component={'div'}>
                                <TableContainer className='eMed_Pharmabill_Table_Container'>
                                    <Table stickyHeader size='small' sx={{ overflowY: 'auto' }}>
                                        <TableHead>
                                            <TableRow sx={{ display: 'flex' }}>
                                                {this.state.isFromInvoice ? null :
                                                    <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>
                                                        <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()} sx={{ visibility: (getCachevalue("ForAdminApproval") === "true") ? 'hidden' : 'visible' }}>
                                                            <Box component={'img'} mt={'0.2dvw'} src={ImagePaths.Delete.default} alt='del' height={'1.3dvw'} width={'1.3dvw'} />
                                                        </Button>
                                                    </TableCell>}
                                                <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                                                <TableCell sx={{ flex: 0.17 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                                                <TableCell sx={{ flex: this.state.isForReturn ? 0.068 : 0.078 }} align='center' className='eMed_Pharma_tbl_header'>Strength</TableCell>
                                                <TableCell align='center' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>Type</TableCell>
                                                <TableCell sx={{ flex: 0.119 }} className='eMed_Pharma_tbl_header'>Batch-Avl Qty</TableCell>
                                                <TableCell align='left' sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>{this.state.isForReturn ? 'Exp. Date' : 'Expiry Date'}</TableCell>
                                                <TableCell sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>Box No.</TableCell>
                                                <TableCell sx={{ flex: this.state.isForReturn ? 0.073 : 0.08 }} align='left' className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                                                <TableCell align='left' sx={{ flex: this.state.isForReturn ? 0.07 : 0.08 }} className='eMed_Pharma_tbl_header'>
                                                    <Tooltip placement='top' title='Quantity'><div>Qty</div></Tooltip>
                                                </TableCell>
                                                {this.state.isForReturn ? <TableCell align='center' sx={{ flex: 0.11 }} className='eMed_Pharma_tbl_header'>
                                                    <Tooltip placement='top' title='Return Quantity'><div>Rtn. Qty</div></Tooltip>
                                                </TableCell> : null}
                                                <TableCell sx={{ flex: this.state.isForReturn ? 0.09 : 0.083 }} className='eMed_Pharma_tbl_header'>{`Total Cost`}</TableCell>
                                                <TableCell sx={{ flex: 0.08 }} className='eMed_Pharma_tbl_header'>
                                                    <Tooltip placement='top' title='Discount'><div>{`Dis.%`}</div></Tooltip>
                                                </TableCell>
                                                <TableCell align='right' sx={{ flex: this.state.isForReturn ? 0.1 : 0.083 }} className='eMed_Pharma_tbl_header'>{`Total (${CurrencySymbol})`}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.pharmacyTblData.map((item, index) => {
                                                var datePart = item?.expiry_date ? item?.expiry_date.match(/\d+/g) : []
                                                var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                                return (
                                                    <Tooltip title={this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? "No Stock Available" : ""} placement='top' arrow>
                                                        <TableRow sx={{ display: 'flex', backgroundColor: this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? Colors.disableComponentColor : null }} key={index}>
                                                            {
                                                                this.state.isFromInvoice ? null :
                                                                    <TableCell sx={{ flex: 0.01 }}>
                                                                        <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)} sx={{ visibility: (getCachevalue("ForAdminApproval") === "true") ? 'hidden' : 'visible' }}>
                                                                            <Box component={'img'} mt={'0.5dvw'} src={ImagePaths.Delete.default} alt='del' height={'1.1dvw'} width={'1.1dvw'} />
                                                                        </Button>
                                                                    </TableCell>
                                                            }
                                                            <TableCell align='center' sx={{ flex: 0.01, paddingTop: '0.8dvw' }}>{index + 1}</TableCell>
                                                            <TableCell sx={{ flex: 0.17 }}>{this.renderDrugColumn(index, item)}</TableCell>
                                                            <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: this.state.isForReturn ? 0.068 : 0.078, paddingTop: '0.8dvw' }} className='eMed_Pharma_tbl_Body'>{item?.dosage_strength ? item?.dosage_strength : '-'}</TableCell>
                                                            <TableCell align='center' emed_tid="bill_dosage_type" sx={{ flex: 0.083, paddingTop: '0.8dvw' }}>
                                                                {item?.dosage_type ? item?.dosage_type?.length > 10 ?
                                                                    <Tooltip placement='top' title={item?.dosage_type} arrow>
                                                                        <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> :
                                                                    item?.dosage_type : "-"}
                                                            </TableCell>
                                                            <TableCell sx={{ flex: this.state.isForReturn ? 0.14 : 0.119 }}>
                                                                {this.renderBatchColumn(index, item)}
                                                            </TableCell>
                                                            <TableCell align='center' emed_tid="bill_expiry_date" sx={{ flex: 0.08, paddingTop: '0.8dvw' }}>{ExpiryDate}</TableCell>
                                                            <TableCell align='center' emed_tid="bill_physical_box_no" sx={{ flex: 0.08, paddingTop: '0.8dvw' }}>{item?.physical_box_no ? item?.physical_box_no : '-'}</TableCell>
                                                            <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.08, paddingTop: '0.8dvw' }}>{item?.cost_per_quantity ? item?.cost_per_quantity : '-'}</TableCell>
                                                            <TableCell sx={{ flex: 0.08 }}>
                                                                {this.inputTextBox(this.state.isFromIpReturn ? item?.return_quantity : item?.quantity, 'quantity', 'Quantity', '3dvw', index, '2dvh', (item?.cost_per_quantity || (getCachevalue("ForAdminApproval") !== "true")) ? false : true)}
                                                            </TableCell>
                                                            {this.state.isForReturn ?
                                                                <TableCell sx={{ flex: 0.083 }}>
                                                                    {this.inputTextBox(item?.return_quantity, 'return_quantity', 'Return Qty', '3dvw', index)}
                                                                </TableCell>
                                                                : null}
                                                            <TableCell emed_tid="bill_total_cost" align='center' sx={{ flex: this.state.isForReturn ? 0.073 : 0.083, paddingTop: '0.8dvw' }}>{item?.total_cost ? (item?.total_cost).toFixed(2) : '0'}</TableCell>
                                                            <TableCell sx={{ flex: 0.08 }}>
                                                                {this.inputTextBox(item?.discount, 'discount', 'Discount', '3dvw', index)}
                                                            </TableCell>
                                                            <TableCell align='right' emed_tid="bill_total_price" sx={{ flex: this.state.isForReturn ? 0.12 : 0.083, paddingTop: '0.8dvw' }}>
                                                                {(this.state.isForReturn || this.state.isFromIpReturn) ? (item?.total_price ? ` ${item?.total_return_price ? item?.total_return_price.toFixed(2) : 0} / ${item?.total_price ? item?.total_price?.toFixed(2) : '0'}` : '0') : (item?.total_price ? item?.total_price.toFixed(2) : '0')}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Tooltip>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {this.customPharmaFooter(this.state.pharmacyTblData)}
                                {this.discountReasonPopup()}
                                {this.state.isErrorMsg ?
                                    <ToastMsg
                                        severity={'error'}
                                        msg={this.state.isErrorMsgText}
                                        msgPop={this.msgClose.bind(this)}
                                    />
                                    : null}
                                {this.state.successMsg ?
                                    <ToastMsg
                                        severity={'success'}
                                        msg={this.state.successMsgText}
                                        msgPop={this.msgClose.bind(this)}
                                        testId={this.state.invoiceNumber}
                                    />
                                    : null}
                                <Loader loaderOpen={this.state.isLoader} />
                                {this.state.ShowDiscard ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
                                {this.state.CompletedBillID !== null ? <BillSuccessPoPUp PatientID={this.state.Patient_Id}
                                    isCredit={this.state.isCredit}
                                    InvoiceId={this.state.CompletedBillID} isFromPharmacy={true}
                                    ClosePopUp={this.ClosePopUp.bind(this)} testID={"Ph_BillSuc"}
                                    CloseBtnText={(this.state.isForReturn || this.state.isFromIpReturn) ? "Back" : null}
                                    successTxt={this.state.isCredit ? "Credit Added Successfully" : null} /> : null}
                                {this.state.creditChange ?
                                    <Dialog
                                        className="emed_DialogboxOrder"
                                        open={this.state.creditChange}
                                        maxWidth={"md"}
                                    >
                                        <div className='emed_Dialog_Appoin' style={{ width: '42dvw', height: "45dvh" }}>
                                            {this.renderCreditType()}
                                        </div>
                                    </Dialog> : null}
                                {this.state.cancelBillClicked ?
                                    <ReasonPopupWithRefund
                                        title={"Cancel Bill"}
                                        AlertPopupClose={this.ReasonPopupClose.bind(this)}
                                        label={"Enter the Cancel Reason"}
                                        btntext={'Close'}
                                        btnvarient={'outlined'}
                                        btncolor={'error'}
                                        btntext1={RoleData?.is_user && !RoleData?.permission_access?.common?.cancel_bill_approval ? "Send Request" : 'Confirm'}
                                        btnvarient1={'contained'}
                                        sendCmt={this.addCancelComments.bind(this)}
                                        BillReceivedAmount={this.state.BillReceivedAmount} />
                                    : null
                                }
                            </Box>
                        </Box>
                    </Box>
                }

            </Box>
        )
    }
}
export default withTranslation()(PharmacyBilling);