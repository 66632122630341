import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../Components/Common Components/CommonComponents';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import ApprovalFilters from './ApprovalFilters';
import { DateTime } from 'luxon';
import { localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import { AmountFormat, formatDate, formatDateAndTimeMeth2 } from '../../Components/CommonFunctions/CommonFunctions';

class ModifyBillApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 30,
            ConcessionApprovalData: [],
            FilterDatas: {
                "FromDate": null,
                "ToDate": null,
                "DateIndex": 0,
                "PatientName": "",
                "AccNo": "",
                "MobileNumber": "",
                "Status": { value: "Pending", label: "Pending" },
            }
        }
    }
    componentDidMount() {
        this.GetModifyBillsData()
        if (this.props.history?.location?.state?.message) {
            this.successMessage(this.props.history?.location?.state?.message)
            this.props.history.push({ state: {} })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetModifyBillsData() {
        try {
            const LoggedData = JSON.parse(localGetItem("loggedInUserInfo"));
            const user_id = JSON.parse(localGetItem("login_user_id")); 
            let url = `${Serviceurls.MODIFY_BILLS_APPROVALS}?from_date=${this.state.FilterDatas?.FromDate || ""}&to_date=${this.state.FilterDatas?.ToDate || ""}&request_status=${this.state.FilterDatas?.Status?.value ? this.state.FilterDatas?.Status?.value : ""}&patient_name=${this.state.FilterDatas?.PatientName}&uhid=${this.state.FilterDatas?.AccNo}&mobile_number=${this.state.FilterDatas?.MobileNumber}`;
            if(LoggedData?.is_user && user_id){
                url = url + `&login_user_id=${user_id}`
            }
            this.LoaderFunction(true)
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ConcessionApprovalData: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                // this.successMessage(message)
                // this.GetBillList()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetModifyBillsData() })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    NavigatetoBilling = (data) => {
        let Url = ""
        let Id = ""
        let ApiCall = false
        if (data.bill_type === "OP") {
            Url = Serviceurls.FO_GET_APPOINMENT_DETAILS
            Id = "?appointment_id=" + data.appointment_id
            ApiCall = true
        } else if (data.bill_type === "IP") {
            Url = Serviceurls.FO_GET_IP_ADMISSION_DETAILS
            Id = "?ip_admission_id=" + data.ip_admission_id
            ApiCall = true
        } else if (data.bill_type === "LAB") {
            let CancelData = JSON.parse(JSON.stringify(data))
            CancelData.laboratory_id = data?.module_id
            CancelData.id = data?.bill_id
            CancelData.request_id = data?.id
            setCachevalue(true, "isForCancelBill")
            setCachevalue(JSON.stringify(CancelData), "ModifyAdminApprovalData")
            setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: CancelData }), "LabCancelBilldata")
            this.props?.history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, cancelBillData: CancelData, isForEditBill: true } })
        } else if (data.bill_type === "PHARMACY") {
            setCachevalue(true, "isForCancelBill")
                    setCachevalue(JSON.stringify({ billSummaryId: data?.bill_id, ForCancel: true, AmountNet: data?.bill_amount, patientData: data }), "PharmaCancelBillData")
                    this.props.history?.push({
                        pathname: '/Billing/PharmacyBilling', state: {
                            Patient_ID: data?.patient_id,
                            Bill_ID: data?.bill_id,
                            isFromInvoice: true,
                            billDetails: data,
                            isForCancel: true,
                        }
                    })
        } else if (data.bill_type === "RADIOLOGY") {
            setCachevalue(JSON.stringify(data), "ModifyAdminApprovalData")            
            setCachevalue(true, "isForCancelBill")
            data["radiology_id"] = data?.module_id
            setCachevalue(JSON.stringify({ fromModifyBill: true, billSummaryId: data?.bill_id, ForCancel: true, AmountNet: data?.bill_amount, isForEditBill: true }), "RadCancelBillData")
            this.props.history?.push({ pathname: '/Billing/RadiologyBilling', state: { fromModifyBill: true, fromCancelBill: false, billSummaryId: data?.bill_id, ModifyFromFO: true, CancelFromFO: false, patientData: data, CancelAdminApproval: true } })
        }

            
        if (ApiCall) {
            let Amt = data?.bill_amount
            RestAPIService.getAll(Url + Id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            // AppointmentDetails: response.data.data,
                            // anchorEl: null
                        }, () => {
                            if (data.bill_type === "OP") {
                                setCachevalue(JSON.stringify({ AppointmentID: data.appointment_id, InvoiceID: data?.bill_id, ForCancel: true, AmountNet: Amt, isForEditBill: true }), "OPCancelBilldata")
                                setCachevalue(JSON.stringify(response.data.data), "patientData")
                                setCachevalue(JSON.stringify(data), "ModifyAdminApprovalData")
                                setCachevalue(true, "isForCancelBill")
                                setCachevalue(true, "ForAdminApproval")
                                this.props?.history.push({ pathname: "/OPHome/PatientDetails/Billing", state: { AppointmentID: data.appointment_id, InvoiceID: data?.bill_id, ForCancel: true, AmountNet: Amt, isForEditBill: true } })
                            } else if (data.bill_type === "IP") {
                                setCachevalue(JSON.stringify({ AppointmentID: data.ip_admission_id, InvoiceID: data?.bill_id, ForCancel: true, AmountNet: Amt, isForEditBill: true }), "IPCancelBilldata")
                                setCachevalue(JSON.stringify(response.data.data), "IppatientData")
                                setCachevalue(JSON.stringify(data), "ModifyAdminApprovalData")
                                setCachevalue(true, "isForCancelBill")
                                setCachevalue(true, "ForAdminApproval")
                                this.props?.history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling", state: { AppointmentID: data.ip_admission_id, InvoiceID: data?.bill_id, ForCancel: true, AmountNet: Amt, isForEditBill: true } })
                            }

                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
    }

    cancelBillPost = (reqdata) => {
        try {
            var data = reqdata?.request_data;
            let URL = ""
            if (reqdata?.bill_type === "OP") {
                URL = Serviceurls.FO_PATIEMT_BILLING
                data = reqdata?.request_data?.post_data;
            } else if (reqdata?.bill_type === "IP") {
                URL = Serviceurls.IP_SERVICE_BILL_POST
            }else if (reqdata?.bill_type === "LAB") {
                URL = Serviceurls.LAB_PARTIAL_BILL_POST
                data = reqdata?.request_data?.post_data;
            }else if (reqdata?.bill_type === "RADIOLOGY") {
                URL = Serviceurls.RAD_BILL_POST
                data.radiology_id = reqdata?.module_id;
            }
            RestAPIService.create(data, URL)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.GetModifyBillsData()
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostApprovalData = (data, status = "") => {
        try {
            var payload_data = {
                'id': data?.id,
                'patient_id': data?.patient_id ? data?.patient_id : null,
                'appointment_id': data?.appointment_id ? data?.appointment_id : null,
                'ip_admission_id': data?.ip_admission_id ? data?.ip_admission_id : null,
                'module_id': data?.module_id ? data?.module_id : null,
                'bill_type': data?.bill_type ? data?.bill_type : "",
                'bill_id': data?.bill_id ? data?.bill_id : null,
                'invoice_number': data?.invoice_number ? data?.invoice_number : "",
                'request_date': data?.request_date ? data?.request_date : "",
                'patient_name': "",
                'from_bill_amount': data?.from_bill_amount ? data?.from_bill_amount : 0,
                'to_bill_amount': data?.from_bill_amount ? data?.from_bill_amount : 0,
                'request_data': data?.request_data ? data?.request_data : null,
                'approved_data': data?.request_data ? data?.request_data : null,
                'status': status,
                'remarks': data?.remarks ? data?.remarks : "",

            }
            RestAPIService.create(payload_data, Serviceurls.MODIFY_BILLS_APPROVALS)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.props.triggerCountsApi()
                        if (status === "Approved") {
                            this.cancelBillPost(data)
                        } else {
                            this.GetModifyBillsData()
                        }
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas?.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas?.ToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        this.state.ConcessionApprovalData.forEach((element, index) => element.sno = index + 1)
        let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.20,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={true} disablePhoto={true} />)
            },
            {
                field: "request_date", headerName: t("Date & Time"), flex: 0.08,
                renderCell: ({ row }) => (<Box component={'div'}>{row?.request_date ? row?.request_date?.length > 10 ?
                    <Tooltip placement='top' title={formatDateAndTimeMeth2(row?.request_date)} arrow>
                        <div>{formatDateAndTimeMeth2(row?.request_date).slice(0, 10) + "..."}</div></Tooltip>
                    : row?.concession_date : "-"}</Box>)
            },
            {
                field: "invoice_number", headerName: t("Bill No"), flex: 0.09,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? params?.row?.invoice_number?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.invoice_number : "-"}</Box>)
            },
            {
                field: "from_bill_amount", headerName: t("Req Amount"), flex: 0.09, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.from_bill_amount ? params?.row?.from_bill_amount : 0)}</Box>)
            },
            {
                field: "to_bill_amount", headerName: t("Approved Amount"), flex: 0.09, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.to_bill_amount ? params?.row?.to_bill_amount : 0)}</Box>)
            },
            {
                field: "remarks", headerName: t("Modify Notes"), flex: 0.09,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.remarks ? params?.row?.remarks?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.remarks} arrow><div>{params?.row?.remarks.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.remarks : "-"}</Box>)
            },
            {
                field: "user_name", headerName: t("Username"), flex: 0.09,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.user_name ? params?.row?.user_name?.length > 10 ?
                    <Tooltip placement='top' title={params?.row?.user_name} arrow><div>{params?.row?.user_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.user_name : "-"}</Box>)
            },
            {
                field: "status", headerName: t("Status"), flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography sx={{ color: params?.row?.status === "Approved" ? "green" : (params?.row?.status === "Pending" ? "orange" : "red"), fontWeight: 600 }}>{params?.row?.status ? params?.row?.status : ""}</Typography>
                </Box>)
            },
            {
                field: "uhid", headerName: t("Action"), flex: 0.10, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>

                    {(!LoggedData?.is_user && params?.row?.status === "Pending") ?
                        <>
                            <IconButton
                                id="emed_CalenderClose_Icon"
                                onClick={() => {
                                    this.NavigatetoBilling(params?.row)
                                }}>
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={ImagePaths.EyeView.default} alt='close' />
                            </IconButton>
                            <IconButton
                                disabled={params?.row?.status !== "Pending"}
                                onClick={() => {
                                    this.PostApprovalData(params?.row, "Approved")
                                }}
                                id="emed_CalenderClose_Icon">
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={params?.row?.status === "Pending" ? ImagePaths.ApproveTick.default : ImagePaths.ApproveDisabled.default} alt='close' />

                            </IconButton>
                        </> : null}
                    <IconButton
                        disabled={params?.row?.status !== "Pending"}
                        onClick={() => {
                            this.PostApprovalData(params?.row, "Cancelled")
                        }}
                        id="emed_CalenderClose_Icon">
                        <img style={{ width: '1.5vw', height: '1.5vw' }} src={params?.row?.status === "Pending" ? ImagePaths.CancelEnable.default : ImagePaths.CancellDisable.default} alt='close' />

                    </IconButton>
                </Box>)
            },
        ]
        return (
            <Box>
                <Box component={"div"}>
                    <Box component={"div"} className='eMed_Action_Container' sx={{ width: "100%", marginTop: "1vw" }}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table' sx={{ height: '70vh', width: '100%', overflow: 'auto' }}>
                    <DataGrid
                        rows={this.state.ConcessionApprovalData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    user_name: (LoggedData?.is_user ? false : true),
                                }
                            }
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={60}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <ApprovalFilters FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
            </Box>
        )
    }
}
export default withTranslation()(ModifyBillApprovals)

