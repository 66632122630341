import { Box, Button, Collapse, IconButton, Menu, MenuItem, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './PatientBill.css'
import { Colors } from '../../../../../Styles/Colors'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { t } from 'i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { CommonPatientCard, CommonPatientDetails } from '../../../../../Components/Common Components/CommonComponents'
import CreditBillPopUp from '../../../BillingTransaction/CreditBillPopUp'
import { CheckAccessFunc, formatDate, AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../../../Components/Loader';
import { MoreVert } from '@material-ui/icons'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChangePaymentMode from '../../../BillingTransaction/ChangePaymentMode'
import { CurrencySymbol } from '../../../../../Utility/Constants'

class PatientCompletedBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OpPatientData: {},
            creditBillPopUp: false,
            attenderName: "",
            attenerNumber: "",
            firstIndex: null,
            secondIndex: null,
            completedBillData: [],
            opCompletedBillId: "",
            withOutAppointment: {},
        }
    }

    componentDidMount() {
        let patientData = getCachevalue("patientData")
        let OpPatientData = JSON.parse(patientData)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        if (details?.patient_id === OpPatientData?.patient_id) {
            this.setState({
                withOutAppointment: details
            }, () => {
                this.getPatientDatails();
                this.getPatientCompletedBillData();
            })
        }
        this.setState({
            OpPatientData: OpPatientData
        }, () => {
            this.getPatientDatails()
            this.getPatientCompletedBillData()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    ClosePopUp() {
        this.setState({
            creditBillPopUp: false,
            ChangePaymentPopup: false,
        })
    }

    getCompletedBillPrint = () => {
        try {
            let API_URL = Serviceurls.RECEIPT_INDIV_PRINT + "?receipt_id=" + (+this.state.opCompletedBillId)
            RestAPIService.getAll(API_URL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getPatientDatails = () => {
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        try {
            if(appointment_id){
                RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_DETAILS + "?appointment_id=" + appointment_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            attenderName: response.data?.data?.caretaker_name,
                            attenerNumber: response.data?.data?.caretaker_number
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPatientCompletedBillData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_RECEIPT_BILL + "?patient_id=" + this.state.OpPatientData?.patient_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            completedBillData: response.data.data
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPatientHeader = () => {
        const { t } = this.props
        let attenderDetails = {
            lineOne: t("Emergency Contact Person"),
            lineTwo: `${this.state.attenderName ? this.state.attenderName : "-"}`,
            lineFour: t("Emergency Mobile No"),
            LineFive: `${this.state.attenerNumber ? this.state.attenerNumber : "-"}`
        }
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        return (
            <Box component={'div'} display={'flex'}>
                <Box component={'div'} className='eMed_nur_notes_details' ml={'0.8vw'}>
                    <CommonPatientDetails data={this.state.OpPatientData} />
                </Box>
                {appointment_id ? <CommonPatientCard details={attenderDetails} showDetailed={true} /> : null}
            </Box>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    // For Completed Bill changed to Receipt Bill 29/05/2023 - start
    // renderTableOne = (item, firstIndex) => {
    //     return (
    //         <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
    //             <Collapse
    //                 in={this.state.selectedIndex === firstIndex}
    //                 timeout={'auto'}
    //                 unmountOnExit
    //             >
    //                 <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
    //                     <Table>
    //                         <TableHead sx={{ backgroundColor: Colors.Background }}>
    //                             <TableRow>
    //                                 <TableCell />
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillNumber")}</TableCell>
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillType")}</TableCell>
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DoctorName")}</TableCell>
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("TotalBillAmount")} (₹)`}</TableCell>
    //                                 <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Action")}</TableCell>
    //                             </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                             {item?.bill_data?.length > 0 ? item?.bill_data.map((i, secondIndex) => {
    //                                 let billType = i?.bill_type ? this.getCapitalize(i.bill_type): "-";
    //                                 return(
    //                                 <><TableRow key={secondIndex}>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>
    //                                         <IconButton
    //                                             size='small'
    //                                             onClick={() => {
    //                                                 this.setState({
    //                                                     selectedSubIndex: this.state.selectedSubIndex === secondIndex ? null : secondIndex
    //                                                 })
    //                                             }}
    //                                         >
    //                                             {this.state.selectedSubIndex === secondIndex && this.state.secondOpen ?
    //                                                 <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //                                         </IconButton>
    //                                     </TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>{i?.bill_date ? i.bill_date : "-"}</TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>{i?.bill_number ? i.bill_number : "-"}</TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>{billType ? billType : "-"}</TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>{i?.doctor_name ? i.doctor_name : "-"}</TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>{i?.net_amount ? i.net_amount : "-"}</TableCell>
    //                                     <TableCell className='eMed_Pat_Tbl_one_style'>
    //                                         {/* <Button
    //                                             className='eMed_Pat_tbl_two_btn'
    //                                             disabled={CheckAccessFunc("front_office", "Home", "Billing", "Completed Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
    //                                             onClick={() => {
    //                                                 let { history } = this.props
    //                                                 this.setState({
    //                                                     opCompletedBillId: i?.id
    //                                                 }, () => {
    //                                                     history.push({
    //                                                         pathname: "/OPHome/PatientDetails/Billing",
    //                                                         state: { AppointmentID: this.state.OpPatientData?.appointment_id, InvoiceID: this.state.opCompletedBillId }
    //                                                     })
    //                                                 })
    //                                             }}
    //                                         >
    //                                             <Tooltip placement='top' title={'Modify Bill'} arrow>
    //                                                 <img src={CheckAccessFunc("front_office", "Home", "Billing", "Completed Bill", "SubTab")?.editAccess ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default} className='eMed_Pat_Bill_img' alt='credit' />
    //                                             </Tooltip>
    //                                         </Button> */}
    //                                         <Button disabled={this.state.withOutAppointment?.is_op} className='eMed_Pat_tbl_two_btn'>
    //                                             <Tooltip placement='top' title={'Print'} arrow>
    //                                                 <img
    //                                                     onClick={() => {
    //                                                         this.setState({
    //                                                             opCompletedBillId: i?.id
    //                                                         }, () => this.getCompletedBillPrint())
    //                                                     }}
    //                                                     src={ImagePaths.PrintIcons.default} className='eMed_Pat_Bill_img' alt='credit' />
    //                                             </Tooltip>
    //                                         </Button>
    //                                         {/* <Button className='eMed_Pat_Bill_more'><MoreVert /></Button> */}
    //                                     </TableCell>
    //                                 </TableRow>
    //                                     <TableRow>
    //                                         {/* {this.renderTableTwo(i, secondIndex)} */}
    //                                     </TableRow></>
    //                             )}) : <TableRow>
    //                                 <TableCell /><TableCell />
    //                                 <TableCell /><TableCell align='center'>
    //                                     {t("NoRecordsFound")}</TableCell>
    //                                 <TableCell /><TableCell />
    //                             </TableRow>}
    //                         </TableBody>
    //                     </Table>
    //                 </Box>
    //             </Collapse>
    //         </TableCell></>
    //     )
    // }
    // For Completed Bill changed to Receipt Bill 29/05/2023 - End

    MenuItem = (data) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PaymentChangeAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.change_payment_mode ? RoleData?.permission_access?.common?.change_payment_mode : false) : true
        const multi_option = [
            { value: "ChangePaymentMode", label: t("Change Payment Mode") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={this.state.ChangePaymentPopup}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} 
                            disabled={option.value === "ChangePaymentMode" ? ((data?.amount_received && PaymentChangeAccess) ? false : true) : false}
                            onClick={() => { 
                                this.setState({ anchorEl: null }, ()=>{
                                    if(option.value === "ChangePaymentMode"){
                                        data.patient_age = this.state.OpPatientData?.patient_age
                                        data.patient_uhid = this.state.OpPatientData?.patient_account_numbers
                                        data.mobile_number = this.state.OpPatientData?.patient_mobile_number
                                        this.setState({ ChangePaymentPopup : true, SelectedBill: data })
                                    }
                                }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderTableTwo = (i, secondIndex) => {
        let appointment_id = this.state.OpPatientData?.appointment_id || this.state.withOutAppointment?.appointment_id;
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === secondIndex}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("receiptNumber")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("PaymentModeType")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Receipt Type")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Paid In")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("CreatedBy")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Description")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("TotalPaidAmount")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Action")}</TableCell>
                            </TableHead>
                            <TableBody>
                                {i?.receipt_data?.length > 0 ? i.receipt_data.map((val, thirdIndex) => (
                                    <TableRow key={thirdIndex}>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.receipt_date ? val.receipt_date : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.receipt_number ? val.receipt_number : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.payment_mode_types ? val.payment_mode_types : "Not Applicable"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.receipt_for ? val.receipt_for : '-'}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.collected_in ? val.collected_in : '-'}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style'>{val?.created_by ? val.created_by : "-"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{val?.description ?
                                                val?.description ? val?.description?.length > 30 ?
                                                    <Tooltip placement='top' title={val?.description} arrow>
                                                        <div style={{ fontSize : "0.875rem"}}>{val?.description.slice(0, 28) + "..."}</div></Tooltip> :
                                                    <div style={{ fontSize : "0.875rem"}}>{val?.description}</div> : "-"
                                                : '-'}</TableCell>                                        <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{val?.amount_received ? AmountFormat((val?.receipt_for === "Cancelled" || val?.receipt_for === "Refund") ? (val.amount_received * -1) : val.amount_received)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                                        <TableCell className='eMed_Pat_Tbl_one_style' sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                            <Button disabled={this.state.withOutAppointment?.is_op} className='eMed_Pat_tbl_two_btn'>
                                                <Tooltip placement='top' title={'Print'} arrow>
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                opCompletedBillId: val?.id
                                                            }
                                                                , () => this.getCompletedBillPrint()
                                                            )
                                                        }}
                                                        src={ImagePaths.PrintIcons.default} className='eMed_Pat_Bill_img' alt='credit' />
                                                </Tooltip>
                                            </Button>
                                            {getCachevalue("isForCancelBill") === "true" ? null : (appointment_id ? this.MenuItem(val, thirdIndex) : null)}
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell >{t("NoRecordsFound")}</TableCell>
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    renderTable = () => {
        return (
            <TableContainer component={'div'} className='eMed_Pat_Bill_Table'>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("AppointmentNumberAndDate")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NR")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("TotalPaidAmount")} (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.completedBillData?.length > 0 ? this.state.completedBillData.map((item, firstIndex) => (
                            <><TableRow
                                sx={{ backgroundColor: this.state.selectedIndex === firstIndex ? Colors?.ThemeLightColor : 'white', cursor:'pointer' }}
                                key={firstIndex}
                                onClick={() => {
                                    this.setState({
                                        selectedIndex: this.state.selectedIndex === firstIndex ? null : firstIndex
                                    })
                                }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === firstIndex ? null : firstIndex
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === firstIndex ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.appointment_number ? item?.appointment_number : "-"} 
                                | ${item?.appointment_date ? formatDate(item?.appointment_date) : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.receipt_count ? item?.receipt_count : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_receipt_amount ? AmountFormat(item?.total_receipt_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableTwo(item, firstIndex)}
                                </TableRow></>
                        )) : <TableRow sx={{ position: 'relative', top: "12vw" }}>
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }} />
                            <TableCell sx={{ borderBottom: "none" }}>{t("NoRecordsFound")}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} />
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    PaymentModeChanged(message) {
        this.setState({
            ChangePaymentPopup : false,
        }, () => {
            this.successMessage(message)
            this.getPatientCompletedBillData()
        })
    }

    render() {
        return (
            <Box component={'div'} className='eMed_Pat_Bill_Container' sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pat_Bill_Header'>
                    {this.renderPatientHeader()}
                </Box>
                <Box component={'div'} className='eMed_Pat_Bill_content'>
                    {this.renderTable()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
                {
                    this.state.ChangePaymentPopup ? <ChangePaymentMode BillData={this.state.SelectedBill} PaymentModeChanged={this.PaymentModeChanged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
            </Box>
        )
    }
}


export default withTranslation()(PatientCompletedBill)