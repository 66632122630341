import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Autocomplete,
    IconButton,
    Tooltip,
    Box,
    InputAdornment
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from '../../../Utility/Constants';
import Loader from '../../../Components/Loader';

class OutsourceMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            selectOutLabName: null,
            selectOutLabName1: '',
            selectTestName: null,
            selectTestGroup: null,
            outsourceTestPrice: '',
            outsourceMappingData: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            testNameTableValue: [],
            testNameGet: [],
            testGroupGet: [],
            testOutSourceLabGet: [],
            deletedID: [],
            deletedPopup: false,
            deletedapiID: '',
            searchKey: '',
            selectedIndex: '',
            SearchText:"",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getTestNameData()
        this.getTestGroupData()
        this.getOutSourceLabData()
        this.getOutsourceMappingData()
    }

    // componentWillReceiveProps(props){
    //     if(props.search_key != this.state.searchKey){
    //         this.setState({
    //             searchKey: props.search_key
    //         },() => {
    //             this.getOutsourceMappingData()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
           isErrorMsg: false,
           isErrorMsgText: '',
           successMsg: false,
           successMsgText: '',
        })
      }

    getTestNameData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testNameGet: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    getTestGroupData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testGroupGet: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    getOutSourceLabData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_OUTSOURCE_CONFIG)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testOutSourceLabGet: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getOutsourceMappingData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_OUTSOURCE_MAPPING + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        var data = response.data.data
                        this.setState({
                            outsourceMappingData: response.data.data ? response.data.data : []
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postOutsourceMappingData = () => {
        try {
            this.LoaderFunction(true)
            var states = this.state
            var outsourceLabID = states.selectOutLabName ? states.selectOutLabName.id : null
            var outsourceLabName = states.selectOutLabName ? states.selectOutLabName.outsource_laboratory_name : null
            var data = {
                "outsource_laboratory_name": outsourceLabName,
                "outsource_id": outsourceLabID,
                "outsource_line_item": states.testNameTableValue ? states.testNameTableValue : []
            }
            if (this.state.selectedEditID) {
                data['outsource_id'] = this.state.selectedEditID
                data['deleted_ids'] = this.state.deletedID
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_OUTSOURCE_MAPPING)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.LoaderFunction(false)
                            this.getOutsourceMappingData()
                            this.setState({
                                selectOutLabName1: '',
                                testNameTableValue: [],
                                selectedEditID: '',
                                disableBtn: false,
                            })
                        })
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }

    editOutsourceMappingData = (item, index) => {
        this.setState({
            selectedEditID: item.out_source_id,
            selectOutLabName1: item.out_source__outsource_laboratory_name ? item.out_source__outsource_laboratory_name : '',
            testNameTableValue: item.data,
            selectedIndex: index,
        })
    }

    deleteOutsourceMappingData = (ID) => {
        try {
            var data = {
                "outsource_id": ID
            }
            RestAPIService.delete(Serviceurls.LAB_OUTSOURCE_MAPPING, data)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getOutsourceMappingData()
                        })
                    }
                }).catch((e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                }))

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // const re = /^[0-9\b]+$/;
        if (value === "" || CommonValidation.NumberWithDot(value) && value > 0) {
            this.setState({
                [name]: value,
            });
        }
    };

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    size={'small'}
                    sx={{ width: width }}
                    label={label}
                    rows={3}
                    name={name}
                    value={value}
                    variant="outlined"
                    onChange={this.numInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.outsourceMappingData.filter((data) => {
            return (
                (data.out_source__outsource_laboratory_name === null ? null : data.out_source__outsource_laboratory_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.data === null ? null : JSON.stringify(data.data).toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("LaboratoryName")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('TestName/Group')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    var testGroup = []
                                    const Datas = data.data
                                    Datas.forEach(element => {
                                        testGroup.push((element.lab_set_name ? element.lab_set_name : element.lab_test_name))
                                    });
                                    return (
                                        <TableBody>
                                            <TableRow sx={{bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white'}}>
                                                <TableCell align='left'><div>{sno}</div></TableCell>
                                                <TableCell align='left'>
                                                {data.out_source__outsource_laboratory_name.length > 15 ?
                                                        <Tooltip placement="top" title={data.out_source__outsource_laboratory_name} arrow>
                                                            <div >{data.out_source__outsource_laboratory_name ? data.out_source__outsource_laboratory_name.slice(0, 15) + '...' : '-'}</div>
                                                        </Tooltip> : <div> {data.out_source__outsource_laboratory_name ? data.out_source__outsource_laboratory_name : '-'}</div>}</TableCell>
                                                <TableCell align='left'>
                                                {testGroup.length > 1 ?
                                                        <Tooltip placement="top" title={<span><p>{testGroup ? testGroup.slice(0, 1000) + '' : '-'}</p></span>} arrow>
                                                            <div >{testGroup ? testGroup.slice(0, 2) + '...' : '-'}</div>
                                                        </Tooltip> : <div> {testGroup}</div>}</TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Outsource Mapping",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Outsource Mapping",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editOutsourceMappingData(data, index) }}
                                                            />
                                                        </IconButton>
                                                        <IconButton size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Outsource Mapping",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Outsource Mapping",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        deletedPopup: true,
                                                                        deletedapiID: data.out_source_id
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsShown')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }
    renderRightTable = () => {
        var { testNameTableValue } = this.state
        const { t } = this.props
        return (
            <div>
                <Paper elevation={0}>
                    <TableContainer className='eMed_ConfigRightTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ flex: 1, }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left'>{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Test/Group')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left'>{t('Price')  +  ` (${CurrencySymbol})`}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center'>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {testNameTableValue && testNameTableValue.length > 0 ? testNameTableValue.map((item, index) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='left'><div>{index + 1}</div></TableCell>
                                            <TableCell align='left'>{item.lab_test_name ? item.lab_test_name : item.lab_set_name}
                                            </TableCell>
                                            <TableCell align='left'>{item.amount ? AmountFormat(item.amount  ? item?.amount : item?.test_amt)?.replace(`${CurrencySymbol}`,"") : '0.00'}</TableCell>
                                            <TableCell align='center'>
                                                <Tooltip title='Delete' placement='top' arrow>
                                                    <img className='eMed_TestConfig_DeleteIcon' style={{ cursor: 'pointer' }} src={ImagePaths.Delete.default} alt="Delete"
                                                        onClick={() => {
                                                            var deletedID = []
                                                            deletedID.push(item.id)
                                                            testNameTableValue.splice(index, 1)
                                                            this.setState({
                                                                testNameTableValue,
                                                                deletedID: deletedID
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }) : null}
                        </Table>
                        {testNameTableValue.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t("NoRecordsFound")}
                            </div> : null}
                    </TableContainer>
                </Paper>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            selectOutLabName1: null,
            selectTestGroup: null,
            selectTestName: null,
            outsourceTestPrice: '',
            testNameTableValue: [],
            selectedIndex: '',
            selectedEditID: '',
        })
    }

    validCheck = () => {
        const {t} = this.props
        var saveErrorMsg = ''
        var states = this.state
        if (!states.selectOutLabName1) {
            saveErrorMsg = t('AddOutSourceLab')
        } else if ((states.testNameTableValue).length == 0) {
            saveErrorMsg = t('AddTest')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postOutsourceMappingData()
        }
    }

    addTestName = () => {
        var states = this.state
        const {t} = this.props
        if (states.selectTestName || states.selectTestGroup) {
            if(states.outsourceTestPrice){

            var checkAmount = states.selectTestName ? states.selectTestName.amount ? states.selectTestName?.amount : states.selectTestName?.test_amt : states.selectTestGroup.amount 

            // if (+(checkAmount) > +(states.outsourceTestPrice)) {

                states['testNameTableValue'].push({
                    "lab_test_name": states.selectTestName ? states.selectTestName.name ? states.selectTestName.name : states.selectTestName.test_name : '',
                    "lab_test_id": states.selectTestName ? states.selectTestName.id : null,
                    "lab_set_name": states.selectTestGroup ? states.selectTestGroup.name : '',
                    "lab_set_id": states.selectTestGroup ? states.selectTestGroup.id : null,
                    "amount": states.outsourceTestPrice
                })
                this.setState({
                    states
                }, () => {
                    this.setState({
                        selectTestName: null,
                        selectTestGroup: null,
                        outsourceTestPrice: ''
                    })
                })
            // }
            //  else {
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t('PriceLesserTestAmt')
            //     })
            // }
        } else{
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('EnterOutsourceAmt')
            })
        }
        } else {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t('SelectTestName')
            })
        }
    }
    PopupClose = () => {
        this.setState({
            deletedPopup: false
        })
    }
    removeDataList = () => {
        this.deleteOutsourceMappingData(this.state.deletedapiID)
        this.setState({
            deletedPopup: false
        })
    }

    render() {
        const { t } = this.props
        var states = this.state
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t('OutsourceMapping')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    disabled={this.state.selectedEditID && this.state.selectedEditID ? true : false}
                                    options={states.testOutSourceLabGet}
                                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.outsource_laboratory_name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            selectOutLabName1: value,
                                            selectOutLabName: value,
                                        })
                                    }}
                                    value={states.selectOutLabName1 ? states.selectOutLabName1 : null}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("OutsourceLaboratoryName") + "*"} size='small' />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    disabled={states.selectTestGroup ? true : false}
                                    options={states.testNameGet}
                                    getOptionLabel={(options) => options.test_name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            testName: value,
                                            selectTestName: value
                                        })
                                    }}
                                    value={states.selectTestName}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("TestName")+ "*"} size='small' />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <Autocomplete
                                    disabled={states.selectTestName ? true : false}
                                    options={states.testGroupGet}
                                    getOptionLabel={(options) => options.name}
                                    onChange={(e, value) => {

                                        this.setState({
                                            groupName: value,
                                            selectTestGroup: value
                                        })
                                    }}
                                    value={states.selectTestGroup}
                                    id="combo-box-demo"
                                    sx={{ width: "29vw" }}
                                    renderInput={(params) => <TextField {...params} label={t("TestGroup")+ "*"} size='small' />}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("OutsourceAmount"), '14vw', states.outsourceTestPrice, 'outsourceTestPrice')}
                                <Button id='eMed_Btn_Text' variant='contained' sx={{ width: '5vw' }}
                                    onClick={() => { this.addTestName() }}
                                >{t('Add')}</Button>
                            </div>
                            <div className='eMed_TestConfig_Right_SideTable'>
                                {this.renderRightTable()}
                            </div>
                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='OutsourceMap_Save_Btn' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Outsource Mapping",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("ConfirmremoveOutsourceMap")}
                        DeleteNotify={t("effectEntireConfig")}
                        DeletContent={t("linkedOutsourceMap")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                    <Loader loaderOpen={this.state.isLoader} />
            </div>
        );
    }
}

export default withTranslation()(OutsourceMapping);