import React, { Component } from 'react'
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import {
    Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment,
    OutlinedInput, Paper, Stack, TextField, Tooltip, Typography
} from '@mui/material';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import { withTranslation } from 'react-i18next';
import './PharmaInvoice.css'
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonPatientCard, CommonPatientDetails } from "../../../Components/Common Components/CommonComponents";
import Loader from '../../../Components/Loader';
import { BillSuccessPoPUp, ReasonPopup } from "../../../Components/Common Components/CommonComponents";
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';
import { CurrencySymbol } from '../../../Utility/Constants';
class PharmaCreditBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedTab: "pending",
            SelectedCreditBill: this.props.SelectedCreditBill,
            BillList: [],
            selectedID: [],
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            InsuranceAmount: "",
            InsuCompanyName: "",
            InsuPolicyNumber: "",
            InsuTransactionNumber: "",
            InsuValidity: '',
            PayingAmount: 0,
            totalPercentage: "",
            totalDiscount: "",
            roundOff: 0,
            netAmount: 0,
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            PatientID: "",
            page: 0,
            pageSize: 10,
            companyName: this.props.PatientDetails?.company_name,
            employeeId: this.props.PatientDetails?.id_no,
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            isInsurance: false,
            isDelete: false,
            deleteId: null,
            TotalPendingAmount: 0,
            TotalPendingAmount: 0,
            receiptID: null,
            PaidAmount: 0,
            PendingAmount: 0,
        }
    }

    componentDidMount() {
        this.GetBillList()
    }

    GetBillList() {
        try {
            var states = this.state
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHARMA_BILLING_CREDIT_POST + "?patient_id=" + states.SelectedCreditBill.patient_id + "&module=" + `${states.SelectedCreditBill.module}` + "&module_id=" + states.SelectedCreditBill.module_id + "&include_paid_bill=" + (this.props?.FilterDatas?.IncludePaidBills ? true : false)  + "&bill_status=" + "&from_date=" + this.props?.FilterDatas?.FromDate + "&to_date=" + this.props?.FilterDatas?.ToDate + "&module_id=" + states.SelectedCreditBill.module_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BillList: response.data.data,
                            TotalPendingAmount: response.data?.total_outstanding_amount ? response.data?.total_outstanding_amount : 0,
                            selectedID: response.data.data?.map((item) => (item?.id)),
                            TotalAmount: response.data.total_bill_amount,
                            PaidAmount: response.data.total_paid_amount,
                            PendingAmount: response.data.total_outstanding_amount,
                            ReturnAmount: response.data.total_return_amount,
                        }, () => {
                            this.LoaderFunction(false)
                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    complateBill = () => {
        try {
            this.LoaderFunction(true)
            let states = this.state
            let PaymentTypeData = [];
            if (this.state.isCash) {
                PaymentTypeData.push(1)
            }
            if (this.state.isCard) {
                PaymentTypeData.push(2)
            }
            if (this.state.isUPI) {
                PaymentTypeData.push(3)
            }
            if (this.state.isBankTranfer) {
                PaymentTypeData.push(5)
            }
            if (this.state.isCheque) {
                PaymentTypeData.push(4)
            }
            var data = {
                "payment_mode": PaymentTypeData ? PaymentTypeData : [],
                "bill_type": "Credit",
                "bill_summary_id_list": this.state.selectedID,
                "recevied_amount": this.state.netAmount ? +this.state.netAmount : 0,
                "card_amount": +this.state.CardAmount,
                "cash_amount": +this.state.CashAmount,
                "upi_amount": +this.state.UPIAmount,
                "bank_amount": +this.state.BankTfrAmount,
                "cheque_amount": +this.state.ChequeAmount,
                "insurance_amount": +this.state.InsuranceAmount,
                "card_number": this.state.CardTransactionNumber,
                "round_off": states.roundOff,
                "general_discount_percentage": states.totalPercentage ? +states.totalPercentage : 0,
                "general_discount": states.totalDiscount ? +states.totalDiscount : 0,
                "total_bill_amount": this.state.PayingAmount ? +this.state.PayingAmount : 0,
                "insurance_company_name": this.state.InsuCompanyName ? this.state.InsuCompanyName : "",
                "policy_number": this.state.InsuPolicyNumber,
                "transaction_number": this.state.InsuTransactionNumber,
                "validity": this.state.InsuValidity,
                "transaction_id": this.state.UPINumber,
                "reference_no": this.state.BankTfrNumber,
                "company_name": this.state.companyName ? this.state.companyName : "",
                "employee_id": this.state.employeeId ? this.state.employeeId : "",
                "grand_total": this.state.netAmount ? +this.state.netAmount : 0,
                "billing_status": "Payment Received",
                "patient_id": this.state.SelectedCreditBill?.patient_id,
                "net_amount": this.state.netAmount,
                "cheque_ref_no": this.state.ChequeNumber,
                "module": states.SelectedCreditBill?.module,
                "module_id": states.SelectedCreditBill?.module_id,
                "billing_customer_name": states.SelectedCreditBill?.billing_customer_name,
                "billing_customer_mobile_no": states.SelectedCreditBill?.billing_customer_mobile_no
            }
            RestAPIService.patch(data, Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            receiptID: response.data?.data?.receipt_id
                        })
                        this.successMessage(response.data.message)
                        this.clearAllData()
                        this.LoaderFunction(false)
                        // this.GetBillList()
                        // this.props.history.push({ pathname: "/PharmacyInvoice/CreditBills" })
                    } else {
                        this.errorMessage(response.data.message)
                        this.LoaderFunction(false)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    reasonPopupClose = () => {
        this.setState({
            isDelete: false,
            deleteId: null
        })
    }

    deleteItem = (comments) => {
        try {
            this.LoaderFunction(true)
            let states = this.state
            var data = {
                bill_summary_id: states.deleteId,
                cancel_reason: comments
            }
            RestAPIService.updateWithOutId(data, `${Serviceurls.PHARMA_BILLING_CREDIT_POST}?bill_summary_id=${states.deleteId}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.reasonPopupClose()
                        this.LoaderFunction(false)
                        this.GetBillList()
                    } else {
                        this.errorMessage(response.data.message)
                        this.LoaderFunction(false)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    printReports = (id) => {
        try {
            var states = this.state
            let ID = id ? id : ""
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.PHARMA_BILLING_CREDIT_POST + "?patient_id=" + states.SelectedCreditBill.patient_id + "&module=" + states.SelectedCreditBill.module + "&module_id=" + states.SelectedCreditBill.module_id + "&id=" + ID + "&include_paid_bill=" + (this.props?.FilterDatas?.IncludePaidBills ? true : false) + "&bill_status=" + "" + "&from_date=" + this.props?.FilterDatas?.FromDate + "&to_date=" + this.props?.FilterDatas?.ToDate + "&export_type=pdf")
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    printIndividual = (id) => {
        try {
            RestAPIService.PrintPDF(Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + id + "&export=pdf")
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    SetTotalAsFirstAmount() {
        if (this.state.firstPayment !== "") {
            let payableAmount = 0;
            let NetAmount = 0;
            let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
            payableAmount = this.state.PayingAmount - discount
            NetAmount = Math.round(+payableAmount)
            this.setState({ firstAmount: NetAmount, secondAmount: "" })
        }

    }

    billingCalculation = (type) => {
        let payableAmount = 0;
        switch (type) {
            case 'roundOff':
                this.state.roundOff = CommonValidation.roundOff(this.state.PayingAmount, this.state.totalDiscount)
                return this.state.roundOff;
            case 'payableAmount':
                let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                payableAmount = this.state.PayingAmount - discount
                this.state.netAmount = Math.round(+payableAmount)
                return AmountFormat(this.state.netAmount)
            default: break;
        }
    }
    // Paying bill wise Flow changed
    // handleTableCheckbox = (newSelection) => {
    //     let states = this.state;
    //     let selectedIDs = new Set(newSelection);
    //     let selectedRows = states["BillList"].filter((row) => selectedIDs.has(row.sno));
    //     let updatedSelectedRows = []
    //     let itemID = []
    //     var total = 0;
    //     updatedSelectedRows.push(selectedRows)
    //     for (let i = 0; i < updatedSelectedRows[0].length; i++) {
    //         total += updatedSelectedRows[0][i]?.grand_total
    //         itemID.push(updatedSelectedRows[0][i]?.id)
    //     }
    //     this.setState({
    //         PayingAmount: total,
    //         selectedID: itemID
    //     }, () => { this.SetTotalAsFirstAmount() })
    // }

    renderAmountTextFields = (label, key, Icon, PaymentNo = null) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                disabled={this.state.forCancel || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ emed_tid: `cb_${label}` }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        if (PaymentNo === null) {
                            states[key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                        else if (PaymentNo === "first") {
                            this.setState({ firstAmount: e.target.value })
                        }
                        else if (PaymentNo === "second") {
                            if (parseFloat(this.state.netAmount) >= +e.target.value) {
                                var firstAmount = parseFloat(this.state.netAmount) - +e.target.value
                                this.setState({
                                    secondAmount: +e.target.value,
                                    firstAmount: firstAmount
                                })
                            }
                        }
                    }
                }}
            />
        )
    }

    clearSinglePayment = (item) => {
        if (item === "2") {
            this.setState({
                CardTransactionNumber: "",
            })
        } else if (item === "3") {
            this.setState({
                UPINumber: "",
            })
        } else if (item === "4") {
            this.setState({
                transaction_id: ""
            })
        } else if (item === "5") {
            this.setState({
                reference_no: ""
            })
        } else if (item === "6") {
            this.setState({
                insurance_company_name: "",
                policy_number: "",
                transaction_number: "",
                validity: ""
            })
        }
        this.setState({
            secondAmount: ""
        })
    }

    clearAllData = () => {
        this.setState({
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            InsuranceAmount: "",
            InsuCompanyName: "",
            InsuPolicyNumber: "",
            InsuTransactionNumber: "",
            InsuValidity: '',
            PayingAmount: 0,
            totalPercentage: "",
            totalDiscount: "",
            roundOff: 0,
            netAmount: 0,
            firstPayment: "",
            secondPayment: "",
            firstAmount: "",
            secondAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            isInsurance: false,
        })
    }

    clearDetails = () => {
        this.setState({
            firstPayment: "",
            secondPayment: "",
            firstAmount: "",
            secondAmount: "",
            card_number: "",
            transaction_id: "",
            reference_no: "",
            insurance_company_name: "",
            policy_number: "",
            transaction_number: "",
            validity: ""
        })
    }

    ChangePaymentType = (item) => {
        let { data } = this.state
        if (this.state.firstPayment == "" && this.state.secondPayment == "") {
            if (this.state.secondPayment != item) {
                this.setState({
                    firstPayment: item
                })
            } else {
                this.setState({
                    secondPayment: ""
                }, () => {
                    this.clearSinglePayment(item)
                })
            }
        } else if (this.state.firstPayment != "" && this.state.secondPayment == "") {
            if (this.state.firstPayment != item) {
                this.setState({
                    secondPayment: item
                })
            } else {
                this.setState({
                    firstPayment: "",
                    save_action: false
                }, () => {
                    this.clearDetails()
                })
            }
        } else if (this.state.firstPayment != "" && this.state.secondPayment != "") {
            if (this.state.firstPayment == item) {
                this.setState({
                    firstPayment: this.state.secondPayment,
                    secondPayment: ""
                }, () => {
                    this.clearSinglePayment(item)
                })
            } else if (this.state.secondPayment == item) {
                this.setState({
                    secondPayment: ""
                }, () => {
                    this.clearSinglePayment(item)
                })
            } else {
                var tem_Type = this.state.secondPayment
                this.setState({
                    secondPayment: this.state.firstPayment,
                    firstPayment: item
                }, () => {
                    this.clearSinglePayment(tem_Type)
                })
            }
        }
        this.setState({
            firstAmount: this.state.netAmount,
            secondAmount: ""
        })
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20, emed_tid: `cb_${label}` }}
                disabled={this.state.forCancel || key === "CashBalanceAmount" || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                focused={key === "CashBalanceAmount"}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let onlynumber = CommonValidation.numberOnly(e.target.value);
                    if (key === "ReceivedCashAmount") {
                        if (onlynumber || e.target.value === "") {
                            this.setState({
                                ReceivedCashAmount: e.target.value,
                                CashBalanceAmount: +this.state.netAmount - +e.target.value
                            })
                        }
                    }
                    else {
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            })
                        }
                    }
                }
                }
            />
        )
    }

    handleCheckBox = (e, key) => {
        try {
            var states = this.state
            states[key] = e.target.checked
            this.setState({ states }, () => {
                if (!states.isCash) { this.setState({ CashAmount: "" }) }
                if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
                if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
                if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
                if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
                if (!states.isInsurance) { this.setState({ InsuCompanyName: "", InsuPolicyNumber: "", InsuTransactionNumber: "", InsuValidity: "" }) }
                if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                    this.setState({ CashAmount: this.state.netAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FullAmountEntered() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            let InsuranceAmt = states.InsuranceAmount === "" ? 0 : +states.InsuranceAmount;
            if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == +states.netAmount && +states.netAmount !== 0) {
                if (
                    (CardAmt === 0 || states.CardTransactionNumber !== "") &&
                    (UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== "") &&
                    (ChequeAmt === 0 || states.ChequeNumber !== "") &&
                    (InsuranceAmt === 0 || states.InsuCompanyName !== "" || states.InsuPolicyNumber !== "")
                ) {
                    return true
                }
            }
            else {
                return false
            }
        }
        catch (e) {
            return false
        }
    }

    renderLeftContent = () => {
        const { t } = this.props
        this.state.BillList?.forEach((item, index) => item.sno = index + 1);
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.03, headerAlign: "center", align: "center", renderCell: (index) => index.row.sno },
            {
                field: 'invoice_number', headerName: t("Bill No"), sortable: false, flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 20 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number?.slice(0, 18) + '...'}</div></Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            { field: 'total_item', headerName: t("Total Items"), sortable: false, flex: 0.04, headerAlign: "center", align: "center" },
            {
                field: 'invoice_date', headerName: t('BillDateTime'), sortable: false, flex: 0.07, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>  {params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
            },
            {
                field: 'bill_type', headerName: t('Bill Type'), type: "number", sortable: false, flex: 0.06, headerAlign: "center", align: "center",
                renderCell: (params) => <Typography color={params?.row?.bill_type === "Return" ? 'red' : 'black'}>{params?.row?.bill_type ? params?.row?.bill_type : "-"}</Typography>
            },
            {
                field: 'payment_status', headerName: t('Payment Status'), type: "number", sortable: false, flex: 0.04, headerAlign: "center", align: "center",
                renderCell: (params) => <Typography sx={{textTransform:"capitalize"}}>{params?.row?.payment_status ? params?.row?.payment_status : "-"}</Typography>
            },
            {
                field: 'grand_total', headerName: t('Bill Amount') + ` (${CurrencySymbol})`, type: "number", sortable: false, flex: 0.05,
                renderCell: (params) => <Typography>{AmountFormat(params?.row?.grand_total || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>
            },
            {
                field: 'action', headerName: t("Action"), type: 'actions', sortable: false, flex: 0.04, headerAlign: "center", align: "center",
                renderCell: ({ row }) => (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <IconButton emed_tid="cb_print_pending_bill" disabled={this.state.disableBtn} onClick={() => { this.printIndividual(row?.id) }} aria-label="Print"> <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' /> </IconButton>
                        {/* <Tooltip title={t("Delete")} placement="top" arrow>
                            <IconButton emed_tid="cnfmord_dlt" size='small' onClick={() => { this.setState({ isDelete: true, deleteId: row?.id }) }}>
                                <img src={ImagePaths.Delete.default} alt='Delete' className='eMed_dir_del' />
                            </IconButton>
                        </Tooltip> */}
                    </Stack>
                )
            }]
        return (
            <div>
                <Paper className="eMed_recPayment_Div" sx={{ height: '68vh' }} elevation={1}>
                    <DataGrid
                        // checkboxSelection={true}
                        // onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
                        rows={this.state.BillList}
                        rowCount={this.state.total}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.sno}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                    />
                </Paper>
            </div>
        )
    }

    renderRightContent = () => {
        const { t } = this.props
        let states = this.state;
        return (
            <Box component={'div'} className='eMed_room_right_card' sx={{ height: "68vh", marginTop: "0.7vw" }}>
                <Box component={'div'} sx={{ backgroundColor: Colors.DataTblHeaderbg }} className='eMed_room_right_header'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>{t("Billing")}</Typography>
                </Box>
                <Box sx={{ height: '57vh', overflowY: 'scroll' }}>
                    <Stack className="emedhub_recPayment_RightContentBox">
                        <Box component={'div'} className='eMed_flex_two'>
                            <Box component={'div'} className='eMed_pharma_bill_tbl_footer'>
                                <Typography mt={0.5} fontWeight={600} fontSize={'0.9vw'}>{t("Total Pending Amount")}</Typography>
                                <Typography emed_tid="cb_total_bill_amount" mt={0.5} mr={'0.1vw'} fontWeight={600}>
                                    {`${CurrencySymbol} ${states.TotalPendingAmount}`}
                                </Typography>
                            </Box>
                            <Box component={'div'} className='eMed_pharma_bill_tbl_footer'>
                                <Typography mt={0.5} fontWeight={600} fontSize={'0.9vw'}>{t("Payable Amount")}</Typography>
                                <OutlinedInput
                                    inputProps={{ emed_tid: "cb_total_discount_amount" }}
                                    value={states.PayingAmount ? states.PayingAmount : ""}
                                    startAdornment={<InputAdornment position="start">{CurrencySymbol}</InputAdornment>}
                                    sx={{ height: "2vw", fontSize: "0.9vw", width: "6.5vw" }}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= states.TotalPendingAmount) || value === "") {
                                            this.setState({
                                                PayingAmount: value
                                            })
                                        }
                                    }} />
                            </Box>
                            {/* <Box component={'div'} className='eMed_pharma_bill_tbl_footer'>
                                <Typography mt={0.5} fontWeight={600} fontSize={'0.9vw'}>{`${t("Discount")} %`}</Typography>
                                <OutlinedInput
                                    inputProps={{ emed_tid: "cb_total_discount_percent" }}
                                    value={states.totalPercentage}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    sx={{ height: "2vw", fontSize: "0.9vw", width: "6vw" }}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
                                            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.PayingAmount)
                                            this.setState({
                                                totalPercentage: value
                                            }, () => { this.SetTotalAsFirstAmount() })
                                        }
                                    }} />
                                <OutlinedInput
                                    inputProps={{ emed_tid: "cb_total_discount_amount" }}
                                    value={states.totalDiscount}
                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                    sx={{ height: "2vw", fontSize: "0.9vw", width: "6.5vw" }}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= states.PayingAmount) || value === "") {
                                            states.totalPercentage = CommonValidation.amountPercentage(value, states.PayingAmount)
                                            this.setState({
                                                totalDiscount: value
                                            }, () => { this.SetTotalAsFirstAmount() })
                                        }
                                    }} />
                            </Box> */}
                            <Box component={'div'} className='eMed_pharma_bill_tbl_footer'>
                                <Typography mt={0.5} fontWeight={600} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
                                <Typography emed_tid="cb_total_roundoff_amount" mt={0.5} mr={'0.1vw'}>
                                    {this.billingCalculation('roundOff')}
                                </Typography>
                            </Box>
                            <Box component={'div'} className='eMed_pharma_bill_tbl_footer' borderTop={"2px solid gray"}>
                                <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("Amount Payable")}</Typography>
                                <Typography emed_tid="cb_total_payable_amount" mt={0.5} mr={'0.1vw'}>
                                    {this.billingCalculation('payableAmount')}
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Box component={'div'} sx={{ backgroundColor: Colors.DataTblHeaderbg }} className='eMed_room_right_header'>
                        <Typography fontWeight={600} color={Colors.SecondaryText}>{t("Payment Type")}</Typography>
                    </Box>
                    <Stack className="emedhub_recPayment_RightContentBox">
                        <FormGroup row>
                            {/* <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox emed_tid="cb_cash_payment" size="small" checked={this.state.firstPayment == "1" || this.state.secondPayment == "1"} onChange={(e) => { this.ChangePaymentType("1") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
                        <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox emed_tid="cb_card_payment" size="small" checked={this.state.firstPayment == "2" || this.state.secondPayment == "2"} onChange={(e) => { this.ChangePaymentType("2") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Card")}</Typography>} />
                        <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox emed_tid="cb_upi_payment" size="small" checked={this.state.firstPayment == "4" || this.state.secondPayment == "4"} onChange={(e) => { this.ChangePaymentType("4") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
                        <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox emed_tid="cb_bank_payment" size="small" checked={this.state.firstPayment == "5" || this.state.secondPayment == "5"} onChange={(e) => { this.ChangePaymentType("5") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
                        <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox emed_tid="cb_insurance_payment" size="small" checked={this.state.firstPayment == "6" || this.state.secondPayment == "6"} onChange={(e) => { this.ChangePaymentType("6") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Insurance")}</Typography>} /> */}
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} />
                        </FormGroup>
                        <Box component={'div'}>
                            {
                                this.state.isCash ?
                                    <Box component={"div"} display={"flex"} flexDirection={"column"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                        {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque ?
                                            <Box component={"div"} display={"flex"} flexDirection={"row"}>
                                                {this.renderNumberTextFields("Received Amount", "ReceivedCashAmount", false)}
                                                {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                            </Box> : null
                                        }
                                    </Box> : null
                            }
                            {
                                this.state.isCard ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                        {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isUPI ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                        {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isBankTranfer ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                        {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isCheque ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                        {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isInsurance ?
                                    <Box component={"div"} display={"flex"} flexWrap={"wrap"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("InsuranceAmount", "InsuranceAmount", ImagePaths.ChequeIcon.default)}
                                        {this.renderNumberTextFields("Company Name *", "InsuCompanyName", true)}
                                        {this.renderNumberTextFields("Policy Number *", "InsuPolicyNumber", false)}
                                        {this.renderNumberTextFields("Transaction Number", "InsuTransactionNumber", true)}
                                        {this.renderNumberTextFields("Validity", "InsuValidity", false)}
                                    </Box>
                                    : null
                            }
                        </Box>
                    </Stack>
                </Box>


                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg, marginTop: "0.3vw" }} alignItems="center" justifyContent="center">
                    <Button emed_tid="cb_clear_btn" variant='outlined' onClick={() => { this.clearAllData() }} size="small" id="eMed_recPayment_btn" >{t("Clear")}</Button>
                    <Button emed_tid="cb_receive_btn" variant='contained' onClick={() => { this.complateBill() }} size="small" id="eMed_recPayment_btn" disabled={!this.FullAmountEntered()}>{t("Receive")}</Button>
                </Stack>
            </Box>
        )
    }

    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null
        }, () => {
            this.props.history.push({ pathname: "/PharmacyInvoice/CreditBills" })
        })
    }

    CrebillPosted(message = "", rec = null, phrrec = null, error = false) {
        this.setState({
            MakePayment: false,
        }, () => {
            if (error) {
                this.errorMessage(message)
            } else {
                this.successMessage(message)
            }
            this.GetBillList()
        })
    }

    ClosePopUp() {
        this.setState({
            MakePayment: false,
        })
    }

    render() {
        const { t } = this.props;
        let totalAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Total Bill Amount"),
            lineTwo: AmountFormat(this.state.TotalAmount || 0),
            colorCode: "black"
        }
        let paidAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Total Paid Amount"),
            lineTwo: AmountFormat(this.state.PaidAmount || 0),
            colorCode: this.state.PaidAmount > 0 ? 'green' : +this.state.PaidAmount === 0 ? "balck" : 'red'
        }
        let pendingAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Total Receivable Amount"),
            lineTwo: AmountFormat(this.state.PendingAmount || 0),
            colorCode: +this.state.PendingAmount === 0 ? "balck" : 'red'
        }
        let returnAmt = {
            image: CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Total Return Amount"),
            lineTwo: AmountFormat(this.state.ReturnAmount || 0),
            colorCode: +this.state.ReturnAmount === 0 ? "balck" : 'red'
        }
        return (
            <div>
                <Box component={'div'} display={'flex'} flexDirection={'column'} sx={{ backgroundColor: Colors.ComponentBgColor, height: '81vh' }}>
                    <Box component={'div'} className='' height={'9vh'} >
                        <Box component={'div'} display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                            <Box component={'div'} display={'flex'}>
                                <Box component={'div'} className='eMed_nur_notes_details eMed_bill_header_one'>
                                    <CommonPatientDetails data={this.props.PatientDetails} showDetailed={false} fromPurchase={true} />
                                </Box>
                                <CommonPatientCard smallWidth={true} details={totalAmt} />
                                <CommonPatientCard smallWidth={true} details={paidAmt} />
                                <CommonPatientCard details={pendingAmt} />
                                <CommonPatientCard  smallWidth={true} details={returnAmt} />
                            </Box>
                            <Box component={'div'} display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                                <Button className='eMed_usrconf_btn' variant='contained' sx={{textTransform:'capitalize'}} emed_tid = {"Receive_Payment_TestID"} onClick={() => this.setState({ MakePayment: true })}>
                                    Receive Payment
                                </Button>
                                <Tooltip title="Print" placement='top' arrow>
                                    <Button className='eMed_usrconf_btn' emed_tid='Pharmacy_Credit_Popup_Print' disabled={this.state.disableBtn} onClick={() => this.printReports()}>
                                        <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Box component={'div'} width={"92.7vw"} marginLeft={"0.5vw"}>
                            {this.renderLeftContent()}
                        </Box>
                        {/* <Box component={'div'} className='eMed_room_right_content'>
                            {this.renderRightContent()}
                        </Box> */}
                    </Box>
                </Box>
                {
                    this.state.MakePayment ?
                        <CreditBillCompletePoP
                            modules={['pharmacy']}
                            Module_ID_Key_Name={this.props.SelectedCreditBill?.module === "OP" ? "appointment_id" : this.props.SelectedCreditBill?.module === "IP"  ? "ip_admission_id" : "bill_id"}
                            Module_ID_Key_Value={(this.props.SelectedCreditBill?.module === "OP" || this.props.SelectedCreditBill?.module === "IP" ) ? this.props.SelectedCreditBill?.module_id : this.state.BillList.map((list)=>list?.id)}
                            BillData={this.props.PatientDetails}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={"PHARMACY"}
                            Bill_Id={this.props.FilterDatas?.is_bill_wise ? ((this.props.SelectedCreditBill?.module === "OP" ||  this.props.SelectedCreditBill?.module === "IP") ? this.props.SelectedCreditBill?.id : null) : null}
                        /> : null}
                {
                    this.state.isDelete && this.state.deleteId ? <ReasonPopup
                        title={`${t("deleteMsg")}?`}
                        AlertPopupClose={this.reasonPopupClose.bind(this)}
                        label={"Enter the Reason"}
                        btntext={'Cancel'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        sendCmt={this.deleteItem.bind(this)} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.receiptID ?
                    <BillSuccessPoPUp creditreceipt={this.state.receiptID} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
            </div>
        )
    }
}

export default withTranslation()(PharmaCreditBilling)