import React, { Component } from "react";
import {Button, Stack, Tooltip,} from "@mui/material";
import { withTranslation } from 'react-i18next';
import { DataGrid } from "@mui/x-data-grid";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import moment from 'moment/moment';
import { AmountFormat } from "../../../Components/CommonFunctions/CommonFunctions";
import { CurrencySymbol } from "../../../Utility/Constants";

class OTDrugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10,
            stockListData: [],
        }
    }

    componentDidMount = () => {
        this.getStockList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getStockList = () => {
        try {
            RestAPIService.getAll(Serviceurls.OT_DRUG_LIST_GET).
                then((response) => {
                    if (response.data.status === "Success") {
                        this.setState({
                            stockListData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    render() {
        this.state.stockListData.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.07 },
            {
                field: 'brand_name', headerName: t('BrandName'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.brand_name}</div>)
            },
            {
                field: 'generic_name', headerName: t('Generic Name'), sortable: false, flex: 0.111,
                renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.generic_name}</div>)
            },
            { field: 'dosage_type', headerName: t('Dosage Type'), sortable: false, flex: 0.111 },
            { field: 'dosage_strength', headerName: t("Dosage Strength"), sortable: false, flex: 0.111, type: "number" },
            { field: 'batch_no', headerName: t('BatchNumber'), sortable: false, flex: 0.111, type: "number" },
            { field: 'stock_in_quantity', headerName: t('Quantity'), sortable: false, flex: 0.111, type: "number" },
            {
                field: 'expiry_date', headerName: t('Expiry Date'), sortable: false, flex: 0.121, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>{moment(params?.row?.expiry_date).format("DD-MM-YYYY")}</div>)
            },
            {
                field: 'physical_box_number', headerName: t('Box'), sortable: false, flex: 0.111, type: "number",
                renderCell: (params) => (<div>{params?.row?.physical_box_number ? params?.row?.physical_box_number : "-"}</div>)
            },
            { field: 'mrp_per_quantity', headerName: t('MRP/Quantity') + ` (${CurrencySymbol})`, sortable: false, flex: 0.111, type: "number",
                renderCell: (params) => (<div>{AmountFormat(params?.row?.mrp_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</div>)
             }]
        return (
            <div className="stock_card">
                <div>
                    <div role={'button'} className="active_tab_header_card">
                        <Button variant="contained" className="checkbox" onClick={() => { this.props.history.push({ pathname: "/OTStockTransfer/RaiseRequest", state: { page: "inbound", isfrom: "OtDrugs" } }) }}  >
                            {t("+ INDENT")}
                        </Button>

                        {/* <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' onClick={() => this.getStockListExcel()}>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='stock_print_design' />
                            </Button></Tooltip> */}

                    </div>
                    <div className="stock_table_container">
                        <DataGrid
                            rows={this.state.stockListData}
                            rowCount={this.state.total}
                            page={this.state.page}
                            columns={columns}
                            hideFooterSelectedRowCount
                            getRowId={(row) => row["sno"]}
                            pageSize={this.state.pageSize}
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            headerHeight={40}
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                        />
                    </div>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </div>
            </div>
        )
    }
}
export default withTranslation()(OTDrugs);