import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonGridHeader, { DeletePopup } from "../../../Components/Common Components/CommonComponents";
import moment from "moment";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import "../CorporateConfigurations/CorpStyle.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { CheckAccessFunc, convertDateSlashToHyphen } from "../../../Components/CommonFunctions/CommonFunctions";
import DocScheduleConfig from "../../Doctor/Common/Home/DocScheduleConfig";
import DocSessionConfig from "../../Doctor/Common/Home/DocSessionConfig";
import CommonDatePicker from "../../../Components/Common Components/CommonDatePicker";
import { DateTime } from 'luxon';
import { localGetItem } from "../../../Utility/Services/CacheProviderService";
import Loader from '../../../Components/Loader'

const SchedulesDays = [
    { label: "Sunday", value: "1" },
    { label: "Monday", value: "2" },
    { label: "Tuesday", value: "3" },
    { label: "Wednesday", value: "4" },
    { label: "Thursday", value: "5" },
    { label: "Friday", value: "6" },
    { label: "Saturday", value: "7" }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SlotConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docDetails: [],
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            EHRStartTime1: "",
            EHRStartTime2: "",
            EHRStartTime3: "",
            EHREndTime1: "",
            EHREndTime2: "",
            EHREndTime3: "",
            IntervelTime: "",
            ScheduledSession1: [],
            ScheduledSession2: [],
            ScheduledSession3: [],
            EHRSession: 1,
            EditSlotPopup: false,
            docSchedule: [],
            isEdit: false,
            deletedPopup: false,
            disableBtn: false,

            SelectedSessionTab: "one",
            SelectedDoctorDetails: {},
            isOutofOffice: false,
            DeletePopup: false,
            GetSchedule: false,
            SelectedSession: 'one',
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            OFFSessions: [],
            SessionList: [],
            page: 0,
            prePage: 0,
            pageSize: 10,
            prePageSize: 10,
            HistoryList: [],
            OFFDescription: "",
            selectedIds: [],
            renderScreen: false,
            SessionType: "all_sessions",
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getDoctorDetails();
    }

    GetSessionList(FromDate, ToDate) {
        try {
            this.LoaderFunction(true)
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            let Default = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
            RestAPIService.getAll(Serviceurls.DOC_SESSION_LIST + `?doctor_id=${this.state.SelectedDoctorDetails?.doctor_id ? this.state.SelectedDoctorDetails?.doctor_id : null}&clinic_id=${ClinicID ? ClinicID : null}&from_date=${FromDate ? FromDate : Default}&to_date=${ToDate ? ToDate : Default}`).
                then((response) => {
                    if (response.data.status === "success") {
                        let Response = response.data.data ? response.data.data : {}
                        this.setState({
                            SessionList: Response
                        }, () => {
                            this.LoaderFunction(false)
                            this.setState({ isOutofOffice: true })
                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(response.data.message, false)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.responseSuccessErrorMessage(error.message, false)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false)
        }
    }

    GetOFFHistory() {
        try {
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.DOC_SCHEDULE_OUTOFOFFICE + '?doctor_id=' + (this.state.SelectedDoctorDetails?.doctor_id ? this.state.SelectedDoctorDetails?.doctor_id : null) + '&clinic_id=' + (ClinicID ? ClinicID : null)).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            HistoryList: response.data.data ? response.data.data : null
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(response.data.message, false)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.responseSuccessErrorMessage(error.message, false)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false)
        }
    }

    DeleteOFFHistory = () => {
        try {
            this.LoaderFunction(true)
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            RestAPIService.delete(`${Serviceurls.DOC_SCHEDULE_OUTOFOFFICE}?id=${this.state.deleteID}&doctor_id=${this.state.SelectedDoctorDetails?.doctor_id ? this.state.SelectedDoctorDetails?.doctor_id : null}&clinic_id=${ClinicID ? ClinicID : null}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.responseSuccessErrorMessage(response.data.message, true);
                        this.LoaderFunction(false)
                        this.setState({
                            DeletePopup: false,
                            renderScreen: true
                        }, () => { this.GetOFFHistory() })
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index,
            OFFSessions: [],
            SessionType: 'all_sessions'
        }, () => {
            this.GetSessionList(from, to)
        })
    }

    PostOutOfOffice = () => {
        try {
            var states = this.state
            let sessionData = ""
            let fromDate = states.fromDate
            let toDate = states.toDate
            if (states?.OFFSessions?.length > 0) {
                let OFFSessionsValue = JSON.parse(JSON.stringify(states?.OFFSessions))
                let sessionArray = []

                OFFSessionsValue.sort((a, b) => {
                    let dateA = new Date(convertDateSlashToHyphen(a?.date))
                    let dateB = new Date(convertDateSlashToHyphen(b?.date))
                    return dateA - dateB;
                });

                OFFSessionsValue?.forEach((item) => {
                    sessionArray.push(item?.session_name)
                })

                fromDate = OFFSessionsValue[0]?.date
                toDate = OFFSessionsValue[OFFSessionsValue.length - 1]?.date
                fromDate = fromDate ? convertDateSlashToHyphen(fromDate) : ""
                toDate = toDate ? convertDateSlashToHyphen(toDate) : ""
                sessionData = String(sessionArray)
            }

            var data = {
                "doctor_id": states?.SelectedDoctorDetails?.doctor_id,
                "from_date": fromDate,
                "to_date": toDate,
                "session": sessionData,
                "out_reason": states?.OFFDescription
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.DOC_SCHEDULE_OUTOFOFFICE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isOutofOffice: false,
                            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            dateIndex: 1,
                            SelectedSession: 'one',
                            OFFDescription: "",
                            OFFSessions: [],
                            SessionType: 'all_sessions',
                            renderScreen: true,
                        }, () => { this.setState({ GetSchedule: true }) })
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(response.data.message, true)
                    } else {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(response.data.message, false)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.LoaderFunction(false)
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    OutofOfficePopup = () => {
        try {
            const { t } = this.props
            let states = this.state
            return (
                <Modal open={states.isOutofOffice}>
                    <Box className='eMed_New_Ip_Popup' sx={{ width: states?.SelectedSession === "one" ? "30vw" : "36vw", height: states?.SelectedSession === "one" ? (states?.SessionType === "all_sessions" ? "50vh" : "55vh") : "29vw" }}>
                        <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
                            <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'0.5vw'} alignItems={'center'} height={'3vw'}>
                                <Tabs
                                    value={this.state.SelectedSession}
                                    onChange={(e, newValue) => {
                                        if (newValue === "two") {
                                            this.GetOFFHistory()
                                        }
                                        this.setState({
                                            SelectedSession: newValue
                                        })
                                    }}
                                >
                                    <Tab value="one" label={t("Out Of Office")} emed_tid='outOfOffice_tab' className='eMed_tab_header' />
                                    <Tab value="two" label={t("History")} emed_tid='history_tab' className='eMed_tab_header' />
                                </Tabs>
                            </Box>
                        </Box>
                        <Box margin={"1vw"}>
                            {states?.SelectedSession === "one" ?
                                <Box>
                                    <Box>
                                        <CommonDatePicker
                                            FromDate={this.state.fromDate}
                                            ToDate={this.state.toDate}
                                            DateIndex={this.state.dateIndex}
                                            SelectedDates={this.FilterDate.bind(this)}
                                            Past={true}
                                            Future={true}
                                            OutOfOffice={true}
                                            HideAllMenu={true} />
                                    </Box>
                                    <FormControl sx={{ marginLeft: "1vw" }}>
                                        <RadioGroup
                                            value={this.state.SessionType}
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            onChange={(e) => {
                                                this.setState({ SessionType: e.target.value, OFFSessions: [] })
                                            }}
                                        >
                                            <FormControlLabel value="all_sessions" control={<Radio size='small' inputProps={{ 'emed_tid': `all_sessions_radio_testID` }} />} label="All Sessions" />
                                            <FormControlLabel value="specific_sessions" control={<Radio size='small' inputProps={{ 'emed_tid': `specific_sessions_radio_testID` }} />} label="Specific Sessions" />
                                        </RadioGroup>
                                    </FormControl>
                                    {this.state.SessionType === "specific_sessions" ?
                                        <Autocomplete
                                            multiple
                                            disableClearable
                                            size='small'
                                            value={this.state.OFFSessions}
                                            sx={{ width: "26.7vw", marginY: "0.5vw" }}
                                            options={this.state.SessionList}
                                            getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.session_name}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    OFFSessions: newValue
                                                })
                                            }}
                                            renderInput={(params) => (<TextField {...params} inputProps={{...params.inputProps, 'emed_tid': 'specifcSession_sessionList_testID' }} label={t('Select Session')} />)} />
                                        : null}
                                    <hr />
                                    <TextField
                                        multiline
                                        label='Reason *'
                                        rows={3}
                                        sx={{ width: '26.7vw', marginTop: "0.5vw" }}
                                        value={this.state.OFFDescription}
                                        inputProps={{ emed_tid: "outofoffice_description_testID" }}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length < 250) {
                                                this.setState({ OFFDescription: e.target.value })
                                            } else {
                                                this.responseSuccessErrorMessage('Allowed only 250 characters')
                                            }
                                        }}
                                    />
                                </Box> :
                                <Box>{this.renderHistoryScreen()}</Box>
                            }
                        </Box>
                        <Box component={'div'}>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='outlined'
                                    emed_tid="outofoffice_popup_cancelBtn_testID"
                                    onClick={() => {
                                        this.setState({ isOutofOffice: false }, () => {
                                            this.setState({
                                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                                dateIndex: 1,
                                                SelectedSession: 'one',
                                                OFFDescription: "",
                                                OFFSessions: [],
                                                selectedIds: [],
                                                SessionType: 'all_sessions'
                                            })
                                        })
                                    }}
                                >Cancel</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='contained'
                                    emed_tid="outofoffice_popup_sendDeleteBtn_testID"
                                    disabled={(this.state.SelectedSession === "two" && this.state.selectedIds.length == 0) || this.state.disableBtn}
                                    onClick={() => {
                                        if (this.state.SelectedSession === "one") {
                                            if (this.state.OFFDescription === "") {
                                                this.responseSuccessErrorMessage("Enter Out Of Office Reason")
                                            } else {
                                                this.setState({ disableBtn: true }, () => this.PostOutOfOffice())
                                            }
                                        } else {
                                            this.setState({ DeletePopup: true, deleteID: this.state.selectedIds })
                                        }
                                    }}
                                >{this.state.SelectedSession === "one" ? "Send" : "Delete"}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    renderHistoryScreen = () => {
        try {
            const { t } = this.props
            let currenDate = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")
            const columns = [
                {
                    field: 'from_date', headerName: 'From Date', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.from_date ? params?.row?.from_date : '-'}</Box>)
                },
                {
                    field: 'to_date', headerName: 'To Date', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.to_date ? params?.row?.to_date : '-'}</Box>)
                },
                {
                    field: 'out_reason', headerName: 'Reason', flex: 0.35,
                    renderCell: (params) => (
                        <Box component={'div'}>
                            {params?.row?.out_reason ? params?.row?.out_reason?.length > 12 ?
                                <Tooltip placement="top" title={params?.row?.out_reason}><Typography fontSize={'0.9vw'}>{params?.row?.out_reason?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{params?.row?.out_reason}</Typography> : '-'}
                        </Box>)
                },
                {
                    field: 'action', headerName: 'Action', flex: 0.25, align: "center",
                    renderCell: (params) => {
                        let disable = params?.row?.to_date?.split("-")?.reverse()?.join("-") < currenDate
                        return (<Box component={'div'}>
                            <button className='eMed_button' style={{ backgroundColor: (!params?.row?.is_active || disable) ? "#bab4b4" : "#f52828" }} onClick={() => { if (params?.row?.is_active && !disable) { this.setState({ DeletePopup: true, deleteID: params?.row?.id }) } }} emed_tid="cancel_btn_history_tab_testId">
                                {params?.row?.is_active ? "Cancel" : "Cancelled"}
                            </button>
                        </Box>)
                    }
                }
            ]
            return (
                <Box component={'div'} className='eMed_Presc_History_pop_table' sx={{ width: "33vw", height: "20vw" }}>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                            const activeIds = newSelection.filter((id) => {
                                const selectedRow = this.state.HistoryList.find(row => row.id === id);
                                return selectedRow && selectedRow.is_active;
                            });
                            this.setState({ selectedIds: activeIds });
                        }}
                        isRowSelectable={(params) => params?.row?.is_active}
                        rows={this.state.HistoryList}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        pageSize={this.state.prePageSize}
                        page={this.state.prePage}
                        onPageChange={(page) => this.setState({ prePage: page })}
                        onPageSizeChange={(pageSize) => this.setState({ prePageSize: pageSize })}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata' sx={{ height: "30vh" }}>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            )
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message,
            disableBtn: false,
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getDoctorDetails = () => {
        this.setState({
            docDetails: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            docDetails: response.data.data,
                        }, () => { this.LoaderFunction(false) })
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error.response?.data.status === "fail") {
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    getDoctorSchedule = (id) => {
        try {
            this.setState({ disableBtn: true })
            RestAPIService.getAll(Serviceurls.SCHEDULE_POST_API + "?doctor_id=" + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            docSchedule: response.data.data,
                        }, () => {
                            this.setState({ EditSlotPopup: true, disableBtn: false })
                            let session1 = []; this.state.docSchedule.forEach((element) => element?.session_id == 1 ? session1 = element : null)
                            let session2 = []; this.state.docSchedule.forEach((element) => element?.session_id == 2 ? session2 = element : null)
                            let session3 = []; this.state.docSchedule.forEach((element) => element?.session_id == 3 ? session3 = element : null)
                            if (session1?.session_id) {
                                this.getWeekDays(session1?.available_days, "ScheduledSession1")
                                this.setState({
                                    EHRStartTime1: session1?.start_time,
                                    EHREndTime1: session1?.end_time,
                                    IntervelTime: session1?.interval,
                                    ScheduleID1: session1?.schedule_id,
                                    isEdit: true
                                })
                            }
                            if (session2?.session_id) {
                                this.getWeekDays(session2?.available_days, "ScheduledSession2")
                                this.setState({
                                    EHRStartTime2: session2?.start_time,
                                    EHREndTime2: session2?.end_time,
                                    IntervelTime: session2?.interval,
                                    ScheduleID2: session2?.schedule_id,
                                    isEdit: true
                                })
                            }
                            if (session3?.session_id) {
                                this.getWeekDays(session3?.available_days, "ScheduledSession3")
                                this.setState({
                                    EHRStartTime3: session3?.start_time,
                                    EHREndTime3: session3?.end_time,
                                    IntervelTime: session3?.interval,
                                    ScheduleID3: session3?.schedule_id,
                                    isEdit: true
                                })
                            }
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.setState({ disableBtn: false })
                        this.responseSuccessErrorMessage(error.response?.data?.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    timeString = (time) => {
        const date = new Date(time);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const timeString = `${hours}:${minutes}:${seconds}`;

        return timeString
    }

    getWeekNumbers = (weekNames) => {
        const weekNamesDict = {
            'Sunday': 1, 'Monday': 2, 'Tuesday': 3, 'Wednesday': 4, 'Thursday': 5, 'Friday': 6, 'Saturday': 7
        };

        return weekNames.map(day => weekNamesDict[day]);
    }

    getWeekDays = (weekNames, key) => {
        let ScheduledSession = []
        let states = this.state
        const weekNamesDict = {
            1: 'Sunday', 2: 'Monday', 3: 'Tuesday', 4: 'Wednesday', 5: 'Thursday', 6: 'Friday', 7: 'Saturday'
        };

        ScheduledSession = weekNames.map(day => weekNamesDict[day]);
        states[key] = ScheduledSession
        this.setState({ states })
    }

    PostSlotConfiguration = () => {
        try {
            let { EHRSession, selectedData, IntervelTime } = this.state
            let states = this.state
            var EHRStartTime = EHRSession == 1 ? states?.EHRStartTime1 : EHRSession == 2 ? states?.EHRStartTime2 : states?.EHRStartTime3
            var EHREndTime = EHRSession == 1 ? states?.EHREndTime1 : EHRSession == 2 ? states?.EHREndTime2 : states?.EHREndTime3
            var ScheduledSession = EHRSession == 1 ? states?.ScheduledSession1 : EHRSession == 2 ? states?.ScheduledSession2 : states?.ScheduledSession3
            if (states.ScheduleID1 || states.ScheduleID2 || states.ScheduleID3) {
                var ScheduleID = EHRSession == 1 ? states?.ScheduleID1 : EHRSession == 2 ? states?.ScheduleID2 : states?.ScheduleID3
            }
            if (EHRStartTime != "" && EHRStartTime != "Invalid Date" && EHREndTime != "" && EHREndTime != "Invalid Date" && IntervelTime != "") {
                const newArray = this.getWeekNumbers(ScheduledSession)
                var startTime = ""; var endTime = "";
                if (typeof EHRStartTime === "string" && typeof EHREndTime !== "string") {
                    startTime = EHRStartTime; endTime = this.timeString(EHREndTime)
                } else if (typeof EHRStartTime !== "string" && typeof EHREndTime === "string") {
                    startTime = this.timeString(EHRStartTime); endTime = EHREndTime
                } else if (typeof EHRStartTime === "string" && typeof EHREndTime === "string") {
                    startTime = EHRStartTime; endTime = EHREndTime
                } else {
                    startTime = this.timeString(EHRStartTime); endTime = this.timeString(EHREndTime)
                }
                let data = {
                    "doctor_id": selectedData?.doctor_id,
                    "session_id": EHRSession,
                    "start_time": startTime,
                    "end_time": endTime,
                    "available_days": newArray,
                    "interval": IntervelTime
                }
                if (ScheduleID) {
                    data["schedule_id"] = ScheduleID
                }
                RestAPIService.create(data, Serviceurls.SCHEDULE_POST_API)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({ EditSlotPopup: false, isEdit: false }, () => {
                                this.SelectedTab(1)
                                this.responseSuccessErrorMessage(response.data.message, true)
                            })
                        }
                    }).catch((error) => {
                        this.setState({ disableBtn: false })
                        if (error?.response?.data?.message) {
                            this.responseSuccessErrorMessage(error.response.data.message, false)
                        } else {
                            this.responseSuccessErrorMessage(error.message, false)
                        }
                    })
            } else {
                if (EHRStartTime == "Invalid Date" || EHREndTime == "Invalid Date") {
                    this.responseSuccessErrorMessage("Invalid Time Formate", false)
                } else if (EHRStartTime == "" || EHREndTime == "" || IntervelTime == "") {
                    this.responseSuccessErrorMessage("Mandatory fields should not be empty", false)
                }
            }
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false)
        }
    }

    deleteSessionData = () => {
        let states = this.state
        try {
            if ((states.EHRSession == 1 && states.ScheduleID1) || (states.EHRSession == 2 && states.ScheduleID2) || (states.EHRSession == 3 && states.ScheduleID3)) {
                var ScheduleID = states.EHRSession == 1 ? states?.ScheduleID1 : states.EHRSession == 2 ? states?.ScheduleID2 : states?.ScheduleID3
                RestAPIService.delete(Serviceurls.SCHEDULE_POST_API + `delete/?id=${ScheduleID}`, "")
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({ EditSlotPopup: false, isEdit: false }, () => {
                                this.SelectedTab(1)
                                this.responseSuccessErrorMessage(response.data.message, true)
                            })
                        }
                    }).catch((e => {
                        this.responseSuccessErrorMessage(e.response.data.message, false)
                    }))
            } else {
                this.responseSuccessErrorMessage("No Schedule for this Session ", false)
            }

        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false)
        }
    }

    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    handleEdit = (item) => {
        this.setState({
            selectedData: item
        }, () => { this.getDoctorSchedule(item?.doctor_id); })
    }

    renderTimePicker = (key, value, label) => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker label={label}
                    onChange={(newStartTime) => {
                        var states = this.state;
                        if (newStartTime == null) {
                            states[key] = new Date()
                            this.setState({ states })
                        } else {
                            states[key] = newStartTime.$d;
                            this.setState({ states })
                        }
                    }}
                    value={value ? moment(value, "hh:mm a") : ""}
                    renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)","emed_tid": `timePicker_${key}`  }} size="small" />} />
            </LocalizationProvider>
        )
    }

    SelectedTab = (tab) => {
        this.setState({
            EHRStartTime1: "",
            EHRStartTime2: "",
            EHRStartTime3: "",
            EHREndTime1: "",
            EHREndTime2: "",
            EHREndTime3: "",
            IntervelTime: "",
            ScheduledSession1: [],
            ScheduledSession2: [],
            ScheduledSession3: [],
            EHRSession: tab,
            ScheduleID1: "",
            ScheduleID2: "",
            ScheduleID3: ""
        })
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            DeletePopup: false,
            disableBtn: false,
        })
    }

    removeDataList = () => {
        this.deleteSessionData()
        this.setState({
            deletedPopup: false
        })
    }

    OpenAppointmentSlotPopUP = () => {
        let states = this.state
        return (
            <Box>
                <Modal open={true}>
                    <Box className="eMed_WebCamPop_Main" sx={{ width: "40vw", height: "55vh" }}>
                        <Box component={'div'} className="eMed_WebCam_Header">
                            <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                Slot Configuration
                            </Typography>
                            <Button emed_tid="appointment_slotPopup_close_btn" onClick={() => { this.setState({ EditSlotPopup: false, isEdit: false }, () => { this.SelectedTab(1) }) }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <hr />
                        <Box component={'div'} style={{ height: "35vh", margin: "1.5vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "1vw", paddingLeft: "2vw", paddingRight: "2vw", marginBottom: "1vw" }}>
                                <Button emed_tid="appointment_slotPopup_session1_btn" variant={this.state.EHRSession == 1 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(1) } else { this.setState({ EHRSession: 1 }) } }}>Session 1</Button>
                                <Button emed_tid="appointment_slotPopup_session2_btn" variant={this.state.EHRSession == 2 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(2) } else { this.setState({ EHRSession: 2 }) } }}>Session 2</Button>
                                <Button emed_tid="appointment_slotPopup_session3_btn" variant={this.state.EHRSession == 3 ? 'contained' : 'outlined'} size='small' style={{ width: "8vw" }} onClick={() => { if (!this.state.isEdit) { this.SelectedTab(3) } else { this.setState({ EHRSession: 3 }) } }}>Session 3</Button>
                            </div>
                            {this.state.EHRSession == 1 ?
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                            SESSION 1
                                        </Typography>
                                        <IconButton style={{ marginLeft: "0.5vw" }} size='small' emed_tid="appointment_slotPopup_delete_btn">
                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                onClick={() => { this.setState({ deletedPopup: true }) }}
                                            />
                                        </IconButton>
                                    </div>
                                    <hr />
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHRStartTime1", this.state.EHRStartTime1, "Start Time *")}
                                            </div>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHREndTime1", this.state.EHREndTime1, "End Time *")}
                                            </div>
                                            <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                                <TextField
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 3, emed_tid: "appointment_slotPopup_intervalInput" }}
                                                    label={"Enter Intervel in Minutes *"}
                                                    size="small"
                                                    value={this.state.IntervelTime}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        let isValid = CommonValidation.numberOnly(value)
                                                        if (value === "" || isValid && value <= 300) {
                                                            states["IntervelTime"] = value
                                                            this.setState({ states })
                                                        }
                                                    }}
                                                    style={{ width: "12vw" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                            <FormControl>
                                                <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={this.state.ScheduledSession1}
                                                    onChange={(e) => {
                                                        states["ScheduledSession1"] = e.target.value
                                                        this.setState({ states })
                                                    }}
                                                    style={{ width: "34.5vw" }}
                                                    input={<OutlinedInput label="Select Week *" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ emed_tid: "appointment_slotPopup_selectWeek" }}
                                                >
                                                    {SchedulesDays.map((name) => (
                                                        <MenuItem key={name.value} value={name.label}>
                                                            <Checkbox checked={this.state.ScheduledSession1.indexOf(name.label) > -1} />
                                                            <ListItemText primary={name.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div></div> : null}
                            {this.state.EHRSession == 2 ?
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                            SESSION 2
                                        </Typography>
                                        <IconButton style={{ marginLeft: "0.5vw" }} size='small' emed_tid="appointment_slotPopup_S2_delete_btn">
                                            <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                                onClick={() => { this.setState({ deletedPopup: true }) }}
                                            />
                                        </IconButton>
                                    </div>
                                    <hr />
                                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHRStartTime2", this.state.EHRStartTime2, "Start Time *")}
                                            </div>
                                            <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                                {this.renderTimePicker("EHREndTime2", this.state.EHREndTime2, "End Time *")}
                                            </div>
                                            <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                                <TextField
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 3, emed_tid: "appointment_slotPopup_S2_intervalInput" }}
                                                    label={"Enter Intervel in Minutes *"}
                                                    size="small"
                                                    value={this.state.IntervelTime}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        let isValid = CommonValidation.numberOnly(value)
                                                        if (value === "" || isValid && value <= 300) {
                                                            states["IntervelTime"] = value
                                                            this.setState({ states })
                                                        }
                                                    }}
                                                    style={{ width: "12vw" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                            <FormControl>
                                                <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={this.state.ScheduledSession2}
                                                    onChange={(e) => {
                                                        states["ScheduledSession2"] = e.target.value
                                                        this.setState({ states })
                                                    }}
                                                    style={{ width: "34.5vw" }}
                                                    input={<OutlinedInput label="Select Week *" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ emed_tid: "appointment_slotPopup_S2_selectWeek" }}
                                                >
                                                    {SchedulesDays.map((name) => (
                                                        <MenuItem key={name.value} value={name.label}>
                                                            <Checkbox checked={this.state.ScheduledSession2.indexOf(name.label) > -1} />
                                                            <ListItemText primary={name.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div></div> : null}
                            {this.state.EHRSession == 3 ? <div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                        SESSION 3
                                    </Typography>
                                    <IconButton style={{ marginLeft: "0.5vw" }} size='small' emed_tid="appointment_slotPopup_S3_deleteBtn">
                                        <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                            onClick={() => { this.setState({ deletedPopup: true }) }}
                                        />
                                    </IconButton>
                                </div>
                                <hr />
                                <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", justifyContent: "space-between" }}>
                                        <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                            {this.renderTimePicker("EHRStartTime3", this.state.EHRStartTime3, "Start Time *")}
                                        </div>
                                        <div style={{ marginRight: "0.5vw", marginTop: "0.25vw" }}>
                                            {this.renderTimePicker("EHREndTime3", this.state.EHREndTime3, "End Time *")}
                                        </div>
                                        <div style={{ marginTop: "0.25vw", marginRight: "0.5vw" }}>
                                            <TextField
                                                autoComplete="off"
                                                inputProps={{ maxLength: 3, emed_tid: "appointment_slotPopup_S3_intervalInput" }}
                                                label={"Enter Intervel in Minutes *"}
                                                size="small"
                                                value={this.state.IntervelTime}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    let isValid = CommonValidation.numberOnly(value)
                                                    if (value === "" || isValid && value <= 300) {
                                                        states["IntervelTime"] = value
                                                        this.setState({ states })
                                                    }
                                                }}
                                                style={{ width: "12vw" }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "1vw" }}>
                                        <FormControl>
                                            <InputLabel style={{ width: "" }}>Select Week *</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={this.state.ScheduledSession3}
                                                onChange={(e) => {
                                                    states["ScheduledSession3"] = e.target.value
                                                    this.setState({ states })
                                                }}
                                                style={{ width: "34.5vw" }}
                                                input={<OutlinedInput label="Select Week *" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                inputProps={{ emed_tid: "appointment_slotPopup_S3_selectWeek" }}
                                            >
                                                {SchedulesDays.map((name) => (
                                                    <MenuItem key={name.value} value={name.label}>
                                                        <Checkbox checked={this.state.ScheduledSession3.indexOf(name.label) > -1} />
                                                        <ListItemText primary={name.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div></div> : null}
                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <Button variant='contained' size='small' emed_tid="SlotConfig_Save_Btn" id="eMed_Config_btn" disabled={this.state.disableBtn} onClick={() => { this.setState({ disableBtn: true }, () => this.PostSlotConfiguration()) }}>Save</Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        )
    }

    OpenDoctoeSessionConfig = () => {
        let states = this.state
        return (
            <Box>
                <Modal open={true}>
                    <Box className="eMed_WebCamPop_Main" sx={{ width: "80vw", height: "85vh" }}>
                        <Box component={'div'} className="eMed_WebCam_Header">
                            <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                                Slot Configuration
                            </Typography>
                            <Button onClick={() => { this.setState({ SelectedDoctorDetails: {}, isEdit: false }) }} emed_tid="doctor_session_config_closeBtn" >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        <hr />
                        <Paper elevation={3} sx={{ flex: 0.8, marginY: "0.5vw" }}>
                            <Typography component={'div'} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <Tabs
                                    value={this.state.SelectedSessionTab}
                                    onChange={(e, newValue) => this.setState({
                                        SelectedSessionTab: newValue
                                    })}
                                >
                                    <Tab value="one" label={"Schedule"} emed_tid="doctor_session_config_schedule_tab" className='eMed_tab_header' />
                                    <Tab value="two" label={"Session"} emed_tid="doctor_session_config_session_tab" className='eMed_tab_header' />
                                </Tabs>
                                {this.state?.SelectedSessionTab === "one" ? <Button size='small' sx={{ marginRight: "1vw" }} emed_tid="doctor_session_config_outofoffice_btn"
                                    onClick={() => {
                                        this.GetSessionList()
                                        this.GetOFFHistory()
                                    }}
                                    variant='contained'>Out Of Office</Button> : null}
                            </Typography>
                            {this.state.SelectedSessionTab === "one" ? <DocScheduleConfig FromFO={true} SelectedDoctorDetails={this.state.SelectedDoctorDetails} DocScheduleGet={this.state.GetSchedule} renderScreenAgain={this.state.renderScreen} rendered={() => this.setState({ renderScreen: false })} /> : <DocSessionConfig FromFO={true} SelectedDoctorDetails={this.state.SelectedDoctorDetails} DocSlotType={this.state.SelectedDoctorDetails?.slot_based_on} />}
                        </Paper>
                    </Box>
                </Modal>
            </Box>
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, docDetails } = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.04, renderCell: (index) => index.api.getRowIndex(index.row.doctor_id) + 1 },
            {
                field: 'doctor_name', headerName: t('DoctorName'), flex: 0.11, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.doctor_name} arrow><div>{cellvalues?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip> : cellvalues?.row?.doctor_name}</div>
                    )
                }
            },
            {
                field: 'registration_proof_number', headerName: t('RegistrationNo'), flex: 0.11, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.registration_proof_number?.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.registration_proof_number} arrow><div></div></Tooltip> : cellvalues?.row?.registration_proof_number}</div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.05, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <Tooltip title="Edit" placement="top" arrow>
                                    <Button className="eMed_Edit_btn eMed_usrconf_btn" emed_tid="SLotConfig_Edit_Btn" disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Slot Configuration", "SubTab")?.editAccess} size='small'>
                                        <img src={CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Slot Configuration", "SubTab")?.editAccess ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} style={{ height: "1.5vw", width: "1.5vw" }} alt='Edit'
                                            onClick={() => {
                                                // this.handleEdit(row)
                                                this.setState({ SelectedDoctorDetails: row })
                                            }} />
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container">
                <Paper className="eMed_srvConfig_Div" >
                    <DataGrid
                        rows={docDetails}
                        columns={columns}
                        getRowId={(row) => row.doctor_id}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        headerHeight={40}
                        rowCount={docDetails.length}
                        page={current_page}
                        onPageChange={(newPage) => this.setState({ current_page: newPage })}
                        pageSize={rowsPerPage}
                        onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        loading={this.state.isLoader}
                    />
                </Paper>
                {this.state.EditSlotPopup ?
                    <div>
                        {this.OpenAppointmentSlotPopUP()}
                    </div> : null}
                {Object.keys(this.state.SelectedDoctorDetails ? this.state.SelectedDoctorDetails : {})?.length ? <div>{this.OpenDoctoeSessionConfig()}</div> : null}
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={t("Confirm Delete this Session")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                        disable={this.state.disableBtn}
                    />
                    : null}
                {this.OutofOfficePopup()}
                {this.state.DeletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to cancel?`}
                    deleteAlertPopupClose={this.PopupClose.bind(this)}
                    removeData={this.DeleteOFFHistory.bind(this)}
                    ButtonText={"Confirm"}
                    disable={this.state.disableBtn}
                /> : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(SlotConfig);