import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Fab, Grid, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import '../Reports/reports.css'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'

const settingsList = [
  {
    title: 'Lab Masters', icon: ImagePaths.opConfig.default, Access : CheckAccessFunc("Laboratory","Settings", "Lab Masters", null, "Tab"),
    reports: [
      { master_names: 'Department Master', path: '/SettingsHome/LabMaster/DepartmentMaster' },
      { master_names: 'Container Master', path: '/SettingsHome/LabMaster/ContainerMaster' },
      { master_names: 'Specimen Master',path:"/SettingsHome/LabMaster/SpecimenMster"},
      { master_names: 'Method Master',path:"/SettingsHome/LabMaster/MethodMaster"},
      { master_names: 'Antibiotic Master',path:"/SettingsHome/LabMaster/AntibioticMaster"},
      { master_names: 'Organism Master',path:"/SettingsHome/LabMaster/OrganismMaster"},
      { master_names: 'Organism Vs Antibiotic Name',path:"/SettingsHome/LabMaster/OrganismVsAntibioticGrp"},
      { master_names: 'Analyser Master',path:"/SettingsHome/LabMaster/AnalyserMaster"},
    ]
  },
  {
    title: 'Lab Configuration', icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", null, "Tab"),
    reports: [
      { master_names: 'Result Configuration', path: '/SettingsHome/ResultConfiguration' },
      { master_names: 'Package Configuration', path: '/SettingsHome/PackageConfiguration' },
      { master_names: 'Template Configuration', path: '/SettingsHome/TemplateConfiguration' },
      { master_names: 'Doctor Share Configuration', path: '/SettingsHome/DoctorConfiguration' },
      { master_names: 'Consumable Mapping', path: '/SettingsHome/ConsumableConfiguration' },
      { master_names: 'Patient Demographics', path: '/SettingsHome/PatientDemographics' },
      { master_names: 'Reffered Doctor', path: '/SettingsHome/ReferredDoctor' },
      { master_names: 'Outsource Configuration', path: '/SettingsHome/OutsourceConfiguration' },
      { master_names: 'Outsource Mapping', path: '/SettingsHome/OutsourceMapping' },
      { master_names: 'Lab E-Signature Configuration', path: '/SettingsHome/SignatureConfiguration' },
    ]
  }
]

class SettingsHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newReport: "",
      isOPT: false
    }
  }

  componentDidMount = () => {
    var loginInfo = JSON.parse(localGetItem("loggedInUserInfo"))
    this.setState({
      isOPT : loginInfo?.is_opt
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  settingsList = (item) => {
    return (
      <>
        <Box component={'div'} className='eMed_rts_card'>
          <Paper className='eMed_rts_content'>
            <Grid container className='eMed_rts_content_top'>
              <Grid item xs={12} className='eMed_rts_txt_one'>
                <Stack display={'flex'} flexDirection='row'>
                  <img src={item.icon} alt="" className='eMed_rts_icon' />
                  <Typography fontWeight={'600'}>{item.title}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} className='eMed_rts_content_btm'>
                {item?.reports?.map((list, index) => {
                    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Typography display={index === 0 ? "none" : 'inline'} color={Colors.themeDark}>|</Typography>
                      <Button key={index} className='eMed_reports_text_btn'
                        onClick={() => {
                          this.props.history.push({ pathname: list.path })
                        }}>{list.master_names}</Button>
                    </Box>
                })}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    return (
      <>
        <Box component={'div'} className='eMed_rts_home_container' overflow={'scroll'}>
          <Box>
            {settingsList.map((item, index) => {
              console.log(CheckAccessFunc("Laboratory", "Reports", item.title, null, "Tab"));
              
              if (CheckAccessFunc("Laboratory", "Reports", item.title, null, "Tab").viewAccess) {
                return (<Stack key={index}>{this.settingsList(item)}</Stack>)
              }
            })}
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
        </Box>
      </>
    )
  }
}

export default withTranslation()(SettingsHome)