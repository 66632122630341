import React, { Component } from 'react';
import { Box, Button, Drawer, Stack } from '@mui/material';
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class ReturnTaxWise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      returnTaxWiseData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "days":91
      },
      pageName: 'ReturnTaxWise',
      filterOpen: false,
      taxableAmount: 0,
      cgst: 0,
      sgst: 0,
      gst: 0,
      discountAmount: 0,
      netAmount: 0,
      isLoader: false,
    }
  }

  componentDidMount() {
    this.getReturnTaxWiseData()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => { this.getReturnTaxWiseData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  getReturnTaxWiseData = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.PHARMA_RETURN_TAX_WISE_SALES}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            let Data = response?.data;
            this.setState({
              returnTaxWiseData: Data?.data?.length > 0 ? Data?.data : [],
              taxableAmount: Data?.total_taxable_amount ? Data?.total_taxable_amount : 0,
              rowCount: Data?.total_count,
              netAmount: Data?.total_amount ? Data?.total_amount : 0,
              cgst: Data?.total_cgst_amount ? Data?.total_cgst_amount : 0,
              sgst: Data?.total_sgst_amount ? Data?.total_sgst_amount : 0,
              gst: Data?.total_gst_amount ? Data?.total_gst_amount : 0,
              discountAmount: Data?.total_discount_amount ? Data?.total_discount_amount : 0,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getReturnTaxWiseExcel = () => {
    try {
      RestAPIService.excelGet(`${Serviceurls.PHARMA_RETURN_TAX_WISE_SALES}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&export=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message);
    }
  }

  getRtnTaxWisePrint = () => {
    try {
      this.setState({disableBtn: true})
      RestAPIService.getAll(`${Serviceurls.PHARMA_RETURN_TAX_WISE_SALES}?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&export_type=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  render() {
    const { t } = this.props;
    this.state.returnTaxWiseData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
      {
        field: 'gst_percentage', headerName: "Gst %", flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.gst_percentage ? row?.gst_percentage : '0'}</Box>)
      },
      {
        field: 'taxable_amount', headerName: `${"Taxable Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.taxable_amount ? AmountFormat(row?.taxable_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: 'net_total_amount', headerName: `${"Total Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.net_total_amount ? AmountFormat(row?.net_total_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: 'sgst_percentage', headerName: "SGST %", flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_percentage ? row?.sgst_percentage : "0"}</Box>)
      },
      {
        field: 'sgst_amount', headerName: `${"SGST Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_amount ? AmountFormat(row?.sgst_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: 'cgst_percentage', headerName: "CGST %", flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_percentage ? row?.cgst_percentage : "0"}</Box>)
      },
      {
        field: 'cgst_amount', headerName: `${"CGST Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
        renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_amount ? AmountFormat(row?.cgst_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      }
    ]

    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Taxable Amount", this.state.taxableAmount, false, "white", false, null, 'rpt_hsncoderate_tax_amt')}
            {AmountsCard("CGST", this.state.cgst, false, "white", false, null, 'rpt_saleDlt_cgst')}
            {AmountsCard("SGST", this.state.sgst, false, "white", false, null, 'rpt_saleDlt_sgst')}
            {AmountsCard("GST Amount", this.state.gst, false, "white", false, null, 'rpt_saleRtn_rtn_gst_amt')}
            {AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, null, 'rpt_saleDlt_dis_amt')}
            {AmountsCard("Total Amount", this.state.netAmount, false, "white", false, null, 'rpt_hsncoderate_total_amt')}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid="rpt_hsncoderate_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid="rpt_hsncoderate_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getRtnTaxWisePrint()}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/* <Button emed_tid="rpt_hsncoderate_export" className='eMed_Pharma_rts_btn' onClick={() => this.getReturnTaxWiseExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.returnTaxWiseData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowCount={this.state.rowCount}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(ReturnTaxWise)