import React, { Component } from 'react'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button,  Stack, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
  import ShowComponents from '../Common/ShowComponent';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import DescriptionIcon from '@mui/icons-material/Description';

class OtherDocComplaintsLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            complainsData: [],
            isDeleteCliked: false,
            SelectedList: {},
            PatientData: this.props?.PatientData,
            EditItem: {},
            updateId: "",
            isEdit: false,
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.getComplaintsData() }
        if (prevprops.SelectedComponent !== this.props.SelectedComponent && this.props.SelectedComponent) {
            this.setState({ EditItem: {} })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    getComplaintsData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_GET + `?appointment_id=${this.props.PatientData.appointment_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            complainsData: response?.data?.data ? response?.data?.data : [],
                            loading: false,
                            isEdit: false,

                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }


    onEditHandler(data) {
        this.setState({
            updateId: data?.id,
            EditItem: data,
            isEdit: true
        }, () => {
            this.props.EditComplaintData(this.state.EditItem)
        })
    }
    onDeleteHandler(data) {
        try {
            this.setState({
                isDeleteCliked: true,
                SelectedList: data,
                updateId: data?.id
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose() {
        this.setState({ isDeleteCliked: false, SelectedList: {} })
    }
    DeleteComplaintsData = () => {
        try {
            let data = {}
            this.setState({ removeData: true })
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_DELETE + `?id=${this.state.updateId}`, data)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getComplaintsData()
                        this.setState({ isDeleteCliked: false, deletePopup: false, removeData: false, updateId: "" })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        this.state.complainsData?.forEach((element, index) => { element.sno = index + 1 })
        const columns = [
            {
                field: "complaint_description", flex: 0.333, headerName: t("Complaint"),
                renderCell: (params) => {
                    return (<Box component={'div'}>
                        {params?.row?.complaint_description ? params?.row?.complaint_description?.length > 25 ?
                            <Tooltip placement='top' title={params?.row?.complaint_description} arrow>
                                <div>{params?.row?.complaint_description.slice(0, 25) + "..."}</div></Tooltip> :
                            params?.row?.complaint_description : "-"}
                    </Box>)
                }
            },
            {
                field: "duration_type", flex: 0.333, headerName: t("Duration"), headerAlign: "center", align: 'center',
                renderCell: ({ row }) => {
                    return (<Box>
                        {row?.duration_time ?
                            row?.duration_time?.length > 10 ?
                                <Tooltip placement='top' title={row?.duration_time}>
                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{row?.duration_time?.slice(0, 10) + '...'}</Typography>
                                </Tooltip> :
                                <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{row?.duration_time + " " + (row?.duration_type == 1 ? "Days" : row?.duration_type == 2 ? "Weeks" : row?.duration_type == 3 ? "Months" : "Years")}</Typography> : '-'
                        }
                    </Box>)
                }
            },
            {
                field: "action", flex: 0.333, headerName: t("Action"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    return (<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Button className='Common_Btn_Min_Width'
                            disabled={this.state.isLocked || this.props.isLocked}
                            onClick={() => { this.onEditHandler(params.row) }}
                        >
                            <Tooltip placement='top' title={'Edit'}>
                                {this.state.isLocked || this.props.isLocked ?
                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                    :
                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                }
                            </Tooltip>
                        </Button>
                        <Button className='Common_Btn_Min_Width' disabled={this.state.isLocked || this.props.isLocked}
                            onClick={() => this.onDeleteHandler(params.row)}
                        >
                            {this.state.isLocked || this.props.isLocked || this.state.isEdit ?
                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                :
                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                            }
                        </Button>
                        <Button>
                            {params.row?.comments ? <Tooltip placement='top' title={params.row.comments}><DescriptionIcon color='primary' /></Tooltip> : null}
                        </Button>
                    </Box>)
                }
            }
        ]
        return (
            <ShowComponents onIntersection={this.getComplaintsData.bind(this)}>
                <Box height={'20vh'} overflow={'auto'}>
                    <DataGrid
                        density='compact'
                        rows={this.state.complainsData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        headerHeight={40}
                        hideFooter={true}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack className='eMed_DocNotes_no_records_text' height={'15vh'}>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        disableSelectionOnClick
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${t("Complaints")} ${this.state.SelectedList ? this.state.SelectedList?.complaint_description : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DeleteComplaintsData.bind(this)}
                /> : null}
            </ShowComponents>
        )
    }
}

export default withTranslation()(OtherDocComplaintsLeft)

