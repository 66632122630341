import {
    Box, Button, Divider, IconButton, Modal, Paper, Stack,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Tooltip, Typography, Checkbox
} from "@mui/material"
import { ImagePaths } from "../../Utility/ImagePaths"
import React,{ Component } from "react"
import RestAPIService from "../../Utility/Services/RestAPIService"
import { Serviceurls } from "../../Utility/API/Serviceurls"
import { localGetItem } from "../../Utility/Services/CacheProviderService"
import ToastMsg from "../ToastMsg/ToastMsg"
import { DateTime } from "luxon"
import CommonValidation from "../CommonFunctions/CommonValidation"
import Loader from '../../Components/Loader';
import { ReasonPopup } from '../../Components/Common Components/CommonComponents';
import { AmountFormat } from "../CommonFunctions/CommonFunctions";
import { CurrencySymbol } from "../../Utility/Constants";

export class CommonStockTransferPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            pharmacyId: null,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText:'',
            isBtnClick: false,
            Cancel_cmt: '',
            is_decline: false,
            isDeclineClked: false,
            declineItem: {},
            is_Success: false,
            isAllChecked: false,
            isAnyChecked: false,
            is_approve: false,
        }
    }

    componentDidMount(){
        if (this.props.RowId) {
            let subLocationDetails = localGetItem("loggedInUserInfo")
            let detail = JSON.parse(subLocationDetails)
            this.setState({
                pharmacyId: detail?.pharmacy_id,
            }, () => {
                this.getProductData(this.props.RowId)
            })
        }

    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isBtnClick: false,
            is_Success: false,
            isAllChecked: false,
            isAnyChecked: false,
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            is_Success: false,
            isAllChecked: false,
            isAnyChecked: false,
            isBtnClick: false,
        })
    }

    getProductData = (ID) => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.SUBLOC_LINE_OF_ITEM + "?request_id=" + ID + "&pharmacy_id=" + this.state.pharmacyId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        var Data = response?.data?.data ? response.data.data : []
                        this.props.title === "Approve" && Data?.forEach(item => {
                            item.approved_quantity = item?.request_quantity || 0;
                        })
                        this.setState({
                            tableData: Data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    inputChange = (event, Index, data) => {
        var name = event.target.name
        var value = event.target.value
        let isNum = CommonValidation.numberOnly(value)
        var {tableData} = this.state

        if(((name === "reorder_threshold")) && (isNum || value === "")){
            tableData[Index][name] = value
            tableData[Index]['batch_detail'].every(item => item[name] = value)
        }else if(name === "physical_box_no"){
            tableData[Index][name] = value
            tableData[Index]['batch_detail'].every(item => item[name] = value)
        }else if((name === 'approved_quantity') && (isNum || value === '') && (+value <= +data.request_quantity)){
            tableData[Index][name] = value
            if (value === 0 || value === "0" || !value) {
                tableData[Index]["isError"] = true;
            } else {
                tableData[Index]["isError"] = false;
            }
        }

        this.setState({
            tableData
        })
    }

    postSecondBtn = () => {
        try {
            if (this.props.title === "Inbound Details") {
                const { Data, title } = this.props
                var states = this.state
                var valid = states.tableData.filter(ele => !ele.is_delete && ele.approved_quantity > 0 && ele.transfer_quantity !== 0)
                .every((item) => item.batch_detail[0]?.physical_box_no && item.batch_detail[0]?.reorder_threshold)
                if (valid) {
                    let finalDrugList = []
                    states.tableData.forEach((list, index) => {
                        finalDrugList.push({
                            'drug_ms_id': list.drug_ms_id,
                            'request_quantity': list.request_quantity,
                            'received_quantity': list.transfer_quantity,
                            'approved_quantity': list.approved_quantity,
                            'transfer_quantity': list.transfer_quantity,
                            'batch_detail': list.batch_detail,
                            'is_delete': list.is_delete,
                            'cancel_reason': list.cancel_reason,
                            'command': list.command ? list.command : '',
                            'id': list.id
                        })
                    })
                    var transDate = new Date()
                    var data = {
                        'main_pharmacy_id': states.tableData[0].main_pharmacy_id,
                        'req_to_location': Data.req_to_location_id,
                        'transit_number': Data.transit_number,
                        'transit_name': Data.transit_name,
                        'transfer_date': DateTime.fromJSDate(transDate).toFormat('yyyy-MM-dd'),
                        'transfer_type': Data.transfer_type,
                        'line_item': finalDrugList,
                        'req_from_location': Data.req_from_location_id,
                        'inbound_id': Data.id,
                        'status': 'Received',
                        'cancel_reason': '',
                        'is_cancel': false,
                        'transfer_from': Data.transfer_from,
                        'transfer_to': Data.transfer_to,
                    }
                    this.LoaderFunction(true)
                    this.setState({
                        isBtnClick: true
                    })
                    RestAPIService.create(data, Serviceurls.SUBLOC_STOCK_TRANSFER)
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.LoaderFunction(false)
                                this.successMessage(response.data.message)
                                setTimeout(() => {
                                    this.props.handlePopUpClose()
                                }, 1000);
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.message) {
                                this.errorMessage(error?.response?.data?.message)
                            } else {
                                this.errorMessage(error.message)
                            }
                            this.LoaderFunction(false)
                            this.setState({
                                isBtnClick: false
                            })
                        })
                } else {
                    this.LoaderFunction(false)
                    this.errorMessage("Please Enter the Physical Box No and Reorder Thershold")
                }
            }else{
                this.postApprove()
            }
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }
    postApprove = () => {
        try {
            let states = this.state
            let finalDrugList = []
            const { Data } = this.props
            states.tableData?.forEach((element, index) => {
                finalDrugList.push({
                    "drug_ms_id": element.drug_ms_id,
                    "request_quantity": +element.request_quantity,
                    "received_quantity": 0,
                    "approved_quantity": +element.approved_quantity,
                    'transfer_quantity': +element.approved_quantity,
                    'batch_detail': element.batch_detail,
                    'is_delete': element.is_delete,
                    'cancel_reason': element.cancel_reason,
                    'command': ''
                })
                if(element?.id){
                    finalDrugList[index].id = element?.id ?  element?.id : null
                }
            })
            var transDate = new Date()
            let data = {
                'main_pharmacy_id': states.tableData[0].main_pharmacy_id,
                'req_to_location': Data.req_to_location_id,
                'transit_number': '',
                'transit_name': '',
                'transfer_type': Data.transfer_type,
                'line_item': finalDrugList,
                'req_from_location': Data.req_from_location_id,
                'inbound_id': Data.id,
                'status': 'In Transit',
                'cancel_reason': '',
                'is_cancel': false,
                'transfer_from' :  Data.transfer_from,
                'transfer_to': Data.transfer_to,
                'transfer_date': DateTime.fromJSDate(transDate).toFormat('yyyy-MM-dd')
            }
            this.LoaderFunction(true)
            this.setState({
                isBtnClick: true
            })
            RestAPIService.create(data, Serviceurls.SUBLOC_STOCK_TRANSFER)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        setTimeout(() => {
                            this.props.handlePopUpClose()
                        }, 1000);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {

                        this.errorMessage(error.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.LoaderFunction(false)
                    this.setState({
                        isBtnClick: false
                    })
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    cancelRequest = (status) => {
        try {
            const { Cancel_cmt, tableData } = this.state;
            if ((Cancel_cmt.trim()) != '' || status) {
                // cancel for all
                const data = {
                    "status_data": "Cancelled",
                    "request_id": this.props.RowId,
                    "cancel_reason": Cancel_cmt.replace(/\s+/g, ' ').trim(),
                    "is_cancel": true,
                }
                if (status === "Approved" && (this.state.isAllChecked || this.state.isAnyChecked)) {
                    data.status_data = "Approved";
                    data.is_cancel= undefined;
                    data.cancel_reason = undefined;
                    data["line_items"] = [...tableData]?.map(item => {
                        const updatedItem = { ...item };
                        if (!updatedItem.is_approved) {
                            updatedItem.approved_quantity = 0;
                        }
                        return updatedItem;
                    });
                    data["approved_reason"] = Cancel_cmt.replace(/\s+/g, ' ').trim();
                }
                this.setState({
                    isBtnClick: true
                })
                RestAPIService.create(data, Serviceurls.SUBLOC_STATUS_CHANGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                            setTimeout(() => {
                                this.props.handlePopUpClose()
                            }, 1000);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Please Enter the Comments")
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    popupClose = () => {
        this.setState({
            is_decline: false,
            is_approve: false,
        })
    }
    addCancelComments = (comments) => {
        this.setState({
            Cancel_cmt: comments
        }, () => {
            this.cancelRequest(this.state.is_approve ? "Approved" : "");
        })
    }

    popupDecClose = () => {
        this.setState({
            isDeclineClked: false,
            declineItem: {}
        })
    }

    cancelLineItem = (comments) => {
        try {
            let { tableData, declineItem } = this.state;
            if ((comments.trim()) != '') {
                const updatedTableData = tableData?.map(item => {
                    if (item.id === declineItem?.id) {
                        return {
                            ...item,
                            is_delete: true,
                            cancel_reason: comments
                        };
                    }
                    return item;
                });
                const data = {
                    "request_id": this.props.RowId,
                    "line_items": updatedTableData
                }
                this.setState({
                    isBtnClick: true,
                })
                RestAPIService.create(data, Serviceurls.SUBLOC_STATUS_CHANGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.popupDecClose();
                            this.getProductData(this.props.RowId)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Please Enter the Comments")
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    handleDeclined = (data) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isDeclineClked: true,
                declineItem: data,
            }
        })
    }

    handleCheckboxChange = (index, event) => {
        const { checked } = event.target;
        this.setState((prevState) => {
            const list = [...prevState.tableData];
            list[index]["is_approved"] = checked;
            return {
                ...prevState,
                tableData: list,
                isAnyChecked: list?.some((row) => row.is_approved && !row.is_delete),
                isAllChecked: list?.filter((row) => !row.is_delete)?.every((row) => row.is_approved)
            }
        })
    };

    handleSelectAllChange = (event) => {
        const { checked } = event.target;
        this.setState((prevState) => {
            const list = [...prevState.tableData]?.map((row) => {
                if (!row.is_delete) {
                    return { ...row, is_approved: checked };
                }
                return row;
            });
            return {
                ...prevState,
                tableData: list,
                isAllChecked: checked,
                isAnyChecked: checked,
            }
        })
    };

    render() {
        const { Data, title , isCancel} = this.props
        const {tableData} = this.state;
        return(
            <Box>
                <Modal open={true} sx= {{outline: "none"}}>
                    <Paper elevation={3} id="emedhub_vendorDetails_mainBox" sx={{ width: "95vw", maxHeight:"60vw" }}>
                        <div id="emedhub_vendorDetails_header">
                            <Typography>{title}</Typography>
                            <IconButton size="small" onClick={() => { this.props.handlePopUpClose() }}><img className="emedhub_popupIcon_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        </div>
                        <Box className="emedhub_confirmOrder_mainDiv">
                            <Paper id="emedhub_confirmOrder_subDiv1" elevation={0}>
                                <div className="emedhub_confirmOrder_Div">
                                    <Typography sx={{ fontSize: "0.9vw" }}>{"Transfer From"}</Typography>
                                    <Typography id="emedhub_directpoTable_cell1">{Data.transfer_from ? Data.transfer_from : "-"}</Typography>
                                </div>
                                <div className="emedhub_confirmOrder_Div">
                                    <Typography sx={{ fontSize: "0.9vw" }}>{"Transfer To"}</Typography>
                                    <Typography id="emedhub_directpoTable_cell1">{Data.transfer_to ? Data.transfer_to : "-"}</Typography>
                                </div>
                                <div className="emedhub_confirmOrder_Div">
                                    <Typography sx={{ fontSize: "0.9vw" }}>{"Requested Date"}</Typography>
                                    <Typography id="emedhub_directpoTable_cell1">{Data.request_date ? Data.request_date : "-"}</Typography>
                                </div>
                                {title === 'Approve' || isCancel ? null :
                                    <div className="emedhub_confirmOrder_Div">
                                        <Typography sx={{ fontSize: "0.9vw" }}>{"Transfered Date"}</Typography>
                                        <Typography id="emedhub_directpoTable_cell1">{Data.transfer_date ? Data.transfer_date : "-"}</Typography>
                                    </div> }
                                <div className="emedhub_confirmOrder_Div">
                                    <Typography sx={{ fontSize: "0.9vw" }}>{"Request Number"}</Typography>
                                    <Typography id="emedhub_directpoTable_cell1">{Data.request_number ? Data.request_number : "-"}</Typography>
                                </div>
                                {title === "Transfer Details" ?
                                    <div className="emedhub_confirmOrder_Div">
                                        <Typography sx={{ fontSize: "0.9vw" }}>{"Total Cost"}</Typography>
                                        <Typography id="emedhub_directpoTable_cell1">{AmountFormat(Data.total_cost || 0)}</Typography>
                                    </div> : null}
                            </Paper>
                            <Divider />
                            <div id="emedhub_confirmOrder_subDiv2">
                                <TableContainer className='emedhub_subloc_stkTranfer_Table'>
                                    <Table stickyHeader size='small'>
                                        <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                                            <TableRow>
                                                {this.props.title === "Approve" ?
                                                    <TableCell padding="checkbox" id="emedhub_directpoTable_headTxt">
                                                        <Checkbox
                                                            disabled = {tableData?.every((item) => item.is_delete)}
                                                            checked={this.state.isAllChecked}
                                                            onChange={this.handleSelectAllChange}
                                                        />
                                                    </TableCell> : null}
                                                <TableCell sx={{ flex: 0.04 }} id="emedhub_directpoTable_headTxt">{"S.No"}</TableCell>
                                                <TableCell sx={{ flex: 0.12 }} id="emedhub_directpoTable_headTxt">{"Brand Name"}</TableCell>
                                                <TableCell sx={{ flex: 0.11 }} id="emedhub_directpoTable_headTxt">{"Generic Name"}</TableCell>
                                                <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Type/Strength"}</TableCell>
                                                { ((title === "Inbound Details" && !isCancel) || title === "Transfer Details" || title === "Return Details" || title === "Return Details") ?
                                                    <TableCell sx={{ flex: 0.2 }} id="emedhub_directpoTable_headTxt"> {"Batch/Expiry"}</TableCell>
                                                    :null }
                                                <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{((title === "Inbound Details" && !isCancel) || title === "Return Details") ? "Transfered Qty" : "Requested Qty"}</TableCell>
                                                {title === "Approve" ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Approved Qty"}</TableCell>
                                                    : null
                                                }
                                                { title === "Inbound Details" && !isCancel ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Phy Box No"}</TableCell>
                                                    : null}
                                                { title === "Inbound Details" && !isCancel ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Threshold"}</TableCell>
                                                    : null}
                                                {title === "Transfer" ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Batch Details"}</TableCell>
                                                    : null}
                                                {title === "Transfer Details" ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Received Qty"}</TableCell>
                                                    : null}
                                                {title === "Transfer Details" ?
                                                    <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{"Total Amount" + ` (${CurrencySymbol})`}</TableCell>
                                                    : null}
                                                {title === "Approve" ?
                                                    <TableCell sx={{ flex: 0.1, textAlign: "center" }} id="emedhub_directpoTable_headTxt">{"Action"}</TableCell>
                                                    : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="emedhub_directpoTable_head">
                                            {tableData?.length > 0 && tableData?.map((item, index) => {
                                                var batchNo = item.is_delete ? "Item Deleted" : item.batch_detail.map((item) => (<Box>{' ' + item.batch_no + ' / ' + item.expiry_date?.slice(0, 7) + ' / ' + item.quantity}<br/></Box> ))
                                                let sno = (index + 1);
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        title={(title === "Transfer Details" && item?.is_delete) ? item?.cancel_reason || "Item Declined" : ""}
                                                    >
                                                    <TableRow key={index} sx={{ bgcolor: (title === "Transfer Details" && item?.is_delete) ? "#eb9a9a" : ((title === "Inbound Details" || title === "Transfer Details") && item.transfer_quantity === 0) ? '#e2e2e2' : 'white'}}>
                                                        {title === "Approve" ? <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled = {item.is_delete}
                                                                checked={item.is_approved}
                                                                onChange={(event) => this.handleCheckboxChange(index, event)}
                                                            />
                                                        </TableCell> : null}
                                                        <TableCell sx={{ flex: 0.04 }}>{sno}</TableCell>
                                                        <TableCell sx={{ flex: 0.12 }}>{item.drug_name}</TableCell>
                                                        <TableCell sx={{ flex: 0.11 }}>{item.generic_name}</TableCell>
                                                        <TableCell sx={{ flex: 0.1 }}>{item.dosage_type + "/" + item.dosage_strength}</TableCell>
                                                        { ((title === "Inbound Details" && !isCancel) || title === "Transfer Details" || title === "Return Details") ?
                                                            <TableCell sx={{ flex: 0.2 }}>{batchNo}</TableCell>
                                                            : null }
                                                        <TableCell sx={{ flex: 0.1 }}>{((title === "Inbound Details" && !isCancel) || title === "Return Details")  ? item.transfer_quantity : title === "Transfer" ? item.approved_quantity : item.request_quantity}</TableCell>
                                                        {title === "Approve" ?
                                                            <TableCell sx={{ flex: 0.1, textAlign: "center" }}>
                                                                <Stack>
                                                                    <TextField
                                                                        name="approved_quantity"
                                                                        value={item.approved_quantity}
                                                                        error={item.isError}
                                                                        size='small'
                                                                        sx={{ width: "6vw" }}
                                                                        onChange={(e) => this.inputChange(e, index, item)}
                                                                    /></Stack>
                                                            </TableCell>
                                                            : null
                                                        }
                                                        { title === "Inbound Details" && !isCancel ?
                                                            <TableCell sx={{ flex: 0.1 }}>
                                                                <Stack>
                                                                    <TextField
                                                                        name="physical_box_no"
                                                                        value={item.physical_box_no}
                                                                        inputProps={{ maxLength: 10 }}
                                                                        disabled={(item.is_delete || item?.transfer_quantity === 0) ? true : false}
                                                                        size="small"
                                                                        sx={{ width: "6vw" }}
                                                                        onChange={(e) => this.inputChange(e, index)}
                                                                    /></Stack>
                                                            </TableCell>
                                                            : null }
                                                        { title === "Inbound Details" && !isCancel ?
                                                            <TableCell sx={{ flex: 0.1 }}>
                                                                <Stack>
                                                                    <TextField
                                                                        name="reorder_threshold"
                                                                        inputProps={{ maxLength: 6 }}
                                                                        disabled={(item.is_delete || item?.transfer_quantity === 0) ? true : false}
                                                                        value={item.reorder_threshold}
                                                                        size="small"
                                                                        sx={{ width: "6vw" }}
                                                                        onChange={(e) => this.inputChange(e, index)}
                                                                    /></Stack>
                                                            </TableCell>
                                                            : null }
                                                        { title === "Transfer" ?
                                                            <TableCell sx={{ flex: 0.2 }}>{batchNo}</TableCell>
                                                            : null }
                                                        {title === "Transfer Details" ?
                                                            <TableCell sx={{ flex: 0.2 }}>{item.received_quantity}</TableCell>
                                                            : null}
                                                        {title === "Transfer Details" ?
                                                            <TableCell sx={{ flex: 0.1 }}>{AmountFormat(item.total_cost || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                                            : null}
                                                        {title === "Approve" ?
                                                            <TableCell sx={{ flex: 0.1, textAlign: "center" }}>{
                                                                item?.is_delete ?
                                                                    <Tooltip arrow title={item?.cancel_reason}>
                                                                        <Typography fontSize={"0.95dvw"} fontWeight={700} color={"red"}>Declined</Typography>
                                                                    </Tooltip>
                                                                    : <IconButton onClick={() => this.handleDeclined(item)} disabled={this.state.isAllChecked || this.state.isAnyChecked}>
                                                                        <img className="emedhub_popupIcon_img" src={(this.state.isAllChecked || this.state.isAnyChecked) ? ImagePaths.DeclineIconOff.default : ImagePaths.DeclineIcon.default} alt='decline' />
                                                                    </IconButton>
                                                            }
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                    </Tooltip>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Box>
                        {isCancel ?
                            <Box>
                                <TextField
                                    fullWidth
                                    onChange={(e)=>{
                                        this.setState({
                                            Cancel_cmt: e.target.value
                                        })
                                    }}
                                    size="small"
                                    id="inbound-cancel-text"
                                    label="Comments"
                                    multiline
                                    rows={2}
                                />
                            </Box> : null}
                        <div id="emedhub_vendorDetails_footer" >
                            {(title != "Transfer Details" && title != "Return Details") ?
                                <Stack direction="row" spacing={2} sx={{width: '100%', justifyContent: 'flex-end'}}>
                                    {this.props.title === "Approve" ?
                                        <Button size="small" sx={{ textTransform: 'capitalize' }}
                                            color={'error'}
                                            disabled={this.state.isBtnClick || this.state.isAllChecked || this.state.isAnyChecked}
                                            variant={"outlined"}
                                            onClick={() => {
                                                this.setState({ is_decline: true })
                                            }}
                                        >
                                            {"Declined"}</Button> : null}
                                    {this.props.title === "Approve" ?
                                        <Button size="small" sx={{ textTransform: 'capitalize' }}
                                            color={'primary'}
                                            disabled={this.state.isBtnClick || (!this.state.isAnyChecked && !this.state.isAllChecked)}
                                            variant={"outlined"}
                                            onClick={() => {
                                                let errorFound = false;
                                                for (let item of this.state.tableData) {
                                                    if (item?.is_approved) {
                                                        if (!item?.approved_quantity || item.approved_quantity === 0 || item.approved_quantity === "0") {
                                                            item.isError = true;
                                                            errorFound = true;
                                                            this.setState({ tableData });
                                                            return;
                                                        }
                                                    }
                                                }
                                                if (!errorFound) {
                                                    this.setState({ is_approve: true })
                                                }
                                            }}
                                        >
                                            {"Approve"}</Button> : null}                                    
                                    {this.props.title === "Approve" ? null :
                                        <Button size="small" sx={{ textTransform: 'capitalize' }}
                                            color={isCancel ? 'error' : 'primary'}
                                            disabled={this.state.isBtnClick}
                                            variant={isCancel ? "outlined" : "contained"}
                                            onClick={() => {
                                                (isCancel) ? this.cancelRequest() : this.postSecondBtn()
                                            }}>
                                            {isCancel ? "Cancel Request" :
                                                title === "Inbound Details" ?
                                                    "Complete Request" : ""}</Button>}
                                </Stack>
                                :
                                // title === "Return Details" ? 
                                // <Stack direction="row" spacing={2} sx={{width: '100%', justifyContent: 'flex-end'}}>
                                // <Button size="small" color={"error"} variant={"outlined"} onClick={() => {this.cancelRequest() }}>{"CANCEL REQUEST"}</Button>
                                // {/* <Button size="small" variant="contained" onClick={() => { }}>{title === "Inbound Details" ? "COMPLETE REQUEST" : "APPROVE REQUEST"}</Button> */}
                                // </Stack>
                                // : 
                                null
                            }

                        </div>
                    </Paper>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {(this.state.is_decline || this.state.is_approve) ? <ReasonPopup
                    title={`Are you sure? Do you want to ${this.state.is_approve ? "Approve" : "Decline"} the Request`}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={`Enter the ${this.state.is_approve ? "Approve" : "Cancel"} Reason`}
                    defaultComment = {this.state.is_approve ? "For Stock" : ""}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={this.state.is_approve ? 'primary' : 'error'}
                    btntext1={'Confirm'}
                    btntextDis={this.state.is_approve ? false : this.state.is_Success}
                    btnvarient1={'contained'}
                    sendCmt={this.addCancelComments.bind(this)}
                    isCmtMandatory={!this.state.is_approve}
                /> : null}

                {this.state.isDeclineClked ? <ReasonPopup
                    title={"Are you sure? Do you want to Decline the Request"}
                    AlertPopupClose={this.popupDecClose.bind(this)}
                    label={"Enter the Cancel Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btntextDis={this.state.is_Success}
                    btnvarient1={'contained'}
                    sendCmt={this.cancelLineItem.bind(this)} /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}