import { Box, Button, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadRpts.css'
import { Colors } from '../../../Styles/Colors'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { CurrencySymbol } from '../../../Utility/Constants'
import { DateTime } from 'luxon'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'

export default class RadPatientReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientReportData: [],
      totalBillAmount: 0,
      totalTests: 0,
      page: 0,
      pageSize: 10,
      loading: false,
      timeError: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      FilterOpen: false
    }
  }

  componentDidMount() {
    this.getPatientReportData()
  }

  getPatientReportData = () => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              patientReportData: response.data.data,
              totalBillAmount: response.data.total_amount,
              totalTests: response.data.total_test
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPatientReportPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_APPOINTMENT_LIST + `?export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  closeFilter = () => {
    this.setState({
      FilterOpen: false
    })
  }
  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{'Filter'}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              this.setState({
                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: 1,
              },
                () => this.getPatientReportData()
              )
            }}
            id="eMed_Config_btn">{"Clear"}</Button>
          <Button variant='contained' size="small"
            onClick={() => {
              this.setState({ FilterOpen: false }, () => this.getPatientReportData())
            }}
            id="eMed_Config_btn">{"Search"}</Button>
        </Stack>
      </Box>
    )
  }

  render() {
    this.state.patientReportData?.forEach((element, index) => element.sno = index + 1)
    const column = [
      { field: 'sno', headerName: 'S.No', flex: 0.05 },
      {
        field: 'invoice_date', headerName: 'Date And Time', flex: 0.143, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'} emed_tid={'rad_rpts_date_pat'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>)
      },
      {
        field: 'name', headerName: 'Patient Details', flex: 0.236,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pat_name_canc'>
          <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.name ?
            params?.row?.name?.length > 15 ?
              <Tooltip placement='top' title={params?.row?.name + "" + params?.row?.patient_age_str + '/' + params?.row?.gender[0]}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.name?.slice(0, 15) + '...' + params?.row?.patient_age_str + '/' + params?.row?.gender[0]}</div></Tooltip> :
              (params?.row?.name + "" + params?.row?.patient_age_str + '/' + params?.row?.gender[0]) : '-'}</Typography>
          <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_account_number ? params?.row?.patient_account_number : '-'}</Typography>
        </Box>)
      },
      {
        field: 'service_name', headerName: 'Service Name', flex: 0.143,
        renderCell: (params) => (<Box component={'div'} emed_tid={'rad_rpts_ser_name_pat'}>{params?.row?.service_name ?
          params?.row?.service_name?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.service_name}><Typography>{params?.row?.service_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.service_name}</Typography> : '-'}</Box>)
      },
      {
        field: 'service_type', headerName: 'Service type', flex: 0.143,
        renderCell: (params) => (<Box component={'div'} emed_tid={'rad_rpts_ser_type_pat'}>{params?.row?.service_type ?
          params?.row?.service_type?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.service_type}><Typography>{params?.row?.service_type?.slice(0, 15) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.service_type}</Typography> : '-'}</Box>)
      },
      {
        field: 'amount', headerName: `Amount (${CurrencySymbol})`, flex: 0.143, type: 'number',
        renderCell: (params) => (<Box component={'div'} emed_tid={'rad_rpts_amt_pat'}>{AmountFormat(params?.row?.amount ? params?.row?.amount : 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      // {
      //   field: 'action', headerName: 'Action', flex: 0.143, headerAlign: 'center', align: 'center',
      //   renderCell: (params) => (<Box component={'div'} emed_tid={'rad_rpts_act_pat'}>
      //     <Tooltip placement='top' title={'Print'} arrow>
      //       <Button className='Common_Btn_Min_Width'>
      //         <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
      //       </Button>
      //     </Tooltip>
      //   </Box>)
      // },
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='rad_Rpts_container' sx={{ backgroundColor: Colors.Background }}>
          <Box component={'div'} className='rad_rpts_header_card'>
            <Box component={'div'} className='rad_rpts_cards'>
              {AmountsCard("Total Test", this.state.totalTests, true, "white", false, null, "rad_pat_totl_tst")}
              {AmountsCard("Total Amount", this.state.totalBillAmount, false, "white", false, null, 'rad_pat_amt')}
            </Box>
            <Box component={'div'} className='rad_rpts_btns'>
              <Tooltip placement='top' title={'Filter'} arrow>
                <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_flt_pat'
                  onClick={() => this.setState({ FilterOpen: true })}
                >
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement='top' title={'Print'} arrow>
                <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_prt_pat'
                  disabled={this.state.disableBtn}
                  onClick={() => this.getPatientReportPrint()}
                >
                  <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
              {/* <Tooltip placement='top' title={'Export'} arrow>
                <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_exp_pat'>
                  <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
          <Box component={'div'} className='rad_rpts_content_card'>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={column}
              rows={this.state.patientReportData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.loading}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
      </Box>
    )
  }
}
