import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Autocomplete, Modal, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../../../Pharmacy/PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let statuslist = ["Active","InActive"]
let RoleData = null;
let userAccess = null;

class AnalyserMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      AnalyserData: [],
      SelectAnalyser: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      Status_type: "",
      openPopUp: false,
      analyserCode: null,
      analyserName: "",
      is_active: true,
      isFromEdit: false,
      inactive_reason: "",
      id: null,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Laboratory", "Settings", "Lab Masters", "Analyser Master", "SubTab");
    this.AnalyserList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  AnalyserList() {
    try {
      this.setState({ postLoad: true })
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.LAB_ANALYSER_MASTER + "?analyser_name=" + (this.state.SelectAnalyser) + "&is_active=" + (this.state.Status_type === "Active" ? true : this.state.Status_type === "InActive" ? false : "") + "&page=" + (this.state.page + 1) + "&page_size=" + this.state.pageSize).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.setState({
              AnalyserData: response?.data?.data,
              rowCount: response?.data?.total_count
            })
          }
          else {
            this.setState({ postLoad: false })
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({ postLoad: false })
          this.LoaderFunction(false);
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
  handleClear = () => {
    this.setState({
        analyserName: "",
        is_active: true,
        inactive_reason: "",
    })
  }

  validation = () => {
    if(this.state.analyserName === ""){
        this.errorMessage("Enter Anlyser name")
    }
    else if(!this.state.is_active && this.state.inactive_reason === ""){
        this.errorMessage("Enter inactive reason")
    }
    else{
        this.SaveAnalyseData()
    }
  }

  SaveAnalyseData = () => {
    try {
      let data = {
        id: this.state.id,
        is_active: this.state.is_active,
        analyser_name: this.state.analyserName ? CommonValidation?.removeSpace(this.state.analyserName) :"",
        inactive_reason: this.state.inactive_reason ? CommonValidation?.removeSpace(this.state.inactive_reason) :"",
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.LAB_ANALYSER_MASTER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({ postLoad: false })
            this.successMessage(response.data.message)
            this.handleClear()
            this.AnalyserList()
            this.setState({
                openPopUp: false,
                isFromEdit: false,
                analyserCode: null,
                id: null,
            })
          }
        }).catch((error) => {
          this.setState({ postLoad: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid="analyse_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("Select Analyser")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              value={states.SelectAnalyser ? states.SelectAnalyser : ""}
              inputProps={{ emed_tid: "analyse_name" , maxLength : 100}}
              onChange={(e) => {
                this.setState({
                  SelectAnalyser: e.target.value.trimStart()
                })
              }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              disableClearable
              options={statuslist}
              getOptionLabel={(option) => (option)}
              value={this.state.Status_type}
              onChange={(event, newValue) => {
                this.setState({ Status_type: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, 'emed_tid': 'analy_status_id', }}
                  label={t('Select Status')}
                  autoComplete='off'
                />
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid="A_fil_clear"
            onClick={() => {
              this.setState({
                SelectAnalyser: "",
                Status_type: "",
              }, () => this.AnalyserList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid="A_fil_search"
            disabled ={this.state.postLoad }
            onClick={() => {          
              this.AnalyserList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  handleEdit = (data) => {
    this.setState({
      isFromEdit: true,
      openPopUp: true,
      analyserCode: data.analyser_code,
      analyserName: data.analyser_name,
      id: data.id,
      inactive_reason: data.inactive_reason,
      is_active: data.is_active,
    })

  }
  renderTextField = (value, statekey, Label, required, width, disable = false) => {
    const { t } = this.props;
    let states = this.state
    return (
      <TextField
        sx={{ width: width ? width : "12vw", marginX: '0.65vw', marginTop: "1vw" }}
        size="small"
        disabled={disable}
        inputProps={{ maxLength: 100 }}
        autoComplete="off"
        label={t(Label)}
        required={required}
        value={value}
        onChange={(e) => {
          let value = e.target.value
            states[statekey] = value.trimStart()
            this.setState({ states })
        }}
      />
    )
  }
  addEditAnalyser = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.openPopUp}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "42vw", height: "63vh", maxHeight: "100%",padding: "1vw", }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} padding={".5vw"} fontWeight={600} color={Colors.SecondaryText}>{states.isFromEdit ? "Edit Analyser" : "Add New Analyser" }</Typography>
              <Button className='Common_Btn_Min_Width' onClick={() => this.setState({ analyserCode: null, openPopUp: false, isFromEdit: false, id: null }, () => { this.handleClear() })} >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <hr />
            <Box component={'div'} style={{ display: "flex", flexDirection: "row", padding: "0vw 1vw" }}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Box  >
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {this.state.isFromEdit ? this.renderTextField(this.state.analyserCode, "analyserCode", "Analyser Code", false, "12vw", true) : null}
                    {this.renderTextField(this.state.analyserName, "analyserName", "Analyser Name", true, "12vw")}
                  </div>
                </Box>
              </Box>
            </Box>
           <Box component={'div'} style={{height: "15vw"}}>
           <Box component={'div'} style={{ display: "flex", flexDirection: "column",padding: "0vw 1vw" }}>
              <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                <Typography>{"Status"}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                <Checkbox
                  disabled ={!this.state.isFromEdit}
                  checked={this.state.is_active}
                  onChange={(e) => { this.setState({ is_active: e.target.checked, inactive_reason: "" }) }}
                />
                <Typography>{"Active"}</Typography>
              </Box>
            </Box>
            {this.state.is_active === false ?
              <Box style={{margin: "0vw 1vw"}}>
                <Box sx={{ marginLeft: "1vh", marginTop: "1vh", padding: "0vw 1vw" }}>
                  <Typography>{"Inactive Comments"}</Typography>
                </Box>
                <Box sx={{ marginLeft: "1vh", marginTop: "1vh" }}>
                  <div>
                    <TextField
                      size='small'
                      label={("Reason *")}
                      style={{ marginTop: "0.5vw", width: "38.6vw" }}
                      multiline={true}
                      disabled ={!this.state.isFromEdit}
                      rows={3}
                      required
                      variant="outlined"
                      value={this.state.inactive_reason}
                      onChange={(event) => {
                        let value = event.target.value
                        if (value?.length <= 250) {
                          this.setState({ inactive_reason: value })
                        } else {
                          this.errorMessage('Allowed only 250 characters')
                        }
                      }}
                    />
                  </div>
                </Box>
              </Box>
              : null
            } </Box>
            <Box>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "2vw", marginTop: "1vw" }}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => { this.handleClear() }}
                >Clear</Button>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  disabled={this.state.postLoad}
                  variant='contained'
                  onClick={() => { this.setState({ postLoad: true },() => {this.validation()}) }}
                >{states.isFromEdit ? "Update" : "Add"} </Button>
              </div>
            </Box>
          </Box>

        </Modal>
      </div>
    )
  }
  gridToolBar = () => {
    var ExportData = []
    this.state.AnalyserData?.length > 0 && this.state.AnalyserData?.map((item) => {

      return (
        ExportData?.push({
          "SI.No": item?.sno, "Analyser Code": item?.analyser_code, "Analyser Name": item?.analyser_name,  "Status": item?.is_active === true ? "Active" : item?.is_active === false ? "InActive" : "",
        })
      )
    }
    )
    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"Analyser Master"} filename={"Analyser Master"} />
      </Box>
    )
  }

  renderTable = () => {
    this.state.AnalyserData?.forEach((element, index) => element.sno = index + 1)

    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "analyser_code", flex: 0.166, headerName: t("Analyser Code"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.analyser_code ? params?.row?.analyser_code : "-"}</Box>)
      },
      {
        field: "analyser_name", flex: 0.256, headerName: t("Analyser Name"), headerAlign: "left", align: "left",
        renderCell: (params) => (<div>{params?.row?.analyser_name?.length > 40 ?
          <Tooltip placement="top" title={params?.row?.analyser_name} arrow><div>{params?.row?.analyser_name.slice(0, 40) + "..."}</div></Tooltip>
          : params?.row?.analyser_name ? params?.row?.analyser_name : "-"}</div>)
      },
      {
        field: "status", flex: 0.166, headerName: t("Status"), headerAlign: "left", align: "left",
        renderCell: (params) => (
          <Tooltip placement="top" title={params?.row?.inactive_reason} arrow><div>{params?.row?.is_active ? "Active" : "Inactive"}</div></Tooltip>  
        )
      },
      {
        field: "action", flex: 0.134, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ? <Tooltip title={t("Edit")} placement="top" arrow>
            <Button emed_tid="analsye_edit" className='eMed_usrconf_btn' onClick={() => { this.handleEdit(params.row) }} >
              <img src={ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
        </Box>
      }
    ]
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' sx={{ backgroundColor: Colors.Background }}>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.AnalyserData}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              loading={this.state.isLoader}
              rowCount={this.state.rowCount}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              disableSelectionOnClick
            />
          </Paper>
        </Stack>
      </Box>
    )
  }
  render() {
    const { t } = this.props;
    return (
      <div  style={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top' style={{ backgroundColor: Colors.Background }}>
          {userAccess?.editAccess ? <><Button emed_tid="add_analyse" className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => { this.setState({ openPopUp: true }) }}>{`Add New Analyser`}</Button>
            <Divider orientation='vertical' /></> : null}
          <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid="analyse_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip>

        </div>
        {this.renderTable()}
        {this.addEditAnalyser()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}

      </div>
    )
  }
}
export default withTranslation()(AnalyserMaster)






