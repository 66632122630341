import { Autocomplete, Box, Button, Drawer, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../FrontOffice/FOReports/reports.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../Utility/Constants';
import Loader from '../../../Components/Loader';


class PharmaISInvoiceReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            serviceOverviewData: [],
            TotalBillAmount: 0,
            TotalReceivedAmount: 0,
            TotalPendingAmount: 0,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            disableBtn: false,
            invoiceReport: [],
            po_pharmacy_id : null,
        }
    }

    componentDidMount() {
        this.getInvoiceReport()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getInvoiceReport = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.INTERNALSALES_INVOICE_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            invoiceReport: response.data.data ? response.data.data : [],
                            TotalBillAmount: response.data.total_bill_amount ? response.data.total_bill_amount : 0,
                            TotalReceivedAmount: response.data.total_received_amount ? response.data.total_received_amount : 0,
                            TotalPendingAmount: response.data.total_balance_amount ? response.data.total_balance_amount : 0,
                            // po_pharmacy_id : this.state.invoiceReport.pharmacy_id ?  this.state.invoiceReport.pharmacy_id  : null ,
                            // po_pharmacy_id : response?.data?.data?.pharmacy_id ? response?.data?.data?.pharmacy_id :  null

                        }, () => {
                            console.log("po_pharmacy_idresponse",this.state.po_pharmacy_id)
                             this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    getInvoiceReportPrint = (printId) => {
        
        let po_id = printId ? printId : "" 
        try {
            this.setState({ disableBtn: true, printLoading: true })
            RestAPIService.getAll(Serviceurls.INTERNALSALES_INVOICE_REPORT + `?detailed=${po_id ? true : false}&po_pharmacy_id=${po_id}&export=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false, printLoading: false })
                }).catch((error) => {
                    this.errorMessage(error?.message)
                    this.setState({ disableBtn: false, printLoading: false })
                })
        } catch (error) {
            this.errorMessage(error?.message)
            this.setState({ disableBtn: false, printLoading: false })
        }
    }
    
    getInvoiceReportExcel = () => {
    const { t } = this.props;
    // let { pharmacy_id, bill_year } = this.state;
    // let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
    try {
        this.setState({ disableBtn: true })
        RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_INVOICE_REPORT}?export=${"excel"}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 
  
                    let filename = response.headers["content-disposition"].split("filename=")[1]
  
                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
    } catch (error) {
        this.errorMessage(t("PrintError"))
        this.setState({ disableBtn: false })
    }
  }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    // renderFilterScreen = () => {
    //     const { t } = this.props
    //     return (
    //         <Box component={"div"} className='eMed_rts_filter_container'>
    //             <Box component={"div"} className='eMed_filter_Top'>
    //                 <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
    //                 <Button emed_tid={'close_filter_btn'} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
    //                     <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
    //                 </Button>
    //             </Box>
    //             <Box className='eMed_rts_middle'>
    //                 <Box className='eMed_date_filter'>
    //                     <CommonDatePicker
    //                         FromDate={this.state.fromDate}
    //                         ToDate={this.state.toDate}
    //                         DateIndex={this.state.dateIndex}
    //                         SelectedDates={this.FilterDate.bind(this)}
    //                         emed_tid={"CommonDatePicker_test_ID"}
    //                         HideAllMenu={true} />
    //                 </Box>
    //                 <Box className='eMed_rts_dropdown'>
    //                 </Box>

    //             </Box>
    //             <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
    //                 <Button variant='outlined' size="small" emed_tid={'clear_btn'}
    //                     onClick={() => {
    //                         this.setState({
    //                             fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    //                             toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    //                             dateIndex: 1,
    //                         }, () => this.getInvoiceReport())
    //                     }}
    //                     id="eMed_Config_btn">{t("Clear")}</Button>
    //                 <Button variant='contained' size="small" emed_tid={"Search01test_ID"}
    //                     onClick={() => {
    //                         this.getInvoiceReport()
    //                         this.setState({ FilterOpen: false })
    //                     }}
    //                     id="eMed_Config_btn">{t("Search")}</Button>
    //             </Stack>
    //         </Box>
    //     )
    // }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    // gridToolBar = () => {
    //     var { t } = this.props
    //     let TextArray = [
    //         { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
    //         { label: "To Date", value: formatDate(this.state.FilteredToDate) },
    //     ]
    //     return (
    //         <Box>
    //             <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
    //         </Box>

    //     )
    // }

    printIndividualReport = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.OP_CARD_PRINT + "?export_type=pdf")
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
     
        const { t } = this.props
        this.state.invoiceReport?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: "sno", sortable: false, flex: 0.03, headerName: t("SNo"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
              },
            {
                field: "pharmacy_name", headerName: t("Pharmacy Name"), flex: 0.142,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.pharmacy_name?.length > 22 ?
                    <Tooltip placement='top' title={params?.row?.pharmacy_name} arrow><div>{params?.row?.pharmacy_name?.slice(0, 22) + "..."}</div></Tooltip>
                    : params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-"}</Box>)
            },
            {
                field: "gst_no", headerName: t("GST Number"), flex: 0.142, headerAlign: "right",  type:'number',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.gst_no ? params?.row?.gst_no : "-"}</Box>)
            },
            {
                field: "total_invoice", headerName: t("Total PO"), flex: 0.142, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_invoice ? params?.row?.total_invoice : "0"}</Box>)
            },
            {
                field: "total_bill_amt", headerName: t("Bill Amount"), flex: 0.142, headerAlign: "right",  type:'number',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_bill_amt ? AmountFormat(params?.row?.total_bill_amt)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "total_received_amt", headerName: t("Received Amount"), flex: 0.142, headerAlign: "right",  type:'number',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_received_amt ? AmountFormat(params?.row?.total_received_amt)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "total_balance_amt", headerName: t("Outstanding Amount"), flex: 0.112, headerAlign: "right",  type:'number',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_balance_amt ? AmountFormat(params?.row?.total_balance_amt)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "action", flex: 0.142, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"} visibility={params.row?.is_pending_bill ? "hidden" : "visible"}>
                    <Tooltip title={t("Print")} placement="top" arrow>
                        <IconButton emed_tid={"PrintIcons_testID"} onClick={() => { this.getInvoiceReportPrint(params?.row?.pharmacy_id) }} className='eMed_usrconf_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                        </IconButton></Tooltip>
                    <Tooltip placement='top' title='View' arrow><div>
                        <IconButton onClick={() => { this.props.history?.push({ pathname: '/PharmacyInternalSales/InvoiceReport/VendorPayment', state: { lineItem: params?.row,  po_pharmacy_id  : this.state.po_pharmacy_id } }) }}>
                            <img className='eMed_upload' emed_tid='LabViewButton__TestID' src={ImagePaths.LabViewButton.default} alt='view' />
                        </IconButton></div>
                    </Tooltip>
                </Box>
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Bill Amount"), this.state.TotalBillAmount)}
                        {AmountsCard(t("Total Received Amount"), this.state.TotalReceivedAmount)}
                        {AmountsCard(t("Total Pending Amount"), this.state.TotalPendingAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        {/* <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid={"FilterTestID"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box> */}
                        <Button className='eMed_usrconf_btn' emed_tid={"print_test_ID"} disabled={this.state.disableBtn} onClick={() => { this.getInvoiceReportPrint() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn' emed_tid={"print_test_ID"} disabled={this.state.disableBtn} onClick={() => { this.getInvoiceReportExcel() }}  >
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.invoiceReport}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {/* <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                > */}
                    {/* {this.renderFilterScreen()} */}
                {/* </Drawer> */}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}


export default withTranslation()(PharmaISInvoiceReport)