import React, { Component } from "react";
import "./charts.css";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import {AmountFormat} from "../../../Components/CommonFunctions/CommonFunctions"
import { withTranslation } from 'react-i18next';
import { CurrencySymbol } from "../../../Utility/Constants";

class RevenueCards extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { details, t } = this.props
        let Amount = details.Amount.toString();
        return (
            <Paper className="eMed_revenueCard_div" elevation={2}>
                <Box className="eMed_revenueCard_imgDiv">
                    <img className="emedhub_revenueCard_img" src={details.image ? details.image : ""} alt={details.title} />
                    <div style={{ marginLeft: "0.2vw" }}>
                        <Typography id="eMed_revenueCard_headTxt">{details?.title?.length > 16 ?
                            <Tooltip placement="top" title={details?.title} arrow>
                                <div id="eMed_revenueCard_headTxt">{details?.title?.slice(0, 15) + "..."}</div>
                            </Tooltip>
                            : details.title ? details.title : ""}
                        </Typography>

                        <Typography id="eMed_revenueCard_Txt">{details?.branchName?.length > 18 ?
                            <Tooltip placement="top" title={details?.branchName} arrow>
                                <div id="eMed_revenueCard_Txt">{details?.branchName?.slice(0, 18) + "..."}</div>
                            </Tooltip>
                            : details.branchName ? details.branchName : ""}
                        </Typography>
                    </div>
                </Box>
                <Box className="eMed_revenueCard_countDiv">
                    {details.countLabel && details.count ?
                        <Typography id="eMed_revenueCard_countTlt">
                            {`${t(details.countLabel)} : `}<span id="eMed_revenueCard_count">{details.count ? details.count : 0}</span>
                        </Typography> : null}
                    <Typography id="eMed_revenueCard_amount" >
                        {Amount?.length > 10 ?
                            <Tooltip placement="top" title={`${CurrencySymbol} ${(details.Amount)?.toFixed(2)}`} arrow>
                                <div id="eMed_revenueCard_amount">{AmountFormat(details?.Amount)?.toString()?.slice(0, 10) + "..."}</div>
                            </Tooltip>
                            : details.Amount ? AmountFormat(details.Amount) : `${CurrencySymbol} 0.00`}
                    </Typography>
                    { (typeof(details.ProfitValue) === "number") ? 
                    <Box>
                     <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                     <img style={{ height:"0.9vw", width:"0.9vw"}} className='eMed_revenueCard_profit' src={details.ProfitValue < 0 ? ImagePaths.LossIcon.default : ImagePaths.ProfitIcon.default} />
                        <Typography fontSize={"0.8vw"} fontWeight={600} sx={{ color : details.ProfitValue < 0 ? '#B8170D' : '#007A64'}}>
                         {`${details.ProfitValue < 0 ? "Loss" : "Profit" } : ${AmountFormat(Math.abs(details.ProfitValue))}`}
                    </Typography></Box>   
                    <Typography id="eMed_revenueCard_comparTlt">{`vs ${details?.compareDate ? details?.compareDate : "Compared Date"}`}</Typography>
                    </Box> : null }
                </Box>
            </Paper>
        )
    }
}
export default withTranslation()(RevenueCards);