import React, { Component } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Drawer, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import SubLocReportFilter from "./SubLocReportFilter";
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../Utility/Constants';

class SubLocationExpiryStock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DataList: [],
            filterDatas: {
                'selectMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'brandName': ''
            },
            totalBill: 0,
            totalAmount: 0,
            page: 0,
            pageSize: 10,
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            pageName: 'SubLocExpiryStock',
            startTime: '',
            endTime: ''
        }
    }

    componentDidMount() {
        this.getSubLocExpiryStock();
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => {
            this.getSubLocExpiryStock()
        })
    }

    getSubLocExpiryStock = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            RestAPIService.getAll(`${Serviceurls.SUBLOC_EXPIRY_STOCK}?month=${this.state.filterDatas?.selectMonth?.split("-")[1]}&year=${this.state.filterDatas?.selectMonth?.split("-")[0]}&brand_name=${brandName}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DataList: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getSubLocExpiryStockPrint = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            RestAPIService.getAll(`${Serviceurls.SUBLOC_EXPIRY_STOCK}?month=${this.state.filterDatas?.selectMonth?.split("-")[1]}&year=${this.state.filterDatas?.selectMonth?.split("-")[0]}&brand_name=${brandName}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response.data.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    formatMonthAndYear = (value) => {
        let month = value.split("-")[1]
        let year = value.split('-')[0]

        return month + "-" + year
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "Month", value: this.formatMonthAndYear(this.state.filterDatas.selectMonth) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    renderTable = () => {
        const { t } = this.props
        let states = this.state;
        states.DataList.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'brand_name', headerName: "Product Details", flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.brand_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.brand_name ? params?.row?.brand_name : '-'}
                    {params?.row?.dosage_type?.length > 12 || params?.row?.dosage_strength?.length > 12 ?
                        <Box display={"flex"}>
                            <Tooltip placement="top" title={params?.row?.dosage_type}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{params?.row?.dosage_type.slice(0, 12) + '...'}</Typography></Tooltip> |
                            <Tooltip placement="top" title={params?.row?.dosage_strength}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{params?.row?.dosage_strength.slice(0, 12)}</Typography></Tooltip>
                        </Box> :
                        <Typography id='eMed_Com_Patient_UhidText'>{`${params?.row?.dosage_type} | ${params?.row?.dosage_strength}`}</Typography>}
                </Box>)
            },
            {
                field: 'batch_no', headerName: "Batch No.", flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.batch_no ? params?.row?.batch_no : '-'}
                </Box>)
            },
            {
                field: 'expiry_date', headerName:"Expiry Date", flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params.row?.expiry_date ? params.row?.expiry_date : '-'}</Box>)
            },
            {
                field: 'available_quantity', headerName: "Stock In Qty", flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params.row?.available_quantity ? params.row?.available_quantity : '0'}</Box>)
            },
            {
                field: 'mrp_qty', headerName: "MRP/Qty" +` (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params.row?.mrp_qty || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'stock_value', headerName: "Stock Value" +` (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params.row?.stock_value || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'}>
                <Paper sx={{ height: '63vh' }}>
                    <DataGrid
                        rows={states.DataList}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={states.page}
                        pageSize={states.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => this.setState({ page: newPage })}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Paper>
            </Box>
        )
    }

    render() {
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                       
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <IconButton emed_tid={"subLoc_consumprpt_flt"} className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </IconButton>
                        <IconButton emed_tid={"subLoc_consumprpt_print"} className='eMed_Pharma_rts_btn' onClick={() => { this.getSubLocExpiryStockPrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </IconButton>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    {this.renderTable()}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <SubLocReportFilter
                        pageName={this.state.pageName}
                        filterDatas={this.state.filterDatas}
                        closeFilter={this.ClosePopUp.bind(this)}
                        filteredData={this.renderFilter.bind(this)}
                        ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(SubLocationExpiryStock);