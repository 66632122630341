import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { Days, FoodUsage, Months, TabletQty, Taper_Med_Frequency, Weeks, Year } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { debounce, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';

const Usage_Type = [
  { value: "ES", label: "Empty Stomach" },
  { value: "BF", label: "Before Food" },
  { value: "WF", label: "With Food" },
  { value: "AF", label: "After Food" },
  { value: "EU", label: "External Use" },
]

const UNIT_LIST = ['mg', 'Drops', 'ml']

const Number_Count = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
class DocPrecriptionRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: localGetItem('SelectedDoctorClinicId'),

      tabletQty: TabletQty,
      foodUsage: FoodUsage,
      appointmentId: this.props.PatientData?.appointment_id,
      patientId: this.props?.PatientData?.patient_id,
      appointmentDate: this.props?.PatientData?.appointment_date,
      isLocked: false,

      pharmacyList: [],
      selectedPharmacy: '',
      selectedDrug: '',
      searchedDrugList: [],
      searchedBrandName: '',
      searchedGenericName: null,
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: '',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      prescriptionId: null,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      ExternalDrugAdd: false,

      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      ExternalDrugName: "",
      ExternalGenericName: "",
      ExternalDosageStrength: "",
      ExternalDosageType: "",
      FreqDrugList: [],
      checkMl: false,
      ExternalDrugList: [],
      ExternalBrandName: '',
      prescripTblData: [],
      selectedTemplateId: null,
      drugSearchFor: 1,
      prescUsage: null,
      prescriptionUsage: [],
      openAddNew: false,
      usagseId: null,
      prescripTblData: [],
      value: 'one',

      // tapper Med States
      newTapMedListData: [],
      newTapSearchDrug: null,
      newTapMedDate: new Date(),
      newTapMedOpenDate: false,
      newTapMedDrugListData: [],
      newTapMedDuration: '',
      newTapDurationType: 'Day(s)',
      newTapMedUnit: "",
      newTapMedUnitType: '',
      newTapMedFrequency: '',
      newTapMedReduceBy: '',
      newTapMedFreqTablet: null,
      newTapMedQty: '',
      newTapMedUsage: '',
      newTapMedComments: '',
      newTapMedGenList: {},
      isNewTapEdit: false,
      newTapMedSelectedIndex: null,
      leftSelectedIndex: null,
      dupTapMedDetails: '{}',
      newLftTapEdit: false,
      newTapMedId: null,
      newTapMedIsFav: false,
      AddClicked: false,
      isChange: false,
      tapUsage: '',
      tapMorning: '',
      tapAfternoon: '',
      tapEvening: '',
      tapNight: '',
      selMor: false,
      selAft: false,
      selEve: false,
      selNig: false,
      selectedTabCount: '',
      TapperDrugList: [],
      tapSelectedId: null,
      MedicationFav: [],
      tapSelectedDrug: '',
      selectedDrugIdTap: null,
      checkOthers: false,
      // External Tapper Med
      tapperExternal: false,
      newTapExeSearchDrug: null,
      newTapMedExeDrugList: [],
      newTapMedExeDosageStrength: '',
      newTapMedExeDosageType: '',
      newTapMedExeGenericName: '',
      tempSearch: '',
      ExternalIsFav: false,
      newTapMedExeFav: false,
      is_pharmacy: false,
      newTapMedExeUnitType: 'mg'
    }
  }

  componentDidMount() {
    let hasPharmacy = JSON.parse(localGetItem('loggedInUserInfo'))?.pharmacy_data
    this.setState({ is_pharmacy: hasPharmacy?.length === 0 ? true : false }, () => {
      this.setState({
        ExternalDrugAdd: this.state.is_pharmacy,
        tapperExternal: this.state.is_pharmacy,
      })
      // }, () => this.setState({ newTapMedExeUnitType: 'mg'  }))
    })
    let drugSearch = localGetItem('drug_search') !== 'undefined' ? JSON.parse(localGetItem('drug_search')) : 1
    this.setState({ drugSearchFor: drugSearch })
    if (this.props.EditPrescriptionData && this.props.EditPrescriptionData && Object.keys(this.props.EditPrescriptionData).length !== 0) {
      let item = this.props.EditPrescriptionData
      this.setState({
        afternoonQty: item?.tapper_meds?.length === 0 ? typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString() : '',
        selectedDrug: item?.tapper_meds?.length === 0 ? item?.brand_name : '',
        comments: item?.tapper_meds?.length === 0 ? item?.comments : item?.comments,
        dosageStrength: item?.tapper_meds?.length === 0 ? item?.dosage_strength : '',
        dosageType: item?.tapper_meds?.length === 0 ? item?.dosage_type : '',
        selectedDuration: item?.tapper_meds?.length === 0 ? item?.duration : '0',
        selectedDays: item?.tapper_meds?.length === 0 ? item?.duration_mode : 'Days',
        eveningQty: item?.tapper_meds?.length === 0 ? typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString() : '0',
        nightQty: item?.tapper_meds?.length === 0 ? typeof (item?.night) === 'string' ? item?.night : (item?.night).toString() : '0',
        morningQty: item?.tapper_meds?.length === 0 ? typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString() : '0',
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.tapper_meds?.length === 0 ? item?.generic_name : '',
        selectedUsage: item?.tapper_meds?.length === 0 ? item?.instructions : '',
        selectedDrugId: item?.drug_id,
        selectedId: item?.new_id ? item?.new_id : item?.id ? item?.id : null,
        isEdit: true,
        checkMl: item?.is_unit_ml ? item?.is_unit_ml : false,
        selectedTemplateId: item?.template_id ? item?.template_id : null,
        // Tapper States
        newTapMedDrugListData: item?.tapper_meds?.length > 0 ? item?.tapper_meds ? JSON.parse(JSON.stringify(item?.tapper_meds)) : [] : [],
        newTapMedQty: item?.tapper_meds?.length > 0 ? item?.quantity ? item?.quantity : '' : '',
        newTapMedGenList: item?.tapper_meds?.length > 0 ? item ? JSON.parse(JSON.stringify(item)) : {} : {},
        newTapSearchDrug: item?.tapper_meds?.length > 0 ? item?.brand_name ? item?.brand_name : '' : null,
        prescriptionId: item?.prescription_id ? item?.prescription_id : null,
        value: item?.tapper_meds?.length === 0 ? 'one' : 'two',
        checkOthers: item?.is_unit_others ? item?.is_unit_others : false,
        newTapMedComments: item?.comments
      }, () => {
        if (item?.tapper_meds?.length === 0) {
          if (!item?.drug_id) {
            this.setState({
              ExternalDrugAdd: true,
              ExternalDrugName: item?.brand_name,
              ExternalBrandName: item?.brand_name,
              ExternalGenericName: item?.generic_name,
              ExternalDosageType: item?.dosage_type,
              ExternalDosageStrength: item?.dosage_strength,
              selectedDrug: "",
              dosageStrength: "",
              dosageType: '',
              searchedGenericName: "",
              ExternalIsFav: item?.is_favorite ? item?.is_favorite : false,
            })
          } else {
            this.getMedicFrequency()
          }
        } else {
          if (!item?.drug_id) {
            this.setState({
              tapperExternal: true,
              newTapExeSearchDrug: item?.brand_name,
              newTapMedExeDosageStrength: item?.dosage_strength,
              newTapMedExeDosageType: item?.dosage_type,
              newTapMedExeGenericName: item?.generic_name,
              tempSearch: '',
              newTapMedExeFav: item?.is_favorite,
            })
          }
        }
        this.props.ClearPropsData()
      })
    }
    if (this.props.prescripTblData?.prescription_lines?.length > 0) {
      const dataList = this.props.prescripTblData?.prescription_lines?.length > 0
        ? [...this.props.prescripTblData.prescription_lines]
        : [...this.props.prescripTblData];
      this.setState({
        prescripTblData: dataList?.length > 0 ? dataList : [],
        selectedPharmacy: this.state.selectedPharmacy !== dataList[0]?.pharmacy_id ?
          dataList[0]?.pharmacy_id : this.state.selectedPharmacy
      })
    }
    this.getPharmacyList()
    this.getPresctiptionUsageList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditPrescriptionData !== this.props.EditPrescriptionData && this.props.EditPrescriptionData && Object.keys(this.props.EditPrescriptionData).length !== 0) {
      let item = this.props.EditPrescriptionData
      this.setState({
        afternoonQty: item?.tapper_meds?.length === 0 ? typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString() : '',
        selectedDrug: item?.tapper_meds?.length === 0 ? item?.brand_name : '',
        comments: item?.tapper_meds?.length === 0 ? item?.comments : '',
        dosageStrength: item?.tapper_meds?.length === 0 ? item?.dosage_strength : '',
        dosageType: item?.tapper_meds?.length === 0 ? item?.dosage_type : '',
        selectedDuration: item?.tapper_meds?.length === 0 ? item?.duration : '0',
        selectedDays: item?.tapper_meds?.length === 0 ? item?.duration_mode : 'Days',
        eveningQty: item?.tapper_meds?.length === 0 ? typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString() : '0',
        nightQty: item?.tapper_meds?.length === 0 ? typeof (item?.night) === 'string' ? item?.night : (item?.night).toString() : '0',
        morningQty: item?.tapper_meds?.length === 0 ? typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString() : '0',
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.tapper_meds?.length === 0 ? item?.generic_name : '',
        selectedUsage: item?.tapper_meds?.length === 0 ? item?.instructions : '',
        selectedDrugId: item?.drug_id,
        selectedId: item?.new_id ? item?.new_id : item?.id ? item?.id : null,
        isEdit: true,
        checkMl: item?.is_unit_ml ? item?.is_unit_ml : false,
        selectedTemplateId: item?.template_id ? item?.template_id : null,
        // Tapper States
        newTapMedDrugListData: item?.tapper_meds?.length > 0 ? item?.tapper_meds ? JSON.parse(JSON.stringify(item?.tapper_meds)) : [] : [],
        newTapMedQty: item?.tapper_meds?.length > 0 ? item?.quantity ? item?.quantity : '' : '',
        newTapMedGenList: item?.tapper_meds?.length > 0 ? item ? JSON.parse(JSON.stringify(item)) : {} : {},
        newTapSearchDrug: item?.tapper_meds?.length > 0 ? item?.brand_name ? item?.brand_name : '' : null,
        prescriptionId: item?.prescription_id ? item?.prescription_id : null,
        value: item?.tapper_meds?.length === 0 ? 'one' : 'two',
        checkOthers: item?.is_unit_others ? item?.is_unit_others : false,
        newTapMedComments: item?.comments
      }, () => {
        if (this.state.value === 'one') {
          if (item?.tapper_meds?.length === 0) {
            if (!item?.drug_id) {
              this.setState({
                ExternalDrugAdd: true,
                ExternalDrugName: item?.brand_name,
                ExternalBrandName: item?.brand_name,
                ExternalGenericName: item?.generic_name,
                ExternalDosageType: item?.dosage_type,
                ExternalDosageStrength: item?.dosage_strength,
                selectedDrug: "",
                dosageStrength: "",
                dosageType: '',
                searchedGenericName: "",
                ExternalIsFav: item?.is_favorite ? item?.is_favorite : false
              })
            }
          } else {
            this.getMedicFrequency()
          }
        } else {
          if (!item?.drug_id) {
            this.setState({
              tapperExternal: true,
              newTapExeSearchDrug: item?.brand_name,
              newTapMedExeDosageStrength: item?.dosage_strength,
              newTapMedExeDosageType: item?.dosage_type,
              newTapMedExeGenericName: item?.generic_name,
              tempSearch: '',
              newTapMedExeFav: item?.is_favorite
            })
          }
        }
        this.props.ClearPropsData()
      })
    }

    const currentPrescripTblData = JSON.parse(JSON.stringify(this.props.prescripTblData));
    const previousPrescripTblData = JSON.parse(JSON.stringify(prevProps.prescripTblData));
    if (
      (previousPrescripTblData !== currentPrescripTblData) &&
      (currentPrescripTblData !== this.state.prescripTblData)
    ) {
      const dataList = Array.isArray(currentPrescripTblData) && currentPrescripTblData?.length > 0
        ? [...currentPrescripTblData]
        : [];

      if (JSON.stringify(this.state.prescripTblData) !== JSON.stringify(dataList)) {
        this.setState({
          prescripTblData: dataList,
          selectedPharmacy: dataList?.length > 0 ? ((this.state.selectedPharmacy !== dataList[0]?.pharmacy_id && dataList[0]?.pharmacy_id) ?
            dataList[0]?.pharmacy_id : (this.state.selectedPharmacy ? this.state.selectedPharmacy : this.state.pharmacyList[0]?.pharmacy_id)) : this.state.pharmacyList[0]?.pharmacy_id
        }, () => {
          this.getFreqDrugList()
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.ClearClickedonRightPrecs(true)
  }

  getPharmacyList = () => {
    try {
      // RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PHARMACY_LIST_GET + `?clinic_id=${this.state.clinicId}&suggest=true`)
      //   .then((response) => {
      //     if (response?.data?.status === 'success') {
      //       this.setState({
      //         pharmacyList: response.data?.data,
      //         selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //       }, () => {
      //         this.getFreqDrugList()
      //       })
      //     }
      //   }).catch((error) => {
      //     if (error?.response?.data?.message) {
      //       this.errorMessage(error.response.data.message)
      //     } else {
      //       this.errorMessage(error.message)
      //     }
      //   })
      let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
      let pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
      const dataList = this.state.prescripTblData?.prescription_lines?.length > 0
        ? [...this.state.prescripTblData.prescription_lines]
        : [...this.state.prescripTblData];
      if (pharmaList?.length) {
        this.setState((prevState) => ({
          pharmacyList: pharmaList,
          selectedPharmacy: (dataList?.length > 0 && prevState?.selectedPharmacy !== dataList[0]?.pharmacy_id && dataList[0]?.pharmacy_id) ?
            dataList[0]?.pharmacy_id : (pharmaList[0]?.pharmacy_id || '')
        }), () => {
          this.getFreqDrugList()
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getFreqDrugList = () => {
    try {
      let params = '';
      let pharmacyId = this.state.selectedPharmacy ? this.state.selectedPharmacy : ''
      if (this.state.value === 'one') {
        params = `?pharmacy_id=${pharmacyId}`
      } else {
        params = `?pharmacy_id=${pharmacyId}&is_tapper_meds=true`
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FEQ + params)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (this.state.value === 'one') {
              this.setState({ FreqDrugList: response.data?.data })
            } else {
              this.setState({ MedicationFav: response.data?.data })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPresctiptionUsageList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PRESCIPTION_USAGE_LIST)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response.data.data; let usageData = [];
            Usage_Type?.forEach(element => usageData?.unshift(element))
            data?.forEach(element => {
              usageData?.push({
                label: element?.instructions,
                value: element?.instructions,
                is_added: true,
                id: element?.id
              })
            })
            this.setState({ prescriptionUsage: usageData }, () => {
              this.setState({ usagseId: this.state.prescriptionUsage?.find(item => item.label === this.state.selectedUsage)?.id })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPresctiptionUsageList = () => {
    try {
      let data = {
        "instructions": this.state.prescUsage
      }
      if (this.state.prescUsage === null || this.state.prescUsage === '') {
        this.setState({ postUsageLoad: false })
        this.errorMessage('Please Enter Usage')
      } else {
        RestAPIService.create(data, Serviceurls.DOC_PRESCIPTION_USAGE_LIST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({ selectedUsage: this.state.prescUsage }, () => {
                this.setState({ openAddNew: false, postUsageLoad: false, prescUsage: null })
                this.getPresctiptionUsageList()
              })
            }
          }).catch((error) => {
            this.setState({ postUsageLoad: false })
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.setState({ postUsageLoad: false })
      this.errorMessage(error.message)
    }
  }

  deletePresctiptionUsageList = (item) => {
    try {
      let data = {}
      RestAPIService.delete(Serviceurls.DOC_PRESCIPTION_USAGE_LIST + '?id=' + item?.id, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ selectedUsage: item?.id === this.state.usagseId ? '' : this.state.selectedUsage })
            this.getPresctiptionUsageList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData(FromClearBtn = false) {
    this.setState({
      selectedDrug: '',
      searchedDrugList: [],
      searchedBrandName: '',
      searchedGenericName: "",
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: 'Days',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      ExternalDrugAdd: this.state.is_pharmacy ? true : false,
      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      ExternalDrugName: '',
      ExternalGenericName: "",
      ExternalDosageStrength: "",
      ExternalDosageType: "",
      checkMl: false,
      ExternalDrugList: [],
      ExternalBrandName: '',
      selectedTemplateId: null,
      checkOthers: false
    }, () => {
      console.log(this.state.ExternalBrandName,this.state.ExternalDrugName)
      if (FromClearBtn) {
        this.props.ClearClickedonRightPrecs(true)
      }
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isDeleteClicked: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  getGenericNameList = (fromFreq = false) => {
    try {
      let params = ''
      if (this.state.value === 'one') {
        if (fromFreq) {
          params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        } else {
          if (+this.state.drugSearchFor === 1) {
            params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
          } else {
            params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=${encodeURIComponent(this.state.searchedGenericName)}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
          }
        }
      } else {
        let brandName = this.state.newTapSearchDrug?.drug_name ? this.state.newTapSearchDrug?.drug_name : this.state.tapSelectedDrug
        let generic_name = this.state.newTapSearchDrug?.generic_name ? this.state.newTapSearchDrug?.generic_name : ''
        params = `?brand_name=${encodeURIComponent(brandName)}&generic_name=${encodeURIComponent(generic_name)}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERIC_NAME_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            if (this.state.value === 'one') {
              this.setState({
                genericList: response.data.data,
                searchedGenericName: response?.data?.data?.length > 0 ? response?.data?.data[0] : '',
              }, () => {
                let val = this.state.searchedGenericName?.latest_prescription ? this.state.searchedGenericName?.latest_prescription : {}
                if (Object.keys(val)?.length > 0) {
                  this.setState({
                    checkMl: val?.is_unit_ml,
                    morningQty: val?.morning,
                    afternoonQty: val?.afternoon,
                    eveningQty: val?.evening,
                    nightQty: val?.night,
                    selectedDays: val?.duration_mode,
                    selectedDuration: val?.duration,
                    selectedUsage: val?.instructions,
                    comments: val?.comments,
                    checkOthers: val?.is_unit_others
                  })
                }
                this.setState({
                  dosageStrength: this.state.searchedGenericName?.dosage_strength,
                  dosageType: this.state.searchedGenericName?.dosage_type
                }, () => {
                  let val = this.state.searchedGenericName?.latest_prescription ? this.state.searchedGenericName?.latest_prescription : {}
                  if (Object.keys(val)?.length === 0) {
                    if ((this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'syrup')) {
                      this.setState({ checkMl: true })
                    } else if (this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'drops' || this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'spray') {
                      this.setState({ checkOthers: true })
                    } else {
                      this.setState({
                        checkMl: false,
                        checkOthers: false
                      })
                    }
                  }
                })
              })
            } else {
              let unit = response.data.data[0]?.dosage_strength ? response.data.data[0]?.dosage_strength?.match(/\d+/) : response.data.data[0]?.dosage_strength
              this.setState({
                newTapMedGenList: response.data.data[0],
                // newTapMedUnitType: response.data.data[0]?.dosage_type?.toUpperCase() === 'STEROID TABLETS' ? 'Mg' : 'Drop(s)',
                newTapMedUnitType: 'mg',
                newTapMedUnit: unit ? unit[0] : response.data.data[0]?.dosage_strength
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyDrugSearchList = (searchValue) => {
    try {
      let params = '';
      if (this.state.value === 'one') {
        if (this.state.drugSearchFor === 1) {
          params = `?brand_name=${searchValue}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        } else if (this.state.drugSearchFor === 2) {
          params = `?brand_name=&generic_name=${searchValue}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        } else {
          params = `?search_key=${searchValue}&brand_name=&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        }
      } else {
        params = `?brand_name=${searchValue}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DRUG_SEARCH + params)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response.data.data
            let uniqueArray = data?.filter((item, index, self) =>
              index === self.findIndex((t) => (
                t.drug_name === item.drug_name
              ))
            );
            if (this.state.value === 'one') {
              this.setState({ searchedDrugList: this.state.drugSearchFor === 1 ? uniqueArray : response.data.data })
            } else {
              this.setState({
                TapperDrugList: uniqueArray,
                tapSelectedDrug: ''
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ + "?allergy=" + states.SelectedAllergy).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.getPharmacyList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      let isDrugValid = states?.ExternalDrugAdd ? true : (states.selectedDrug !== '') && (states.searchedGenericName === null) && (states.dosageStrength === '') && (states.dosageType === '')

      if (this.state.value === 'one') {
        if (states?.ExternalDrugAdd) {
          if (states.ExternalDrugName === "") {
            message = 'Select Brand name'
          } else if (states.ExternalGenericName === "") {
            message = 'Select Generic Name'
          } else if (states.ExternalDosageType === "" || states.ExternalDosageStrength === "") {
            message = 'Dosage Strength and Dosage Type is Empty'
          } else if ((states.morningQty === '0' || states.morningQty === '') && (states.afternoonQty === '0' || states.afternoonQty === '') && (states.eveningQty === '0' || states.eveningQty === '') && (states.nightQty === '0' || states.nightQty === '')) {
            message = 'Please Select MAEN Dosage Quantity'
          } else if (states.selectedDays === '' || states.selectedDuration === '') {
            message = 'Select duration'
          } else if (states.selectedUsage === '') {
            message = 'Select Usage'
          }
        } else {
          if (states.selectedDrug === '') {
            message = 'Select Brand name'
          } else if (states.searchedGenericName === null || states.searchedGenericName === "") {
            message = 'Select Generic Name'
          } else if (states.dosageStrength === '' || states.dosageType === '') {
            message = 'Dosage Strength and Dosage Type is Empty'
          } else if ((states.morningQty === '0' || states.morningQty === '') && (states.afternoonQty === '0' || states.afternoonQty === '') && (states.eveningQty === '0' || states.eveningQty === '') && (states.nightQty === '0' || states.nightQty === '')) {
            message = 'Please Select MAEN Dosage Quantity'
          } else if (states.selectedDays === '' || states.selectedDuration === '') {
            message = 'Select duration'
          } else if (states.selectedUsage === '') {
            message = 'Select Usage'
          }
        }
      } else {
        if (states.tapperExternal) {
          if ((states.newTapExeSearchDrug === null || states.newTapExeSearchDrug === '')) {
            message = 'Enter Drug Details'
          } else if (states.newTapMedDrugListData?.length === 0) {
            message = 'Add atleast one tapper details'
          }
        } else {
          if ((states.newTapSearchDrug === null || states.newTapSearchDrug === '') && states.tapSelectedDrug === '') {
            message = 'Enter Drug Details'
          } else if (states.newTapMedDrugListData?.length === 0) {
            message = 'Add atleast one tapper details'
          }
        }
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  instructions = (item) => {
    let value = '';
    switch (item) {
      case 'Before Food':
        value = 'BF'
        break;
      case 'After Food':
        value = 'AF'
        break;
      case 'External Use':
        value = 'EU'
        break;
      case 'Empty Stomach':
        value = 'ES'
        break;
      case 'With Food':
        value = 'WF'
        break;
      default:
        value = item;
        break;
    }
    return value;
  }

  addPrescriptionData = () => {
    try {
      let states = this.state
      let list = {}
      if (this.state.value === 'one') {
        list = {
          afternoon: states.afternoonQty ? states.afternoonQty : '0',
          brand_name: states?.ExternalDrugAdd ? states?.ExternalDrugName : states.selectedDrug,
          comments: states.comments,
          dosage_strength: states?.ExternalDrugAdd ? states?.ExternalDosageStrength : states.dosageStrength,
          dosage_type: states?.ExternalDrugAdd ? states?.ExternalDosageType : states.dosageType,
          drug_id: states?.ExternalDrugAdd ? null : states.searchedGenericName?.drug_id ? states.searchedGenericName?.drug_id : states.selectedDrugId,
          duration: states.selectedDuration,
          duration_mode: states.selectedDays,
          evening: states.eveningQty ? states.eveningQty : '0',
          generic_name: states?.ExternalDrugAdd ? states?.ExternalGenericName : states.searchedGenericName?.generic_name ? states.searchedGenericName?.generic_name : states.searchedGenericName,
          instructions: states.selectedUsage?.label ? this.instructions(states.selectedUsage?.label) : states.selectedUsage,
          is_favorite: states?.ExternalDrugAdd ? JSON.parse(JSON.stringify(states.ExternalIsFav)) : JSON.parse(JSON.stringify(states.favourite)),
          morning: states.morningQty ? states.morningQty : '0',
          night: states.nightQty ? states.nightQty : '0',
          id: states.selectedId ? states.selectedId : null,
          is_unit_ml: states.checkMl ? states.checkMl : false,
          pharmacy_id: states.selectedPharmacy || '',
          is_tapper_meds: false,
          tapper_meds: [],
          is_unit_others: states.checkOthers ? states.checkOthers : false,
          is_external_drug: states.ExternalDrugAdd ? true : false
        }
      } else {
        list = {
          afternoon: '0',
          comments: states.newTapMedComments,
          drug_id: states?.newTapMedGenList?.drug_id ? states?.newTapMedGenList?.drug_id : null,
          duration: '0',
          duration_mode: 'Days',
          evening: '0',
          instructions: '',
          morning: '0',
          night: '0',
          id: states.selectedId ? states.selectedId : null,
          is_unit_ml: false,
          is_unit_others: false,
          pharmacy_id: states.selectedPharmacy || '',
          is_tapper_meds: true,
          tapper_meds: states.newTapMedDrugListData ? states.newTapMedDrugListData : [],
        }
        if (this.state.tapperExternal) {
          list.brand_name = states.newTapExeSearchDrug?.drug_name ? states.newTapExeSearchDrug?.drug_name : states.newTapExeSearchDrug?.brand_name ? states.newTapExeSearchDrug?.brand_name : states.newTapExeSearchDrug
          list.generic_name = states.newTapMedExeGenericName ? states.newTapMedExeGenericName : ''
          list.dosage_strength = states.newTapMedExeDosageStrength ? states.newTapMedExeDosageStrength : ''
          list.dosage_type = states?.newTapMedExeDosageType ? states?.newTapMedExeDosageType : ''
          list.is_favorite = JSON.parse(JSON.stringify(states?.newTapMedExeFav))
          list.is_external_drug = true
        } else {
          list.brand_name = states.newTapMedGenList?.drug_name ? states.newTapMedGenList?.drug_name : states.newTapMedGenList?.brand_name ? states.newTapMedGenList?.brand_name : ''
          list.generic_name = states.newTapMedGenList?.generic_name ? states.newTapMedGenList?.generic_name : ''
          list.dosage_strength = states.newTapMedGenList?.dosage_strength ? states.newTapMedGenList?.dosage_strength : ''
          list.dosage_type = states?.newTapMedGenList?.dosage_type ? states?.newTapMedGenList?.dosage_type : ''
          list.is_favorite = states?.newTapMedGenList?.is_favorite ? JSON.parse(JSON.stringify(states?.newTapMedGenList?.is_favorite)) : false
          list.is_external_drug = false
        }
      }
      let checkValidation = this.checkValidation()
      if (checkValidation === null) {
        let data = CommonValidation.updateOrInsertList(states.prescriptionList, list, states.selectedIndex)
        this.props.AddPrescritionItem(data)
        // this.setState({ usagseId: null, value: 'one' })
        this.setState({ usagseId: null, value: this.state.isEdit ? 'one' : this.state.value, tapperExternal: this.state.is_pharmacy ? true : false,ExternalBrandName:'',ExternalDrugName:'' })
        this.ClearData()
        this.onClearTapMedHandler()
      } else {
        this.errorMessage(checkValidation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postTemplateData = () => {
    try {
      let data = {
        doctor_category: this.state.CategoryAddText
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.ClearData()
            this.getPatientCategoryList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }

  checkDuplicate = () => {
    try {
      let message = null
      let states = this.state
      states.drugList?.forEach(element => {
        if ((element?.name === states.searchedBrandName) || (element === states.searchedBrandName)) {
          message = 'Brand Name Already Exists'
        }
      })
      return message
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getExternalDrugSearchList = (brandName, genericName, brandNameSelected = false) => {
    try {
      let params = ''
      if (this.state.value === 'one') {
        params = `?brand_name=${brandName}&generic_name=${genericName}`
      } else {
        params = `?brand_name=${brandName}&generic_name=${genericName}&is_tapper_med=true`
      }
      RestAPIService.getAll(Serviceurls.DOC_OPT_EXTERNAL_DRUG + params)
        .then((response) => {
          if (response.data.status === 'success') {
            if (this.state.value === 'one') {
              this.setState({ ExternalDrugList: response?.data?.data })
            } else {
              let unit = response.data.data[0]?.dosage_strength ? response.data.data[0]?.dosage_strength?.match(/\d+/) : response.data.data[0]?.dosage_strength
              this.setState({
                newTapMedExeDrugList: response?.data?.data,
                newTapMedExeDosageStrength: brandNameSelected ? response?.data?.data[0]?.dosage_strength : '',
                newTapMedExeDosageType: brandNameSelected ? response?.data?.data[0]?.dosage_type : '',
                newTapMedExeGenericName: brandNameSelected ? response?.data?.data[0]?.generic_name : '',
                newTapMedExeFav: brandNameSelected ? response?.data?.data[0]?.is_favorite : false,
                // newTapMedGenList: brandNameSelected ? response?.data?.data[0] : {},
                newTapMedUnitType: 'Mg',
                newTapMedUnit: brandNameSelected ? unit : ''
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewUsagePopup = () => {
    try {
      return (
        <Dialog open={this.state.openAddNew} >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontSize={'0.9dvw'} fontWeight={600}>Add New Usage</Typography>
            <Button
              className='Common_Btn_Min_Width'
              onClick={() => this.setState({ openAddNew: false, prescUsage: null })}
            >
              <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1dvw'} width={'1dvw'} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              size='small'
              sx={{ width: '20dvw', mr: '1dvw' }}
              value={this.state.prescUsage}
              onChange={(e) => {
                if (e.target.value?.length <= 50) {
                  this.setState({ prescUsage: e.target.value })
                } else {
                  this.errorMessage('Allowed Only 50 Characters')
                }
              }}
              placeholder='Enter Usage'
            />
            <Button variant='contained' sx={{ textTransform: 'capitalize' }}
              disabled={this.state.postUsageLoad}
              onClick={() => this.setState({ postUsageLoad: true }, () => this.postPresctiptionUsageList())}
            >Add</Button>
          </DialogContent>
        </Dialog>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getOptions = (option) => {
    try {
      let label = '';
      switch (option) {
        case 'ES':
          label = "Empty Stomach"
          break;
        case 'BF':
          label = "Before Food"
          break;
        case 'WF':
          label = "With Food"
          break;
        case 'AF':
          label = "After Food"
          break;
        case 'EU':
          label = "External Use"
          break;
        default:
          label = option
          break;
      }
      return label;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPrescriptionScreen = () => {
    const { t } = this.props
    let list = this.state.checkMl ? this.state.tabletQty?.with_ml : this.state.checkOthers ? this.state.tabletQty?.with_drops : this.state.tabletQty?.without_ml
    let states = this.state
    return (
      <>
        <Box height={"55vh"} overflow={'auto'}>
          <Box component={'div'} mt={'0.5vw'} sx={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end', alignItems: "center", padding: "0.5dvw" }}>
            {/* <FormControl size='small' sx={{ width: '15vw' }}>
              <InputLabel>Select Pharmacy *</InputLabel>
              <Select
                label='select Pharmacy'
                value={this.state.selectedPharmacy}
                disabled={this.state.prescripTblData?.length > 0}
                // disabled = {this.state.prescripTblData?.length > 0 && this.state.prescripTblData[0]?.pharmacy_id === this.state.selectedPharmacy}
                onChange={(e) => this.setState({ selectedPharmacy: e.target.value },
                  () => {
                    this.getFreqDrugList();
                    this.ClearData();
                  })}
              >
                {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                  <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
            {this.state.is_pharmacy ? null :
              <Button variant='outlined' onClick={() => {
                this.setState({ ExternalDrugAdd: !this.state.ExternalDrugAdd })
              }}>{this.state.ExternalDrugAdd ? "Pharmacy Drugs" : "External Drugs"}</Button>}
          </Box>
          {
            this.state.ExternalDrugAdd ? <Box>
              <Autocomplete
                size='small'
                sx={{ marginTop: '0.5vw', width: '19vw', ml: '0.5vw' }}
                clearIcon
                freeSolo
                autoSelect={false}
                options={this.state.ExternalDrugList?.map((item) => item?.drug_name)}
                value={this.state.ExternalBrandName ? this.state.ExternalBrandName : ''}
                onChange={(e, value) => {
                  let selectedDrug = this.state.ExternalDrugList.filter((item) => item?.drug_name === value)
                  this.getExternalDrugSearchList(value, '')
                  this.setState({
                    ExternalBrandName: value
                  }, () => {
                    this.setState({
                      ExternalDrugName: selectedDrug[0]?.drug_name,
                      ExternalGenericName: selectedDrug[0]?.generic_name,
                      ExternalDosageStrength: selectedDrug[0]?.dosage_strength,
                      ExternalDosageType: selectedDrug[0]?.dosage_type,
                      ExternalIsFav: selectedDrug[0]?.is_favorite ? selectedDrug[0]?.is_favorite : false
                    })
                  })
                }}
                renderInput={(params) => <TextField {...params}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      states.ExternalDrugName = e.target.value
                      this.setState({
                        states
                      }, () => this.getExternalDrugSearchList(e.target.value, ''))
                    } else {
                      this.setState({
                        ExternalBrandName: '',
                        ExternalDrugName: '',
                        ExternalGenericName: '',
                        ExternalDosageStrength: '',
                        ExternalDosageType: '',
                      })
                    }
                  }}
                  label='Drug Name*' />}
              />
              <Box component={'div'} mt={'0.5vw'} sx={{ marginX: '0.5vw' }}>
                <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                  <TextField
                    size='small'
                    sx={{ width: '15vw' }}
                    value={this.state.ExternalGenericName}
                    onChange={(e) => {
                      if ((e.target.value) || e.target.value === "") {
                        this.setState({ ExternalGenericName: e.target.value })
                      }
                    }}
                    label='generic Name *'
                  />
                  <TextField
                    size='small'
                    sx={{ width: '10vw', ml: '0.5vw' }}
                    value={this.state.ExternalDosageStrength}
                    onChange={(e) => {
                      if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                        this.setState({ ExternalDosageStrength: e.target.value })
                      }
                    }}
                    label='Dosage Strength *'
                  />
                  <TextField
                    size='small'
                    sx={{ width: '10vw', ml: '0.5vw' }}
                    value={this.state.ExternalDosageType}
                    onChange={(e) => {
                      if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                        this.setState({ ExternalDosageType: e.target.value }, () => {
                          if (this.state.ExternalDosageType?.toLocaleLowerCase() === 'syrup') {
                            this.setState({ checkMl: true })
                          } else if (this.state.ExternalDosageType?.toLocaleLowerCase() === 'drops' || this.state.ExternalDosageType?.toLocaleLowerCase() === 'spray') {
                            this.setState({ checkOthers: true })
                          } else {
                            this.setState({
                              checkMl: false,
                              checkOthers: false
                              // prescription auto fetch so cmt for this ******//
                              // morningQty: '0',
                              // afternoonQty: '0', eveningQty: '0',
                              // nightQty: '0'
                            })
                          }
                        })
                      }
                    }}
                    label='Dosage Type *'
                  />
                </Box>
              </Box>
            </Box> :
              <Box>
                {
                  this.state.FreqDrugList?.length > 0 ? this.state.FreqDrugList.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.name ? item?.name : item}
                      variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        this.setState({
                          selectedDrug: item?.name ? item?.name : item,
                          favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
                          selectedDrugId: item?.id,
                          searchedGenericName: null,
                          dosageStrength: '', dosageType: ''
                        }, () => {
                          this.getGenericNameList(true)
                        })
                      }}
                      // color={'primary'}
                      clickable
                    />
                  )) : null
                }

                {/* <Autocomplete
              size='small'
              sx={{ margin: '0.5vw' }}
              clearIcon
              freeSolo
              options={this.state.searchedDrugList?.map((item) => item)}
              value={this.state.selectedDrug ? this.state.selectedDrug : this.state.searchedBrandName ? this.state.searchedBrandName : ""}
              onChange={(e, value) => {
                this.setState({
                  searchedBrandName: value
                }, () => {
                  if (this.state.searchedBrandName?.length > 0) {
                    let check = this.checkDuplicate()
                    // if (check === null) {
                    //   this.state.FreqDrugList?.push(this.state.searchedBrandName)
                    // }
                    this.setState({
                      FreqDrugList: this.state.FreqDrugList,
                      selectedDrug: this.state.searchedBrandName
                    }, () => {
                      this.setState({ searchedBrandName: '', searchedDrugList: [] })
                      this.getGenericNameList(this.state.selectedDrug)
                    })
                    // } else {
                    //   this.errorMessage(check)
                    //   this.setState({ searchedBrandName: '', searchedDrugList: [] })
                    // }
                  }
                })
              }}
              renderInput={(params) => <TextField {...params}
                onChange={(e) => {
                  if (e.target.value?.length > 1) {
                    this.setState({
                      searchedBrandName: e.target.value
                    }, () => {
                      this.getPharmacyDrugSearchList(this.state.searchedBrandName)
                    })
                  } else {
                    this.setState({ genericList: [] })
                  }
                }}
                label='Search Drug *' />}
            /> */}
                <Autocomplete
                  size='small'
                  sx={{ margin: '0.5vw' }}
                  clearIcon
                  options={this.state.searchedDrugList}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : (this.state.drugSearchFor === 1 ? option.drug_name : `${option.drug_name} - ${option?.generic_name}`)}
                  value={this.state.selectedDrug ? this.state.selectedDrug : this.state.searchedBrandName ? this.state.searchedBrandName : ""}
                  onChange={(e, value) => {
                    this.setState({ searchedBrandName: value }, () => {
                      if (this.state.searchedBrandName) {
                        this.setState({ selectedDrug: this.state.searchedBrandName?.drug_name }, () => {
                          if (this.state.drugSearchFor === 1) {
                            this.getGenericNameList(this.state.searchedBrandName?.drug_name)
                          } else {
                            this.setState({ searchedGenericName: this.state.searchedBrandName?.generic_name }, () => this.getGenericNameList())
                          }
                        })
                      } else {
                        this.setState({
                          searchedBrandName: '',
                          searchedDrugList: [],
                          searchedGenericName: null,
                          dosageStrength: '',
                          dosageType: ''
                        })
                      }
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label={'Search Drug'}
                    onChange={(e) => {
                      if (e.target.value?.length > 1) {
                        this.setState({ searchedBrandName: e.target.value }, () => {
                          this.getPharmacyDrugSearchList(this.state.searchedBrandName)
                        })
                      }
                    }}
                  />}
                  renderOption={(props, option) => (
                    <li {...props} key={props['data-option-index']}>
                      <Typography fontSize={'0.9vw'}>{this.state.drugSearchFor === 1 ? option.drug_name : `${option.drug_name} - ${option?.generic_name}`}</Typography>
                    </li>
                  )}
                />
                <Box component={'div'} mt={'0.5vw'} sx={{ marginX: '0.5vw' }}>
                  <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                    <Autocomplete
                      size='small'
                      sx={{ width: '15vw' }}
                      clearIcon
                      freeSolo
                      readOnly={this.state.drugSearchFor === 1 ? false : true}
                      options={this.state.genericList}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.generic_name}
                      value={this.state.searchedGenericName ? this.state.searchedGenericName : ""}
                      onChange={(e, value) => {
                        this.setState({
                          searchedGenericName: value
                        }, () => {
                          this.setState({
                            dosageStrength: this.state.searchedGenericName?.dosage_strength,
                            dosageType: this.state.searchedGenericName?.dosage_type,
                            morningQty: this.state.searchedGenericName?.latest_prescription?.morning,
                            afternoonQty: this.state.searchedGenericName?.latest_prescription?.afternoon,
                            eveningQty: this.state.searchedGenericName?.latest_prescription?.evening,
                            nightQty: this.state.searchedGenericName?.latest_prescription?.night,
                            selectedDays: this.state.searchedGenericName?.latest_prescription?.duration_mode,
                            selectedDuration: this.state.searchedGenericName?.latest_prescription?.duration,
                            selectedUsage: this.state.searchedGenericName?.latest_prescription?.instructions,
                            comments: this.state.searchedGenericName?.latest_prescription?.comments,
                          }, () => {
                            if ((this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'syrup')) {
                              this.setState({ checkMl: true })
                            } else if (this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'drops' || this.state.searchedGenericName?.dosage_type?.toLocaleLowerCase() === 'spray') {
                              this.setState({ checkOthers: true })
                            } else {
                              this.setState({
                                checkMl: false,
                                checkOthers: false
                                // prescription auto fetch so cmt for this ******//
                                // morningQty: '0',
                                // afternoonQty: '0', eveningQty: '0',
                                // nightQty: '0'
                              })
                            }
                          })
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        label='Select Generic Name *' />}
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageStrength}
                      InputProps={{ readOnly: true }}
                      label='Dosage Strength'
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageType}
                      InputProps={{ readOnly: true }}
                      label='Dosage Type'
                    />
                  </Box>
                </Box>
              </Box>
          }
          <Box component={'div'} mt={'0.5vw'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box component={'div'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>MAEN Dosage Quantity *</Typography>
                <FormControlLabel checked={this.state.checkMl} control={<Checkbox size='small' />}
                  onChange={(e) => {
                    this.setState({
                      checkMl: e.target.checked
                    }, () => {
                      if (!this.state.checkMl) {
                        this.setState({
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      } else if (this.state.checkMl) {
                        this.setState({
                          checkOthers: false,
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      }
                    })
                  }} label='ML' />
                <FormControlLabel checked={this.state.checkOthers} control={<Checkbox size='small' />}
                  onChange={(e) => {
                    this.setState({
                      checkOthers: e.target.checked
                    }, () => {
                      if (!this.state.checkOthers) {
                        this.setState({
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      } else if (this.state.checkOthers) {
                        this.setState({
                          checkMl: false,
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      }
                    })
                  }} label='Others' />
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'} mt={'0.5vw'}>
                <Typography mr={'1.8vw'} fontSize={'0.9vw'} fontWeight={600}>Morning</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.morningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.morningQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if (this.state.morningQty === selectedLabel) {
                        this.setState({ morningQty: "" });
                      } else {
                        this.setState({ morningQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ?
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'mrg_txt' }}
                    value={this.state.morningQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.DecimalNumberTwo(value) || value === '') {
                        this.setState({ morningQty: value })
                      }
                    }}
                  />
                  : null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography sx={{ mr: '1vw' }} fontSize={'0.9vw'} fontWeight={600}>Afternoon</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.afternoonQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.afternoonQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if (this.state.afternoonQty === selectedLabel) {
                        this.setState({ afternoonQty: "" });
                      } else {
                        this.setState({ afternoonQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ?
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'aft_txt' }}
                    value={this.state.afternoonQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.DecimalNumberTwo(value) || value === '') {
                        this.setState({ afternoonQty: value })
                      }
                    }}
                  />
                  : null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography mr={'2vw'} fontSize={'0.9vw'} fontWeight={600}>Evening</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.eveningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.eveningQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if (this.state.eveningQty === selectedLabel) {
                        this.setState({ eveningQty: "" });
                      } else {
                        this.setState({ eveningQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ?
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'eve_txt' }}
                    value={this.state.eveningQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.DecimalNumberTwo(value) || value === '') {
                        this.setState({ eveningQty: value })
                      }
                    }}
                  />
                  : null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography mr={'3vw'} fontSize={'0.9vw'} fontWeight={600}>Night</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.nightQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.nightQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if (this.state.nightQty === selectedLabel) {
                        this.setState({ nightQty: "" });
                      } else {
                        this.setState({ nightQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ?
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'ngt_txt' }}
                    value={this.state.nightQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.DecimalNumberTwo(value) || value === '') {
                        this.setState({ nightQty: value })
                      }
                    }}
                  />
                  : null}
              </Box>
            </Box>
            <Box component={'div'} pl={'0.5vw'}>
              <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginY={'0.5vw'} pr={"1vw"} pl={'0.8vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>Duration *</Typography>
                <FormControl size='small'>
                  <InputLabel>Select Duration</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    label='Select Duration'
                    value={this.state.selectedDays}
                    onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                  >
                    <MenuItem value={'1'}>Days</MenuItem>
                    <MenuItem value={'2'}>Weeks</MenuItem>
                    <MenuItem value={'3'}>Months</MenuItem>
                    <MenuItem value={'4'}>Years</MenuItem>
                    <MenuItem value={'5'}>Alternative Days</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box component={'div'} mt={'0.5vw'}>
                {(this.state.selectedDays === '1' || this.state.selectedDays === '5') ?
                  this.state.durationDays?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.label}
                      variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                      // color={'primary'}
                      clickable
                    />
                  )) : this.state.selectedDays === '2' ?
                    this.state.durationWeeks?.map((item, index) => (
                      <Chip
                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                        label={item.label}
                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                        key={index}
                        onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                        // color={'primary'}
                        clickable
                      />
                    )) : this.state.selectedDays === '3' ?
                      this.state.durationMonts?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                          // color={'primary'}
                          clickable
                        />
                      )) : this.state.durationYears?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                          // color={'primary'}
                          clickable
                        />
                      ))
                }
              </Box>
            </Box>
          </Box>
          <Box component={'div'} mt={'0.5vw'} display={'flex'} flexDirection={'column'}>
            {/* <FormControl size='small' sx={{ width: '28vw', marginLeft: '1.5vw' }}>
          <InputLabel>Select Usage *</InputLabel>
          <Select
            label='Select Usage'
            value={this.state.selectedUsage}
            onChange={(e) => this.setState({ selectedUsage: e.target.value })}
          >
            {this.state.prescriptionUsage?.length > 0 && this.state.prescriptionUsage?.map((item, index) => (
                <MenuItem value={item?.value} key={index}>
                  <Box component={'span'}>{item?.label}</Box>
                  <DeleteIcon color='primary'/>
                </MenuItem>
            ))}
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => { this.setState({ openAddNew: true }) }}>
                  <ListItemText primary={'Add New Usage'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Select>
        </FormControl> */}
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '28vw', marginLeft: '1.5vw' }}
              options={this.state.prescriptionUsage}
              getOptionLabel={(option) => typeof (option) === 'string' ? this.getOptions(option) : option.label}
              value={this.state.selectedUsage}
              onChange={(e, value) => this.setState({ selectedUsage: value })}
              renderInput={(params) => <TextField {...params} label={'Select Usage *'} />}
              renderOption={(props, item) => {
                return (
                  <Box component={'div'} display={'flex'} justifyContent={'space-between'} key={props["data-option-index"]}>
                    <Box component="li" {...props} width={'100%'}>
                      <Typography>{item?.label}</Typography>
                    </Box>
                    {item?.is_added ?
                      <Button className='Common_Btn_Min_Width'
                        onClick={() => this.deletePresctiptionUsageList(item)}
                      ><DeleteIcon color='error' /></Button> : null}
                  </Box>
                )
              }}
              PaperComponent={({ children }) => {
                return (
                  <Paper>
                    {children}
                    <Divider />
                    <Button
                      color="primary"
                      fullWidth
                      sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                      onMouseDown={(e) => {
                        this.setState({ openAddNew: true })
                      }}
                    >
                      Add Usage
                    </Button>
                  </Paper>
                )
              }}
            />
            <Box component={'div'}>
              <TextField
                size='small'
                className='eMed_DocNotes_TextFields'
                multiline={true}
                rows={3}
                label={t("Comments")}
                inputProps={{ maxLength: 250 }}
                value={this.state.comments}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length < 100) {
                    this.setState({ comments: e.target.value })
                  } else {
                    this.errorMessage('Allowed only 100 characters')
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"5.5vh"}>
          <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.ClearData(true) }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.addPrescriptionData() }}>{t("Add")}</Button>
        </Stack>
      </>
    )
  }

  // Tapper Med Screen Starts

  handleInputChange = debounce((value) => {
    this.setState({ newTapSearchDrug: value }, () => {
      if (value) {
        this.getPharmacyDrugSearchList(this.state.newTapSearchDrug)
      } else {
        this.onClearTapMedHandler()
      }
    })
  }, 500)

  handleExternalInputChange = debounce((value) => {
    this.setState({ newTapExeSearchDrug: value }, () => {
      if (value) {
        this.getExternalDrugSearchList(this.state.newTapExeSearchDrug, '')
      } else {
        this.onClearTapMedHandler()
      }
    })
  }, 500)

  getMedicFrequency = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_FREQUENCY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let data = response.data.data
            const uniqueArray = [...new Set(data)];
            this.setState({
              frequencyTemplateList: uniqueArray,
              frequency_list: response.data?.tapper_data ? response.data?.tapper_data : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  newCheckTapValidation = (tapperDetails) => {
    try {
      let message = null;
      let states = this.state
      let steroid = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? true : false
      if (states.newTapMedDuration === '') {
        message = 'Enter Duration'
      } else if (states.newTapMedUnit === '') {
        message = 'Enter Unit'
      }
      // else if (states.newTapMedReduceBy === '' && steroid) {
      //   message = 'Enter Reduce By'
      // } 
      else if (states.selectedTabCount === '') {
        message = 'select Tablet Count'
      } else if (!states.selMor && !states.selAft && !states.selEve && !states.selNig) {
        message = 'Select M-A-E-N Time'
      } else if (states.newTapMedQty === '') {
        message = 'Enter Quantity'
      } else if (states.newTapMedUsage === '') {
        message = 'Select Usage'
      } else if (states.newTapMedDrugListData?.length > 0) {
        states.newTapMedDrugListData?.forEach((element, index) => {
          if (this.state.newTapMedSelectedIndex !== index) {
            if (element?.start_date === tapperDetails?.start_date && element?.end_date === tapperDetails?.end_date) {
              message = 'Taper Details Already Exists'
            }
          }
        })
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearTapMedHandler = (FromClearBtn = false,clickExternal = false) => {
    try {
      this.setState({
        newTapMedListData: [],
        newTapSearchDrug: null,
        newTapMedDate: new Date(),
        newTapMedOpenDate: false,
        newTapMedDrugListData: [],
        newTapMedDuration: '',
        newTapDurationType: 'Day(s)',
        newTapMedUnit: "",
        newTapMedUnitType: '',
        newTapMedFrequency: '',
        newTapMedReduceBy: '',
        newTapMedFreqTablet: null,
        newTapMedQty: '',
        newTapMedUsage: '',
        newTapMedComments: '',
        newTapMedGenList: {},
        isNewTapEdit: false,
        newTapMedSelectedIndex: null,
        leftSelectedIndex: null,
        dupTapMedDetails: '{}',
        newLftTapEdit: false,
        newTapMedId: null,
        newTapMedIsFav: false,
        AddClicked: false,
        isChange: false,
        tapUsage: '',
        tapMorning: '',
        tapAfternoon: '',
        tapEvening: '',
        tapNight: '',
        selMor: false,
        selAft: false,
        selEve: false,
        selNig: false,
        selectedTabCount: '',
        TapperDrugList: [],
        tapSelectedDrug: '',
        selectedDrugIdTap: null,
        // External 
        newTapExeSearchDrug: '',
        newTapMedExeDrugList: [],
        newTapMedExeDosageStrength: '',
        newTapMedExeDosageType: '',
        newTapMedExeGenericName: '',
        tempSearch: '',
        ExternalIsFav: false,
        newTapMedExeFav: false,
        newTapMedFreqTablet: '',
        newTapMedExeUnitType: 'mg'
      }, () => {
        if (FromClearBtn) {
          // this.setState({ value: 'one' })
          this.props.ClearClickedonRightPrecs(true)
        }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onNewTappperMedAddHandler = () => {
    try {
      let states = this.state
      let startDate = new Date(states.newTapMedDate)
      let newTapStartDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      var duration = states.newTapMedDuration
      let frequency = ''; let reducedBy = ''; let dosageCountLabel = ''; let frequencyLabel = '';

      // if (states.tapperExternal) {
      frequency = ''
      reducedBy = states.newTapMedReduceBy ? states.newTapMedReduceBy : 0

      dosageCountLabel = states.newTapMedExeUnitType
      frequencyLabel = this.state.newTapMedFreqTablet
      // } else {
      //   frequency = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? "" : states.newTapMedFrequency
      //   reducedBy = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.newTapMedReduceBy : ""

      //   dosageCountLabel = (states.newTapMedGenList?.dosage_type).toUpperCase() === "STEROID TABLETS" ? 'Mg' : 'Drop(s)'
      //   frequencyLabel = dosageCountLabel === "Drop(s)" ? ((+frequency == 1) ? ' time a day' : ' times a day') : this.state.newTapMedFreqTablet
      // }

      if (states.newTapDurationType === "Week(s)") {
        duration = +(states.newTapMedDuration) * 7
      } else if (states.newTapDurationType === "Month(s)") {
        duration = +(states.newTapMedDuration) * 30
      }

      let date = new Date(startDate);
      date.setDate(date.getDate() + (+duration));

      let tapperDetails = {
        start_date: newTapStartDate,
        no_of_dosage: states.newTapMedUnit + " " + dosageCountLabel,
        duration: states.newTapMedDuration + " " + states.newTapDurationType,
        end_date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
        frequency: frequency + frequencyLabel,
        reducedBy: reducedBy,
        instructions: states.newTapMedUsage,
        unit: states.newTapMedUnit,
        unitType: dosageCountLabel,
        duration1: states.newTapMedDuration,
        durationType: states.newTapDurationType,
        usage: states.newTapMedUsage,
        // comments: states.newTapMedComments,
        quantity: states.newTapMedQty,
        morning: states.tapMorning,
        evening: states.tapEvening,
        afternoon: states.tapAfternoon,
        night: states.tapNight,
        selMor: states.selMor,
        selAft: states.selAft,
        selEve: states.selEve,
        selNig: states.selNig,
        tablet_count: states.selectedTabCount
      }
      // if (this.state.tapperExternal) {
      tapperDetails.freq = states.newTapMedFreqTablet
      // } else {
      //   tapperDetails.freq = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.newTapMedFreqTablet : states.newTapMedFrequency
      // }
      let chekNewTap = this.newCheckTapValidation(tapperDetails)
      if (chekNewTap === null) {
        if (this.state.isNewTapEdit) {
          this.state.newTapMedDrugListData[this.state.newTapMedSelectedIndex] = tapperDetails
        } else {
          this.state.newTapMedDrugListData?.push(tapperDetails)
        }
        this.setState({ newTapMedDrugListData: this.state.newTapMedDrugListData }, () => {
          let nextDate = new Date(date)
          nextDate.setDate(nextDate.getDate() + 1)
          if (frequency == 1) {
            frequency = frequency
          } else if (frequency == 12) {
            frequency = 10
          } else if (frequency == 10) {
            frequency = 6
          } else {
            frequency = +(frequency) - 1
          }
          this.setState({
            isNewTapEdit: false,
            newTapMedDate: nextDate,
            newTapMedUnit: +states.newTapMedReduceBy < +states.newTapMedUnit ? (+states.newTapMedUnit) - (+states.newTapMedReduceBy) : states.newTapMedUnit,
            newTapMedFrequency: frequency + "",
            newTapMedSelectedIndex: null,
            dupTapMedDetails: '{}',
            newTapMedExeUnitType: states.newTapMedExeUnitType ? states.newTapMedExeUnitType : 'mg'
          })
        })
      } else {
        this.errorMessage(chekNewTap)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onNewTapEditHandler = (item, index) => {
    try {
      let states = this.state
      this.setState({
        newTapMedDate: item?.start_date,
        newTapMedDuration: item?.duration1,
        newTapDurationType: item?.durationType,
        newTapMedUnit: item?.unit,
        newTapMedSelectedIndex: index,
        newTapMedReduceBy: item?.reducedBy,
        newTapMedUsage: item?.usage,
        // newTapMedComments: item?.comments,
        newTapMedQty: item?.quantity,
        selMor: item?.selMor,
        selAft: item?.selAft,
        selEve: item?.selEve,
        selNig: item?.selNig,
        tapMorning: item?.morning,
        tapAfternoon: item?.afternoon,
        tapEvening: item?.evening,
        tapNight: item?.night,
        selectedTabCount: item?.tablet_count,
        newTapMedExeUnitType: item?.unitType,
        newTapMedFreqTablet: item?.freq
      }, () => {
        // if (this.state.tapperExternal) {
        //   this.setState({
        //     newTapMedFreqTablet: item?.freq
        //   })
        // } else {
        //   this.setState({
        //     newTapMedFreqTablet: (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? item?.freq : '',
        //     newTapMedFrequency: (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? '' : item?.freq,
        //   })
        // }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTapperMedScreen = () => {
    try {
      let tablet = (this.state.newTapMedGenList?.dosage_type?.toUpperCase() === 'STEROID TABLETS') ? true : false
      let TapperUnits = tablet ? 'Mg' : 'Drops'
      return (
        <>
          <Box component={'div'} height={'55dvh'} overflow={'auto'} p={'0.5vw'}>
            <Box component={'div'} borderBottom={'1.5px solid lightgray'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                {this.state.tapperExternal ?
                  <Autocomplete
                    clearIcon
                    size='small'
                    sx={{ width: "20dvw", mt: '0.5dvw', mb: '0.5dvw' }}
                    freeSolo
                    options={this.state.newTapMedExeDrugList}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.drug_name}
                    value={this.state.newTapExeSearchDrug}
                    onChange={(e, value) => {
                      this.setState({ newTapExeSearchDrug: value }, () => {
                        if (this.state.newTapExeSearchDrug) {
                          this.getExternalDrugSearchList(this.state.newTapExeSearchDrug?.drug_name, '', true)
                        } else {
                          this.onClearTapMedHandler()
                        }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label='Search External Drug'
                      onChange={(e) => {
                        let value = e.target.value
                        this.setState({ tempSearch: value })
                        this.handleExternalInputChange(value)
                      }} />}
                  /> : <Autocomplete
                    clearIcon
                    size='small'
                    sx={{ width: "20dvw", mt: '0.5dvw' }}
                    options={this.state.TapperDrugList}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.drug_name}
                    value={this.state.newTapSearchDrug}
                    onChange={(e, value) => {
                      this.setState({ newTapSearchDrug: value }, () => {
                        if (this.state.newTapSearchDrug) {
                          this.getGenericNameList()
                        } else {
                          this.onClearTapMedHandler()
                        }
                      })
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      label='Search Drug'
                      onChange={(e) => {
                        let value = e.target.value
                        this.handleInputChange(value)
                      }}
                    />}
                  />}
                {this.state.is_pharmacy ? null : <Button variant='outlined'
                  sx={{ height: '2.5dvw', mt: this.state.tapperExternal ? '' : '0.5dvw' }}
                  onClick={() => this.setState({ tapperExternal: !this.state.tapperExternal, newTapMedExeUnitType: 'mg' }, () => {
                    this.onClearTapMedHandler(false,true)
                  })}
                >{this.state.tapperExternal ? 'pharmacy Drug' : 'External Drug'}</Button>}
                {/* <Typography fontSize={'0.9dvw'} fontWeight={600} ml={'0.5dvw'}>Available Qty:
                  <Typography component={'span'} fontSize={'0.9dvw'} fontWeight={600}>{this.state.newTapMedGenList?.quantity ? this.state.newTapMedGenList?.quantity : '0'}</Typography>
                </Typography> */}
              </Box>
              {this.state.tapperExternal ? null : <Box component={'div'} mt={'0.5dvw'} mb={'0.5dvw'}>
                {this.state.MedicationFav?.length > 0 ? this.state.MedicationFav?.map((item, index) => {
                  return (
                    <Chip
                      className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugIdTap && (item.name) === this.state.tapSelectedDrug) : (item === this.state.tapSelectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size="small"
                      label={item?.name}
                      key={index}
                      onClick={() => {
                        if (this.state.tapSelectedDrug === item?.name) {
                          this.onClearTapMedHandler()
                        } else {
                          this.setState({
                            selectedDrugIdTap: item?.id,
                            tapSelectedDrug: item?.name,
                            newTapSearchDrug: null,
                            TapperDrugList: []
                          }, () => this.getGenericNameList())
                        }
                      }}
                      variant={(item.name ? (item?.id === this.state.selectedDrugIdTap && (item.name) === this.state.tapSelectedDrug) : (item === this.state.tapSelectedDrug)) ? 'contained' : 'outlined'}
                      // color={'primary'}
                      clickable
                    />
                  )
                }) : null}
              </Box>}
            </Box>
            {this.state.tapperExternal ? null : Object?.keys(this.state.newTapMedGenList)?.length > 0 ?
              <Box component={'div'} borderBottom={'1.5px solid lightgray'} mt={'0.5dvw'}>
                <Typography fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>Drug Details</Typography>
                <Box component={'div'} display={'flex'} alignItems={'center'} m={'0.5vw'}>
                  <Box component={'img'} src={ImagePaths.Tablet.default} height={'1.5vw'} width={'1.5vw'} />
                  <Box component={'div'} ml={'1dvw'}>
                    <Typography>{this.state.newTapMedGenList?.drug_name ? this.state.newTapMedGenList?.drug_name : this.state.newTapMedGenList?.brand_name}</Typography>
                    <Typography>{`${this.state.newTapMedGenList?.generic_name} | ${this.state.newTapMedGenList?.dosage_type}`}</Typography>
                  </Box>
                  <Box component={'div'} ml={'1dvw'}>
                    <Typography>{this.state.newTapMedGenList?.dosage_strength}</Typography>
                    <Typography>Strength</Typography>
                  </Box>
                  <Box component={'div'} ml={'1dvw'}>
                    <Button className='Common_Btn_Min_Width'>
                      {this.state?.newTapMedGenList?.is_favorite ?
                        <Box component={'img'} src={ImagePaths.StarFill.default} height={'1.5vw'} width={'1.5vw'} />
                        :
                        <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                      }
                    </Button>
                  </Box>
                </Box>
              </Box> : null}
            {this.state.tapperExternal ?
              <Box component={'div'} mt={'0.5dvw'} mb={'0.5dvw'}>
                <TextField size='small' label={'Generic Name'} sx={{ mr: '0.5dvw', mb: '1dvw' }}
                  value={this.state.newTapMedExeGenericName}
                  onChange={(e) => this.setState({ newTapMedExeGenericName: e.target.value })} />
                <TextField size='small' label={'Dosage Strength'}
                  value={this.state.newTapMedExeDosageStrength}
                  onChange={(e) => {
                    if (CommonValidation.numberOnly(e.target.value) && +e.target.value <= 5000 || e.target.value === '') {
                      this.setState({ newTapMedExeDosageStrength: e.target.value }, () => {
                        let unit = this.state.newTapMedExeDosageStrength ? this.state.newTapMedExeDosageStrength?.match(/\d+/) : this.state.newTapMedExeDosageStrength
                        this.setState({ newTapMedUnit: unit })
                      })
                    }
                  }} />
                <TextField size='small' label={'Dosage Type'}
                  value={this.state.newTapMedExeDosageType}
                  onChange={(e) => {
                    this.setState({ newTapMedExeDosageType: e.target.value })
                  }} />
              </Box>
              : null}
            <Box m={'0.5dvw 0dvw'}><Typography fontSize={'0.9dvw'} fontWeight={600}>Taper Details</Typography></Box>
            <Box component={'div'} mt={'0.5dvw'}>
              <Typography fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>Tablet Count *</Typography>
              <Box component={'div'}>
                {Number_Count?.map((item, index) => {
                  return (
                    <Chip
                      disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                      className='eMed_chip'
                      label={item}
                      key={index}
                      onClick={() => {
                        this.setState({ selectedTabCount: item }, () => {
                          this.setState({
                            tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                            tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                            tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                            tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                          })
                        })
                      }}
                      variant={item !== this.state.selectedTabCount ? 'outlined' : 'contained'}
                      color={'primary'}
                      clickable
                    />
                  )
                })}
                <TextField
                  disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                  autoComplete='off'
                  size='small'
                  value={this.state.selectedTabCount}
                  sx={{ width: '5dvw' }}
                  onChange={(e) => {
                    let value = e.target.value
                    if (CommonValidation.DecimalNumberTwo(value) || value == '') {
                      this.setState({ selectedTabCount: value }, () => {
                        this.setState({
                          tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                          tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                          tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                          tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                        })
                      })
                    }
                  }}
                />
              </Box>
              <Box component={'div'} mt={'0.5dvw'}>
                <Typography fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>M-A-E-N *</Typography>
                <Box component={'div'} mt={'0.5dvw'} mb={'0.5dvw'}>
                  <Button
                    disabled={((this.state.selectedTabCount === '')) ? true : false}
                    variant={this.state.selMor ? 'contained' : 'outlined'} sx={{ width: '6dvw', mr: "0.5dvw", textTransform: 'capitalize' }}
                    onClick={() => {
                      this.setState({ selMor: !this.state.selMor }, () => {
                        if (this.state.selMor) {
                          this.setState({
                            tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                            tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                            tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                            tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                          })
                        } else {
                          this.setState({ tapMorning: '' })
                        }
                      })
                    }}>Morning</Button>
                  <Button
                    disabled={((this.state.selectedTabCount === '')) ? true : false}
                    variant={this.state.selAft ? 'contained' : 'outlined'} sx={{ width: '6dvw', mr: "0.5dvw", textTransform: 'capitalize' }}
                    onClick={() => {
                      this.setState({ selAft: !this.state.selAft }, () => {
                        if (this.state.selAft) {
                          this.setState({
                            tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                            tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                            tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                            tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                          })
                        } else {
                          this.setState({ tapAfternoon: '' })
                        }
                      })
                    }}>Afternoon</Button>
                  <Button
                    disabled={((this.state.selectedTabCount === '')) ? true : false}
                    variant={this.state.selEve ? 'contained' : 'outlined'} sx={{ width: '6dvw', mr: "0.5dvw", textTransform: 'capitalize' }}
                    onClick={() => {
                      this.setState({ selEve: !this.state.selEve }, () => {
                        if (this.state.selEve) {
                          this.setState({
                            tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                            tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                            tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                            tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                          })
                        } else {
                          this.setState({ tapEvening: '' })
                        }
                      })
                    }}>Evening</Button>
                  <Button
                    disabled={((this.state.selectedTabCount === '')) ? true : false}
                    variant={this.state.selNig ? 'contained' : 'outlined'} sx={{ width: '6dvw', textTransform: 'capitalize' }}
                    onClick={() => {
                      this.setState({ selNig: !this.state.selNig }, () => {
                        if (this.state.selNig) {
                          this.setState({
                            tapMorning: this.state.selMor ? this.state.selectedTabCount : '',
                            tapAfternoon: this.state.selAft ? this.state.selectedTabCount : '',
                            tapEvening: this.state.selEve ? this.state.selectedTabCount : '',
                            tapNight: this.state.selNig ? this.state.selectedTabCount : ''
                          })
                        } else {
                          this.setState({ tapNight: '' })
                        }
                      })
                    }}>Night</Button>
                </Box>
                <Box component={'div'} mt={'1dvw'} display={'flex'}>
                  <Box component={'div'} display={'flex'}>
                    <Box component={'div'} mr={'1dvw'}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.newTapMedOpenDate}
                          onOpen={() => { this.setState({ newTapMedOpenDate: true }) }}
                          onClose={() => { this.setState({ newTapMedOpenDate: false }) }}
                          value={this.state.newTapMedDate}
                          inputFormat='DD-MM-YYYY'
                          label={"Select Date"}
                          disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                          minDate={new Date()}
                          onChange={(value) => { this.setState({ newTapMedDate: value }) }}
                          renderInput={(params) => <TextField size='small' {...params}
                            sx={{ width: '14dvw' }}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={() => { this.setState({ newTapMedOpenDate: true }) }}
                          />}
                        />
                      </LocalizationProvider>
                    </Box>
                    <TextField
                      disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                      autoComplete='off'
                      sx={{ width: '8dvw' }}
                      size='small'
                      value={this.state.newTapMedDuration}
                      onChange={(e) => {
                        let value = e.target.value
                        if (CommonValidation.numberOnly(value) || value === '') {
                          this.setState({ newTapMedDuration: value })
                        }
                      }}
                      label={'Duration *'}
                    />
                    <Select
                      disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                      size='small'
                      sx={{ width: '7vw' }}
                      value={this.state.newTapDurationType}
                      onChange={(e) => this.setState({ newTapDurationType: e.target.value, tapDuration: '' })}
                    >
                      <MenuItem value="Day(s)">Days</MenuItem>
                      <MenuItem value="Week(s)">Weeks</MenuItem>
                      <MenuItem value="Month(s)">Months</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box component={'div'} display={'flex'} mt={'1dvw'}>
                  <Box component={'div'} mr={'1dvw'}>
                    <TextField
                      disabled={true}
                      autoComplete='off'
                      sx={{ width: '8dvw' }}
                      size='small'
                      value={this.state.newTapMedUnit}
                      onChange={(e) => {
                        let value = e.target.value
                        if (CommonValidation.numberOnly(value) || value === '') {
                          this.setState({ newTapMedUnit: value })
                        }
                      }}
                      label={'Unit *'}
                    />
                    {/* {this.state.tapperExternal ? */}
                    <FormControl size='small' sx={{ width: '7vw' }}>
                      <Select
                        disabled={this.state.newTapMedDrugListData?.length === 0 ? false : true}
                        value={this.state.newTapMedExeUnitType}
                        onChange={(e) => {
                          this.setState({ newTapMedExeUnitType: e.target.value })
                        }}
                      >
                        {UNIT_LIST?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {/* : <TextField
                        disabled={true}
                        autoComplete='off'
                        sx={{ width: '7dvw' }}
                        size='small'
                        value={TapperUnits}
                        InputProps={{ readOnly: true }}
                      />} */}
                  </Box>
                  {/* {this.state.tapperExternal ? */}
                  <TextField
                    // disabled={this.state.tapperExternal ? false : true}
                    autoComplete='off'
                    size='small'
                    sx={{ width: '14dvw' }}
                    value={this.state.newTapMedReduceBy}
                    onChange={(e) => {
                      if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                        this.setState({ newTapMedReduceBy: e.target.value })
                      }
                    }}
                    label='Reduce By'
                  />
                  {/* : tablet ?
                      <TextField
                        disabled={Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                        autoComplete='off'
                        size='small'
                        sx={{ width: '14dvw' }}
                        value={this.state.newTapMedReduceBy}
                        onChange={(e) => {
                          if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                            this.setState({ newTapMedReduceBy: e.target.value })
                          }
                        }}
                        label='Reduce By *'
                      /> : null} */}
                </Box>
                <Box component={'div'} mt={'1dvw'} display={'flex'}>
                  {/* {this.state.tapperExternal ? */}
                  <Autocomplete
                    // disabled={this.state.tapperExternal ? false : true}
                    size='small'
                    sx={{ width: '15vw', mr: "1vw" }}
                    clearIcon
                    freeSolo
                    options={this.state.frequency_list}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.frequency_name}
                    value={this.state.newTapMedFreqTablet}
                    onChange={(e, value) => {
                      this.setState({ newTapMedFreqTablet: value })
                    }}
                    renderInput={(params) => <TextField {...params}
                      value={this.state.newTapMedFreqTablet}
                      onChange={(e) => {
                        this.setState({ newTapMedFreqTablet: e.target.value })
                      }}
                      inputProps={{ ...params.inputProps, maxLength: 50 }}
                      label='Frequency' />}
                  />
                  {/* : tablet ? <Autocomplete
                      disabled={Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                      size='small'
                      sx={{ width: '15vw', mr: "1vw" }}
                      clearIcon
                      freeSolo
                      options={this.state.frequency_list}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.frequency_name}
                      value={this.state.newTapMedFreqTablet}
                      onChange={(e, value) => {
                        this.setState({ newTapMedFreqTablet: value })
                      }}
                      renderInput={(params) => <TextField {...params}
                        value={this.state.newTapMedFreqTablet}
                        onChange={(e) => {
                          this.setState({ newTapMedFreqTablet: e.target.value })
                        }}
                        inputProps={{ ...params.inputProps, maxLength: 50 }}
                        label='Frequency' />}
                    />
                      : <FormControl size='small' sx={{ width: '15vw', mr: "1vw" }}>
                        <InputLabel>Select Frequency</InputLabel>
                        <Select
                          disabled={Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                          label='Select Frequency'
                          value={this.state.newTapMedFrequency}
                          onChange={(e) => {
                            this.setState({ newTapMedFrequency: e.target.value })
                          }}
                        >
                          <MenuItem value="">Select Frequency</MenuItem>
                          <MenuItem value={"1"}>1 time a day</MenuItem>
                          <MenuItem value={"2"}>2 time a day</MenuItem>
                          <MenuItem value={"3"}>3 time a day</MenuItem>
                          <MenuItem value={"4"}>4 time a day</MenuItem>
                          <MenuItem value={"5"}>5 time a day</MenuItem>
                          <MenuItem value={"6"}>6 time a day</MenuItem>
                          <MenuItem value={"10"}>10 time a day</MenuItem>
                          <MenuItem value={"12"}>12 time a day</MenuItem>
                        </Select>
                      </FormControl>} */}
                  <TextField
                    disabled={this.state.tapperExternal ? false : Object.keys(this.state.newTapMedGenList)?.length === 0 ? true : false}
                    autoComplete='off'
                    sx={{ width: '14dvw' }}
                    size='small'
                    value={this.state.newTapMedQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if (CommonValidation.numberOnly(value) || value === '') {
                        this.setState({ newTapMedQty: value })
                      }
                    }}
                    label={'Quantity *'}
                  />
                </Box>
                <Box component={'div'} mt={'0.8dvw'} display={'flex'}>
                  {/* <FormControl size='small' sx={{ width: '11vw' }}>
                    <InputLabel>Select Usage *</InputLabel>
                    <Select
                      label='Select Usage *'
                      value={this.state.newTapMedUsage}
                      onChange={(e) => this.setState({ newTapMedUsage: e.target.value })}
                    >
                      {this.state.prescriptionUsage?.length > 0 && this.state.prescriptionUsage?.map((item, index) => (
                        <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Autocomplete
                    clearIcon
                    size='small'
                    sx={{ width: '11vw' }}
                    options={this.state.prescriptionUsage}
                    getOptionLabel={(option) => typeof (option) === 'string' ? this.getOptions(option) : option.label}
                    value={this.state.newTapMedUsage}
                    onChange={(e, value) => this.setState({ newTapMedUsage: value?.label })}
                    renderInput={(params) => <TextField {...params} label={'Select Usage *'} />}
                    renderOption={(props, item) => {
                      return (
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} key={props["data-option-index"]}>
                          <Box component="li" {...props} width={'100%'}>
                            <Typography>{item?.label}</Typography>
                          </Box>
                          {item?.is_added ?
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => this.deletePresctiptionUsageList(item)}
                            ><DeleteIcon color='error' /></Button> : null}
                        </Box>
                      )
                    }}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper>
                          {children}
                          <Divider />
                          <Button
                            color="primary"
                            fullWidth
                            sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                            onMouseDown={(e) => {
                              this.setState({ openAddNew: true })
                            }}
                          >
                            Add Usage
                          </Button>
                        </Paper>
                      )
                    }}
                  />
                  <TextField
                    autoComplete='off'
                    sx={{ width: '13dvw', marginLeft: '1vw' }}
                    size='small'
                    value={this.state.newTapMedComments}
                    onChange={(e) => {
                      if (e.target.value?.length <= 100) {
                        this.setState({ newTapMedComments: e.target.value })
                      } else {
                        this.errorMessage('Allow only 100 characters')
                      }
                    }}
                    label={'Comments'}
                  />
                  <Button variant='contained' sx={{ ml: '0.5dvw', textTransform: 'capitalize' }}
                    onClick={() => this.onNewTappperMedAddHandler()}
                  >Add</Button>
                </Box>
              </Box>
            </Box>
            <Box component={'div'} border={'1px solid lightgray'} mt={'0.5dvw'}>
              {this.renderTapperMedTable()}
            </Box>
          </Box>
          <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"5.5dvh"}>
            <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.onClearTapMedHandler(true) }}>{("Clear")}</Button>
            <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.addPrescriptionData() }}>{("Add")}</Button>
          </Stack>
        </>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTapperMedTable = () => {
    try {
      return (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
                <Typography fontWeight={600} fontSize={'0.8dvw'}>Days</Typography>
              </TableCell>
              <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                <Typography fontWeight={600} fontSize={'0.8dvw'}>Unit</Typography>
              </TableCell>
              <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                <Typography fontWeight={600} fontSize={'0.8dvw'}>Frequency</Typography>
              </TableCell>
              <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                <Typography fontWeight={600} fontSize={'0.8dvw'}>Reduced By</Typography>
              </TableCell>
              <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
                <Typography fontWeight={600} fontSize={'0.8dvw'}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.newTapMedDrugListData?.length > 0 ? this.state.newTapMedDrugListData?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography fontSize={'0.8dvw'}>{`${formatDate(item?.start_date)} - ${formatDate(item?.end_date)}`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'0.8dvw'}>{item?.no_of_dosage ? item?.no_of_dosage : '-'}</Typography>
                    <Typography fontSize={'0.8dvw'}>{item?.instructions ? item?.instructions : ''}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'0.8dvw'}>{`${item?.morning ? item?.morning : '0'} - ${item?.afternoon ? item?.afternoon : '0'} - ${item?.evening ? item?.evening : '0'} - ${item?.night ? item?.night : '0'}`}</Typography>
                    <Typography fontSize={'0.8dvw'}>{item?.frequency ? item?.frequency : '-'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'0.8dvw'}>{item?.reducedBy ? item?.reducedBy : '-'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box component={'div'} display={'flex'}>
                      <Button className='Common_Btn_Min_Width'
                        onClick={() => {
                          this.setState({
                            isNewTapEdit: true,
                          }, () => { this.onNewTapEditHandler(item, index) })
                        }}
                      >
                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.3dvw'} width={'1.3dvw'} />
                      </Button>
                      <Button className='Common_Btn_Min_Width' disabled={this.state.isNewTapEdit}
                        onClick={() => {
                          this.state.newTapMedDrugListData?.splice(index, 1)
                          this.setState({ newTapMedDrugListData: this.state.newTapMedDrugListData })
                        }}
                      >
                        <Box component={'img'} src={ImagePaths.Delete.default} height={'1.3dvw'} width={'1.3dvw'} />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            }) : <TableRow><TableCell align='center' colSpan={6}>No Records To Be Shown</TableCell></TableRow>}
          </TableBody>
        </Table>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    const { t } = this.props
    console.log(this.state.prescripTblData);
    return (
      <Box>
        {/* <Typography className='eMed_refdoc_title'>{t("Prescription")}</Typography> */}
        <Tabs
          value={this.state.value}
          onChange={(e, newValue) => {
            this.setState({ value: newValue }, () => {
              if (this.state.value === 'two') {
                this.getFreqDrugList()
                this.getMedicFrequency()
              }
              this.ClearData();
              this.onClearTapMedHandler()
            })
          }}
          sx={{ backgroundColor: Colors.DataTblHeaderbg }}
        // className='eMed_bill_Tab'
        >
          <Tab value={'one'} label='Prescription' className='eMed_tab_header' />
          <Tab value={'two'} label='Taper Med' className='eMed_tab_header' />
          {(this.state.tapperExternal || this.state.ExternalDrugAdd) ? null : <Box p={'0.5vw'}>
            <FormControl size='small' sx={{ width: '15vw' }}>
              <InputLabel>Select Pharmacy *</InputLabel>
              <Select
                label='select Pharmacy'
                value={this.state.selectedPharmacy}
                disabled={this.state.prescripTblData?.length > 0}
                // disabled = {this.state.prescripTblData?.length > 0 && this.state.prescripTblData[0]?.pharmacy_id === this.state.selectedPharmacy}
                onChange={(e) => this.setState({ selectedPharmacy: e.target.value },
                  () => {
                    this.getFreqDrugList();
                    this.ClearData();
                    this.onClearTapMedHandler()
                  })}
              >
                {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                  <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>}
        </Tabs>
        {this.state.value === 'one' ? this.renderPrescriptionScreen() : this.renderTapperMedScreen()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Allergy")} ${this.state.SelectedAllergy ? this.state.SelectedAllergy : "-"} From Frequent List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteAllergy.bind(this)}
        /> : null}
        {this.renderNewUsagePopup()}
      </Box>
    )
  }
}
export default withTranslation()(DocPrecriptionRight)
