import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { CommonGridToolBarWithFilterText } from "../../../../Components/Common Components/CommonComponents";
// import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { AmountFormat, formatDate } from "../../../../Components/CommonFunctions/CommonFunctions";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { CurrencySymbol, OPTICAL_CRM_API_MODE } from '../../../../Utility/Constants';
import { sha256, sha224 } from 'js-sha256';
import { localSetItem } from '../../../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';

const BillTypeList = [
    { label: "All", value: "" },
    { label: "CASH", value: "CASH" },
    { label: "UPI", value: "UPI" },
    { label: "CARD", value: "CARD" }
]

class OpticalCRMsales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            OpticalCrmData: [],
            PatientUHID: "",
            PaymentType: "",
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        }
    }

    componentDidMount() {
        this.getCustomerDetails()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getCustomerDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.OPTICAL_CRM_CUS_DETAILS)
                .then((response) => {
                    if (response.data.status === "success") {
                        localSetItem("OPTICAL_CRM_API_TOKEN", sha256(response.data.data?.customer_number + response.data.data?.api_key))
                        var data = {
                            "mode": OPTICAL_CRM_API_MODE,
                            "customerNumber": response.data.data?.customer_number,
                            "branchIDs": response.data.data?.branch_id,
                            "cID": response.data.crm_patient_id,
                            "orderDate": this.state.fromDate === "" ? "" : (this.state.fromDate + "," + this.state.toDate),
                            "orderNo": "",
                            "memberShipID": this.state.PatientUHID,
                            "limit": 100,
                            "offset": 0,
                            "paymentType": this.state.PaymentType,
                            "branchKey": response.data.data?.branch_key ? response.data.data?.branch_key : ""
                        }
                        RestAPIService.OPTICAL_CRM_POST(data)
                            .then((response) => {
                                if (response.data.status == "Success" && response.data.data != "No Records Found") {
                                    var result = Object.keys(response.data.data).map((key) => [response.data.data[key]][0]);
                                    this.setState({
                                        OpticalCrmData: result,
                                        FilteredFromDate: this.state.fromDate,
                                        FilteredToDate: this.state.toDate,
                                    },()=>{this.LoaderFunction(false)})
                                }
                                else {
                                    this.setState({
                                        OpticalCrmData: [],
                                    },()=>{this.LoaderFunction(false)})
                                }
                            }).catch(error => {
                                this.LoaderFunction(false)
                                if (error?.response?.data?.status === "fail") {
                                    this.errorMessage(error.response.data.message)
                                } else {
                                    this.errorMessage(error.message)
                                }
                            })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    handleCheckBox = (e, key) => {
        let states = this.state
        states[key] = e.target.checked
        this.setState({ states })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 15 }}
                            size='small'
                            label={t("PatientUHID")}
                            name="PatientUHID"
                            value={this.state.PatientUHID}
                            ma
                            onChange={(e) => {
                                if (e.target.value === "" || CommonValidation.ALPHA_NUMARIC(e.target.value)) {
                                    this.setState({ PatientUHID: e.target.value.toUpperCase() })
                                }
                            }}
                        />
                    </Box>
                    <Box component={"div"}>
                        <FormControl size='small' className='eMed_rts_dropdown'>
                            <InputLabel id="demo-simple-select-label">{t('PaymentType')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.PaymentType}
                                label={t('PaymentType')}
                                onChange={(e) => {
                                    this.setState({
                                        PaymentType: e.target.value
                                    })
                                }}
                            >
                                {BillTypeList.map((item) => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                PatientUHID: "",
                                PaymentType: "",
                            })
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getCustomerDetails()
                            this.closeFilter()
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
          </Box>
    
        )
      }
    render() {
        this.state.OpticalCrmData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("BillNo"), flex: 0.05, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "customerName", headerName: t("CustomerName"), flex: 0.13, headerAlign: "center", align: "left",
                renderCell: (params) => (<Box component={'div'} display={'flex'} flexDirection={'column'}>
                    <Typography>{params?.row?.customerDetails.customerName ? params?.row?.customerDetails.customerName : "-"}</Typography>
                    <Typography fontSize={'0.8vw'}>{params?.row?.customerDetails.memberShipID ? params?.row?.customerDetails.memberShipID : "-"}</Typography>
                </Box>)
            },
            {
                field: "billDate", headerName: t("BillDate"), flex: 0.125, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.billDate ? formatDate(params?.row?.billDate) : "-"}</Box>)
            },
            {
                field: "orderStatus", headerName: t("OrderStatus"), flex: 0.125, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.orderStatus ? params?.row?.orderStatus : "-"}</Box>)
            },
            {
                field: "deliveryDate", headerName: t("DeliveryDate"), flex: 0.125, headerAlign: "center", align: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.deliveryDate ? formatDate(params?.row?.deliveryDate) : "-"}</Box>)
            },
            {
                field: "totalSalesAmount", headerName: `${t("BillAmount")} (${CurrencySymbol})`, flex: 0.125, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.totalSalesAmount ? AmountFormat(params?.row?.totalSalesAmount)?.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            },
            {
                field: "discountAmount", headerName: `${t("DiscountAmount")} (${CurrencySymbol})`, flex: 0.125, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discountAmount ? AmountFormat(params?.row?.discountAmount)?.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            },
            {
                field: "totalAmount", headerName: `${t("FinalAmount")} (${CurrencySymbol})`, flex: 0.125, type: "number",
                renderCell: (params) => (
                    <Box component={'div'} display={'flex'} flexDirection={'column'}>
                        <Typography textAlign={"right"}>{params?.row?.totalAmount ? AmountFormat(params?.row?.totalAmount).replace(`${CurrencySymbol}`, "") : "-"}</Typography>
                        <Typography textAlign={"right"} fontSize={'0.8vw'}>{params?.row?.paymentDetails[0].paymentName ? params?.row?.paymentDetails[0].paymentName : "-"}</Typography>
                    </Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {/* {AmountsCard(t("TotalAmount"), this.state.refDoctorAmount)}
                        {AmountsCard(t("TotalPatients"), this.state.refDoctorPatientCount, true)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.OpticalCrmData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(OpticalCRMsales)