import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../Components/Common Components/CommonComponents';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../Utility/ImagePaths';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import ApprovalFilters from './ApprovalFilters';
import { DateTime } from 'luxon';
import { localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import { AmountFormat, formatDate, formatDateAndTimeMeth2 } from '../../Components/CommonFunctions/CommonFunctions';

class FinalBillRevertApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 30,
            ConcessionApprovalData: [],
            FilterDatas: {
                "FromDate": null,
                "ToDate": null,
                "DateIndex": 0,
                "PatientName": "",
                "AccNo": "",
                "MobileNumber": "",
                "Status": { value: "Pending", label: "Pending" },
            }
        }
    }
    componentDidMount() {
        this.GetRevertRequests()
        if (this.props.history?.location?.state?.message) {
            this.successMessage(this.props.history?.location?.state?.message)
            this.props.history.push({ state: {} })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetRevertRequests() {
        try {
            const LoggedData = JSON.parse(localGetItem("loggedInUserInfo"));
            const user_id = JSON.parse(localGetItem("login_user_id")); 
            let url = `${Serviceurls.REVERT_FINAL_BILL}?from_date=${this.state.FilterDatas?.FromDate || ""}&to_date=${this.state.FilterDatas?.ToDate || ""}&request_status=${this.state.FilterDatas?.Status?.value ? this.state.FilterDatas?.Status?.value : ""}&patient_name=${this.state.FilterDatas?.PatientName}&uhid=${this.state.FilterDatas?.AccNo}&mobile_number=${this.state.FilterDatas?.MobileNumber}`;
            if(LoggedData?.is_user && user_id){
                url = url + `&login_user_id=${user_id}`
            }
            this.LoaderFunction(true)
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ConcessionApprovalData: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                // this.successMessage(message)
                // this.GetBillList()
            }
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetRevertRequests() })
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    PostApprovalData = (data, status = "") => {
        try {
            var data = {
                'patient_id': data?.patient_id ? data?.patient_id : null,
                'ip_admission_id': data?.ip_admission_id ? data?.ip_admission_id : null,
                'request_date': data?.request_date ? data?.request_date : "",
                'status': status,
                'remarks': data?.remarks ? data?.remarks : "",
                'id' : data?.id
            }
            RestAPIService.create(data, Serviceurls.REVERT_FINAL_BILL).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.props.triggerCountsApi()
                        if(status === "Approved"){
                            this.revertFinalBill(data)
                        }else{
                            this.GetRevertRequests()
                        }
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    revertFinalBill = (data) => {
        try {
            var data = {
                'ip_admission_id': data?.ip_admission_id ? data?.ip_admission_id : null,
            }
            RestAPIService.create(data, Serviceurls.FINAL_BILL_REVERT_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.GetRevertRequests()
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas?.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas?.ToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        this.state.ConcessionApprovalData.forEach((element, index) => element.sno = index + 1)
        let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.25,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showTag = {false} disablePhoto={true} />)
            },
            {
                field: "request_date", headerName: t("Date & Time"), flex: 0.25,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.request_date ? formatDateAndTimeMeth2(params?.row?.request_date) : "-"}</Box>)
            },
            // {
            //     field: "invoice_number", headerName: t("Bill No"), flex: 0.3,
            //     renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? params?.row?.invoice_number?.length > 30 ?
            //         <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.invoice_number : "-"}</Box>)
            // },
            // {
            //     field: "bill_amount", headerName: t("Bill Amount"), flex: 0.15, type: "number",
            //     renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.bill_amount ? params?.row?.bill_amount : 0)}</Box>)
            // }, commented becoz of this data is not required for reverting final bill by naveen surya
            {
                field: "remarks", headerName: t("Reason"), flex: 0.35,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.remarks ? params?.row?.remarks?.length > 35 ?
                    <Tooltip placement='top' title={params?.row?.remarks} arrow><div>{params?.row?.remarks.slice(0, 35) + "..."}</div></Tooltip> : params?.row?.remarks : "-"}</Box>)
            },
            {
                field: "user_name", headerName: t("Username"), flex: 0.35,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.user_name ? params?.row?.user_name?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.user_name} arrow><div>{params?.row?.user_name.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.user_name : "-"}</Box>)
            },
            {
                field: "status", headerName: t("Status"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography sx={{ color: params?.row?.status === "Approved" ? "green" : (params?.row?.status === "Pending" ? "orange" : "red"), fontWeight: 600 }}>{params?.row?.status ? params?.row?.status : ""}</Typography>
                </Box>)
            },
            {
                field: "uhid", headerName: t("Action"), flex: 0.15, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>

                    {!LoggedData?.is_user ?
                        <>
                            <IconButton
                                disabled={params?.row?.status !== "Pending"}
                                onClick={() => {
                                    this.PostApprovalData(params?.row, "Approved")
                                }}
                                id="emed_CalenderClose_Icon">
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={params?.row?.status === "Pending" ? ImagePaths.ApproveTick.default : ImagePaths.ApproveDisabled.default} alt='close' />

                            </IconButton>
                        </> : null}
                    <IconButton
                        disabled={params?.row?.status !== "Pending"}
                        onClick={() => {
                            this.PostApprovalData(params?.row, "Cancelled")
                        }}
                        id="emed_CalenderClose_Icon">
                        <img style={{ width: '1.5vw', height: '1.5vw' }} src={params?.row?.status === "Pending" ? ImagePaths.CancelEnable.default : ImagePaths.CancellDisable.default} alt='close' />

                    </IconButton>
                </Box>)
            },
        ]
        return (
            <Box>
                <Box component={"div"}>
                    <Box component={"div"} className='eMed_Action_Container' sx={{ width: "100%", marginTop: "1vw" }}>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table' sx={{ height: '70vh', width: '100%', overflow: 'auto' }}>
                    <DataGrid
                        rows={this.state.ConcessionApprovalData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    user_name: (LoggedData?.is_user ? false : true),
                                }
                            }
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        rowHeight={60}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <ApprovalFilters FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>
            </Box>
        )
    }
}
export default withTranslation()(FinalBillRevertApprovals)
