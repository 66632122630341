import React, { Component } from 'react';
import './Config.css';
import {
  Button,
  Stack,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc, AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';

class TemplateConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      SearchText: "",
      testGroupDataList: [],
      testConfigData: [],
      TempName: "",
      SelectedTestName: null,
      SelectedTestGrp: null,
      TempSplitList: [],
      TempDataList: [],
      SelectedTempData: {},
      disableBtn: false,
      testAmt: 0,
      testCount: 0,
      tempDiscount: "",
      totalTestAmt: 0,
      TempId: '',
    }
  }

  componentDidMount() {
    this.getTemplateConfigData()
    this.getTestData()
    this.getTestGroupData()
  }

  getTestGroupData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              testGroupDataList: response.data.data
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getTemplateConfigData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.LAB_TEMPLATE_CONFIGURATION)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              TempDataList: response.data.data
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          }, ()=>{ this.LoaderFunction(false) })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  getTestData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?list=all")
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              testConfigData: response.data.data
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: "",
    })
  }

  postPackageConfigData = () => {
    try {
      var states = this.state
      let TestIds = []
      let GrorpIds = []
      this.state.TempSplitList.forEach((item) => {
        if (item?.lab_test_details) {
          GrorpIds.push(item.id ? item.id : item.grp_id ? item.grp_id : null)
        } else {
          TestIds.push(item?.id ? item?.id : item?.test_id ? item?.test_id : null)
        }
      })
      var data = {
        "template_id": states.TempId ? states.TempId : '',
        "template_name": CommonValidation.removeSpace(states.TempName),
        "total_items": states.testCount ? +states.testCount : 0,
        "total_amt": states.testAmt ? +states.testAmt : 0,
        "discount_amt": states.tempDiscount ? +states.tempDiscount : 0,
        "net_amt": states.totalTestAmt ? states.totalTestAmt : 0,
        "lab_test_list": TestIds,
        "lab_group_list": GrorpIds,
      }
      if (Object.keys(this.state.SelectedTempData).length > 0) {
        data['template_id'] = this.state.SelectedTempData?.template_id
      }
      this.setState({disableBtn: true})
      RestAPIService.create(data, Serviceurls.LAB_TEMPLATE_CONFIGURATION)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.clearValue()
              this.getTemplateConfigData()
              this.setState({disableBtn: false})
            })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message,
            disableBtn: false,
          })
        })
    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message,
        disableBtn: false,
      })
    }
  }

  DelectPackage = () => {
    try {
      RestAPIService.delete(Serviceurls.LAB_TEMPLATE_CONFIGURATION + "?template_id=" + this.state.SelectedTempData?.template_id)
        .then(response => {
          if (response.data.status == "success") {
            this.setState({
              successMsg: true,
              successMsgText: response.data.message
            }, () => {
              this.clearValue()
              this.getTemplateConfigData()
            })
          }
        }).catch((e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.response.data.message
          })
        }))

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  GetIndividualPackageData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.LAB_TEMPLATE_CONFIGURATION + "?template_id=" + this.state.SelectedTempData?.template_id)
        .then(response => {
          if (response.data.status === "success") {
            let GrpList = response.data.data[0]?.group_details ? response.data.data[0]?.group_details : []
            let TestList = response.data.data[0]?.test_details ? response.data.data[0]?.test_details : []
            this.setState({
              TempName: response.data.data[0]?.template_name,
              TempId: response.data.data[0]?.template_id,
              totalTestAmt: response.data.data[0]?.net_amt,
              tempDiscount: response.data.data[0]?.discount_amt,
              testAmt: response.data.data[0]?.total_amt,
              testCount: response.data.data[0]?.total_items,
              TempSplitList: [...GrpList, ...TestList]
            }, ()=>{ this.LoaderFunction(false) })
          }
        }).catch(e => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          }, ()=>{ this.LoaderFunction(false) })
        })

    } catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e.message
      })
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  textInputcheck = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value.length <= 150) {
      this.setState({
        [name]: value
      })
    };
  };

  numInputcheck = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value) && value.length <= 10) {
      this.setState({
        [name]: value,
      });
    }
  };

  renderTextBox = (label, width, value, name, multiline = false) => {
    return (
      <div>
        <TextField
          disabled={name === "testUnit" ? true : false}
          size={'small'}
          sx={{ width: width }}
          label={label}
          rows={3}
          name={name}
          multiline={multiline}
          value={value}
          variant="outlined"
          onChange={this.textInputcheck}
          inputProps={{emed_tid: name}}
        />
      </div>
    )
  }

  renderLeftTable = () => {
    var states = this.state
    const { t } = this.props
    const filtereddata = this.state.TempDataList?.filter((data) => {
      return (
        (data.template_name === null ? null : data.template_name?.toLowerCase()?.includes(this.state.SearchText?.toLowerCase()))
      );
    });
    return (
      <div>
        <Paper elevation={0}>
          <Box className="eMed_Search_Box_">
            <TextField
              variant='standard'
              placeholder='Search'
              value={this.state.SearchText}
              onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
              inputProps={{emed_tid: "temp_config_search"}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '15vw' }} />
          </Box>
          <TableContainer className='eMed_ConfigTable_Paper'>
            <Table stickyHeader size='small'>
              <TableHead sx={{ height: '7.3vh' }}>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='center' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("Template Name")}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('No of Tests')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('No of Group')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='right' sx={{ color: Colors.HeadText }}>{t(`Amount (${CurrencySymbol})`)}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                .map((data, index) => {
                  var sno = (states.page * states.rowsPerPage) + (index + 1)
                  return (
                    <TableBody>
                      <TableRow sx={{ bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white' }}>
                        <TableCell align='center'><div>{sno}</div></TableCell>
                        <TableCell align='left'><div>{data.template_name ? data.template_name : '-'}</div></TableCell>
                        <TableCell align='center'><div>{data.no_of_tests ? data.no_of_tests : 0}</div></TableCell>
                        <TableCell align='center'><div>{data.no_of_groups ? data.no_of_groups : 0}</div></TableCell>
                        <TableCell align='right'><div>{AmountFormat(data.net_amt || 0)?.replace(`${CurrencySymbol}`, "")}</div></TableCell>
                        <TableCell align='center'>
                          <div className='eMed_TestConfig_Con'>
                            <IconButton size='small' emed_tid='tempCongfig_edit_Btn' disabled={!CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}>
                              <img src={CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledEdit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                onClick={() => {
                                  this.setState({
                                    SelectedTempData: data
                                  }, () => { this.GetIndividualPackageData() })

                                }}
                              />
                            </IconButton>
                            <IconButton size='small' emed_tid='tempCongfig_delete_Btn' disabled={!CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}>
                              <img src={CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                onClick={() => {
                                  this.setState({
                                    SelectedTempData: data
                                  }, () => { this.setState({ deletedPopup: true }) })
                                }}
                              />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                })
              }
            </Table>
            {filtereddata.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t('NoRecordsFound')}
              </div> : null}
          </TableContainer>
        </Paper>
        <div className='eMed_TestConfig_LeftPageination'>
          <TablePagination
            component='div'
            id="eMed_tbl_pagination"
            rowsPerPageOptions={[10, 20, 30]}
            count={filtereddata.length}
            rowsPerPage={states.rowsPerPage}
            page={states.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }
  renderRightTable = () => {
    var { TempSplitList } = this.state
    const { t } = this.props
    return (
      <div>
        <Paper elevation={0}>
          <TableContainer className='eMed_ConfigRightTable_Paper' sx={{ width: '29vw' }}>
            <Table stickyHeader size='small'>
              <TableHead sx={{ flex: 1, }}>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='left'>{t('SNo')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left'>{t('Test Name or Group Name')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left'>{t('Amount')}</TableCell>
                  <TableCell id='eMed_config_TableRow' align='left'>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              {TempSplitList && TempSplitList.length > 0 ? TempSplitList.map((item, index) => {
                let TestorGrpName = item?.name ? item?.name : item?.test_name ? item?.test_name : item?.grp_name ? item?.grp_name : ''
                return (
                  <TableBody>
                    <TableRow sx={{ height: '2.5vw' }}>
                      <TableCell align='left'><div>{index + 1}</div></TableCell>
                      <TableCell align='left' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '2.5vw' }}>
                        {TestorGrpName.length > 20 ?
                          <Tooltip placement="top" title={TestorGrpName} arrow>
                            <div >{TestorGrpName ? TestorGrpName.slice(0, 20) + '...' : '-'}</div>
                          </Tooltip> : <div> {TestorGrpName ? TestorGrpName : '-'}</div>}
                        {item?.lab_test_details ?
                          <Tooltip title={<div style={{ display: 'flex', flexDirection: 'column', maxHeight: '15vw', overflowY: 'scroll' }}>
                            {item?.lab_test_details.map((testitem, testindex) => (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography fontSize={"0.9vw"} sx={{ textDecoration: 'underline' }}>{testindex === 0 ? "Test Names" : ""}</Typography>
                                <Typography fontSize={"0.9vw"}>{`${testitem?.test_name} ${testindex === item?.lab_test_details?.length - 1 ? "" : ","}`}</Typography>
                              </div>
                            ))}</div>} placement='top' arrow>
                            <Typography fontSize={'0.9vw'} color={Colors.themeDark} paddingLeft={"0.5vw"}>View Tests</Typography>
                          </Tooltip> : null}
                      </TableCell>
                      <TableCell align='left'>
                        <div>{AmountFormat(item?.amount ? item?.amount : item.test_amt ? item.test_amt : item?.grp_amt ? item?.grp_amt : 0)}</div>

                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title='Delete' placement='top' arrow>
                          <img className='eMed_TestConfig_DeleteIcon eMed_cursor' emed_tid='tempCongfig_delete_two_Btn' role={"button"} src={ImagePaths.Delete.default} alt="Delete"
                            onClick={() => {
                              TempSplitList.splice(index, 1)
                              this.setState({
                                TempSplitList
                              },()=>{
                                if(TempSplitList.length >= 1){
                                    this.calTestAmount()
                                }
                                else{
                                    this.setState({
                                        testAmt: 0,
                                        testCount: 0,
                                        tempDiscount: "",
                                        totalTestAmt: 0,
                                    })
                                }
                            })
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              }) : null}
            </Table>
            {TempSplitList.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t("NoRecordsFound")}
              </div> : null}
          </TableContainer>
        </Paper>
      </div>
    )
  }

  clearValue = () => {
    this.setState({
      SearchText: "",
      TempName: "",
      TempId: "",
      SelectedTestName: null,
      SelectedTestGrp: null,
      TempSplitList: [],
      SelectedTempData: {},
      testAmt: 0,
      testCount: 0,
      tempDiscount: "",
      totalTestAmt: 0,
    })
  }

  onSave = () => {
    var states = this.state
    const { t } = this.props

    if (CommonValidation.removeSpace(states?.TempName) !== "" && states.TempSplitList.length > 0) {
      this.postPackageConfigData()
    } else {
      if (CommonValidation.removeSpace(states?.TempName) === "") {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Enter Template Name')
        })
      }
      else if (states.TempSplitList.length === 0) {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Select Test Name or Group')
        })
      }
    }

  }


  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      SelectedTempData: {}
    })
  }
  removeDataList = () => {
    this.DelectPackage()
    this.setState({
      deletedPopup: false
    })
  }

  calTestAmount = () => {
    let testAmt = 0
    let testCount = 0
    let totalTestAmt = 0
    let discountAmt = this.state.tempDiscount ? +this.state.tempDiscount : 0

    this.state.TempSplitList?.forEach((item)=>{
      testAmt += item?.amount ? item?.amount : item.test_amt ? item.test_amt : item?.grp_amt ? item?.grp_amt : 0
    })

    if(testAmt < discountAmt){
        discountAmt = 0
        this.setState({
            tempDiscount: '',
        })
    }

    testCount = this.state.TempSplitList?.length > 0 ? +(this.state.TempSplitList?.length) : 0
    totalTestAmt = +testAmt - discountAmt

    this.setState({
        testAmt : +testAmt,
        testCount: +testCount,
        totalTestAmt: +totalTestAmt
    })
  }


  AddTestinPackage() {
    try {
      const { t } = this.props;
      if (this.state.SelectedTestName !== null) {
        let isDuplicate = false
        for (let i = 0; i < this.state.TempSplitList.length; i++) {
          if (this.state.TempSplitList[i]?.id === this.state.SelectedTestName?.id) {
            isDuplicate = true
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: t('Test Already Added')
            })
            break
          } else if (this.state.TempSplitList[i]?.lab_test_details) {
            for (let j = 0; j < this.state.TempSplitList[i]?.lab_test_details?.length; j++) {
              if (this.state.TempSplitList[i]?.lab_test_details[j]?.id === this.state.SelectedTestName?.id) {
                isDuplicate = true
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: `Test Already Includes in Group - ${this.state.TempSplitList[i]?.name}`
                })
              }
            }
          }
        }
        if (!isDuplicate) {
          this.state.TempSplitList.push(this.state.SelectedTestName)
          this.setState({ TempSplitList: this.state.TempSplitList, SelectedTestName: null },()=>{this.calTestAmount()})
        }

      } else if (this.state.SelectedTestGrp !== null) {
        let isDuplicate = false
        for (let i = 0; i < this.state.TempSplitList.length; i++) {
          if (this.state.TempSplitList[i]?.id === this.state.SelectedTestGrp?.id && this.state.TempSplitList[i]?.lab_test_details) {
            isDuplicate = true
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: t('Group Already Added')
            })
            break
          } else {
            for (let j = 0; j < this.state.SelectedTestGrp?.lab_test_details?.length; j++) {
              if (this.state.SelectedTestGrp?.lab_test_details[j]?.id === this.state.TempSplitList[i]?.id) {
                isDuplicate = true
                this.setState({
                  isErrorMsg: true,
                  isErrorMsgText: t('Group contains a already added Test')
                })
              }
            }
          }
        }
        if (!isDuplicate) {
          this.state.TempSplitList.push(this.state.SelectedTestGrp)
          this.setState({ TempSplitList: this.state.TempSplitList, SelectedTestGrp: null },
            ()=>{ this.calTestAmount() }
          )
        }
      } else if (this.state.SelectedTestName === null && this.state.SelectedTestGrp === null) {
        this.setState({
          isErrorMsg: true,
          isErrorMsgText: t('Select Test Name or Test Group')
        })
      }
    }
    catch (e) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: e?.message
      })
    }
  }

  LoaderFunction = (key = false) => {
    this.setState({
      isLoader: key
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className='eMed_TestConfig_MainContainer'>
        <div className='eMed_TestConfig_Con'>
          <div className='eMed_TestConfig_leftSide'>
            {this.renderLeftTable()}
          </div>
          <div className='eMed_TestConfig_RightSide'>
            <div className='eMed_TestConfig_RightHead'>
              <p className='eMed_Testconfig_RightHead_text'>{t('Template Configuration')}</p>
            </div>
            <div className='eMed_TestConfig_RightContentBox'>
              <div className='eMed_TestConfig_RightMethod'>
                {this.renderTextBox(t("Template Name *"), '29vw', this.state.TempName, 'TempName')}
              </div>
              <div className='eMed_TestConfig_RightMethod' style={{display: 'block'}}>
                <div>
                    <Divider textAlign='left' sx={{ marginLeft: '0.5vw', marginTop: '0.5vw' }}>
                        <Typography component={'p'} className='eMed_Service_title'>Amount Break Up</Typography>
                    </Divider>
                </div>
                <div style={{display:'flex',padding:'0.5vw', justifyContent:'space-around'}}>
                    <div>
                        <Typography component={'p'} sx={{fontSize: "0.9vw"}}>Total Test/Group</Typography>
                        <Typography component={'p'} fontSize={'1vw'} fontWeight={600}>{this.state.testCount}</Typography>
                    </div>
                    <div>
                        <Typography component={'p'} sx={{fontSize: "0.9vw"}}>Total Amount</Typography>
                        <Typography component={'p'} fontSize={'1vw'} fontWeight={600}>{AmountFormat(this.state.testAmt || 0)}</Typography>
                    </div>
                    <div>
                        <Typography component={'p'} sx={{fontSize: "0.9vw"}}>Discount</Typography>
                        <TextField 
                            size='small'
                            value={this.state.tempDiscount}
                            disabled={this.state.TempSplitList?.length === 0}
                            name=""
                            variant="outlined"
                            inputProps={{emed_tid: "temp_config_discount_text"}}
                            sx={{width:'5vw','& .MuiOutlinedInput-input': {padding: "0.2vw"},}}
                            onChange={(e)=>{
                                if((CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value == "") && this.state.testAmt >= +e.target.value){
                                    this.setState({ tempDiscount : e.target.value},
                                        ()=>{
                                            this.calTestAmount()
                                        }
                                    )
                                }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {CurrencySymbol}
                                </InputAdornment>
                              ),
                            }}
                        />
                    </div>
                    <div>
                        <Typography component={'p'} sx={{fontSize: "0.9vw"}}>Net Amount</Typography>
                        <Typography component={'p'} fontSize={'1vw'} fontWeight={600}>{AmountFormat(this.state.totalTestAmt || 0)}</Typography>
                    </div>
                </div>
              </div>
              <Divider textAlign='left' sx={{ marginLeft: '0.5vw', marginTop: '0.5vw' }}>
                <Typography component={'p'} className='eMed_Service_title'>Add Test to Template</Typography></Divider>

              <div className='eMed_TestConfig_RightMethod'>
                <Autocomplete
                  options={this.state.testConfigData}
                  getOptionLabel={(options) => options.test_name}
                  onChange={(e, value) => {
                    this.setState({
                      SelectedTestGrp: null,
                      SelectedTestName: value
                    })
                  }}
                  value={this.state.SelectedTestName}
                  id="combo-box-demo"
                  sx={{ width: "10vw" }}
                  renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, emed_tid:"TempConfig_TestName"}} label={t("Test Name")} size='small' />}
                />
                <Autocomplete
                  options={this.state.testGroupDataList}
                  getOptionLabel={(options) => options.name}
                  onChange={(e, value) => {
                    this.setState({
                      SelectedTestGrp: value,
                      SelectedTestName: null
                    })
                  }}
                  value={this.state.SelectedTestGrp}
                  id="combo-box-demo"
                  sx={{ width: "10vw" }}
                  renderInput={(params) => <TextField {...params} label={t("Test Group")} inputProps={{...params.inputProps, emed_tid:"TempConfig_TestGrp"}} size='small' />}
                />
                <Button emed_tid='tempConfig_add_test_Btn' id='eMed_Btn_Text' variant='contained' sx={{ width: '5vw' }}
                  onClick={() => { this.AddTestinPackage() }}
                >{t('Add')}</Button>
              </div>
              <div className='eMed_TestConfig_Right_SideTable'>
                {this.renderRightTable()}
              </div>
            </div>
            <div className='eMed_TestConfig_RightBtnCon'>
              <Stack spacing={2} direction="row">
                <Button id='eMed_Btn_Text' emed_tid='tempCongfig_Clear_Btn' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                <Button id='eMed_Btn_Text' emed_tid='packageCongfig_Save_Btn' disabled={this.state.disableBtn ? true : !CheckAccessFunc("Laboratory", "Configuration", "Package Configuration",  null, "Tab")?.editAccess}  variant='contained' onClick={() => { this.onSave() }}>{t("Save")}</Button>
              </Stack>
            </div>
          </div>
        </div>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={` Are you sure to delete Template - ${this.state.SelectedTempData?.template_name}`}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
           <Loader loaderOpen={this.state.isLoader} />
      </div>
    );
  }
}

export default withTranslation()(TemplateConfig);