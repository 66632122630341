import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, InputAdornment, Paper, TextField, Typography, Stack, Tooltip } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { CommonDeleteButton, CommonEditButton, CommonGridToolBarWithFilterText, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import { CurrencySymbol } from '../../../Utility/Constants';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';

class CanteenMenuItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      menuItemData: [],
      isLoader: false,
      errorMsg: false,
      errorMsgtext: '',
      successMsg: false,
      successMsgtext: '',
      itemName: '',
      itemPrice: '',
      itemDesc: '',
      isEdit: false,
      selectedID: null,
      itemCode: '',
      deletedPopup: false,
      deleteId: null,
      delName: '',
      isDisable: false,
    }
  }

  componentDidMount() {
    this.getCanteenData()
  }

  errorMessage = (msg) => {
    this.setState({
      errorMsg: true,
      errorMsgtext: msg,
    })
  }
  successMessage = (msg) => {
    this.setState({
      successMsg: true,
      successMsgtext: msg
    })
  }
  getCanteenData = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_GET)
        .then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false)
            this.setState({
              menuItemData: response.data.data
            })
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  postMenuItem = () => {
    try {
      this.setState({
        isDisable: true
      })
      var states = this.state
      let data = {
        "item_name": states.itemName.trim(),
        "price": +states.itemPrice,
        "description": states.itemDesc
      }
      if (this.state.isEdit) {
        data['id'] = this.state.selectedID
        data['item_code'] = this.state.itemCode
      }
      let Url = this.state.isEdit ? RestAPIService.updateWithOutId(data, Serviceurls.CANTEEN_GET) : RestAPIService.create(data, Serviceurls.CANTEEN_GET)
      if (states.itemName && states.itemPrice) {
        Url
          .then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response?.data?.message)
              this.onClearItem()
              this.getCanteenData()
            }
          })
          .catch((e) => {
            this.setState({
              isDisable: false
            })
            if (e?.response?.data?.status === 'fail') {
              this.errorMessage(e.response.data.message)
            } else {
              this.errorMessage(e.message)
            }
          })
      } else {
        this.setState({
          isDisable: false
        })
        if (states.itemName === '') {
          this.errorMessage("Please Enter Item Name")
        } else if (states.itemPrice === '') {
          this.errorMessage("Please Enter Item Price")
        }
      }
    } catch (e) {
      this.setState({
        isDisable: false
      })
      this.errorMessage(e.message)
    }
  }

  removeDataList = () => {
    try {
      RestAPIService.delete(`${Serviceurls.CANTEEN_GET}?id=${this.state.deleteId ? this.state.deleteId : 0}`)
        .then(response => {
          if (response.data.status === "success") {
            this.PopupClose();
            this.successMessage(response.data.message);
            this.getCanteenData();
          }
        })
        .catch(e => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        });
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  handleEdit = (data) => {
    this.setState({
      itemName: data?.item_name,
      itemPrice: data?.price,
      itemDesc: data?.description,
      isEdit: true,
      selectedID: data?.id,
      itemCode: data?.item_code
    })
  }

  handleDelete = (data) => {
    if (!this.state.isEdit) {
      this.setState({
        deletedPopup: true,
        deleteId: data?.id,
        delName: data?.item_name
      })
    } else {
      this.errorMessage("You have clicked the Edit button. So, please add or clear the details.")
    }
  }

  gridToolBar = () => {
    return (
      <Box>
        <CommonGridToolBarWithFilterText />
      </Box>

    )
  }

  renderLeftSide = () => {
    const { t } = this.props
    this.state.menuItemData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: "sno", headerName: "S.No", flex: 0.10 },
      {
        field: "item_code", headerName: "Item Code", flex: 0.20, type: "string",
        renderCell: (params) => (params?.row?.item_code ? params?.row?.item_code : '-')
      },
      {
        field: "item_name", headerName: "Item Name", flex: 0.30, type: "string",
        renderCell: (params) => (params?.row?.item_name?.length > 25 ?
          <Tooltip title={params?.row?.item_name} placement='top' arrow><Typography>{params?.row?.item_name.slice(0, 25) + '...'}</Typography></Tooltip> :
          <Typography>{params?.row?.item_name ? params?.row?.item_name : '-'}</Typography>)
      },
      {
        field: "price", headerName: `Price (${CurrencySymbol})`, flex: 0.20, type: "number",
        renderCell: (params) => (AmountFormat(params?.row?.price || 0)?.replace(`${CurrencySymbol}`, ""))
      },
      {
        field: "action", headerName: "Action", flex: 0.20, align: "center", headerAlign: "center",
        renderCell: (params) => <div>
          <CommonEditButton size={"1.5vw"} onClick={this.handleEdit.bind(this, params.row)} />
          <CommonDeleteButton size={"1.5vw"} onClick={this.handleDelete.bind(this, params.row)} />
        </div>
      },

    ]
    return (
      <Paper className='eMed_Canteen_Menuitem_rightCon' elevation={2}>
        <Box sx={{ height: '76vh' }}>
          <DataGrid
            rows={this.state.menuItemData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
      </Paper>
    )
  }

  onClearItem = () => {
    this.setState({
      itemName: '',
      itemPrice: '',
      itemDesc: '',
      itemCode: '',
      isEdit: false,
      selectedID: null,
      isDisable: false,
    })
  }

  renderRightSide = () => {
    return (
      <Paper className='eMed_Canteen_Menuitem_LeftCon' elevation={2}>
        <Box className='eMed_Canteen_leftHeadCon'>
          <Typography className='eMed_Canteen_Headtext'>Menu Item</Typography>
        </Box>
        <Box sx={{ height: '63.5vh', overflow: 'scroll' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', margin: '1vw' }}>
            <TextField
              sx={{ width: '30vw' }}
              id="item-name"
              label="Item Name"
              value={this.state.itemName}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              onChange={(e) => {
                let alphanumcha = /^[ a-z A-Z 0-9 ( ) &]+$/
                if (alphanumcha || e.target.value === '') {
                  this.setState({ itemName: e.target.value })
                }
              }} />
            <TextField
              sx={{ width: '15vw', marginTop: '1vw' }}
              id="item-name"
              label="Price"
              variant="outlined"
              value={this.state.itemPrice}
              InputProps={{
                endAdornment:
                  <InputAdornment>{CurrencySymbol}</InputAdornment>
              }}
              onChange={(e) => {
                let validation = CommonValidation.RangeDecimalNumber(e.target.value)
                if (validation || e.target.value === '') {
                  this.setState({ itemPrice: e.target.value })
                }
              }}
            />
          </Box>
          <Box sx={{ border: '1px solid #d9dbdb' }}></Box>
          <Box sx={{ margin: '1vw' }}>
            <TextField
              sx={{ width: '30vw' }}
              multiline
              rows={3}
              id="item-name"
              value={this.state.itemDesc}
              inputProps={{ maxLength: 150 }}
              label="Description"
              variant="outlined"
              onChange={(e) => {
                this.setState({ itemDesc: e.target.value })
              }} />
          </Box>
        </Box>
        <Box className='eMed_Canteen_BtnCon'>
          <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' sx={{ marginRight: '1vw' }}
            onClick={() => { this.onClearItem() }}>Clear</Button>
          <Button size='small' variant='contained' className='eMed_Canteen_BtnText'
            disabled={!this.state.itemName || !this.state.itemPrice || this.state.isDisable}
            onClick={() => { this.postMenuItem() }}>Add</Button>
        </Box>
      </Paper>
    )
  }
  msgClose() {
    this.setState({
      errorMsg: false,
      errorMsgtext: '',
      successMsg: false,
      successMsgtext: '',
    })
  }
  PopupClose = () => {
    this.setState({
      deletedPopup: false,
      deleteId: null,
      delName: "",
    })
  }

  render() {
    const { t } = this.props
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box display={'flex'}>
          {this.renderLeftSide()}
          {this.renderRightSide()}
        </Box>
        {this.state.errorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.errorMsgtext}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgtext}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletedPopup ?
          <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.delName ? this.state.delName : '-'} ?`}
            DeleteNotify={t("effectentireBilling")}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(CanteenMenuItem);