import React, { Component } from 'react'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import {
    Box, Button, Drawer, Paper, Table, TableBody, TableCell, TableHead, TableRow,
    Typography, TableContainer, Autocomplete, TextField, Stack
} from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';

const MonthList = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
];

const startYear = 2018;
const currentYear = new Date().getFullYear();
const yearsArray = [];

for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push(year);
}


class IPOccupancyReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            OccupancyData: [],
            page: 0,
            successMsg: false,
            isErrorMsg: false,
            pageName: 'Occupancy Report',
            isErrorMsgText: '',
            successMsgText: '',
            HeaderData: [],
            RowDatas: [],
            SelectedYear: new Date().getFullYear(),
            SelectedMonth: null,
            BlockName: [],
            Floorno: [],
            WardName: [],
            WardList: [],
            BlockList: [],
            FloorList: [],
        }
    }

    componentDidMount = () => {
        this.GetBedList()
        this.getOccupancyData()
    }

    GetOccupancyListexcel = () => {
        const { t } = this.props
        try {
            RestAPIService.excelGet(Serviceurls.IP_OCCUPENCY_REPORT_GET + "?year_number=" + this.state.SelectedYear + "&month_name=" + (this.state.SelectedMonth ? this.state.SelectedMonth?.name : "") + "&ward_name=" + (this.state?.WardName ? this.state?.WardName : "") + "&block_name=" + (this.state?.BlockName ? this.state?.BlockName : "") + "&floor_number=" + (this.state?.Floorno ? this.state?.Floorno : "") + "&export_type=excel")
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    GetBedList() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.BED_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        let BedData = response.data?.data ? response.data?.data : []
                        if (BedData.length > 0) {
                            const Block = BedData.map(item => item.block)
                            const uniqueBlock = [...new Set(Block)];
                            const Ward = BedData.map(item => item.ward__ward_name)
                            const uniqueWard = [...new Set(Ward)];
                            const Floor = BedData.map(item => item.floor)
                            const uniqueFloor = [...new Set(Floor)];
                            this.setState({
                                BlockList: uniqueBlock,
                                WardList: uniqueWard,
                                FloorList: uniqueFloor
                            })
                        }

                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.LoaderFunction(false)
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    getOccupancyData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_OCCUPENCY_REPORT_GET + "?year_number=" + this.state.SelectedYear + "&month_name=" + (this.state.SelectedMonth ? this.state.SelectedMonth?.name : "") + "&ward_name=" + (this.state?.WardName ? this.state?.WardName : "") + "&block_name=" + (this.state?.BlockName ? this.state?.BlockName : "") + "&floor_number=" + (this.state?.Floorno ? this.state?.Floorno : ""))
                .then((response) => {
                    if (response.data.status === 'success') {
                        let Datas = response.data.data ? response.data.data : []
                        let HeaderData = []
                        let ColumnDatas = []
                        if (response.data?.total_occupancy) {
                            HeaderData = Datas.length > 0 ? Object.keys(Datas[0][1]) : []
                            Datas?.forEach((list) => {
                                ColumnDatas.push({ ...list[1], ...{ label: list[0] } })
                            })
                        }
                        this.setState({
                            HeaderData: HeaderData,
                            RowDatas: ColumnDatas,
                            TotalWards: HeaderData?.length ? HeaderData?.length : 0,
                            TotalOccucpency: response.data?.total_occupancy ? response.data?.total_occupancy : 0,
                            TotalPatient: response.data?.patient_count ? response.data?.patient_count : 0,
                        })
                        this.LoaderFunction(false)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })
        } catch (e) {
            this.LoaderFunction(e.message)
        }
    }

    getOccupancyPrint = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.ACTIVE_DRUG_LIST)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    )
                    const fileURL = URL.createObjectURL(file)
                    window.open(fileURL)
                    this.LoaderFunction(false)
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message)
                        this.LoaderFunction(false)
                    } else {
                        this.errorMessage(error.message)
                        this.LoaderFunction(false)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderTable = () => {
        return (
            <Paper sx={{ width: '100%', height: '100%' }}>
                <TableContainer sx={{ height: '65vh' }}>
                    <Table stickyHeader size='small' >
                        <TableHead>
                            {this.state.HeaderData?.length > 0 ?
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='center' sx={{ position: "sticky", left: 0, top: 0, zIndex: 3 }}>
                                        {typeof (this.state.RowDatas[0]?.label) === "number" ? "Month" : <Typography width={'6vw'} fontWeight={'bold'}>Date</Typography>}
                                    </TableCell>
                                    {this.state.HeaderData?.map((item) => (
                                        <TableCell id='eMed_config_TableRow1' align='center'>{item}</TableCell>
                                    ))}
                                </TableRow> : null
                            }

                        </TableHead>
                        <TableBody>
                            {this.state.RowDatas?.length > 0 ? this.state.RowDatas?.map((item) => {
                                return (
                                    <TableRow>
                                        <TableCell align='center' sx={{ fontWeight: 600, position: "sticky", left: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            {typeof (item?.label) === "number" ? MonthList.find((list) => list?.id === item?.label)?.name : <Typography width={'6vw'}>{formatDate(item?.label)}</Typography>}
                                        </TableCell>
                                        {this.state.HeaderData?.map((list, index) => (<TableCell align='center'>{item[list]}</TableCell>))}
                                    </TableRow>
                                )
                            }) : <Typography sx={{ marginTop: "10vw", marginLeft: "40vw" }}>No Records To Be Shown</Typography>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )

    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.BlockList ? this.state?.BlockList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.BlockName}
                            onChange={(event, newValue) => {
                                this.setState({ BlockName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, emed_tid: "Occupency_Block_TestID" }}
                                    label={t('SelectBlock')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.FloorList ? this.state?.FloorList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.Floorno}
                            onChange={(event, newValue) => {
                                this.setState({ Floorno: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, emed_tid: "Occupency_Floor_TestID" }}
                                    label={t('SelectFloor')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            size='small'
                            options={this.state?.WardList ? this.state?.WardList : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state?.WardName}
                            onChange={(event, newValue) => {
                                this.setState({ WardName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, emed_tid: "Occupency_Ward_TestID" }}
                                    label={t('SelectWard')}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                BlockName: [],
                                Floorno: [],
                                WardName: [],
                            }, () => this.getOccupancyData())
                        }}
                        emed_tid={"occupency_clear_testID"}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button
                        variant='contained'
                        size="small"
                        emed_tid={"occupency_serach_testID"}
                        onClick={() => {
                            this.getOccupancyData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    render() {
        const { t } = this.props
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Wards"), this.state.TotalWards, true)}
                        {AmountsCard(t("Total Occupied"), this.state.TotalOccucpency, true)}
                        {AmountsCard(t("Total Patient"), this.state.TotalPatient, true)}
                    </Box>
                    <Box flex={0.8} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Box>
                            <Autocomplete
                                size='small'
                                sx={{ width: "10vw" }}
                                options={yearsArray}
                                value={this.state.SelectedYear}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        SelectedYear: newValue
                                    }, () => { this.getOccupancyData() })
                                }}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{ ...params.inputProps, emed_tid: "Occupency_Year_TestID" }}
                                        label={"Year"}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Autocomplete
                                size='small'
                                sx={{ width: "15vw", marginLeft: "1vw", marginRight: "1vw" }}
                                options={MonthList}
                                value={this.state.SelectedMonth}
                                getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.name}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        SelectedMonth: newValue
                                    }, () => { this.getOccupancyData() })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{ ...params.inputProps, emed_tid: "Occupency_Month_TestID" }}
                                        label={"Month"}
                                    />
                                )}
                            />
                        </Box>
                        <Button emed_tid="rpt_occupancy_filter" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ FilterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid="rpt_occupancy_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => { this.GetOccupancyListexcel() }}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    {this.renderTable()}
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(IPOccupancyReport)
