import { Box, Drawer, Button, TextField, Autocomplete, Stack, Typography, Tooltip } from '@mui/material'
import './reports.css'
import React, { Component } from 'react'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { DataGrid } from '@mui/x-data-grid';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CurrencySymbol } from '../../../Utility/Constants';

class PackageWiseReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataList: [],
            packageData: [],
            FilterOpen: false,
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            totalAmt: 0,
            packageName: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            loading:false
        }
    }

    componentDidMount() {
        this.getPackageWiseReport()
        this.getPackageData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    getPackageWiseReport = () => {
        try {
            let packageid = [];
            this.state.packageName.forEach(element => packageid.push(element.package_id))
            this.setState({loading:true})
            RestAPIService.getAll(Serviceurls.LAB_PACKAGE_REPORT + `?package_id=${packageid}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            dataList: response.data?.data?.length > 0 ? response.data?.data : [],
                            totalAmt: response?.data?.total_amount ? response?.data?.total_amount : 0
                        })
                        this.setState({loading:false})
                    }
                }).catch((error) => {
                    this.setState({loading:false})
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.setState({loading:false})
            this.errorMessage(error.message)
        }
    }

    getPackageWiseReportPrint = () => {
        try {
            let packageid = [];
            this.state.packageName.forEach(element => packageid.push(element.package_id))
            RestAPIService.getAll(Serviceurls.LAB_PACKAGE_REPORT + `?package_id=${packageid}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPackageData = () => {
        try {
            RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            packageData: response.data?.data?.length > 0 ? response.data?.data : [],
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            size='small'
                            options={this.state.packageData}
                            getOptionLabel={(option) => (typeof (option) === 'string' ? option : option?.package_name)}
                            value={this.state.packageName}
                            onChange={(event, newValue) => {
                                this.setState({ packageName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Select Package Name')}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                packageName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            },
                                () => this.getPackageWiseReport())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getPackageWiseReport()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    render() {
        const { t } = this.props
        this.state.dataList.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: "sno", sortable: false, flex: 0.10, headerName: t("SNo") },
            {
                field: "package_name", headerName: 'Package Name', flex: 0.50, type: 'string',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.package_name?.length > 90 ?
                    <Tooltip placement='top' title={params?.row?.package_name} arrow><div>{params?.row?.package_name.slice(0, 90) + "..."}</div></Tooltip>
                    : params?.row?.package_name ? params?.row?.package_name : "-"}</Box>)
            },
            {
                field: "test_count", headerName: 'Test Count', flex: 0.20, type: 'number',
                renderCell: (params) => (<Box>{params?.row?.test_count ? params?.row?.test_count : '0'}</Box>)
            },
            {
                field: "total_package_amount", headerName: `Total Amount (${CurrencySymbol})`, flex: 0.20, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total_package_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'} className='reports_container' height={'79vh'}>
                <Box component={'div'} className='reports_header_card'>
                    <Box className='reports_left'>
                        <Box className='reports_bill_box_one'>
                            {AmountsCard(t("TotalAmount"), this.state.totalAmt)}
                        </Box>
                    </Box>
                    <Box className='reports_right'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' disabled={this.state.dataList.length === 0} onClick={() => { this.getPackageWiseReportPrint() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} sx={{ height: '63vh', width: '92vw', margin: 'auto', backgroundColor: 'white', marginTop: '1vw' }}>
                    <DataGrid
                        rows={this.state.dataList}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.sno}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ pageSize: newPageSize })
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                        loading={this.state.loading}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={this.state.isErrorMsgText}
                    msgPop={this.msgClose.bind(this)}
                />
                : null}
            </Box>
        )
    }
}
export default withTranslation()(PackageWiseReport);