import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class ConsolidatedSales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            consolidatedSalesData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'month': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "days":91
            },
            pageName: 'consolidatedSales',
            filterOpen: false,
            purchase:0,
            profit:0,
            sales:0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getConsolidatedSalesData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getConsolidatedSalesData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    formatMonthAndYear = (value) => {
        let month = value.split("-")[1]
        let year = value.split('-')[0]
    
        return year
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "Year", value: this.formatMonthAndYear(this.state.filterDatas.month) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    getConsolidatedSalesData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_CONSOLIDATED_REPORT_GET + `?invoice_year=${this.state.filterDatas?.month.split("-")[0]}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            consolidatedSalesData: response.data.data?.result,
                            profit: response.data.data?.profit_total,
                            purchase: response.data.data?.purchase_total,
                            sales: response.data.data?.sales_total,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getConsolidatePrint = () => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_CONSOLIDATED_REPORT_GET + `?invoice_year=${this.state.filterDatas?.month.split("-")[0]}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    render() {
        const { t } = this.props
        this.state.consolidatedSalesData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'date', headerName: "Date", flex: 0.25,align:'center',headerAlign:'center',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.date?.length > 12 ? <Tooltip placement='top' title={params?.row?.date} arrow><div>{params?.row?.date?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.date ? params?.row?.date : '-'}
                </Box>)
            },
            {
                field: 'purchase_amount', headerName: `${"Purchase Amount"} (${CurrencySymbol})`, flex: 0.25, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.purchase_amount ? AmountFormat(parmas.row?.purchase_amount).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'sales_amount', headerName: `${"Sales Amount"} (${CurrencySymbol})`, flex: 0.25, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.sales_amount ? AmountFormat(parmas.row?.sales_amount).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'profit_amount', headerName: `${"Profit Amount"} (${CurrencySymbol})`, flex: 0.25, type: "number",
                renderCell: (parmas) => (<Box color={'green'}>{parmas.row?.profit_amount ? AmountFormat(parmas.row?.profit_amount).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Purchase Total", this.state.purchase, false, "white", false, null, 'rpt_consoli_pur_total')}
                        {AmountsCard("Sales Total", this.state.sales, false, "white", false, null, 'rpt_consoli_sal_total')}
                        {AmountsCard("Profit Total", this.state.profit, false, "white", false, null, 'rpt_consoli_pro_total')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid = "rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid = "rpt_consoli_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={()=>{this.getConsolidatePrint()}}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.consolidatedSalesData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading= {this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(ConsolidatedSales)