import { Box, Button, Divider, Step, StepLabel, Stepper } from '@mui/material';
import React, { Component } from 'react';
import './IPAppointments.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IPAddmissionDetails from './IPAddmissionDetails';
import IPBilling from './IPBilling';
import CreateAppointments from '../../OutPatients/QuickBillAppointments/CreateAppointment'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';

const steps = ['Registration', 'Admission Details', 'Billing'];

export default class IPAppoinmentIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      StepNumber: 0,
      ViewScreen: this?.props?.history?.location?.state?.is_PatientDetails || this?.props?.is_From_Ip_AdmissionDetails ? 1 : 0,
      PatientDetails: {},
      AdmissionDetails: {},
      PatientDetailBack: {},
      bacKfromBilling: {},
      is_From_PatientDetails: this?.props?.history?.location?.state?.is_PatientDetails,
      isPatientDischarged: this?.props?.history?.location?.state?.isPatientDischarged,
    }
  }

  componentDidMount() {
    let IpPatientDetails =  JSON.parse(getCachevalue('IppatientData'));
    let DoctorClinicId = JSON.parse(localGetItem('SelectedDoctorClinicId'));
    if (this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.PatientClicked === true) {
      this.setState({
        PatientDetails: this.props.history?.location?.state?.SelectedPatientDetails
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        }, () => { this.props.history.push({ state: { SelectedPatientDetails: {} } }) })
      })
    }
    if (this.props.history?.location?.state?.OTPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this.props.history?.location?.state?.OTPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.PatientClicked === true) {
      let Data = {
        photo_url: "",
        first_name: this.props.history?.location?.state?.OTPatientDetails?.patient_name,
        patient_account_number: this.props.history?.location?.state?.OTPatientDetails?.uhid,
        mobile_number: this.props.history?.location?.state?.OTPatientDetails?.patients_mobile_number,
        dob: this.props.history?.location?.state?.OTPatientDetails?.patients_dob,
        patient_id: this.props.history?.location?.state?.OTPatientDetails?.patient_id,
        patient_gender: this.props.history?.location?.state?.OTPatientDetails?.gender,
        fromOTHome: true,
        OTid: this.props.history?.location?.state?.OTPatientDetails?.id,
        ClinicID: this.props.history?.location?.state?.ClinicId,
        DoctorID: this.props.history?.location?.state?.OTPatientDetails?.surgery_doctor_id,
        fromOTModule: this.props.history?.location?.state?.fromOTModule,
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
    if (this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.ReservationClicked === true) {
      let Data = {
        photo_url: "",
        first_name: this.props.history?.location?.state?.SelectedPatientDetails?.patient_first_name,
        patient_account_number: this.props.history?.location?.state?.SelectedPatientDetails?.patient_acc_no,
        mobile_number: this.props.history?.location?.state?.SelectedPatientDetails?.patient__mobile_number,
        dob: this.props.history?.location?.state?.SelectedPatientDetails?.patient__approx_dob,
        patient_id: this.props.history?.location?.state?.SelectedPatientDetails?.patient_id,
        gender: this.props.history?.location?.state?.SelectedPatientDetails?.patient_gender
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
    if (IpPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this?.props?.is_From_Ip_AdmissionDetails) {
      let Data = {
        photo_url: IpPatientDetails?.patient_photo_url,
        first_name: IpPatientDetails?.patient_name,
        patient_account_number: IpPatientDetails?.patient_account_number,
        mobile_number: IpPatientDetails?.patient__mobile_number,
        dob: IpPatientDetails?.patient__dob || IpPatientDetails?.patient__approx_dob,
        patient_id: IpPatientDetails?.patient_id,
        gender: IpPatientDetails?.patient_gender,
        ClinicID: DoctorClinicId,
        DoctorID: IpPatientDetails?.doctor_id,
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    let IpPatientDetails =  JSON.parse(getCachevalue('IppatientData'));
    let DoctorClinicId = JSON.parse(localGetItem('SelectedDoctorClinicId'));
    if (prevProps.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && prevProps.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.PatientClicked === true) {
      this.setState({
        PatientDetails: prevProps.history?.location?.state?.SelectedPatientDetails
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        }, () => { this.props.history.push({ state: { SelectedPatientDetails: {} } }) })
      })
    }
    if (prevProps.history?.location?.state?.OTPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && prevProps.history?.location?.state?.OTPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.PatientClicked === true) {
      let Data = {
        photo_url: "",
        first_name: this.props.history?.location?.state?.OTPatientDetails?.patient_name,
        patient_account_number: this.props.history?.location?.state?.OTPatientDetails?.uhid,
        mobile_number: this.props.history?.location?.state?.OTPatientDetails?.patients_mobile_number,
        dob: this.props.history?.location?.state?.OTPatientDetails?.patients_dob,
        patient_id: this.props.history?.location?.state?.OTPatientDetails?.patient_id,
        patient_gender: this.props.history?.location?.state?.OTPatientDetails?.gender,
        fromOTHome: true,
        OTid: this.props.history?.location?.state?.OTPatientDetails?.id,
        ClinicID: this.props.history?.location?.state?.ClinicId,
        DoctorID: this.props.history?.location?.state?.OTPatientDetails?.surgery_doctor_id,
        fromOTModule: this.props.history?.location?.state?.fromOTModule,
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
    if (this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this.props.history?.location?.state?.SelectedPatientDetails?.patient_id !== this.state.PatientDetailBack?.patient_id && this.props.history?.location?.state?.ReservationClicked === true) {
      let Data = {
        photo_url: "",
        first_name: this.props.history?.location?.state?.SelectedPatientDetails?.patient_first_name,
        patient_account_number: this.props.history?.location?.state?.SelectedPatientDetails?.patient_acc_no,
        mobile_number: this.props.history?.location?.state?.SelectedPatientDetails?.patient__mobile_number,
        dob: this.props.history?.location?.state?.SelectedPatientDetails?.patient__approx_dob,
        patient_id: this.props.history?.location?.state?.SelectedPatientDetails?.patient_id,
        gender: this.props.history?.location?.state?.SelectedPatientDetails?.patient_gender
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
    if (IpPatientDetails?.patient_id !== this.state.PatientDetails?.patient_id && this?.props?.is_From_Ip_AdmissionDetails) {
      let Data = {
        photo_url: IpPatientDetails?.patient_photo_url,
        first_name: IpPatientDetails?.patient_name,
        patient_account_number: IpPatientDetails?.patient_account_number,
        mobile_number: IpPatientDetails?.patient__mobile_number,
        dob: IpPatientDetails?.patient__dob || IpPatientDetails?.patient__approx_dob,
        patient_id: IpPatientDetails?.patient_id,
        gender: IpPatientDetails?.patient_gender,
        ClinicID: DoctorClinicId,
        DoctorID: IpPatientDetails?.doctor_id,
      }
      this.setState({
        PatientDetails: Data
      }, () => {
        this.setState({
          StepNumber: 1,
          ViewScreen: 1
        })
      })
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  ChangeScreen(value) {
    this.setState({ ViewScreen: value })
  }
  BackToRegistration(data) {
    setTimeout(() => {
      this.setState({
        ViewScreen: 0,
        PatientDetailBack: data
      })
    }, 1000)
  }
  IpPatientRegSuccess(message, data) {
    this.successMessage(message)
    this.setState({
      ViewScreen: 1,
      StepNumber: 1,
      PatientDetails: data
    })
  }
  IPAdmisssionSuccess(message, data) {
    this.successMessage(message)
    this.setState({
      ViewScreen: 2,
      StepNumber: 2,
      AdmissionDetails: data
    })
  }
  BactToAdmissionDetials(data) {
    this.setState({
      ViewScreen: 1,
      bacKfromBilling: data
    })
  }
  BackToBilling() {
    this.setState({
      ViewScreen: 2
    })
  }
  renderComponent() {
    if (this.state.ViewScreen === 0) {
      return <CreateAppointments
        PatientDetailBack={this.state.PatientDetailBack}
        isFromIP={true}
        IpPatientRegSuccess={this.IpPatientRegSuccess.bind(this)}
        ChangeScreen={this.ChangeScreen.bind(this)}
        history={this.props.history} />
    }
    else if (this.state.ViewScreen === 1) {
      return <IPAddmissionDetails
        BackToRegistration={this.BackToRegistration.bind(this)}
        PatientDetails={this.state.PatientDetails}
        IPAdmisssionSuccess={this.IPAdmisssionSuccess.bind(this)}
        ChangeScreen={this.ChangeScreen.bind(this)}
        bacKfromBilling={this.state.bacKfromBilling}
        BackToBilling={this.BackToBilling.bind(this)}
        history={this.props.history}
        isfrom_Patientdetails={this.state.is_From_PatientDetails}
        isPatientDischarged={this.state.isPatientDischarged}
        is_From_Ip_AdmissionDetails={this.props.is_From_Ip_AdmissionDetails} />
    }
    else if (this.state.ViewScreen === 2) {
      return <IPBilling
        ChangeScreen={this.ChangeScreen.bind(this)}
        PatientDetails={this.state.PatientDetails}
        AdmissionDetails={this.state.AdmissionDetails}
        BactToAdmissionDetials={this.BactToAdmissionDetials.bind(this)}
        history={this.props.history} />
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    return (
      <Box>
        <Box component={'div'} className="eMed_IPappoint_Stepper_box">
          {this.props.is_From_Ip_AdmissionDetails ? null :
          <Button size="small" id="eMed_back_btn"
            onClick={() => { this.props.history.push({ pathname: this.state.is_From_PatientDetails ? "/IPHome/PatientDetails/PatientDetails" : this.state?.PatientDetails?.fromOTModule ? "/OTHome" : this.state?.PatientDetails?.OTid ? "/IPHome/OThome" : "/IPHome" }) }}
            startIcon={<KeyboardArrowLeftIcon color='primary' />}>Back</Button>}
          {(this.state.is_From_PatientDetails || this.props.is_From_Ip_AdmissionDetails) ? null :
            <Stepper activeStep={this.state.StepNumber} sx={{ width: "90%" }}>
              {steps.map((label, index) => (
                <Step key={index}>
                  {/* <StepButton d onClick={()=>{this.setState({ ViewScreen: index })}}> */}
                  <StepLabel>{label}</StepLabel>
                  {/* </StepButton> */}
                </Step>
              ))}
            </Stepper>}
        </Box>
        {this.props.is_From_Ip_AdmissionDetails ? null : <Divider />}
        <Box component={'div'} className="eMed_IPappoint_Component_box">
          {this.renderComponent()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}