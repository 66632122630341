import { Box, Tab, Tabs } from '@mui/material'
import React, { Component } from 'react'
import ConcessionApproval from './ConcessionApproval';
import CancelBillApprovals from './CancelBillApprovals';
import FinalBillRevertApprovals from './FinalBillRevertApprovals';
import OverAllBillConcession from './OverAllBillConcession';
import './ApprovalHome.css'
import ExpenseApprovals from './ExpenseApprovals';
import ModifyBillApprovals from './ModifyBillApprovals';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';

export default class ApprovalsHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedTab: "concession",
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        }
    }

    componentDidMount() {
        if (this.props.history?.location?.state?.topage) {
            this.setState({
                SelectedTab: this.props.history?.location?.state?.topage
            })
            this.getMakerCheckerCount()
        } else {
          this.getMakerCheckerCount()
        }
    } 

  // componentDidUpdate(PrevProps){
  //   if(this.props !== PrevProps){
  //     this.getMakerCheckerCount()
  //   }
  // }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getMakerCheckerCount = () => {
    const LoggedData = JSON.parse(localGetItem("loggedInUserInfo"));
    const user_id = JSON.parse(localGetItem("login_user_id"));
    let url = Serviceurls.MAKER_CHECKER_COUNT;
    if(LoggedData?.is_user && user_id){
      url = url + `?login_user_id=${user_id}`
    }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response?.data?.status === "success") {
            this.setState({
              MakerCheckerCnt: response.data
            }, () => {
              this.props.getmakerCheckercount(response.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

    renderComponent = () => {
        switch (this.state.SelectedTab) {
            case "concession": return <ConcessionApproval history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
            case "bill_cancel": return <CancelBillApprovals history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
            case "final_bill": return <FinalBillRevertApprovals history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
            case "overall_bill_concession": return <OverAllBillConcession history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
            case "expense": return <ExpenseApprovals history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
            case "bill_modify": return <ModifyBillApprovals history={this.props.history} triggerCountsApi={this.getMakerCheckerCount}/>
        }
    }
    render() {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className='eMed_tabWrapper' display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={"90vw"}>
                    <Tabs
                        value={this.state.SelectedTab}
                        sx={{ minHeight: '1dvw' }}
                        onChange={(e, newValue) => this.setState({
                            SelectedTab: newValue,
                        }, () => {this.getMakerCheckerCount()})}>
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Concession{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.concession_count ? this.state.MakerCheckerCnt?.concession_count : 0}</span></span>} value={"concession"} />
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Bill Modification{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.modify_count ? this.state.MakerCheckerCnt?.modify_count : 0}</span></span>} value={"bill_modify"} />
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Bill Cancel{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.cancel_count ? this.state.MakerCheckerCnt?.cancel_count : 0}</span></span>} value={"bill_cancel"} />
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Final Bill Revert{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.final_bill_count ? this.state.MakerCheckerCnt?.final_bill_count : 0}</span></span>} value={"final_bill"} />
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Expense{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.expense_count ? this.state.MakerCheckerCnt?.expense_count : 0}</span></span>} value={"expense"} />
                        <Tab className='emed_tab_font' label={<span className='emed_tab_font'>Over All Bill Concession{' '}<span className='Tab_batch_count'>{this.state.MakerCheckerCnt?.final_concession ? this.state.MakerCheckerCnt?.final_concession : 0}</span></span>} value={"overall_bill_concession"} />
                    </Tabs>
                </Box>
                <Box>
                    {this.renderComponent()}
                </Box>
            {this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
            {this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
            </Box>

        )
    }
}
