import React, { Component } from 'react'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { Colors } from '../../../../Styles/Colors'
import Loader from '../../../../Components/Loader';
import { DataGrid } from '@mui/x-data-grid'
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker'
import { DateTime } from 'luxon'
import './Reports.css'

export default class DocDoctorVisitReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      doctorVisitReportData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      startDate: '',
      endDate: '',
      dateIndex: 0,
      selectedDoctor: "All",
      doctorId: null,
      isOPT: false,
      fromCounsellor: this.props.history?.location?.state?.fromCounsellor
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = this.props.fromOT ? JSON.parse(getCachevalue("OTpatientData")) : JSON.parse(getCachevalue('DoctorPatientData'))
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    this.setState({
      clinicId: clinicId ? clinicId : myUser?.clinic_id,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id ? appointmentId?.patient_id : this.props.history?.location?.state?.patientDetails?.patient_id,
      doctorId: appointmentId?.doctor_id,
      isOPT: UserData.service_type === "OPT" ? true : false
    }, () => {
      this.getPatientDetailData()
      this.getDoctorClinicListData()
      this.getDoctorVisitReportData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorClinicListData = () => {
    try {
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let isOpthometry = UserData?.module_name === "Optometry"
      RestAPIService.getAll(isOpthometry ? Serviceurls.DOC_OPTHOMETRY_CLINIC_LIST_GET : Serviceurls.DOC_DOCTOR_CLINIC_LIST_GET + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              doctorClinicList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorVisitReportData = () => {
    try {
      let doctorId = this.state.selectedDoctor ? this.state.selectedDoctor == "All" ? "" : this.state.selectedDoctor?.doctor_id : ''
      this.setState({ loading: true })
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let ApiUrl = ""
      if (UserData?.service_type === "OPTOMETRY" || this.props.history?.location?.state?.fromCounsellor) {
        ApiUrl = Serviceurls.DOC_DOCTOR_VISIT_REPORT + `?patient_id=${this.state.patientId}&start_date=${this.state.startDate}&end_date=${this.state.endDate}&clinic_id=${UserData?.clinic_id}&doctor_id=${doctorId}`
      } else {
        ApiUrl = Serviceurls.DOC_DOCTOR_VISIT_REPORT + `?patient_id=${this.state.patientId}&start_date=${this.state.startDate}&end_date=${this.state.endDate}&clinic_id=&doctor_id=${doctorId}`
      }
      RestAPIService.getAll(ApiUrl)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              doctorVisitReportData: response?.data?.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.data) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.data)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  getDoctorVisitReportPrint = (item) => {
    let ServiceURL = "";
    ServiceURL = item?.is_opt ? Serviceurls.DOC_DOCTOR_NOTES_PRINT_OPT : Serviceurls.DOC_DOCTOR_VIST_REPORT_PRINT
    try {
      // &doctor_notes=all
      RestAPIService.getAll(ServiceURL + `?appointment_id=${item?.appointment_id}&clinic_id=${this.state.clinicId}&doctor_id=${item?.doctor_id}&doctor_notes=`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  filterDate = (From, To, Index) => {
    this.setState({
      startDate: From,
      endDate: To,
      dateIndex: Index
    })
  }

  onClearDoctorVisitReport = () => {
    try {
      this.setState({
        startDate: '',
        endDate: '',
        dateIndex: 0,
        selectedDoctor: "All"
      }, () => { this.getDoctorVisitReportData() })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      this.state.doctorVisitReportData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        { field: 'visit_date', headerName: 'Visited Date', flex: 0.2 },
        { field: 'visit_time', headerName: 'Visited Time', flex: 0.15 },
        {
          field: 'branch_name', headerName: 'Branch Name', flex: 0.2,
          renderCell: (params) => {
            return (
              <Box>
                {params?.row?.branch_name ? params?.row?.branch_name?.length > 20 ?
                  <Tooltip placement='top' title={params?.row?.branch_name}><Typography>{params?.row?.branch_name?.slice(0, 20) + '...'}</Typography></Tooltip> :
                  <Typography>{params?.row?.branch_name}</Typography> : '-'}
              </Box>
            )
          }
        },
        {
          field: 'doctor_name', headerName: 'Doctor Name', flex: 0.2,
          renderCell: (params) => {
            return (
              <Box>
                {params?.row?.doctor_name ? params?.row?.doctor_name?.length > 20 ?
                  <Tooltip placement='top' title={params?.row?.doctor_name}><Typography>{params?.row?.doctor_name?.slice(0, 20) + '...'}</Typography></Tooltip> :
                  <Typography>{params?.row?.doctor_name}</Typography> : '-'}
              </Box>
            )
          }
        },
        {
          field: 'visit_note', headerName: 'Medical Report', flex: 0.25,
          renderCell: (params) => (
            <Box component={'div'}>

              {params?.row?.visit_note === 'View' ?
                <Box>
                  <Button
                    className='Common_Btn_Min_Width'
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => {
                      // if (this.state.doctorId === params?.row?.doctor_id) {
                      //   setCachevalue(true, "isForVisitreport")
                      //   this.props.history?.push({ pathname: '/DoctorHome/DoctorNotes', state: { appointmentId: params?.row?.appointment_id } })
                      // } else {
                      this.getDoctorVisitReportPrint(params?.row)
                      // }
                    }}
                  >{'Print'}</Button>
                  {params?.row?.reason_for_admission ? params?.row?.reason_for_admission?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.reason_for_admission}><Typography>{params?.row?.reason_for_admission?.slice(0, 20) + '...'}</Typography></Tooltip> :
                    <Typography fontSize={'0.8vw'}>{params?.row?.reason_for_admission}</Typography> : null
                  }
                </Box>
                :
                <Box>
                  <Typography fontSize={params?.row?.is_ip_patient ? '0.9vw' : ''}>{params?.row?.visit_note}</Typography>
                  {params?.row?.reason_for_admission ? params?.row?.reason_for_admission?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.reason_for_admission}><Typography>{params?.row?.reason_for_admission?.slice(0, 20) + '...'}</Typography></Tooltip> :
                    <Typography fontSize={'0.8vw'}>{params?.row?.reason_for_admission}</Typography> : null
                  }
                </Box>
              }
            </Box>
          )
        },
      ]
      return (
        <DataGrid
          rows={this.state.doctorVisitReportData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          sx={{
            backgroundColor: Colors.white,
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "inherit"
            }
          }}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          getRowClassName={(params) => params.row?.is_ip_patient ? 'eMedhub_highlight_column' : null}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Doctor Visit Report</Typography>
          </Box>
          <Box component={'div'} height={'23vw'} sx={{ background: Colors.white }} p={'1vw'}>
            <Box component={'div'} width={'21vw'}>
              <CommonDatePicker
                FromDate={this.state.startDate}
                ToDate={this.state.endDate}
                DateIndex={this.state.dateIndex}
                SelectedDates={this.filterDate.bind(this)}
              // HideAllMenu={true}
              // PharmaDateFilterRestriction={false}
              />
            </Box>
            <Box mt={'0.5vw'}>
              {/* <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '20vw' }}
                autoComplete='off'
                options={this.state.doctorClinicList}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.first_name}
                value={this.state.selectedDoctor}
                onChange={(event, newValue) => this.setState({ selectedDoctor: newValue })}
                renderInput={(params) => <TextField {...params} label='Select Doctor' />}
              /> */}
              <FormControl size='small' className='eMed_small_textFields'>
                <InputLabel>{"Select Doctor"}</InputLabel>
                <Select
                  value={this.state.selectedDoctor ? this.state.selectedDoctor : ""}
                  label={"Select Doctor"}
                  onChange={(e) => { this.setState({ selectedDoctor: e.target.value }) }}
                  MenuProps={{
                    style: { maxHeight: 350 }
                  }}
                >
                  <MenuItem value={"All"} >{"All"}</MenuItem>
                  {
                    this.state.doctorClinicList?.length > 0 ?
                      this.state.doctorClinicList.map((list, index) => (
                        <MenuItem key={index} value={list}>{`${(list?.title ? list?.title : "")} ${list?.first_name} ${(list?.initial ? list?.initial : "")}`}</MenuItem>
                      )) : null
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => { this.onClearDoctorVisitReport() }}
            >Clear</Button>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => { this.getDoctorVisitReportData() }}
            >Search</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={this.state.fromCounsellor ? '79vh' : '74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={this.state.fromCounsellor ? '65.5vh' : '61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
