import { Box, Button, Drawer } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { DateTime } from 'luxon'
import { DataGrid } from '@mui/x-data-grid'
import { Colors } from '../../../../Styles/Colors'
import PharmaFilter from '../PharmaFilter'

export default class CreditNoteReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creditNoteData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "vendorList": [],
        "days": 91,
      },
      pageName: 'creditNote',
      totalCreditValue: 0
    }
  }

  componentDidMount() {
    this.getCreditNoteData()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getCreditNoteData = () => {
    try {
      this.setState({ loading: true })
      let vendorId = [];
      this.state.filterDatas.vendorList?.forEach(element => vendorId?.push(element?.vendor_id))
      RestAPIService.getAll(Serviceurls.PHARMA_CREDIT_NOTE_REPORT + `?vendor_id=${vendorId}&from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              creditNoteData: response.data.data,
              totalCreditValue: response.data.total_amount,
              loading: false
            })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>

    )
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page: 0,
    }, () => { this.getCreditNoteData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  render() {
    this.state.creditNoteData?.forEach((element, index) => element.sno = index + 1)

    let columns = [
      { field: 'vendor_name', headerName: 'Vendor Name', flex: 0.25 },
      { field: 'credit_number', headerName: 'Credit Number', flex: 0.25 },
      { field: 'credit_date', headerName: 'Credit Date', flex: 0.25 },
      { field: 'credit_value', headerName: 'Credit Value', flex: 0.25 },
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Total Credit Value", this.state.totalCreditValue, false, "white", false, null, 'rpt_purcrt_total_cre')}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid="rpt_crenot_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/* <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid="rpt_crenot_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.creditNoteData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {("No Records To Be Shown")}
                </Box>
              )
            }}
            rowCount={this.state.rowCount}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading={this.state.loading}
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
