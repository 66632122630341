import { Box, Stack, Tooltip, IconButton, } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, ConfirmPopup } from '../../../../Components/Common Components/CommonComponents'
import './patientDetails.css'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'


class TreatmentPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            planData: [],
            patientData: {},
            attender_name: "",
            attender_mobile: "",
            vital_attender_mobile: "",
            vital_attender_name: "",
            vital_doctor_name: "",
            vital_doctor_id: "",
            isHistoryClicked: false,
            vitalHistory: [],
            checkVitals: false,
            withOutAppointment: {},
            ClinicID: "",
            isLoader: false,
            isComplete: false,
            postList: {},
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var selectedClinic = JSON.parse(localGetItem("SelectedDoctorClinicId"))
        if (selectedClinic) {
            this.setState({
                ClinicID: selectedClinic
            })
        }

        let patientDatas = getCachevalue("patientData")
        let patients = JSON.parse(patientDatas)
        this.setState({
            patientData: patients
        }, () => {
            if (this.state.patientData?.appointment_id) {
                this.getTreatmentPlanData()
            }
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getTreatmentPlanData = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PLAN_GET + `?appointment_id=${this.state.patientData?.appointment_id}&patient_id=${this.state.patientData?.patient_id}&doctor_id=${this.state.patientData?.doctor_id}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            planData: response.data.data.length > 0 ? response.data.data : [],
                            // attender_name: response.data.patient_data[0].attender_name ? response.data.patient_data[0].attender_name : '',
                            // attender_mobile: response.data.patient_data[0].attender_mobile ? response.data.patient_data[0].attender_mobile : '',
                            isLoader: false
                        })
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    postTreatment = () => {
        try {
            let states = this.state
            let data = {
                "patient_id": states.patientData?.patient_id,
                "appointment_id": states.patientData?.appointment_id,
                "doctor_id": states.patientData?.doctor_id,
                "is_completed": true,
                "treatment_id": states?.postList?.treatment_id,
            }
            this.setState({ isLoader: true, disableBtn: true })
            RestAPIService.patch(data, Serviceurls?.DOC_DOCTOR_NOTES_PLAN_GET)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ isLoader: false, disableBtn: false, isComplete: false, })
                        this.successMessage(response?.data?.message)
                        this.getTreatmentPlanData()
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false, disableBtn: false })
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.setState({ isLoader: false, disableBtn: false })
            this.errorMessage(e.message)
        }
    }

    renderNurseNotesHeader = () => {
        const { t } = this.props
        let data = {
            lineOne: t("Emergency Contact Person"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('Emergency Mobile No'),
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        let vital_attender_data = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.vital_attender_name ? this.state.vital_attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.vital_attender_mobile ? this.state.vital_attender_mobile : "-"}`
        }
        let vital_doctor_data = {
            image: ImagePaths.Doctor_Icon.default,
            height: "2.3vw",
            width: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${this.state.vital_doctor_name ? this.state.vital_doctor_name : "-"}`
        }
        return (
            <Box display={'flex'} >
                <Box className='eMed_nur_notes_details'>
                    <CommonPatientDetails data={this.state.patientData} />

                </Box>
                <CommonPatientCard details={data} showDetailed={true} />
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }

    renderTbl = () => {
        try {
            const { t } = this.props
            const columns = [
                {
                    field: "treatment_name", flex: 0.30, headerName: t("Treatment"), type: 'string',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.treatment_name ? params?.row?.treatment_name?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.treatment_name} arrow>
                                <div>{params?.row?.treatment_name.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.treatment_name : "-"}
                    </Box>)
                },
                {
                    field: "dosage", flex: 0.25, headerName: t("Dosage"), type: 'string',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.dosage ? params?.row?.dosage?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.dosage} arrow>
                                <div>{params?.row?.dosage.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.dosage : "-"}
                    </Box>)
                },
                {
                    field: "route", flex: 0.30, headerName: t("Route"), type: 'string',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.route ? params?.row?.route?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.route} arrow>
                                <div>{params?.row?.route.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.route : "-"}
                    </Box>)
                },
                {
                    field: "procedure_name", flex: 0.30, headerName: t("Procedure"), type: 'string',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.procedure_name ? params?.row?.procedure_name?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.procedure_name} arrow>
                                <div>{params?.row?.procedure_name.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.procedure_name : "-"}
                    </Box>)
                },
                {
                    field: "description", flex: 0.30, headerName: t("Description"), type: 'string',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.description ? params?.row?.description?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.description} arrow>
                                <div>{params?.row?.description.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.description : "-"}
                    </Box>)
                },
                {
                    field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Tooltip title={params?.row?.is_completed ? 'Finished' : "Finish"} placement='top'>
                            <IconButton disabled={params?.row?.is_completed} onClick={() => { this.setState({ isComplete: true, postList: params?.row }) }}>
                                <img style={{ width: '1.5vw', height: '1.5vw' }} src={params?.row?.is_completed ? ImagePaths.DisTick.default : ImagePaths.ColorTick.default} alt='img' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            ]
            return (
                <Box sx={{ width: '92vw', height: '63vh', padding: '.5vw' }}>
                    <DataGrid
                        rows={this.state.planData}
                        columns={columns}
                        getRowId={(row) => row.procedure_id}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => {
                                return (
                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        loading={this.state.isLoader}
                    />
                </Box>
            )
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    popClose = () => {
        this.setState({
            isComplete: false,
            disableBtn: false,
        })
    }

    confirmRemove = () => {
        this.postTreatment()
    }

    render() {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        return (
            <Box component={'div'} sx={{ backgroundColor: '#F5F8F7', height: '78vh' }}>
                <Box component={'div'}>
                    {this.renderNurseNotesHeader()}
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} sx={{ width: '92vw', height: '63vh', margin: '.5vw', backgroundColor: 'white' }}>
                        {this.renderTbl()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isComplete ? <ConfirmPopup
                    cnfTitle={t('Confirmation')}
                    cnfContent={t('Are you sure, do you want to Complete the Treatment Plan?')}
                    cnfAlertPopupClose={this.popClose.bind(this)}
                    cnfData={this.confirmRemove.bind(this)}
                    cnfDisable={this.state.disableBtn}

                /> : null}
            </Box>
        )
    }
}


export default withTranslation()(TreatmentPlan)