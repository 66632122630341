import React, { Component } from 'react'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Autocomplete, Box, Button, Drawer, TextField, Tooltip, Typography, Stack } from '@mui/material'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DateTime } from 'luxon'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { AmountFormat, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../Utility/Constants'
import { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from '../../../Components/Common Components/CommonComponents'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'

export default class CorporateBillReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            corporateBillData: [],
            totalAmount: 0,
            totalNetAmount: 0,
            corporatePayable: 0,
            returnAmount: 0,
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            corporateFilterList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            companyName: [],
            employeeName: "",
            FilterOpen: false,
            patientPayable:0
        }
    }

    componentDidMount() {
        this.getLabCorporateBillReportData()
        this.getCorportateFilterList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getLabCorporateBillReportData = () => {
        try {
            let companyName = [];
            this.state.companyName.forEach(element => companyName.push(element.company_name))
            var { FilterDatas } = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_CORPORATE_PATIENT + `?mode=laboratory&employee_name=${this.state.employeeName}&company_name=${companyName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            corporateBillData: response.data.data,
                            totalAmount: response.data.total_amount,
                            totalNetAmount: response.data.net_total_amount,
                            corporatePayable: response.data.corporate_payable,
                            returnAmount: response.data.return_amount,
                            patientPayable:response.data.patient_payable
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getCorportateFilterList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            corporateFilterList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gridToolBar = () => {
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    selectedTime = (start, end, error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box sx={{ width: '22vw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            fullWidth
                            size='small'
                            label={("Employee Name")}
                            name="employeeName"
                            value={this.state.employeeName}
                            onChange={(e) => { this.onChangeHandler(e) }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.corporateFilterList}
                            getOptionLabel={(option) => (option?.company_name)}
                            value={this.state.companyName}
                            onChange={(event, newValue) => {
                                this.setState({ companyName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={("Select Corporate Name")}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                companyName: [], employeeName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getLabCorporateBillReportData())
                        }}
                        id="eMed_Config_btn">{("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getLabCorporateBillReportData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.corporateBillData.forEach((element, index) => element.sno = index + 1)

        const columns = [
            {
                field: 'employee_name', headerName: 'Employee Details', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.employee_name ? params?.row?.employee_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.employee_name} arrow><div>{params?.row?.employee_name.slice(0, 20)}</div></Tooltip> : params?.row?.employee_name : "-"}
                    {params?.row?.employee_id ? <Typography id='eMed_Com_Patient_UhidText'>ID: {params?.row?.employee_id}</Typography> : ""}
                </Box>)
            },
            {
                field: 'company_name', headerName: 'Company Name', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.company_name ? params?.row?.company_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.company_name} arrow><div>{params?.row?.company_name.slice(0, 20)}</div></Tooltip> : params?.row?.company_name : "-"}</Box>)
            },
            {
                field: 'invoice_number', headerName: 'Bill Number', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                    <Box>{params?.row?.invoice_number ?
                        "..." + params?.row?.invoice_number.slice(9) : params?.row?.invoice_number}</Box>
                </Tooltip> : "-"}</Box>)
            },
            {
                field: 'invoice_date', headerName: 'Bill Date And Time', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: 'gross_amount', headerName: 'Total Value', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.gross_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'payable_amount', headerName: 'Corporate Payable', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.payable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'patient_payable', headerName: 'Patient Payable', flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.patient_payable || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'}>
                <Box component={'div'} height={'79dvh'}>
                    <Box component={'div'} height={'15dvh'} alignItems={'center'} p={'0.8rem 0rem'} display={'flex'} justifyContent={'space-between'}>
                        <Box component={'div'} display={'flex'}>
                            {AmountsCard(("Total Amount"), this.state.totalAmount)}
                            {/* {AmountsCard(("Total Net Amount"), this.state.totalNetAmount)} */}
                            {AmountsCard(("Corporate Payable"), this.state.corporatePayable)}
                            {AmountsCard(("Patient Payable"), this.state.patientPayable)}
                            {/* {AmountsCard(("Return Amount"), this.state.returnAmount)} */}
                        </Box>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box component={'div'} height={'64dvh'} p={'0rem 0.8rem'}>
                        <DataGrid
                            rows={this.state.corporateBillData}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: ""
                            }}
                            components={{
                                Toolbar: this.gridToolBar,
                                NoRowsOverlay: () => (
                                    <Box className='eMed_conf_nodata'>
                                        {("No Records Found")}
                                    </Box>
                                )
                            }}
                            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                            rowsPerPageOptions={[10, 20, 30]}
                            pagination
                            headerHeight={40}
                            disableSelectionOnClick
                            loading={this.state.isLoader}
                        />
                    </Box>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
