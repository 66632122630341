import React, { Component } from 'react';
import './IPPatientsHome.css';
import {
    IconButton, Tooltip, Box, Typography, MenuItem, Stack, Button, Menu,
} from '@mui/material';
import { ImagePaths } from '../../.././Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { AppointmentCard, CommonGridToolBarWithFilterText, CommonPatientDetails, ReasonPopup, ReasonPopupWithRefund } from "../../../Components/Common Components/CommonComponents";
import { DataGrid } from '@mui/x-data-grid';
import RestAPIService from '../../.././Utility/Services/RestAPIService';
import { Serviceurls } from '../../.././Utility/API/Serviceurls';
import ToastMsg from '../../.././Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';
import { AmountFormat, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import MoreVertIcon from '@mui/icons-material/MoreVert';

class RoomAvailability extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reservation_data: [],
            selectedCard: "",
            isReserve: false,
            PatientBlockList: [],
            PatientFloorList: [],
            PatientWardList: [],
            AttenderBlockList: [],
            AttenderFloorList: [],
            AttenderWardList: [],
            WardList: [],
            doctorList: [],
            filteredDocList: [],
            specList: [],
            ipPatientList: [],
            mobile_no: "",
            uhid: "",
            patient_name: "",
            patient_id: "",
            ip_No: "",
            ip_admission_id: "",
            admission_date: new Date(),
            reserv_doctor: null,
            reserv_spec: null,
            complaints: "",
            preference: "",
            reserv_block: "",
            reserv_floor: "",
            reserv_ward: "",
            attender_reserv_block: "",
            attender_reserv_floor: "",
            attender_reserv_ward: "",
            isCancel: false,
            SelectedPatientDetails: {},
            ComplaintsList: localGetItem("ipComplaintsDataList") === null ? [] : JSON.parse(localGetItem("ipComplaintsDataList")),
            all_total: 0,
            reserved_total: 0,
            completed_total: 0,
            cancel_total: 0,
            page: 0,
            pageSize: 10,
            DateTimerOpen: false,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            isAlsoforAttender: false,
            ReceiptCancelFor: 'Cancel',
            fromDate: this.props.dateDetails?.fromDate ? this.props.dateDetails?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: this.props.dateDetails?.toDate ? this.props.dateDetails?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: this.props.dateDetails?.dateIndex ? this.props.dateDetails?.dateIndex : 1,
        }
    }

    componentDidMount() {
        this.getReserveDetails();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.dateDetails !== this.props.dateDetails) {
            this.setState({
                fromDate: this.props.dateDetails?.fromDate ? this.props.dateDetails?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: this.props.dateDetails?.toDate ? this.props.dateDetails?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: this.props.dateDetails?.dateIndex ? this.props.dateDetails?.dateIndex : 1,
            }, () => this.getReserveDetails())
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            disableBtn: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getReserveDetails = () => {
        this.setState({
            reservation_data: []
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(`${Serviceurls.FO_RESERVATION_GET}?status=${this.state.selectedCard ? this.state.selectedCard : ""}&from_date=${this.state.fromDate ? this.state.fromDate : ""}&to_date=${this.state.toDate ? this.state.toDate : ""}`)
                .then(response => {
                    let data = response?.data;
                    if (response.data.status === "success") {
                        this.setState({
                            all_total: data?.count?.total,
                            reserved_total: data?.count?.reserved,
                            completed_total: data?.count?.completed,
                            cancel_total: data?.count?.cancelled,
                            reservation_data: data.data
                        }, () => {
                            this.state["reservation_data"].forEach((item, i) => item["index"] = i);
                            this.LoaderFunction(false);
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response?.data?.message, false);
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message, false);
                    }
                });
        } catch (e) {
            this.errorMessage(e.message, false);
        }
    }

    reasonPopupClose = () => {
        this.setState({
            isCancel: false,
            SelectedPatientDetails: {},
            isSelected: false,
            selectedRow: '',
        })
    }

    addCancelComments = (datas) => {
        let states = this.state;
        let payment = []
        if (datas?.isCash) {
            payment.push(1)
        }
        if (datas?.isUPI) {
            payment.push(3)
        }
        if (datas?.isBankTranfer) {
            payment.push(5)
        }
        let data = {
            reservation_id: states.SelectedPatientDetails?.id ? states.SelectedPatientDetails?.id : null,
            cancel_reason: datas?.receiptType === "Cancel" ? datas?.comments : '',
            receipt_for: datas?.receiptType === "Cancel" ? "Cancelled" : "Refund",
            receipt_cancel_reason: datas?.receiptType !== "Cancel" ? datas?.comments : '',
            with_reservation: false,
            status: 'Cancelled',
            payment_mode: payment,
            amount_received: states.SelectedPatientDetails?.advance_amount ? states.SelectedPatientDetails?.advance_amount : 0,
            invoice_date: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss'),
            amount_cash: datas?.CashAmount ? +datas?.CashAmount : 0,
            upi_amount: datas?.UPIAmount ? +datas?.UPIAmount : 0,
            bank_transfer_amount: datas?.BankTfrAmount ? +datas?.BankTfrAmount : 0,
            bank_ref_no: datas?.BankTfrNumber ? datas?.BankTfrNumber : "",
            upi_ref_no: datas?.UPINumber ? datas?.UPINumber : "",
        }
        try {
            RestAPIService.create(data, Serviceurls.FO_RESERVATION_GET)
                .then(response => {
                    if (response.data.status === "success") {
                        this.reasonPopupClose();
                        this.successMessage(response.data?.message);
                        this.getReserveDetails();
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data?.message);
                    } else {
                        this.errorMessage(error.message)
                    }
                });
        } catch (e) {
            this.errorMessage(e?.message, false);
        }
    }

    renderReserveType = () => {
        const { t } = this.props
        var states = this.state
        return (
            <div className='eMed_OP_AddPatient'>
                <div className='eMed_OP_PatientType_CreateBtn'>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Tooltip title={t('CreateReservation')} placement='top' arrow>
                            <IconButton size="small" onClick={() => { this.props.history.push({ pathname: "/IPHome/Reservation/Create" }) }}>
                                <img className='eMed_OP_AddPatient_Icon' src={ImagePaths.ReserveRoom.default} alt={"Add Patient"} />
                            </IconButton>
                        </Tooltip>
                        <Typography sx={{ fontSize: "0.8vw", color: "#04B7B1", fontWeight: 600 }}>{t("Create Reservation")}</Typography>
                    </Stack>
                </div>
                <div>
                    <div className='eMed_OP_Apt_Cards'>
                        <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getReserveDetails() }) }} count={`${states.all_total ? states.all_total : 0}`} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
                        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Reserved' }, () => { this.getReserveDetails() }) }} count={`${states.reserved_total ? states.reserved_total : 0}`} label={t("Reserved")} color={"#0000ff"} isSelectedCard={states.selectedCard === "Reserved" ? true : false} />
                        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Completed' }, () => { this.getReserveDetails() }) }} count={`${states.completed_total ? states.completed_total : 0}`} label={t("Completed")} color={"#04B7B1"} isSelectedCard={states.selectedCard === "Completed" ? true : false} />
                        <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getReserveDetails() }) }} count={`${states.cancel_total ? states.cancel_total : 0}`} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} />
                    </div>
                </div>
            </div>
        )
    }

    routeToDemograhics = (params) => {
        try {
            let { history } = this.props;
            setCachevalue(JSON.stringify(params?.row), "reservePatientData")
            if (!params?.row?.is_attender) {
                if (params.field === "patient_name" || (params.field === "ipRecords") || params.field === "doctor_name" || params.field === "speciality__name__name" || (params.field === "is_attender") || (params.field === "ward_block_floor")) {
                    history.push({ pathname: "/IPHome/Reservation/Demographics" })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    renderReserveTable = () => {
        const { t } = this.props;
        let states = this.state;
        let multi_option = [{ label: "Cancel", value: "Cancel" }]
        const columns = [
            { field: 'patient_name', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.20, renderCell: ({ row }) => (<CommonPatientDetails data={row} showAdmissionDateOnly={true} showAproxDate={true} />), density: 'compact' },
            {
                field: 'complaints', headerName: t('Diagnosis'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact',
                renderCell: ({ row }) => (
                    <div>{row.complaints && row.complaints?.length > 15 ?
                        <Tooltip title={row.complaints ? row.complaints : null} placement={'top'} arrow>
                            <Typography>{row.complaints ? row.complaints?.slice(0, 13) + '...' : '-'}</Typography>
                        </Tooltip> : <Typography>{row.complaints ? row.complaints : '-'}</Typography>} </div>
                )
            },
            {
                field: 'doctor_name', headerName: t('Consultant'), headerClassName: 'eMed_Apt_TblHead', flex: 0.13, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.doctor_name?.length > 20 ? <Tooltip placement="top" title={row?.doctor_name} arrow><div>{row?.doctor_name?.slice(0, 18) + "..."}</div></Tooltip> : row.doctor_name ? row.doctor_name : "-"}</div>)
            },
            {
                field: 'speciality_name', headerName: t('Speciality'), headerClassName: 'eMed_Apt_TblHead', flex: 0.11, density: 'compact',
                renderCell: ({ row }) => (<div>{row?.speciality_name?.length > 12 ? <Tooltip placement="top" title={row?.speciality_name} arrow><div>{row?.speciality_name?.slice(0, 10) + "..."}</div></Tooltip> : row.speciality_name ? row.speciality_name : "-"}</div>)
            },
            {
                field: 'is_attender', headerName: t('For'), headerClassName: 'eMed_Apt_TblHead', flex: 0.1, density: 'compact', headerAlign: 'center', align: "center",
                renderCell: ({ row }) => (<div>{row?.is_attender ? "Attender" : "Patient"}</div>)
            },
            {
                field: 'ward_block_floor', headerName: t('Ward/Block/Floor'), headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact',
                renderCell: ({ row }) => {
                    let ward_block_floor = `${row.ward_name ? row.ward_name : "-"}/${row.block_name ? row.block_name : "-"}/${row.floor_number ? row.floor_number : (row.floor_number == 0 ? 0 : "-")}`
                    return (<div>{ward_block_floor.length > 15 ? <Tooltip placement="top" title={ward_block_floor} arrow><div>{ward_block_floor?.slice(0, 15) + "..."}</div></Tooltip> : ward_block_floor ? ward_block_floor : "-"}</div>)
                }
            },
            {
                field: 'advance_amount', headerName: `${t("Advance")} (${CurrencySymbol})`, headerClassName: 'eMed_Apt_TblHead', flex: 0.12, density: 'compact', type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.advance_amount ? AmountFormat(params?.row?.advance_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: 'status', headerName: t('status'), headerClassName: 'eMed_Apt_TblHead', flex: 0.15, density: 'compact',
                renderCell: ({ row }) => (
                    <div><Tooltip placement='top' title={row?.cancel_reason} arrow><Button size='small' color={row?.status === "Reserved" ? 'info' : row?.status === "Completed" ? "primary" : 'error'} sx={{ textTransform: 'capitalize', width: '10vw' }} variant='contained'>{row?.status === "Reserved" ? t("Reserved") : row?.status === "Completed" ? t("Completed") : t("Cancelled")}</Button></Tooltip></div>
                )
            },
            {
                field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.10, headerAlign: 'center', align: "center", hideable: false, sortable: false, density: 'compact',
                renderCell: ({ row }) => (
                    <Stack direction="row">
                        <Tooltip title={t('Create Admission')} placement='top' arrow>
                            <IconButton size="small" disabled={row?.status !== "Reserved" || row?.is_attender} onClick={() => { this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { SelectedPatientDetails: row, ReservationClicked: true } }) }}>
                                <img className='eMed_OP_AptTbl_Status_Icon' src={(row?.status === "Reserved" && !row?.is_attender) ? ImagePaths.CreateIPAdmission.default : ImagePaths.DisCreateIPAdmission.default} alt='Create Appointments' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Receive Payment')} placement='top' arrow>
                            <div>
                                <IconButton disabled={(row?.status === "Reserved" && !row?.is_attender) ? false : true} onClick={() => {
                                    let { history } = this.props;
                                    setCachevalue(JSON.stringify(row), "reservePatientData")
                                    history.push({ pathname: '/IPHome/Reservation/ReceivePayment' })
                                }}
                                ><img className='eMed_OP_AptTbl_Status_Icon' src={(row?.status === "Reserved" && !row?.is_attender) ? ImagePaths.BillCreated.default : ImagePaths.DisabledCreateBill.default} alt='Create Bill' /></IconButton>
                            </div>
                        </Tooltip>
                        <div>
                            <Tooltip title={"more"} placement='top' arrow>
                                <IconButton
                                    onClick={(e) => {
                                        this.setState({
                                            anchorEl: e.currentTarget,
                                            selectedRow: row.id,
                                            isSelected: true
                                        })
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                            {(this.state.isSelected && this.state.selectedRow == row.id) ?
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={() => {
                                        this.setState({ anchorEl: null, })
                                    }}
                                >
                                    {multi_option.map((option) => (
                                        <MenuItem key={option} disabled={(row.status === "Reserved" && !row?.is_attender) ? false : true}
                                            onClick={() => {
                                                if (option?.label === "Cancel") {
                                                    this.setState({ isCancel: true, SelectedPatientDetails: row })
                                                }
                                            }}>
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                : null}
                        </div>
                    </Stack>
                )
            }
        ];
        return (
            <div>
                <Box className='eMed_IPAptTbl_Con'>
                    <DataGrid
                        rows={states?.reservation_data}
                        columns={columns}
                        getRowId={(row) => row.index}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        rowsPerPageOptions={[10, 20, 30]}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        density={'compact'}
                        rowHeight={100}
                        pagination
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                        onCellClick={(params) => this.routeToDemograhics(params)}
                    />
                </Box>
            </div>
        )
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        return (
            <div id='eMed_IP_Appointment'>
                {this.renderReserveType()}
                {this.renderReserveTable()}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

                {states.isCancel ?
                    <ReasonPopupWithRefund
                        title={"Cancel Bill"}
                        AlertPopupClose={this.reasonPopupClose}
                        label={"Enter reason for cancel"}
                        btntext={'Close'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        IPRefundPost={this.addCancelComments}
                        fromReservation={true}
                        payableAmount={this.state.SelectedPatientDetails?.advance_amount}
                        BillReceivedAmount={this.state.SelectedPatientDetails?.advance_amount}
                    />
                    : null}
            </div>
        )
    }
}
export default withTranslation()(RoomAvailability);