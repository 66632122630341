import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../OutpatientHome.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Colors } from '../../../../Styles/Colors';
import './CreateBill.css';
import { Trans, withTranslation } from 'react-i18next';
import { t } from "i18next";
import { Demo_Layout_Default_JSON } from '../../../../Utility/Constants';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { AmountFormat, formatDate, CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';
import CommonOpBilling from './CommonOpBilling';
import { CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { getCachevalue, setCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import Loader from '../../../../Components/Loader';
import moment from "moment";
import { City, Country, State } from 'country-state-city';
class QuickBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutData: [],
      mandotory_count: '03',
      RightTabIndex: "1",
      OPServicesList: [],
      OPServiceTypeList: [],
      OPserviceTemplates: [],
      SelectedTemplateName: "",
      SelectedServiceName: "",
      SelectedServiceType: "",
      SelectedServices: [],
      Services: [],
      SpecializationList: [],
      DoctorList: [],
      TagsList: [],
      AppointmentTypeList: [],
      filteredDocList: [],

      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      selectedAppointTypeId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: "",

      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: "",
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",

      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      MandatoryLabels: [],
      CorporateList: [],
      patient_img: null,
      ProfilePicClicked: false,
      OpenAccordion: false,
      receive_email: false,
      receive_whatsapp: false,
      receive_sms: true,
      corporateName: "",
      SameMobileNumPatients: [],
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      DateOpen: false,
      PatientID: null,
      NurseList: [],
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      POVList: [],
      InsuranceList: [],
      RequestedPatientData: {},
      slotPopup: false,
      selectedSlot: "",
      old_referral_no: "",
      company_name: '',
      followupID: null,
      isDoctorMandatory: JSON.parse(localGetItem("loggedInUserInfo"))?.token_gen_type === "Specialization" ? false : true,
      isQmsConfig: false,
      QRPatientData: {},
      manuallySelected: false,
      manuallyChanged: false,
      selectedFatHusName:'',
      disableNameGenderEdit: false
    }
  }

  componentDidMount() {
    this.GetDatas()
    this.getInsuranceList()
    this.lodaerFunction(true)
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    let permissionAccess = userData?.permission_access?.common
    this.setState({ isQmsConfig: userData?.is_qms_enabled ? userData?.is_qms_enabled : false, disableNameGenderEdit: (permissionAccess?.is_demographics_edit && userData?.is_user) ? true : false})
    if (getCachevalue("OP_CreateAppointment_PatientId")) {
      this.setState({
        PatientID: getCachevalue("OP_CreateAppointment_PatientId")
      }, () => { this.getPatientDemographics() })
    }
    if (getCachevalue("AppRequestPatientData") && getCachevalue("AppRequestPatientData") !== JSON.stringify(this.state.RequestedPatientData)) {

      let Data = JSON.parse(getCachevalue("AppRequestPatientData"))
      if (Data?.appointment_date === DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd') || this.props.history?.location?.state?.fromFollowUp) {
        this.setState({
          RequestedPatientData: Data,
          first_name: Data?.first_name ? Data?.first_name : "",
          mobile_no: Data?.mobile_number ? Data?.mobile_number : "",
          selectedAppointTypeId: Data?.appoinment_type ? Data?.Data?.appoinment_type : null,
          purposeOfVisit: Data?.message ? Data?.message : "",
          followupID: this.props.history?.location?.state?.followupID ? this.props.history?.location?.state?.followupID : null,
          address_1: Data?.address_line_1 ? Data?.address_line_1 : "",
          address_2: Data?.address_line_2 ? Data?.address_line_2 :"",
          country_code: this.state.AllCountry?.find((item)=>(item.name === Data?.country))?.isoCode,
          AllStates: State.getStatesOfCountry(this.state.AllCountry?.find((item)=>(item.name === Data?.country))?.isoCode),
          state_code: State.getStatesOfCountry(this.state.AllCountry?.find((item)=>(item.name === Data?.country))?.isoCode)?.find((item)=>(item.name === Data?.state_name))?.isoCode,
          city: Data?.city_name? Data?.city_name :"" ,
          pin_code: Data?.pincode ? Data?.pincode : null,
          age: Data?.age ? Data?.age : null,
          // appointmentDateTime: new Date(`${Data?.appointment_date} ${Data?.appointment_time}`)
        }, () => {
          if (Data?.age) {
            this.setState({
              age: Data?.age,
              dob: this.handleFindApproxDob(Data?.age)
            })
          }
          if (Data?.patient_id) {
            this.setState({
              PatientID: Data?.patient_id ? Data?.patient_id : ""
            }, () => {
              this.lodaerFunction(true)
              this.getPatientDemographics()
            })
          }
        })
      }
    }

    if (getCachevalue("QRRequestPatientData") && getCachevalue("QRRequestPatientData") !== JSON.stringify(this.state.QRPatientData)) {
      let Data = JSON.parse(getCachevalue("QRRequestPatientData"))
      this.setState({
        QRPatientData: Data,
        first_name: Data?.patient_name ? Data?.patient_name : "",
        mobile_no: Data?.mobile_number ? Data?.mobile_number : "",
        address_1: Data?.address ? Data?.address : "",
        email_id: Data?.email ? Data?.email : "",
        gender: Data?.gender ? Data?.gender : "",
        pin_code: Data?.pincode ? Data?.pincode : "",
        referralSource: Data?.referral_source ? Data?.referral_source : "",
        father_husband_name: Data?.father_name ? Data?.father_name : "",
        occupation: Data?.occupation ? Data?.occupation : "",
        guardian: Data?.guardian_name ? Data?.guardian_name : "",
        marital_status: Data?.marital_status ?  (Data?.marital_status === 'Unmarried' ? 'Single' : Data?.marital_status) : "",
        aadhar_card_number: Data?.aadhar_number ? Data?.aadhar_number : "",
        selectedFatHusName:Data?.relationship_label ? Data?.relationship_label : '',
        address_1: Data.address_line_1 ? Data.address_line_1 : Data?.address ? Data?.address : '',
        address_2: Data.address_line_2,
        country_code: this.state.AllCountry.find((item)=>(item.name === Data?.country))?.isoCode,
        AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item)=>(item.name === Data?.country))?.isoCode),
        state_code: State.getStatesOfCountry(this.state.AllCountry.find((item)=>(item.name === Data?.country))?.isoCode).find((item)=>(item.name === Data?.state_name))?.isoCode,
        city: Data?.city_name,
        title: Data.title,
        emergency_contact_mobile: Data?.emergency_number ? Data?.emergency_number : "",
      }, () => {
        if (Data?.age) {
          this.setState({
            age: Data?.age,
            dob: this.handleFindApproxDob(Data?.age)
          })
        }
        this.CheckExistingPatients()
      })
    }

    let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
    let array = []
    JSON.parse(POVList).forEach(element => array.push(element.reason));
    if (array.length > 0) {
      this.setState({
        POVList: array
      })
    }
  }
  componentDidUpdate(preProps) {
    if (this.props != preProps) {
      let DocId = this.state.DoctorList.find(item => item.doctor_id === this.state.RequestedPatientData?.doctor_id)
      let SpecID = this.state.SpecializationList.find(item => item.id === DocId?.specialization_id)
      if (Object.keys(this.state.RequestedPatientData).length > 0 && !this.props?.is_Nurse) {
        this.setState({
          selectedDocId: this.state.RequestedPatientData?.doctor_id ? (DocId ? DocId : null) : null,
          selectedSpecId: this.state.RequestedPatientData?.doctor_id ? (SpecID ? SpecID : null) : null,
          selectedNurseId: null,
          purposeOfVisit: this.state.purposeOfVisit ? this.state.purposeOfVisit : SpecID?.purpose_of_visit ? SpecID?.purpose_of_visit : ''
        }, () => {
          if (DocId) {
            let states = this.state
            let docDetail = DocId
            let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter((item) => (item["specialization_id"] === docDetail["specialization_id"]))
            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
            this.setState({ states })
          }
        })
      } else {
        this.setState({
          selectedDocId: null,
          selectedSpecId: null,
          selectedNurseId: null,
        })
      }

    }
  }

  getInsuranceList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getLastVisitDateOutstandingAmount = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_LAST_VISIT_DATE_AND_BALANCE_AMOUNT + "?patient_id=" + this.state.PatientID + "&module=OP&branch_id=" + localGetItem("BranchId")).
        then((response) => {
          if (response.data.status === "success") {
            let Date = response.data?.last_visited_date ? response.data.last_visited_date : ""
            let Amount = response.data?.total_outstanding ? response.data.total_outstanding : 0
            let AdvanceBalance = response.data?.advance_balance ? response.data.advance_balance : 0
            this.props.VisitDateBalAmount({ "last_visited_date": Date, "total_outstanding": Amount, "advance_balance": AdvanceBalance })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getPatientDemographics = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + "?patient_id=" + this.state.PatientID)
        .then(response => {
          if (response.data.status === "success") {
            this.assignDemographicsData(response.data.data)
          }
        })
        .catch(error => {

        });
    }
    catch (e) {

    }
  }

  getSlotIntervel = () => {
    var date = new Date(this.state.appointmentDateTime)
    var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
    this.lodaerFunction(true)
    try {
      RestAPIService.getAll(Serviceurls.SCHEDULE_TIME_GET + "?date=" + appointment_date + "&doctor_id=" + this.state.selectedDocId?.doctor_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              slotList: response.data.data,
              slotPopup: true
            }, () => { this.lodaerFunction(false) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.lodaerFunction(false)
        })

    } catch (e) {
      this.errorMessage(e.message)
      this.lodaerFunction(false)
    }
  }

  assignDemographicsData(Datas) {
    try {
      this.setState({
        referredBy: Datas.reffered_by,
        referralSource: Datas.referral_source,
        mobile_no: Datas.mobile_number,
        first_name: Datas.first_name,
        age: this.handleAgeCalculation(Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob)),
        dob: Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob),
        gender: Datas.gender,
        title: Datas.title,
        middle_name: Datas.middle_name,
        surname: Datas.last_name,
        address_1: Datas.address_line_1,
        address_2: Datas.address_line_2,
        country_code: this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode,
        AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode),
        state_code: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode).find((item) => (item.name === Datas?.state_name))?.isoCode,
        city: Datas?.city_name,
        pin_code: Datas.pincode,
        employer_id: Datas.employer_id,
        employee_id: Datas.id_no,
        email_id: Datas.email,
        marital_status: Datas.marital_status,
        father_husband_name: Datas.father_husband_name,
        father_husband_age: Datas.father_husband_age ? Datas.father_husband_age : "",
        occupation: Datas.occupation,
        emergency_contact_person: Datas.caretaker_name,
        emergency_contact_mobile: Datas.caretaker_mobile,
        relationship: Datas.caretaker_relationship,
        docDocument: null,
        aadharProof: Datas.aadhar === "" ? null : Datas.aadhar,
        panProof: Datas.pan === "" ? null : Datas.pan,
        passportProof: Datas.passport === "" ? null : Datas.passport,
        rationProof: Datas.ration_card === "" ? null : Datas.ration_card,
        dlProof: Datas.dl === "" ? null : Datas.dl,
        pancard_number: Datas.pancard_number,
        ration_card_number: Datas.ration_card_number,
        dl_number: Datas.dl_number,
        passport_number: Datas.passport_number,
        aadhar_card_number: Datas.aadhar_card_number,
        patient_img: Datas?.photo_url === "" ? null : Datas.photo_url,
        receive_email: Datas?.receive_email,
        receive_whatsapp: Datas?.receive_whatsapp,
        receive_sms: Datas?.receive_sms,
        insurance_company_name: Datas?.insurance_company_name,
        policy_number: Datas?.policy_number,
        guardian: Datas?.guardian,
        mother_name: Datas?.mother_name,
        old_referral_no: Datas.old_referral_no ? Datas.old_referral_no : "",
        mother_age: Datas?.mother_age,
        company_name: Datas?.company_name ? Datas?.company_name : '',
        selectedFatHusName:Datas?.relationship_label ? Datas?.relationship_label : ''
      }, ()=>{
        this.setState({cityName: City.getCitiesOfState(this.state.country_code , this.state.state_code)})
        this.getLastVisitDateOutstandingAmount()
      })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  GetDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.FODemographicsLayout)
        .then((response) => {
          if (response.data.status === "success") {
            var Data = response.data.data
            var len = Data.length
            this.setState({
              layoutData: Data[len - 1] ? Data[len - 1].field_order : Demo_Layout_Default_JSON,
              mandotory_count: Data[len - 1] ? ("0" + Data[len - 1].mandatory_field_count) : '04',
            }, () => {
              let MandLabels = []
              for (let i = 0; i < +this.state.mandotory_count; i++) {
                MandLabels.push(this.state.layoutData[i].label)
              }
              this.setState({ MandatoryLabels: MandLabels })
              this.lodaerFunction(false)
            })
          }
        }).catch((error) => {
          this.lodaerFunction(false)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SpecializationList: response.data.data.filter((item) => (item.is_active === true))
            }, () => {
              if (Object.keys(this.state.RequestedPatientData).length > 0) {
                let DocID = this.state.DoctorList.find(item => item.doctor_id === this.state.RequestedPatientData?.doctor_id)
                let SpecID = this.state.SpecializationList.find(item => item.id === DocID?.specialization_id)
                this.setState({
                  selectedDocId: DocID ? DocID : null,
                  selectedSpecId: SpecID ? SpecID : null,
                }, () => {
                  if (DocID) {
                    let states = this.state
                    let docDetail = DocID
                    let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter(item => item["specialization_id"] === docDetail["specialization_id"])
                    states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                    this.setState({ states })
                  }
                })
              }

            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
      var date = new Date(this.state.appointmentDateTime)
      var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      var appointment_time = DateTime.fromJSDate(date).toFormat('HH:mm')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?date=${appointment_date}&time=${appointment_time}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DoctorList: response.data.data.filter((item) => (item.specialization_active === true))
            }, () => {
              if (Object.keys(this.state.RequestedPatientData).length > 0) {
                let DocID = this.state.DoctorList.find(item => item.doctor_id === this.state.RequestedPatientData?.doctor_id)
                let SpecID = this.state.SpecializationList.find(item => item.id === DocID?.specialization_id)
                this.setState({
                  selectedDocId: DocID ? DocID : null,
                  selectedSpecId: SpecID ? SpecID : null,
                }, () => {
                  if (DocID) {
                    let states = this.state
                    let docDetail = DocID
                    let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter(item => item["specialization_id"] === docDetail["specialization_id"])
                    states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                    this.setState({ states })
                  }
                })
              }

            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPPATIENTTAG).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              TagsList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              AppointmentTypeList: response.data.data.filter((item) => (item.is_active === true)),
            }, () => {
              if ((Object.keys(this.state.RequestedPatientData)?.length > 0) && !this.props.history?.location?.state?.fromFollowUp) {
                this.setState({
                  selectedAppointTypeId: this.state.RequestedPatientData?.booked_through === "Mobile" ? response.data.data.find((item) => (item.appoinment_type === "Mobile Appointment")) : response.data.data.find((item) => (item.appoinment_type === "Telephonic Appointment"))
                })
              } else {
                this.setState({
                  selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === "Walk in Appointment"))
                })
              }
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_STAFF_LIST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              NurseList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDoctorDetails = () => {
    try {
      var date = new Date(this.state.appointmentDateTime)
      var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      var appointment_time = DateTime.fromJSDate(date).toFormat('HH:mm')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?date=${appointment_date}&time=${appointment_time}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DoctorList: response?.data?.data?.filter((item) => (item?.specialization_active === true))
            }, () => {
              if (Object.keys(this.state.RequestedPatientData).length > 0) {
                let DocID = this.state.DoctorList?.find(item => item.doctor_id === this.state.RequestedPatientData?.doctor_id)
                let SpecID = this.state.SpecializationList?.find(item => item.id === DocID?.specialization_id)
                this.setState({
                  selectedDocId: DocID ? DocID : null,
                  selectedSpecId: SpecID ? SpecID : null,
                }, () => {
                  if (DocID) {
                    let states = this.state
                    let docDetail = DocID
                    let filteredLst = states["DoctorList"]?.length > 0 && states["DoctorList"]?.filter(item => item["specialization_id"] === docDetail["specialization_id"])
                    states["filteredDocList"] = filteredLst?.length > 0 && filteredLst;
                    this.setState({ states })
                  }
                })
              }

            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  renderDropDown = (label, stateKey, Data) => {
    let states = this.state;
    return (
      <Autocomplete
        size='small'
        clearIcon={false}
        disabled={this.props?.is_Nurse && label === "Specialization" ? true : false}
        sx={{ width: '14vw' }}
        componentsProps={{ popper: { style: { width: label === 'Doctor' ? (this.state.isQmsConfig ? "30vw" : "23vw") : "14vw" } } }}
        options={Data}
        getOptionLabel={(item) => (label === "Doctor" ? item.doctor_name : label === "Specialization" ? item.name : label === "Patient Tag" ? item.patient_tag_name : label === "Appointment Type" ? item.appoinment_type : label === "Nurse" ? item.nurse : "")}
        value={states[stateKey]}
        renderOption={label === 'Doctor' ? (props, item) => (
          <Box>
            {props["data-option-index"] === 0 ?
              <Box className="eMed_App_Doctor_table_head" sx={{ width: this.state.isQmsConfig ? "29vw" : "22vw" }}>
                <Typography width={this.state.isQmsConfig ? "40%" : "50%"} fontWeight={'600'} fontSize={"0.8vw"}>Doctor Name</Typography>
                <Typography width={this.state.isQmsConfig ? "15%" : "25%"} fontWeight={'600'} fontSize={"0.8vw"}>Total App</Typography>
                <Typography width={this.state.isQmsConfig ? "15%" : "25%"} fontWeight={'600'} fontSize={"0.8vw"}>New App</Typography>
                {this.state.isQmsConfig ? <Typography width={"30%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={"0.5vw"}>Availability</Typography> : null}
              </Box> : null}
            <Box component="li" className='eMed_App_Doctor_table_data' {...props} sx={{ width: this.state.isQmsConfig ? "29vw" : '22vw' }}>
              <div style={{ width: this.state.isQmsConfig ? "40%" : "50%" }}>{item?.doctor_name.length > 15 ? <Tooltip placement="top" title={item?.doctor_name} arrow><div>{item?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : item?.doctor_name ? item?.doctor_name : "-"}</div>
              <Typography width={this.state.isQmsConfig ? "15%" : "25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.total_appointment}</Typography>
              <Typography width={this.state.isQmsConfig ? "15%" : "25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.new_appointment}</Typography>
              {this.state.isQmsConfig ? <Box width={"30%"} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box className="eMed_Unavailable_point" sx={{ backgroundColor: item.availability === "Available" ? "#1E972A" : item.availability === "Inpermission" ? "#DE7D24" : "#B8170D" }}></Box>
                {item?.reason ? <Tooltip placement='top' title={item?.reason} arrow><div>{item.availability}</div></Tooltip> : <Typography>{item.availability}</Typography>}
              </Box> : null}
            </Box>
          </Box>) : null}
        onChange={(event, newValue) => {
          states[stateKey] = newValue
          if (newValue !== null && "doctor_id" in newValue) {
            let docDetail = { ...newValue }
            docDetail["name"] = docDetail["specialization_name"];
            delete docDetail.specialization_name;
            docDetail["id"] = docDetail["specialization_id"];
            delete docDetail.specialization_id;
            states["selectedSpecId"] = docDetail;
          }
          if (newValue !== null && "name" in newValue) {
            let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter(item => item["specialization_id"] === newValue["id"])
            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
            states["selectedDocId"] = null;
          }
          if (newValue === null && label === "Specialization") {
            states["filteredDocList"] = states["DoctorList"];
          }
          if (label === "Doctor" || label === "Specialization") {
            let selectedSpec = this.state.SpecializationList?.find((item) => item?.id === newValue?.specialization_id )?.purpose_of_visit
            if(!states.manuallySelected || states.purposeOfVisit === ""){
              states["purposeOfVisit"] = newValue?.purpose_of_visit ? newValue?.purpose_of_visit : selectedSpec ? selectedSpec : (states.selectedSpecId && states.purposeOfVisit) ? states.purposeOfVisit : ''
            }
            states["selectedSlot"] = ""
            states["slotList"] = []
          }
          this.setState({ states })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label + `${((label === "Doctor" && this.state.isDoctorMandatory) || label === "Specialization" || label === "Nurse") ? " *" : ""}`}
            placeholder={label}
          />
        )}
      />
    )
  }

  renderDateTimeFilter = () => {
    try {
      return (
        <Box sx={{ width: '10vw' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              open={this.state.DateOpen}
              onOpen={() => { this.setState({ DateOpen: true }) }}
              onClose={() => { this.setState({ DateOpen: false }) }}
              inputFormat='DD-MM-YYYY & hh:mm A'
              value={this.state.appointmentDateTime}
              minDate={new Date()}
              maxDate={new Date()}
              onChange={(newDate) => {
                if (newDate === null) {
                  this.setState({ appointmentDateTime: new Date() }, () => this.getDoctorDetails())
                }
                else {
                  this.setState({ appointmentDateTime: newDate?.$d }, () => this.getDoctorDetails())
                }
              }}
              renderInput={(params) => <TextField size='small' autoComplete="off"
                onKeyDown={(e) => e.preventDefault()}
                onClick={() => { this.setState({ DateOpen: true }) }}
                {...params}
                fullWidth
                variant='outlined'
                label="Appointment Date & Time *"
              />}
            />
          </LocalizationProvider>
        </Box>
      )
    } catch (e) {

    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      RightTabIndex: newValue,
      SelectedServices: [],
      OPServiceTypeList: [],
      SelectedTemplateName: "",
      SelectedServiceName: "",
      SelectedServiceType: "",
    })

  };

  alphaValidation = (num) => {
    return !/[^A-Z a-z]+/.test(num)
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  handleAgeCalculation = date => {
    // let age = "";
    // let dob = DateTime.fromJSDate(date);
    // var year_age = DateTime.now().diff(dob, 'years');
    // age = Math.floor(year_age.years) || 0;
    // return (date < new Date() && date > new Date("1872-01-01"))?age:"";
    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");
    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age > 0) {
      age = year_age || 0;
    } else {
      age = year_age + "Y/" + month_age + "M/" + day_age + "D";
    }
    return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
  }

  handleFindApproxDob = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    year = +(ageEntered)
    if (!year) {
      tempDate = "";
    } else {
      tempDate = DateTime.now().minus({ years: year }).toBSON();
    }

    return tempDate;
  }

  CheckExistingPatients() {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.mobile_no}&address_key=&father_husband_key=`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SameMobileNumPatients: response.data.data
            })
          }
          else {
          }
        }).catch((error) => {
        })
    }
    catch (e) {
    }
  }

  onClickChipHandler = (item) => {
    this.setState({ selectedSlot: item })
  }

  convertTo12Hour(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Converts "0" hour to "12"
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }


  OpenAppointmentSlotPopUP = () => {
    return (
      <Box>
        <Modal open={true}>
          <Box className="eMed_WebCamPop_Main" sx={{ height: "40vh" }}>
            <Box component={'div'} className="eMed_WebCam_Header">
              <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                Select Slot
              </Typography>
              <Button onClick={() => { this.setState({ slotPopup: false }) }} >
                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
              </Button>
            </Box>
            <Box component={'div'} style={{ display: "flex", flexWrap: "wrap", width: "90%", alignItems: "center", margin: "1vw", marginLeft: "2.5vw", height: "25vh", overflow: "scroll" }}>
              {this.state.slotList?.length > 0 ? this.state.slotList.map((item, index) => (
                <Chip className='eMed_chip'
                  size="medium"
                  label={this.convertTo12Hour(item)}
                  variant={item === this.state.selectedSlot ? 'contained' : 'outlined'}
                  key={index}
                  onClick={() => { this.onClickChipHandler(item) }}
                  color={'primary'}
                  sx={{ width: "5vw", borderRadius: "0.6vw" }}
                  clickable />)) : <Typography sx={{ height: "5vw", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "red" }}>{"Doctor Schedule not available"}</Typography>}
            </Box>
            <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingLeft: "1vw", paddingRight: "3vw" }}>
              <Button variant='outlined' size='small' id="eMed_Config_btn" style={{ marginRight: "0.5vw" }} onClick={() => { this.setState({ selectedSlot: "" }) }}>Clear</Button>
              <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => {
                let AppointTime = new Date(this.state.appointmentDateTime)
                if (this.state.selectedSlot) {
                  AppointTime.setHours(this.state.selectedSlot?.split(":")[0]);
                  AppointTime.setMinutes(this.state.selectedSlot?.split(":")[1]);
                }
                this.setState({
                  appointmentDateTime: (AppointTime && this.state.selectedSlot) ? AppointTime : this.state.appointmentDateTime,
                  slotPopup: false
                })
              }}>Select</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }

  SameNumberPatientPop() {
    let { t } = this.props;
    return (
      <Modal open={true}>
        <Box className="eMed_MobileNum_Main">
          <Box component={'div'} className="eMed_MobileNum_Header">
            <Typography variant='h6'>{"Patient List"}</Typography>
            <IconButton onClick={() => { this.setState({ SameMobileNumPatients: [] }) }} >
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
            </IconButton>
          </Box>
          <Box component={'div'} className="eMed_MobileNum_wrapper">
            {
              this.state.SameMobileNumPatients.length > 0 ?
                this.state.SameMobileNumPatients.map((list) => {
                  let PatientFullName = `${list.first_name} ${list.last_name}`
                  list["patient_name"] = PatientFullName
                  list["patient_mobile_number"] = list.mobile_number
                  list["patient_photo_url"] = list.photo_url
                  return (
                    <Box>
                      <Box className="eMed_Patient_Num_Search_pop">
                        <CommonPatientDetails data={list} />
                        {((list.appointment_date === "" || list.appointment_date !== "") || list.appointment_status === "Cancelled") ? <Tooltip title={t("CreateAppointment")} arrow>
                          <IconButton onClick={() => {
                            setCachevalue(list.patient_id, "OP_CreateAppointment_PatientId")
                            this.setState({ PatientID: list.patient_id, SameMobileNumPatients: [] }, () => { this.getPatientDemographics() })
                          }} position="end">
                            <PersonAddAlt1Icon color='primary' />
                          </IconButton>
                        </Tooltip> : <Typography fontSize={'0.8vw'}>{list.appointment_date}</Typography>}
                      </Box>
                      <Divider />
                    </Box>
                  )
                }) : null
            }
          </Box>
        </Box>
      </Modal>
    )
  }
  getPincodeToCity = (pincode) => {
    fetch("https://api.postalpincode.in/pincode/" + pincode)
      .then((res) => res.json())
      .then(response => {
        if (response && response[0].Status == "Success") {
          var data = response[0].PostOffice
          if (data && data.length > 0) {
            var Api_state = (data[0]?.State) == 'Pondicherry' ? 'Puducherry' : (data[0]?.State)
            this.setState({
              city: this.state?.city ? this.state?.city : data[0].District,
              AllStates: this.state?.state_code ? this.state?.AllStates : State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode),
              country_code: this.state?.country_code ? this.state?.country_code : this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode,
            }, () => {
              this.setState({
                state_code: this.state?.state_code ? this.state?.state_code : this.state.AllStates.find((item) => (item.name === Api_state))?.isoCode,
                cityName: this.state?.city ? this.state?.cityName : City.getCitiesOfState(this.state.country_code, this.state.AllStates.find((item) => (item.name === Api_state))?.isoCode)
              })
            })
          }
        } else if (response.data.status == "fail") {
        }
      })
      .catch(e => {
      });
  }
  renderInput = (data, type) => {
    var states = this.state
    const { t } = this.props
    return (
      <form>
        <Box sx={{ marginTop: '2.5vh', marginLeft: '1vw', width: (data.label == "Age" || data.label == "Title") ? '7vw' : data.label == "Gender" ? '10vw' : '12vw' }}>
          {data.label == "Date of Birth" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                inputFormat={"dd-MM-yyyy"}
                autoComplete="off"
                value={states[data.field_name]}
                minDate={new Date("1872-01-01")}
                maxDate={DateTime.now()}
                onChange={(newDate) => {
                  var age = this.handleAgeCalculation(newDate)
                  this.setState({
                    dob: newDate,
                    age: age
                  })
                }}
                renderInput={(params) => <TextField {...params} size='small' autoComplete="off"
                  variant="outlined" fullWidth />}
              />
            </LocalizationProvider>
            :
            data.label == "Title" ?
              <FormControl size='small' fullWidth sx={{ textAlign: 'center', height: '1.3vw' }}>
                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                <Select
                  disabled={this.state.PatientID && ((!(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)) || this.state.disableNameGenderEdit)}
                  labelId="title-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  sx={{ fontSize: '0.8vw', }}
                  size='small'
                  value={states[data.field_name]}
                  label={t(data.label)}
                  onChange={(e) => {
                    states[data.field_name] = e.target.value

                    this.setState({
                      states
                    }, () => {
                      var states = this.state
                      if (states.title == "Mr") {
                        states["gender"] = "m";
                      } else if (states.title == "Ms") {
                        states["gender"] = "f";
                      } else if (states.title == "Mrs") {
                        states["gender"] = "f";
                        states["marital_status"] = "Married"
                        states["manuallyChanged"] = true
                      }
                      if (states.manuallyChanged && states.title !== "Mrs") {
                        states["marital_status"] = ""
                      }
                      this.setState({ states })
                    })
                  }}
                >
                  <MenuItem value={""}>{"Title"}</MenuItem>
                  <MenuItem value={"Dr"}>{"Dr"}</MenuItem>
                  <MenuItem value={"Mr"}>{"Mr"}</MenuItem>
                  <MenuItem value={"Ms"}>{"Ms"}</MenuItem>
                  <MenuItem value={"Mrs"}>{"Mrs"}</MenuItem>
                  <MenuItem value={"Master"}>{"Master"}</MenuItem>
                  <MenuItem value={"Baby"}>{"Baby"}</MenuItem>
                  <MenuItem value={"Baby of"}>{"Baby of"}</MenuItem>
                </Select>
              </FormControl>
              : data.label == "Gender" ?
                <FormControl size='small' sx={{ width: '10vw' }}>
                  <InputLabel id="gender-select-label">{"Gender *"}</InputLabel>
                  <Select
                    disabled={(this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)) || (this.state.disableNameGenderEdit && this.state.PatientID) ? true : false}
                    sx={{ width: '10vw' }}
                    labelId='gender-select-label'
                    size='small'
                    label={t(data.label)}
                    value={states[data.field_name]}
                    onChange={(e) => {
                      states[data.field_name] = e.target.value
                      this.setState({
                        states
                      })
                    }}
                  >
                    <MenuItem key={1} value={"m"}>{t("Male")}</MenuItem>
                    <MenuItem key={2} value={"f"}>{t("Female")}</MenuItem>
                    <MenuItem key={2} value={"t"}>{t("TransGender")}</MenuItem>
                  </Select>
                </FormControl>
                : data.label === "Insurance Company Name" ?
                  <Autocomplete
                    disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                    sx={{ width: "12vw" }}
                    size="small"
                    options={states.InsuranceList}
                    getOptionLabel={(option) => (typeof (option) === "string" ? option : option.insurance_name)}
                    onChange={(event, value) => {
                      states["insurance_company_name"] = value?.insurance_name ? value?.insurance_name : ""
                      states["NewInsurance"] = false
                      this.setState({
                        states
                      })
                    }}
                    clearIcon={false}
                    value={states.insurance_company_name}
                    renderInput={(params) => (
                      <TextField
                        label={t("Insurance Company Name")} InputProps={{ ...params.InputProps }}
                        onChange={(event) => {
                          states["insurance_company_name"] = event.target.value
                          states["NewInsurance"] = true
                          this.setState({
                            states
                          })
                        }}
                        {...params}
                      />
                    )}
                  />
                  : data.label == "Mobile no" ?
                    <TextField
                      disabled={Object.keys(this.state.RequestedPatientData).length > 0 || this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                      label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                      autoComplete="off"
                      size="small"
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{"+91"}</InputAdornment>
                      }}
                      value={states[data.field_name]}
                      onChange={(e) => {
                        var states = this.state
                        var isMobile = this.numberValidation(e.target.value)
                        if (data.label == "Mobile no") {
                          let mobileNum = e.target.value
                          if ((isMobile && mobileNum.length < 11) || e.target.value == "") {
                            states[data.field_name] = e.target.value
                            if (e.target.value.length === 10) { this.CheckExistingPatients() }
                          }
                        }
                        this.setState({ states })
                      }}
                      error={states.mobile_no !== "" && states.mobile_no.length !== 10}
                    />
                    : data.label === "City" ?
                      <Autocomplete
                        disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                        sx={{ width: "12vw" }}
                        size="small"
                        options={states.cityName ? states.cityName : []}
                        getOptionLabel={(option) => typeof (option) === "string" ? option : option.name}
                        onChange={(e, value) => {
                          this.setState({
                            city: value?.name ? value?.name : '',
                            // pin_code: '',
                          })
                        }}
                        clearIcon={false}
                        value={states.city}
                        renderInput={(params) => (
                          <TextField
                            label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                            InputProps={{ ...params.InputProps }}
                            onChange={(e) => this.setState({
                              city: e.target.value,
                              // pin_code: ''
                            })}
                            {...params}
                          />)}
                        onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : null}
                      />
                      : data.label == "Corporate Name" ?
                        <FormControl size='small' sx={{ width: '12vw' }}>
                          <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                          <Select
                            disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                            sx={{ width: '12vw' }}
                            MenuProps={{ style: { maxHeight: 250 } }}
                            labelId='gender-select-label'
                            size='small'
                            label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                            value={states[data.field_name]}
                            onChange={(e) => {
                              let selectedCompany = states.CorporateList?.find((item) => item?.id === e.target.value)
                              states.company_name = selectedCompany ? selectedCompany?.company_name : ''
                              states[data.field_name] = e.target.value
                              if (e.target.value === "") {
                                states.employee_id = ""
                                states.company_name = ""
                              }
                              this.setState({
                                states
                              })
                            }}
                          >
                            <MenuItem value={""}>{"None"}</MenuItem>
                            {
                              states.CorporateList.length > 0 ?
                                states.CorporateList.map((list, index) => (
                                  <MenuItem key={index} value={list.id}>{list.company_name}</MenuItem>
                                )) : null
                            }
                          </Select>
                        </FormControl>
                        : data.label == "Marital Status" ?
                          <FormControl size='small' sx={{ width: '12vw' }}>
                            <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                            <Select
                              disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                              sx={{ width: '12vw' }}
                              labelId='gender-select-label'
                              size='small'
                              label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                              value={states[data.field_name]}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value
                                states["manuallyChanged"] = false
                                this.setState({
                                  states
                                })
                              }}
                            >
                              <MenuItem key={1} value={"Single"}>{t("Single")}</MenuItem>
                              <MenuItem key={2} value={"Married"}>{t("Married")}</MenuItem>
                              <MenuItem key={2} value={"Divorced"}>{t("Divorced")}</MenuItem>
                            </Select>
                          </FormControl>
                          : data.label == "Employee ID No" ?
                            <TextField
                              autoComplete="off"
                              inputProps={{ maxLength: 20 }}
                              label={(type === 'mandatory' || data.is_default || states.employer_id) ? (t(data.label) + "*") : t(data.label)}
                              size="small" value={states[data.field_name]}
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value.toUpperCase()
                                this.setState({ states })
                              }}
                              disabled={states.employer_id === null || states.employer_id === "" || this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                            />
                            : data.label == "State" ?
                              // <RegionDropdown className='eMed_demo_dropdown_inputs2'
                              //   country={this.state.country}
                              //   value={this.state.state}
                              //   onChange={(val) => {
                              //     states[data.field_name] = val
                              //     this.setState({ states })
                              //   }} />
                              <FormControl size='small' sx={{ width: '12vw' }}>
                                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                                <Select
                                  disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                                  sx={{ width: '12vw' }}
                                  MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                  labelId='gender-select-label'
                                  size='small'
                                  label={data.label}
                                  value={states.state_code}
                                  onChange={(e) => {
                                    this.setState({ state_code: e.target.value },
                                      () => {
                                        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code), city: '' })
                                      })
                                  }}
                                >
                                  {
                                    states.AllStates.map((list) => (
                                      <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                              : data.label == "Country" ?
                                // <CountryDropdown className='eMed_demo_dropdown_inputs2'
                                //   value={this.state.country}
                                //   onChange={(val) => this.setState({ country: val })}
                                //   priorityOptions={["IN"]}
                                //   whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                                // />
                                <FormControl size='small' sx={{ width: '12vw', maxWidth: '12vw' }}>
                                  <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                                  <Select
                                    disabled={this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                                    sx={{ width: '12vw' }}
                                    MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                    labelId='gender-select-label'
                                    size='small'
                                    label={data.label}
                                    value={states.country_code}
                                    onChange={(e) => {
                                      this.setState({ country_code: e.target.value, state_code: "", city: '' }, () => {
                                        this.setState({ AllStates: State.getStatesOfCountry(this.state.country_code), cityName: '' })
                                      })
                                    }}
                                  >
                                    {
                                      states.AllCountry.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                                : data.label === "Father/Husband Name" ?
                                <Box component={'div'} display={'flex'}>
                                  <Select
                                    size='small'
                                    value={this.state.selectedFatHusName}
                                    onChange={(e) => this.setState({ selectedFatHusName: e.target.value })}
                                    sx={{width:'3.5vw'}}
                                  >
                                    <MenuItem value={1}>Father</MenuItem>
                                    <MenuItem value={2}>Husband</MenuItem>
                                  </Select>
                                  <TextField
                                    disabled={Object.keys(this.state.RequestedPatientData).length > 0 || this.state.SelectedPatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                                    label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                                    autoComplete="off"
                                    size="small"
                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                    value={states[data.field_name]}
                                    onChange={(e) => {
                                      let states = this.state
                                      let isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({states })
                                      }
                                    }}
                                    sx={{width:'10vw'}}
                                  />
                                </Box> :
                                <TextField
                                  disabled={((data.label === "First Name") ? Object.keys(this.state.RequestedPatientData).length > 0 : false) || this.state.PatientID && !(CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab")?.editAccess)}
                                  autoComplete="off"
                                  inputProps={{ maxLength: (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Referred By" || data.label === "Referral Source" || data.label === "Mother's Name" || data.label === "Father/Husband Name" || data.label === "Emergency Contact Person" || data.label === "Relationship") ? 32 : (data.label === "Occupation" || data.label === "'Insurance Company Name'" || data.label === "Insurance No") ? 45 : (data.label === "Email ID") ? 50 : 100 }}
                                  label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                                  variant="outlined"
                                  value={states[data.field_name]}
                                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                  onChange={(e) => {
                                    var isAlpha = this.alphaValidation(e.target.value)
                                    var isNum = this.numberValidation(e.target.value)
                                    var isAlphaNum = CommonValidation.alphanumValidation(e.target.value)
                                    var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)

                                    if (data.label === "Age" || data.label === "Father/Husband Age" || data.label === "Mother's Age") {
                                      var age = e.target.value
                                      if ((isNum && +(age) <= 150) || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        states['dob'] = data.label === "Age" ? null : states.dob
                                        this.setState({
                                          states,
                                        }, () => {
                                          if (data.label === "Age") {
                                            var approxDob = this.handleFindApproxDob(this.state.age);
                                            this.setState({
                                              dob: approxDob
                                            })
                                          }
                                        })
                                      }
                                      else if (e.target.value.includes("/")) {
                                        this.setState({ age: "", dob: null })
                                      }
                                    }
                                    else if (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Emergency Contact Person" || data.label === "Occupation" || data.label === "Relationship" || data.label === "Guardian") {
                                      if (isAlpha || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Father/Husband Name" || data.label === "Mother's Name") {
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Pin Code") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 6) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        }, () => {
                                          if (this.state.pin_code && this.state.pin_code.length === 6) {
                                            this.getPincodeToCity(this.state.pin_code)
                                          }
                                        })
                                      }
                                    }
                                    else if (data.label === "Emergency Mobile No") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 10) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Insurance No") {
                                      if ((isAlphaNum || e.target.value == "")) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else {
                                      states[data.field_name] = e.target.value
                                      this.setState({
                                        states
                                      })
                                    }
                                  }}
                                  size="small"
                                />
          }
        </Box>
      </form>
    )
  }
  QuickBillPostSucess() {
    this.ClearDatas()
  }

  ClearDatas() {
    this.setState({
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      selectedAppointTypeId: this.state.AppointmentTypeList.find((item) => (item.appoinment_type === "Walk in Appointment")),
      appointmentDateTime: new Date(),
      purposeOfVisit: "",
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: null,
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      filteredDocList: [],
      PatientID: null,
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      NewInsurance: false,
      RequestedPatientData: {},
      old_referral_no: "",
      company_name: '',
      QRPatientData: {},
      manuallySelected: false,
      manuallyChanged: false,
      followupID: null,
      selectedFatHusName:''
    }, ()=>{
      this.props.VisitDateBalAmount({})
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  changeNursestate = (check) => {
    this.props.changeNursestate(check)
  }

  ClearBillDatas = () => {
    this.setState({
      BillData: {}
    })
  }

  getmakerCount = (flag) => {
    this.props.getmakerCount(flag);
  }

  render() {
    var states = this.state
    var Mandatory_Array = []
    var Non_Mandatory_Array = []
    var listMandatory = []
    var remainMandatoryList = []
    const SplitCount = +(states.mandotory_count)
    const Total_Len = (states.layoutData).length
    var Active_Array = (states.layoutData).filter((item) => {
      return item.is_active == true
    })

    Mandatory_Array = (Active_Array).slice(0, SplitCount)
    Non_Mandatory_Array = (Active_Array).slice(SplitCount, Total_Len)

    // mandatory list splited for view purpose
    listMandatory = SplitCount > 7 && Active_Array.slice(0, 7);
    remainMandatoryList = Active_Array.slice(7, SplitCount);

    let DemographicData = {
      PatientID: states.PatientID,
      selectedDocId: this.props.is_Nurse ? null : states.selectedDocId,
      selectedSpecId: this.props.is_Nurse ? null : states.selectedSpecId,
      selectedTagId: states.selectedTagId,
      selectedAppointTypeId: states.selectedAppointTypeId,
      appointmentDateTime: states.appointmentDateTime,
      purposeOfVisit: states.purposeOfVisit,
      referredBy: states.referredBy,
      referralSource: states.referralSource,
      mobile_no: states.mobile_no,
      first_name: states.first_name,
      age: states.age,
      dob: states.dob,
      gender: states.gender,
      title: states.title,
      middle_name: states.middle_name,
      surname: states.surname,
      address_1: states.address_1,
      address_2: states.address_2,
      city: states.city,
      state: states.AllStates?.find((item) => (item.isoCode === states.state_code))?.name,
      country: states.AllCountry?.find((item) => (item.isoCode === states.country_code))?.name,
      pin_code: states.pin_code,
      employer_id: states.employer_id,
      employee_id: states.employee_id,
      email_id: states.email_id,
      marital_status: states.marital_status,
      father_husband_name: states.father_husband_name,
      father_husband_age: states.father_husband_age,
      occupation: states.occupation,
      emergency_contact_person: states.emergency_contact_person,
      emergency_contact_mobile: states.emergency_contact_mobile,
      relationship: states.relationship,
      MandatoryLabels: states.MandatoryLabels,
      layoutData: states.layoutData,
      mandotory_count: states.mandotory_count,
      receive_email: states?.receive_email,
      receive_whatsapp: states?.receive_whatsapp,
      receive_sms: states?.receive_sms,
      insurance_company_name: states?.insurance_company_name,
      policy_number: states?.policy_number,
      guardian: states?.guardian,
      selectedNurseId: states.selectedNurseId,
      isNurse: this.props.is_Nurse,
      mother_name: states.mother_name,
      mother_age: states.mother_age,
      NewInsurance: states.NewInsurance,
      RequestedPatientData: states?.RequestedPatientData,
      selectedSlot: states?.selectedSlot,
      old_referral_no: states?.old_referral_no,
      company_name: states?.company_name,
      followupID: states?.followupID,
      isDoctorMandatory: states?.isDoctorMandatory,
      QRPatientData : states?.QRPatientData,
      relationship_label: states.selectedFatHusName ? states.selectedFatHusName : ''
    }
    return (

      <Box component={"div"} className={this.props.opPatientBilling ? "eMed_op_bill" : "eMed_bill_container"}>
        {this.props.opPatientBilling ? null :
          <Box className="emed_qckbill_box1">
            <Accordion expanded={this.state.OpenAccordion} sx={{ zIndex: "3", backgroundColor: Colors.ComponentBgColor }} elevation={0}>
              <AccordionSummary
                // sx={{ backgroundColor: Colors.ComponentBgColor }}
                className='emed_qckbill_box1_accordian'
                expandIcon={
                  <IconButton color='primary' onClick={() => { this.setState({ OpenAccordion: !this.state.OpenAccordion }) }}><ExpandMoreIcon /></IconButton>}
              >
                {listMandatory.length > 0 ? listMandatory.map((item, index) => (
                  this.renderInput(item, 'mandatory')
                )) :
                  Mandatory_Array.map((item, index) => (
                    this.renderInput(item, 'mandatory')
                  ))}
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: Colors.ComponentBgColor }}>
                <Box className='eMed_demolayout_left_TextInput' mb={1.3}>
                  {remainMandatoryList.length > 0 ? remainMandatoryList.map((item, index) => (
                    this.renderInput(item, 'mandatory')
                  )) : null}
                </Box>
                <Box className="emed_qckbill_box1_accordian_detail_box">
                  <Typography className='eMed_Esstial_Fields_title'>{"Essential Information"}</Typography>
                  {/* <Box className="emed_qckbill_box1_accordian_detail_box">
                    <FormControlLabel control={<Checkbox checked={this.state.receive_email} onChange={(e) => { this.setState({ receive_email: e.target.checked }) }} size='small' />} label="Receive Email" />
                    <FormControlLabel control={<Checkbox checked={this.state.receive_sms} onChange={(e) => { this.setState({ receive_sms: e.target.checked }) }} size='small' />} label="Receive SMS" />
                    <FormControlLabel control={<Checkbox disabled checked={this.state.receive_whatsapp} onChange={(e) => { this.setState({ receive_whatsapp: e.target.checked }) }} size='small' />} label="WhatsApp" />
                  </Box> */}
                </Box>
                <Box className='eMed_demolayout_left_TextInput'>
                  {Non_Mandatory_Array.map((item, index) => (
                    this.renderInput(item, 'non-mandatory')
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}
        <Box component={'div'} display={'flex'} mt={'0.5vw'}>
          {this.props.opPatientBilling ? null :
            <Box className="eMed_quickbill_left_view1">
              {this.renderDropDown("Specialization", "selectedSpecId", this.state.SpecializationList)}
              {this.props.is_Nurse ? this.renderDropDown("Nurse", "selectedNurseId", this.state.NurseList) :
                this.renderDropDown("Doctor", "selectedDocId", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.DoctorList)}
              {this.renderDateTimeFilter()}
              <Button variant='outlined' disabled={this.state.selectedDocId == null || !this.state.selectedDocId?.doctor_id || !this.state.isQmsConfig} style={{ width: "8vw", height: "2.5vw" }} size="small" id="eMed_Config_btn" onClick={() => { this.getSlotIntervel() }}>{this.state.selectedSlot ? `Slot : ${this.convertTo12Hour(this.state.selectedSlot)}` : "Select Slots"}</Button>
              {this.renderDropDown("Appointment Type", "selectedAppointTypeId", this.state.AppointmentTypeList)}

            </Box>}
          {this.props.opPatientBilling ? null :
            <Box className="eMed_quickbill_right_view1">
              {this.renderDropDown("Patient Tag", "selectedTagId", this.state.TagsList)}
              <Autocomplete
                inputProps={{ maxLength: 250 }}
                options={states.POVList}
                getOptionLabel={(options) => options}
                onChange={(e, value) => {
                  this.setState({
                    purposeOfVisit: value,
                    manuallySelected: true
                  })
                  if(!value){
                    this.setState({ manuallySelected: false })
                  }
                }}
                clearIcon={false}
                size='small'
                sx={{ width: '15vw', marginLeft: "1vw" }}
                value={states.purposeOfVisit}
                renderInput={(params) => <TextField sx={{ width: "15vw" }} onChange={(event) => {
                  this.setState({
                    purposeOfVisit: event.target.value,
                    manuallySelected: true
                  })
                  if(event.target.value === ""){
                    this.setState({ manuallySelected: false })
                  }
                }}
                  {...params}
                  placeholder="Purpose Of Visit *"
                />}
              />
            </Box>
          }
        </Box>
        <Box component={"div"} className={this.props.opPatientBilling ? "eMed_OP_credit_Bill" : "eMed_OP_bill_card"} >
          <CommonOpBilling
            DamographData={DemographicData}
            isFromQuickBilling={true}
            opPatientBilling={this.props.opPatientBilling}
            appointmentId={this.props.appointmentId}
            QuickBillPostSucess={this.QuickBillPostSucess.bind(this)}
            ClearClicked={this.ClearDatas.bind(this)}
            corporateList={this.state.CorporateList}
            ClearBillDatas={this.ClearBillDatas.bind(this)}
            changeNursestate={this.changeNursestate}
            getmakerCount = {this.getmakerCount} />
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.SameMobileNumPatients.length > 0 ? this.SameNumberPatientPop() : null}
        {this.state.slotPopup ? this.OpenAppointmentSlotPopUP() : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(QuickBilling)