import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, DeletePopup, ToolsField, ToolsFieldWithMic } from '../../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../../Styles/Colors'
import { Autocomplete, Box, Button, Dialog, IconButton, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintPreviewPop from '../../../../../Components/Common Components/PrintPreviewPop'
import { Bucket_Name } from '../../../../../Utility/Constants'

let isUser = null;
let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  signatureVersion: 'v4',
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1",
  params: { Bucket: Bucket_Name },
});

export default class RadTestReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientData: {},
      attender_mobile: '',
      attender_name: '',
      page: 0,
      pageSize: 10,
      testReportData: [],
      testServiceData: [],
      ResultTextData: "",
      EditorDisable : false,
      editedText: '',
      confirmpopup: false,
      ResultPDF: [],
      printPreviewPop: false,
      printPreviewData: '',
      pdfName: ''
    }
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    let patientData = JSON.parse(getCachevalue('RadPatientData'))
    this.setState({
      patientData: patientData
    }, () => this.getPatientTestReportData())
  }

  getPatientTestReportData = () => {
    try {
      let params = ''
      if (this.state.patientData?.ip_admission_id) {
        params = `?admission_id=${this.state.patientData?.ip_admission_id}`
      } else if (this.state.patientData?.appointment_id) {
        params = `?appointment_id=${this.state.patientData?.appointment_id}`
      } else {
        let date = this.state.patientData?.invoice_date?.slice(0, 10)?.split('-')
        let invoiceDate = date[2] + "-" + date[1] + "-" + date[0]
        params = `?patient_id=${this.state.patientData?.patient_id}&bill_date=${invoiceDate}`
      }
      this.setState({ isLoader: true })
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_BILLS_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              testReportData: response.data.data,
              isLoader: false
            }, () => {
              let testServiceData = []
              this.state.testReportData?.forEach(element => {
                element?.service_details?.forEach((secElement) => {
                  testServiceData?.push(secElement)
                })
              })
              this.setState({ testServiceData: testServiceData })
            })
          }
        }).catch((error) => {
          this.setState({ isLoader: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ isLoader: false })
      this.errorMessage(error.message)
    }
  }

  getResultTemplateData = () => {
    try {
      this.setState({ tempPost: true })
      RestAPIService.getAll(Serviceurls.RAD_RESULT_GET + '?service_type=' + this.state.selectedServiceData?.service_type + '&service_name=' + this.state.selectedServiceData?.service_name)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              templateData: response.data.data ? response.data.data : [],
              // selectedTemplate: this.state.selectedServiceData?.template_name ? this.state.selectedServiceData?.template_name : response.data.data[0],
              // selectedTemplateResult: this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : response.data.data[0]?.template_data
            }, () => {
              this.setState({
                ResultTextData: this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : ""
              })
              // this.editorRef.current.innerHTML = this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : response.data.data[0]?.template_data ? response.data.data[0]?.template_data : ""
            })
          }
        }).catch((error) => {
          this.setState({ tempPost: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ tempPost: false })
      this.errorMessage(error.message)
    }
  }

  postResultTemplateData = (ResultData, isForSave = false,isForCompleted = false, file) => {
    try {
      const data = new FormData();
      data.append('id', this.state.selectedServiceData?.id)
      data.append('result_data', ResultData ? ResultData : this.state.editedText ? this.state.editedText : "")
      data.append('update_status', isForSave ? 'Result Saved' : isForCompleted ? 'Completed' : "Dispatched")
      data.append('template_name', this.state.selectedTemplate?.template_name ? this.state.selectedTemplate?.template_name : this.state.selectedTemplate || '')
      if (file?.length > 0) {
        if (this.state.ResultPDF?.length > 0 && this.state.ResultPDF[0]?.document_name === file[0]?.document_name) {
          data.append('result_document', JSON.stringify(this.state.ResultPDF))
        } else {
          data.append('result_document', file[0]?.document_file, file[0].document_name)
        }
      } else if (this.state.ResultPDF?.length > 0 && file?.length === 0) {
        data.append('result_document', "")
        data.append('is_deleted', true)
      } else {
        data.append('result_document', "")
      }
      RestAPIService.create(data, Serviceurls.RAD_RESULT_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getPatientTestReportData()
            this.setState({
              selectedServiceData: null,
              selectedTemplate: null,
              selectedTemplateResult: '',
              templatePopUpOpen: false,
              tempPost: false,
              isSave: false,
              EditorDisable: false,
              ResultTextData: "",
              confirmpopup: false,
              editedText: ''
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getS3Key(doc_key, doc_name) {
    try {
      new Promise((resolve, reject) => {
        const params = {
          Bucket: Bucket_Name,
          Key: doc_key,
          Expires: 300,
          ResponseContentType: 'application/pdf',
          ResponseContentDisposition: 'inline',
        };

        s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            this.setState({
              printPreviewPop: true,
              printPreviewData: url,
              pdfName: doc_name ? doc_name : 'PDF Preview'
            });
            resolve(url);
          }
        });
      })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }
  

  handleStyleChange = (command) => {
    document.execCommand(command, false, null);
  };

  PopupClose = () => {
    this.setState({
      confirmpopup: false,
      editedText: ''
    })
  }

  dispatchPopup = (value)=>{
    this.setState({
      confirmpopup : true,
      editedText: value
    })
  }

  renderResultUploadPopup = () => {    
    try {
      return (
          <Dialog open={this.state.templatePopUpOpen} fullWidth={true} maxWidth={'md'} >
            <Box component={'div'}>
              <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={"0.5vw"} mr={'0.5vw'}>
                <Autocomplete
                  value={this.state.selectedTemplate ? this.state.selectedTemplate : null}
                  options={this.state.templateData ? this.state.templateData : []}
                  disabled={this.state.selectedServiceData?.result_status === 'Completed' ? true : this.state.selectedServiceData?.result_status === 'Dispatched' ? true : false}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.template_name}
                  size='small'
                  onChange={(e, value) => {
                    this.setState({
                      selectedTemplate: value
                    }, () => {
                      if (this.state.selectedTemplate !== null) {
                        this.setState({
                          ResultTextData: this.state.selectedTemplate?.template_data
                        })
                      } else {
                        this.setState({
                          ResultTextData: ""
                        })
                      }
                    })
                  }}
                  sx={{ width: '20vw', mr: '2vw' }}
                  renderInput={(params) => <TextField {...params} label='Select Template' />}
                />
                <Button className='Common_Btn_Min_Width' emed_tid='rad_tmp_cls_btn'
                  onClick={() => this.setState({
                    templatePopUpOpen: false,
                    selectedServiceData: null,
                    selectedTemplateResult: '',
                    ResultTextData: '',
                    EditorDisable: false,
                    selectedTemplate: null
                  })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <ToolsFieldWithMic
                FromTestReport={true}
                selectedServiceData={this.state.selectedServiceData}
                EditorDisable={this.state.EditorDisable}
                isDispatchshown={this.state.isDispatchshown}
                Title={"Result"}
                EditorRef={this.state.ResultTextData}
                handleSaveResult={this.postResultTemplateData.bind(this)}
                handlePostResult={this.postResultTemplateData.bind(this)}
                handleDispatchResult={this.dispatchPopup.bind(this)} 
                overallHeight={"70vh"} 
                EditorHeight={"53vh"}
                ResultPDF={this.state.ResultPDF}
                micTextEditDisabled={true}
                errorMsg={this.errorMessage.bind(this)}
              />
            </Box>
          </Dialog>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  PrintReport = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_RESULT_PRINT + '?id=' + id + "&export_type=pdf").
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
      this.errorMessage(error.message, false)
    }
  }

  moreActionOption = (data) => {
    let multi_option = []
    multi_option.push({ label: "View Document", value: "viewDocument" })
    return (
      <div>
        <Tooltip title={"More"} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null })
            }}
          >
            {multi_option.map((option) => (
              <MenuItem key={option}
                disabled={!(data?.row?.result_document?.length > 0)}
                onClick={() => { this.getS3Key(data?.row?.result_document[0]?.document_file, data?.row?.result_document[0]?.document_name) }}>
                {option.label}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  closePrintPreview = () => {
    this.setState({
      printPreviewPop: false,
      printPreviewData: '',
      pdfName: ''
    })
  }

  render() {
    let data = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
      lineFour: "Contact Number",
      LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    }    
    const columns = [
      { field: 'sno', headerName: 'S.no', flex: 0.2 },
      {
        field: 'service_type', headerName: 'Service Type', flex: 0.2,
        renderCell: (params) => (<Box>{params?.row?.service_type ? params?.row?.service_type : '-'}</Box>)
      },
      {
        field: 'service_name', headerName: 'Service Name', flex: 0.2,
        renderCell: (params) => (<Box>{params?.row?.service_name ? params?.row?.service_name : '-'}</Box>)
      },
      {
        field: 'result_status', headerName: 'Result Status', flex: 0.2,
        renderCell: (params) => (<Box>{params?.row?.result_status ? params?.row?.result_status : '-'}</Box>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.2,
        renderCell: (params) => (
          <Box component={'div'} display={'flex'} alignItems={'center'}>
            {params?.row?.result_status === "In Progress" || params?.row?.result_status === "Result Saved" ?
              <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_bill_upl' 
              disabled={!CheckAccessFunc('Radiology', "Home", 'Reports', 'Test Reports', "SubTab")?.editAccess}
               onClick={() => this.setState({ templatePopUpOpen: true, selectedServiceData: params?.row, ResultPDF: params?.row?.result_document ? params?.row?.result_document : [] }, () => this.getResultTemplateData())}>
                <Box component={'img'} src={ImagePaths.UploadBtnTheme.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_bill_view'
                onClick={() => this.setState({
                  selectedTemplate: params?.row?.template_name,
                  selectedTemplateResult: params?.row?.template_data,
                  selectedServiceData: params?.row,
                  ResultTextData : params?.row?.result_data ? params?.row?.result_data : "",
                  ResultPDF: params?.row?.result_document ? params?.row?.result_document : []
                }, () => {
                  this.setState({ templatePopUpOpen: true, EditorDisable: true ,isDispatchshown :params?.row?.result_status ==="Dispatched" ? false :true})
                })}
                disabled={!CheckAccessFunc('Radiology', "Home", 'Reports', 'Test Reports', "SubTab")?.editAccess}>
                <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>}
            {params?.row?.result_status === "Result Saved" || params?.row?.result_status === "Completed" || params?.row?.result_status === "Dispatched" ?
              <Button className='Common_Btn_Min_Width' onClick={() => { this.PrintReport(params?.row?.id) }} >
                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
              </Button> :
              <Button className='Common_Btn_Min_Width'>
                <Box component={'img'} src={ImagePaths.LabPrintIconOff.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>}
              {this.moreActionOption(params)}
          </Box>
        )
      }]
    this.state.testServiceData?.forEach((element, index) => element.sno = index + 1)
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '73vh' }}>
          <Box component={'div'} height={"9.5vh"}>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'}>
                <CommonPatientDetails data={this.state.patientData} showDetailed={(this.state.patientData?.op_number || this.state.patientData?.ip_number) ? true : false} />
              </Box>
              {/* <CommonPatientCard details={data} showDetailed={true} /> */}
            </Box>
          </Box>
          <Box component={'div'} m={'0.5vw'} height={'61vh'} sx={{ backgroundColor: Colors.white }}>
            <DataGrid
              columns={columns}
              rows={this.state.testServiceData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.renderResultUploadPopup()}
        {this.state.printPreviewPop ?
          <PrintPreviewPop
            Data={this.state.printPreviewData}
            Title={this.state.pdfName}
            BillData={this.state.selectedRow}
            ClosePopUp={() => {this.closePrintPreview()}}
            history={this.props.history}
          /> : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.confirmpopup ?
           <DeletePopup DeletTitle={"Are you sure you want to dispatch ?"}
           deleteAlertPopupClose={this.PopupClose.bind(this)}
           removeData={this.postResultTemplateData.bind(this)}
           ButtonText={"Confirm"}
         />
         : null}
      </Box>
    )
  }
}
