import { Box, Button, Stack, Tooltip, Typography, Drawer, Switch } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import {CommonGridToolBarWithFilterText} from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, CheckAccessFunc, formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { CurrencySymbol } from '../../../Utility/Constants';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';

let RoleData = null;
let userAccess = null;

class PharmaVendorPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VendorPaymentList: [],
      vendor_list: [],
      searchkey: null,
      page: 0,
      pageSize: 10,
      rowCount: 0,
      totalAmount:0,
      totalPaidAmount:0,
      totalPendingAmount:0,
      FilterOpen: false,
      fromDate: '',
      toDate: '',
      dateIndex: 0,
      is_bill_wise: false,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Vendor Payment', null, "Tab");

    this.VendorPaymentList()
    this._getVendorList();
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  VendorPaymentList() {
    let {searchkey, fromDate, toDate} = this.state;
    try {
      this.LoaderFunction(true);
      let Billwise = this.state.is_bill_wise ? 'grn_wise' : 'bill_wise'
      RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_PAYMENT}?vendor_id=${searchkey?.vendor_id ? searchkey?.vendor_id : ""}&from_date=${fromDate}&to_date=${toDate}&report_type=${Billwise}`).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              VendorPaymentList: response?.data?.data,
              rowCount: response?.data?.data?.total_count,
              totalAmount: response?.data?.total_bill_amount,
              totalPaidAmount: response?.data?.total_paid_amount,
              totalPendingAmount: response?.data?.total_balance_amount,
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    }
    catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  _getVendorList = () => {
    try {
        RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?from=purchase_order`)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        vendor_list: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.errorMessage(error.response?.data?.message);
                }
            })
    } catch (e) {
        this.errorMessage(e.message);
    }
}

  printVendorPaymentList = () => {
    let {searchkey, fromDate, toDate} = this.state;
    try {
      this.setState({disableBtn: true})
      let Billwise = this.state.is_bill_wise ? 'grn_wise' : 'bill_wise'
      RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_PAYMENT}?vendor_id=${searchkey?.vendor_id ? searchkey?.vendor_id : ""}&from_date=${fromDate}&to_date=${toDate}&report_type=${Billwise}&export_type=${"pdf"}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  getVendorPaymentListExcel = () => {
    const { t } = this.props;
    let {searchkey, fromDate, toDate} = this.state;
    try {
      this.setState({disableBtn: true})
      let Billwise = this.state.is_bill_wise ? 'grn_wise' : 'bill_wise'
      RestAPIService.excelGet(`${Serviceurls.PURCHASE_VENDOR_PAYMENT}?vendor_id=${searchkey?.vendor_id ? searchkey?.vendor_id : ""}&from_date=${fromDate}&to_date=${toDate}&report_type=${Billwise}&export_type=${"excel"}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({disableBtn: false})
    }
  }

  getVendorDetailedBillPrint = (id) => {
    try{
      this.setState({disableBtn: true})
      RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_PAYMENT_PRINT}?vendor_id=${id ? id : ""}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
  })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid = "vndpay_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter_vendor'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={false} />
          </Box>
          {/* <Box className='eMed_rts_dropdown'>
          <Autocomplete
                size='small'
                clearIcon={false}
                sx={{ width: '20vw' }}
                options={this.state.vendor_list.length > 0 ? this.state.vendor_list : []}
                getOptionLabel={(option) => (typeof (option.name) === "string" ? option.name : option)}
                value={states.searchkey ? states.searchkey : ""}
                ListboxProps={{ style: { maxHeight: 300 } }}
                onChange={(event, newValue) => {
                    this.setState({ searchkey: newValue })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("VendorName")}
                        size="small"
                        inputProps = {{...params.inputProps, emed_tid : "VendorName"}}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
            />
          </Box> */}
          <Box className='eMed_rts_dropdown'>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Bill Wise</Typography>
              <Switch size='small' color="primary" checked={this.state.is_bill_wise ? true : false} 
              onClick={(e)=>{
                this.setState({
                  is_bill_wise: e.target.checked
                })
              }} />
              <Typography>GRN Entry Wise</Typography>
            </Stack>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid = "fil_clear"
            onClick={() => {
              this.setState({
                searchkey: null,
                fromDate: '',
                toDate: '',
                dateIndex: 0,
                is_bill_wise: false,
              }, () => this.VendorPaymentList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid = "fil_search"
            onClick={() => {
              this.VendorPaymentList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  gridToolBar = () => {
    const {t} = this.props;
    let TextArray = this.state.dateIndex === 0 ? [] : [
      {label:"From Date", value: formatDate(this.state.fromDate)},
      {label:"To Date", value:formatDate(this.state.toDate)},
  ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} searchHolder = {t("searchVendorName")}/>
      </Box>
    )
  }

  renderTable = () => {
    this.state.VendorPaymentList.forEach((element, index) => element.sno = index + 1)
    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "vendor_name", flex: 0.15, headerName: t("Vendor Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.vendor_name?.length > 23 ?
          <Tooltip placement="top" title={row?.vendor_name} arrow><div>{row?.vendor_name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.vendor_name ? row?.vendor_name : "-"}</div>)
      },
      {
        field: "vendor_gstin_number", flex: 0.1, headerName: t("GST Number"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.vendor_gstin_number ? params?.row?.vendor_gstin_number : "-"}</Box>)
      },
      {
        field: "total_purchase_order", flex: 0.15, headerName: t("Total PO"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_purchase_order ? params?.row?.total_purchase_order : "-"}</Box>)
      },
      {
        field: "total_bill_amount", flex: 0.1, headerName: t(`Bill Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total_bill_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: "total_paid_amount", flex: 0.1, headerName: t(`Paid Amount (${CurrencySymbol})`),  type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.total_paid_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: "total_balance_amount", flex: 0.1, headerName: t(`OutStandingAmount`)+(` (${CurrencySymbol})`),  type: "number",
        renderCell: (params) => (<Typography sx={{color: Colors?.red}}>{AmountFormat(params?.row?.total_balance_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>)
      }
    ]

    if (userAccess?.viewAccess) {
      columns.push(
        {
          field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
          renderCell: ({row}) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={t("Print")} placement="top" arrow>
              <div>
                <Button emed_tid="Print_invoice" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => { this.getVendorDetailedBillPrint(row?.vendor_id) }}>
                  <img src={ImagePaths.PrintIcons.default} alt="cart" className='eMed_action_img' />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={t("Payback")} placement="top" arrow>
              <div>
                <Button emed_tid="vnd_payBk" className='eMed_usrconf_btn' onClick={() => { this.props.history.push({ pathname: "/PharmacyPurchase/VendorPayment/VendorMonthly", state: { vendor_id: row?.vendor_id } }) }}>
                  <img src={ImagePaths.Wallet.default} alt="Wallet" className='eMed_action_img' />
                </Button>
              </div>
            </Tooltip>
          </Box>
        }
      );
    }

    return (
      <Box component={"div"} className='eMed_Pharma_rts_tbl_card'>
        {/* <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' > */}
            <DataGrid
              className='eMed_Pharma_table'
              rows={this.state.VendorPaymentList}
              columns={columns}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              loading={this.state.isLoader}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowCount={this.state.rowCount}
            />
          {/* </Paper>
        </Stack> */}
      </Box>
    )
  }
  render() {
  const {t} = this.props;
    return (
      <div>
          <Box component={'div'} className='eMed_Pharma_rts_header'>
            <Box component={'div'} flex={0.8} display={'flex'}>
              {AmountsCard("Total Bill Amount", this.state.totalAmount, false,"white",false,null)}
              {AmountsCard("Total Paid Amount", this.state.totalPaidAmount, false,"white",false,null)}
              {AmountsCard("Total Pending Amount", this.state.totalPendingAmount, false,"white",false,null)}
            </Box>
            <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Tooltip title="Filter" placement='top' arrow>
              <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' style={{height:'2.3vw',width:'2.3vw'}} />
              </Button>
            </Tooltip>
              <Tooltip title="Print" placement='top' arrow>
                <Button  emed_tid = "vndpay_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.printVendorPaymentList()}>
                  <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' style={{height:'2.3vw',width:'2.3vw'}}/>
                </Button></Tooltip>
              <Tooltip placement="top" title={t("Export")} arrow>
                <Button emed_tid = "vndpay_export" size="small" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getVendorPaymentListExcel()}>
                  <img  src={ImagePaths.LabUploadButton.default} alt='upload' className='eMed_action_img' style={{height:'2.3vw',width:'2.3vw'}}/>
                </Button></Tooltip>
            </Box>
          </Box>

        {this.renderTable()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </div>
    )
  }
}
export default withTranslation()(PharmaVendorPayment)